import React, { useMemo } from 'react';
import type { FC } from 'react';
import { capitalCase } from 'change-case-all';
import type { AuditComponentProps } from '../types';
import { AuditLog } from '../../../AuditLog';

export const ConnectionEstablishedAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const connectedServices = useMemo(() => audit.map((entry) => capitalCase(String(entry.context.serviceName))).join(', '), [audit]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Connected ${connectedServices}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
