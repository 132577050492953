import React from 'react';
import type { FC } from 'react';
import type { ProductApiModel } from '@lama/contracts';
import { Link } from 'react-router-dom';
import { ProductCardBase } from './ProductCardBase';

interface ProductCardProps {
  product: ProductApiModel;
}

export const ProductCard: FC<ProductCardProps> = ({ product }) => (
  <Link to={`${product.id}/appetite`} style={{ textDecoration: 'none' }}>
    <ProductCardBase cta={'Edit Settings'} product={product} />
  </Link>
);
