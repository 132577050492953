import { useContext, useMemo } from 'react';
import { useGetPartnerQuery } from '../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { ApplicationContext } from '../../../ApplicationContext';
import { useGetOpportunitySpreadsConfigurationQuery } from '../../../../../shared/hooks/react-query/opportunity/useGetOpportunitySpreadsConfigurationQuery';

export const useSpreadConfigurationById = (spreadId: string | undefined) => {
  const {
    product,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(product?.partnerId);
  const { data: opportunitySpreadConfig } = useGetOpportunitySpreadsConfigurationQuery(opportunityId);

  return useMemo(() => {
    const opportunitySpreadConfiguration = opportunitySpreadConfig?.spreadConfigurations.find((config) => config.id === spreadId);
    return opportunitySpreadConfiguration ?? partner?.spreadsConfigurations?.find((config) => config.id === spreadId);
  }, [opportunitySpreadConfig, partner?.spreadsConfigurations, spreadId]);
};
