import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { documentServiceUrl } from '../../../../framework/environment';

interface UpdateExtractedDataParams {
  documentId: string;
  extractedDataKey: string;
  correctedValue: number;
  confidence: number;
}

export const useUpdateExtractedDataQuery = (options?: UseMutationOptions<void, unknown, UpdateExtractedDataParams>) => {
  const { getAccessTokenSilently } = useAuth0();

  const updateExtractedDataQuery = useCallback(
    async ({ documentId, extractedDataKey, correctedValue, confidence }: UpdateExtractedDataParams) => {
      const token = await getAccessTokenSilently();

      const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

      await documentServiceClient.updateDocumentExtractedData(
        documentId,
        {
          extractedDataKey,
          value: correctedValue,
          confidence,
        },
        token,
      );
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: updateExtractedDataQuery,
    ...options,
  });
};
