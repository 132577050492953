import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { sub } from 'date-fns';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';

interface ReportsFilterContextProps {
  partnerId: string | null;
  setPartnerId: React.Dispatch<React.SetStateAction<string | null>>;
  productId: string;
  setProductId: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  report: string;
  setReport: React.Dispatch<React.SetStateAction<string>>;
}

export const ReportsFilterContext = createContext<ReportsFilterContextProps>({} as ReportsFilterContextProps);

export const ReportsFilterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { partner } = useContext(UserDetailsContext);

  const [partnerId, setPartnerId] = useState<string | null>(partner ?? null);
  const [productId, setProductId] = useState<string>('allProducts');
  const [report, setReport] = useState<string>('');

  const defaultStartDate = sub(new Date(), { weeks: 4 });
  defaultStartDate.setHours(0, 0, 0, 0);
  const defaultEndDate = new Date();
  defaultEndDate.setHours(23, 59, 59, 0);

  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const value = useMemo(
    () => ({ partnerId, setPartnerId, productId, setProductId, startDate, endDate, setStartDate, setEndDate, report, setReport }),
    [partnerId, productId, startDate, endDate, report],
  );

  return <ReportsFilterContext.Provider value={value}>{children}</ReportsFilterContext.Provider>;
};
