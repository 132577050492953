import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Switch, Flex, Text, Button } from '@lama/design-system';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmModal } from '@lama/app-components';
import { formatConditionDefinition, getConditionConfig } from '@lama/conditions';
import type { Condition, Matcher } from '@lama/contracts';
import { TextField } from '@mui/material';
import { useToggle } from 'react-use';
import { AutoApprovalContext } from '../context/AutoApprovalContext';
import { MatcherCard } from './Matchers/MatcherCard';
import { AddMatcherMenu } from './Matchers/AddMatcherMenu';
import type { MatcherValue } from './Matchers/types';
import { conditionV2ByTypeComponent } from './conditionComponentByType';

export const ConditionPage: FC = () => {
  const { conditionId } = useParams<{ conditionId?: string }>();
  const { conditions, updateCondition, deleteCondition } = useContext(AutoApprovalContext);
  const condition: Condition | null = useMemo(() => conditions?.find((c) => c.id === conditionId) ?? null, [conditionId, conditions]);
  const navigate = useNavigate();

  const { confirm } = useConfirmModal();

  const menuRef = useRef(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);

  const [active, toggleActive] = useToggle(condition?.active ?? false);

  const [note, setNote] = useState(condition?.note ?? '');

  const [enabledMatcher, setEnabledMatcher] = useState<Matcher | null>(condition?.enabledMatcher ?? null);

  const [conditionValues, setConditionValues] = useState<MatcherValue | null>(null);

  const config = useMemo(() => (condition ? getConditionConfig(condition) : null), [condition]);

  const onAddMatcher = useCallback(
    async (matcher: Matcher) => {
      if (!condition) {
        return;
      }

      setEnabledMatcher(matcher);
    },
    [condition],
  );

  const onSubmit = useCallback(async () => {
    if (!condition) {
      return;
    }

    updateCondition({
      ...condition,
      active,
      note,
      enabledMatcher,
      ...conditionValues,
    });

    navigate('../');
  }, [active, condition, conditionValues, enabledMatcher, navigate, note, updateCondition]);

  const removeMatcher = useCallback(() => {
    if (!condition) {
      return;
    }

    setEnabledMatcher(null);
  }, [condition]);

  const onNoteChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setNote(event.target.value);
  }, []);

  const onDelete = useCallback(async () => {
    if (!condition) {
      return;
    }

    const confirmed = await confirm({
      title: 'Delete Rule',
      message: 'Are you sure you want to delete this rule?',
      cancelText: 'Cancel',
      confirmText: 'Delete',
    });

    if (confirmed) {
      deleteCondition(condition.id);
      navigate('../');
    }
  }, [condition, confirm, deleteCondition, navigate]);

  const onCancel = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const ConditionComponent = useMemo(() => {
    if (!condition || !config) {
      return null;
    }

    return conditionV2ByTypeComponent(config, setConditionValues, condition);
  }, [condition, config, setConditionValues]);

  if (!condition) {
    return <Flex>{'Rule not found'}</Flex>;
  }

  return (
    <>
      <Flex flexDirection={'column'} gap={enabledMatcher ? 8 : 2}>
        {enabledMatcher ? (
          <MatcherCard matcher={enabledMatcher} onRemove={removeMatcher} setMatcher={setEnabledMatcher} />
        ) : (
          <Flex>
            <Button variant={'tertiary'} color={'neutral'} size={'m'} startIcon={<Add />} onClick={toggleMenuOpen} ref={menuRef}>
              {'Add Condition'}
            </Button>
          </Flex>
        )}
        <Flex flexDirection={'column'} border={'1px solid #E0E0E0'} borderRadius={'8px'}>
          <Flex
            flexDirection={'row'}
            px={6}
            py={5}
            justifyContent={'space-between'}
            backgroundColor={'grey.50'}
            borderRadius={'8px 8px 0px 0px'}
          >
            <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
              <Flex gap={2} alignItems={'center'}>
                <Text variant={'body2'}>{condition.name}</Text>
                <Text variant={'body2'} color={'grey.300'}>
                  {'•'}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {formatConditionDefinition(condition.conditionMatcher, config?.format)}
                </Text>
              </Flex>
            </Flex>
            <Switch checked={active} onChange={toggleActive} size={'m'} />
          </Flex>
          <Flex flexDirection={'column'} gap={4} p={6}>
            {ConditionComponent ?? null}
            <TextField value={note} onChange={onNoteChange} placeholder={'Add a note...'} />
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Button variant={'tertiary'} color={'danger'} size={'l'} type={'button'} onClick={onDelete}>
                {'Delete Rule'}
              </Button>
              <Flex flexDirection={'row'} gap={4}>
                <Button variant={'tertiary'} color={'neutral'} size={'l'} type={'button'} onClick={onCancel}>
                  {'Cancel'}
                </Button>
                <Button variant={'primary'} color={'primary'} size={'l'} type={'submit'} onClick={onSubmit}>
                  {'Save'}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <AddMatcherMenu open={menuOpen} menuRef={menuRef} onClose={toggleMenuOpen} addMatcher={onAddMatcher} />
    </>
  );
};
