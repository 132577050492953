import type { FC } from 'react';
import React from 'react';
import { Link, Stack, styled, Typography, useTheme } from '@mui/material';
import { MaterialRoundedIcon, Tooltip } from '@lama/app-components';
import { grey } from '@mui/material/colors';
import type { AccountOwner } from '@lama/contracts';
import { compact } from 'lodash-es';

const OwnerInfo: FC<{ icon: string; text: string }> = ({ icon, text }) => {
  const theme = useTheme();

  return (
    <Tooltip placement={'bottom-start'} title={text}>
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        <MaterialRoundedIcon name={icon} size={'16px'} color={theme.palette.text.secondary} />
        <Typography variant={'subtitle1'} color={'text.secondary'} noWrap>
          {text}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const OwnerInfoLink: FC<{ icon: string; text: string; link: string }> = ({ icon, text, link }) => {
  const theme = useTheme();

  return (
    <Tooltip placement={'bottom-start'} title={text}>
      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <MaterialRoundedIcon name={icon} size={'16px'} color={theme.palette.link.color} />
        <Typography variant={'body2'} noWrap>
          <Link variant={'subtitle1'} color={theme.palette.link.color} href={link} underline={'none'} target={'_blank'}>
            {text}
          </Link>
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const OwnerDetailsListItem = styled(Stack)(({ theme }) => ({
  borderRadius: '0px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #0000001F',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const OwnerDetails: FC<{ owner: AccountOwner }> = ({ owner }) => {
  const email = owner.emails?.find((e) => e.primary) ?? owner.emails?.[0];
  const phone = owner.phoneNumbers?.find((p) => p.primary) ?? owner.phoneNumbers?.[0];
  const address = owner.addresses?.find((a) => a.primary) ?? owner.addresses?.[0];

  return (
    <OwnerDetailsListItem spacing={0.5}>
      <Stack direction={'row'} spacing={1}>
        <OwnerInfo icon={'person'} text={owner.names[0] ?? '-'} />
        <OwnerInfoLink icon={'email'} text={email?.data ?? '-'} link={email?.data ? `mailto:${email.data}` : ''} />
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <OwnerInfoLink icon={'phone'} text={phone?.data ?? '-'} link={phone?.data ? `tel:${phone.data}` : ''} />
        <OwnerInfo
          icon={'location_on'}
          text={
            address?.data
              ? compact([address.data.street, address.data.city, address.data.country, address.data.postalCode]).join(', ')
              : '-'
          }
        />
      </Stack>
    </OwnerDetailsListItem>
  );
};
export const AccountOwners: FC<{ owners: AccountOwner[] }> = ({ owners }) => (
  <Stack spacing={2} p={2} sx={{ backgroundColor: grey[50] }} maxWidth={'560px'}>
    <Typography variant={'body2'} color={'text.primary'}>
      {'Owner Details'}
    </Typography>
    {owners.length ? (
      <Stack spacing={2}>
        {owners.map((owner) => (
          <OwnerDetails key={owner.names[0]} owner={owner} />
        ))}
      </Stack>
    ) : (
      <Typography variant={'body2'} color={'text.secondary'}>
        {'No owner details found'}
      </Typography>
    )}
  </Stack>
);
