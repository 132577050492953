import React from 'react';
import { useTheme } from '@mui/material';

export const NoConditionsImage = () => {
  const theme = useTheme();

  return (
    <svg width={'446'} height={'280'} viewBox={'0 0 446 280'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <circle cx={'223'} cy={'140'} r={'140'} fill={'url(#paint0_linear_32011_34458)'} />
      <path
        d={
          'M401.709 39.7746C402.008 36.1297 405.201 33.4142 408.848 33.7102C412.496 34.0062 415.21 37.2055 414.916 40.8519C414.617 44.4968 411.424 47.2124 407.777 46.9164C404.13 46.6204 401.415 43.4211 401.709 39.7746Z'
        }
        fill={'#DDDDDD'}
      />
      <path
        d={
          'M39.3677 54.6473C38.6464 54.4197 37.9511 54.1101 37.2833 53.713C35.0329 52.3718 33.4404 50.241 32.7999 47.7016C32.1578 45.1676 32.5396 42.5302 33.8808 40.2799C36.6443 35.6365 42.6705 34.1139 47.3139 36.8774C49.5643 38.2186 51.1568 40.3496 51.7972 42.889C52.4377 45.4284 52.0577 48.0602 50.7165 50.3106C49.3753 52.5609 47.2443 54.1535 44.7049 54.794C42.9163 55.2448 41.0834 55.1889 39.3677 54.6473ZM37.1337 42.2139C36.3132 43.5952 36.0727 45.2137 36.4709 46.7694C36.8691 48.325 37.8432 49.6359 39.2228 50.4619C40.6041 51.2824 42.2226 51.5229 43.7782 51.1247C45.3339 50.7265 46.6448 49.7523 47.4707 48.3728C48.2913 46.9915 48.5263 45.3714 48.1335 43.8174C47.7353 42.2617 46.7612 40.9509 45.3816 40.1249C44.0003 39.3044 42.3819 39.0638 40.8263 39.462C39.2706 39.8602 37.9597 40.8343 37.1337 42.2139Z'
        }
        fill={'#DBDBDB'}
      />
      <path
        d={
          'M386.368 73.6473C385.646 73.4197 384.951 73.1101 384.283 72.713C382.033 71.3718 380.44 69.241 379.8 66.7016C379.158 64.1676 379.54 61.5302 380.881 59.2799C383.644 54.6365 389.671 53.1139 394.314 55.8774C396.564 57.2186 398.157 59.3496 398.797 61.889C399.438 64.4284 399.058 67.0602 397.716 69.3106C396.375 71.5609 394.244 73.1535 391.705 73.794C389.916 74.2448 388.083 74.1889 386.368 73.6473ZM384.134 61.2139C383.313 62.5952 383.073 64.2137 383.471 65.7694C383.869 67.325 384.843 68.6359 386.223 69.4619C387.604 70.2824 389.223 70.5229 390.778 70.1247C392.334 69.7265 393.645 68.7523 394.471 67.3728C395.291 65.9915 395.526 64.3714 395.134 62.8174C394.735 61.2617 393.761 59.9509 392.382 59.1249C391 58.3044 389.382 58.0638 387.826 58.462C386.271 58.8602 384.96 59.8343 384.134 61.2139Z'
        }
        fill={'#DCDCDC'}
      />
      <g opacity={'0.8'}>
        <g filter={'url(#filter0_d_32011_34458)'}>
          <rect x={'25'} y={'75'} width={'328'} height={'64'} rx={'4'} fill={'white'} />
          <rect x={'25.5'} y={'75.5'} width={'327'} height={'63'} rx={'3.5'} stroke={'#E0E0E0'} />
        </g>
        <rect x={'41'} y={'91'} width={'32'} height={'32'} rx={'2'} fill={'#F3F0FF'} />
        <rect x={'89'} y={'91'} width={'248'} height={'10'} rx={'2'} fill={'#EEEEEE'} />
        <rect x={'89'} y={'113'} width={'150'} height={'10'} rx={'2'} fill={'#EEEEEE'} />
      </g>
      <g filter={'url(#filter1_d_32011_34458)'}>
        <rect x={'93'} y={'123'} width={'328'} height={'64'} rx={'4'} fill={'white'} />
        <rect x={'93.5'} y={'123.5'} width={'327'} height={'63'} rx={'3.5'} stroke={'#E0E0E0'} />
      </g>
      <rect x={'109'} y={'139'} width={'32'} height={'32'} rx={'2'} fill={'#F3F0FF'} />
      <mask
        id={'mask0_32011_34458'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'113'}
        y={'143'}
        width={'24'}
        height={'24'}
      >
        <rect x={'113'} y={'143'} width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_32011_34458)'}>
        <path
          d={
            'M121.543 160.356L124.693 158.456L127.843 160.381L127.018 156.781L129.793 154.381L126.143 154.056L124.693 150.656L123.243 154.031L119.593 154.356L122.368 156.781L121.543 160.356ZM124.693 160.219L120.803 162.565C120.658 162.65 120.513 162.685 120.367 162.672C120.222 162.659 120.09 162.609 119.972 162.525C119.854 162.44 119.763 162.33 119.699 162.193C119.635 162.057 119.624 161.906 119.668 161.74L120.701 157.323L117.266 154.35C117.138 154.238 117.056 154.109 117.021 153.962C116.986 153.816 116.995 153.673 117.049 153.535C117.103 153.396 117.18 153.283 117.281 153.195C117.383 153.107 117.521 153.05 117.697 153.023L122.23 152.627L123.989 148.456C124.053 148.301 124.15 148.186 124.28 148.112C124.411 148.037 124.548 148 124.693 148C124.838 148 124.975 148.037 125.105 148.112C125.236 148.186 125.333 148.301 125.397 148.456L127.156 152.627L131.689 153.023C131.865 153.05 132.003 153.107 132.104 153.195C132.206 153.283 132.283 153.396 132.337 153.535C132.391 153.673 132.4 153.816 132.365 153.962C132.33 154.109 132.248 154.238 132.12 154.35L128.685 157.323L129.718 161.74C129.762 161.906 129.751 162.057 129.687 162.193C129.623 162.33 129.532 162.44 129.414 162.525C129.296 162.609 129.164 162.659 129.019 162.672C128.873 162.685 128.728 162.65 128.583 162.565L124.693 160.219Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
      <rect x={'157'} y={'139'} width={'150'} height={'10'} rx={'2'} fill={'#EEEEEE'} />
      <rect x={'157'} y={'161'} width={'239'} height={'10'} rx={'2'} fill={'#EEEEEE'} />
      <mask
        id={'mask1_32011_34458'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'45'}
        y={'95'}
        width={'24'}
        height={'24'}
      >
        <rect x={'45'} y={'95'} width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask1_32011_34458)'}>
        <path
          d={
            'M53.543 112.356L56.693 110.456L59.843 112.381L59.018 108.781L61.793 106.381L58.143 106.056L56.693 102.656L55.243 106.031L51.593 106.356L54.368 108.781L53.543 112.356ZM56.693 112.219L52.8026 114.565C52.6577 114.65 52.5125 114.685 52.367 114.672C52.2215 114.659 52.0898 114.609 51.9718 114.525C51.8539 114.44 51.7629 114.33 51.6988 114.193C51.6347 114.057 51.6244 113.906 51.668 113.74L52.7007 109.323L49.2661 106.35C49.1379 106.238 49.0562 106.109 49.0209 105.962C48.9857 105.816 48.995 105.673 49.0488 105.535C49.1026 105.396 49.1802 105.283 49.2815 105.195C49.3828 105.107 49.5212 105.05 49.6969 105.023L54.2295 104.627L55.9891 100.456C56.0532 100.301 56.1504 100.186 56.2805 100.112C56.4106 100.037 56.5481 100 56.693 100C56.8378 100 56.9753 100.037 57.1055 100.112C57.2356 100.186 57.3327 100.301 57.3968 100.456L59.1564 104.627L63.6891 105.023C63.8647 105.05 64.0032 105.107 64.1044 105.195C64.2057 105.283 64.2833 105.396 64.3371 105.535C64.391 105.673 64.4003 105.816 64.365 105.962C64.3298 106.109 64.248 106.238 64.1198 106.35L60.6852 109.323L61.7179 113.74C61.7615 113.906 61.7513 114.057 61.6871 114.193C61.623 114.33 61.532 114.44 61.4141 114.525C61.2961 114.609 61.1644 114.659 61.0189 114.672C60.8734 114.685 60.7282 114.65 60.5833 114.565L56.693 112.219Z'
          }
          fill={theme.palette.primary.main}
          fillOpacity={'0.8'}
        />
      </g>
      <defs>
        <filter
          id={'filter0_d_32011_34458'}
          x={'0'}
          y={'58'}
          width={'378'}
          height={'114'}
          filterUnits={'userSpaceOnUse'}
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
          <feColorMatrix in={'SourceAlpha'} type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'} result={'hardAlpha'} />
          <feOffset dy={'8'} />
          <feGaussianBlur stdDeviation={'12.5'} />
          <feComposite in2={'hardAlpha'} operator={'out'} />
          <feColorMatrix type={'matrix'} values={'0 0 0 0 0.219333 0 0 0 0 0 0 0 0 0 0.391667 0 0 0 0.08 0'} />
          <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_32011_34458'} />
          <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_32011_34458'} result={'shape'} />
        </filter>
        <filter
          id={'filter1_d_32011_34458'}
          x={'68'}
          y={'106'}
          width={'378'}
          height={'114'}
          filterUnits={'userSpaceOnUse'}
          colorInterpolationFilters={'sRGB'}
        >
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
          <feColorMatrix in={'SourceAlpha'} type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'} result={'hardAlpha'} />
          <feOffset dy={'8'} />
          <feGaussianBlur stdDeviation={'12.5'} />
          <feComposite in2={'hardAlpha'} operator={'out'} />
          <feColorMatrix type={'matrix'} values={'0 0 0 0 0.219333 0 0 0 0 0 0 0 0 0 0.391667 0 0 0 0.08 0'} />
          <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_32011_34458'} />
          <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_32011_34458'} result={'shape'} />
        </filter>
        <linearGradient id={'paint0_linear_32011_34458'} x1={'223'} y1={'0'} x2={'223'} y2={'280'} gradientUnits={'userSpaceOnUse'}>
          <stop stopColor={'#DADADA'} />
          <stop offset={'0.696005'} stopColor={'#ECECEC'} stopOpacity={'0.4'} />
          <stop offset={'1'} stopColor={'#EEEEEE'} stopOpacity={'0'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
