/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import type { FC } from 'react';
import { TextField } from '@mui/material';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Flex } from '@lama/design-system';
import { googleApiKey } from '../../../../../framework/environment';
import type { ApiAddressPart, PlaceApiResult } from '../../../GooglePlacesServiceContext';
import type { RequirementScreenProps } from './types';
import { GenericRequirementForm } from './GenericRequirementForm';

const getAddressPartsByType = (parts?: ApiAddressPart[]) =>
  parts
    ? Object.fromEntries(
        parts.map(({ types: [firstType], short_name, long_name }) => [firstType, { shortName: short_name, longName: long_name }]),
      )
    : null;

const getAddress1 = (parts: ApiAddressPart[]) => {
  const addressPartsByType = getAddressPartsByType(parts);

  return addressPartsByType?.street_number?.shortName && addressPartsByType?.route?.shortName
    ? `${addressPartsByType.street_number?.shortName} ${addressPartsByType.route?.shortName}`
    : '';
};

const showLenderBusinessSearch = process.env.NODE_ENV === 'production';

export const BusinessDetailsForm: FC<RequirementScreenProps> = (props) => {
  const [fieldsOverrides, setFieldsOverrides] = useState<Record<string, any>>({});

  const { ref } = usePlacesWidget({
    options: {
      componentRestrictions: { country: 'us' },
      types: ['establishment'],
      fields: ['address_component', 'url', 'name', 'formatted_phone_number', 'place_id', 'type', 'website'],
    },
    apiKey: googleApiKey,
    onPlaceSelected: (placeApiResult?: PlaceApiResult) => {
      if (!placeApiResult) {
        return;
      }
      const { address_components, website, name, formatted_phone_number, place_id, types } = placeApiResult;
      const addressPartsByType = getAddressPartsByType(address_components);
      if (!addressPartsByType) {
        return;
      }
      setFieldsOverrides({
        website,
        addresses: [
          {
            address1: getAddress1(address_components),
            city: addressPartsByType.locality?.shortName ?? '',
            state: addressPartsByType.administrative_area_level_1?.longName ?? '',
            zip: addressPartsByType.postal_code?.shortName ?? '',
          },
        ],
        legalName: name,
        industryTypes: types?.filter((t) => !['establishment', 'point_of_interest'].includes(t)),
        phoneNumber: formatted_phone_number,
        googlePlaceId: place_id,
      });
    },
  });
  return (
    <Flex flexDirection={'column'} gap={8}>
      {showLenderBusinessSearch ? <TextField inputRef={ref} label={'Search Business'} placeholder={'Enter name or address'} /> : null}
      <GenericRequirementForm {...props} fieldsOverrides={fieldsOverrides} />
    </Flex>
  );
};
