import type { UseOfFundsBreakdown } from '@lama/contracts';
import { Typography } from '@mui/material';
import type { MRT_ColumnDef } from 'material-react-table';
import type { FC } from 'react';
import React from 'react';
import { formatValue } from '@lama/data-formatters';
import { SimpleTable } from '../../../../shared/components/SimpleTable';

const columnsDefinition: MRT_ColumnDef<UseOfFundsBreakdown>[] = [
  {
    header: 'Type',
    accessorKey: 'type',
    Header: <Typography variant={'body1'}>{'Purpose'}</Typography>,
    Cell: ({ cell }) => (
      <Typography variant={'body2'} color={'text.secondary'}>
        {cell.getValue<string>()}
      </Typography>
    ),
  },
  {
    header: 'Description',
    accessorKey: 'description',
    Header: <Typography variant={'body1'}>{'Description'}</Typography>,
    Cell: ({ cell }) => (
      <Typography variant={'body2'} color={'text.secondary'} width={'260px'} maxWidth={'280px'}>
        {cell.getValue<string>()}
      </Typography>
    ),
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    Header: <Typography variant={'body1'}>{'Amount'}</Typography>,
    Cell: ({ cell }) => (
      <Typography variant={'body2'} color={'text.secondary'}>
        {formatValue(cell.getValue<number>(), 'currency')}
      </Typography>
    ),
  },
];

interface UseOfFundsBreakdownTableProps {
  useOfFundsBreakdown: UseOfFundsBreakdown[];
}

export const UseOfFundsBreakdownTable: FC<UseOfFundsBreakdownTableProps> = ({ useOfFundsBreakdown }) => (
  <SimpleTable data={useOfFundsBreakdown} columns={columnsDefinition} />
);
