import type { OpportunityApiModel } from '@lama/clients';
import { useMemo } from 'react';

export const useAdjustmentNote = (opportunity: OpportunityApiModel, adjustmentId?: string) =>
  useMemo(() => {
    if (!adjustmentId) {
      return;
    }

    return opportunity?.underwriting?.notes?.find(
      ({ type, relatedFinancialAdjustmentId }) => type === 'adjustment' && relatedFinancialAdjustmentId === adjustmentId,
    );
  }, [adjustmentId, opportunity.underwriting?.notes]);
