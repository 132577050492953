import type { ExportedComment } from '@lama/clients';
import type { ExcelColumnSchema } from '@lama/reports-service-client';

export const commentsExportExcelSchema: ExcelColumnSchema<ExportedComment>[] = [
  {
    column: 'ID',
    type: String,
    value: (row) => row.id,
    width: 10,
  },
  {
    column: 'Type',
    type: String,
    value: (row) => row.type,
    width: 10,
  },
  {
    column: 'Author',
    type: String,
    value: (row) => row.author,
    width: 25,
  },
  {
    column: 'Timestamp',
    type: Date,
    format: 'mm/dd/yyyy hh:mm:ss',
    value: (row) => new Date(row.timestamp),
    width: 20,
  },
  {
    column: 'Content',
    type: String,
    value: (row) => row.content,
    width: 35,
  },
  {
    column: 'Context',
    type: String,
    value: (row) => row.context,
    width: 25,
  },
];
