import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../../shared/clients/applicationServiceClient';

export const useRemoveDocumentFromClosingTaskMutation = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, documentId }: { taskId: string; documentId: string }) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.removeOpportunityClosingTaskDocument(opportunityId, taskId, documentId, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['closingTasks', opportunityId] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
