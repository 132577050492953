import { LoadingPage } from '@lama/app-components';
import { Stack } from '@mui/material';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useProductTemplatesQuery } from '../../../../shared/hooks/react-query/product/useProductTemplatesQuery';
import { queryClient } from '../../../../framework/queryClient';
import { productServiceClient } from '../../../../shared/clients/productService';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { ProductTemplateCard } from './ProductTemplateCard';

interface ProductTemplateCardListProps {
  returnTo?: string;
}

export const ProductTemplateCardList: FC<ProductTemplateCardListProps> = ({ returnTo }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { partner } = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const { data: productTemplates, isPending: loadingProductTemplates } = useProductTemplatesQuery();

  const orderedProductTemplates = useMemo(() => orderBy(productTemplates, ['name'], ['asc']), [productTemplates]);

  const { mutate: createProduct, isPending: creatingProduct } = useMutation({
    mutationFn: async (templateId: string) => {
      const token = await getAccessTokenSilently();
      const productId = uuidv4();
      const productTemplate = productTemplates?.find((p) => p.id === templateId);

      if (!productTemplate || !partner) {
        throw new Error('Product template not found');
      }

      const partnerRequirementsReferences = productTemplate.requirements.map((r) => ({ key: r.key }));
      await productServiceClient.createProduct(
        { ...productTemplate, partnerRequirementsReferences, id: productId, partnerId: partner },
        token,
      );

      return productId;
    },
    onSuccess: async (productId: string) => {
      await queryClient.invalidateQueries({ queryKey: ['products'] });
      await queryClient.invalidateQueries({ queryKey: ['deals'] });

      navigate({ pathname: `/products/${productId}/requirements` }, { state: { returnTo } });
    },
  });

  if (creatingProduct || loadingProductTemplates) {
    return <LoadingPage />;
  }

  if (!orderedProductTemplates?.length) {
    return <div>{'No products found'}</div>;
  }

  return (
    <Stack width={'100%'} gap={4} direction={'row'} flexWrap={'wrap'}>
      {orderedProductTemplates.map((productTemplate) => (
        <ProductTemplateCard
          key={productTemplate.id}
          productTemplate={productTemplate}
          onClick={createProduct}
          isLoading={creatingProduct}
        />
      ))}
    </Stack>
  );
};
