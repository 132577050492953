import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { getAbbreviatedUserName } from '@lama/data-formatters';
import { format } from 'date-fns';
import EditedIcon from '../../../assets/edited.svg';
import { ApplicationContext } from '../../../../../ApplicationContext';
import { useAdjustmentNote } from '../../../hooks/useAdjustmentNote';
import type { FieldDescriptionProps } from './types';

export const AdjustmentSourceDescription: FC<FieldDescriptionProps> = ({ editedBy, updatedAt, value: rowData }) => {
  const adjustedUserName = useMemo(() => editedBy && getAbbreviatedUserName(editedBy), [editedBy]);
  const { opportunity } = useContext(ApplicationContext);

  const adjustmentNote = useAdjustmentNote(opportunity, rowData.financialAttribute?.selectedSource.adjustment?.id);

  const editedAt = useMemo(() => updatedAt && format(updatedAt, 'MMM do, yyyy'), [updatedAt]);

  return (
    <Flex flexDirection={'column'} gap={2} width={'400px'}>
      <Flex gap={2} alignItems={'center'}>
        <EditedIcon />
        <Text variant={'body2'}>{'Adjusted Input'}</Text>
        <Text variant={'body3'} color={'secondary'}>
          {'·'}
        </Text>
        {adjustedUserName ? (
          <>
            <Text variant={'body3'} color={'secondary'}>
              {adjustedUserName}
            </Text>
            <Text variant={'body3'} color={'secondary'}>
              {'·'}
            </Text>
          </>
        ) : null}
        <Text variant={'body2'} color={'secondary'}>
          {editedAt}
        </Text>
      </Flex>
      <Flex alignItems={'center'}>
        {adjustmentNote?.text ? (
          <Text variant={'body2'} color={'secondary'}>
            {adjustmentNote.text}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
