import React, { useMemo } from 'react';
import type { FC } from 'react';
import { capitalCase, sentenceCase } from 'change-case-all';
import type { AuditComponentProps } from '../types';
import { AuditLog } from '../../../AuditLog';

export const DataProcessedAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const data = useMemo(
    () =>
      audit
        .map((entry) => `${sentenceCase(String(entry.context.dataType))} from ${capitalCase(String(entry.context.serviceName))}`)
        .join(', '),
    [audit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Fetched ${data}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
