import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Entity } from '@lama/common-types';
import type { AccountData } from '@lama/contracts';
import { bankServiceClient } from '../../../clients/bankService';

export const useBankingDataQuery = (entityId: string, entityType: Entity, options?: UseQueryOptions<AccountData[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getAccountsDataQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return bankServiceClient.getAccountsData(entityId, entityType, token);
  }, [entityId, entityType, getAccessTokenSilently]);

  const query = useQuery<AccountData[]>({
    queryKey: ['bankingData', entityId],
    queryFn: getAccountsDataQuery,
    ...options,
  });

  return query;
};
