import React from 'react';
import type { FC } from 'react';
import type { MRT_Cell, MRT_Column, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { Flex, Text } from '@lama/design-system';
import { StatusFlagOutlined } from '../StatusFlag';
import { theme } from '../../../../../../shared/styling/theme';
import type { ScoreCardData } from './scoreCardData';
import { getWeightedValueFlagStatus, scoreCardData } from './scoreCardData';
import { WeightedAverageLabel } from './WeightedAverageLabel';

const gradeCell = ({
  cell,
}: {
  cell: MRT_Cell<ScoreCardData>;
  column: MRT_Column<ScoreCardData>;
  row: MRT_Row<ScoreCardData>;
  table: MRT_TableInstance<ScoreCardData>;
}) => {
  const grade = cell.getValue<string>();

  return (
    <Text variant={'body2'} color={theme.palette.text.secondary}>
      {grade}
    </Text>
  );
};

const columns: MRT_ColumnDef<ScoreCardData>[] = [
  {
    accessorKey: 'category',
    header: 'Category',
    size: 200,
    maxSize: 200,
  },
  {
    accessorKey: 'coefficient',
    header: 'Coefficient',
    size: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'grade1',
    header: '<1>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade2',
    header: '<2>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade3',
    header: '<3>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade4',
    header: '<4>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade5',
    header: '<5>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade6',
    header: '<6>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'grade7',
    header: '<7>',
    size: 100,
    maxSize: 100,
    Cell: gradeCell,
  },
  {
    accessorKey: 'value',
    header: 'Grade (1-7)',
    size: 120,
    maxSize: 120,
  },
  {
    accessorFn: (row) => row.value * row.coefficient,
    header: 'Weighted',
    size: 120,
    maxSize: 120,
    Cell: ({ cell }) => {
      const weightedScore = cell.getValue<number>();
      const weightedScoreStatus = getWeightedValueFlagStatus(weightedScore);

      return (
        <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
          <StatusFlagOutlined status={weightedScoreStatus} />
          <Text variant={'body2'}>{weightedScore}</Text>
        </Flex>
      );
    },
  },
];

export const ExpandedScoreCard: FC = () => (
  <Flex gap={8} p={8}>
    <MaterialReactTable
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      enablePagination={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableSorting={false}
      muiTableHeadRowProps={{
        sx: {
          backgroundColor: '#F5F5F5',
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: 'solid #0000001F',
          borderWidth: '1px 0px 0px 0px',
        },
      }}
      muiTableContainerProps={{
        sx: {
          border: 'solid #E0E0E0',
          borderWidth: '1px',
          borderRadius: '4px',
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
        },
      }}
      columns={columns}
      data={scoreCardData}
    />
    <Flex justifyContent={'flex-end'}>
      <WeightedAverageLabel />
    </Flex>
  </Flex>
);
