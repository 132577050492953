import React from 'react';
import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { StatusFlagOutlined } from '../StatusFlag';
import { getWeightedAverageFlagStatus, scoreCardData } from './scoreCardData';

export const WeightedAverageLabel: FC = () => {
  const totalCoefficient = scoreCardData.reduce((acc, curr) => acc + curr.coefficient, 0);
  const totalWeighted = scoreCardData.reduce((acc, curr) => acc + curr.coefficient * curr.value, 0);
  const weightedAverage = Math.round((totalWeighted / totalCoefficient) * 10) / 10;
  const weightedAverageStatus = getWeightedAverageFlagStatus(weightedAverage);

  return (
    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
      <Typography fontWeight={'500'}>{'W. Average'}</Typography>
      <StatusFlagOutlined status={weightedAverageStatus} />
      <Typography fontWeight={'500'}>{weightedAverage}</Typography>
    </Stack>
  );
};
