import { useAuth0 } from '@auth0/auth0-react';
import type { GetPaginatedPipelineItemsResponse, GetPipelineItemsQueryParams } from '@lama/pipeline-items-service-client';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { pipelineItemsServiceClient } from '../../../clients/pipelineItemsServiceClient';

export const useGetPaginatedPipelineQuery = (
  queryFilters: Pick<
    GetPipelineItemsQueryParams,
    'assignedTo' | 'filterBySharedWithPartner' | 'limit' | 'partnerIds' | 'productIds' | 'referrerCodes' | 'search' | 'statuses'
  >,
  options?: Partial<UseQueryOptions<GetPaginatedPipelineItemsResponse>>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getPipelineQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return pipelineItemsServiceClient.getPaginatedPipeline({ page: 1, limit: 10, ...queryFilters }, token);
  }, [getAccessTokenSilently, queryFilters]);

  return useQuery<GetPaginatedPipelineItemsResponse>({
    queryKey: ['pipeline', queryFilters],
    queryFn: getPipelineQueryFunction,
    ...options,
  });
};
