import { isNil } from 'lodash-es';
import { useContext, useCallback } from 'react';
import type { AttributeWithFinancialData } from '../Forms/FinancialStatements/utils/financialAttribute';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

export const useGetAttributesDocumentFunction = () => {
  const { currentDocument, currentPage } = useContext(SpreadingDocumentContext);
  const getAttributeDocumentPage = useCallback(
    ({ financialData, financialPage }: AttributeWithFinancialData) => {
      if (
        financialData?.source?.type === 'Document' &&
        financialData?.source?.documentId === currentDocument.id &&
        financialData?.source?.page
      ) {
        return financialData?.source?.page;
      }

      if (!isNil(financialPage) && currentPage !== financialPage - 1) {
        return financialPage;
      }
      return null;
    },
    [currentDocument, currentPage],
  );
  return getAttributeDocumentPage;
};
