import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { GetSimilarCommentsParams, GetSimilarCommentsResponse } from '@lama/clients';
import { applicationServiceClient } from '../../../shared/clients/applicationServiceClient';

export const useGetSimilarCommentsQuery = (
  params: GetSimilarCommentsParams,
  options?: Partial<UseQueryOptions<GetSimilarCommentsResponse>>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getSimilarCommentsQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return applicationServiceClient.getOpportunitiesSimilarComments(params, token);
  }, [getAccessTokenSilently, params]);

  return useQuery<GetSimilarCommentsResponse>({
    queryKey: ['similiarComments', params.opportunityId],
    queryFn: getSimilarCommentsQueryFunction,
    ...options,
  });
};
