import { useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { UserDetailsContext } from '../context/UserDetailsContext';
import { mixpanelToken } from '../../framework/environment';

export const useMixpanelIdentification = () => {
  const user = useContext(UserDetailsContext);
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    if (!isRegistered && mixpanelToken && process.env.NODE_ENV === 'production' && !navigator.userAgent.includes('DatadogSynthetics')) {
      const { email, firstName, lastName, partner, segments, type, userId, permissions, auth0Id } = user;

      const name = firstName && lastName ? `${firstName} ${lastName}` : email;

      if (email) {
        mixpanel.identify(userId);
        mixpanel.people.set({
          email,
          name,
          partner,
          type,
          userId,
          permissions,
          auth0Id,
        });

        // add superProps to other events
        mixpanel.register({
          name,
          email,
          partner,
          type,
          segments: JSON.stringify(segments),
        });

        setIsRegistered(true);
      }
    }
  }, [isRegistered, user]);
};
