import type { ApplicationApiModel } from '@lama/clients';
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, greyPalette } from '@lama/design-system';
import { getPrimaryContactDetails } from '../summaryData';
import { DetailsSegment } from '../DetailsSegment';
import { SetPrimaryContactSearchMenu } from './SetPrimaryContactMenu';

interface PrimaryContactDetailsSegmentProps {
  application: ApplicationApiModel;
}

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '268px',
      maxWidth: '100%',
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

export const PrimaryContactDetailsSegment: FC<PrimaryContactDetailsSegmentProps> = ({ application }) => {
  const primaryContactDetails = useMemo(() => getPrimaryContactDetails(application), [application]);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const onEditClick = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const onMenuClosed = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const setPrimaryContactButton = useMemo(
    () => (
      <Button color={'neutral'} variant={'tertiary'} onClick={onEditClick} ref={menuRef} size={'s'}>
        {'Change'}
      </Button>
    ),
    [onEditClick],
  );

  return (
    <>
      <DetailsSegment title={'Primary Contact'} items={primaryContactDetails} titleActionComponent={setPrimaryContactButton} />
      <SetPrimaryContactSearchMenu open={menuOpen} menuProps={menuProps} onClose={onMenuClosed} menuRef={menuRef} onBack={onMenuClosed} />
    </>
  );
};
