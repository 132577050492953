import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../../ApplicationContext';
import { useEvaluateOpportunityQuery } from '../../../../../shared/hooks/react-query/application/useEvaluateOpportunityQuery';
import { ConditionsTableSegment } from '../../../shared/Conditions/ConditionsTableSegment';
import { ConditionsAlertsSegment } from '../../../shared/Conditions/ConditionsAlertsSegment';

export const ConditionsSection: FC = () => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: conditions, isPending: isEvaluatingOpportunity } = useEvaluateOpportunityQuery(opportunity.id);

  const visibleConditions = useMemo(() => conditions?.filter(({ condition: { hideInCreditMemo } }) => !hideInCreditMemo), [conditions]);

  if (isEvaluatingOpportunity) {
    return <LoadingPage />;
  }

  if (!visibleConditions?.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <ConditionsAlertsSegment conditions={visibleConditions} />
      <Flex flexDirection={'column'} gap={2}>
        <ConditionsTableSegment conditions={visibleConditions} />
        <Text variant={'body3'} color={'secondary'}>
          {`Financial conditions are calculated according to data from ${opportunity.referenceYear}.`}
        </Text>
      </Flex>
    </Flex>
  );
};
