import type { ApplicationApiModel } from '@lama/clients';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { AuditEntryApiModel } from '@lama/audit-service-client';
import { allApplicationPeopleSelector, businessRelationsSelector } from '@lama/data-formatters';
import { capitalCase } from 'change-case-all';
import { keyBy } from 'lodash-es';

export const getAuditFieldsRequirementsMap = (
  audit: AuditEntryApiModel,
  requirements: EvaluatedOpportunityRequirement[],
): Record<string, EvaluatedOpportunityRequirement> => {
  const auditFields = Object.keys(audit.changes ?? {});
  const entityRequirements = requirements.filter((req) => req.entityType === audit.entityType && req.entityId === audit.entityId);

  const fieldToRequirementMap = Object.fromEntries(
    auditFields
      .map((field) => {
        const requirement = entityRequirements.find((req) => req.properties.some((property) => property.fieldName === field));
        return [field, requirement];
      })
      .filter(([, requirement]) => requirement !== undefined),
  );

  return fieldToRequirementMap;
};

interface UpdateAuditPropertyRepresentation {
  name: string;
  entity?: string;
}

export const getAuditUpdatedPropertyRepresentation = (
  audit: AuditEntryApiModel[],
  requirements: EvaluatedOpportunityRequirement[],
  application: ApplicationApiModel,
): UpdateAuditPropertyRepresentation[] => {
  const allPeople = allApplicationPeopleSelector(application);
  const peopleById = keyBy(allPeople, ({ id }) => id);
  const relatedBusinessesById = keyBy(application.relatedBusinesses, ({ business: { id } }) => id);

  return audit.flatMap((auditEntry) => {
    const fieldToRequirementMap = getAuditFieldsRequirementsMap(auditEntry, requirements);

    const auditFields = Object.keys(auditEntry.changes ?? {}).filter((field) => !fieldToRequirementMap[field]);

    return [
      ...auditFields.map((f) => ({ name: capitalCase(f) })),
      ...Object.values(fieldToRequirementMap).map((req) => {
        if (req.entityType === 'person') {
          const person = peopleById[req.entityId];

          if (person) {
            return {
              name: req.name,
              entity: auditEntry.entityName,
            };
          }
        }

        if (req.entityType === 'business') {
          const relatedBusiness = relatedBusinessesById[req.entityId];

          if (relatedBusiness) {
            return {
              name: req.name,
              entity: `${auditEntry.entityName} (${capitalCase(businessRelationsSelector(relatedBusiness.business.id, application))})`,
            };
          }
        }

        return {
          name: req.name,
        };
      }),
    ];
  });
};
