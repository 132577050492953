import { useAuth0 } from '@auth0/auth0-react';
import { useQueries } from '@tanstack/react-query';
import { compact } from 'lodash-es';
import { financialsServiceClient } from '../../../clients/financialsServiceClient';

export const useGetMultiEntityFinancialsQuery = (entities: { entityId: string }[], includeUnverifiedFinancials: boolean) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQueries({
    queries: entities.map(({ entityId }) => ({
      queryKey: ['financials', entityId, includeUnverifiedFinancials],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        return financialsServiceClient.getFinancials({ entityId, includeUnverifiedFinancials }, token);
      },
    })),
    combine: (results) => ({
      loading: results.some((result) => result.isPending),
      financials: compact(results.flatMap((result) => result.data)),
    }),
  });
};
