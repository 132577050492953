import type { FC } from 'react';
import React from 'react';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

export const GrasshopperRelatedDepositsTable: FC = () => (
  <SegmentContainer title={'Related Deposits'} bordered={false}>
    <CreditMemoTable
      headers={[
        {
          text: 'Deposit',
        },
        {
          text: 'Entity',
        },
        {
          text: 'Current Balance',
        },
        {
          text: 'Product',
        },
      ]}
      rows={[
        [
          {
            value: '-',
            align: 'left',
          },
          {
            value: '-',
            align: 'left',
          },
          {
            value: '-',
            align: 'left',
          },
          {
            value: '-',
            align: 'left',
          },
        ],
      ]}
    />
  </SegmentContainer>
);
