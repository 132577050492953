import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { getAssetUrl } from '../../../../../shared/utils/getAssetUrl';
import { EngagementCard } from './EngagementCard';

const cards = [
  {
    title: 'Existing Customers',
    avatarSource: getAssetUrl('engagementDemoAvatars/existingCustomers.png'),
    description: ['Core System Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link', 'Download Audience List'],
    deals: '5.4K',
    amount: 22_500_000,
    chipLabel: 'Internal',
  },
  {
    title: 'Lama AI Exchange',
    avatarSource: getAssetUrl('engagementDemoAvatars/lamaAi.png'),
    description: ['Marketplace Integration Set'],
    settingOptions: ['Configure'],
    deals: '6.3K',
    amount: 31_000_000,
    chipLabel: 'Marketplace',
  },
  {
    title: 'Bridge Marketplace',
    avatarSource: getAssetUrl('engagementDemoAvatars/bridge.png'),
    description: ['Marketplace Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Marketplace',
  },
  {
    title: 'Salesforce',
    avatarSource: getAssetUrl('engagementDemoAvatars/salesforce.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'Shopify',
    avatarSource: getAssetUrl('engagementDemoAvatars/shopify.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'Quickbooks',
    avatarSource: getAssetUrl('engagementDemoAvatars/quickbooks.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'Square',
    avatarSource: getAssetUrl('engagementDemoAvatars/square.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'PayPal',
    avatarSource: getAssetUrl('engagementDemoAvatars/paypal.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'Stripe',
    avatarSource: getAssetUrl('engagementDemoAvatars/stripe.png'),
    description: ['Embedded Integration Set'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Embedded',
  },
  {
    title: 'Google AdWords',
    avatarSource: getAssetUrl('engagementDemoAvatars/google.png'),
    description: ['Account ID: 624-103-349', 'Campaign ID: 287-002-146'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Campaign',
  },
  {
    title: 'Google AdWords: Retargeting',
    avatarSource: getAssetUrl('engagementDemoAvatars/google.png'),
    description: ['Account ID: 541-192-789', 'Campaign ID: 885-643-110'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Campaign',
    deals: '1.1K',
    amount: 10_000_000,
  },
  {
    title: 'Facebook Ads',
    avatarSource: getAssetUrl('engagementDemoAvatars/facebook.png'),
    description: ['Account ID: 142-928-880', 'Campaign ID: 902-020-642'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Campaign',
  },
  {
    title: 'Facebook Ads: Retargeting',
    avatarSource: getAssetUrl('engagementDemoAvatars/facebook.png'),
    description: ['Account ID: 479-023-387', 'Campaign ID: 004-413-739'],
    settingOptions: ['Configure', 'Copy LP Link'],
    chipLabel: 'Campaign',
    deals: '10.1K',
    amount: 100_000_000,
  },
  {
    title: 'Facebook Ads: Lookalike',
    avatarSource: getAssetUrl('engagementDemoAvatars/facebook.png'),
    description: ['Account ID: 894-348-098', 'Campaign ID: 394-496-103'],
    settingOptions: ['Configure', 'Copy LP Link', 'Download Audience List'],
    chipLabel: 'Campaign',
  },
];

export const EngagementSettingsPage: FC = () => (
  <Flex flexDirection={'row'} gap={8} flexWrap={'wrap'}>
    {cards.map((card) => (
      <EngagementCard key={card.title} {...card} />
    ))}
  </Flex>
);
