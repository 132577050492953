import React, { useMemo, useState, useCallback } from 'react';
import type { AnyOfConditionConfiguration, NoneOfConditionConfiguration, HasAllConditionConfiguration } from '@lama/conditions';
import type { NoneOfMatcher, AnyOfMatcher, HasAllMatcher } from '@lama/contracts';
import { TextField } from '@mui/material';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import { AutocompleteMultiPicker } from '@lama/app-components';
import type { MatcherValue } from './Matchers/types';
import type { AnyOfMatcherConfiguration, HasAllMatcherConfiguration, NoneOfMatcherConfiguration } from './Matchers/matcherConfigurations';

interface SelectConditionProps {
  condition?: AnyOfMatcher | HasAllMatcher | NoneOfMatcher;
  configuration:
    | AnyOfConditionConfiguration
    | AnyOfMatcherConfiguration
    | HasAllConditionConfiguration
    | HasAllMatcherConfiguration
    | NoneOfConditionConfiguration
    | NoneOfMatcherConfiguration;
  onChange: (matcher: MatcherValue) => void;
}

export const SelectCondition: FC<SelectConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [value, setValue] = useState<string[]>(condition?.value || configuration.default);

  const updateConditionValue = useCallback(() => {
    onConditionChange({ value, type: configuration.type });
  }, [configuration.type, onConditionChange, value]);

  useDebounce(updateConditionValue, 1000, [value]);

  const onChange = useCallback((values: string[]) => {
    setValue(values);
  }, []);

  const options = useMemo(() => configuration.options.map((option) => option), [configuration.options]);

  const renderInput = useCallback(
    (params: any) => <TextField {...params} label={configuration.displayName} />,
    [configuration.displayName],
  );

  return <AutocompleteMultiPicker value={value} onChange={onChange} options={options} renderInput={renderInput} />;
};
