import type { FC } from 'react';
import React from 'react';
import { Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { TreasuryOriginationChart } from './TreasuryOriginationChart';
import { AccountOpeningChart } from './AccountOpeningChart';
import { FlowAnalysisFunnel } from './FlowAnalysisFunnel';

const timeOptions = ['Today', 'Past week', 'Past month', 'Past 3 months', 'Past year', 'Custom Range'];

const TimeSelection: FC = () => (
  <TextField select defaultValue={'Past week'} sx={{ width: '200px' }}>
    {timeOptions.map((value) => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ))}
  </TextField>
);

export const AccountDashboardPage: FC = () => (
  <Stack spacing={4} px={4} py={4}>
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Typography variant={'h4'}>{'Dashboard 2022'}</Typography>
      <TimeSelection />
    </Stack>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TreasuryOriginationChart />
      </Grid>
      <Grid item xs={6}>
        <AccountOpeningChart />
      </Grid>
      <Grid item xs={6}>
        <FlowAnalysisFunnel />
      </Grid>
    </Grid>
  </Stack>
);
