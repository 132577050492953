import type { InfoLineProps } from '@lama/app-components';
import type { Collateral } from '@lama/contracts';

const typesWithSpecification = new Set([
  'Equipment',
  'Inventory',
  'Other',
  'Personal Vehicle',
  'Commercial Vehicle',
  'Furniture and Fixtures (FF&E)',
]);

export const getInfoLine = (collateral: Collateral): InfoLineProps[] => {
  const { method, ownershipStatus, totalValue, collateralOwner, hasInsurance, typeSpecification, type } = collateral;
  const items: InfoLineProps[] = [
    {
      label: 'Total Value',
      values: totalValue,
      type: 'currency',
    },
    {
      label: 'Ownership Status',
      values: ownershipStatus,
    },
    {
      label: 'Valuation Method',
      values: method,
    },
    {
      label: 'Collateral Owner',
      values: collateralOwner,
    },
    {
      label: 'Has Insurance Coverage',
      values: hasInsurance,
      type: 'boolean',
    },
  ];

  if (!!type && typesWithSpecification.has(type)) {
    items.push({
      label: 'Type Specification',
      values: typeSpecification,
    });
  }

  return items;
};

export const getInsuranceInfo = (collateral: Collateral): InfoLineProps[] => {
  const { insuranceDetails } = collateral;
  return [
    {
      label: 'Insurance Agency',
      values: insuranceDetails?.agency,
    },
    {
      label: 'Phone Number',
      values: insuranceDetails?.phoneNumber,
    },
    {
      label: 'Agent',
      values: insuranceDetails?.agent,
    },
    {
      label: 'Policy Number',
      values: insuranceDetails?.policyNumber,
    },
    {
      label: 'Insurance Premium',
      values: insuranceDetails?.premium?.toString(),
    },
    {
      label: 'Expiration Date',
      values: insuranceDetails?.expirationDate,
      type: 'date',
    },
  ];
};

export const getRealEstateInfo = (collateral: Collateral): InfoLineProps[] => {
  const { realEstateDetails } = collateral;
  return [
    {
      label: 'Address',
      values: realEstateDetails?.address,
    },
    {
      label: 'City',
      values: realEstateDetails?.city,
    },
    {
      label: 'State',
      values: realEstateDetails?.state,
    },
    {
      label: 'Zip Code',
      values: realEstateDetails?.zip,
    },
    {
      label: 'County',
      values: realEstateDetails?.county,
    },
    {
      label: 'Occupancy Type',
      values: realEstateDetails?.occupancyType,
    },
    {
      label: 'Owner Occupied',
      values: realEstateDetails?.ownerOccupied,
      type: 'boolean',
    },
    {
      label: 'Construction Method',
      values: realEstateDetails?.constructionMethod,
    },
    {
      label: 'Multifamily Affordable Units',
      values: realEstateDetails?.multifamilyAffordableUnits,
    },
    {
      label: 'Manufactured Home Land Property Interest',
      values: realEstateDetails?.hmdaManufacturesHomeLandPropertyInterest,
      type: 'boolean',
    },
  ];
};

export const getVehicleInfo = (collateral: Collateral): InfoLineProps[] => {
  const { vehicleDetails, condition } = collateral;
  return [
    {
      label: 'Vehicle Make',
      values: vehicleDetails?.make,
    },
    {
      label: 'Vehicle Model',
      values: vehicleDetails?.model,
    },
    {
      label: 'Vehicle Year',
      values: vehicleDetails?.year?.toString(),
    },
    {
      label: 'VIN',
      values: vehicleDetails?.vin,
    },
    {
      label: 'Vehicle Millage',
      values: vehicleDetails?.mileage?.toString(),
    },
    {
      label: 'Condition',
      values: condition,
    },
  ];
};
