export const lineData = [
  {
    id: 'Opening',
    color: '#DCD4FD',
    data: [
      {
        x: 'Sun',
        y: 2,
      },
      {
        x: 'Mon',
        y: 6,
      },
      {
        x: 'Tue',
        y: 4,
      },
      {
        x: 'Wed',
        y: 8,
      },
      {
        x: 'Thu',
        y: 11,
      },
      {
        x: 'Fri',
        y: 7,
      },
      {
        x: 'Sat',
        y: 12,
      },
    ],
  },
  {
    id: 'Verification',
    color: '#FFDDA6',
    data: [
      {
        x: 'Sun',
        y: 11,
      },
      {
        x: 'Mon',
        y: 7,
      },
      {
        x: 'Tue',
        y: 6,
      },
      {
        x: 'Wed',
        y: 9,
      },
      {
        x: 'Thu',
        y: 13,
      },
      {
        x: 'Fri',
        y: 15,
      },
      {
        x: 'Sat',
        y: 12,
      },
    ],
  },
  {
    id: 'Review',
    color: '#C1E2F4',
    data: [
      {
        x: 'Sun',
        y: 0,
      },
      {
        x: 'Mon',
        y: 2,
      },
      {
        x: 'Tue',
        y: 4,
      },
      {
        x: 'Wed',
        y: 6,
      },
      {
        x: 'Thu',
        y: 2,
      },
      {
        x: 'Fri',
        y: 8,
      },
      {
        x: 'Sat',
        y: 12,
      },
    ],
  },
  {
    id: 'Approve',
    color: '#BEEEDF',
    data: [
      {
        x: 'Sun',
        y: 14,
      },
      {
        x: 'Mon',
        y: 12,
      },
      {
        x: 'Tue',
        y: 8,
      },
      {
        x: 'Wed',
        y: 12,
      },
      {
        x: 'Thu',
        y: 7,
      },
      {
        x: 'Fri',
        y: 11,
      },
      {
        x: 'Sat',
        y: 14,
      },
    ],
  },
  {
    id: 'Reject',
    color: 'hsl(306, 70%, 50%)',
    data: [
      {
        x: 'Sun',
        y: 3,
      },
      {
        x: 'Mon',
        y: 6,
      },
      {
        x: 'Tue',
        y: 9,
      },
      {
        x: 'Wed',
        y: 6,
      },
      {
        x: 'Thu',
        y: 10,
      },
      {
        x: 'Fri',
        y: 12,
      },
      {
        x: 'Sat',
        y: 13,
      },
    ],
  },
];
