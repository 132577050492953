import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InputValue, PixelSuffix } from '@lama/design-system';
import { RichInput, Button, Flex, greyPalette, Text, Popover, PopoverTrigger, PopoverContent } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAsyncFn } from 'react-use';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { useGenerateNoteMutation } from '../../../../shared/hooks/react-query/hackathon/useGenerateNoteMutation';
import { BorderedContainer } from '../BorderedContainer';
import { CommentSearch } from '../../../CommentsSearch/CommentsSearch';
import { useUploadImage } from './hooks/useGetImageUploadUrl';
import { SearchIcon } from './SmartInlineNote/SearchIcon';
import { getCreditSummaryReportTitlesToGenerate } from './SmartInlineNote/creditSummaryPrompts';

const StyledFlex = styled(({ selected, ...rest }) => <Flex {...rest} />)`
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #f3f0ff;
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: #d2c7ff;

    &:hover {
      background-color: #d2c7ff;
    }
    
  `}
`;

const StyledSegmentContainer = styled(Flex)`
  @media print {
    break-inside: avoid !important;
  }
`;

const SmartSegmentContainer: FC<{
  children: React.ReactNode;
  title?: string;
  bordered?: boolean;
  id?: string;
  padding?: PixelSuffix;
  onGenerate: (comment?: string) => Promise<void>;
  noteType: string;
}> = ({ id, children, title, padding, bordered = true, noteType, onGenerate }) => {
  const wrappedchildren = useMemo(
    () => (bordered ? <BorderedContainer padding={padding}>{children}</BorderedContainer> : children),
    [bordered, children, padding],
  );

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const [{ loading: generatingBasedOnExisting }, onGenerateBasedOnExisting] = useAsyncFn(
    async (comment: string) => {
      setOpen(false);
      await onGenerate(comment);
    },
    [onGenerate],
  );

  const [{ loading: generatingAutomatically }, onGenerateAutomatically] = useAsyncFn(async () => {
    setOpen(false);
    await onGenerate();
  }, [onGenerate]);

  const size = 24;

  return (
    <StyledSegmentContainer flexDirection={'column'} gap={4}>
      {title ? (
        <Flex id={id} justifyContent={'space-between'} alignItems={'center'}>
          <Text variant={'body1'} color={greyPalette[500]}>
            {title}
          </Text>
          <Popover placement={'bottom-end'} open={open}>
            <StyledFlex selected={open} border={'1.5px solid'} borderColor={'#714EF5'} borderRadius={4}>
              <Button
                variant={'tertiary'}
                size={'m'}
                color={'primary'}
                startIcon={<AutoAwesomeIcon />}
                style={{ borderRight: '1px solid', borderColor: '#714EF5', borderRadius: 0 }}
                onClick={onGenerateAutomatically}
                disabled={generatingBasedOnExisting}
                loading={generatingAutomatically}
              >
                {'Generate'}
              </Button>
              <PopoverTrigger>
                <Button
                  variant={'tertiary'}
                  size={'m'}
                  color={'primary'}
                  startIcon={<SearchIcon />}
                  endIcon={<ExpandMoreIcon sx={{ width: size, height: size }} />}
                  px={2}
                  pr={2}
                  gap={0}
                  onClick={toggleOpen}
                  disabled={generatingAutomatically}
                  loading={generatingBasedOnExisting}
                />
              </PopoverTrigger>
            </StyledFlex>
            <PopoverContent px={4} py={4} borderRadius={8}>
              <CommentSearch commentType={noteType} onGenerate={onGenerateBasedOnExisting} />
            </PopoverContent>
          </Popover>
        </Flex>
      ) : null}
      {wrappedchildren}
    </StyledSegmentContainer>
  );
};

export const SmartInlineNote: FC<{
  title: string;
  noteName: string;
  noteType?: string;
  valueTemplate?: string;
  placeholder?: string;
  backwardCompatibleNoteName?: string;
  entityId?: string;
}> = ({ title, noteName, noteType, valueTemplate, placeholder, backwardCompatibleNoteName, entityId }) => {
  const { allowImageUploadInInlineNotes } = useFlags();
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: addNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);
  const { mutateAsync: generateNote } = useGenerateNoteMutation(opportunity.id);
  const { mutateAsync: uploadImage } = useUploadImage();
  const [generatedComment, setGeneratedComment] = useState<string | null>(null);
  const [currentlyGeneratedComment, setCurrentlyGeneratedComment] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const toggleEditMode = useCallback(() => {
    setEditMode((prev) => !prev);
  }, []);

  const sectionNote = useMemo(
    () =>
      opportunity.underwriting?.notes?.find(
        ({ title: noteTitle, entityId: noteEntityId }) =>
          noteName &&
          noteTitle === noteName &&
          (entityId ? noteEntityId === entityId || noteEntityId === backwardCompatibleNoteName : true),
      ),
    [backwardCompatibleNoteName, entityId, noteName, opportunity.underwriting?.notes],
  );

  const onImageSelected = useCallback(
    async (img: File) => {
      const src = await uploadImage({ opportunityId: opportunity.id, filename: img.name, fileContents: img });
      return { src };
    },
    [opportunity.id, uploadImage],
  );

  const onNoteSubmit = useCallback(
    async ({ value }: InputValue) => {
      await (sectionNote
        ? updateNote({
            noteId: sectionNote.id,
            notePayload: { text: value, entityId, title: noteName },
          })
        : addNote({
            id: uuidv4(),
            text: value,
            title: noteName,
            entityId,
            type: 'inline',
          }));

      setGeneratedComment(null);
    },
    [sectionNote, updateNote, addNote, noteName, entityId],
  );

  const onGenerateNote = useCallback(
    async (comment?: string) => {
      setIsGenerating(true);
      if (comment) {
        setCurrentlyGeneratedComment(`Currently generating based on: ${comment}`);
        const note = await generateNote({
          noteType: 'Credit Summary',
          body: comment ? { existingComment: comment } : {},
        });
        setGeneratedComment(note.comment);
        setIsGenerating(false);
        setCurrentlyGeneratedComment('');
        return;
      }
      const titles = getCreditSummaryReportTitlesToGenerate(opportunity);
      if (!titles) {
        return;
      }
      const comments: { text: string; position: number }[] = [];
      await Promise.all(
        titles.map(async (t, index) => {
          const note = await generateNote({
            noteType: t,
            body: {},
          });
          comments.push({ text: note.comment, position: index });

          setCurrentlyGeneratedComment(() =>
            comments
              .sort((a, b) => a.position - b.position)
              .map(({ text }) => text)
              .join('\n\n'),
          );
        }),
      );
      setGeneratedComment(
        comments
          .sort((a, b) => a.position - b.position)
          .map(({ text }) => text)
          .join('\n\n'),
      );
      setIsGenerating(false);
      setEditMode(true);
    },
    [opportunity, generateNote],
  );

  const value = useMemo(() => {
    if (isGenerating) {
      return [
        ...currentlyGeneratedComment,
        '<div><img style="max-width: 100%;height: 100px;" src="https://lama-ai-assets.s3.us-east-1.amazonaws.com/small-eclipse.gif" /></div>',
      ].join('');
    }
    return generatedComment ?? sectionNote?.text ?? valueTemplate;
  }, [generatedComment, isGenerating, currentlyGeneratedComment, sectionNote, valueTemplate]);
  return (
    <Flex flexDirection={'column'}>
      <SmartSegmentContainer padding={'12px'} title={title} onGenerate={onGenerateNote} noteType={noteType ?? 'Credit Summary'}>
        <RichInput
          onImageSelected={allowImageUploadInInlineNotes ? onImageSelected : undefined}
          placeholder={placeholder ?? 'Type here'}
          value={value}
          onSubmit={onNoteSubmit}
          editMode={editMode}
          allowEditing={!isGenerating}
          toggleEditMode={toggleEditMode}
        />
      </SmartSegmentContainer>
    </Flex>
  );
};
