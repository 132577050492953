import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { EvaluatedOpportunityRequirement, OpportunityRequirementStatus } from '@lama/contracts';
import { partition } from 'lodash-es';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

// This is a workaround to override the status of the lenderSubmit requirement based on the status of the other requirements
// we don't want to perform this override in the backend because it would interfere with the requirements evaluation logic
// Also, we want the submit flow to not be a requirement, but a separate action
const overrideSubmitRequirementStatus = (requirements: EvaluatedOpportunityRequirement[]): EvaluatedOpportunityRequirement[] => {
  const [[lenderSubmitRequirement], restRequirements] = partition(requirements, (r) => r.screen === 'lenderSubmit');

  if (!lenderSubmitRequirement) {
    return requirements;
  }

  const currentStatusRequirements = restRequirements.filter((r) => r.status !== 'AwaitingFutureStatus');

  const allCompleteOrDismissed = currentStatusRequirements.every(
    (r) => r.status === 'Complete' || r.status === 'Dismissed' || r.status === 'DismissedByLender',
  );
  const allSubmitted = currentStatusRequirements.filter((r) => r.status === 'Complete').every((r) => r.submitted && !r.isInvalidSubmission);

  const lenderSubmitRequirementStatus: OpportunityRequirementStatus = allCompleteOrDismissed
    ? allSubmitted
      ? 'Complete'
      : 'PendingReview'
    : 'AwaitingFutureStatus';

  const updatedLenderSubmitRequirement: EvaluatedOpportunityRequirement = {
    ...lenderSubmitRequirement,
    status: lenderSubmitRequirementStatus,
    submissions: undefined,
    submitted: false,
    statusOverride: undefined,
    statusOverrideByEntity: undefined,
  };

  const updatedRequirements = [...restRequirements, updatedLenderSubmitRequirement];

  return updatedRequirements;
};

export const useOpportunityRequirementsQuery = (
  opportunityId: string | undefined,
  options?: Partial<UseQueryOptions<EvaluatedOpportunityRequirement[] | null>>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const getRequirementsQuery = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const opportunityRequirements = await applicationServiceClient.getOpportunityRequirements(opportunityId, token);

    return overrideSubmitRequirementStatus(opportunityRequirements);
  }, [getAccessTokenSilently, opportunityId]);

  return useQuery<EvaluatedOpportunityRequirement[] | null>({
    queryKey: ['opportunityRequirements', opportunityId],
    queryFn: getRequirementsQuery,
    ...options,
  });
};
