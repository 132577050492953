import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { getAbbreviatedUserName } from '@lama/data-formatters';
import { format } from 'date-fns';
import EditedIcon from '../../../assets/edited.svg';
import type { FieldDescriptionProps } from './types';

export const ManualSourceDescription: FC<FieldDescriptionProps> = ({ editedBy, updatedAt }) => {
  const editedUserName = useMemo(() => editedBy && getAbbreviatedUserName({ ...editedBy }), [editedBy]);

  const editedAt = useMemo(() => updatedAt && format(updatedAt, 'MMM do, yyyy'), [updatedAt]);

  return (
    <Flex gap={2} alignItems={'center'} width={400}>
      <EditedIcon />
      <Text variant={'body2'}>{'Stated Input'}</Text>
      <Text variant={'body3'} color={'secondary'}>
        {'·'}
      </Text>
      {editedUserName ? (
        <>
          <Text variant={'body3'} color={'secondary'}>
            {editedUserName}
          </Text>
          <Text variant={'body3'} color={'secondary'}>
            {'·'}
          </Text>
        </>
      ) : null}
      <Text variant={'body2'} color={'secondary'}>
        {editedAt}
      </Text>
    </Flex>
  );
};
