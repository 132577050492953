/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { DeepChat as DeepChatCore } from 'deep-chat';
import { DeepChat } from 'deep-chat-react';
import { Fab, Fade, Tooltip, useTheme } from '@mui/material';
import { useToggle } from 'react-use';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Flex, greyPalette, Text } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/server';
import type { MessageContent } from 'deep-chat/dist/types/messages';
import styled from 'styled-components';
import { openAiKey } from '../../../../framework/environment';
import { ApplicationContext } from '../../ApplicationContext';
import { useCreateNewThreadForOpportunityMutation } from './hooks/useCreateNewThreadForOpportunityMutation';

const LastMessageWrapper = styled(Flex)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  padding: 8px;
`;

const ChatWrapper = styled(Flex)`
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 9999;
  border: 1px solid ${greyPalette[300]};
  border-radius: 10px;
  width: 500px;
  height: 600px;
  background-color: white;
  boxshadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  animation: scale 0.3s;
  transform-origin: bottom right;

  @keyframes scale {
    from {
      transform: scale(0.1);
    }
    to {
      transform: scale(1);
    }
  }
`;

const questionSuggestions = [
  'What is the revenue trend in the available periods?',
  'Calculate the annualized income based on the interims.',
  'Estimate the debt-service coverage ratio based on the latest tax return.',
  'How much rent does the business pay?',
];

const Suggestions = (
  <div className={'deep-chat-message'} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
    {questionSuggestions.map((suggestion, index) => (
      <button key={`${index}-suggestion`} type={'button'} className={'deep-chat-button deep-chat-suggestion-button'}>
        {suggestion}
      </button>
    ))}
  </div>
);

const defaultSuggestionMessage: MessageContent = {
  html: ReactDOM.renderToStaticMarkup(Suggestions),
  role: 'ai',
};

const AssistantChat: FC<{ assistantId: string; threadId: string }> = ({ assistantId, threadId }) => {
  const componentRef = useRef<DeepChatCore>(null);
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const theme = useTheme();
  const chatHistory = JSON.parse(localStorage.getItem('chatHistory') ?? '{}') as Record<string, MessageContent[]>;
  const threadMessages = useMemo(() => chatHistory[threadId] ?? [defaultSuggestionMessage], [chatHistory, threadId]);
  const lastMessage = useMemo(() => threadMessages.at(-1), [threadMessages]);
  const [showChat, setShowChat] = useState(!lastMessage);
  const { mutateAsync: createThread, isPending: creatingThread } = useCreateNewThreadForOpportunityMutation(opportunityId);

  // TODO: currently when the AI returns a response with annotations, this callback is triggered twice.
  // In order to overcome this, we use a ref to always pull all the messages from the chat component.
  const onMessageAdded = useCallback(
    (body: { message: MessageContent; isHistory: boolean }) => {
      if (body.isHistory || !componentRef.current) {
        return;
      }
      const messages = componentRef.current.getMessages();
      threadMessages.splice(0, threadMessages.length);
      threadMessages.push(...messages);
      localStorage.setItem('chatHistory', JSON.stringify({ ...chatHistory, [threadId]: threadMessages }));
    },
    [chatHistory, threadId, threadMessages],
  );

  const onStartNewChat = useCallback(async () => {
    await createThread();
    setShowChat(true);
    localStorage.setItem('chatHistory', JSON.stringify({ ...chatHistory, [threadId]: [] }));
  }, [chatHistory, createThread, threadId]);

  const onContinueChat = useCallback(() => {
    setShowChat(true);
  }, [setShowChat]);

  return showChat ? (
    <DeepChat
      ref={componentRef}
      style={{ width: '100%', height: '100%', fontFamily: 'Poppins', border: 'none', borderRadius: 'inherit' }}
      textInput={{ placeholder: { text: 'Ask a question' } }}
      connect={{ stream: true }}
      mixedFiles
      introMessage={{ text: 'Hello! How can I help you today?' }}
      directConnection={{
        openAI: {
          key: openAiKey,
          assistant: {
            assistant_id: assistantId,
            thread_id: threadId,
          },
        },
      }}
      messageStyles={{
        default: {
          ai: { bubble: { backgroundColor: `${theme.palette.primary.light}`, color: 'black' } },
          user: { bubble: { backgroundColor: greyPalette[100], color: 'black' } },
        },
      }}
      chatStyle={{
        fontFamily: 'Poppins',
      }}
      avatars={{
        ai: { src: '/BotAvatar.svg' },
        user: { styles: { avatar: { display: 'none' } } },
      }}
      history={threadMessages}
      onMessage={onMessageAdded}
    />
  ) : (
    <Flex flexDirection={'column'} alignItems={'center'} style={{ width: '100%', height: '100%' }} p={8} gap={4}>
      {lastMessage ? (
        <>
          <Text variant={'body2'}>{'Your chat with Lama AI Assistant'}</Text>
          <LastMessageWrapper>
            <Text variant={'body2'} maxLines={3}>
              {lastMessage.text}
            </Text>
          </LastMessageWrapper>
          <Button variant={'tertiary'} onClick={onContinueChat}>
            {'Continue chat'}
          </Button>
          <Text variant={'body2'}>{'or'}</Text>
        </>
      ) : null}
      <Button onClick={onStartNewChat} loading={creatingThread}>
        {'Start a new chat'}
      </Button>
    </Flex>
  );
};

export const SmartAssistant: FC = () => {
  const {
    opportunity: { openAiConfiguration },
  } = useContext(ApplicationContext);
  const { smartAssistantEnabled } = useFlags();
  const [isChatOpen, toggleIsChatOpen] = useToggle(false);

  if (!smartAssistantEnabled || !openAiKey || !openAiConfiguration?.assistantId || !openAiConfiguration?.threadId) {
    return null;
  }

  return (
    <>
      <Tooltip title={isChatOpen ? '' : 'Chat with Lama AI Assistant'}>
        <Fab onClick={toggleIsChatOpen} color={'primary'} size={'medium'} style={{ position: 'fixed', bottom: '20px', right: '30px' }}>
          <Fade in={isChatOpen} timeout={500}>
            <KeyboardArrowDownIcon fontSize={'small'} style={{ position: 'absolute' }} />
          </Fade>
          <Fade in={!isChatOpen} timeout={500}>
            <AutoAwesomeIcon fontSize={'small'} style={{ position: 'absolute' }} />
          </Fade>
        </Fab>
      </Tooltip>
      {isChatOpen ? (
        <ChatWrapper>
          <AssistantChat assistantId={openAiConfiguration.assistantId} threadId={openAiConfiguration.threadId} />
        </ChatWrapper>
      ) : null}
    </>
  );
};
