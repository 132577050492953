import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useSessionStorage } from 'react-use';
import type { SearchResult } from './types';

export interface ApplicationSearchContextProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recentResults: SearchResult[];
  pushRecentResult: (item: SearchResult) => void;
}

export const ApplicationSearchContext = React.createContext<ApplicationSearchContextProps>({
  setModalOpen: () => {},
  modalOpen: false,
  pushRecentResult: () => {},
  recentResults: [],
});

interface ApplicationSearchPorviderProps {
  children: React.ReactNode;
}

export const ApplicationSearchProvider: FC<ApplicationSearchPorviderProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [recentResults, setRecentResults] = useSessionStorage<SearchResult[]>('recentResults', []);

  const pushRecentResult = useCallback(
    (item: SearchResult) => {
      const newRecentResults = [item, ...recentResults.filter((i) => i.opportunityId !== item.opportunityId)].slice(0, 6);
      setRecentResults(newRecentResults);
    },
    [recentResults, setRecentResults],
  );

  const value = useMemo(
    () => ({
      modalOpen,
      setModalOpen,
      recentResults,
      pushRecentResult,
    }),
    [modalOpen, pushRecentResult, recentResults],
  );

  return <ApplicationSearchContext.Provider value={value}>{children}</ApplicationSearchContext.Provider>;
};
