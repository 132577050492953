import type { TopicAnalysisParams } from '@lama/contracts';
import type { SpreadingDocument } from '@lama/document-service-client';
import type { FC } from 'react';
import React, { useCallback, useState, useMemo } from 'react';
import type { PDFHighlightedArea } from '@lama/app-components';
import { useSearchParams } from 'react-router-dom';
import { useGetFormTypesQuery } from '../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetFormTypesQuery';

export interface SpreadingDocumentContextValue {
  documents: SpreadingDocument[];
  currentDocument: SpreadingDocument; // TODO: this should be optional
  applicationId: string;
  opportunityId?: string;
  allFormTypes: TopicAnalysisParams[];
  setCurrentDocument: (documentId: string) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  highlightedArea: PDFHighlightedArea | null;
  setHighlightedArea: (area: PDFHighlightedArea | null) => void;
  currentFormType?: TopicAnalysisParams;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  jumpToPage: number | null;
  setJumpToPage: (page: number | null) => void;
  loading: boolean;
}

export const SpreadingDocumentContext = React.createContext<SpreadingDocumentContextValue>(null!);

interface SpreadingDocumentProviderProps {
  opportunityId?: string;
  documents: SpreadingDocument[];
  applicationId: string;
  children: React.ReactNode;
}

export const SpreadingDocumentProvider: FC<SpreadingDocumentProviderProps> = ({ opportunityId, documents, applicationId, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDocumentId, setCurrentDocumentId] = useState<string>(searchParams.get('documentId') || documents[0]?.id || '');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number | null>(null);
  const [highlightedArea, setHighlightedArea] = useState<PDFHighlightedArea | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const { data: allFormTypes, isPending: formTypesLoading } = useGetFormTypesQuery();

  const currentDocument = useMemo(
    () => documents.find(({ id }) => id === searchParams.get('documentId') || id === currentDocumentId) ?? documents[0]!,
    [searchParams, documents, currentDocumentId],
  );

  const documentPageToFormType = useMemo(
    () =>
      formTypesLoading
        ? {}
        : Object.fromEntries(
            currentDocument?.documentPages?.map(({ page, formType }) => [
              page,
              allFormTypes?.formTypes.find(({ id }) => id === formType),
            ]) ?? [],
          ),
    [currentDocument, allFormTypes?.formTypes, formTypesLoading],
  );

  const currentFormType = useMemo(() => documentPageToFormType[currentPage + 1], [currentPage, documentPageToFormType]);

  const changeCurrentDocument = useCallback(
    (documentId: string) => {
      setCurrentDocumentId(documentId);
      setCurrentPage(0);
      setSearchParams((currentParams) => {
        currentParams.set('documentId', documentId);
        return currentParams;
      });
    },
    [setSearchParams],
  );

  const value = useMemo(
    () => ({
      applicationId,
      opportunityId,
      documents,
      currentDocument,
      setCurrentDocument: changeCurrentDocument,
      currentPage,
      setCurrentPage,
      highlightedArea,
      setHighlightedArea,
      currentFormType,
      saving,
      setSaving,
      jumpToPage,
      setJumpToPage,
      loading: formTypesLoading,
      allFormTypes: allFormTypes?.formTypes ?? [],
    }),
    [
      applicationId,
      opportunityId,
      documents,
      currentDocument,
      changeCurrentDocument,
      currentPage,
      highlightedArea,
      currentFormType,
      saving,
      jumpToPage,
      formTypesLoading,
      allFormTypes?.formTypes,
    ],
  );

  return <SpreadingDocumentContext.Provider value={value}>{children}</SpreadingDocumentContext.Provider>;
};
