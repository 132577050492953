import React from 'react';
import ReactDOM from 'react-dom/client';
import setupLocatorUI from '@locator/runtime';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Replay, init as initSentry, BrowserTracing } from '@sentry/react';
import { init } from 'mixpanel-browser';
import App from './App';
import { ldClientSideId, mixpanelToken } from './framework/environment';

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI();
}

if (mixpanelToken) {
  const token = process.env.NODE_ENV === 'production' && !navigator.userAgent.includes('DatadogSynthetics') ? mixpanelToken : 'test';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  init(token ?? '', { track_pageview: 'full-url', ignore_dnt: true });
}

if (process.env.NODE_ENV === 'production' && !navigator.userAgent.includes('DatadogSynthetics')) {
  initSentry({
    dsn: 'https://318a999a84c14b24a9b04c0cb552a9fe@o4505183063506944.ingest.sentry.io/4505183177932800',
    integrations: [
      new BrowserTracing(),
      new Replay({
        networkDetailAllowUrls: ['api.lama.ai'],
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    tracesSampleRate: 1,
  });
}

const favicon = document.querySelector('#favicon')!;
if (favicon) {
  (favicon as any).href = import.meta.env.DEV ? '/favicon_dev.svg' : '/favicon.png';
}

await (async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientSideId,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      logger: {
        debug: () => {},
        info: () => {},
        warn: console.warn,
        error: console.error,
      },
    },
  });
  ReactDOM.createRoot(document.querySelector('#root')!).render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
})();
