import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import { Flex, Input, Text, greyPalette } from '@lama/design-system';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { displayToast } from '@lama/app-components';
import { useMarkTaskCompleted } from '../shared/useMarkTaskCompleted';
import { ApplicationContext } from '../../../../ApplicationContext';

import type { ClosingTaskComponentProps } from '../closingTaskComponentProps';
import { useUploadDocumentsToNautilus } from '../../../../../../shared/hooks/react-query/nautilus/useUploadDocumentsToNautilus';
import { useApplicationDocumentsQuery } from '../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ExportLine } from '../shared/ExportLine';
import { useUpdateOpportunityMutation } from '../../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';

const DocumentsNote = ({ documents }: { documents?: DocumentWithIssues[] }) => {
  const { showDocumentDataInNautilusExport } = useFlags();
  if (!documents || documents.length === 0) {
    return (
      <Text variant={'body3'}>{'No documents to export. Please upload documents to the application before exporting to Nautilus.'}</Text>
    );
  }
  if (!showDocumentDataInNautilusExport) {
    return null;
  }
  return (
    <Flex flexDirection={'column'}>
      {documents.map((doc) => (
        <Text key={doc.id} variant={'body3'}>
          {`${doc.topic}:${doc.filename}`}
        </Text>
      ))}
    </Flex>
  );
};

export const NautilusExportTaskComponent: FC<ClosingTaskComponentProps> = ({ task }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [dnaLoanNumber, setDnaLoanNumber] = useState(opportunity.externalLoanId);
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const saveDnaLoanNumber = useCallback(async () => {
    if (dnaLoanNumber === opportunity.externalLoanId) {
      return;
    }
    await updateOpportunity(
      { externalLoanId: dnaLoanNumber },
      {
        onError: () => {
          displayToast('Failed saving external loan number. Please try again.', 'error');
        },
      },
    );
  }, [dnaLoanNumber, opportunity.externalLoanId, updateOpportunity]);
  useDebounce(saveDnaLoanNumber, 500, [dnaLoanNumber]);
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: opportunity.application.id,
    includeDeleted: false,
  });
  const { markAsComplete, markingTaskAsComplete } = useMarkTaskCompleted(task);
  const { mutateAsync: uploadToNautilus, isPending: uploadingDocuments } = useUploadDocumentsToNautilus(opportunity.id);
  const handleClick = useCallback(async () => {
    if (!dnaLoanNumber) {
      return;
    }
    await uploadToNautilus(dnaLoanNumber);
    markAsComplete();
  }, [uploadToNautilus, markAsComplete, dnaLoanNumber]);

  const handleDnaLoanNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDnaLoanNumber(e.target.value);
  }, []);

  const isLoading = useMemo(
    () => uploadingDocuments || markingTaskAsComplete || loadingDocuments,
    [uploadingDocuments, markingTaskAsComplete, loadingDocuments],
  );
  const hasDocuments = useMemo(() => applicationDocuments && applicationDocuments?.length > 0, [applicationDocuments]);
  return (
    <Flex flexDirection={'column'} gap={4}>
      <DocumentsNote documents={applicationDocuments} />
      <Input
        value={dnaLoanNumber}
        onChange={handleDnaLoanNumberChange}
        placeholder={'DNA Loan Number'}
        disabled={!applicationDocuments?.length}
      />
      <Flex border={`1px solid ${greyPalette[300]}`} width={'100%'} p={3} borderRadius={'4px'} flexDirection={'row'}>
        <ExportLine
          exportDisabled={!hasDocuments || isLoading}
          name={'Sync all documents in this application to Nautilus (pdf, doc, docx, xls, xlsx)'}
          onExportClick={handleClick}
        />
      </Flex>
    </Flex>
  );
};
