import React from 'react';
import { Flex, Text } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductCardList } from './ProductCardList';

export const ProductsPage = () => {
  const { useIframeProductSettings } = useFlags();

  if (useIframeProductSettings) {
    return (
      <iframe
        src={'https://demo.lama.ai'}
        frameBorder={'0'}
        title={'products'}
        width={'100%'}
        height={'99%'}
        sandbox={'allow-same-origin allow-forms allow-scripts'}
      />
    );
  }

  return (
    <Flex flexDirection={'column'} height={'100%'} backgroundColor={'#F6F6F6'} px={20} py={8} gap={12}>
      <Text variant={'h5'}> {'Products'}</Text>
      <ProductCardList />
    </Flex>
  );
};
