import React from 'react';
import type { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import DismissedIcon from '../../../shared/icons/dismissed.svg';

export const DismissedMark: FC = () => (
  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={0.5}>
    <DismissedIcon />
    <Typography variant={'body2'} color={'text.secondary'}>
      {'Dismissed'}
    </Typography>
  </Stack>
);
