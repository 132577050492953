import React, { useMemo } from 'react';
import type { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { data as demoAccountingData } from '../../demoData/businessMetrics';
import { MetricsTable } from './MetricsTable';
import { columnsDefinition } from './MetricsColumns';

export const BusinessMetrics: FC = () => {
  const { useDemoBusinessDetails } = useFlags<{ useDemoBusinessDetails: boolean }>();

  const tableData = useMemo(() => (useDemoBusinessDetails ? demoAccountingData : []), [useDemoBusinessDetails]);

  if (!tableData?.length) {
    return null;
  }

  return <MetricsTable columns={columnsDefinition} data={tableData} />;
};
