import React, { useContext, useMemo, useRef } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { RenderThumbnailItem, RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';
import { useTheme } from '@mui/material';
import { useDebounce, useToggle } from 'react-use';
import styled from 'styled-components';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

const StyledPreviewContainer = styled(Flex)<{ open: boolean }>`
  position: relative;
  border-radius: 4px;

  img {
    border-radius: 4px;
    transition: filter 0.1s ease-in-out;
    filter: ${({ open }) => (open ? 'none' : 'blur(1px)')};
  }

  .rpv-thumbnail__container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;

    ${({ open }) =>
      open
        ? `
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
      `
        : ''}
  }

  .rpv-thumbnail__container {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
  }
`;

export const ThumbnailDetailsContainer = styled(Flex)<{ open: boolean }>`
  position: absolute;

  transition: opacity 0.1s ease-in-out;
  opacity: ${({ open }) => (open ? 0 : 1)};
  z-index: 2;
`;

export const PageThumbnail: RenderThumbnailItem = ({
  pageIndex,
  onJumpToPage,
  renderPageThumbnail,
  currentPage,
}: RenderThumbnailItemProps) => {
  const displayPageIndex = pageIndex + 1;
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const formType = currentDocument.documentPages?.find(({ page }) => displayPageIndex === page)?.formType ?? '';
  const theme = useTheme();
  const thumbnailRef = useRef<HTMLDivElement>(null);
  const isCurrentPage = useMemo(() => pageIndex === currentPage, [pageIndex, currentPage]);
  const [showDetails, toggleShowDetails] = useToggle(false);

  useDebounce(
    () => {
      if (isCurrentPage && thumbnailRef.current) {
        thumbnailRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
    200,
    [isCurrentPage],
  );

  return (
    <Flex flexDirection={'column'} gap={2} py={3} justifyContent={'center'} alignItems={'center'} onClick={onJumpToPage} key={pageIndex}>
      <StyledPreviewContainer
        flexDirection={'column'}
        borderRadius={isCurrentPage ? 0 : undefined}
        onMouseEnter={toggleShowDetails}
        onMouseLeave={toggleShowDetails}
        open={showDetails}
        border={isCurrentPage ? `2px solid ${theme.palette.primary.main}` : undefined}
      >
        {renderPageThumbnail}
        <ThumbnailDetailsContainer
          open={showDetails}
          flexDirection={'column'}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          height={'100%'}
          p={1}
          ref={thumbnailRef}
        >
          <Text variant={'body3'} color={isCurrentPage ? theme.palette.primary.main : 'black'} textAlign={'center'} ellipsis>
            {`Page ${displayPageIndex}`}
          </Text>
          <Text variant={'body3'} color={isCurrentPage ? theme.palette.primary.main : 'black'} textAlign={'center'} maxLines={2}>
            {formType}
          </Text>
        </ThumbnailDetailsContainer>
      </StyledPreviewContainer>
    </Flex>
  );
};
