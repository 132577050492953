import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';
import type { GetPaginatedPipelineItemsResponse, GetPipelineItemsQueryParams } from '@lama/pipeline-items-service-client';
import { pipelineItemsServiceClient } from '../../../clients/pipelineItemsServiceClient';

export const useGetPipelineCategoriesQueries = (
  pipelineCategories: string[],
  queryFilters: Pick<GetPipelineItemsQueryParams, 'assignedTo' | 'partnerIds' | 'productIds' | 'referrerCodes' | 'search' | 'statuses'>,
  options?: Partial<UseQueryOptions<GetPaginatedPipelineItemsResponse>>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getPipelineCategoryQueryFunction = useCallback(
    async (pipelineCategory: string) => {
      const token = await getAccessTokenSilently();

      return pipelineItemsServiceClient.getPaginatedPipeline({ page: 1, limit: 1, ...queryFilters, pipelineCategory }, token);
    },
    [getAccessTokenSilently, queryFilters],
  );

  const combine = useCallback(
    (results: UseQueryResult<GetPaginatedPipelineItemsResponse>[]) => ({
      pending: results.some((result) => result.isPending),
      data: results.map((result) => ({
        category: result.data?.requestedPipelineCategory,
        count: result.data?.total,
        totalRequestedAmount: result.data?.totalRequestedAmount,
      })),
    }),
    [],
  );

  return useQueries({
    queries: pipelineCategories.map((pipelineCategory) => ({
      queryKey: ['pipelineCategory', pipelineCategory, queryFilters],
      queryFn: () => getPipelineCategoryQueryFunction(pipelineCategory),
    })),
    combine,
    ...options,
  });
};
