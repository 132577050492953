import { Popover } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Flex } from '@lama/design-system';
import { AddNoteInput } from './AddNoteInput.js';

interface AddNotePopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  placeholder: string;
  initialText?: string;
  onSubmit: (text: string) => Promise<void>;
}

export const AddNotePopover: FC<AddNotePopoverProps> = ({ anchorEl, onSubmit, onClose, placeholder, initialText }) => {
  const onSubmitInternal = useCallback(
    async (text: string) => {
      await onSubmit(text);
      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <Popover
      open={!!anchorEl}
      sx={{ mt: 1 }}
      PaperProps={{
        sx: {
          boxShadow: '0px 8px 32px 0px #3800641F',
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        },
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Flex flexDirection={'column'} width={'700px'} backgroundColor={'white'}>
        <AddNoteInput onSubmit={onSubmitInternal} placeholder={placeholder} initialText={initialText} />
      </Flex>
    </Popover>
  );
};
