import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { DocumentLineItem } from '@lama/document-service-client';
import { documentServiceClient } from '../../../../clients/documentServiceClient';

export const useDocumentLineItemsQuery = (
  documentId: string,
  includeUnverifiedLineItems?: boolean,
  options?: UseQueryOptions<DocumentLineItem[]>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getDocumentLineItems = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const lineItems = await documentServiceClient.getDocumentLineItems(documentId, token, includeUnverifiedLineItems);

    return lineItems;
  }, [documentId, getAccessTokenSilently, includeUnverifiedLineItems]);

  const query = useQuery<DocumentLineItem[]>({
    queryKey: ['documentLineItems', documentId],
    queryFn: getDocumentLineItems,
    ...options,
  });

  return query;
};
