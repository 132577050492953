import { useAuth0 } from '@auth0/auth0-react';
import type { UnderwritingDecisionCreateModel } from '@lama/clients';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useCreateDecisionMutation = (
  opportunityId: string,
  options?: UseMutationOptions<unknown, unknown, UnderwritingDecisionCreateModel>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (decisionPayload: UnderwritingDecisionCreateModel) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.addDecision(opportunityId, decisionPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] }),
        queryClient.invalidateQueries({ queryKey: ['pipeline'] }),
        queryClient.invalidateQueries({ queryKey: ['opportunityAlerts', opportunityId] }),
      ]);
    },
    ...options,
  });
};
