import React from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { AttributeWithFinancialData } from '../../../FinancialStatements/utils/financialAttribute';
import { TaxReturnFormFields } from './TaxReturnFormFields';

interface TaxReturnsScheduleProps {
  scheduleName?: string;
  financialAttributes: AttributeWithFinancialData[];
  onFieldFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const TaxReturnsSchedule: FC<TaxReturnsScheduleProps> = ({ scheduleName, financialAttributes, onFieldFocus, onFieldBlur }) => (
  <Flex flexDirection={'column'} key={scheduleName} p={2} gap={4}>
    {scheduleName ? <Text variant={'h6'}>{scheduleName}</Text> : null}
    <TaxReturnFormFields financialAttributes={financialAttributes} onFieldFocus={onFieldFocus} onFieldBlur={onFieldBlur} />
  </Flex>
);
