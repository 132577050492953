import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { UnderwritingDecision } from '@lama/contracts';
import { useConfirmModal } from '@lama/app-components';
import type { InputValue } from '@lama/design-system';
import { Button, Flex, greyPalette, redPalette, Text, RichInput } from '@lama/design-system';
import { Cancel, MailOutline, MarkEmailReadOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateDecisionMutation } from '../../../../../shared/hooks/react-query/opportunity/useUpdateDecisionMutation';
import { useDeleteDecisionMutation } from '../../../../../shared/hooks/react-query/opportunity/useDeleteDecisionMutation';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useSendRejectionEmailMutation } from '../../../../../shared/hooks/react-query/useSendRejectionEmailMutation';
import { useUpdateOpportunityMutation } from '../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { SendEmailDialog } from '../../../../../shared/components/SendEmailDialog/SendEmailDialog';
import { DecisionMakerTitle } from './DecisionMakerTitle';
import { StyledDecisionCardContainer } from './StyledDecisionCardContainer';

interface DecisionCardProps {
  decision: UnderwritingDecision;
  canCurrentUserApprove?: boolean;
}

export const DecisionCard: FC<DecisionCardProps> = ({ decision, canCurrentUserApprove }) => {
  const {
    application,
    opportunity: { adverseActionNoticeSent, id: opportunityId, partnerId },
  } = useContext(ApplicationContext);
  const { userId: currentUserId } = useContext(UserDetailsContext);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();
  const { mutate: updateDecision } = useUpdateDecisionMutation(opportunityId);
  const { mutate: deleteDecision } = useDeleteDecisionMutation(opportunityId);
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);
  const { data: users } = useUsersByPartnerQuery(partnerId);
  const { mutateAsync: sendRejectionEmail, isPending: sendingRejectionEmail } = useSendRejectionEmailMutation(
    application.id,
    opportunityId,
  );
  const [emailDialogOpen, toggleEmailDialog] = useToggle(false);

  const decisionEmailTitle = useMemo(() => {
    switch (decision.decision) {
      case 'Rejected': {
        return t('decision.adverseAction.title');
      }
      case 'ExpresslyWithdrawn': {
        return t('decision.withdrawal.title');
      }
      default: {
        return t('decision.adverseAction.title');
      }
    }
  }, [decision.decision, t]);

  const sendEmail = useCallback(
    async ({ preview }: { preview: boolean }) => {
      await sendRejectionEmail({ preview });
    },
    [sendRejectionEmail],
  );

  const [firstName, lastName] = useMemo(() => {
    if (decision.userId === 'lama') {
      return ['Lama', 'AI'];
    }

    const decisionUser = users?.find((user) => user.id === decision.userId);

    return [decisionUser?.firstName ?? 'Unknown', decisionUser?.lastName ?? 'User'];
  }, [decision.userId, users]);
  const decisionRole = useMemo(
    () => (decision.userId === 'lama' ? 'lama' : decision.roles.at(-1) ?? ''),
    [decision.roles, decision.userId],
  );
  const decisionBelongsToCurrentUser = useMemo(() => decision.userId === currentUserId, [currentUserId, decision.userId]);

  const onDecisionNoteEdit = useCallback(
    ({ value }: InputValue) => {
      if (!canCurrentUserApprove && !decisionBelongsToCurrentUser) {
        return;
      }

      updateDecision({
        decisionId: decision.id,
        decisionPayload: {
          text: value,
        },
      });
    },
    [canCurrentUserApprove, decisionBelongsToCurrentUser, updateDecision, decision.id],
  );

  const onClickRevert = useCallback(async () => {
    const confirmed = await confirm({ title: 'Are you sure you want to undo this decision?' });

    if (confirmed) {
      deleteDecision(decision.id);
      updateOpportunity({
        adverseActionNoticeSent: false,
      });
    }
  }, [confirm, decision.id, deleteDecision, updateOpportunity]);

  const isSystemDecision = useMemo(() => decision.userId === 'lama', [decision]);

  return (
    <StyledDecisionCardContainer>
      <DecisionMakerTitle
        decision={decision.decision}
        role={decisionRole}
        firstName={firstName}
        lastName={lastName}
        decisionTimestamp={decision.createdAt}
      />
      <Flex gap={4} flexDirection={'column'}>
        {decision.reasons?.length ? (
          <Flex flexDirection={'column'} p={2} borderRadius={4}>
            {decision.reasons?.map(({ text, id }) => (
              <li style={{ color: greyPalette[500], padding: 1 }} key={id}>
                <Text variant={'body2'} color={greyPalette[500]}>
                  {text}
                </Text>
              </li>
            ))}
          </Flex>
        ) : null}
        {!isSystemDecision ? <RichInput value={decision.text ?? ''} onSubmit={onDecisionNoteEdit} placeholder={'Add a note'} /> : null}
      </Flex>
      {isSystemDecision ? null : (
        <Flex justifyContent={'flex-end'} alignItems={'center'}>
          <Flex gap={4} alignItems={'center'}>
            {decision.decision === 'Rejected' || decision.decision === 'ExpresslyWithdrawn' ? (
              <Button
                variant={'secondary'}
                color={'neutral'}
                iconColor={'primary'}
                startIcon={adverseActionNoticeSent ? <MarkEmailReadOutlined /> : <MailOutline />}
                size={'m'}
                onClick={toggleEmailDialog}
                disabled={!!adverseActionNoticeSent || !canCurrentUserApprove}
                loading={sendingRejectionEmail}
              >
                {t(`decision.adverseAction.cta.${adverseActionNoticeSent ? 'sent' : 'send'}`)}
              </Button>
            ) : null}
            {adverseActionNoticeSent ? null : (
              <Button
                variant={'secondary'}
                color={'neutral'}
                iconColor={redPalette[500]}
                startIcon={<Cancel />}
                disabled={!canCurrentUserApprove && !decisionBelongsToCurrentUser}
                size={'m'}
                onClick={onClickRevert}
              >
                {'Revert Decision'}
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <SendEmailDialog open={emailDialogOpen} onClose={toggleEmailDialog} sendEmail={sendEmail} title={decisionEmailTitle} />
    </StyledDecisionCardContainer>
  );
};
