import type { OpportunityClosingTask } from '@lama/contracts';
import { Flex, Text, grayPalette, greyPalette } from '@lama/design-system';
import styled from 'styled-components';
import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { personFullName, formatValue } from '@lama/data-formatters';
import ExpandButton from '../../../../shared/components/ExpandButton';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { ClosingTaskStatusBadge } from './ClosingTaskStatusBadge';

const StyledTaskCardHeader = styled(Flex)<{ open: boolean; dismissed: boolean }>(
  ({ open, dismissed }) => `
    border-color: ${grayPalette[300]};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: ${dismissed ? grayPalette[50] : open ? grayPalette[50] : 'transparent'};
    cursor: pointer;
    height: 56px;
    transition: background 0.1s ease-in-out;

    ${
      !open &&
      `&:hover {
        background: ${grayPalette[50]};
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }`
    }
`,
);

export const TaskCardHeader: FC<{ open: boolean; onClick: (taskId: string) => void; task: OpportunityClosingTask }> = ({
  open,
  onClick,
  task,
}) => {
  const { partner } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partner);

  const assignee = useMemo(() => users?.find((u) => task.assigneeIds?.includes(u.id)) ?? null, [task.assigneeIds, users]);

  const onCardClick = useCallback(() => {
    onClick(task.id);
  }, [onClick, task.id]);

  const inapplicable = useMemo(() => task.status === 'Dismissed', [task.status]);

  return (
    <StyledTaskCardHeader open={open} dismissed={inapplicable} gap={2} px={6} py={4} alignItems={'center'} onClick={onCardClick}>
      <Flex flexDirection={'row'} gap={2} alignItems={'center'} flex={1}>
        <Text variant={'body2'} color={inapplicable ? 'disabled' : 'primary'}>
          {task.name}
        </Text>
        {task.name && task.role ? (
          <Text variant={'body2'} color={greyPalette[500]}>
            {'•'}
          </Text>
        ) : null}
        {task.role ? (
          <Text variant={'body2'} color={inapplicable ? 'disabled' : 'secondary'}>
            {task.role}
          </Text>
        ) : null}
      </Flex>
      <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
        <Flex flexDirection={'row'} gap={2}>
          {assignee ? (
            <Text variant={'body2'} color={inapplicable ? 'disabled' : 'secondary'}>
              {personFullName(assignee)}
            </Text>
          ) : null}
          {assignee && task.completedAt ? (
            <Text variant={'body2'} color={greyPalette[500]}>
              {'•'}
            </Text>
          ) : null}
          {task.completedAt ? (
            <Text variant={'body2'} color={inapplicable ? 'disabled' : 'secondary'}>
              {formatValue(task.completedAt, 'date')}
            </Text>
          ) : null}
          <ClosingTaskStatusBadge status={task.status} />
        </Flex>
        <ExpandButton expand={open} sx={{ color: grayPalette[500] }} />
      </Flex>
    </StyledTaskCardHeader>
  );
};
