import React, { useContext, useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import * as dataFormatters from '@lama/data-formatters';
import { applicationPrimaryContactSelector } from '@lama/data-formatters';
import { MaterialRoundedIcon, Tooltip } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex, Text } from '@lama/design-system';
import { useTheme } from 'styled-components';
import { useEffectOnce } from 'react-use';
import { BusinessImage } from '../../../shared/components/BusinessImage';
import { useUsersByPartnerQuery } from '../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { ApplicationContext } from '../ApplicationContext';
import { ApplicationActionsMenu } from '../ActionsMenu/ApplicationActionsMenu';
import { useGetPartnerQuery } from '../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { ApplicationSearchContext } from '../ApplicationSearch/ApplicationSearchContext';
import { opportunityToSearchResult } from '../ApplicationSearch/searchResultTransformers';
import { ApplicationNumber } from '../../../shared/components/ApplicationNumber';
import { ApplicationAssignees } from './ApplicationAssignees';
import { ApplicationStatusWorkflowChip } from './ApplicationWorkflow/ApplicationStatusWorkflowChip';

interface BusinessInfoProps {
  icon: ReactNode | string;
  text: string;
}
const BusinessInfo: FC<BusinessInfoProps> = ({ icon, text }) => {
  const theme = useTheme();

  return (
    <Flex alignItems={'center'} gap={1}>
      {icon && typeof icon === 'string' ? <MaterialRoundedIcon name={icon} size={'16px'} color={theme.text.colors.secondary} /> : icon}
      <Text variant={'body4'} color={'secondary'}>
        {text}
      </Text>
    </Flex>
  );
};

export const ApplicationHeader: FC = () => {
  const { fibtDemo, showOpportunityPartnerInHeader, shortenApplicationDisplayName } = useFlags();
  const {
    application,
    product: { prettyName },
    opportunity,
  } = useContext(ApplicationContext);
  const { pushRecentResult } = useContext(ApplicationSearchContext);
  const { requestedAmount } = application;

  const { data: opportunityPartner } = useGetPartnerQuery(showOpportunityPartnerInHeader ? opportunity.partnerId : '', {
    enabled: showOpportunityPartnerInHeader,
  });
  const { data: originatingPartner } = useGetPartnerQuery(showOpportunityPartnerInHeader ? application.originatingPartner : '', {
    enabled: showOpportunityPartnerInHeader,
  });

  const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);

  const applicationDisplayName = useMemo(() => dataFormatters.loanDisplayNameSelector(application), [application]);

  const primaryContactFullName = useMemo(() => {
    const primaryContact = applicationPrimaryContactSelector(application);
    return primaryContact ? dataFormatters.personFullName(primaryContact) : '-';
  }, [application]);
  const opportunityAssignees = useMemo(
    () => users?.filter((user) => opportunity.assigneeIds?.includes(user.id)) ?? [],
    [opportunity, users],
  );

  const firstBorrowingBusiness = useMemo(
    () => dataFormatters.applicationBorrowingRelatedBusinessesSelector(application).at(0)?.business,
    [application],
  );

  const applicationNumber = useMemo(() => dataFormatters.applicationNumberSelector(opportunity), [opportunity]);

  useEffectOnce(() => {
    pushRecentResult(opportunityToSearchResult(opportunity));
  });

  return (
    <Flex gap={6} alignItems={'center'}>
      <BusinessImage googlePlaceId={firstBorrowingBusiness?.googlePlaceId} size={64} />
      <Flex flexDirection={'row'} width={'100%'} alignItems={'ce'}>
        <Flex flexDirection={'column'} width={'100%'}>
          <Flex justifyContent={'space-between'} alignItems={'baseline'}>
            <Flex gap={2} minWidth={0} maxWidth={shortenApplicationDisplayName ? '50%' : '100%'}>
              <Tooltip title={applicationDisplayName} placement={'top'}>
                <Text variant={'h6'} ellipsis>
                  {applicationDisplayName}
                </Text>
              </Tooltip>
            </Flex>
            <Flex gap={4} alignItems={'center'}>
              <ApplicationAssignees assignees={opportunityAssignees} />
              <ApplicationStatusWorkflowChip />
              <ApplicationActionsMenu />
            </Flex>
          </Flex>
          <Flex gap={4}>
            {applicationNumber ? <ApplicationNumber numberLabel={applicationNumber} size={'small'} /> : null}
            <BusinessInfo icon={'person'} text={primaryContactFullName} />
            {fibtDemo ? null : requestedAmount ? (
              <BusinessInfo icon={'monetization_on'} text={dataFormatters.formatValue(requestedAmount, 'currencyCompact')} />
            ) : null}
            {fibtDemo ? null : <BusinessInfo icon={'package_2'} text={prettyName ?? '-'} />}
            {showOpportunityPartnerInHeader ? (
              <>
                <BusinessInfo icon={'handshake'} text={originatingPartner?.displayName ?? '-'} />
                <BusinessInfo icon={'hub'} text={opportunityPartner?.displayName ?? '-'} />
              </>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
