import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const DealBoardIcon: FC<{ color: string }> = ({ color }) => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_21988_202682'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_21988_202682)'}>
        <path
          d={
            'M4.99997 11C4.59101 11 4.23877 10.8522 3.94327 10.5567C3.64776 10.2612 3.5 9.90897 3.5 9.5V4.99998C3.5 4.59101 3.64776 4.23878 3.94327 3.94328C4.23877 3.64776 4.59101 3.5 4.99997 3.5H9.5C9.90897 3.5 10.2612 3.64776 10.5567 3.94328C10.8522 4.23878 11 4.59101 11 4.99998V9.5C11 9.90897 10.8522 10.2612 10.5567 10.5567C10.2612 10.8522 9.90897 11 9.5 11H4.99997ZM4.99997 20.5C4.59101 20.5 4.23877 20.3522 3.94327 20.0567C3.64776 19.7612 3.5 19.4089 3.5 19V14.5C3.5 14.091 3.64776 13.7387 3.94327 13.4432C4.23877 13.1477 4.59101 13 4.99997 13H9.5C9.90897 13 10.2612 13.1477 10.5567 13.4432C10.8522 13.7387 11 14.091 11 14.5V19C11 19.4089 10.8522 19.7612 10.5567 20.0567C10.2612 20.3522 9.90897 20.5 9.5 20.5H4.99997ZM14.5 11C14.091 11 13.7387 10.8522 13.4432 10.5567C13.1477 10.2612 13 9.90897 13 9.5V4.99998C13 4.59101 13.1477 4.23878 13.4432 3.94328C13.7387 3.64776 14.091 3.5 14.5 3.5H19C19.4089 3.5 19.7612 3.64776 20.0567 3.94328C20.3522 4.23878 20.5 4.59101 20.5 4.99998V9.5C20.5 9.90897 20.3522 10.2612 20.0567 10.5567C19.7612 10.8522 19.4089 11 19 11H14.5ZM14.5 20.5C14.091 20.5 13.7387 20.3522 13.4432 20.0567C13.1477 19.7612 13 19.4089 13 19V14.5C13 14.091 13.1477 13.7387 13.4432 13.4432C13.7387 13.1477 14.091 13 14.5 13H19C19.4089 13 19.7612 13.1477 20.0567 13.4432C20.3522 13.7387 20.5 14.091 20.5 14.5V19C20.5 19.4089 20.3522 19.7612 20.0567 20.0567C19.7612 20.3522 19.4089 20.5 19 20.5H14.5ZM4.99997 9.5H9.5V4.99998H4.99997V9.5ZM14.5 9.5H19V4.99998H14.5V9.5ZM14.5 19H19V14.5H14.5V19ZM4.99997 19H9.5V14.5H4.99997V19Z'
          }
          fill={color}
        />
      </g>
    </svg>
  </Box>
);
