import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetApplicationsByStatusChartDataResponseSchema } from '@lama/reports-service-client';
import { reportsServiceClient } from '../../../../../shared/clients/reportsServiceClient';

export const useApplicationsByStatusChartData = ({
  partnerId,
  productId,
  endDate,
  startDate,
}: {
  partnerId: string | null;
  productId: string | null;
  endDate: Date | null;
  startDate: Date | null;
}): UseQueryResult<GetApplicationsByStatusChartDataResponseSchema | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getApplicationsByStatusChartData = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!partnerId || !productId || !endDate || !startDate) {
      return null;
    }

    const response = await reportsServiceClient.getApplicationsByStatusChartData(
      {
        partnerId,
        productId,
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
      },
      token,
    );

    return response;
  }, [getAccessTokenSilently, partnerId, productId, endDate, startDate]);

  const query = useQuery({
    queryKey: ['applicationsByStatusChartData', partnerId, productId, endDate, startDate],
    queryFn: getApplicationsByStatusChartData,
  });

  return query;
};
