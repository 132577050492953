import { ResponsiveBar, type BarTooltipProps } from '@nivo/bar';
import React, { type FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { GetWeeklyChartDataResponse } from '@lama/reports-service-client';
import { formatChartAxisValue } from './chartUtils.js';

interface ChartDatum {
  weekText: string;
  week: string;
  loading?: boolean;
  amount: number;
}

const tableMargins = {
  top: 20,
  right: 0,
  bottom: 90,
  left: 10,
};

const BarToolTip: FC<BarTooltipProps<ChartDatum>> = ({ formattedValue }) => (
  <Flex bg={'#ffffff'} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={1} px={2}>
    <Text variant={'body3'}>{formattedValue}</Text>
  </Flex>
);

export interface ApplicationsWeeklyChartProps {
  chartData: GetWeeklyChartDataResponse['chartData'] | null | undefined;
  color?: string;
}

export const ApplicationsWeeklyChart: FC<ApplicationsWeeklyChartProps> = ({ chartData, color }) => (
  <Flex flex={1} height={'280px'} maxHeight={'280px'} justifyContent={'center'} alignItems={'center'}>
    {!chartData?.length ? (
      <Text variant={'body2'}>{'No applications to display for the selected time range.'}</Text>
    ) : (
      <ResponsiveBar
        data={chartData}
        keys={['amount']}
        indexBy={'weekText'}
        colors={color}
        margin={tableMargins}
        padding={0.5}
        enableGridX={false}
        enableGridY={false}
        enableLabel={false}
        axisLeft={{
          tickSize: 1,
          tickPadding: -15,
          format: formatChartAxisValue,
        }}
        axisBottom={{
          tickSize: 0,
          tickRotation: 45,
        }}
        tooltip={BarToolTip}
      />
    )}
  </Flex>
);
