import React from 'react';
import type { FC } from 'react';
import { getAssetUrl } from '../../../../shared/utils/getAssetUrl';

const defaultImage = getAssetUrl('deal-photos/dealCardExample.png');

interface FullDealHeaderImageProps {
  image?: string;
}

export const FullDealHeaderImage: FC<FullDealHeaderImageProps> = ({ image }) => (
  <img
    width={'624px'}
    height={'200px'}
    style={{ objectFit: 'cover', borderRadius: '8px' }}
    src={image || defaultImage}
    alt={'Deal Image'}
  />
);
