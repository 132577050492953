import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createHandleApplicationRejectionClient } from '@lama/handle-application-rejection-client';

import { displayToast } from '@lama/app-components';
import { handleApplicationRejectionBaseUrl } from '../../../framework/environment';

const handleApplicationRejectionClient = createHandleApplicationRejectionClient(handleApplicationRejectionBaseUrl as string);

export const useSendIncompletenessLetterMutation = (opportunityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ preview = false }: { preview?: boolean }) => {
      const token = await getAccessTokenSilently();

      return handleApplicationRejectionClient.sendLetterOfIncompleteness({ preview, opportunityId }, token);
    },
    onError: () => {
      displayToast('Something went wrong while sending the email. Please contact support.', 'error');
    },
    onSuccess: async () => {
      displayToast('Email sent', 'success', { autoClose: 2000 });
      await queryClient.invalidateQueries({
        queryKey: ['opportunity', opportunityId],
      });

      await queryClient.invalidateQueries({
        queryKey: ['opportunityAlerts', opportunityId],
      });
    },
  });
};
