import React from 'react';
import type { FC } from 'react';
import { CreateEntityAuditLog } from '../CreateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { ApplicationUpdateAuditLog } from './ApplicationUpdateAuditLog';

export const ApplicationAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'Update') {
    return <ApplicationUpdateAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Create') {
    return <CreateEntityAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  return null;
};
