import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { CellContent } from '../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';

const headers = [
  { text: 'Name', width: 30 },
  { text: 'Signature', width: 14 },
  { text: 'Date', width: 14 },
];

const titles = [
  'VP, Business Development Officer',
  'VP, Credit Officer SBA',
  'Director of Commercial Real Estate Lending & Director of Sales-SBA & USDA Loans',
  'SVP, Credit Director-SBA',
  'First SVP, Managing Director Government Guaranteed Lending',
];

const rows = titles.map<CellContent[]>((item) => [{ value: item }, { value: null, align: 'right' }, { value: null, align: 'right' }]);

export const HanoverApprovedSection: FC = () => (
  <Flex flexDirection={'column'} gap={8}>
    <CreditMemoTable headers={headers} rows={rows} />
    <InlineNote title={'Payment Reserves'} noteName={'Payment Reserves'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'Funds Availability'} noteName={'Funds Availability'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'Repayment Source'} noteName={'Repayment Source'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'Renewal Options'} noteName={'Renewal Options'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'SBA Eligible'} noteName={'SBA Eligible'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'CRA Eligible'} noteName={'CRA Eligible'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote
      title={'Risk Rating & Recommendations'}
      noteName={'Risk Rating & Recommendations'}
      valueTemplate={undefined}
      placeholder={undefined}
    />
  </Flex>
);
