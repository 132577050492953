import React, { useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';
import type { CreateCondition } from '@lama/clients';
import type { Condition } from '@lama/contracts';
import { Flex, Text, Button, greyPalette } from '@lama/design-system';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { ConditionConfiguration } from '@lama/conditions';
import { getConditionConfig } from '@lama/conditions';
import { LoadingPage } from '@lama/app-components';
import { useProductQuery } from '../../../../../shared/hooks/react-query/product/useProductQuery';
import { useUpdateConditionMutation } from '../hooks/useUpdateConditionMutation';
import { useAddConditionMutation } from '../hooks/useAddConditionMutation';
import { AppetiteEmptyState } from './AppetiteEmptyState';
import { AddConditionMenu } from './ConditionComponents/ConditionV2Components/AddConditionMenu';
import { ConditionCardV2 } from './ConditionComponents/ConditionV2Components/ConditionCardV2';

export const AppetiteSettingsV2 = () => {
  const { productId, conditionId } = useParams<{ productId?: string; conditionId?: string }>();
  const { data: product, isPending: loadingProduct } = useProductQuery(productId);

  const navigate = useNavigate();

  const { mutateAsync: updateCondtition } = useUpdateConditionMutation(productId);

  const { mutateAsync: addCondition } = useAddConditionMutation(productId);

  const onAddCondition = useCallback(
    async (condition: CreateCondition) => {
      await addCondition({ conditionPayload: condition });
      navigate(`./${condition.id}`);
    },
    [addCondition, navigate],
  );

  const onUpdateCondition = useCallback(
    async (condition: Condition) => {
      const { id, ...conditionPayload } = condition;
      await updateCondtition({ conditionId: id, conditionPayload });
    },
    [updateCondtition],
  );

  const menuRef = useRef(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);

  const conditionsRecord = useMemo(
    () =>
      Object.fromEntries(
        product?.conditions?.map((condition) => {
          const config = getConditionConfig(condition);

          return [condition.id, { condition, config }];
        }) ?? [],
      ) as Record<string, { condition: Condition; config: ConditionConfiguration }>,
    [product?.conditions],
  );

  if (loadingProduct) {
    return <LoadingPage />;
  }

  if (conditionId) {
    return <Outlet />;
  }

  return (
    <>
      <Flex flexDirection={'column'} gap={8}>
        <Flex flex={1} border={`0.5px solid ${greyPalette[300]}`} height={'1px'} borderRadius={4} />
        <Flex alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
          <Text variant={'body2'} color={greyPalette[700]}>
            {'Applications failing '}
            <span style={{ fontWeight: 700 }}>{'any'}</span>
            {' of these rules won’t be eligible for this loan product.'}
          </Text>
          <Button ref={menuRef} variant={'secondary'} color={'primary'} size={'l'} onClick={toggleMenuOpen}>
            {'Add Rule'}
          </Button>
        </Flex>
        {Object.keys(conditionsRecord)?.length ? (
          Object.values(conditionsRecord).map(({ condition, config }) => (
            <ConditionCardV2 key={condition.id} condition={condition} config={config} onChange={onUpdateCondition} />
          ))
        ) : (
          <AppetiteEmptyState />
        )}
      </Flex>
      <AddConditionMenu menuRef={menuRef} open={menuOpen} onClose={toggleMenuOpen} addCondition={onAddCondition} />
    </>
  );
};
