import React from 'react';
import type { FC } from 'react';
import { Skeleton } from '@mui/material';
import { Flex, Text } from '@lama/design-system';

const IndicatorSkeleton: FC = () => (
  <Flex justifyContent={'space-between'} width={'100%'} gap={6} flex={1}>
    <Flex gap={2} alignItems={'center'} flex={0.45}>
      <Text variant={'body2'} color={'text.secondary'}>
        <Skeleton width={'150px'} />
      </Text>
    </Flex>
    <Flex flex={0.55} gap={2} alignItems={'center'} minWidth={0}>
      <Skeleton variant={'circular'} width={'16px'} height={'16px'} />
      <Text variant={'body2'}>
        <Skeleton width={'60px'} />
      </Text>
    </Flex>
  </Flex>
);

export const KeyIndicatorsSkeleton: FC = () => (
  <Flex minWidth={0} gap={4} flexDirection={'column'}>
    <Text variant={'body1'} color={'text.primary'}>
      {'Key Indicators'}
    </Text>
    {Array.from({ length: 13 }, (_v, i) => (
      <IndicatorSkeleton key={i} />
    ))}
  </Flex>
);
