import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import type { GenerateReportBody, ReportType } from '@lama/reports-service-client';
import { reportsServiceClient } from '../../../shared/clients/reportsServiceClient';

export const downloadReport = async (filename: string, body: GenerateReportBody, token: string) => {
  const response = await reportsServiceClient.generateReport(body, token);
  return response.data;
};

export const useDownloadReportMutation = (reportType: ReportType) => {
  const { getAccessTokenSilently } = useAuth0();

  const mutation = useMutation({
    mutationFn: async ({ body, filename }: { body: Omit<GenerateReportBody, 'reportType'>; filename: string }) => {
      const token = await getAccessTokenSilently();
      return downloadReport(filename, { reportType, ...body }, token);
    },
  });

  return mutation;
};
