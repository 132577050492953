import { useContext } from 'react';
import { useAsync } from 'react-use';
import { setUser } from '@sentry/react';
import { UserDetailsContext } from '../context/UserDetailsContext';

export const useSentryIdentification = () => {
  const user = useContext(UserDetailsContext);

  useAsync(async () => {
    if (process.env.NODE_ENV === 'production') {
      const { email, firstName, lastName, partner, segments, type } = user;

      const name = firstName && lastName ? `${firstName} ${lastName}` : email;

      if (name && partner && email && type) {
        setUser({
          name,
          email,
          partner,
          type,
          segments: JSON.stringify(segments),
        });
      }
    }
  }, [user]);
};
