import { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { PlaceApiResult } from '../../components/Application/GooglePlacesServiceContext';
import { GooglePlacesServiceContext } from '../../components/Application/GooglePlacesServiceContext';

export const useGetGooglePlacePhotoUrl = (placeId?: string) => {
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const { useGooglePlaces } = useFlags();

  const placesService = useContext(GooglePlacesServiceContext);

  useEffect(() => {
    if (navigator.userAgent.includes('DatadogSynthetics') || !useGooglePlaces) {
      setPhotoUrl(null);
      return;
    }

    if (placeId) {
      const cachedPhoto = sessionStorage.getItem(`photosCache.${placeId}`);
      if (cachedPhoto) {
        setPhotoUrl(cachedPhoto);
      } else if (placesService) {
        placesService.getDetails({ placeId, fields: ['photos'] }, (placeDetails: PlaceApiResult) => {
          const photoUrlInner = placeDetails?.photos?.[0]?.getUrl({ maxWidth: 200 });

          if (photoUrlInner) {
            sessionStorage.setItem(`photosCache.${placeId}`, photoUrlInner);
          }

          setPhotoUrl(photoUrlInner ?? null);
        });
      }
    }
  }, [photoUrl, placeId, placesService, useGooglePlaces]);

  return photoUrl;
};
