import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { HideOnPrintWrapper, Tooltip } from '@lama/app-components';
import { groupBy } from 'lodash-es';
import { InfoOutlined } from '@mui/icons-material';
import type { UnderwritingNote } from '@lama/contracts';
import { compareAsc } from 'date-fns';
import { Divider } from '@mui/material';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import type { UnderwritingNoteRelatedSectionType } from '../../../components/Application/shared/noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../../../components/Application/shared/noteUtils';
import { CommentPlaceholder } from './CommentPlaceholder';
import { Thread } from './Thread';

export interface CommentsSegmentProps {
  relatedItemType: UnderwritingNoteRelatedSectionType;
  relatedItemId: string;
  showExportHelperText?: boolean;
}

export const CommentsSegment: FC<CommentsSegmentProps> = ({ relatedItemType, relatedItemId, showExportHelperText = true }) => {
  const {
    opportunity: { underwriting },
  } = useContext(ApplicationContext);

  const threads = useMemo(() => {
    const relevantNotes =
      underwriting?.notes?.filter(
        (note) => note[getUnderwritingNoteRelatedItemProperty(relatedItemType)] === relatedItemId && note.type === 'general',
      ) ?? [];

    const notesByThread = groupBy(relevantNotes, 'threadId');

    return Object.entries(notesByThread).map<[string, UnderwritingNote[]]>(([threadId, notes]) => [
      threadId,
      notes.sort((a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt))),
    ]);
  }, [relatedItemId, relatedItemType, underwriting?.notes]);

  return (
    <HideOnPrintWrapper>
      <Flex flexDirection={'column'} gap={4}>
        <Divider />
        <Flex alignItems={'center'} gap={2}>
          <Text variant={'body2'}>{'Comments'}</Text>
          {showExportHelperText ? (
            <Tooltip placement={'right'} title={"These internal comments won't be exported"}>
              <InfoOutlined sx={{ width: '16px', color: greyPalette[500] }} />
            </Tooltip>
          ) : null}
        </Flex>
        <Flex flexDirection={'column'} gap={4}>
          {threads.map(([threadId, comments]) => (
            <Thread
              relatedItemType={relatedItemType}
              relatedItemId={relatedItemId}
              key={threadId}
              threadNotes={comments}
              threadId={threadId}
            />
          ))}
          <CommentPlaceholder relatedItemType={relatedItemType} relatedItemId={relatedItemId} startInEditMode={!threads.length} />
        </Flex>
      </Flex>
    </HideOnPrintWrapper>
  );
};
