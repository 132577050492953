import { Flex, Input, Button, ModalContent, ModalFooter, Modal } from '@lama/design-system';
import type { FC, ChangeEvent } from 'react';
import React, { useState, useCallback } from 'react';
import { useAuthenticateWithEtran } from '../../../../shared/hooks/react-query/etran/useAuthenticateWithEtran';
import type { EtranCredentials, EtranDialogProps } from './types';

export interface EtranUsernamePasswordProps extends EtranDialogProps<EtranCredentials> {
  onlyCollectCredentials?: boolean;
  buttonTitle: string;
}

export const EtranUsernamePassword: FC<EtranUsernamePasswordProps> = ({
  open,
  onClose,
  onSuccess,
  onlyCollectCredentials,
  buttonTitle,
}) => {
  const { mutateAsync: authenticateWithEtran, isPending: isAuthenticating } = useAuthenticateWithEtran();
  const [loginDetails, setLoginDetails] = useState({ username: '', password: '' });
  const onUserNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLoginDetails((prev) => ({ ...prev, username: e.target.value }));
  }, []);
  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLoginDetails((prev) => ({ ...prev, password: e.target.value }));
  }, []);
  const onLoginClick = useCallback(async () => {
    if (!onlyCollectCredentials) {
      await authenticateWithEtran(loginDetails);
    }
    setLoginDetails({ username: '', password: '' });
    onSuccess(loginDetails);
  }, [authenticateWithEtran, loginDetails, onSuccess, onlyCollectCredentials]);
  return (
    <Modal subtitle={'Enter your SBA portal credentials'} open={open} onClose={onClose} title={'Pull SBSS Score'} size={'s'} fullWidth>
      <ModalContent>
        <Flex gap={2} flexDirection={'column'}>
          <Input value={loginDetails.username} label={'User name'} onChange={onUserNameChange} />
          <Input value={loginDetails.password} label={'Password'} type={'password'} onChange={onPasswordChange} />
        </Flex>
      </ModalContent>
      <ModalFooter>
        <Button loading={isAuthenticating} onClick={onLoginClick}>
          {buttonTitle}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
