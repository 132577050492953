import React from 'react';
import type { FC } from 'react';
import type { PointTooltipProps } from '@nivo/line';
import { format } from 'date-fns';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';

export const BalanceTooltip: FC<PointTooltipProps> = ({ point: { data } }) => (
  <Flex
    backgroundColor={'#616161E5'}
    borderRadius={'4px'}
    alignItems={'center'}
    justifyContent={'center'}
    py={1}
    px={2}
    flexDirection={'column'}
  >
    <Text variant={'body2'} color={'white'}>
      {formatValue(data.y, 'currency')}
    </Text>
    <Text variant={'body4'} color={'white'}>
      {format(data.x as Date, 'MMM d, yyyy')}
    </Text>
  </Flex>
);
