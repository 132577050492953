import React from 'react';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Stack, Typography } from '@mui/material';
import { opportunityRequirementStatus } from '@lama/contracts';

const requirementStatusesWithNoneAndSubmitted = ['None', 'Submitted', ...opportunityRequirementStatus] as const;

export type RequirementStatusWithNone = (typeof requirementStatusesWithNoneAndSubmitted)[number];

export const requirementStatusToTextMapping: Record<RequirementStatusWithNone | 'None', string> = {
  PendingBorrower: 'Pending Applicant',
  PendingReview: 'Pending Lender',
  Dismissed: 'Dismissed By Applicant',
  DismissedByLender: 'Dismissed',
  Complete: 'Complete',
  Processing: 'Processing',
  None: 'None',
  AwaitingFutureStatus: 'Not Yet Applicable',
  Submitted: 'Submitted',
};

export const statusColorMapping: Record<RequirementStatusWithNone | 'None', string> = {
  PendingBorrower: '#0288D1',
  PendingReview: '#FFA000',
  Dismissed: grey[500],
  DismissedByLender: grey[500],
  Complete: '#44CFA6',
  Submitted: '#44CFA6',
  Processing: '#0288D1',
  None: grey[500],
  AwaitingFutureStatus: grey[500],
};

export const statusToBgColorMapping: Record<RequirementStatusWithNone | 'None', string> = {
  PendingBorrower: '#E1F5FE',
  PendingReview: '#FFF8E1',
  Dismissed: grey[100],
  DismissedByLender: grey[100],
  Complete: '#E2FBF3',
  Submitted: '#E2FBF3',
  Processing: '#E1F5FE',
  None: grey[100],
  AwaitingFutureStatus: grey[100],
};

export const StyledStatusBadge = styled.div<{ status: RequirementStatusWithNone; size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ status }) => statusColorMapping[status]};
  border-radius: 50%;
  display: inline-block;
`;

export const RequirementStatusBadge: React.FC<{ status: RequirementStatusWithNone; showText?: boolean; size?: string }> = ({
  status,
  showText = true,
  size = '12px',
}) => (
  <Stack direction={'row'} spacing={1} alignItems={'center'}>
    <StyledStatusBadge status={status} size={size} />
    {showText ? (
      <Typography width={'128px'} variant={'body2'}>
        {requirementStatusToTextMapping[status]}
      </Typography>
    ) : null}
  </Stack>
);
