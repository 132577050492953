import type { FC } from 'react';
import React from 'react';
import { Tooltip, useTheme } from '@mui/material';
import type { PersonApiModel } from '@lama/clients';
import { grey } from '@mui/material/colors';
import { Flex, Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import PassIcon from './assets/Pass.svg';
import FailIcon from './assets/Fail.svg';
import UnknownIcon from './assets/Unknown.svg';

const verificationToIcon = (verification?: 'Fail' | 'Pass' | 'Requires Investigation' | 'Unknown') => {
  switch (verification) {
    case 'Unknown': {
      return <UnknownIcon />;
    }
    case 'Pass': {
      return <PassIcon />;
    }
    case 'Fail': {
      return <FailIcon />;
    }
    case 'Requires Investigation': {
      return <FailIcon />;
    }
    default: {
      return <UnknownIcon />;
    }
  }
};

const SSNCheck: FC<{ person: PersonApiModel }> = ({ person: { verifications } }) => (
  <Flex gap={3} alignItems={'center'}>
    {verificationToIcon(verifications?.ssn)}
    <Text variant={'body1'} color={grey[500]}>
      {`SSN Check: ${verifications?.ssn ?? 'Unknown'}`}
    </Text>
  </Flex>
);

const AddressCheck: FC<{ person: PersonApiModel }> = ({ person: { verifications } }) => (
  <Flex gap={3} alignItems={'center'}>
    {verificationToIcon(verifications?.address)}
    <Text variant={'body1'} color={grey[500]}>
      {`Address Check: ${verifications?.address ?? 'Unknown'}`}
    </Text>
  </Flex>
);

const AdditionalAlertsCheck: FC<{ person: PersonApiModel }> = ({ person: { thirdPartyData } }) => {
  const theme = useTheme();

  const hasAdditionalAlerts = thirdPartyData?.creditReport?.fraudShieldIndicators?.length;

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex gap={3} alignItems={'center'}>
        {verificationToIcon(hasAdditionalAlerts ? 'Requires Investigation' : 'Pass')}
        <Text variant={'body1'} color={grey[500]}>
          {`Additional Alerts Check: ${hasAdditionalAlerts ? 'Requires Investigation' : 'No Alerts Found'}`}
        </Text>
      </Flex>
      {hasAdditionalAlerts ? (
        <Flex flexDirection={'column'} gap={2} pl={9}>
          {thirdPartyData?.creditReport?.fraudShieldIndicators?.map((indicator) => (
            <Text key={indicator.code} variant={'body1'} color={theme.palette.error.light}>
              {indicator.text}
            </Text>
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
};

export const PersonFraudShieldAlertsSegment: FC<{ person: PersonApiModel }> = ({ person }) => (
  <Flex gap={4} justifyContent={'space-between'}>
    <Flex gap={4} flexDirection={'column'}>
      <SSNCheck person={person} />
      <AddressCheck person={person} />
      <AdditionalAlertsCheck person={person} />
    </Flex>
    <Flex flexDirection={'column'}>
      <Tooltip title={formatValue(person.thirdPartyData?.creditReport?.ficoScoreDate, 'datetime')}>
        <Text variant={'body2'} color={grey[500]}>
          {`Lookup date: ${formatValue(person.thirdPartyData?.creditReport?.ficoScoreDate, 'date') || '-'}`}
        </Text>
      </Tooltip>
    </Flex>
  </Flex>
);
