import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Text } from '@lama/design-system';
import type { DecisionReason } from '@lama/contracts';
import { Autocomplete, MenuItem, TextField } from '@mui/material';

export interface KnockoutReasonsAutocompleteProps {
  reasons: DecisionReason[];
  setReason: (reason: DecisionReason | null) => void;
  reason: DecisionReason | null;
  disabled?: boolean;
}

export const KnockoutReasonsAutocomplete: FC<KnockoutReasonsAutocompleteProps> = ({ reasons, setReason, reason, disabled }) => {
  const onReasonChange = useCallback(
    (event: React.ChangeEvent<{}>, value: DecisionReason | null) => {
      setReason(value ?? null);
    },
    [setReason],
  );

  const renderReasonInput = useCallback(
    (props: any) => <TextField id={'reasons'} name={'reasons'} label={'Reason for fail'} placeholder={'Reason for declining'} {...props} />,
    [],
  );

  const renderReasonOption = useCallback(
    (props: any, reasonOption: DecisionReason) => (
      <MenuItem name={reasonOption.id} direction={'row'} gap={1} {...props}>
        <Text variant={'body1'} ellipsis>
          {reasonOption.text}
        </Text>
      </MenuItem>
    ),
    [],
  );

  const getReasonOptionLabel = useCallback((option: DecisionReason) => option.text, []);

  return (
    <Autocomplete
      id={'reasons'}
      value={reason}
      onChange={onReasonChange}
      renderInput={renderReasonInput}
      options={reasons}
      renderOption={renderReasonOption}
      getOptionLabel={getReasonOptionLabel}
      limitTags={1}
      disabled={disabled}
      fullWidth
    />
  );
};
