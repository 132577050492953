import React, { useCallback, useRef } from 'react';
import type { FC } from 'react';
import type { DealApiModel } from '@lama/contracts';
import { Stack, Typography, Button, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { sentenceCase } from 'change-case-all';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperRef } from 'swiper';
import { noMatchRegex } from '@lama/data-formatters';
import { DealCard, dealCardWidth } from './DealCard';
import 'swiper/css';

interface DealCategoryCarouselProps {
  category: string;
  categoryTitle?: string;
  deals: DealApiModel[];
}

const NextPrevButtonStyle = {
  borderRadius: '4px',
  backgroundColor: 'white',
  width: '40px',
  height: '40px',
  border: '1px solid #E0E0E0',
};

const NextDealButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => (
  <IconButton onClick={onClick} disabled={disabled} sx={NextPrevButtonStyle}>
    <ChevronRight />
  </IconButton>
);

const PreviousDealButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => (
  <IconButton onClick={onClick} disabled={disabled} sx={NextPrevButtonStyle}>
    <ChevronLeft />
  </IconButton>
);

export const DealCategoryCarousel: FC<DealCategoryCarouselProps> = ({ category, deals, categoryTitle }: DealCategoryCarouselProps) => {
  const swiperRef = useRef<SwiperRef>();

  const onNextClick = useCallback(() => {
    swiperRef.current?.slideNext(300);
  }, []);

  const onPreviousClick = useCallback(() => {
    swiperRef.current?.slidePrev(300);
  }, []);

  const onSwiperInit = useCallback((swiper: SwiperRef) => {
    swiperRef.current = swiper;
  }, []);

  return (
    <Stack key={category} gap={2} width={'100%'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h6'}>{sentenceCase(categoryTitle ?? category, { stripRegexp: noMatchRegex })}</Typography>
        <Stack direction={'row'} gap={3} alignItems={'center'}>
          {deals.length > 3 && (
            <Link to={`/deals?category=${encodeURIComponent(category)}`} style={{ textDecoration: 'none' }}>
              <Button variant={'outlined'} sx={{ height: '40px' }}>
                {'Show all'}
              </Button>
            </Link>
          )}
          <Stack direction={'row'} gap={2}>
            <PreviousDealButton onClick={onPreviousClick} />
            <NextDealButton onClick={onNextClick} />
          </Stack>
        </Stack>
      </Stack>
      <Box maxWidth={'100%'}>
        <Swiper onSwiper={onSwiperInit} slidesPerView={'auto'} spaceBetween={32}>
          {deals.map((deal) => (
            <SwiperSlide key={`${category}_${deal.id}`} style={{ width: dealCardWidth }}>
              <DealCard deal={deal} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Stack>
  );
};
