/* eslint-disable react/jsx-no-bind */
import { Button, Flex, Modal, ModalContent, ModalFooter, Text } from '@lama/design-system';
import { Autocomplete, Divider, Fade, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import { displayToast } from '@lama/app-components';
import styled from 'styled-components';
import { ChevronRight } from '@mui/icons-material';
import { useAsyncFn } from 'react-use';

const renderInput = (props: any) => <TextField label={'Product'} {...props} />;

const productOptions = ['Product A', 'Product B', 'Product C'];

const ProductSelectionModal: FC<{ open: boolean; onClose: (success?: boolean) => void }> = ({ open, onClose }) => {
  const [selectedProduct, setSelectedProduct] = React.useState<string | null>(null);

  const [{ loading }, onButtonClick] = useAsyncFn(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    onClose(true);
  }, [onClose]);

  const onSelectionChange = useCallback((_e: any, value: string | null) => {
    setSelectedProduct(value);
  }, []);

  return (
    <Modal title={'Apply product'} open={open} onClose={onClose} subtitle={'Select the product to apply:'} fullWidth>
      <ModalContent>
        <Autocomplete value={selectedProduct} renderInput={renderInput} options={productOptions} onChange={onSelectionChange} fullWidth />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onButtonClick} loading={loading} disabled={!selectedProduct}>
          {'Apply'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const StyledFloatingActionCenter = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

export const RowSelectionFab: FC<{ selectedRowsCount: number }> = ({ selectedRowsCount }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const onButtonClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onModalClose = useCallback((success?: boolean) => {
    setModalOpen(false);
    if (success) {
      displayToast('Product applied', 'success');
    }
  }, []);

  return (
    <>
      <Fade in={!!selectedRowsCount} timeout={300}>
        <Flex justifyContent={'center'} width={'100%'} position={'fixed'} bottom={'20px'}>
          <StyledFloatingActionCenter>
            <Text variant={'body2'} paddingRight={3}>{`${selectedRowsCount} selected`}</Text>
            <Divider orientation={'vertical'} flexItem />
            <Button color={'primary'} variant={'tertiary'} onClick={onButtonClick} endIcon={<ChevronRight />}>
              {'Choose Action'}
            </Button>
          </StyledFloatingActionCenter>
        </Flex>
      </Fade>
      {modalOpen ? <ProductSelectionModal open={modalOpen} onClose={onModalClose} /> : null}
    </>
  );
};
