import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ProductApiModel } from '@lama/contracts';
import { productServiceClient } from '../../../clients/productService';

export const useProductQuery = (
  productId: string | undefined,
  options?: UseQueryOptions<ProductApiModel | null>,
): UseQueryResult<ProductApiModel | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getProductQuery = useCallback(async () => {
    if (!productId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const product = await productServiceClient.getProduct(productId, token);

    return product;
  }, [productId, getAccessTokenSilently]);

  const query = useQuery({ queryKey: ['product', productId], queryFn: getProductQuery, ...options });

  return query;
};
