import type { RelatedApplicationMatch } from '@lama/contracts';
import { min, sortBy } from 'lodash-es';

const personMatchingKeyPriority = ['SSN', 'USCIS', 'Email', 'Phone Number'];

const selectPersonMatch = (matches: RelatedApplicationMatch[]) => {
  const matchesByHighestMatchingKey = matches.map((match) => ({
    match,
    highestPriorityMatchingKeyIndex: min(
      match.matchingKeys.map((key) =>
        !personMatchingKeyPriority.includes(key) ? Number.POSITIVE_INFINITY : personMatchingKeyPriority.indexOf(key),
      ),
    ),
  }));

  const match = sortBy(matchesByHighestMatchingKey, ({ highestPriorityMatchingKeyIndex }) => highestPriorityMatchingKeyIndex)[0]?.match;

  return match ?? matches[0];
};

export const selectMatch = (matches: RelatedApplicationMatch[]) => {
  const businessesRelations = matches.filter(({ entityType }) => entityType === 'business');
  const peopleRelations = matches.filter(({ entityType }) => entityType === 'person');

  if (!businessesRelations.length && !peopleRelations.length) {
    return null;
  }

  return businessesRelations[0] ?? selectPersonMatch(peopleRelations);
};

export const selectedPrimaryMatchingKey = (match: RelatedApplicationMatch) => {
  const { entityType, matchingKeys } = match;

  const primaryMatchingKey =
    entityType === 'business' ? matchingKeys[0] : personMatchingKeyPriority.find((key) => matchingKeys.includes(key)) ?? matchingKeys[0];

  return primaryMatchingKey;
};
