/* eslint-disable react/no-array-index-key */
import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Spinner, Text } from '@lama/design-system';
import { compareAsc } from 'date-fns';
import { ApplicationContext } from '../../ApplicationContext';
import { useRelatedApplicationsQuery } from '../../../../shared/hooks/react-query/application/useRelatedApplicationsQuery';
import { OverviewSection } from '../OverviewColumn';
import { RelatedApplicationRow } from './RelatedApplicationRow';

export const RelatedApplications: FC = () => {
  const { application } = useContext(ApplicationContext);
  const { data: relatedApplications, isPending: loadingRelatedApplications } = useRelatedApplicationsQuery(application.id);

  const title = useMemo(() => `Related Applications (${relatedApplications?.length})`, [relatedApplications]);

  const openOpportunityInNewTab = useCallback((opportunityId: string) => {
    window.open(`/pipeline/${opportunityId}`, '_blank');
  }, []);

  const sortedRelatedApplications = useMemo(
    () =>
      relatedApplications?.sort((a, b) =>
        compareAsc(new Date(b.application.lastStatusUpdatedAt), new Date(a.application.lastStatusUpdatedAt)),
      ),
    [relatedApplications],
  );

  if (loadingRelatedApplications) {
    return <Spinner />;
  }

  return (
    <OverviewSection>
      <Flex flexDirection={'column'} gap={6} overflow={'hidden'} id={'relatedApplicationsSegment'}>
        <Text variant={'body1'}>{title}</Text>
        <Flex flexDirection={'column'}>
          {sortedRelatedApplications?.length ? (
            sortedRelatedApplications?.map((relatedApplication, index) => (
              <RelatedApplicationRow
                key={`${application.id}-${index}`}
                relatedApplication={relatedApplication}
                onClick={openOpportunityInNewTab}
              />
            ))
          ) : (
            <Text variant={'body3'} color={'secondary'}>
              {'No related applications Found'}
            </Text>
          )}
        </Flex>
      </Flex>
    </OverviewSection>
  );
};
