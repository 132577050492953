/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

const headers = [
  {
    text: 'Condition',
  },
  {
    text: 'Required',
  },
  {
    text: 'Description',
  },
];

const data = [
  {
    condition: 'Business Interruption Insurance',
    required: 'At Closing',
    description: 'Business interruption insurance in the amount of actual loss sustained',
  },
  {
    condition: 'Business/Personal Property Insurance',
    required: 'At Closing',
    description: 'Replacement Cost',
  },
  {
    condition: "Workers' Compensation insurance",
    required: 'At Closing',
    description: 'Minimum state Required',
  },
  {
    condition: 'Tax Return Verification',
    required: 'At Closing',
    description: 'Tax Transcripts for 2020, 2021, and 2022',
  },
  {
    condition: 'Credit rating on note to be refinanced',
    required: 'At Closing',
    description: 'Payoff letter for debt to be refinanced',
  },
  {
    condition: 'Construction Contract',
    required: 'At Closing',
    description: 'Contract as well as cost breakdown, plans and specs, and proof of payment to date',
  },
  {
    condition: 'YTD Business Financials',
    required: 'At Closing',
    description: 'P&L and balance sheet that reflects newly acquired business debt',
  },
  {
    condition: 'Landlord Waiver',
    required: 'At Closing',
    description: 'Landlord Consent and Waiver',
  },
  {
    condition: 'Other',
    required: 'At Closing',
    description: 'Final site inspection by 3rd party (to be hired by Grasshopper Bank)',
  },
  {
    condition: 'Other',
    required: 'At Closing',
    description: 'G/L insurance',
  },
  {
    condition: 'Other',
    required: 'At Closing',
    description: 'All appropriate licenses needed to operate the business',
  },
];

export const GrasshopperConditionsTableSegment: FC = () => {
  const rows: CellContent[][] = useMemo(
    () =>
      data.map((item) => [
        {
          value: item.condition,
        },
        {
          value: item.required,
        },
        {
          value: item.description,
        },
      ]),
    [],
  );

  return (
    <SegmentContainer title={'Conditions'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
