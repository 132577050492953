import React, { useCallback, useMemo } from 'react';
import { Card, Typography, Stack } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { ArrowForward } from '@mui/icons-material';
import type { ProductTemplateApiModel } from '@lama/contracts';
import { Tooltip } from '@lama/app-components';
import { getAssetUrl } from '../../../shared/utils/getAssetUrl';

interface ProductCardProps {
  product: ProductTemplateApiModel;
  cta: string;
  chip?: ReactElement;
  onClick?: (productId: string) => void;
}

const productBackgroundMap: Record<string, string> = {
  term: 'termCardBg',
  sba: 'sbaCardBg',
  lineOfCredit: 'lineOfCreditCardBg',
  invoice: 'invoiceCardBg',
  inventory: 'inventoryCardBg',
  equipment: 'equipmentCardBg',
  cre: 'creCardBg',
};

const getRandomProductBackground = () =>
  productBackgroundMap[Object.keys(productBackgroundMap)[Math.floor(Math.random() * Object.keys(productBackgroundMap).length)]!];

export const ProductCardBase: FC<ProductCardProps> = ({ product, cta, chip, onClick }) => {
  const cardBgImage = useMemo(() => {
    const bgKey = Object.keys(productBackgroundMap).find((name) => product.name.toLowerCase().includes(name));

    const actualKey = bgKey ? productBackgroundMap[bgKey] : getRandomProductBackground();

    return getAssetUrl(`${actualKey}.png`);
  }, [product.name]);

  const handleClick = useCallback(() => {
    onClick?.(product.id);
  }, [onClick, product.id]);

  return (
    <Card
      elevation={0}
      sx={{
        padding: '36px 32px',
        width: '310px',
        aspectRatio: '2/1',
        backgroundImage: `url(${cardBgImage})`,
        backgroundSize: 'contain',
        borderRadius: '10px',
        cursor: 'pointer',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: '0px 8px 33px 0px #3800641F',
        },
      }}
      onClick={handleClick}
    >
      <Stack gap={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Tooltip title={product.prettyName} placement={'top'}>
            <Typography variant={'h6'} color={'text.primary'} noWrap>
              {product.prettyName}
            </Typography>
          </Tooltip>
          {chip}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography variant={'body1'} color={'primary'}>
            {cta}
          </Typography>
          <ArrowForward color={'primary'} />
        </Stack>
      </Stack>
    </Card>
  );
};
