import React from 'react';
import { Chip } from '@mui/material';
import { greyPalette } from '@lama/design-system';
import type { EnvelopeStatus } from '@lama/docusign-client';

const chipStyles = {
  created: { bgcolor: '#FFF8E1', color: '#FFA000', borderColor: '#FFF8E1' },
  completed: { bgcolor: '#FFF8E1', color: '#FFA000', borderColor: '#FFF8E1' },
  sent: { bgcolor: '#E1F5FE', color: '#0288D1', borderColor: '#E1F5FE' },
  declined: { bgcolor: '#FEEBEE', color: '#EF5350', borderColor: '#FEEBEE' },
  voided: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[100] },
};

const statusToChip: Record<NonNullable<EnvelopeStatus>, { label: string; style: {} }> = {
  completed: { label: 'Completed', style: chipStyles.completed },
  signed: { label: 'Signed', style: chipStyles.completed },
  created: { label: 'Created', style: chipStyles.created },
  declined: { label: 'Declined', style: chipStyles.declined },
  deleted: { label: 'Deleted', style: chipStyles.voided },
  voided: { label: 'Voided', style: chipStyles.voided },
  delivered: { label: 'Delivered', style: chipStyles.sent },
  sent: { label: 'Sent', style: chipStyles.sent },
};

export const EnvelopeStatusChip = ({ status, size }: { status: NonNullable<EnvelopeStatus>; size?: 'medium' | 'small' }) => (
  <Chip label={statusToChip[status].label} size={size} variant={'outlined'} sx={statusToChip[status].style} />
);
