import { v4 as uuidv4 } from 'uuid';
import type { SpreadingDocument } from '@lama/document-service-client';
import type { ApplicationCreateApiModel, PersonCreateApiModel } from '@lama/clients';
import type { CreateBusinessModelApi } from '@lama/business-service-client';
import { applicationServiceClient } from '../../../../shared/clients/applicationServiceClient';
import { documentServiceClient } from '../../../../shared/clients/documentServiceClient';
import type { UserDetails } from '../../../../shared/context/UserDetailsContext';
import { businessServiceClient } from '../../../../shared/clients/businessServiceClient';
import { peopleServiceClient } from '../../../../shared/clients/peopleServiceClient';
import { userServiceClient } from '../../../../shared/clients/userServiceClient';
import { documentDataExtractorServiceClient } from '../../../../shared/clients/documentDataExtractorServiceClient';

export const copytoSpreadingTestsPartner = async (user: UserDetails, currentDocument: SpreadingDocument, token: string) => {
  if (!user.userId) {
    throw new Error('User does not have an ID');
  }

  if (!currentDocument.filename.toLowerCase().endsWith('.pdf')) {
    return;
  }

  const personId = uuidv4();
  const targetApplicationId = uuidv4();
  const businessId = uuidv4();
  const targetDocumentId = uuidv4();
  const partnerId = 'spreadingsamples';
  const productId = 'ada459aa-9cba-4561-8f92-2974fffb588c';

  const personToCreate: PersonCreateApiModel = {
    firstName: user?.firstName ?? user.email ?? 'Spreader',
    lastName: user?.lastName ?? 'Spreader',
    email: user.email,
    id: personId,
    applicationId: targetApplicationId,
    partnerId,
  };

  const businessToCreate: CreateBusinessModelApi = {
    id: businessId,
    applicationId: targetApplicationId,
    partnerId,
    people: [
      {
        ...personToCreate,
        guarantor: true,
      },
    ],
    legalName: targetDocumentId,
    description: `copied to spreading partner from document ${currentDocument.id}`,
  };

  const createApplicationPayload: ApplicationCreateApiModel = {
    id: targetApplicationId,
    initiator: personId,
    requestedAmount: 10_000,
    originalProductId: productId,
    originatingPartner: partnerId,
    metadata: { sampleName: targetDocumentId },
    relatedBusinesses: [],
    relatedPeople: [],
  };

  await applicationServiceClient.createApplication({ application: createApplicationPayload, token });
  await peopleServiceClient.createPerson(personToCreate, token);
  await userServiceClient.addPersonIdToUser(user.userId, personId, token);
  await businessServiceClient.createBusiness({ business: businessToCreate, applicationId: targetApplicationId, token });
  await applicationServiceClient.addApplicationRelatedEntity(
    {
      applicationId: targetApplicationId,
      entityType: 'business',
      entityId: businessId,
      relation: 'borrower',
    },
    token,
  );

  const applicationRequirements = await applicationServiceClient.getApplicationRequirements(targetApplicationId, token);

  const selectedRequirement = applicationRequirements.find(({ key }) =>
    currentDocument.relatedEntityType === 'person' ? key === 'person-spreading-sample' : key === 'business-spreading-sample',
  );

  if (!selectedRequirement) {
    throw new Error('Could not find a requirement for the current document');
  }

  await documentServiceClient.copyDocumentToApplication(
    currentDocument.id,
    {
      targetApplicationId,
      targetDocumentId,
      targetEntityType: currentDocument.relatedEntityType,
      targetEntityId: currentDocument.relatedEntityType === 'person' ? personId : businessId,
      targetRequirementId: selectedRequirement.id,
    },
    token,
  );

  await documentDataExtractorServiceClient.syncSpreadingSample(targetApplicationId, token);

  const application = await applicationServiceClient.getApplication(targetApplicationId, token);

  return application.leadingOpportunityId;
};
