import React from 'react';
import { Stack } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { useProductQuery } from '../../../../shared/hooks/react-query/product/useProductQuery';
import { BackLink } from '../../../../shared/components/BackLink';
import { ProductSettings } from './ProductSettings';

export const ProductSettingsPage = () => {
  const { productId } = useParams<{ productId?: string }>();
  const { data: product, isPending: loadingProduct } = useProductQuery(productId);
  const location = useLocation();

  return (
    <Stack height={'100%'} px={10} py={4} gap={5} overflow={'auto'}>
      <BackLink title={'Back to Products'} to={location.state?.returnTo ?? '..'} />
      {loadingProduct ? <LoadingPage /> : product ? <ProductSettings product={product} /> : <div>{'Product not found'}</div>}
    </Stack>
  );
};
