/* eslint-disable react/jsx-no-bind */
import React, { forwardRef, useMemo } from 'react';
import type { ColumnMenuTab } from '@ag-grid-community/core';
import { type GridOptions, type ColDef, type ColGroupDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { FilterMenuIcon } from './Icons/FilterMenuIcon';
import { FilterOnIcon } from './Icons/FilterOnIcon';

interface PipelineTableProps extends GridOptions {
  columnDefs: (ColDef | ColGroupDef)[];
  rowData?: Record<string, any>[];
  gridOptions?: GridOptions;
}

export const PipelineTable = forwardRef<AgGridReact, PipelineTableProps>(
  ({ rowData, columnDefs, gridOptions, ...props }: PipelineTableProps, ref) => {
    const defaultColDef = useMemo(
      () => ({
        sortable: true,
        minWidth: 100,
        menuTabs: ['filterMenuTab'] as ColumnMenuTab[],
        resizable: true,
        filterParams: {
          defaultToNothingSelected: true,
          showTooltips: true,
        },
        cellStyle: { display: 'flex', alignItems: 'center' },
      }),
      [],
    );

    const effectiveGridOptions: GridOptions = useMemo(
      () => ({
        suppressPaginationPanel: true,
        suppressColumnMoveAnimation: true,
        suppressMenuHide: true,
        suppressMovableColumns: true,
        headerHeight: 56,
        rowHeight: 80,
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: true,
        suppressCellFocus: true,
        columnDefs,
        defaultColDef,
        suppressContextMenu: true,
        autoSizeStrategy: {
          type: 'fitGridWidth',
        },
        icons: {
          menu: FilterMenuIcon,
          filter: FilterOnIcon,
        },

        cacheQuickFilter: true,
        ...gridOptions,
      }),
      [columnDefs, defaultColDef, gridOptions],
    );

    return (
      <div id={'pipeline'} style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          ref={ref}
          className={'ag-theme-quartz'}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={effectiveGridOptions}
          {...props}
        />
      </div>
    );
  },
);
