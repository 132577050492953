import styled from '@emotion/styled';
import { Tooltip } from '@lama/app-components';
import { Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC, ReactNode } from 'react';
import React from 'react';

interface AddAssigneeIconProps {
  children?: ReactNode;
  onClick?: () => void;
  menuRef?: any;
}

const AddAssigneeIcon = styled(Stack)`
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23BDBDBDFF' stroke-width='2' stroke-dasharray='2%2c3' stroke-dashoffset='54' stroke-linecap='butt'/%3e%3c/svg%3e");

  border-radius: 50%;

  &:hover {
    background-color: ${grey[100]};
  }
`;

export const AddAssigneeIconButton: FC<AddAssigneeIconProps> = ({ children, menuRef, ...props }) => (
  <Tooltip title={'Assign To'} placement={'top'}>
    <AddAssigneeIcon ref={menuRef} justifyContent={'center'} alignItems={'center'} {...props}>
      {children}
    </AddAssigneeIcon>
  </Tooltip>
);
