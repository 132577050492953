import React from 'react';
import type { FC } from 'react';
import { Modal, ModalContent, Text } from '@lama/design-system';
import type { EtranCredentials, EtranDialogProps } from './types';

export interface EtranExportErrorsDialogProps extends EtranDialogProps<EtranCredentials> {
  errors: string[];
}

export const EtranExportErrorsDialog: FC<EtranExportErrorsDialogProps> = ({ open, onClose, errors }) => (
  <Modal open={open} onClose={onClose} title={'Failed to pull SBSS score'} size={'s'} fullWidth>
    <ModalContent>
      {errors.map((error) => (
        <Text key={error} variant={'body1'}>
          {error}
        </Text>
      ))}
    </ModalContent>
  </Modal>
);
