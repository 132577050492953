import React from 'react';
import type { FC } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import Help from '../../../../shared/icons/help.svg';

interface ContactUsProps {
  name: string;
}

export const ContactUs: FC<ContactUsProps> = ({ name }) => (
  <Stack direction={'row'} spacing={0.5}>
    <Help />
    <Typography variant={'body2'} color={'disabled'}>
      {'Need more info to decide?'}
    </Typography>
    <Link variant={'body2'} href={`mailto:contact@lama.ai?subject=Question regarding ${name}`}>
      {'Contact us'}
    </Link>
  </Stack>
);
