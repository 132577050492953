import type { PixelSuffix } from '@lama/design-system';
import { Flex, Text, greyPalette } from '@lama/design-system';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { BorderedContainer } from './BorderedContainer';

const StyledSegmentContainer = styled(Flex)`
  @media print {
    break-inside: avoid !important;
  }
`;

export const SegmentContainer: FC<{
  children: React.ReactNode;
  title?: string;
  bordered?: boolean;
  id?: string;
  padding?: PixelSuffix;
}> = ({ id, children, title, padding, bordered = true }) => {
  const wrappedchildren = useMemo(
    () => (bordered ? <BorderedContainer padding={padding}>{children}</BorderedContainer> : children),
    [bordered, children, padding],
  );
  return (
    <StyledSegmentContainer flexDirection={'column'} gap={4}>
      {title ? (
        <Flex id={id}>
          <Text variant={'body1'} color={greyPalette[500]}>
            {title}
          </Text>
        </Flex>
      ) : null}
      {wrappedchildren}
    </StyledSegmentContainer>
  );
};
