import React, { useMemo, useContext } from 'react';
import { OwnedBusinesses } from '@lama/app-components';
import { allApplicationPeopleSelector } from '@lama/data-formatters';
import type { RequirementScreenProps } from '../types';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../../../../Pipeline/hooks/useCreateBusinessMutation';
import { useUpdatePerson } from '../../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { useAddApplicationRelation } from '../../../../../../shared/hooks/react-query/application/useAddApplicationRelation';
import { useRemoveApplicationRelation } from '../../../../../../shared/hooks/react-query/application/useRemoveApplicationRelation';

export const OwnedBusinessesScreen: React.FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    opportunity: { id: opportunityId },
    application,
  } = useContext(ApplicationContext);
  const { mutateAsync: createOwnedBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updateOwnedBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunityId);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunityId);
  const { mutateAsync: addApplicationRelation, isPending: addingApplicationRelation } = useAddApplicationRelation(
    application.id,
    opportunityId,
  );
  const { mutateAsync: removeApplicationRelation, isPending: removingApplicationRelation } = useRemoveApplicationRelation(
    application.id,
    opportunityId,
  );

  const owner = useMemo(() => {
    const business = application.relatedBusinesses.find(({ business: { id } }) => id === requirement.entityId)?.business;
    if (business) {
      return { entityType: 'business' as const, entity: business };
    }

    const person = allApplicationPeopleSelector(application).find(({ id }) => id === requirement.entityId);
    if (person) {
      return { entityType: 'person' as const, entity: person };
    }

    return null;
  }, [application, requirement.entityId]);

  if (!owner || !application) {
    return null;
  }

  return (
    <OwnedBusinesses
      application={application}
      owner={owner}
      createBusiness={createOwnedBusiness}
      updateBusiness={updateOwnedBusiness}
      updatePerson={updatePerson}
      addApplicationRelation={addApplicationRelation}
      removeApplicationRelation={removeApplicationRelation}
      loading={creatingBusiness || updatingBusiness || updatingPerson || addingApplicationRelation || removingApplicationRelation}
    />
  );
};
