import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import {
  applicationBorrowingRelatedBusinessesSelector,
  applicationGuarantorsSelector,
  businessName,
  personFullName,
  relatedBusinessesByRelationSelector,
} from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsBorrowerDetailsSegment: FC = () => {
  const { application } = useContext(ApplicationContext);
  const sellerBusinesses = useMemo(() => relatedBusinessesByRelationSelector(application, 'seller'), [application]);
  const borrowerNames = useMemo(
    () =>
      applicationBorrowingRelatedBusinessesSelector(application)
        .map((b) => businessName(b.business))
        .join(', '),
    [application],
  );

  const guarantorNames = useMemo(
    () =>
      applicationGuarantorsSelector(application)
        ?.map((guarantor) => personFullName(guarantor))
        .join(', '),
    [application],
  );

  return (
    <SegmentContainer title={'Borrowing Structure'}>
      <Grid columns={2}>
        <KeyValuePair name={'Relationship'} value={borrowerNames} />
        <KeyValuePair name={'Guarantor(s)'} value={guarantorNames} />
        <KeyValuePair name={'Facilities Amount'} value={application.requestedAmount} type={'currency'} />
        <KeyValuePair name={'Number of Facilities'} value={sellerBusinesses?.length || 1} />
      </Grid>
    </SegmentContainer>
  );
};
