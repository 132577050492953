import { useAuth0 } from '@auth0/auth0-react';
import type { ApplicationUpdateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../../shared/clients/applicationServiceClient';

export const useUpdateStatusMutation = (applicationId: string, opportunityId?: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ updateApplicationPayload }: { updateApplicationPayload: ApplicationUpdateApiModel }) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.updateApplication({ applicationId, application: updateApplicationPayload, token });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
