import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { GetWeeklyChartDataResponse } from '@lama/reports-service-client';
import { reportsServiceClient } from '../../../../../shared/clients/reportsServiceClient';

export const useHmdaReportableChartData = ({
  partnerId,
  productId,
  endDate,
  startDate,
}: {
  partnerId: string | null;
  productId: string | null;
  endDate: Date | null;
  startDate: Date | null;
}): UseQueryResult<GetWeeklyChartDataResponse | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getHmdaReportableChartData = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!partnerId || !productId || !endDate || !startDate) {
      return null;
    }

    try {
      const response = await reportsServiceClient.getHmdaReportableChartData(
        {
          partnerId,
          productId,
          endDate: endDate.toISOString(),
          startDate: startDate.toISOString(),
        },
        token,
      );

      return response;
    } catch (error) {
      console.warn('Error fetching Hmda reportable chart data', error);

      return null;
    }
  }, [getAccessTokenSilently, partnerId, productId, endDate, startDate]);

  const query = useQuery({
    queryKey: ['hmdaReportableChartData', partnerId, productId, endDate?.toISOString(), startDate?.toISOString()],
    queryFn: getHmdaReportableChartData,
  });

  return query;
};
