import type { LabeledValue } from '@lama/contracts';

export const gcBranches: LabeledValue[] = [
  { label: '01 - Fargo - Downtown', value: '01' },
  { label: '02 - Minot - Downtown', value: '02' },
  { label: '03 - Bismarck - Downtown', value: '03' },
  { label: '04 - Jamestown', value: '04' },
  { label: '05 - Devils Lake', value: '05' },
  { label: '06 - Dickinson', value: '06' },
  { label: '07 - Mayville', value: '07' },
  { label: '08 - Carrington', value: '08' },
  { label: '09 - Fargo - Village West', value: '09' },
  { label: '10 - Wahpeton', value: '10' },
  { label: '11 - Grand Forks - South Washington', value: '11' },
  { label: '12 - Hettinger', value: '12' },
  { label: '13 - Park River', value: '13' },
  { label: '14 - Williston', value: '14' },
  { label: '15 - Grand Forks - Columbia Mall', value: '15' },
  { label: '16 - Mohall', value: '16' },
  { label: '17 - Minot - Dakota Square', value: '17' },
  { label: '18 - Bismarck - Gateway', value: '18' },
  { label: '19 - Grand Forks - Downtown', value: '19' },
  { label: '20 - Fargo - Southpointe', value: '20' },
  { label: '21 - Fargo - South University', value: '21' },
  { label: '22 - Moorhead - South', value: '22' },
  { label: '23 - Moorhead - Downtown', value: '23' },
  { label: '24 - Mandan', value: '24' },
  { label: '25 - West Fargo - Main', value: '25' },
  { label: '26 - Fargo - North', value: '26' },
  { label: '27 - Fargo - O Hornbachers Osgood', value: '27' },
  { label: '28 - Bismarck - Country West', value: '28' },
  { label: '29 - Alexandria', value: '29' },
  { label: '30 - Fergus Falls', value: '30' },
  { label: '31 - Fargo - S Hornbachers Southgate', value: '31' },
  { label: '32 - Fargo - VW Hornbachers Village West', value: '32' },
  { label: '33 - West Fargo - Veterans Blvd', value: '33' },
  { label: '34 - South Moorhead - Hornbachers', value: '34' },
  { label: '35 - Bismarck - Sunrise Town Centre', value: '35' },
  { label: '36 - Fargo - Woodhaven', value: '36' },
  { label: '37 - Moorhead - Hornbachers', value: '37' },
  { label: '38 - West Fargo - Gateway West Hornbachers', value: '38' },
  { label: '39 - Waite Park Cash Wise', value: '39' },
  { label: '40 - Sauk Rapids Coborns', value: '40' },
  { label: '41 - East St. Cloud Cash Wise', value: '41' },
  { label: '42 - Dickinson Cash Wise', value: '42' },
  { label: '43 - South Bismarck Cash Wise', value: '43' },
  { label: '44 - Elk River Coborns', value: '44' },
  { label: '45 - Williston Cash Wise', value: '45' },
  { label: '72 - Business Lending', value: '72' },
];
