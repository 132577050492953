import type { FC } from 'react';
import React, { useContext } from 'react';
import type { BusinessApiModel } from '@lama/business-service-client';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

interface Props {
  business: BusinessApiModel;
}

export const GrasshopperRelationshipExposureSummaryTable: FC<Props> = ({ business }) => {
  const { application } = useContext(ApplicationContext);

  return (
    <SegmentContainer title={'Relationship Exposure Summary'} bordered={false}>
      <CreditMemoTable
        headers={[
          {
            text: 'Direct',
          },
          {
            text: 'Indirect',
          },
          {
            text: 'Affiliated',
          },
        ]}
        rows={[
          [
            {
              value: (business.totalLiabilities ?? 0) + (application.requestedAmount ?? 0),
              type: 'currency',
              align: 'left',
            },
            {
              value: '-',
              align: 'left',
            },
            {
              value: '-',
              align: 'left',
            },
          ],
        ]}
      />
    </SegmentContainer>
  );
};
