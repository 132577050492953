/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { useTheme as useMuiTheme } from '@mui/material';
import { useToggle } from 'react-use';

const StyledContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 2px;
  user-select: none;
`;

interface IconContainerProps {
  active: boolean;
  disabled?: boolean;
  hovered?: boolean;
}

const StyledIconContainer = styled(Flex)<IconContainerProps>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: ${({ active, theme, hovered }) =>
    active ? theme.colors.primary.light : hovered ? theme.colors.grey[100] : 'transparent'};
`;

export interface NavItemProps {
  text: string;
  href?: string;
  disabled?: boolean;
  Icon: FC<{ color: string }>;
  onClick?: () => void;
}

const SideNavWrapper: FC<LinkProps & { disabled?: boolean; children: ReactNode | ReactNode[] }> = ({ children, disabled, to, ...rest }) =>
  to && !disabled ? (
    <Link to={to} style={{ textDecoration: 'none' }} {...rest}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export const SideNavItem: FC<NavItemProps> = ({ text, href, disabled, Icon, onClick }) => {
  const muiTheme = useMuiTheme();
  const { pathname } = useLocation();
  const [isHovered, toggleHovered] = useToggle(false);
  const active = useMemo(() => !disabled && !!href && pathname.includes(href), [disabled, href, pathname]);

  return (
    <SideNavWrapper to={href ?? ''} disabled={disabled} onMouseEnter={toggleHovered} onMouseLeave={toggleHovered}>
      <StyledContainer>
        <StyledIconContainer active={active} disabled={disabled} onClick={onClick} hovered={isHovered}>
          <Icon
            color={disabled ? muiTheme.palette.text.disabled : active ? muiTheme.palette.primary.main : muiTheme.palette.text.secondary}
          />
        </StyledIconContainer>
        <Text variant={'body4'} color={disabled ? 'disabled' : active ? 'primary.main' : 'secondary'} textAlign={'center'}>
          {text}
        </Text>
      </StyledContainer>
    </SideNavWrapper>
  );
};
