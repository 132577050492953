import { useCallback } from 'react';
import type { GetFormTypesResponse } from '@lama/clients';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { documentDataExtractorServiceClient } from '../../../../../../../shared/clients/documentDataExtractorServiceClient';

export const useGetFormTypesQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getFormTypes = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return documentDataExtractorServiceClient.getFormTypes(token);
  }, [getAccessTokenSilently]);

  return useQuery<GetFormTypesResponse>({ queryKey: ['formTypes'], queryFn: getFormTypes });
};
