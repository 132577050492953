import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Chip } from '@mui/material';

import { type ApplicationStatus } from '@lama/contracts';
import type { ApplicationStatusDisplayNameMapping } from '@lama/partner-service-client';
import { applicationStatusToColors } from '../utils/applicationStatusToColors';
import { useUserPartner } from '../hooks/useCurrentPartner';

export const statusToChip = (
  status: ApplicationStatus,
  applicationStatusToDisplayName: ApplicationStatusDisplayNameMapping,
  isApplicationAutoDeclined?: boolean,
): { label: string; style: { bgcolor: string; color: string; borderColor: string } } => ({
  label: isApplicationAutoDeclined ? 'Auto Declined' : applicationStatusToDisplayName[status],
  style: isApplicationAutoDeclined ? applicationStatusToColors.Rejected : applicationStatusToColors[status],
});

interface ApplicationStatusChipProps {
  status: ApplicationStatus;
  size?: 'medium' | 'small';
  sx?: SxProps<Theme>;
  isApplicationAutoDeclined?: boolean;
}

export const ApplicationStatusChip: FC<ApplicationStatusChipProps> = ({ status, size, sx, isApplicationAutoDeclined }) => {
  const partner = useUserPartner();
  const { label, style } = useMemo(
    () => statusToChip(status, partner.applicationStatusDisplayNameMapping, isApplicationAutoDeclined),
    [status, partner.applicationStatusDisplayNameMapping, isApplicationAutoDeclined],
  );
  return <Chip label={label} size={size} variant={'outlined'} sx={{ ...style, ...sx }} />;
};
