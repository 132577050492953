import React from 'react';
import type { FC } from 'react';
import { Button, Flex, greenPalette, redPalette } from '@lama/design-system';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { DecisionMakerTitle } from './DecisionMakerTitle';
import { StyledDecisionCardContainer } from './StyledDecisionCardContainer';

interface DecisionHintCardProps {
  requiredRole: string;
}

export const RequiredRoleDecisionCard: FC<DecisionHintCardProps> = ({ requiredRole }) => (
  <StyledDecisionCardContainer disabled>
    <DecisionMakerTitle role={requiredRole} firstName={'Authorized'} lastName={'User'} />
    <Flex justifyContent={'flex-end'} alignItems={'center'}>
      <Flex gap={4} alignItems={'center'}>
        <Button startIcon={<CheckCircle />} size={'m'} variant={'secondary'} color={'neutral'} iconColor={greenPalette[500]} disabled>
          {'Approve'}
        </Button>
        <Button startIcon={<Cancel />} size={'m'} variant={'secondary'} color={'neutral'} iconColor={redPalette[500]} disabled>
          {'Decline'}
        </Button>
      </Flex>
    </Flex>
  </StyledDecisionCardContainer>
);
