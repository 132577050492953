import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import type { AccountData } from '@lama/contracts';
import React from 'react';
import { TransactionItem } from './TransactionItem';

export const TransactionsList: FC<{ transactions: AccountData['transactions'] }> = ({ transactions }) => (
  <Flex flexDirection={'column'} gap={3} px={2} width={'100%'}>
    {transactions.map((transaction) => (
      <TransactionItem key={transaction.transactionId} transaction={transaction} />
    ))}
  </Flex>
);
