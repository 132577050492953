import { orderBy } from 'lodash-es';
import { parseDateWithoutTime } from '@lama/data-formatters';
import type { AttributeWithFinancialData } from '../FinancialStatements/utils/financialAttribute';

const getNumericPart = (str: string): number => Number(str.replaceAll(/\D/g, ''));
const getNonNumericPart = (str: string): string => str.replaceAll(/\d/g, '');

export const sortScheduleAttributesByLine = (a: AttributeWithFinancialData, b: AttributeWithFinancialData) => {
  const { page: pageA, displayData: displayDataA } = a.attribute;
  const { page: pageB, displayData: displayDataB } = b.attribute;

  if (!displayDataA || !displayDataB) {
    return 0;
  }

  const { displayLineName: lineNameA, columnName: colA } = displayDataA;
  const { displayLineName: lineNameB, columnName: colB } = displayDataB;

  if (pageA !== pageB && !Number.isNaN(Number(pageA)) && !Number.isNaN(Number(pageB))) {
    return Number(pageA) - Number(pageB);
  }

  const lineNumberA = lineNameA.split(' ').at(0) ?? '';
  const lineNumberB = lineNameB.split(' ').at(0) ?? '';

  const numericPartA = getNumericPart(lineNumberA);
  const numericPartB = getNumericPart(lineNumberB);
  const nonNumericPartA = getNonNumericPart(lineNumberA);
  const nonNumericPartB = getNonNumericPart(lineNumberB);

  const lineNumberCompare = numericPartA !== numericPartB ? numericPartA - numericPartB : nonNumericPartA.localeCompare(nonNumericPartB);
  if (lineNumberCompare !== 0) {
    return lineNumberCompare;
  }

  if (colA && colB) {
    return colA.localeCompare(colB);
  }

  if (a.financialData?.startDate && b.financialData?.startDate) {
    return parseDateWithoutTime(a.financialData.startDate).getTime() - parseDateWithoutTime(b.financialData.startDate).getTime();
  }
  return 0;
};

export const sortFormAttributesByPage = (attributes: AttributeWithFinancialData[]) =>
  orderBy(
    attributes,
    [({ attribute: { page } }) => (page === '*' ? undefined : page), ({ attribute }) => attribute.schedule ?? '-'],
    ['asc', 'asc', 'asc'],
  );
