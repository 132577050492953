import React, { useContext, useMemo } from 'react';
import { useOfFundsBreakdownByType as selectorUseOfFundsBreakdownByType } from '@lama/data-formatters';
import { sumBy } from 'lodash-es';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../ApplicationContext';
import { totalRow } from '../../CreditMemo/Sections/creditMemoTable/creditMemoTableRowCreator';

const headers = [
  { text: 'Type', width: '50%' },
  { text: 'Amount', width: '50%' },
];

export const UseOfFundsSummaryTable = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const breakdownByTypeRows = selectorUseOfFundsBreakdownByType(application).map<CellContent[]>((item) => [
      { value: item.type },
      { value: item.amount, type: 'currency', align: 'right' },
    ]);

    const totalAccountedUseOfFunds = sumBy(application.useOfFundsBreakdown ?? [], (a) => a.amount ?? 0);
    const totalUnaccountedUseOfFunds =
      application.requestedAmount && totalAccountedUseOfFunds < application.requestedAmount
        ? application.requestedAmount - totalAccountedUseOfFunds
        : 0;

    const unaccountedRow: CellContent[] =
      totalUnaccountedUseOfFunds > 0
        ? [{ value: 'Unaccounted' }, { value: totalUnaccountedUseOfFunds, type: 'currency', align: 'right' }]
        : [];

    const totalRowDefinition = totalRow({
      cells: [{ value: totalAccountedUseOfFunds + totalUnaccountedUseOfFunds, type: 'currency', align: 'right' }],
    });

    return [...breakdownByTypeRows, unaccountedRow, totalRowDefinition];
  }, [application]);

  return <CreditMemoTable headers={headers} rows={rows} />;
};
