import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import { useApplicationDocumentsQuery } from '../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentIssuesSection } from './DocumentIssuesSection';

export const DocumentDetailsPage = () => {
  const { documentId } = useParams();
  const { application } = useContext(ApplicationContext);
  const { data: documents, isPending } = useApplicationDocumentsQuery({ applicationId: application.id });
  const document = documents?.find((d) => d.id === documentId);

  if (isPending) {
    return null;
  }

  if (!document) {
    return <Text variant={'h4'}>{'not found'}</Text>;
  }

  return (
    <Flex flexDirection={'row'} justifyContent={'space-between'}>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h4'}>{document.description}</Text>
        <Flex flexDirection={'column'}>
          <Text variant={'body2'}>{`file name: ${document.filename}`}</Text>
          <Text variant={'body2'}>{`uploaded at: ${document.createdAt}`}</Text>
          <Text variant={'body2'}>{`uploaded by: ${document.uploaderId}`}</Text>
        </Flex>
        {document.issues ? <DocumentIssuesSection documentId={document.id} issues={document.issues} /> : null}
      </Flex>
      <a href={document?.previewUrl} target={'_blank'} rel={'noreferrer'}>
        {'View document'}
      </a>
    </Flex>
  );
};
