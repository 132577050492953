import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { Layout } from './shared/components/Layout';
import IntegrationsPage from './components/Integrations/IntegrationsPage';
import { AccountDashboardPage } from './components/AccountsDashboard/AccountDashboardPage';
import { DealPage } from './components/Deals/DealView/DealPage';
import { ProductSelectionPage } from './components/Deals/ProductSelection/ProductSelection';
import { DealProductTemplateSelectionPage } from './components/Deals/ProductSelection/DealProductTemplateSelectionPage';
import { ProductTemplateSelectionPage } from './components/Application/Products/ProductTemplates/ProductTemplateSelectionPage';
import { AppetiteSettingsPage } from './components/Application/Products/ProductSettings/Appetite/AppetiteSettingsPage';
import { ProductSettingsPage } from './components/Application/Products/ProductSettings/ProductSettingsPage';
import { DocumentDetailsPage } from './components/DocumentDetails/DocumentDetailsPage';
import { TermsSettingsPage } from './components/Application/Products/ProductSettings/Terms/TermsSettingsPage';
import { CreditMemoPage } from './components/Application/CreditMemo/CreditMemoPage';
import { NotesPage } from './components/Application/Notes/NotesPage';
import { InsightsPage } from './components/Application/Insights/InsightsPage';
import { SpreadingManagementPage } from './components/ManualSpreadingManagement/ManualSpreadingManagementPage';
import { ReportsPage } from './components/Reports/components/ReportsPage';
import { ClosingPage } from './components/Application/Closing/ClosingPage';
import { ConditionPage as AppetiteConditionPage } from './components/Application/Products/ProductSettings/Appetite/ConditionComponents/ConditionV2Components/ConditionPage';
import { ConditionPage as AutoApprovalConditionPage } from './components/Application/Products/ProductSettings/AutoApproval/ConditionComponents/ConditionPage';
import { EngagementSettingsPage } from './components/Application/Products/ProductSettings/Engagement/EngagementSettingsPage';
import { OpportunityRequirementsPage } from './components/Application/OpportunityRequirements/OpportunityRequirements/OpportunityRequirementsPage';
import { SummaryPage } from './components/Application/Summary/SummaryPage';
import { UnderwritingPage } from './components/Application/Underwriting/UnderwritingPage';
import { OpportunityPage } from './components/Application/OpportunityPage';
import { PipelinePage } from './components/Pipeline/PipelinePage';
import { ProductsPage } from './components/Application/Products/ProductsPage';
import DealsPage from './components/Deals/DealsPage';
import { AutoApprovalSettingsPage } from './components/Application/Products/ProductSettings/AutoApproval/AutoApprovalSettings';
import { PartnerRequirementsSettings } from './components/Application/Products/ProductSettings/PartnerRequirements/PartnerRequirementsSettings';
import { DocumentHubPage } from './components/DocumentHub/DocumentHubPage';
import { ReferrerPipelinePage } from './components/Referrer/ReferrerPipelinePage';
import { SimulatorPage } from './components/Simulator/SimulatorPage';

const BaseRedirectPage = () => <Navigate to={'/pipeline'} />;

export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={'/'} element={<Layout />} key={'layout'}>
      <Route path={'spreading'}>
        <Route path={':applicationId'} element={<SpreadingManagementPage />} />
      </Route>
      <Route path={'reports'} element={<ReportsPage />} />
      <Route path={'referrers'} element={<ReferrerPipelinePage />} />
      <Route path={'pipeline'}>
        <Route path={':opportunityId'} element={<OpportunityPage />}>
          <Route index element={<Navigate to={'summary'} replace />} />
          <Route path={'summary'} element={<SummaryPage />} />
          <Route path={'requirements'} element={<OpportunityRequirementsPage />} />
          <Route path={'document-hub'} element={<DocumentHubPage />} />
          <Route path={'document-hub/:documentId'} element={<DocumentDetailsPage />} />
          <Route path={'underwriting'} element={<UnderwritingPage />} />
          <Route path={'notes'} element={<NotesPage />} />
          <Route path={'credit-memo'} element={<CreditMemoPage />} />
          <Route path={'closing'} element={<ClosingPage />} />
          <Route path={'insights'} element={<InsightsPage />} />
        </Route>
        <Route index element={<PipelinePage />} />
      </Route>
      <Route path={'products'}>
        <Route path={'templates'} element={<ProductTemplateSelectionPage />} />
        <Route path={':productId'} element={<ProductSettingsPage />}>
          <Route path={'appetite'} element={<AppetiteSettingsPage />}>
            <Route path={':conditionId'} element={<AppetiteConditionPage />} />
          </Route>
          <Route path={'autoApproval'} element={<AutoApprovalSettingsPage />}>
            <Route path={':conditionId'} element={<AutoApprovalConditionPage />} />
          </Route>
          <Route path={'requirements'} element={<PartnerRequirementsSettings />} />
          <Route path={'terms'} element={<TermsSettingsPage />} />
          <Route path={'engagement'} element={<EngagementSettingsPage />} />
        </Route>
        <Route index element={<ProductsPage />} />
      </Route>
      <Route path={'deals'}>
        <Route path={':dealId'}>
          <Route path={'productSelection'}>
            <Route path={'templates'}>
              <Route index element={<DealProductTemplateSelectionPage />} />
            </Route>
            <Route index element={<ProductSelectionPage />} />
          </Route>
          <Route index element={<DealPage />} />
        </Route>
        <Route index element={<DealsPage />} />
      </Route>
      <Route path={'accountsDashboard'} element={<AccountDashboardPage />} />
      <Route path={'integrations'} element={<IntegrationsPage />} />
      <Route path={'simulator'} element={<SimulatorPage />} />
      <Route index element={<BaseRedirectPage />} />
      <Route path={'*'} element={<BaseRedirectPage />} />
    </Route>,
  ]),
);
