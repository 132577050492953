import React, { useCallback } from 'react';
import type { FC } from 'react';
import { MenuItem } from '@mui/material';
import { Tooltip } from '@lama/app-components';
import { Text } from '@lama/design-system';
import type { Matcher } from '@lama/contracts';
import { rangeMatcherType } from '@lama/contracts';
import type { MatcherConfiguration } from './matcherConfigurations';
import type { MatcherValue } from './types';

interface AddMatcherMenuItemProps {
  matcherConfiguration: MatcherConfiguration;
  addMatcher: (matcher: Matcher) => void;
  closeMenu: () => void;
}

export const AddMatcherMenuItem: FC<AddMatcherMenuItemProps> = ({ matcherConfiguration, addMatcher, closeMenu }) => {
  const onClick = useCallback(() => {
    const typeSpecificValues: MatcherValue =
      matcherConfiguration.type === rangeMatcherType
        ? { min: matcherConfiguration.min, max: matcherConfiguration.max, type: matcherConfiguration.type }
        : { value: matcherConfiguration.default, type: matcherConfiguration.type };

    const createMatcher: Matcher = {
      entityType: matcherConfiguration.entityType,
      fieldName: matcherConfiguration.fieldName,
      displayName: matcherConfiguration.displayName,
      ...typeSpecificValues,
    };

    addMatcher(createMatcher);

    closeMenu();
  }, [addMatcher, closeMenu, matcherConfiguration]);
  return (
    <Tooltip title={matcherConfiguration.displayName}>
      <MenuItem
        onClick={onClick}
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': { bgcolor: 'primary.light' },
          borderRadius: '4px',
          px: 2,
        }}
      >
        <Text ellipsis>{matcherConfiguration.displayName}</Text>
      </MenuItem>
    </Tooltip>
  );
};
