import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useDeleteNote = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (noteId: string) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.deleteNote(opportunityId, noteId, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
  });
};
