import { Flex, grayPalette, greyPalette, Text } from '@lama/design-system';
import { Chip, Switch } from '@mui/material';
import { MaterialRoundedIcon, Avatar } from '@lama/app-components';
import type { FC } from 'react';
import React from 'react';
import { formatValue } from '@lama/data-formatters';
import { EngagementCardSettings } from './EngagementCardSettings';

interface EngagementCardProps {
  title: string;
  description: string[];
  settingOptions: string[];
  chipLabel: string;
  avatarSource: string;
  deals?: string;
  amount?: number;
}

export const EngagementCard: FC<EngagementCardProps> = ({ title, description, settingOptions, chipLabel, avatarSource, deals, amount }) => (
  <Flex
    flexDirection={'column'}
    border={`1px solid ${greyPalette[300]}`}
    borderRadius={'8px'}
    justifyContent={'space-between'}
    height={'168px'}
    minWidth={'280px'}
    flex={'0 1 calc(33.33% - 24px)'}
    px={6}
    py={4}
  >
    <Flex flexDirection={'column'} gap={2}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'} gap={2}>
        <Flex flexDirection={'row'} gap={2}>
          <Avatar size={32} style={{ background: 'white' }} imgSrc={avatarSource} firstName={''} lastName={''} />
          <Text variant={'body1'} m={'auto'}>
            {title}
          </Text>
        </Flex>
        <EngagementCardSettings options={settingOptions} />
      </Flex>
      <Flex flexDirection={'column'} px={10}>
        {description.map((descriptionLine) => (
          <Text key={descriptionLine} variant={'body3'} color={'secondary'} ellipsis>
            {descriptionLine}
          </Text>
        ))}
      </Flex>
    </Flex>
    <Flex flexDirection={'row'} justifyContent={'space-between'} gap={2}>
      <Chip
        sx={{
          cursor: 'inherit',
          fontSize: '12px !important',
          marginY: 'auto',
        }}
        variant={'outlined'}
        label={chipLabel}
        size={'small'}
      />
      <Flex gap={3}>
        {deals ? (
          <Flex flexDirection={'row'} alignItems={'center'} gap={0.8}>
            <MaterialRoundedIcon name={'store'} size={'18px'} color={grayPalette[700]} />
            <Text variant={'body3'} color={grayPalette[700]}>
              {deals}
            </Text>
          </Flex>
        ) : null}
        {amount ? (
          <Flex flexDirection={'row'} alignItems={'center'} gap={0.8}>
            <Text variant={'body3'} color={grayPalette[700]}>
              {formatValue(amount ?? 0, 'currencyCompact')}
            </Text>
          </Flex>
        ) : null}
        <Switch />
      </Flex>
    </Flex>
  </Flex>
);
