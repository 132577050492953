import React from 'react';
import type { TableCellProps } from '@mui/material';
import type { InformationTypes } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

const dataRows: {
  type: InformationTypes;
  values: (number | string | null)[];
  name: string;
  bold: boolean;
  bgColor?: string;
  colspan?: number;
}[] = [
  {
    name: 'Asset Class',
    values: [],
    type: 'currency',
    bold: true,
    colspan: 1000,
  },
  {
    name: 'Accounts Receivable, Net',
    values: [2_383_379, 0, 2_383_379, 80, 1_906_704],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Inventory',
    values: [6_233_952, 0, 6_233_952, 65, 4_052_068],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Working Capital Subtotal',
    values: [8_617_331, 0, 8_617_331, null, 5_958_772],
    type: 'currency',
    bold: true,
  },

  {
    name: 'Net PPE',
    values: [20_716, 0, 20_716, 50, 10_358],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Equipment - OLV',
    values: [0, 0, 0, 80, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Fixed Asset Subtotal',
    values: [20_716, 0, 20_716, 50, 10_358],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Real Estate - Address',
    values: ['Appraisal Date', ' ', 'Appraised Value', null, ' '],
    type: 'string',
    bold: true,
    bgColor: '#EFEFEF',
    colspan: 1,
  },
  {
    name: 'Real Estate - Address',
    values: [0, 0, 0, 75, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Real Estate Subtotal',
    values: [0, 0, 0, null, 0],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Other Collateral',
    values: [0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: '',
    values: [' ', ' ', 'Net Collateral', null, 'Margined Value'],
    type: 'string',
    bold: true,
    bgColor: '#EFEFEF',
  },
  {
    name: 'TOTAL COLLATERAL -',
    values: ['06/30/2024', null, 8_638_047, null, 5_969_130],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Credit Facilities',
    values: [' ', ' ', 'Commitment', null, 'Funded'],
    type: 'string',
    bold: true,
    bgColor: '#EFEFEF',
  },
  {
    name: 'Line of Credit',
    values: [' ', ' ', 5_500_000, null, 5_291_000],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Other',
    values: [' ', ' ', 0, null, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'First Bank Sr Secured Debt',
    values: [' ', ' ', 5_500_000, null, 5_291_000],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Collateral Coverage',
    values: [null, null, 108.5, null, 112.8],
    type: 'percent',
    bold: true,
  },
  {
    name: 'Collateral Excess (Shortfall)',
    values: [' ', ' ', 469_130, null, 678_130],
    type: 'currency',
    bold: true,
  },
];

const headers = [
  { text: ['Collateral Analysis', 'As of: 06/30/2024'], width: '25%' },
  { text: 'Gross Collateral', width: '16%' },
  { text: 'Ineligibles', width: '12%' },
  { text: 'Net Collateral', width: '16%' },
  { text: 'Advance Rate', width: '14%', typeOverride: 'percent' },
  { text: 'Margined Value', width: '20%' },
];

export const FirstBankCommercialCollateral = () => {
  const rows: CellContent[][] = dataRows.map((item) => [
    { value: item.name, bold: item.bold, bgColor: item.bgColor, colspan: item.colspan },
    ...item.values.map((value, index) => ({
      value,
      type: (headers[index + 1]?.typeOverride ?? item.type) as InformationTypes,
      align: 'right' as TableCellProps['align'],
      bold: item.bold,
      bgColor: item.bgColor,
      colspan: item.colspan,
    })),
  ]);

  return (
    <SegmentContainer bordered={false}>
      <CreditMemoTable headers={headers} rows={[...rows]} />
    </SegmentContainer>
  );
};
