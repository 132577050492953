import React from 'react';
import type { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import ThumbDown from '../../../../shared/icons/thumb_down.svg';
import ThumbDownFilled from '../../../../shared/icons/thumb_down_filled.svg';
import ThumbUp from '../../../../shared/icons/thumb_up.svg';
import ThumbUpFilled from '../../../../shared/icons/thumb_up_filled.svg';

interface SimilarDealsProps {
  interested: boolean;
  dismissed: boolean;
}

export const SimilarDeals: FC<SimilarDealsProps> = ({ interested, dismissed }) => (
  <Stack direction={'row'} spacing={1} justifyContent={'center'}>
    {interested ? <ThumbUpFilled /> : <ThumbUp />}
    <Typography variant={'subtitle1'} color={'text.secondary'}>
      {'More like this'}
    </Typography>
    {dismissed ? <ThumbDownFilled /> : <ThumbDown />}
    <Typography variant={'subtitle1'} color={'text.secondary'}>
      {'Less like this'}
    </Typography>
  </Stack>
);
