import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import type { EnvelopeTracking } from '@lama/docusign-client';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useCreateEnvelopeMutation } from './hooks/useCreateEnvelopeMutation';
import { useUpdateEnvelopeMutation } from './hooks/useUpdateEnvelopeMutation';
import { useVoidEnvelopeMutation } from './hooks/useVoidEnvelopeMutation';
import { statusesToVoid } from './EnvelopeDocusignCard';

interface EnvelopeButtonProps {
  envelopeTracking?: EnvelopeTracking;
  peopleIds: string[];
  templateId: string;
  businessId?: string;
}

export const EnvelopeButton: FC<EnvelopeButtonProps> = ({ envelopeTracking, peopleIds, templateId, businessId }) => {
  const queryClient = useQueryClient();
  const { opportunity } = useContext(ApplicationContext);

  const onSuccess = useCallback(
    (data: { url: string; envelopeTracking: EnvelopeTracking }) => {
      open(data.url, '_blank');
      void queryClient.invalidateQueries({ queryKey: ['opportunityEnvelopesTracking', data.envelopeTracking?.opportunityId] });
    },
    [queryClient],
  );

  const onVoidSuccess = useCallback(
    (data: { envelopeTracking: EnvelopeTracking }) => {
      void queryClient.invalidateQueries({ queryKey: ['opportunityEnvelopesTracking', data.envelopeTracking?.opportunityId] });
    },
    [queryClient],
  );

  const { mutate: createEnvelope, isPending: creatingEnvelope } = useCreateEnvelopeMutation({ onSuccess });
  const { mutate: updateEnvelope, isPending: updatingEnvelope } = useUpdateEnvelopeMutation({ onSuccess });
  const { mutate: voidEnvelope, isPending: voidingEnvelope } = useVoidEnvelopeMutation({ onSuccess: onVoidSuccess });

  const onSendDocument = useCallback(() => {
    if (!envelopeTracking) {
      createEnvelope({
        opportunityId: opportunity.id,
        peopleIds,
        returnUrl: `${window.location.href}`,
        templateId,
        businessId,
      });
    } else {
      updateEnvelope({
        opportunityId: opportunity.id,
        envelopeId: envelopeTracking.envelopeId,
        returnUrl: `${window.location.href}`,
      });
    }
  }, [createEnvelope, envelopeTracking, peopleIds, templateId, updateEnvelope, opportunity.id, businessId]);

  const onVoidDocument = useCallback(() => {
    if (!envelopeTracking?.status) {
      return;
    }

    voidEnvelope({
      opportunityId: opportunity.id,
      envelopeId: envelopeTracking.envelopeId,
      voidReason: 'Voided by Lender',
    });
  }, [envelopeTracking?.envelopeId, envelopeTracking?.status, opportunity.id, voidEnvelope]);

  const buttonText = useMemo(() => {
    if (!envelopeTracking?.status || envelopeTracking.status === 'created') {
      return 'Preview & Send';
    }

    if (statusesToVoid.has(envelopeTracking.status)) {
      return 'Cancel Envelope';
    }

    return 'Envelope Sent';
  }, [envelopeTracking]);

  const buttonLoading = useMemo(() => {
    if (!envelopeTracking?.status || envelopeTracking.status === 'created') {
      return creatingEnvelope || updatingEnvelope;
    }

    if (statusesToVoid.has(envelopeTracking.status)) {
      return voidingEnvelope;
    }

    return false;
  }, [creatingEnvelope, envelopeTracking, updatingEnvelope, voidingEnvelope]);

  const onClick = useMemo(() => {
    if (!envelopeTracking?.status || envelopeTracking.status === 'created') {
      return onSendDocument;
    }

    if (statusesToVoid.has(envelopeTracking.status)) {
      return onVoidDocument;
    }

    return () => {};
  }, [envelopeTracking, onSendDocument, onVoidDocument]);

  return (
    <LoadingButton loading={buttonLoading} onClick={onClick} variant={'outlined'} size={'small'} sx={{ borderRadius: '4px' }}>
      {buttonText}
    </LoadingButton>
  );
};
