import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { Button, Flex, Text, greyPalette } from '@lama/design-system';
import { ArrowRight } from '@mui/icons-material';
import { isNil } from 'lodash-es';
import DocumentPlaceholderImage from '../../../assets/document-placeholder.svg';
import { useApplicationDocumentsQuery } from '../../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import type { FieldDescriptionProps } from './types';

export const DocumentSourceDescription: FC<FieldDescriptionProps> = ({ value, applicationId, openDocument }) => {
  const { data: documents } = useApplicationDocumentsQuery({ applicationId });

  const valueFinancial = useMemo(() => value.financialAttribute?.selectedSource, [value]);

  const valueDocument = useMemo(
    () =>
      documents?.find(
        (doc) =>
          (valueFinancial?.financialDataSource.type === 'Document' && doc.id === valueFinancial?.financialDataSource.documentId) ||
          (valueFinancial?.financialDataSource.type === 'Manual' && doc.id === valueFinancial?.financialDataSource.relatedDocumentId),
      ),
    [documents, valueFinancial?.financialDataSource],
  );

  const formType = useMemo(() => {
    const financialDocumentPage = valueDocument?.documentPages?.find(
      (documentPage) =>
        valueFinancial?.financialDataSource.type === 'Document' && documentPage.page === valueFinancial?.financialDataSource.page,
    );

    return financialDocumentPage?.formType;
  }, [valueDocument?.documentPages, valueFinancial?.financialDataSource]);

  const onOpenDocumentClick = useCallback(() => {
    if (valueDocument && valueFinancial) {
      openDocument({
        documentId: valueDocument.id,
        pageIndex:
          (valueFinancial.financialDataSource.type === 'Document' &&
            !isNil(valueFinancial.financialDataSource.page) &&
            valueFinancial.financialDataSource.page - 1) ||
          0,
        boundingBox: valueFinancial.financialDataSource.type === 'Document' ? valueFinancial.financialDataSource.boundingBox : undefined,
      });
    }
  }, [openDocument, valueDocument, valueFinancial]);

  if (!valueDocument) {
    return null;
  }

  return (
    <Flex gap={4} pr={4} alignItems={'center'} maxWidth={'450px'} overflow={'hidden'} minWidth={0}>
      <DocumentPlaceholderImage width={'150px'} />
      <Flex flexDirection={'column'} gap={2}>
        <Text variant={'body2'} color={'secondary'}>
          {'Document'}
        </Text>
        <Flex flexDirection={'column'}>
          <Text variant={'body1'} ellipsis>
            {valueDocument?.filename ?? 'N/A'}
          </Text>
          <Text variant={'body3'} color={'secondary'} ellipsis>
            {formType}
          </Text>
        </Flex>
        <Flex>
          <Button
            variant={'secondary'}
            color={'neutral'}
            onClick={onOpenDocumentClick}
            size={'m'}
            iconColor={greyPalette[500]}
            endIcon={<ArrowRight />}
          >
            {'Open Document'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
