import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { PartnerRequirement } from '@lama/contracts';
import { productServiceClient } from '../../../clients/productService';

export const usePartnerRequirementsQuery = (
  partnerId?: string,
  options?: UseQueryOptions<PartnerRequirement[] | null>,
): UseQueryResult<PartnerRequirement[] | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getPartnerRequirementsQuery = useCallback(async () => {
    if (!partnerId) {
      return [];
    }
    const token = await getAccessTokenSilently();

    return productServiceClient.getPartnerRequirements(partnerId, token);
  }, [getAccessTokenSilently, partnerId]);

  const query = useQuery({ queryKey: ['partnerRequirements', partnerId], queryFn: getPartnerRequirementsQuery, ...options });

  return query;
};
