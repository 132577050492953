import { Stack, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { BackLink } from '../../../../shared/components/BackLink';
import { ProductTemplateCardList } from './ProductTemplateCardList';

interface ProductTemplateSelectionProps {
  returnTo?: string;
}

export const ProductTemplateSelectionPage: FC<ProductTemplateSelectionProps> = ({ returnTo }) => {
  const theme = useTheme();

  return (
    <Stack height={'100%'} bgcolor={'#F6F6F6'} px={10} py={4} gap={4}>
      <BackLink title={'Back to Products'} to={'..'} />
      <Stack gap={6}>
        <Stack gap={2}>
          <Typography variant={'h5'}> {'Create From Template'}</Typography>
          <Typography variant={'body2'} color={theme.palette.text.secondary}>
            {'Configure your product from a premade template'}
          </Typography>
        </Stack>
        <ProductTemplateCardList returnTo={returnTo} />
      </Stack>
    </Stack>
  );
};
