import React from 'react';
import type { FC } from 'react';
import { Text, Flex } from '@lama/design-system';
import InterestedIcon from '../../shared/icons/interested.svg';

export const InterestedMark: FC = () => (
  <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={1}>
    <InterestedIcon />
    <Text variant={'body2'} color={'text.secondary'}>
      {'Interested'}
    </Text>
  </Flex>
);
