import type { Debt } from '@lama/contracts';
import React from 'react';
import type { FC } from 'react';
import { Flex, Grid, Text, greyPalette } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../SegmentContainer';

export const DebtSegment: FC<{ debt: Debt }> = ({ debt }) => {
  const {
    type,
    amount,
    description,
    monthlyPayment,
    securedBy,
    creditorName,
    creditorAddress,
    interestRate,
    originalDate,
    originalAmount,
    maturityDate,
  } = debt;
  const info: InfoLineProps[] = [
    {
      label: 'Creditor Name',
      values: creditorName,
    },
    {
      label: 'Creditor Address',
      values: creditorAddress,
    },
    {
      label: 'Original Date',
      values: originalDate,
      type: 'date',
    },
    {
      label: 'Original Amount',
      values: originalAmount,
      type: 'currency',
    },
    {
      label: 'Present Balance',
      values: amount,
      type: 'currency',
    },
    {
      label: 'Interest Rate',
      values: interestRate,
      type: 'percent',
    },
    {
      label: 'Monthly Payment',
      values: monthlyPayment,
      type: 'currency',
    },
    {
      label: 'Maturity Date',
      values: maturityDate,
      type: 'date',
    },
    {
      label: 'Secured By',
      values: securedBy,
    },
  ];

  return (
    <SegmentContainer title={type}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex flexDirection={'column'} gap={4}>
          <Grid columns={3}>
            {info.map((line) => (
              <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
            ))}
          </Grid>
        </Flex>
        <Flex flexDirection={'column'} gap={2}>
          <Text variant={'body2'} color={greyPalette[500]}>
            {'Description'}
          </Text>
          <Text variant={'body2'} paragraph>
            {description}
          </Text>
        </Flex>
      </Flex>
    </SegmentContainer>
  );
};
