import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { formatAlertMessage } from '@lama/alerts-service-client';
import { sortBy } from 'lodash-es';
import { useGetOpportunityAlertsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityAlertsQuery';
import { ApplicationContext } from '../ApplicationContext';
import { OpportunityAlertView } from './OpportunityAlertView';

export const OpportunityAlerts: FC = () => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { data: alerts } = useGetOpportunityAlertsQuery(opportunityId, {
    refetchInterval: 5000,
  });

  const alertsWithMessages = useMemo(() => {
    if (!alerts?.length) {
      return [];
    }

    const sorted = sortBy(alerts, [(a) => !a.sticky, (a) => a.level === 'info']);

    return sorted.map((a) => ({ ...a, message: formatAlertMessage(a) }));
  }, [alerts]);

  if (!alertsWithMessages?.length) {
    return null;
  }

  return (
    <OpportunityAlertView
      params={alertsWithMessages[0]!.messageParams ?? {}}
      message={alertsWithMessages[0]!.message}
      actions={alertsWithMessages[0]!.actions ?? []}
      level={alertsWithMessages[0]!.level}
    />
  );
};
