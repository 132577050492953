/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import type { Condition } from '@lama/contracts';
import type { CreateCondition } from '@lama/clients';
import { v4 as uuidv4 } from 'uuid';

export interface AutoApproval {
  conditions?: Condition[];
  addCondition: (condition: CreateCondition) => void;
  updateCondition: (condition: Condition) => void;
  deleteCondition: (conditionId: string) => void;
}

export const AutoApprovalContext = React.createContext<AutoApproval>(null!);

interface AutoApprovalProviderProps {
  children: React.ReactNode;
}

export const DemoAutoApprovalProvider: FC<AutoApprovalProviderProps> = ({ children }) => {
  const [conditions, setConditions] = useState<Condition[]>([
    {
      id: uuidv4(),
      name: 'Minimum Credit Score',
      conditionMatcher: {
        type: 'greaterThan',
        entityType: 'business',
        fieldName: 'averageGuarantorsCreditScore',
        value: 700,
      },
      active: true,
    },
    {
      id: uuidv4(),
      name: 'SBSS Score',
      conditionMatcher: {
        type: 'greaterThan',
        entityType: 'business',
        fieldName: 'businessSbss',
        value: 170,
      },
      active: true,
    },
    {
      id: uuidv4(),
      name: 'Business DSCR',
      conditionMatcher: {
        type: 'greaterThan',
        entityType: 'application',
        fieldName: 'dscr',
        value: 1.2,
      },
      active: true,
    },
    {
      id: uuidv4(),
      name: 'Global DSCR',
      conditionMatcher: {
        type: 'greaterThan',
        entityType: 'application',
        fieldName: 'globalDscr',
        value: 1.1,
      },
      active: true,
    },
    {
      id: uuidv4(),
      name: 'Loan Amount',
      conditionMatcher: {
        type: 'range',
        entityType: 'application',
        fieldName: 'requestedAmount',
        min: 0,
        max: 50_000,
      },
      active: true,
    },
    {
      id: uuidv4(),
      name: 'Customer Tenure',
      conditionMatcher: {
        type: 'greaterThan',
        entityType: 'application',
        fieldName: '',
        value: 24,
      },
      active: true,
    },
  ]);

  const addCondition = useCallback(
    (condition: CreateCondition) => {
      setConditions([...conditions, condition]);
    },
    [conditions],
  );

  const updateCondition = useCallback(
    (conditionToUpdate: Condition) => {
      setConditions(
        conditions.map((condition) =>
          condition.id === conditionToUpdate.id ? { ...conditionToUpdate, updatedAt: new Date().toISOString() } : condition,
        ),
      );
    },
    [conditions],
  );

  const deleteCondition = useCallback(
    (conditionId: string) => {
      setConditions(conditions.filter((c) => c.id !== conditionId));
    },
    [setConditions, conditions],
  );

  const value = useMemo(
    () => ({ conditions, addCondition, updateCondition, deleteCondition }),
    [conditions, addCondition, updateCondition, deleteCondition],
  );

  return <AutoApprovalContext.Provider value={value}>{children}</AutoApprovalContext.Provider>;
};
