import React from 'react';
import type { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import type { DealApiModel } from '@lama/contracts';
import { sentenceCase } from 'change-case-all';
import { noMatchRegex } from '@lama/data-formatters';
import { BackLink } from '../../../shared/components/BackLink';
import { DealCard } from './DealCard';

interface DealCategoryShowAllProps {
  deals: DealApiModel[];
  category: string;
}

export const DealCategoryShowAll: FC<DealCategoryShowAllProps> = ({ deals, category }: DealCategoryShowAllProps) => (
  <Stack px={10} py={4} spacing={3} bgcolor={'#F6F6F6'} height={'100%'}>
    <Stack spacing={2}>
      <BackLink title={'Back to Deals'} to={'/deals'} />
      <Typography variant={'h6'} color={'text.primary'}>
        {sentenceCase(category, { stripRegexp: noMatchRegex })}
      </Typography>
    </Stack>
    <Stack direction={'row'} gap={6} flexWrap={'wrap'}>
      {deals.map((deal) => (
        <DealCard key={deal.id} deal={deal} category={category} />
      ))}
    </Stack>
  </Stack>
);
