/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Flex, Spinner, Text } from '@lama/design-system';
import { displayToast, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../ApplicationContext';
import { useProductsByPartnerQuery } from '../../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { SubMenuHeader } from './SubMenuHeader';
import { useChangeOpportunityProductMutation } from './hooks/useChangeOpportunityProductMutation';
import { ActionMenuSearchInput } from './ActionMenuSearchInput';

interface ChangeOpportunityProductMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

export const ChangeOpportunityProductMenu: FC<ChangeOpportunityProductMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const {
    opportunity: { id: opportunityId, partnerId: opportunityPartener, productId: opportunityProductId },
  } = useContext(ApplicationContext);

  const [filterOptions, setFilterOptions] = useState('');
  const { t } = useTranslation();
  const { mutateAsync: changeOpportunityProduct } = useChangeOpportunityProductMutation(opportunityId);
  const { data: products } = useProductsByPartnerQuery(opportunityPartener);
  const { confirm } = useConfirmModal();

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const [{ loading }, updateOpportunityProduct] = useAsyncFn(
    async (productId: string) => {
      await changeOpportunityProduct({
        productId,
      });

      handleClose();

      displayToast('Product changed successfully', 'success');
    },
    [changeOpportunityProduct, handleClose],
  );

  const onPickProduct = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      const productId = event.currentTarget.getAttribute('value');

      if (!productId) {
        return;
      }

      const result = await confirm({
        title: t('changeOpportunityProductConfirmModal.title'),
        message: t('changeOpportunityProductConfirmModal.message'),
        cancelText: t('changeOpportunityProductConfirmModal.cancelText'),
        confirmText: t('changeOpportunityProductConfirmModal.confirmText'),
      });

      if (!result) {
        return;
      }

      await updateOpportunityProduct(productId);
    },
    [confirm, t, updateOpportunityProduct],
  );

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const options = useMemo(() => {
    const productOptions = products
      ?.filter(({ id }) => id !== opportunityProductId)
      .map(({ id, prettyName }) => ({ id, name: prettyName }));

    return productOptions?.filter(({ name }) => `${name.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase())) ?? [];
  }, [filterOptions, opportunityProductId, products]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Flex flexDirection={'column'} gap={2} flex={1}>
        <SubMenuHeader onClick={onBack} text={'Products'} />
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Flex pt={2} px={2} maxHeight={'300px'} overflow={'auto'} flexDirection={'column'} flex={1}>
          {options.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={onPickProduct}
              sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}
            >
              <Flex flexDirection={'row'} alignItems={'center'} flex={1} gap={6} pr={6}>
                <Text variant={'body1'} ellipsis>
                  {name}
                </Text>
                {loading ? <Spinner size={'s'} /> : null}
              </Flex>
            </MenuItem>
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
