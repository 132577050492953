import type { FC } from 'react';
import React from 'react';
import { Stack } from '@mui/material';
import { useToggle } from 'react-use';
import { sentenceCase } from 'change-case-all';
import { grey } from '@mui/material/colors';
import type { PartnerRequirement } from '@lama/contracts';
import { Collapse, Text } from '@lama/design-system';
import ExpandButton from '../../../../../shared/components/ExpandButton';
import { RequirementSettingsCard } from './PartnerRequirementSettingsCard';

type ToggleablePartnerRequirement = PartnerRequirement & { active: boolean };

interface PartnerRequirementSettingsCategoryProps {
  requirements: ToggleablePartnerRequirement[];
  onCheck: (requirement: PartnerRequirement) => void;
  category: string;
}

export const PartnerRequirementSettingsCategory: FC<PartnerRequirementSettingsCategoryProps> = ({ requirements, category, onCheck }) => {
  const [open, toggleOpen] = useToggle(true);

  return (
    <Stack gap={2}>
      <Stack direction={'row'} gap={1} onClick={toggleOpen} alignItems={'center'}>
        <ExpandButton expand={open} />
        <Text variant={'body1'} color={grey[500]}>
          {sentenceCase(category)}
        </Text>
      </Stack>
      <Collapse expanded={open}>
        <Stack gap={2}>
          {requirements.map((r) => (
            <RequirementSettingsCard key={r.id} onCheck={onCheck} requirementActive={r.active} requirement={r} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
