import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { CreateDocumentLineItemApiModel } from '@lama/document-service-client';
import { documentServiceClient } from '../../../../clients/documentServiceClient';

interface CreateDocumentLineItems {
  documentId: string;
  lineItem: CreateDocumentLineItemApiModel;
}

export const useCreateDocumentLineItemMutation = (options?: UseMutationOptions<void, unknown, CreateDocumentLineItems>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const createDocumentLineItemMutation = useCallback(
    async ({ documentId, lineItem }: CreateDocumentLineItems) => {
      const token = await getAccessTokenSilently();

      await documentServiceClient.createDocumentLineItems(documentId, { lineItems: [lineItem] }, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: createDocumentLineItemMutation,
    onMutate: async ({ documentId, lineItem }: CreateDocumentLineItems) => {
      await queryClient.cancelQueries({ queryKey: ['documentLineItems', documentId] });

      const previousLineItems = queryClient.getQueryData(['documentLineItems', documentId]);

      queryClient.setQueryData(['documentLineItems', documentId], (old: any) => [lineItem, ...old]);

      await queryClient.invalidateQueries({ queryKey: ['documentLineItems', documentId] });

      return { previousLineItems, documentId };
    },
    onError: (error, variables, context: any) => {
      if (context?.previousLineItems && context?.documentId) {
        queryClient.setQueryData(['documentLineItems', context.documentId], context.previousLineItems);
      }
    },
    onSettled: (b, a, { documentId }) => {
      void queryClient.invalidateQueries({ queryKey: ['documentLineItems', documentId] });
    },
    ...options,
  });
};
