import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateOpportunityRequestBody } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useCreateOpportunityMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutateAsync } = useMutation({
    mutationFn: async (createOpportunityPayload: CreateOpportunityRequestBody) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.createOpportunity(createOpportunityPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity'],
        }),
      ]);
    },
  });

  return mutateAsync;
};
