import { parseDateWithoutTime, parseUTCDatetoString } from '@lama/data-formatters';
import type { Period } from '@lama/selectors';
import { add, differenceInMonths } from 'date-fns';

export const getShortPeriodNameWithoutMonths = (period: Period): string => `${parseUTCDatetoString(parseDateWithoutTime(period.endDate))}`;

export const getShortPeriodName = (period: Period): string => {
  const endDate = parseDateWithoutTime(period.endDate);
  return `${differenceInMonths(add(endDate, { days: 1 }), parseDateWithoutTime(period.startDate))} Months`;
};
