import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { MaterialRoundedIcon } from '@lama/app-components';
import { categoriesDesign } from './types';

export const CategoryLogo: FC<{ category: string }> = ({ category }) => {
  const categoryDesign = categoriesDesign.find((c) => c.name === category) ?? categoriesDesign[0];

  if (!categoryDesign) {
    return null;
  }

  return (
    <Box
      display={'flex'}
      width={'32px'}
      height={'32px'}
      sx={{
        backgroundColor: categoryDesign.iconBackgroundColor,
      }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <MaterialRoundedIcon name={categoryDesign.icon} color={categoryDesign.iconColor} size={'24px'} />
    </Box>
  );
};
