/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { InputAdornment, Slider, TextField } from '@mui/material';
import { useField } from 'formik';
import type { FieldFormatter } from '@lama/data-formatters';
import { formatValue } from '@lama/data-formatters';
import { FormikMoneyInputField, type FormikInputProps } from '@lama/app-components';
import { Flex } from '@lama/design-system';

const hideNumberSpinnersSx: SxProps<Theme> = {
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

type InputType = 'currency' | 'number' | 'percentage';

const inputTypeToFormatter: Record<string, FieldFormatter> = {
  currency: 'currencyCompact',
  number: 'number',
  percentage: 'percentage',
};

export const FormikInputAndSlider: FC<
  FormikInputProps & {
    minAmount: number;
    maxAmount: number;
    name: string;
    label: string;
    sliderStep?: number;
    type: InputType;
  }
> = ({ minAmount, maxAmount, name, label, sliderStep, type }) => {
  const [{ value: fieldValue, ...field }, { error, touched }, { setValue: setFieldValue }] = useField(name);

  const onSliderChange = useCallback(
    async (_event: Event, newValue: number[] | number) => {
      await setFieldValue(newValue.toString());
    },
    [setFieldValue],
  );

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = Number(event.target.value);
      if (newValue < minAmount) {
        newValue = minAmount;
      }

      if (newValue > maxAmount) {
        newValue = maxAmount;
      }

      await setFieldValue(newValue.toString());
    },
    [setFieldValue, minAmount, maxAmount],
  );

  const marks = useMemo(
    () => [
      { value: minAmount, label: `${formatValue(minAmount, inputTypeToFormatter[type])}${type === 'percentage' ? '%' : ''}` },
      { value: maxAmount, label: `${formatValue(maxAmount, inputTypeToFormatter[type])}${type === 'percentage' ? '%' : ''}` },
    ],
    [minAmount, maxAmount, type],
  );

  const inputError = useMemo(() => !!touched && Boolean(error), [error, touched]);

  return (
    <Flex flexDirection={'column'}>
      {type === 'currency' ? (
        <FormikMoneyInputField name={name} label={label} onChange={handleInputChange} />
      ) : (
        <TextField
          label={label}
          type={'number'}
          value={fieldValue}
          {...field}
          error={inputError}
          sx={hideNumberSpinnersSx}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: type === 'percentage' ? <InputAdornment position={'start'}>{'%'}</InputAdornment> : undefined,
          }}
        />
      )}
      <Slider step={sliderStep ?? 1} min={minAmount} max={maxAmount} value={fieldValue} onChange={onSliderChange} marks={marks} />
    </Flex>
  );
};
