/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import type { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Flex, Spinner, Text, greyPalette } from '@lama/design-system';
import { personFullName, formatValue } from '@lama/data-formatters';
import { Divider } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useUpdateOpportunityClosingTaskMutation } from './useUpdateClosingTaskMutation';
import { ClosingTaskCompletedByMenu } from './ClosingTaskOwnerMenu';

import 'react-datepicker/dist/react-datepicker.css';
import { useMarkTaskCompleted } from './ClosingTasksTypes/shared/useMarkTaskCompleted';
import { useMarkTaskInapplicable } from './ClosingTasksTypes/shared/useMarkTaskInapplicable';
import { useMarkTaskPending } from './ClosingTasksTypes/shared/useMarkTaskPending';
import type { ClosingTaskComponentProps } from './ClosingTasksTypes/closingTaskComponentProps';

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '268px',
      maxWidth: '100%',
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

export const TaskCardFooter: FC<ClosingTaskComponentProps> = ({ task }) => {
  const { partner } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partner);
  const { markAsComplete, markingTaskAsComplete } = useMarkTaskCompleted(task);
  const { markAsPending, markingTaskAsPending } = useMarkTaskPending(task);
  const { markAsInapplicable, markingTaskAsInapplicable } = useMarkTaskInapplicable(task);
  const updatingTaskStatus = useMemo(
    () => markingTaskAsComplete || markingTaskAsPending || markingTaskAsInapplicable,
    [markingTaskAsInapplicable, markingTaskAsComplete, markingTaskAsPending],
  );
  const completedByMenuRef = useRef(null);
  const [completedByOpen, toggleCompletedByOpen] = useToggle(false);
  const [completedAt, setCompletedAt] = useState(task.completedAt ? new Date(task.completedAt) : null);
  const onMarkCompletedClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      markAsComplete();
    },
    [markAsComplete],
  );
  const onMarkPendingClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      markAsPending();
    },
    [markAsPending],
  );
  const onMarkInapplicableClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      markAsInapplicable();
    },
    [markAsInapplicable],
  );
  const assignee = useMemo(() => {
    if (!users) {
      return null;
    }

    return users.find((u) => task.assigneeIds?.includes(u.id));
  }, [task.assigneeIds, users]);

  const { mutate: updateOpportunityClosingTask, isPending: updatingClosingTask } = useUpdateOpportunityClosingTaskMutation(
    task.opportunityId,
  );

  const onMenuClose = useCallback(
    (assigneeId?: string) => {
      if (assigneeId) {
        updateOpportunityClosingTask({ taskId: task.id, taskUpdate: { assigneeIds: [assigneeId] } });
      }
      toggleCompletedByOpen();
    },
    [task.id, toggleCompletedByOpen, updateOpportunityClosingTask],
  );

  const onCompletedAtChange = useCallback(
    (date: Date | null) => {
      setCompletedAt(date);

      if (date) {
        updateOpportunityClosingTask({ taskId: task.id, taskUpdate: { completedAt: date.toISOString() } });
      }
    },
    [task.id, updateOpportunityClosingTask],
  );

  const pending = useMemo(() => task.status === 'Pending', [task.status]);
  const isLoading = useMemo(() => updatingTaskStatus || updatingClosingTask, [updatingTaskStatus, updatingClosingTask]);
  return (
    <>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Flex flexDirection={'row'} gap={6} alignItems={'center'} flexGrow={1}>
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {'Completed by'}
            </Text>
            <Flex flexDirection={'row'} gap={2} alignItems={'center'} onClick={toggleCompletedByOpen} ref={completedByMenuRef}>
              <Text variant={'body2'} color={'primary'}>
                {assignee ? personFullName(assignee) : '-'}
              </Text>
              {isLoading ? <Spinner size={'s'} /> : <EditOutlined color={'action'} fontSize={'small'} />}
            </Flex>
          </Flex>
          <Divider orientation={'vertical'} variant={'fullWidth'} flexItem />
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {'Date of completion'}
            </Text>
            <DatePicker
              selected={completedAt}
              disabled={isLoading}
              onChange={onCompletedAtChange}
              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
              customInput={
                <Flex gap={2} alignItems={'center'}>
                  <Text variant={'body2'} color={'primary'}>
                    {task.completedAt ? formatValue(task.completedAt, 'date') : '-'}
                  </Text>
                  {isLoading ? <Spinner size={'s'} /> : <EditOutlined color={'action'} fontSize={'small'} />}
                </Flex>
              }
              dropdownMode={'select'}
            />
          </Flex>
        </Flex>
        <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
          {task.status !== 'Dismissed' ? (
            <LoadingButton
              loading={isLoading}
              onClick={onMarkInapplicableClick}
              size={'large'}
              variant={'text'}
              sx={{ color: greyPalette[500] }}
            >
              {'Dismiss'}
            </LoadingButton>
          ) : null}
          <LoadingButton
            loading={isLoading}
            onClick={!pending ? onMarkPendingClick : onMarkCompletedClick}
            variant={!pending ? 'outlined' : 'contained'}
            size={'large'}
          >
            {`Mark as ${!pending ? 'Pending' : 'Complete'}`}
          </LoadingButton>
        </Flex>
      </Flex>
      <ClosingTaskCompletedByMenu
        menuRef={completedByMenuRef}
        open={completedByOpen}
        users={users}
        menuProps={menuProps}
        onClose={onMenuClose}
      />
    </>
  );
};
