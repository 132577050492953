import type { FC } from 'react';
import React, { useRef } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useToggle } from 'react-use';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Text, grayPalette } from '@lama/design-system';

interface EngagementCardSettingsProps {
  options: string[];
}
export const EngagementCardSettings: FC<EngagementCardSettingsProps> = ({ options }) => {
  const [open, toggleOpen] = useToggle(false);
  const menuRef = useRef(null);

  return (
    <>
      <IconButton onClick={toggleOpen} sx={{ borderRadius: '8px', padding: 0 }} ref={menuRef} role={'button'} disableRipple>
        <MoreVertIcon height={'24px'} width={'24px'} />
      </IconButton>
      <Menu
        anchorEl={menuRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={toggleOpen}
        MenuListProps={{ sx: { py: 1, px: 0.5 } }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={toggleOpen}>
            <Text variant={'body2'} color={grayPalette[700]}>
              {option}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
