import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export const useLogoutWithRedirect = () => {
  const { logout } = useAuth0();

  return useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    mixpanel.reset();
    logout({ returnTo: window.location.origin });
  }, [logout]);
};
