import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { Flex } from '@lama/design-system';
import {
  FormikAutoSave,
  GenericPropertiesGrid,
  createUpdatePayload,
  formValuesToEntityPartial,
  getBusinessIdByEntity,
  getInitialFormValues,
  getValidationSchema,
} from '@lama/app-components';
import type { SourcedProperty } from '@lama/properties';
import { getSourcedProperty } from '@lama/properties';
import type { Entity } from '@lama/common-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useUpdateBusiness } from '../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { useUpdatePerson } from '../../../../shared/hooks/react-query/people/useUpdatePerson';
import { SaveFormButton } from '../../shared/SaveFormButton';
import { getFormProperties } from '../../../../shared/utils/getFormProperties';
import type { PropertyKeyWithFormConfig } from '../../../../shared/utils/getFormProperties';
import { SegmentContainer } from '../../shared/SegmentContainer';

export const GenericSegmentForm: FC<{
  entity: Record<string, any> & { id: string };
  entityType: Entity;
  propertyDefinitions: PropertyKeyWithFormConfig[];
  title?: string;
}> = ({ entity, entityType, propertyDefinitions, title }) => {
  const { opportunity, application, properties } = useContext(ApplicationContext);
  const formProperties = useMemo(() => getFormProperties(propertyDefinitions, properties), [properties, propertyDefinitions]);
  const [submitted, setSubmitted] = useState(false);
  const { mutateAsync: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);
  const { autoSaveEnabled } = useFlags();

  const propertiesWithDecidedSource = useMemo<SourcedProperty[]>(() => {
    const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;

    return formProperties.map((p) => getSourcedProperty(p, entity, application, yearsBack)) ?? [];
  }, [entity, opportunity.referenceYear, application, formProperties]);

  const initialValues = useMemo(() => getInitialFormValues(propertiesWithDecidedSource, entity), [entity, propertiesWithDecidedSource]);

  const validationSchema = useMemo(() => getValidationSchema({ properties: formProperties }), [formProperties]);

  const onSubmit = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      setSubmitted(true);
      resetForm({ values });

      const entityPartial = formValuesToEntityPartial(values, initialValues, formProperties);

      const updatePayload = createUpdatePayload(application, entityType, entity.id, entityPartial);

      if (!isEmpty(updatePayload.updateOpportunityPayload)) {
        await updateOpportunity(updatePayload.updateOpportunityPayload);
      }

      if (!isEmpty(updatePayload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: updatePayload.updateApplicationPayload });
      }

      if (!isEmpty(updatePayload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entity.id);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: updatePayload.updateBusinessPayload });
        }
      }

      if (!isEmpty(updatePayload.updatePersonPayload)) {
        await updatePerson({ personId: entity.id, updatePersonPayload: updatePayload.updatePersonPayload });
      }
    },
    [application, entity.id, entityType, initialValues, formProperties, updateApplication, updateBusiness, updateOpportunity, updatePerson],
  );

  const isUpdating = useMemo(
    () => updatingOpportunity || updatingApplication || updatingBusiness || updatingPerson,
    [updatingOpportunity, updatingApplication, updatingBusiness, updatingPerson],
  );

  return (
    <SegmentContainer title={title} bordered={false}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <Flex key={`${entity.id}-form`} gap={4} flexWrap={'wrap'} flexDirection={'row'} width={'100%'}>
            <GenericPropertiesGrid
              properties={propertiesWithDecidedSource}
              entityType={'opportunity'}
              submitted={submitted}
              entity={opportunity}
              application={application}
            />
            {autoSaveEnabled ? (
              <FormikAutoSave />
            ) : (
              <SaveFormButton loading={isUpdating} submit={handleSubmit} disabled={!dirty || !isValid} />
            )}
          </Flex>
        )}
      </Formik>
    </SegmentContainer>
  );
};
