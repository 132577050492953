/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useContext, useCallback } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ConfirmLeave, FormikMoneyInputField, useIsMobile } from '@lama/app-components';
import { t } from 'i18next';
import type { FinancialData } from '@lama/contracts';
import { getApplicationEntityByType } from '@lama/properties';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import type { RequirementScreenProps } from '../types';
import { SaveFormButton } from '../../../../shared/SaveFormButton';
import { getFinancialFieldByYear, getFinancialPayload } from './financialFieldUtils';
import { useSubmitFinancialsMutation } from './hooks/useSubmitFinancialsMutation';

const validationSchema = yup.object({
  gross1: yup.number().min(0),
  netOperating1: yup.number(),
  gross2: yup.number().min(0),
  netOperating2: yup.number(),
  gross3: yup.number().min(0),
  netOperating3: yup.number(),
  revolvingDebtBalance: yup.number(),
  revolvingDebt: yup.number(),
});

interface RevenueScreenInnerFormProps {
  formikProps: FormikProps<any>;
  currentYear: number;
  savingFinancial: boolean;
}

const RevenueScreenInnerForm: FC<RevenueScreenInnerFormProps> = ({
  formikProps: { dirty, isValid, handleSubmit },
  currentYear,
  savingFinancial,
}) => {
  const isMobile = useIsMobile();

  return (
    <ConfirmLeave shouldBlock={dirty}>
      <Form onSubmit={handleSubmit}>
        <Stack gap={4}>
          <Stack gap={2}>
            <Typography variant={'body1'} color={'text.disabled'}>
              {currentYear} {'YTD'}
            </Typography>
            <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
              <FormikMoneyInputField name={'gross1'} label={`${t('revenue.gross')}`} />
              <FormikMoneyInputField name={'netOperating1'} label={`${t('revenue.netOperating')}`} />
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography variant={'body1'} color={'text.disabled'}>
              {currentYear - 1}
            </Typography>
            <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
              <FormikMoneyInputField name={'gross2'} label={`${t('revenue.gross')}`} />
              <FormikMoneyInputField name={'netOperating2'} label={`${t('revenue.netOperating')}`} />
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography variant={'body1'} color={'text.disabled'}>
              {currentYear - 2}
            </Typography>
            <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
              <FormikMoneyInputField name={'gross3'} label={`${t('revenue.gross')}`} />
              <FormikMoneyInputField name={'netOperating3'} label={`${t('revenue.netOperating')}`} />
            </Stack>
          </Stack>
          <Divider />
          <Stack gap={2}>
            <Typography variant={'body1'} color={'text.disabled'}>
              {'Others'}
            </Typography>
            <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
              <FormikMoneyInputField name={'revolvingDebtBalance'} label={`${t('revenue.revolvingDebtBalance')}`} />
              <FormikMoneyInputField name={'revolvingDebt'} label={`${t('revenue.revolvingDebt')}`} />
            </Stack>
          </Stack>
          <SaveFormButton loading={savingFinancial} submit={handleSubmit} disabled={!dirty || !isValid} />
        </Stack>
      </Form>
    </ConfirmLeave>
  );
};

interface RevenueValues {
  gross1: string;
  netOperating1: string;
  gross2: string;
  netOperating2: string;
  gross3: string;
  netOperating3: string;
  revolvingDebtBalance: string;
  revolvingDebt: string;
}

export const RevenueScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    requirement.entityId,
    requirement.entityType,
    opportunityId,
  );
  const currentYear = new Date().getUTCFullYear();

  const entityFinancials = useMemo<FinancialData[]>(
    () =>
      getApplicationEntityByType(application, requirement.entityType, ['all']).find(({ id }) => id === requirement.entityId)?.financials ??
      [],
    [application, requirement.entityId, requirement.entityType],
  );

  // "Gross Income", "Net Operating income", "Debt Service" pulled for backwards compatibility
  const initialValues: RevenueValues = useMemo(
    () => ({
      gross1:
        getFinancialFieldByYear(entityFinancials, currentYear, 'Net Revenue')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear, 'Gross Income')?.value?.toString() ??
        '',
      netOperating1:
        getFinancialFieldByYear(entityFinancials, currentYear, 'Net Operating Income')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear, 'Net Operating income')?.value?.toString() ??
        '',
      gross2:
        getFinancialFieldByYear(entityFinancials, currentYear - 1, 'Net Revenue')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear - 1, 'Gross Income')?.value?.toString() ??
        '',
      netOperating2:
        getFinancialFieldByYear(entityFinancials, currentYear - 1, 'Net Operating Income')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear, 'Net Operating income')?.value?.toString() ??
        '',
      gross3:
        getFinancialFieldByYear(entityFinancials, currentYear - 2, 'Net Revenue')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear - 2, 'Gross Income')?.value?.toString() ??
        '',
      netOperating3:
        getFinancialFieldByYear(entityFinancials, currentYear - 2, 'Net Operating Income')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, currentYear, 'Net Operating income')?.value?.toString() ??
        '',
      revolvingDebtBalance:
        getFinancialFieldByYear(entityFinancials, currentYear, 'Business Revolving Credit Balance')?.value?.toString() ?? '',
      revolvingDebt: getFinancialFieldByYear(entityFinancials, currentYear, 'Business Revolving Credit Limit')?.value?.toString() ?? '',
    }),
    [currentYear, entityFinancials],
  );

  const submitFinancial = useCallback(
    async (newValues: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
    const { gross1, gross2, gross3, netOperating1, netOperating2, netOperating3, revolvingDebt, revolvingDebtBalance } =
        newValues;

      resetForm({ values: newValues });
      // "Gross Income", "Net Operating income", "Debt Service" pulled for backwards compatibility
      const revenuePayload = userId
        ? [
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear,
              fieldName: 'Net Revenue',
              value: gross1,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear,
                fieldName: 'Gross Income',
                value: gross1,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear,
              fieldName: 'Net Operating Income',
              value: netOperating1,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear,
                fieldName: 'Net Operating income',
                value: netOperating1,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear - 1,
              fieldName: 'Net Revenue',
              value: gross2,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear - 1,
                fieldName: 'Gross Income',
                value: gross1,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear - 1,
              fieldName: 'Net Operating Income',
              value: netOperating2,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear - 1,
                fieldName: 'Net Operating income',
                value: netOperating2,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear - 2,
              fieldName: 'Net Revenue',
              value: gross3,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear - 2,
                fieldName: 'Gross Income',
                value: gross3,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear - 2,
              fieldName: 'Net Operating Income',
              value: netOperating3,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: currentYear - 2,
                fieldName: 'Net Operating income',
                value: netOperating2,
                userId,
              })),
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear,
              fieldName: 'Business Revolving Credit Balance',
              value: revolvingDebtBalance,
              userId,
            }),
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: currentYear,
              fieldName: 'Business Revolving Credit Limit',
              value: revolvingDebt,
              userId,
            }),
          ]
        : [];

      if (revenuePayload.length) {
        await updateFinancialData(revenuePayload);
      }
    },
    [userId, requirement.entityId, entityFinancials, currentYear, updateFinancialData],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitFinancial}>
      {(formikProps) => <RevenueScreenInnerForm formikProps={formikProps} currentYear={currentYear} savingFinancial={savingFinancials} />}
    </Formik>
  );
};
