import { useAuth0 } from '@auth0/auth0-react';
import { useQueries } from '@tanstack/react-query';
import type { ApplicationApiModel } from '@lama/clients';
import { compact } from 'lodash-es';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useEvaluateApplicationForProductsQuery = (productIds: string[], application?: ApplicationApiModel) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQueries({
    queries: productIds.map((productId) => ({
      queryKey: ['evaluateApplicationForProduct', application?.id, productId],
      queryFn: async () => {
        if (!application) {
          return { productId, evaluatedConditions: [] };
        }
        const token = await getAccessTokenSilently();

        const { evaluatedConditions } = await applicationServiceClient.evaluateApplicationForProduct(application, productId, token);
        return { productId, evaluatedConditions };
      },
    })),
    combine: (results) => ({
      pending: results.some((result) => result.isPending),
      data: compact(results.map((result) => result.data)),
    }),
  });
};
