import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../../shared/SegmentContainer';

export const FirstBankDecision = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'Date'} value={'03/28/2023'} />
        <KeyValuePair name={'Status'} value={'APPROVE'} />
        <KeyValuePair name={'Amt Requested'} value={'$100,000.00'} />
        <KeyValuePair name={'Amt Approved'} value={'$100,000.00'} />
        <KeyValuePair name={'Product'} value={'First Premier Invest LOC - MO'} />
        <KeyValuePair name={'Purpose'} value={'Establish Line of Credit-NonHMDA'} />
        <KeyValuePair name={'Application ID'} value={'226111'} />
      </Grid>
    </SegmentContainer>
  </Flex>
);
