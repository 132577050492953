import { ResponsiveBar, type BarTooltipProps } from '@nivo/bar';
import React, { useMemo, type FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { GetOfacChartDataResponse } from '@lama/reports-service-client';
import { applicationStatusToColors } from '../../../../shared/utils/applicationStatusToColors';
import { formatChartAxisValue, getChartDataByWeeks } from './chartUtils.js';

interface ChartDatum {
  weekText: string;
  week: string;
  loading?: boolean;
  amount: number;
}

const tableMargins = {
  top: 20,
  right: 0,
  bottom: 90,
  left: 10,
};

const BarToolTip: FC<BarTooltipProps<ChartDatum>> = ({ formattedValue }) => (
  <Flex bg={'#ffffff'} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={1} px={2}>
    <Text variant={'body3'}>{formattedValue}</Text>
  </Flex>
);

const { color: onboardingCompleteColor } = applicationStatusToColors.OnboardingCompleted;

export interface OfacResultsByDateChartProps {
  chartData: GetOfacChartDataResponse | null | undefined;
}

export const OfacResultsByDateChart: FC<OfacResultsByDateChartProps> = ({ chartData }) => {
  const chartDataByWeeks = useMemo(() => {
    if (!chartData) {
      return [];
    }

    const chartDataWithTimestamp: (Object & { itemTimestamp: string })[] =
      chartData?.map((item) => ({ ...item, itemTimestamp: item.ofacSearchFindingsDate })) ?? [];

    return chartDataWithTimestamp.length ? getChartDataByWeeks(chartDataWithTimestamp) : [];
  }, [chartData]);

  return (
    <Flex flex={1} height={'280px'} maxHeight={'280px'} justifyContent={'center'} alignItems={'center'}>
      {!chartDataByWeeks?.length ? (
        <Text variant={'body2'}>{'No applications to display for the selected time range.'}</Text>
      ) : (
        <ResponsiveBar
          data={chartDataByWeeks}
          keys={['amount']}
          indexBy={'weekText'}
          colors={onboardingCompleteColor}
          margin={tableMargins}
          padding={0.5}
          enableGridX={false}
          enableGridY={false}
          enableLabel={false}
          axisLeft={{
            tickSize: 1,
            tickPadding: -15,
            format: formatChartAxisValue,
          }}
          axisBottom={{
            tickSize: 0,
            tickRotation: 45,
          }}
          tooltip={BarToolTip}
        />
      )}
    </Flex>
  );
};
