import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { SpreadRow } from '@lama/spreads-generator-client';
import { compact, isNil, round } from 'lodash-es';
import { getShortPeriodNameWithoutMonths } from '../utils/getShortPeriodName';
import { OpportunityAlertView } from '../../../Summary/OpportunityAlertView';

const getErrorMessageIfAssetsAndLiabilitiesAndNetWorthNotEqual = ({
  rows,
  sourceType1,
  sourceType2,
}: {
  rows: SpreadRow[];
  sourceType1: string;
  sourceType2: string;
}): string | null => {
  const row1 = rows?.find((row) =>
    Object.values(row.valueByPeriod).some(({ financialAttribute }) => financialAttribute?.selectedSource.type === sourceType1),
  );
  const row2 = rows?.find((row) =>
    Object.values(row.valueByPeriod).some(({ financialAttribute }) => financialAttribute?.selectedSource.type === sourceType2),
  );

  const periodsWithValueDifferences = compact(
    Object.keys(row1?.valueByPeriod ?? {}).map((period) => {
      const row1Value = row1?.valueByPeriod[period]?.financialAttribute?.value;
      const row2Value = row2?.valueByPeriod[period]?.financialAttribute?.value;

      const roundedRow1Value = isNil(row1Value) ? row1Value : round(row1Value, 2);
      const roundedRow2Value = isNil(row2Value) ? row2Value : round(row2Value, 2);

      if (roundedRow1Value === roundedRow2Value) {
        return null;
      }

      const periodObject = row1?.valueByPeriod[period]?.financialAttribute?.selectedSource.period;
      if (!periodObject) {
        return null;
      }

      return getShortPeriodNameWithoutMonths(periodObject);
    }),
  );

  return periodsWithValueDifferences.length
    ? `Detected an imbalance between ASSETS and LIABILITIES & NET WORTH for: ${periodsWithValueDifferences.join(' and ')}`
    : null;
};

export const SpreadErrorForGrasshopper: FC<{ spreadRows: SpreadRow[] }> = ({ spreadRows }) => {
  const errorForBusiness = useMemo(
    () =>
      getErrorMessageIfAssetsAndLiabilitiesAndNetWorthNotEqual({
        rows: spreadRows,
        sourceType1: 'Personal Assets',
        sourceType2: 'Personal Liabilities & Net Worth',
      }),
    [spreadRows],
  );

  const errorForIndividuals = useMemo(
    () =>
      getErrorMessageIfAssetsAndLiabilitiesAndNetWorthNotEqual({
        rows: spreadRows,
        sourceType1: 'Assets',
        sourceType2: 'Liabilities & Equity',
      }),
    [spreadRows],
  );

  const error = errorForBusiness ?? errorForIndividuals;

  return error ? <OpportunityAlertView message={error} actions={[]} params={{}} /> : null;
};
