import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { DocumentLineItem, UpdateDocumentLineItemBody } from '@lama/document-service-client';
import { documentServiceClient } from '../../../../clients/documentServiceClient';

interface UpdateDocumentLineItem {
  documentId: string;
  lineItemId: string;
  lineItemUpdate: UpdateDocumentLineItemBody;
}

export const useUpdateDocumentLineItemMutation = (options?: UseMutationOptions<void, unknown, UpdateDocumentLineItem>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const updateDocumentLineItem = useCallback(
    async ({ documentId, lineItemId, lineItemUpdate }: UpdateDocumentLineItem) => {
      const token = await getAccessTokenSilently();

      await documentServiceClient.updateDocumentLineItem(documentId, lineItemId, lineItemUpdate, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: updateDocumentLineItem,
    onMutate: async ({ lineItemId, lineItemUpdate, documentId }: UpdateDocumentLineItem) => {
      await queryClient.cancelQueries({ queryKey: ['documentLineItems', documentId] });

      const previousLineItems = queryClient.getQueryData(['documentLineItems', documentId]);

      queryClient.setQueryData(
        ['documentLineItems', documentId],
        (oldLineItems: DocumentLineItem[] | undefined) =>
          oldLineItems?.map((lineItem) => (lineItem.id === lineItemId ? { ...lineItem, ...lineItemUpdate } : lineItem)) ?? [],
      );

      return { previousLineItems, documentId };
    },
    onError: (error, variables, context: any) => {
      if (context?.previousLineItems && context?.documentId) {
        queryClient.setQueryData(['documentLineItems', context.documentId], context.previousLineItems);
      }
    },
    onSettled: (b, a, { documentId }) => {
      void queryClient.invalidateQueries({ queryKey: ['documentLineItems', documentId] });
    },
    ...options,
  });
};
