import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

const headers = [
  { text: 'Fee Type', width: '30%' },
  { text: 'Amount', width: '30%' },
  { text: 'Comments', width: '40%' },
];

export const GrasshopperFeesSegment: FC = () => {
  const {
    application: { fees },
  } = useContext(ApplicationContext);

  const rows = useMemo(
    () => fees?.map<CellContent[]>((item) => [{ value: item.type }, { value: item.amount, type: 'currency' }, { value: item.comments }]),
    [fees],
  );

  if (!rows) {
    return null;
  }

  return <CreditMemoTable headers={headers} rows={rows} />;
};
