import type { AccountData } from '@lama/contracts';
import { Button, Card, CardContent, Collapse } from '@mui/material';
import { Avatar, KeyValuePair } from '@lama/app-components';
import React, { useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { sentenceCase } from 'change-case-all';
import { ArrowForward } from '@mui/icons-material';
import { formatValue } from '@lama/data-formatters';
import { Flex, Grid, Text } from '@lama/design-system';
import { sumBy } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { compareAsc, endOfMonth, format, isBefore, isSameDay, parse, subMonths } from 'date-fns';
import ExpandButton from '../../../../../shared/components/ExpandButton';
import { SegmentContainer } from '../../../shared/SegmentContainer';
import { AccountOwners } from './AccountOwners';
import { Charts } from './Charts';
import { TransactionsDialog } from './Transactions/TransactionsDialog';

interface AccountCardProps {
  account: AccountData;
  institution: { logo?: string; name?: string; institutionId: string };
  expand: boolean;
}

const parsePlaidDate = (date: string) => parse(date, 'yyyy-MM-dd', new Date());

const getLastDayBalancesLast9Months = (balances: { date: string; current: number }[]) => {
  balances.sort((a, b) => compareAsc(parsePlaidDate(a.date), parsePlaidDate(b.date)));

  // Find the latest date in the data
  const latestDate = parsePlaidDate(balances.at(-1)!.date);

  // Determine the last full month's end date
  const lastFullMonth = isBefore(endOfMonth(latestDate), latestDate) ? endOfMonth(latestDate) : endOfMonth(subMonths(latestDate, 1));

  // Generate the last days of the previous 9 full months
  const lastDaysOfMonths = Array.from({ length: 9 }, (_, i) => endOfMonth(subMonths(lastFullMonth, i)));

  // Find the balance for the last day of each month
  const results = lastDaysOfMonths.map((lastDay) => {
    const balanceForLastDay = balances.find(({ date }) => isSameDay(parsePlaidDate(date), lastDay));
    return {
      date: format(lastDay, 'yyyy-MM-dd'),
      balance: balanceForLastDay ? balanceForLastDay.current : 0, // Default to 0 if no balance found
    };
  });

  return results.reverse(); // Reverse to show months in chronological order
};

const BalanceSummary: React.FC<{ balances: { date: string; current: number }[] }> = ({ balances }) => {
  const { spreadingBackOfficeMode } = useFlags();

  const lastBalanceOfMonths = useMemo(() => getLastDayBalancesLast9Months(balances), [balances]);

  if (!spreadingBackOfficeMode) {
    return null;
  }

  return (
    <SegmentContainer title={'Balance Summary'}>
      <Grid columns={3}>
        {lastBalanceOfMonths.map(({ date, balance }) => (
          <KeyValuePair key={date} name={date} value={balance} type={'currency'} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};

export const AccountCard: React.FC<AccountCardProps> = ({ account, institution, expand }) => {
  const [expanded, toggleExpanded] = useToggle(expand ?? false);
  const averageBalance = useMemo(() => {
    const exisitingBalances = account.balances.filter((balance) => balance?.date);

    if (!exisitingBalances.length) {
      return null;
    }

    return sumBy(account.balances, (balance) => balance?.current ?? 0) / exisitingBalances.length;
  }, [account.balances]);

  const insitutionLogoBase64 = useMemo(() => {
    const base64Initial = 'data:image/png;base64,';

    if (institution.logo?.slice(0, Math.max(0, base64Initial.length)) === base64Initial) {
      return institution.logo;
    }

    return base64Initial + institution.logo;
  }, [institution.logo]);

  const positive = useMemo(() => averageBalance && averageBalance >= 0, [averageBalance]);

  const [open, setOpen] = useState(false);

  const onClickCard = useCallback(() => {
    toggleExpanded();
  }, [toggleExpanded]);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Card
      elevation={0}
      variant={'outlined'}
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiCard-root': {
          border: 'border: 1px solid #E0E0E0',
        },
        padding: '12px 24px',
      }}
    >
      <CardContent
        sx={{
          p: 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:last-child': {
            paddingBottom: 0,
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={onClickCard}
      >
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex gap={2} alignItems={'center'}>
            <Avatar
              imgSrc={insitutionLogoBase64}
              imgSize={'24px'}
              size={32}
              firstName={institution.name ?? '-'}
              lastName={institution.name?.split(' ')?.[1] ?? ''}
            />
            <Text variant={'body2'}>{institution.name ?? '-'}</Text>
            <Text variant={'h4'} color={'grey.300'}>
              {'\u00B7'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>{`****${account.mask}`}</Text>
            <Text variant={'h4'} color={'grey.300'}>
              {'\u00B7'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>
              {sentenceCase(account.type)}
            </Text>
          </Flex>
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {'Avg. daily balance: '}
              <Text variant={'body2'} color={averageBalance ? (positive ? 'green.500' : 'red.500') : 'secondary'}>
                {averageBalance === null ? 'N/A' : `${positive ? '+' : ''}${formatValue(averageBalance, 'currency')}`}
              </Text>
            </Text>
            <ExpandButton expand={expanded} />
          </Flex>
        </Flex>
      </CardContent>
      <Collapse in={expanded} timeout={'auto'} unmountOnExit>
        <CardContent>
          <Flex flexDirection={'column'} gap={8}>
            <Charts account={account} />
            <Button
              endIcon={<ArrowForward />}
              variant={'contained'}
              sx={{ maxWidth: '240px' }}
              onClick={openDialog}
              disabled={!account.transactions.length}
            >
              {'Show Transactions'}
            </Button>
            <AccountOwners owners={account.owners} />
            <BalanceSummary balances={account.balances} />
          </Flex>
        </CardContent>
      </Collapse>
      <TransactionsDialog transactions={account.transactions} open={open} onClose={closeDialog} />
    </Card>
  );
};
