import type {
  FormPropertyFields,
  PartnerProperty,
  PropertiesRecord,
  RequirementDefinitionProperty,
  RequirementProperty,
} from '@lama/contracts';

export type PropertyKeyWithFormConfig = FormPropertyFields &
  Pick<PartnerProperty, 'valueOptions'> &
  Pick<RequirementDefinitionProperty, 'childProperties' | 'propertyKey'>;

export const getFormProperties = (
  formPropertiesConfiguration: PropertyKeyWithFormConfig[],
  properties: PropertiesRecord,
): RequirementProperty[] =>
  formPropertiesConfiguration.flatMap((p) => {
    const property = properties[p.propertyKey ?? ''];
    if (!property) {
      return [];
    }
    return [
      {
        ...property,
        ...p,
        childProperties: getFormProperties(p.childProperties ?? [], properties),
        displayName: p.displayNameOverride ?? property.displayName,
      },
    ];
  });
