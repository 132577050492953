import React, { useContext, type FC, useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import writeXlsxFile from 'write-excel-file';
import type { NoaExportRow } from '@lama/reports-service-client';
import { noaReportExcelSchema } from '@lama/reports-service-client';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation.js';
import { ReportsFilterContext } from '../ReportsFilterContext.js';
import { ReportCard } from '../ReportCard.js';
import { ApplicationsWeeklyChart } from '../Charts/ApplicationsWeeklyChart.js';
import { useNoaChartData } from '../Charts/hooks/useNoaChartData.js';

const REPORT_NAME = 'NOA';

export const NoaReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { mutateAsync: downloadReport, isPending: downloadingReport } = useDownloadReportMutation('Noa');
  const { data: chartData, isPending: loadingChartData } = useNoaChartData({ endDate, startDate, productId, partnerId });

  const onClickDownload = useCallback(async () => {
    if (!startDate || !endDate || !partnerId || !productId) {
      displayToast(`${REPORT_NAME} report download unavailable, please contact support`, 'info');

      console.error('some params are missing for downloading', REPORT_NAME, { partnerId, productId, startDate, endDate });

      return;
    }

    const filename = `${REPORT_NAME} Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const data: NoaExportRow[] = await downloadReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    await writeXlsxFile(data, {
      schema: noaReportExcelSchema,
      fileName: filename,
    });
  }, [downloadReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || downloadingReport}
      actionText={'Download Report'}
      onClickAction={onClickDownload}
      actionLoading={downloadingReport}
      loading={loadingChartData}
      title={`${REPORT_NAME} Report`}
    >
      <ApplicationsWeeklyChart chartData={chartData?.chartData} />
    </ReportCard>
  );
};
