// DocumentHubFilters.tsx

import React, { useCallback, useEffect, useState } from 'react';
import { Input, Flex, ButtonGroup } from '@lama/design-system';
import { Autocomplete, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import type { ButtonGroupItem } from '@lama/design-system';
import mixpanel from 'mixpanel-browser';
import { Search } from '@mui/icons-material';
import type { DocumentBoxDetails } from './types';

interface LabeledValue {
  value: string;
  label: string;
}

export const autoCompleteFilterOptions: LabeledValue[] = [
  { label: 'All', value: 'all' },
  { label: 'Missing', value: 'missing' },
  { label: 'Uploaded', value: 'uploaded' },
];

type GroupingOptions = 'entity' | 'recent' | 'topic';

const groupingOptions: ButtonGroupItem[] = [
  {
    value: 'entity',
    label: 'Entity',
  },
  {
    value: 'topic',
    label: 'Topic',
  },
  {
    value: 'recent',
    label: 'Recent',
  },
];

interface DocumentFilterProps {
  documents: DocumentBoxDetails[];
  searchQuery: string;
  groupedBy: GroupingOptions;
  filterBy: LabeledValue;
  onGroupChange: (groupedBy: GroupingOptions) => void;
  onFilterChange: (newFilter: LabeledValue) => void;
  onSearchChange: (newSearch: string) => void;
}

const renderInput = ({ InputProps, ...params }: any) => (
  <TextField label={'Filter'} variant={'outlined'} InputProps={{ ...InputProps, sx: { borderRadius: '8px' } }} {...params} size={'small'} />
);

export const DocumentHubFilters: React.FC<DocumentFilterProps> = ({
  groupedBy,
  onGroupChange,
  onSearchChange,
  searchQuery,
  filterBy,
  onFilterChange,
}) => {
  const [internalValue, setInternalValue] = useState(searchQuery);

  useEffect(() => {
    setInternalValue(searchQuery);
  }, [searchQuery]);

  const onSearchChangeInner = useCallback(
    (newValue: string) => {
      if (newValue === searchQuery) {
        return;
      }

      onSearchChange(newValue);
    },
    [searchQuery, onSearchChange],
  );

  const debouncedSearch = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChangeInner(event.target.value);
  }, 350);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(event.target.value);
      debouncedSearch(event);
    },
    [debouncedSearch],
  );

  const onFilterChangeInner = useCallback(
    (event: React.SyntheticEvent, newValue: LabeledValue | null) => {
      if (newValue) {
        onFilterChange(newValue);
      }
    },
    [onFilterChange],
  );

  const handleGroupChange = useCallback(
    (value: string) => {
      onGroupChange(value as GroupingOptions);

      mixpanel.track('DocumentHub - Group Changed', {
        page: 'documentHub',
        groupBy: value,
      });
    },
    [onGroupChange],
  );

  const renderAutocompleteInput = useCallback(renderInput, []);

  const isOptionEqualToValue = useCallback((option: LabeledValue, value: LabeledValue) => option.value === value.value, []);
  const getOptionLabel = useCallback((option: LabeledValue) => option.label, []);

  return (
    <Flex flexDirection={'row'} gap={1} py={2} alignItems={'center'} justifyContent={'space-between'}>
      <ButtonGroup items={groupingOptions} value={groupedBy} onChange={handleGroupChange} />
      <Flex flexDirection={'row'} gap={2} flex={0.6} alignItems={'center'} justifySelf={'flex-end'}>
        <Flex flexDirection={'row'} flex={0.4}>
          <Autocomplete
            id={'filter'}
            value={filterBy}
            renderInput={renderAutocompleteInput}
            onChange={onFilterChangeInner}
            options={autoCompleteFilterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            disableClearable
            fullWidth
          />
        </Flex>
        <Flex flexDirection={'row'} flex={0.6}>
          <Input placeholder={'Search'} value={internalValue} onChange={onChange} $size={'m'} startIcon={<Search />} fullWidth />
        </Flex>
      </Flex>
    </Flex>
  );
};
