import type { FC } from 'react';
import React, { useContext } from 'react';

import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';
import { SizeStandardTable } from './SizeStandardTable';

export const SizeStandardSegment: FC = () => {
  const {
    application,
    opportunity: { referenceYear },
  } = useContext(ApplicationContext);

  return (
    <SegmentContainer title={'Size Standard'} bordered={false}>
      <SizeStandardTable application={application} referenceYear={referenceYear} />
    </SegmentContainer>
  );
};
