import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import {
  applicationBorrowingRelatedBusinessesSelector,
  businessName,
  personFullName,
  applicationGuarantorsSelector,
} from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../SegmentContainer';
import { ApplicationContext } from '../../ApplicationContext';

export const LoanTermsBorrowerDetailsSegment: FC = () => {
  const { application } = useContext(ApplicationContext);
  const { fibtDemo } = useFlags();

  const borrowerNames = useMemo(
    () =>
      applicationBorrowingRelatedBusinessesSelector(application)
        .map((b) => businessName(b.business))
        .join(', '),
    [application],
  );

  const guarantorNames = useMemo(
    () =>
      applicationGuarantorsSelector(application)
        ?.map((guarantor) => personFullName(guarantor))
        .join(', '),
    [application],
  );

  return (
    <SegmentContainer title={`${fibtDemo ? 'Application' : 'Borrowing'} Structure`}>
      <Grid columns={2}>
        <KeyValuePair name={`${fibtDemo ? 'Applicant' : 'Borrower'}(s)`} value={borrowerNames} />
        <KeyValuePair name={`${fibtDemo ? 'Owner' : 'Guarantor'}(s)`} value={guarantorNames} />
      </Grid>
    </SegmentContainer>
  );
};
