import React from 'react';
import type { UnderwritingNoteType } from '@lama/contracts';
import { BookmarkAdded, Bookmark, Chat } from '@mui/icons-material';
import { amber, red, grey } from '@mui/material/colors';

export const noteTypeToIcon = (noteType?: UnderwritingNoteType) => {
  switch (noteType) {
    case 'exception': {
      return <BookmarkAdded sx={{ color: amber[700], width: '18px', height: '18px' }} />;
    }
    case 'flag': {
      return <Bookmark sx={{ color: red[400], width: '18px', height: '18px' }} />;
    }
    case 'general': {
      return <Chat sx={{ color: grey[500], width: '18px', height: '18px' }} />;
    }
    default: {
      return null;
    }
  }
};
