import React from 'react';
import { useTheme } from '@mui/material';

export const EmptyDataImage: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width={'257'} height={'201'} viewBox={'0 0 257 201'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <rect x={'20.5'} y={'10.5'} width={'200.875'} height={'139'} rx={'7.5'} fill={'white'} stroke={'black'} />
      <rect x={'27'} y={'17'} width={'187.88'} height={'126'} rx={'4'} fill={'#E0E0E0'} />
      <rect x={'27'} y={'17'} width={'187.88'} height={'126'} rx={'4'} fill={'#E0E0E0'} />
      <rect x={'33.3613'} y={'81.8271'} width={'103.932'} height={'56.1729'} rx={'4'} fill={'white'} />
      <rect width={'103.841'} height={'52.9846'} rx={'4'} transform={'matrix(1 0 0 -1 33 74.9846)'} fill={'white'} />
      <rect x={'41.8203'} y={'25.6541'} width={'22.0548'} height={'3.04509'} rx={'1.52255'} fill={'#EBECED'} />
      <rect x={'41.8203'} y={'63.7175'} width={'30.0191'} height={'2.43607'} rx={'1.21804'} fill={'#F7F8F8'} />
      <rect x={'41.8203'} y={'67.981'} width={'22.0548'} height={'2.43607'} rx={'1.21804'} fill={'#F7F8F8'} />
      <rect
        width={'91.8951'}
        height={'6.09018'}
        rx={'3.04509'}
        transform={'matrix(1 0 0 -1 41.8184 105.435)'}
        fill={'#E0E0E0'}
        fillOpacity={'0.12'}
      />
      <rect
        width={'91.8951'}
        height={'6.09018'}
        rx={'3.04509'}
        transform={'matrix(1 0 0 -1 41.8184 114.571)'}
        fill={'#E0E0E0'}
        fillOpacity={'0.12'}
      />
      <rect
        width={'91.8951'}
        height={'6.09018'}
        rx={'3.04509'}
        transform={'matrix(1 0 0 -1 41.8184 123.706)'}
        fill={'#E0E0E0'}
        fillOpacity={'0.12'}
      />
      <rect
        width={'92.2014'}
        height={'6.09018'}
        rx={'3.04509'}
        transform={'matrix(1 0 0 -1 41.8184 132.841)'}
        fill={'#E0E0E0'}
        fillOpacity={'0.12'}
      />
      <rect x={'48.25'} y={'100.867'} width={'22.0548'} height={'3.04509'} rx={'1.52255'} fill={'#EBECED'} />
      <rect x={'73.3691'} y={'100.867'} width={'22.6675'} height={'3.04509'} rx={'1.52255'} fill={'#EBECED'} />
      <rect x={'105.227'} y={'100.867'} width={'22.0548'} height={'3.04509'} rx={'1.52255'} fill={'#EBECED'} />
      <rect width={'3.06317'} height={'3.04509'} rx={'1.52255'} transform={'matrix(-1 0 0 1 102.162 100.867)'} fill={'#D9D9D9'} />
      <rect x={'48.2578'} y={'110.104'} width={'13.4113'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'64.7344'} y={'110.104'} width={'31.2291'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'105.16'} y={'110.104'} width={'22.0328'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect width={'3.06543'} height={'3.04734'} rx={'1.52367'} transform={'matrix(-1 0 0 1 102.094 110.104)'} fill={'#D9D9D9'} />
      <rect x={'48.2578'} y={'119.246'} width={'29.1216'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'80.4453'} y={'119.246'} width={'15.5187'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'105.16'} y={'119.246'} width={'19.5421'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect width={'3.06543'} height={'3.04734'} rx={'1.52367'} transform={'matrix(-1 0 0 1 102.094 119.246)'} fill={'#D9D9D9'} />
      <rect x={'48.4492'} y={'128.388'} width={'21.0748'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'72.5898'} y={'128.388'} width={'23.5655'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'105.352'} y={'128.388'} width={'14.1776'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect x={'122.594'} y={'128.388'} width={'5.93927'} height={'3.04734'} rx={'1.52367'} fill={'#EBECED'} />
      <rect width={'3.06543'} height={'3.04734'} rx={'1.52367'} transform={'matrix(-1 0 0 1 102.285 128.388)'} fill={'#D9D9D9'} />
      <rect x={'41.8203'} y={'32.0488'} width={'30.0191'} height={'28.6238'} rx={'2'} fill={'#F7F8F8'} />
      <rect x={'147'} y={'112'} width={'58.5245'} height={'25.0772'} rx={'4'} fill={'white'} />
      <rect x={'147.5'} y={'22'} width={'57.8939'} height={'83.131'} rx={'4'} fill={'white'} />
      <rect x={'153.32'} y={'32.0488'} width={'46.2539'} height={'5.17665'} rx={'2'} fill={'#EBECED'} />
      <rect x={'42'} y={'88'} width={'41'} height={'5'} rx={'2'} fill={'#EBECED'} />
      <rect x={'153.32'} y={'42.7065'} width={'46.2539'} height={'12.4849'} rx={'2'} fill={'#F7F8F8'} />
      <rect x={'153.32'} y={'59.4546'} width={'46.2539'} height={'12.1804'} rx={'2'} fill={'#F7F8F8'} />
      <rect x={'153.32'} y={'74.3757'} width={'46.2539'} height={'12.4849'} rx={'2'} fill={'#F7F8F8'} />
      <rect x={'164.654'} y={'91.4282'} width={'23.5864'} height={'3.95862'} rx={'1.97931'} fill={'#F3F0FF'} />
      <path
        d={'M96.9414 33C96.9414 32.4477 97.3891 32 97.9414 32H104.343C104.896 32 105.343 32.4477 105.343 33V70.1163H96.9414V33Z'}
        fill={'#F7F8F8'}
      />
      <path
        d={
          'M108.658 41.0657C108.658 40.5134 109.106 40.0657 109.658 40.0657H116.06C116.613 40.0657 117.06 40.5134 117.06 41.0657V70.1164H108.658V41.0657Z'
        }
        fill={'#F7F8F8'}
      />
      <path
        d={
          'M120.598 56.2861C120.598 55.7338 121.045 55.2861 121.598 55.2861H128C128.552 55.2861 129 55.7338 129 56.2861V70.1164H120.598V56.2861Z'
        }
        fill={'#F7F8F8'}
      />
      <mask
        id={'mask0_15393_177500'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'85'}
        y={'58'}
        width={'9'}
        height={'13'}
      >
        <path
          d={
            'M85 59.4841C85 58.9318 85.4477 58.4841 86 58.4841H92.4616C93.0139 58.4841 93.4616 58.9318 93.4616 59.4841V70.1337H85V59.4841Z'
          }
          fill={'#F7F8F8'}
        />
      </mask>
      <g mask={'url(#mask0_15393_177500)'}>
        <path
          d={
            'M76.793 57.6919C76.793 57.1396 77.2407 56.6919 77.793 56.6919H103.547C104.099 56.6919 104.547 57.1396 104.547 57.6919V78.3979H76.793V57.6919Z'
          }
          fill={'#F7F8F8'}
        />
      </g>
      <line x1={'13.65'} y1={'81.65'} x2={'13.65'} y2={'27.35'} stroke={'#0C2007'} strokeWidth={'0.7'} strokeLinecap={'round'} />
      <line x1={'13.65'} y1={'149.65'} x2={'13.65'} y2={'117.35'} stroke={'#0C2007'} strokeWidth={'0.7'} strokeLinecap={'round'} />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M193.251 112C186.517 121.1 175.706 127 163.517 127C156.223 127 149.424 124.888 143.695 121.242V116C143.695 113.791 145.486 112 147.695 112H193.251Z'
        }
        fill={'white'}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M197.037 108H148.371C146.162 108 144.371 106.209 144.371 104V62.3639C149.924 58.9614 156.455 57 163.444 57C183.634 57 200.001 73.3669 200.001 93.5566C200.001 98.6865 198.944 103.57 197.037 108Z'
        }
        fill={'white'}
      />
      <circle cx={'163'} cy={'125'} r={'9'} transform={'rotate(-81.1184 163 125)'} fill={'#EBECED'} />
      <path
        d={
          'M164.39 116.108C162.409 115.798 160.382 116.159 158.629 117.133C156.877 118.106 155.5 119.637 154.716 121.482C153.933 123.328 153.788 125.382 154.304 127.319C154.821 129.256 155.969 130.965 157.567 132.175C159.166 133.385 161.122 134.027 163.127 133.999C165.131 133.971 167.069 133.274 168.633 132.02C170.196 130.765 171.296 129.024 171.758 127.074C172.22 125.123 172.017 123.074 171.182 121.251L163 125L164.39 116.108Z'
        }
        fill={'#F7F8F8'}
      />
      <circle cx={'189.002'} cy={'125'} r={'9'} fill={'#EBECED'} />
      <path
        d={
          'M189.002 116C187.584 116 186.185 116.335 184.921 116.978C183.657 117.621 182.563 118.554 181.728 119.7C180.893 120.846 180.34 122.174 180.116 123.574C179.891 124.975 180 126.408 180.435 127.758C180.87 129.109 181.618 130.337 182.617 131.343C183.617 132.349 184.84 133.105 186.188 133.548C187.535 133.992 188.968 134.111 190.37 133.895C191.771 133.679 193.102 133.136 194.254 132.308L189.002 125V116Z'
        }
        fill={'#F7F8F8'}
      />
      <rect x={'150'} y={'59'} width={'50'} height={'18'} rx={'2'} fill={'#F7F8F8'} />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M139.925 116C134.303 109.345 131 101.224 131 92.4599C131 88.4816 131.68 84.636 132.95 81H135.925C138.135 81 139.925 82.7909 139.925 85V116Z'
        }
        fill={'white'}
      />
      <rect x={'150'} y={'81'} width={'50'} height={'18'} rx={'2'} fill={'#F7F8F8'} />
      <path
        d={
          'M180.261 125.107L190.731 118.239L223.892 168.794C225.788 171.685 224.982 175.567 222.09 177.463C219.199 179.36 215.318 178.553 213.421 175.662L180.261 125.107Z'
        }
        fill={'#E0E0E0'}
        stroke={'#1C1C1B'}
      />
      <rect
        x={'200.753'}
        y={'158.031'}
        width={'14.3655'}
        height={'28.5057'}
        rx={'0.5'}
        transform={'rotate(-33.2622 200.753 158.031)'}
        fill={'#1C1C1B'}
        stroke={'#1C1C1B'}
      />
      <mask id={'path-57-inside-1_15393_177500'} fill={'white'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M187.985 125.748C206.931 113.321 212.216 87.8878 199.788 68.9419C187.361 49.996 161.928 44.7116 142.982 57.1389C124.036 69.5661 118.752 94.9991 131.179 113.945C143.606 132.891 169.039 138.175 187.985 125.748ZM184.469 120.522C200.435 110.049 204.888 88.6171 194.415 72.6514C183.943 56.6858 162.511 52.2326 146.545 62.705C130.579 73.1774 126.126 94.6097 136.599 110.575C147.071 126.541 168.503 130.994 184.469 120.522Z'
          }
        />
      </mask>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M187.985 125.748C206.931 113.321 212.216 87.8878 199.788 68.9419C187.361 49.996 161.928 44.7116 142.982 57.1389C124.036 69.5661 118.752 94.9991 131.179 113.945C143.606 132.891 169.039 138.175 187.985 125.748ZM184.469 120.522C200.435 110.049 204.888 88.6171 194.415 72.6514C183.943 56.6858 162.511 52.2326 146.545 62.705C130.579 73.1774 126.126 94.6097 136.599 110.575C147.071 126.541 168.503 130.994 184.469 120.522Z'
        }
        fill={'#E0E0E0'}
      />
      <path
        d={
          'M198.952 69.4904C211.077 87.9745 205.921 112.788 187.437 124.912L188.534 126.584C207.942 113.854 213.355 87.8012 200.625 68.3935L198.952 69.4904ZM143.531 57.975C162.015 45.8507 186.828 51.0063 198.952 69.4904L200.625 68.3935C187.894 48.9858 161.842 43.5725 142.434 56.3027L143.531 57.975ZM132.015 113.396C119.891 94.9124 125.047 70.0994 143.531 57.975L142.434 56.3027C123.026 69.0329 117.613 95.0857 130.343 114.493L132.015 113.396ZM187.437 124.912C168.953 137.036 144.14 131.881 132.015 113.396L130.343 114.493C143.073 133.901 169.126 139.314 188.534 126.584L187.437 124.912ZM193.579 73.1999C203.749 88.7038 199.424 109.516 183.921 119.686L185.017 121.358C201.445 110.583 206.027 88.5304 195.252 72.103L193.579 73.1999ZM147.094 63.5412C162.597 53.3717 183.41 57.6961 193.579 73.1999L195.252 72.103C184.476 55.6755 162.424 51.0936 145.997 61.8689L147.094 63.5412ZM137.435 110.027C127.265 94.523 131.59 73.7107 147.094 63.5412L145.997 61.8689C129.569 72.6442 124.987 94.6964 135.763 111.124L137.435 110.027ZM183.921 119.686C168.417 129.855 147.604 125.531 137.435 110.027L135.763 111.124C146.538 127.551 168.59 132.133 185.017 121.358L183.921 119.686Z'
        }
        fill={'#1C1C1B'}
        mask={'url(#path-57-inside-1_15393_177500)'}
      />
      <circle cx={'165.5'} cy={'91.5'} r={'34.5'} fill={'white'} fillOpacity={'0.33'} />
      <path
        d={
          'M229.528 4.58774C228.686 3.6791 228.737 2.26115 229.644 1.41899C230.55 0.576826 231.968 0.631572 232.811 1.53935C233.653 2.44799 233.602 3.86595 232.695 4.70812C231.789 5.55028 230.371 5.49552 229.528 4.58774Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M245.481 28.5463C245.285 28.6595 245.076 28.7505 244.853 28.8176C244.102 29.0433 243.309 28.9641 242.619 28.592C241.93 28.2215 241.425 27.6039 241.199 26.8527C240.733 25.3037 241.615 23.6652 243.164 23.1987C243.915 22.973 244.708 23.0523 245.398 23.4243C246.088 23.7964 246.592 24.4124 246.818 25.1636C247.044 25.9147 246.964 26.7073 246.592 27.3975C246.33 27.8836 245.947 28.277 245.481 28.5463ZM242.284 26.5255C242.423 26.986 242.732 27.3664 243.156 27.593C243.58 27.8196 244.066 27.8696 244.527 27.732C244.988 27.5929 245.368 27.2842 245.595 26.8602C245.821 26.4362 245.871 25.9498 245.734 25.4884C245.595 25.028 245.285 24.6485 244.862 24.421C244.438 24.1944 243.952 24.1444 243.49 24.282C243.03 24.4211 242.649 24.7298 242.423 25.1538C242.196 25.5778 242.146 26.0642 242.284 26.5255Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M5.48085 102.546C5.28474 102.66 5.07579 102.75 4.8531 102.818C4.10193 103.043 3.30942 102.964 2.61922 102.592C1.92987 102.221 1.42475 101.604 1.19905 100.853C0.732555 99.3037 1.61487 97.6652 3.16394 97.1987C3.9151 96.973 4.70764 97.0523 5.39784 97.4243C6.08804 97.7964 6.59231 98.4124 6.818 99.1636C7.0437 99.9147 6.96446 100.707 6.59241 101.397C6.33031 101.884 5.94733 102.277 5.48085 102.546ZM2.28381 100.526C2.42289 100.986 2.73164 101.366 3.15565 101.593C3.57966 101.82 4.06608 101.87 4.5274 101.732C4.98787 101.593 5.36822 101.284 5.59486 100.86C5.82149 100.436 5.87145 99.9498 5.73385 99.4884C5.59478 99.028 5.28456 98.6485 4.86204 98.421C4.43803 98.1944 3.95161 98.1444 3.49028 98.282C3.02981 98.4211 2.64946 98.7298 2.42283 99.1538C2.19619 99.5778 2.14622 100.064 2.28381 100.526Z'
        }
        fill={'black'}
      />
    </svg>
  );
};
