import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Text } from '@lama/design-system';
import { applicationCollateralSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { CollateralSummaryTable } from './CollateralSummaryTable';

export const SouthStateCollateralSegment: FC = () => {
  const { application } = useContext(ApplicationContext);
  const collateral = useMemo(
    () =>
      applicationCollateralSelector({
        entity: application,
        entityType: 'application',
        application,
        yearsBack: 0,
      }),
    [application],
  );

  if (!collateral) {
    return <Text variant={'body2'}>{'No information found'}</Text>;
  }

  if (!collateral.length) {
    return <Text variant={'body2'}>{'Applicant marked no collateral'}</Text>;
  }

  return (
    <SegmentContainer title={'Summary'} bordered={false}>
      <CollateralSummaryTable />
    </SegmentContainer>
  );
};
