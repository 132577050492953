import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { useAuth0 } from '@auth0/auth0-react';
import type { MiddeskReportTracking } from '@lama/verify-business-service-client';
import { verifyBusinessServiceClient } from '../../../../../../../shared/clients/verifyBusinessServiceClient';

interface MiddeskSearchUccFillingsMutationParams {
  businessId: string;
  taskId: string;
}

export const useMiddeskSearchUccFillingsMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ businessId, taskId }: MiddeskSearchUccFillingsMutationParams) => {
      const token = await getAccessTokenSilently();

      await verifyBusinessServiceClient.searchLiens(businessId, taskId, token);

      return { businessId, taskId };
    },
    onMutate: async ({ businessId, taskId }: MiddeskSearchUccFillingsMutationParams) => {
      const previousBusinessMiddeskTracking: MiddeskReportTracking | undefined = queryClient.getQueryData([
        'middeskReportTracking',
        businessId,
      ]);

      const otherOrders =
        previousBusinessMiddeskTracking?.ordersTracking?.filter((order: any) => order.relatedClosingTaskId !== taskId) ?? [];

      const updatedBusinessMiddeskTracking = {
        ...previousBusinessMiddeskTracking,
        ordersTracking: [
          ...otherOrders,
          {
            orderType: 'liens',
            relatedClosingTaskId: taskId,
            searchOnly: true,
            status: 'pending',
          },
        ],
      };

      queryClient.setQueryData(['middeskReportTracking', businessId], () => updatedBusinessMiddeskTracking);
    },
    onError: () => {
      displayToast('Something went wrong. Please contact support.', 'error');
    },
  });
};
