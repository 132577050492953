import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, greyPalette } from '@lama/design-system';
import { loanDisplayNameSelector } from '@lama/data-formatters';
import { saveAs } from 'file-saver';
import { LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../../ApplicationContext';
import { transformToLaserProFormat } from '../../../ActionsMenu/utils/laserPro';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useLenderProfilesByPartnerQuery } from '../../../../../shared/hooks/react-query/lenderProfile/useLenderProfilesByPartnerQuery';
import type { ClosingTaskComponentProps } from './closingTaskComponentProps';
import { ExportLine } from './shared/ExportLine';

export const LaserProExportClosingTask: FC<ClosingTaskComponentProps> = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const loanDisplayName = useMemo<string | null>(() => loanDisplayNameSelector(application), [application]);

  const { data: users, isPending: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);
  const { data: lenderProfiles, isPending: loadingLenderProfiles } = useLenderProfilesByPartnerQuery(opportunity.partnerId);

  const lenderProfile = useMemo(() => {
    const assignedRM = users?.find((u) => (opportunity.assigneeIds ?? []).includes(u.id) && u.roles.includes('relationshipManager'));
    return lenderProfiles?.find((p) => p.userId === assignedRM?.id);
  }, [lenderProfiles, opportunity.assigneeIds, users]);

  const exportToLaserPro = useCallback(() => {
    const laserProData = transformToLaserProFormat(opportunity, lenderProfile);

    const blob = new Blob([laserProData], {
      type: 'application/zip',
    });

    saveAs(blob, `${loanDisplayName ?? application.id}.txt`);
  }, [application.id, lenderProfile, loanDisplayName, opportunity]);

  if (loadingUsers || loadingLenderProfiles) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} width={'100%'} p={4} border={`1px solid ${greyPalette[300]}`}>
      <ExportLine name={'Export to LaserPro'} onExportClick={exportToLaserPro} />
    </Flex>
  );
};
