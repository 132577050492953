import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { EnvelopeTracking } from '@lama/docusign-client';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useOpportunityEnvelopesTrackingQuery = (opportunityId: string, options?: UseQueryOptions<EnvelopeTracking[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityEnvelopesTracking = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return docusignServiceClient.getOpportunityEnvelopesTracking(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);

  return useQuery({
    queryKey: ['opportunityEnvelopesTracking', opportunityId],
    queryFn: getOpportunityEnvelopesTracking,
    ...options,
  });
};
