import type { ConditionStatus } from '../../../../../../shared/types/conditionTypes';

export interface ScoreCardData {
  category: string;
  coefficient: number;
  grade1: string;
  grade2: string;
  grade3: string;
  grade4: string;
  grade5: string;
  grade6: string;
  grade7: string;
  value: number;
}

export const scoreCardData: ScoreCardData[] = [
  {
    category: 'LTV-Capitalization',
    coefficient: 3,
    grade1: '0 - 20%',
    grade2: '21 - 40%',
    grade3: '41 - 60%',
    grade4: '61 - 70%',
    grade5: '71 - 80%',
    grade6: '81 - 90%',
    grade7: '> 91%',
    value: 3,
  },
  {
    category: 'Collateral',
    coefficient: 3,
    grade1: 'Cash / Securities',
    grade2: '1-4F, Multi-Family, O/O',
    grade3: 'Mixed-use non-O/O',
    grade4: 'Retail, C&I',
    grade5: '',
    grade6: '',
    grade7: '',
    value: 4,
  },
  {
    category: 'Debt Service Ratio',
    coefficient: 4,
    grade1: '> 2.0',
    grade2: '1.5 -1.99',
    grade3: '1.25 - 1.49',
    grade4: '1.0 - 1.24',
    grade5: '0.75 - 0.99',
    grade6: '> 0.74',
    grade7: 'N/A',
    value: 4,
  },
  {
    category: 'Delinquency History',
    coefficient: 2,
    grade1: 'None for 36 months or more',
    grade2: 'None for 24-36 months',
    grade3: 'None for 12-24 months',
    grade4: '3 - 4 occurences',
    grade5: '5 - 6 occurences',
    grade6: '7 - 8 occurences',
    grade7: '> 8 occurences',
    value: 3,
  },
  {
    category: 'Sales Trends',
    coefficient: 1,
    grade1: 'Steady for 3 years or more',
    grade2: 'Steady 2-3 years',
    grade3: 'Steady 1-2 years',
    grade4: '5% annual decrease',
    grade5: '10% annual decrease.',
    grade6: '15% annual decrease',
    grade7: '> 15% annual decrease',
    value: 3,
  },
  {
    category: 'Reputation/Credit History',
    coefficient: 2,
    grade1: '> 740',
    grade2: '720 - 739',
    grade3: '680 - 719',
    grade4: '660 - 679',
    grade5: '640 - 659 ',
    grade6: '620 - 639',
    grade7: '< 619',
    value: 4,
  },
];

export const getWeightedValueFlagStatus = (number: number): ConditionStatus =>
  number < 10 ? 'approved' : number < 16 ? 'pending' : 'rejected';

export const getWeightedAverageFlagStatus = (number: number): ConditionStatus =>
  number <= 3 ? 'approved' : number <= 5 ? 'pending' : 'rejected';
