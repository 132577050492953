import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../../shared/SegmentContainer';

export const FirstBankDemoLoanDetails = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'source'} value={'625 - Private Banking 800 (Private Banking)'} />
        <KeyValuePair name={'Data Entry'} value={'johnstm02 03/28/2023 01:50:42 PM'} />
        <KeyValuePair name={'Product'} value={'First Premier Invest LOC - MO'} />
        <KeyValuePair name={'Loan Purpose'} value={'Establish Line of Credit-NonHMDA'} />
        <KeyValuePair name={'Decision Officer'} value={'bootha001 03/31/2023 08:01:00 AM'} />
        <KeyValuePair name={'Decision Policy'} value={'First Premier Invest'} />
        <KeyValuePair name={'Recommended Decision'} value={'APPROVE'} />
        <KeyValuePair name={'Current Decision'} value={'APPROVE'} />
        <KeyValuePair name={'Letter Date'} />
        <KeyValuePair name={'Concurring Officer'} />
        <KeyValuePair name={'Verification Status'} value={'Pending'} />
        <KeyValuePair name={'MLA'} value={'No'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer title={'Requested'}>
      <Grid columns={3}>
        <KeyValuePair name={'Amount Requested'} value={'$100,000.00'} />
        <KeyValuePair name={'Term Requested'} value={'36'} />
        <KeyValuePair name={'Interest Rate Requested'} value={'0.00%'} />
        <KeyValuePair name={'Estimated Payment'} value={'*Percentage*'} />
        <KeyValuePair name={'Requested LTV'} value={'35.359%'} />
        <KeyValuePair name={'Current DTI'} value={'37.712%'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer title={'Proposed'}>
      <Grid columns={3}>
        <KeyValuePair name={'Amount Proposed'} value={'$100,000.00'} />
        <KeyValuePair name={'Term Proposed'} value={'36'} />
        <KeyValuePair name={'Interest Rate Proposed'} value={'6.50%'} />
        <KeyValuePair name={'Proposed Payment'} value={'$932.10'} />
        <KeyValuePair name={'Proposed LTV'} value={'35.359%'} />
        <KeyValuePair name={'Proposed Post DTI'} value={'49.364%'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer title={'Decision'}>
      <Grid columns={3}>
        <KeyValuePair name={'Final Requested'} value={'$100,000.00'} />
        <KeyValuePair name={'Final Term'} value={'36'} />
        <KeyValuePair name={'Final Interest Rate'} value={'6.50%'} />
        <KeyValuePair name={'Primary Bureau'} value={'Trans Union'} />
        <KeyValuePair name={'Final LTV'} value={'35.359%'} />
        <KeyValuePair name={'Final DTI'} value={'49.364%'} />
      </Grid>
    </SegmentContainer>
  </Flex>
);
