import React from 'react';
import type { FC } from 'react';
import type { UnderwritingNote } from '@lama/contracts';
import type { User } from '@lama/user-service-client';

import { NoteCard } from './NoteCard';

interface ExceptionCardProps {
  note: UnderwritingNote;
  user?: User;
}

export const DismissedFlagsCard: FC<ExceptionCardProps> = ({ note, user }) => (
  <NoteCard
    note={note}
    user={user}
    deleteEnabled={false}
    changeNoteTypeButtonsProps={[{ changeNoteTypeCTA: 'Flag Issue', noteTypeTarget: 'flag' }]}
  />
);
