/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import type { AgGridReact } from '@ag-grid-community/react';
import React, { useMemo, useCallback, useRef, useState } from 'react';
import { compact } from 'lodash-es';
import type { IServerSideGetRowsParams, IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';

import { useDebounce } from 'react-use';
import { Flex, Text } from '@lama/design-system';
import type { PipelineItem } from '@lama/pipeline-items-service-client';
import { pipelineItemsServiceClient } from '../../shared/clients/pipelineItemsServiceClient';
import { useUserPartner } from '../../shared/hooks/useCurrentPartner';
import { getPipelineCategoriesForItem } from '../Pipeline/getPipelineCategoryForItem';
import { PipelineTable } from '../Pipeline/PipelineTable';
import { filtersToPipelineQueryParams, getColumnDefinitions } from '../Pipeline/PipelineColumns';
import '../Pipeline/styles/pipelineTable.css';
import type { CalculatedPipelineItem } from '../Pipeline/types';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { PipelineFilters } from '../Pipeline/PipelineFilters';

const PIPELINE_LIMIT = 30;

export const ReferrerPipelinePage: FC = () => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [debouncedFilter, setDebouncedFilter] = useState<string>('');
  const [agFilterCount, setAgFilterCount] = useState<number>(0);
  const partner = useUserPartner();
  const { fibtDemo, showSourceColumnInPipeline, showApplicationNumber, hideAssigneeColumnReferrer } = useFlags();
  const { showReferrerColumnInPipeline, showLastStatusUpdateInPipeline } = useMemo(() => partner?.featureConfigurations ?? {}, [partner]);
  const gridRef = useRef<AgGridReact>(null);
  const { getAccessTokenSilently } = useAuth0();

  const getPipelineItemsWithCategories = useCallback(
    (pipelineItems: PipelineItem[]) =>
      compact(
        pipelineItems.map(
          (item: PipelineItem) =>
            ({
              ...item,
              pipelineCategories: getPipelineCategoriesForItem(item),
            } as CalculatedPipelineItem),
        ),
      ),
    [],
  );

  const getPipelineQueryFunction = useCallback(
    async (requestParams: IServerSideGetRowsRequest) => {
      const token = await getAccessTokenSilently();

      const filters = filtersToPipelineQueryParams({
        filterModel: requestParams.filterModel,
        userPartners: [partner.id],
        globalFilter: debouncedFilter,
        columnState: requestParams.sortModel,
        pipelineCategory: 'all',
      });

      return pipelineItemsServiceClient.getReferredPipelineItems(
        {
          limit: PIPELINE_LIMIT,
          page: requestParams.startRow ? Math.floor(requestParams.startRow / PIPELINE_LIMIT) + 1 : 1,
          filterBySharedWithPartner: true,
          ...filters,
        },
        token,
      );
    },
    [debouncedFilter, getAccessTokenSilently, partner.id],
  );
  const updateDebouncedFilter = useCallback(() => {
    if (gridRef.current) {
      setDebouncedFilter(globalFilter);
    }
  }, [globalFilter]);
  useDebounce(updateDebouncedFilter, 350, [globalFilter]);
  const agColumnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        userOptions: [],
        productOptions: [],
        partnerOptions: [],
        referrerOptions: [],
        showApplicationNumberColumnInPipeline: !!showApplicationNumber,
        showReferrerColumnInPipeline: !!showReferrerColumnInPipeline,
        showSourceColumnInPipeline,
        showLastStatusUpdateInPipeline: !!showLastStatusUpdateInPipeline,
        applicationStatusToDisplayName: partner.applicationStatusDisplayNameMapping,
        enableArrayFieldSorting: false,
        fibtDemo,
        preventNavigateOnClick: true,
        showAlertsColumn: false,
        hideAssigneeColumn: hideAssigneeColumnReferrer,
      }),
    [
      showApplicationNumber,
      showReferrerColumnInPipeline,
      hideAssigneeColumnReferrer,
      showSourceColumnInPipeline,
      showLastStatusUpdateInPipeline,
      partner.applicationStatusDisplayNameMapping,
      fibtDemo,
    ],
  );

  const serverDataSource = useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        const response = await getPipelineQueryFunction(params.request);

        const rowData = getPipelineItemsWithCategories(response.pipeline);

        params.success({ rowData, rowCount: response.pipeline?.length ?? 0 });
      },
    }),
    [getPipelineQueryFunction, getPipelineItemsWithCategories],
  );
  const clearGlobalFilter = useCallback(() => {
    setGlobalFilter('');
    if (gridRef.current) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setDebouncedFilter('');
    }
  }, []);
  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
  }, []);

  const onFilterChanged = useCallback(() => {
    if (gridRef.current) {
      const filters = gridRef.current.api.getFilterModel();
      setAgFilterCount(Object.keys(filters).length);
    }
  }, []);
  return (
    <Flex flexDirection={'column'} pt={4} height={'100%'}>
      <Flex justifyContent={'space-between'} alignItems={'center'} pt={6} pb={6} px={8} gap={16}>
        <Text variant={'h4'}>{'Referrals'}</Text>
      </Flex>
      <Flex justifyContent={'space-between'} alignItems={'center'} pt={6} pb={6} px={8} gap={16}>
        <PipelineFilters
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          onClearFilters={clearFilters}
          clearGlobalFilter={clearGlobalFilter}
          agFilterCount={agFilterCount}
        />
      </Flex>
      <PipelineTable
        rowStyle={{ cursor: 'default' }}
        ref={gridRef}
        columnDefs={agColumnDefinitions}
        rowModelType={'serverSide'}
        serverSideDatasource={serverDataSource}
        pagination
        suppressServerSideFullWidthLoadingRow
        onFilterChanged={onFilterChanged}
        suppressPaginationPanel={false}
        gridOptions={{ suppressPaginationPanel: false }}
        paginationPageSize={PIPELINE_LIMIT}
        cacheBlockSize={PIPELINE_LIMIT}
        paginationPageSizeSelector={false}
        rowSelection={fibtDemo ? 'multiple' : undefined}
      />
    </Flex>
  );
};
