import React, { useCallback, useRef, useState } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import { Menu } from '@mui/material';
import { Button, Flex } from '@lama/design-system';
import type { MRT_TableInstance } from 'material-react-table';
import type { FC } from 'react';
import { Add } from '@mui/icons-material';
import { useToggle } from 'react-use';
import DatePicker from 'react-datepicker';
import { getUTCDateFromZonedTime } from '@lama/data-formatters';
import type { Period } from './FinancialStatementsSpreadingContent';

export const AddColumnButton: FC<{
  table: MRT_TableInstance<DocumentLineItem>;
  onAddColumn: (period: Period) => void;
}> = ({ onAddColumn }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showDatePicker, toggleDatePicker] = useToggle(false);
  const datePickerRef = useRef(null);

  const onChange = useCallback((dates: [Date | null, Date | null]) => {
    const [timezoneStartDate, timezonedEndDate] = dates;

    if (!timezoneStartDate) {
      return;
    }
    const startDateChange = getUTCDateFromZonedTime(timezoneStartDate);
    const endDateChange = timezonedEndDate ? getUTCDateFromZonedTime(timezonedEndDate) : null;

    setStartDate(startDateChange);
    setEndDate(endDateChange);
  }, []);

  const onClickSave = useCallback(() => {
    if (startDate && endDate) {
      onAddColumn({ startDate, endDate });
      toggleDatePicker();
      setStartDate(null);
      setEndDate(null);
    }
  }, [endDate, onAddColumn, startDate, toggleDatePicker]);

  return (
    <>
      <Button variant={'tertiary'} onClick={toggleDatePicker} startIcon={<Add />} disabled={showDatePicker} ref={datePickerRef}>
        {'Add Column'}
      </Button>
      <Menu anchorEl={datePickerRef.current} open={showDatePicker} onClose={toggleDatePicker}>
        <Flex flexDirection={'column'} p={1}>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            dropdownMode={'select'}
            showMonthDropdown
            showYearDropdown
            selectsRange
            inline
          />
          <Button variant={'primary'} color={'primary'} size={'m'} disabled={!startDate || !endDate} onClick={onClickSave}>
            {'Add Column'}
          </Button>
        </Flex>
      </Menu>
    </>
  );
};
