import { LoadingPage, displayToast } from '@lama/app-components';
import { Stack } from '@mui/material';
import type { FC } from 'react';
import React, { useContext, useCallback, useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import type { DealApiModel } from '@lama/contracts';
import { useQueryClient } from '@tanstack/react-query';
import { useProductsByPartnerQuery } from '../../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { ProductCardBase } from '../../Application/Products/ProductCardBase';
import { useCreateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useCreateOpportunityMutation';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { CreateProductFromTemplateCard } from '../../Application/Products/ProductTemplates/CreateProductFromTemplateCard';

export const ProductSelectionCardList: FC<{ deal: DealApiModel }> = ({ deal }) => {
  const { partner } = useContext(UserDetailsContext);
  const { data: products, isPending: loadingProducts } = useProductsByPartnerQuery(partner);
  const orderedProducts = useMemo(() => orderBy(products, ['name'], ['asc']), [products]);
  const queryClient = useQueryClient();

  const createOpportunity = useCreateOpportunityMutation();
  const navigate = useNavigate();

  const handleClick = useCallback(
    async (productId: string) => {
      if (!partner) {
        return;
      }

      await createOpportunity({
        id: uuidv4(),
        applicationId: deal.applicationId,
        productId,
        partnerId: partner,
      });

      await queryClient.invalidateQueries({ queryKey: ['deals'] });

      displayToast("Request received, we'll contact you soon!", 'success');
      navigate(`/deals/${deal.id}`);
    },
    [createOpportunity, deal.applicationId, deal.id, navigate, partner, queryClient],
  );

  if (loadingProducts) {
    return <LoadingPage />;
  }

  return (
    <Stack width={'100%'} gap={4} direction={'row'} flexWrap={'wrap'}>
      {orderedProducts.map((product) => (
        <ProductCardBase key={product.id} cta={'Select Product'} product={product} onClick={handleClick} />
      ))}
      <CreateProductFromTemplateCard />
    </Stack>
  );
};
