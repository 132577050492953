/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import decode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import type { LamaJwtPayload, User } from '@lama/user-service-client';
import { userServiceClient } from '../clients/userServiceClient';

export interface UserDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  partner?: string;
  permissions?: string[];
  segments?: string[];
  type?: string;
  auth0Id?: string;
  userId?: string;
  partnerPermissions?: Record<string, string[]>;
  userData?: User;
}

export const UserDetailsContext = React.createContext<UserDetails>({});

interface UserDetailsProviderProps {
  children: React.ReactNode;
  user?: UserDetails;
}

export const UserDetailsProvider: FC<UserDetailsProviderProps> = ({ user, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [userDetails, setUserDetails] = useState<UserDetails>({});

  useAsync(async () => {
    const token = await getAccessTokenSilently();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      'lama.ai/partner': partner,
      'lama.ai/segments': segments,
      'lama.ai/type': type,
      'lama.ai/userId': userId,
      'lama.ai/partnerPermissions': partnerPermissions,
      permissions,
      firstName,
      lastName,
      email,
      sub,
    } = decode<LamaJwtPayload>(token);

    const auth0Id = sub?.split('auth0|')?.[1] ?? '';
    const userData = user?.userData ?? (await userServiceClient.getUser({ userId }, token));
    setUserDetails(
      user ?? { partner, permissions, firstName, lastName, email, segments, type, auth0Id, userId, partnerPermissions, userData },
    );
  }, [getAccessTokenSilently, user]);

  return <UserDetailsContext.Provider value={userDetails}>{children}</UserDetailsContext.Provider>;
};
