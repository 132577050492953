import React, { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { Condition } from '../../../../../../shared/types/conditionTypes';
import { ConditionsListSectionContent } from '../ConditionsListSectionContent';

const onlineDemoData: Condition = {
  id: uuidv4(),
  status: 'approved',
  name: 'Online Activity',
  hasComments: false,
  hasInsight: false,
  category: 'Activity',
  chargeOffPrediction: 0.5,
  assignedId: '',

  fields: [
    { name: 'Has online payments option', value: 'Yes', dataSources: ['online'] },
    { name: 'Online Reviews', value: '409', dataSources: ['online'] },
    { name: 'Online Rating', value: '4.9', dataSources: ['online'] },
    {
      name: 'Website visits',
      value: '450 / month',
      dataSources: ['online'],
    },
    {
      name: 'Top traffic sources',
      value: 'FL',
      dataSources: ['socialMedia', 'online'],
    },
    { name: 'Opening Hours', value: '12pm-10pm', dataSources: ['online'] },
    { name: 'Pinterest Profile', value: 'N/A', dataSources: ['online'] },
    { name: 'Facebook Profile', value: 'icecreamlilys', dataSources: ['online'] },
    { name: 'instagram Profile', value: 'icecreamlilys', dataSources: ['online'] },
    { name: 'Twitter Profile', value: 'N/A', dataSources: ['online'] },
    { name: 'Yelp Profile', value: 'lilys-handmade-ice-cream-delray-beach', dataSources: ['online'] },
    { name: 'Linkedin Profile', value: 'N/A', dataSources: ['online'] },
    { name: 'Tiktok Profile', value: 'N/A', dataSources: ['online'] },
    { name: 'Youtube Profile', value: 'N/A', dataSources: ['online'] },
  ],
};

export const OnlineActivitySection: FC = () => <ConditionsListSectionContent {...onlineDemoData} />;
