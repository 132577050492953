import React, { useMemo, useContext } from 'react';
import type { PersonApiModel } from '@lama/clients';
import type { BusinessApiModel, CreateBusinessModelApi, UpdateBusinessRequest } from '@lama/business-service-client';
import { RelatedCompanies } from '@lama/app-components';
import type { RequirementScreenProps } from '../types';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../../../../Pipeline/hooks/useCreateBusinessMutation';

type CreateRelatedCompanyRequest = CreateBusinessModelApi;
export type UpdateRelatedCompanyRequest = UpdateBusinessRequest & { id: string };
type RelatedCompany = CreateRelatedCompanyRequest | UpdateRelatedCompanyRequest;

interface Owner {
  person?: PersonApiModel;
  business?: BusinessApiModel;
  ownershipPercentage: number;
}
export type RelatedCompanyWithOwner = RelatedCompany & { owner: Owner };

export const RelatedCompaniesScreen: React.FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    opportunity: { id: opportunityId },
    application,
  } = useContext(ApplicationContext);
  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunityId);

  if (!business || !application) {
    return null;
  }

  return (
    <RelatedCompanies
      application={application}
      business={business}
      createBusiness={createBusiness}
      updateBusiness={updateBusiness}
      loading={creatingBusiness || updatingBusiness}
    />
  );
};
