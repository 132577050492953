import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useSubmitApplicationMutation = (applicationId: string, opportunityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.submitApplication(applicationId, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while signing the application. Please contact support.', 'error');
    },
  });
};
