import type { FC } from 'react';
import React from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Chip } from '@mui/material';
import type { LabeledValue } from '@lama/contracts';

export const failActionsTypes = ['alternativeProduct', 'knockout', 'manualReview', 'network'] as const;
export type FailActionType = (typeof failActionsTypes)[number];

export const failActionOptions: LabeledValue[] = [
  {
    value: 'alternativeProduct',
    label: 'Alternative Product',
  },
  {
    value: 'knockout',
    label: 'Knockout',
  },
  {
    value: 'manualReview',
    label: 'Manual Review',
  },
  {
    value: 'network',
    label: 'Send to Network',
  },
];

export const failActionToColors: Record<FailActionType, { bgcolor: string; color: string; borderColor: string }> = {
  network: { bgcolor: '#F3F0FF', color: '#714EF5', borderColor: '#F3F0FF' },
  manualReview: { bgcolor: '#E1F5FE', color: '#0288D1', borderColor: '#E1F5FE' },
  alternativeProduct: { bgcolor: '#E1F5FE', color: '#0288D1', borderColor: '#E1F5FE' },
  knockout: { bgcolor: '#FEEBEE', color: '#EF5350', borderColor: '#FEEBEE' },
};

const statusToChip: Record<FailActionType, { label: string; style: {} }> = {
  manualReview: { label: 'Manual Review', style: failActionToColors.manualReview },
  alternativeProduct: { label: 'Alt. Product', style: failActionToColors.alternativeProduct },
  network: { label: 'Send to Network', style: failActionToColors.network },
  knockout: { label: 'Knockout', style: failActionToColors.knockout },
};

interface FailActionChipProps {
  status: FailActionType;
  size?: 'medium' | 'small';
  label?: string;
  sx?: SxProps<Theme>;
}

export const FailActionChip: FC<FailActionChipProps> = ({ status, size, label, sx }) => (
  <Chip label={label ?? statusToChip[status].label} size={size} variant={'outlined'} sx={{ ...statusToChip[status].style, ...sx }} />
);
