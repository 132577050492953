import type { DealApiModel } from '@lama/contracts';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case-all';
import { isAfter, sub } from 'date-fns';
import { Link } from 'react-router-dom';
import { usdFormatStandard } from '@lama/data-formatters';
import { ApprovalDelegation, MonetizationOn, Store, Location } from '../../../shared/components/Icons';
import { InterestedMark } from '../InterestedMark';
import { getDealName } from '../helpers';
import HighMatchIcon from '../../../shared/icons/high_match.svg';
import { getShortAddressInfoLine } from '../../../shared/utils/informationLineUtils';
import { DealCardImage } from './DealCardImage';
import type { DealInfoLineProps } from './DealInfoLine';
import { DealInfoLine } from './DealInfoLine';

interface DealCardProps {
  deal: DealApiModel;
  category?: string;
}

interface DealMatchProps {
  deal: DealApiModel;
}

const DealMatch: FC<DealMatchProps> = ({ deal }) => {
  const theme = useTheme();
  if (!deal.matchingProducts?.length) {
    return null;
  }

  return (
    <Stack gap={1} direction={'row'}>
      <HighMatchIcon />
      <Typography variant={'body2'} color={theme.palette.text.secondary}>
        {'High match'}
      </Typography>
    </Stack>
  );
};

const dealData = (deal: DealApiModel): DealInfoLineProps[] => [
  {
    icon: Store,
    label: 'Industry types',
    type: 'array',
    values: deal.industryTypes?.map((value: string) => sentenceCase(value)) ?? ['-'],
  },
  {
    icon: MonetizationOn,
    label: 'Requested amount',
    values: deal.requestedAmount ? usdFormatStandard.format(deal.requestedAmount) : [],
    type: 'item',
  },
  {
    icon: ApprovalDelegation,
    label: 'Use of funds',
    values: deal.useOfFunds ?? [],
    type: 'array',
  },
  {
    icon: Location,
    label: 'Location',
    values: getShortAddressInfoLine(deal.address),
    type: 'item',
  },
];

export const dealCardWidth = 304;

export const DealCard: FC<DealCardProps> = ({ deal, category }: DealCardProps) => {
  const name = useMemo(() => getDealName(deal), [deal]);

  const isNew = useMemo(() => isAfter(new Date(deal.createdAt), sub(new Date(), { days: 7 })), [deal.createdAt]);

  return (
    <Link to={`/deals/${deal.id}`} state={{ category }} style={{ textDecoration: 'none' }}>
      <Stack
        width={dealCardWidth}
        minHeight={'424px'}
        bgcolor={'white'}
        sx={{
          textDecoration: 'none',
          cursor: 'pointer',
          userSelect: 'none',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            boxShadow: '0px 8px 32px 0px #3800641F',
          },
          marginBottom: '32px',
        }}
        borderRadius={'10px'}
      >
        <DealCardImage image={deal.image} />
        <Stack spacing={2} p={3}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant={'h6'} color={'text.primary'}>
              {name}
            </Typography>
            {isNew ? (
              <Chip label={'New'} size={'small'} color={'primary'} variant={'outlined'} sx={{ bgcolor: 'primary.light', border: 'none' }} />
            ) : null}
          </Stack>
          <Stack spacing={2}>
            {dealData(deal).map(({ label, icon, values, type, check }) => (
              <DealInfoLine key={label} icon={icon} label={label} values={values} type={type} check={check} />
            ))}
          </Stack>
          {deal.matchingProducts?.length || deal.liked ? (
            <>
              <Divider />
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <DealMatch deal={deal} />
                {deal.liked ? <InterestedMark /> : null}
              </Stack>
            </>
          ) : null}
        </Stack>
      </Stack>
    </Link>
  );
};
