import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityCommentsMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  const mutation = useMutation({
    mutationFn: async ({ opportunityId }: { opportunityId: string }) => {
      const token = await getAccessTokenSilently();
      const { comments } = await applicationServiceClient.getOpportunityComments(opportunityId, token);
      return comments;
    },
  });

  return mutation;
};
