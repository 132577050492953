import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { sumBy } from 'lodash-es';
import { CreditMemoTable } from '../../../creditMemoTable/CreditMemoTable';
import type { CellContent } from '../../../creditMemoTable/CreditMemoTable';
import { totalRow } from '../../../creditMemoTable/creditMemoTableRowCreator';
import { ApplicationContext } from '../../../../../ApplicationContext';

const headers = [
  { text: 'Type', width: '15%' },
  { text: 'Market Value', width: '15%' },
  { text: 'Liquidation Value', width: '10%' },
  { text: 'Description', width: '15%' },
  { text: 'Lien Position', width: '10%' },
];

export const CollateralSummaryTable: FC = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const collateralValueByType = selectors.collateralValueByType({
      application,
      entity: application,
      entityType: 'application',
      yearsBack: 0,
    });
    const collateral = selectors.applicationCollateralSelector({
      application,
      entity: application,
      entityType: 'application',
      yearsBack: 0,
    });
    if (!collateral?.length) {
      return [];
    }
    const collateralTypeRows = collateral.map<CellContent[]>((item) => [
      { value: item.type ?? 'N/A' },
      { value: item.totalValue, type: 'currency', align: 'right' },
      { value: (item.totalValue ?? 0) * ((item.advanceRate ?? 0) / 100), type: 'currency', align: 'right' },
      { value: item.description ?? 'N/A' },
      { value: item.lienPosition ?? 'N/A', align: 'right' },
    ]);

    const totalsRowCellValues: CellContent[] = [
      { value: sumBy(collateralValueByType, 'marketValue'), type: 'currency', align: 'right' },
      { value: sumBy(collateralValueByType, 'netAmount'), type: 'currency', align: 'right' },
      { value: '' }, // empty cell for description total
      { value: '', align: 'right' }, // empty cell for lien position total
    ];

    const totalRowDefinition = totalRow({ cells: totalsRowCellValues });

    return [...collateralTypeRows, totalRowDefinition];
  }, [application]);

  return <CreditMemoTable headers={headers} rows={rows} />;
};
