import React, { useContext, useMemo } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import { getSelectedPropertyValue } from '@lama/properties';
import { Text, Flex, greyPalette } from '@lama/design-system';
import { InfoLine } from '@lama/app-components';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';
import { ApplicationContext } from '../../../../Application/ApplicationContext';

const PropertyInfoLine = ({ property, entity }: { property: RequirementProperty; entity: Record<string, any> }) => {
  const { application } = useContext(ApplicationContext);
  const propertyValue = useMemo(
    () =>
      getSelectedPropertyValue({
        fieldName: property.fieldName,
        entity,
        entityType: property.entityType ?? 'opportunity',
        application,
        sources: property.sources,
        propertyType: property.type,
      }).value,
    [entity, property.fieldName, property.sources, property.type, property.entityType, application],
  );
  return <InfoLine key={property.propertyId} label={property.displayName} values={propertyValue} type={property.type} />;
};

export const EntityPropertiesExport = ({
  entity,
  entityName,
  properties,
}: {
  entity: Record<string, any>;
  entityName: string;
  properties: RequirementProperty[];
}) => (
  <Flex flexDirection={'column'} gap={6}>
    <Text variant={'h5'} color={greyPalette[500]}>
      {entityName}
    </Text>
    <BorderedContainer>
      <Flex flexDirection={'column'} gap={4}>
        {properties.map((property) => (
          <PropertyInfoLine key={property.propertyId} property={property} entity={entity} />
        ))}
      </Flex>
    </BorderedContainer>
  </Flex>
);
