import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useConfirmModal } from '@lama/app-components';
import type { DealApiModel } from '@lama/contracts';
import { useQueryClient } from '@tanstack/react-query';
import LightInterested from '../../../../shared/icons/light_interested.svg';
import { useUpdateDeal } from '../../../../shared/hooks/react-query/deal/useUpdateDeal';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';

interface HeaderButtonsProps {
  interested: boolean;
  dismissed: boolean;
  deal: DealApiModel;
}

export const HeaderButtons: FC<HeaderButtonsProps> = ({ interested, dismissed, deal: { id: dealId, opportunityId } }) => {
  const navigate = useNavigate();
  const updateDeal = useUpdateDeal(dealId);
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);
  const { confirm } = useConfirmModal();
  const queryClient = useQueryClient();

  const handleInterested = useCallback(() => {
    navigate('productSelection');
  }, [navigate]);

  const handleDismiss = useCallback(async () => {
    const confirmed = await confirm({ title: 'Are you sure?', confirmText: 'Confirm' });

    if (!confirmed) {
      return;
    }

    await updateDeal({
      dismissed: true,
    });
  }, [confirm, updateDeal]);

  const handleNotInterested = useCallback(async () => {
    const confirmed = await confirm({ title: 'Are you sure?', confirmText: 'Confirm' });
    if (!confirmed) {
      return;
    }

    await updateOpportunity({ status: 'Cancelled' });
    await queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
  }, [confirm, dealId, queryClient, updateOpportunity]);

  const handleRevertDismiss = useCallback(async () => {
    await updateDeal({
      dismissed: false,
    });
  }, [updateDeal]);

  if (interested) {
    return (
      <Stack spacing={2}>
        <Button variant={'outlined'} color={'error'} onClick={handleNotInterested}>
          {'Not Interested'}
        </Button>
      </Stack>
    );
  }

  if (dismissed) {
    return (
      <Button variant={'outlined'} onClick={handleRevertDismiss}>
        {'Revert Dismiss'}
      </Button>
    );
  }

  return (
    <Stack spacing={2}>
      <Button startIcon={<LightInterested />} variant={'contained'} onClick={handleInterested}>
        {'Interested'}
      </Button>
      <Button variant={'outlined'} color={'error'} onClick={handleDismiss}>
        {'Dismiss'}
      </Button>
    </Stack>
  );
};
