import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Flex, greyPalette, Text, Button } from '@lama/design-system';
import { getSourcedProperty } from '@lama/properties';
import { ApplicationContext } from '../../ApplicationContext';
import { EtranAuthenticationDialog } from '../ExportToEtran/EtranAuthenticationDialog';
import { EtranExportErrorsDialog } from '../ExportToEtran/EtranExportErrorsDialog';
import { EtranPayloadPreviewSegment } from '../ExportToEtran/EtranPreviewSegment';
import { useExportToEtranFlow } from '../ExportToEtran/useExportToEtranFlow';
import { useGetPartnerQuery } from '../../../../shared/hooks/react-query/partner/useGetPartnerQuery';

const sbssScorePropertyKey = 'application_sbss';

export const SbssEtranSegment: FC = () => {
  const {
    application,
    properties,
    opportunity: { partnerId },
  } = useContext(ApplicationContext);
  const {
    dialogMode,
    onNextStepClick,
    onClose,
    hasCode,
    exportErrors,
    payload,
    showDialog,
    onDialogSuccess,
    clearErrors,
    exporting,
    payloadErrorStatus,
  } = useExportToEtranFlow();
  const { data: partner } = useGetPartnerQuery(partnerId);
  const { etranExportEnabledForUnderwriting } = useMemo(() => partner?.featureConfigurations ?? {}, [partner]);

  const sourcedSbssScore = useMemo(() => {
    const sbssScoreProperty = properties[sbssScorePropertyKey];
    if (!sbssScoreProperty) {
      return;
    }
    return getSourcedProperty(sbssScoreProperty, application, application, 0);
  }, [properties, application]);

  const onErrorDialogClose = useCallback(() => {
    clearErrors();
  }, [clearErrors]);

  const fetchedFromEtran = useMemo(() => sourcedSbssScore?.selectedPropertyValue?.source.source === 'creditReport', [sourcedSbssScore]);

  const exportButtonEnabled = useMemo(
    () => !payloadErrorStatus.applicationHasErrors && !payloadErrorStatus.businessesHaveErrors && !payloadErrorStatus.peopleHaveErrors,
    [payloadErrorStatus],
  );

  if (!etranExportEnabledForUnderwriting || fetchedFromEtran) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex justifyContent={'space-between'}>
        <Flex flexDirection={'column'}>
          <EtranExportErrorsDialog
            open={exportErrors?.length > 0}
            onClose={onErrorDialogClose}
            errors={exportErrors}
            onSuccess={onErrorDialogClose}
          />
        </Flex>
      </Flex>
      <Flex height={'100%'} maxHeight={'100%'} justifyContent={'space-between'}>
        <Flex alignSelf={'flex-end'}>
          <Text variant={'body1'} color={greyPalette[500]}>
            {'Pull SBSS Score'}
          </Text>
        </Flex>
        <Button disabled={!exportButtonEnabled} loading={exporting} onClick={onNextStepClick}>
          {'Pull SBSS Score'}
        </Button>
      </Flex>
      <EtranPayloadPreviewSegment payload={payload} />
      <EtranAuthenticationDialog
        open={showDialog}
        mode={dialogMode}
        onlyCollectCredentials={hasCode}
        onClose={onClose}
        onSuccess={onDialogSuccess}
        buttonTitle={hasCode ? 'Submit' : 'Login'}
      />
    </Flex>
  );
};
