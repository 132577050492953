import React from 'react';
import styled from '@emotion/styled';
import { greyPalette, Flex, Text } from '@lama/design-system';
import { closingTaskStatuses } from '@lama/contracts';

const closingTasksStatusesWithNone = ['None', ...closingTaskStatuses] as const;

export type ClosingTaskStatusWithNone = (typeof closingTasksStatusesWithNone)[number];

export const closingTaskStatusToTextMapping: Record<ClosingTaskStatusWithNone, string> = {
  Complete: 'Complete',
  Pending: 'Pending',
  Dismissed: 'Dismissed',
  None: 'None',
};

export const statusColorMapping: Record<ClosingTaskStatusWithNone, string> = {
  Pending: '#FFA000',
  Complete: '#44CFA6',
  Dismissed: greyPalette[500],
  None: greyPalette[500],
};

export const StyledStatusBadge = styled.div<{ status: ClosingTaskStatusWithNone; size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ status }) => statusColorMapping[status]};
  border-radius: 50%;
  border: 1px solid #ffffff;
  display: inline-block;
`;

export const ClosingTaskStatusBadge: React.FC<{ status: ClosingTaskStatusWithNone; showText?: boolean; size?: string }> = ({
  status,
  showText = true,
}) => (
  <Flex gap={2} alignItems={'center'}>
    <StyledStatusBadge status={status} size={'10px'} />
    {showText ? (
      <Text variant={'body2'} ellipsis color={greyPalette[500]} size={28}>
        {closingTaskStatusToTextMapping[status]}
      </Text>
    ) : null}
  </Flex>
);
