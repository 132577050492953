import React from 'react';
import type { AnyOfMatcher, Condition, GreaterThanMatcher, LessThanMatcher, Matcher, NoneOfMatcher, RangeMatcher } from '@lama/contracts';
import type { ConditionConfiguration } from '@lama/conditions';
import type { MatcherValue } from './Matchers/types';
import type { MatcherConfiguration } from './Matchers/matcherConfigurations';
import { InequalityCondition } from './InequalityCondition';
import { SelectCondition } from './SelectCondition';
import { RangeCondition } from './RangeCondition';

const isCondition = (condition: Condition | Matcher): condition is Condition =>
  condition && (condition as Condition).conditionMatcher !== undefined;
export const conditionV2ByTypeComponent = (
  configuration: ConditionConfiguration | MatcherConfiguration,
  onConditionChange: (matcher: MatcherValue) => void,
  condition: Condition | Matcher,
) => {
  let matcher = condition;
  if (isCondition(condition)) {
    matcher = condition.conditionMatcher;
  }
  switch (configuration.type) {
    case 'range': {
      return (
        <RangeCondition
          key={configuration.displayName}
          condition={matcher as RangeMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'greaterThan': {
      return (
        <InequalityCondition
          key={configuration.displayName}
          condition={matcher as GreaterThanMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'lessThan': {
      return (
        <InequalityCondition
          key={configuration.displayName}
          condition={matcher as LessThanMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'noneOf': {
      return (
        <SelectCondition
          key={configuration.displayName}
          condition={matcher as NoneOfMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    case 'anyOf': {
      return (
        <SelectCondition
          key={configuration.displayName}
          condition={matcher as AnyOfMatcher}
          configuration={configuration}
          onChange={onConditionChange}
        />
      );
    }
    default: {
      return null;
    }
  }
};
