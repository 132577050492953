import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import type { GroupedDocumentBoxDetails } from '../types';

interface ZipEntry {
  path: string;
  blob: Blob;
}

const getDocumentsStructure = async (groupName: string, documents: GroupedDocumentBoxDetails[], basePath = ''): Promise<ZipEntry[]> => {
  const groupPath = basePath ? `${basePath}/${groupName}` : groupName;

  const documentEntries = await Promise.all(
    documents
      .filter((doc) => doc.document)
      .map(async (doc) => {
        const downloadUrl = doc.document?.downloadUrl;

        if (downloadUrl) {
          try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const fileName = `${doc.document?.filename ?? 'document'}.pdf`;
            return { path: `${groupPath}/${doc.subGroup}/${fileName}`, blob };
          } catch {
            toast.error('Failed to download document', { toastId: 'document-download-failed' });
            return null;
          }
        }
        return null;
      }),
  );

  return documentEntries.filter((entry): entry is ZipEntry => entry !== null);
};

export const downloadDocumentGroup = async (groupName: string, documents: GroupedDocumentBoxDetails[]) => {
  const entries = await getDocumentsStructure(groupName, documents);

  if (entries.length === 0) {
    console.log('No documents to download');
    return;
  }

  const zip = new JSZip();

  entries.forEach(({ path, blob }) => {
    zip.file(path, blob);
  });

  const content: Blob = await zip.generateAsync({ type: 'blob' });

  saveAs(content, `${groupName}.zip`);
};
