import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { sumBy } from 'lodash-es';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { totalRow } from '../../creditMemoTable/creditMemoTableRowCreator';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

const headers = [
  { text: 'Closing Item', width: '50%' },
  { text: 'Amount', width: '50%' },
];

export const GrasshopperClosingCostsSummarySegment: FC = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const breakdownByTypeRows =
      application.closingItems?.map<CellContent[]>((item) => [
        { value: item.type },
        { value: item.amount, type: 'currency', align: 'right' },
      ]) ?? [];

    const totalAmount = sumBy(application.closingItems ?? [], (a) => a.amount ?? 0);
    const totalRowDefinition = totalRow({ cells: [{ value: totalAmount, type: 'currency', align: 'right' }] });

    return [...breakdownByTypeRows, totalRowDefinition];
  }, [application]);

  return (
    <SegmentContainer title={'Closing Costs'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
