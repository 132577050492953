import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ProductTemplateApiModel } from '@lama/contracts';
import { useAuth0 } from '@auth0/auth0-react';
import { productServiceClient } from '../../../clients/productService';

export const useProductTemplatesQuery = (options?: UseQueryOptions<ProductTemplateApiModel[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getProductTemplatesQuery = async () => {
    const token = await getAccessTokenSilently();

    return productServiceClient.getProductTemplates(token);
  };

  return useQuery<ProductTemplateApiModel[]>({ queryKey: ['productTemplates'], queryFn: getProductTemplatesQuery, ...options });
};
