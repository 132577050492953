/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import type { AgGridReact } from '@ag-grid-community/react';
import React, { useContext, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { compact, intersection, isEmpty, isNil, keyBy, uniq } from 'lodash-es';
import { Add } from '@mui/icons-material';
import type {
  GridReadyEvent,
  IRowNode,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  IServerSideSelectionState,
  PaginationChangedEvent,
} from '@ag-grid-community/core';
import { useDebounce, useSessionStorage, useToggle } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingPage } from '@lama/app-components';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex } from '@lama/design-system';
import type { GetPipelineItemsQueryParams, PipelineItem } from '@lama/pipeline-items-service-client';
import { applicationServiceClient } from '../../shared/clients/applicationServiceClient';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useGetPartnersQuery } from '../../shared/hooks/react-query/partner/useGetPartnersQuery';
import { pipelineItemsServiceClient } from '../../shared/clients/pipelineItemsServiceClient';
import { useGetPartnersProductsQuery } from '../../shared/hooks/react-query/product/useGetPartnersProductsQuery';
import { useGetPartnersReferrersQuery } from '../../shared/hooks/react-query/referrer/useGetPartnersReferrersQuery';
import { NotificationCenter } from '../../shared/components/NotificationCenter/NotificationCenter';
import { useUserPartner } from '../../shared/hooks/useCurrentPartner';
import { getPipelineCategoriesForItem } from './getPipelineCategoryForItem';
import { PipelineFilters } from './PipelineFilters';
import { defaultPipelineCategory, PipelineCategoryTabsV2 } from './PipelineCategoryTabsV2';
import { useGetBusinessImages } from './hooks/useGetBusinessImages';
import { PipelineTable } from './PipelineTable';
import { filtersToPipelineQueryParams, getColumnDefinitions } from './PipelineColumns';
import './styles/pipelineTable.css';
import type { CalculatedPipelineItem } from './types';
import { AddApplicationDialogV2 } from './AddApplicationDialogV2';
import type { PipelinePreservedState } from './localStorageUtils';
import { savePipelineState, loadPipelinePreservedState, searchFromState, searchToState, clearPipelineState } from './localStorageUtils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { RowSelectionFab } from './RowSelectionFab';

const PIPELINE_LIMIT = 30;

export const PipelinePageV2: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [createApplicationDialogOpen, toggleCreateApplicationDialogOpen] = useToggle(false);
  const [globalFilter, setGlobalFilter] = useState<string>(decodeURIComponent(searchParams.get('q') ?? ''));
  const [debouncedFilter, setDebouncedFilter] = useState<string>(decodeURIComponent(searchParams.get('q') ?? ''));
  const [agFilterCount, setAgFilterCount] = useState<number>(0);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [queryFilters, setQueryFilters] = useState<GetPipelineItemsQueryParams>({});
  const { partner: userPartnerId, email, partnerPermissions } = useContext(UserDetailsContext);
  const partner = useUserPartner();
  const queryClient = useQueryClient();
  const { fibtDemo, showSourceColumnInPipeline, showApplicationNumber, allowUserPermissionsInPipeline } = useFlags();
  const { showReferrerColumnInPipeline, showLastStatusUpdateInPipeline, enableCreateApplicationButton } = useMemo(
    () => partner?.featureConfigurations ?? {},
    [partner],
  );
  const gridRef = useRef<AgGridReact>(null);

  const tabsAppliedFilters = useMemo(
    () => ({
      partnerIds: queryFilters.partnerIds ?? [],
      productIds: queryFilters.productIds ?? [],
      referrerCodes: queryFilters.referrerCodes ?? [],
      statuses: queryFilters.statuses ?? [],
      search: queryFilters.search ?? '',
      assignedTo: queryFilters.assignedTo ?? [],
    }),
    [queryFilters],
  );

  const [selectedPipelineCategory, setSelectedPipelineCategory] = useSessionStorage(
    'selectedPipelineCategory',
    decodeURIComponent(searchParams.get('category') ?? '') || defaultPipelineCategory,
  );
  const userPartners = useMemo(() => {
    const res = uniq([
      ...(userPartnerId ? [userPartnerId] : []),
      ...(allowUserPermissionsInPipeline && partnerPermissions
        ? Object.entries(partnerPermissions)
            .filter(([, value]) => intersection(value, ['read', 'write']).length > 0)
            .map(([key]) => key)
        : []),
    ]);

    return res;
  }, [userPartnerId, allowUserPermissionsInPipeline, partnerPermissions]);

  const { data: partners, pending: loadingPartners } = useGetPartnersQuery(userPartners);

  const partnerOptions = useMemo(() => (loadingPartners ? [] : partners ?? []), [loadingPartners, partners]);

  const { data: users } = useUsersByPartnerQuery(userPartnerId);
  const { data: products, pending: loadingProducts } = useGetPartnersProductsQuery(userPartners);
  const { data: referrers, pending: loadingReferrers } = useGetPartnersReferrersQuery(userPartners);

  const saveQueryChanges = useCallback(
    (partialState: Partial<PipelinePreservedState>) => {
      savePipelineState(partialState);
      const stateFromCurrentSearch = searchToState(location.search);
      const newSearch = searchFromState({ ...stateFromCurrentSearch, ...partialState });

      if (`${location.pathname}${location.search}` !== `${location.pathname}${newSearch}`) {
        navigate(`${location.pathname}${newSearch}`, { replace: true });
      }
    },
    [location.pathname, location.search, navigate],
  );

  const productOptions = useMemo(() => products ?? [], [products]);

  const referrerOptions = useMemo(() => referrers ?? [], [referrers]);

  const onGridReady = useCallback(
    (params: GridReadyEvent<CalculatedPipelineItem>) => {
      if (location.search) {
        const searchState = searchToState(location.search);

        if (!isNil(searchState) && !isEmpty(searchState)) {
          setSelectedPipelineCategory(searchState.selectedCategory ?? defaultPipelineCategory);

          if (searchState.searchTerm) {
            setGlobalFilter(searchState.searchTerm);
          }
          if (searchState.columnState) {
            params.api.applyColumnState({ state: searchState.columnState, defaultState: { sort: null } });
          }

          if (searchState.filterModel) {
            params.api.setFilterModel(searchState.filterModel);
            setAgFilterCount(Object.keys(searchState.filterModel).length);
          }
        }
      }
    },
    [location, setSelectedPipelineCategory],
  );

  useEffect(() => {
    if (location.state?.preserved) {
      const state = loadPipelinePreservedState();

      if (state) {
        saveQueryChanges(state);
      }

      return;
    }

    if (!location.search) {
      clearPipelineState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: businessImages } = useGetBusinessImages([]);

  const userOptions = useMemo(
    () => users?.filter((user) => (email?.includes('@lama.ai') ? true : !user.email.includes('@lama.ai'))) ?? [],
    [email, users],
  );

  const businessImagesByPlaceId = useMemo(() => keyBy(businessImages, (r) => r.placeId), [businessImages]);

  const { getAccessTokenSilently } = useAuth0();

  const handleCategoryChange = useCallback(
    (_: React.SyntheticEvent, category: string) => {
      setSelectedPipelineCategory(category);

      if (gridRef.current) {
        gridRef.current.api.onFilterChanged();
      }

      saveQueryChanges({ selectedCategory: category });
    },
    [saveQueryChanges, setSelectedPipelineCategory],
  );

  const getPipelineItemsWithCategories = useCallback(
    (pipelineItems: PipelineItem[]) =>
      compact(
        pipelineItems.map(
          (item: PipelineItem) =>
            ({
              ...item,
              pipelineCategories: getPipelineCategoriesForItem(item),
              assignedTo: users ? users.filter((user) => item.assignedToIds?.includes(user.id)) : [],
              imageUrl: businessImagesByPlaceId[item.businessGooglePlaceId ?? '']?.imageUrl,
            } as CalculatedPipelineItem),
        ),
      ),
    [users, businessImagesByPlaceId],
  );

  const onCreateApplicationClose = useCallback(
    async (applicationId?: string) => {
      toggleCreateApplicationDialogOpen();

      if (applicationId) {
        const token = await getAccessTokenSilently();
        try {
          const application = await applicationServiceClient.getApplication(applicationId, token);
          queryClient.setQueryData(['application', applicationId], application);
          navigate(`./${application.leadingOpportunityId}/requirements`);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [getAccessTokenSilently, navigate, queryClient, toggleCreateApplicationDialogOpen],
  );

  const updateDebouncedFilter = useCallback(() => {
    if (gridRef.current) {
      setDebouncedFilter(globalFilter);
    }

    saveQueryChanges({ searchTerm: globalFilter });
  }, [globalFilter, saveQueryChanges]);

  const clearGlobalFilter = useCallback(() => {
    setGlobalFilter('');
    saveQueryChanges({ searchTerm: '' });
    if (gridRef.current) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setDebouncedFilter('');
    }
  }, [saveQueryChanges]);

  const doesItemFitCategory = useCallback(
    (item: IRowNode<CalculatedPipelineItem>) => !!item.data?.pipelineCategories.includes(selectedPipelineCategory),
    [selectedPipelineCategory],
  );

  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
    saveQueryChanges({ filterModel: {}, searchTerm: '', selectedCategory: defaultPipelineCategory });
  }, [saveQueryChanges]);

  const onFilterChanged = useCallback(() => {
    if (gridRef.current) {
      const filters = gridRef.current.api.getFilterModel();
      setAgFilterCount(Object.keys(filters).length);
      saveQueryChanges({ filterModel: filters });
    }
  }, [saveQueryChanges]);

  const onSortChanged = useCallback(() => {
    if (gridRef.current) {
      const columnState = gridRef.current.api.getColumnState();
      saveQueryChanges({ columnState });
    }
  }, [saveQueryChanges]);

  const getPipelineQueryFunction = useCallback(
    async (requestParams: IServerSideGetRowsRequest) => {
      const token = await getAccessTokenSilently();

      const filters = filtersToPipelineQueryParams({
        filterModel: requestParams.filterModel,
        userPartners,
        globalFilter: debouncedFilter,
        columnState: requestParams.sortModel,
        pipelineCategory: debouncedFilter.length ? 'all' : selectedPipelineCategory,
      });

      setQueryFilters(filters);

      return pipelineItemsServiceClient.getPaginatedPipeline(
        {
          limit: PIPELINE_LIMIT,
          page: requestParams.startRow ? Math.floor(requestParams.startRow / PIPELINE_LIMIT) + 1 : 1,
          ...filters,
        },
        token,
      );
    },
    [debouncedFilter, getAccessTokenSilently, selectedPipelineCategory, userPartners],
  );

  const agColumnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        userOptions,
        productOptions,
        partnerOptions,
        referrerOptions,
        showApplicationNumberColumnInPipeline: !!showApplicationNumber,
        showReferrerColumnInPipeline: !!showReferrerColumnInPipeline,
        showSourceColumnInPipeline,
        showLastStatusUpdateInPipeline: !!showLastStatusUpdateInPipeline,
        applicationStatusToDisplayName: partner.applicationStatusDisplayNameMapping,
        enableArrayFieldSorting: false,
        fibtDemo,
      }),
    [
      userOptions,
      productOptions,
      partnerOptions,
      referrerOptions,
      showApplicationNumber,
      showReferrerColumnInPipeline,
      showSourceColumnInPipeline,
      showLastStatusUpdateInPipeline,
      partner.applicationStatusDisplayNameMapping,
      fibtDemo,
    ],
  );

  const isExternalFilterPresent = useCallback(
    () => selectedPipelineCategory !== 'all' && !debouncedFilter,
    [debouncedFilter, selectedPipelineCategory],
  );

  const serverDataSource = useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        const response = await getPipelineQueryFunction(params.request);

        const rowData = getPipelineItemsWithCategories(response.pipeline);

        params.success({ rowData, rowCount: response.total });
      },
    }),
    [getPipelineQueryFunction, getPipelineItemsWithCategories],
  );

  const onPaginationChanged = useCallback(
    (event: PaginationChangedEvent) => {
      if (event.newPage) {
        const newPage = event.api.paginationGetCurrentPage();
        saveQueryChanges({ page: newPage + 1 });
      }
    },
    [saveQueryChanges],
  );

  const onSelectionChanged = useCallback(() => {
    if (gridRef.current) {
      const serverSelectionState = gridRef.current.api.getServerSideSelectionState() as IServerSideSelectionState;
      if (!serverSelectionState) {
        setSelectedRowsCount(0);
        return;
      }

      setSelectedRowsCount(
        serverSelectionState.selectAll ? gridRef.current.api.paginationGetRowCount() : serverSelectionState.toggledNodes.length,
      );
    }
  }, []);

  useDebounce(updateDebouncedFilter, 350, [globalFilter]);
  if (loadingPartners || loadingProducts || loadingReferrers) {
    return <LoadingPage />;
  }

  return (
    <>
      <Flex flexDirection={'column'} pt={4} height={'100%'}>
        <Flex justifyContent={'space-between'} alignItems={'center'} pt={6} pb={6} px={8} gap={16}>
          <PipelineFilters
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            onClearFilters={clearFilters}
            clearGlobalFilter={clearGlobalFilter}
            agFilterCount={agFilterCount}
          />
          <Flex gap={2}>
            <Flex alignItems={'center'} gap={4}>
              {enableCreateApplicationButton ? (
                <Button variant={'secondary'} color={'primary'} size={'m'} startIcon={<Add />} onClick={toggleCreateApplicationDialogOpen}>
                  {'Application'}
                </Button>
              ) : null}
              <NotificationCenter />
            </Flex>
          </Flex>
        </Flex>
        <PipelineCategoryTabsV2
          selectedPipelineCategory={selectedPipelineCategory}
          queryFilters={tabsAppliedFilters}
          onChange={handleCategoryChange}
          hideCategories={!!debouncedFilter}
        />
        <PipelineTable
          ref={gridRef}
          columnDefs={agColumnDefinitions}
          rowModelType={'serverSide'}
          serverSideDatasource={serverDataSource}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesItemFitCategory}
          onFilterChanged={onFilterChanged}
          onGridReady={onGridReady}
          onSortChanged={onSortChanged}
          pagination
          suppressServerSideFullWidthLoadingRow
          suppressPaginationPanel={false}
          gridOptions={{ suppressPaginationPanel: false }}
          paginationPageSize={PIPELINE_LIMIT}
          cacheBlockSize={PIPELINE_LIMIT}
          onPaginationChanged={onPaginationChanged}
          paginationPageSizeSelector={false}
          rowSelection={fibtDemo ? 'multiple' : undefined}
          onSelectionChanged={onSelectionChanged}
        />
        <RowSelectionFab selectedRowsCount={selectedRowsCount} />
      </Flex>
      {createApplicationDialogOpen ? (
        <AddApplicationDialogV2 open={createApplicationDialogOpen} handleClose={onCreateApplicationClose} />
      ) : null}
    </>
  );
};
