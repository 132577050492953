import { useAuth0 } from '@auth0/auth0-react';
import type { UpdateApplicationRelationParams } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useRemoveApplicationRelation = (applicationId: string, opportunityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ relation, entityId, entityType }: UpdateApplicationRelationParams) => {
      const token = await getAccessTokenSilently();
      await applicationServiceClient.removeApplicationRelatedEntity({ applicationId, relation, entityId, entityType }, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityEvaluation', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityAlerts', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
