import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import type { EvaluatedCondition } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export interface EvaluatedPipelineItem {
  opportunityId: string;
  evaluatedConditions: EvaluatedCondition[];
}

export const useEvaluateOpportunityQuery = (opportunityId: string | undefined, options?: UseQueryOptions<EvaluatedCondition[] | null>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const evaluateOpportunity = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const { evaluatedConditions } = await applicationServiceClient.evaluateOpportunity(opportunityId, token);

    const pipelineItem: EvaluatedPipelineItem = { opportunityId, evaluatedConditions };
    queryClient.setQueryData(['pipelineEvaluation', opportunityId], pipelineItem);

    return evaluatedConditions;
  }, [getAccessTokenSilently, opportunityId, queryClient]);

  const query = useQuery<EvaluatedCondition[] | null>({
    queryKey: ['opportunityEvaluation', opportunityId],
    queryFn: evaluateOpportunity,
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

  return query;
};
