import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../../shared/SegmentContainer';

export const FirstBankBorrowers = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'Name'} value={'Abigail Benedict Covington'} />
        <KeyValuePair name={'CB Score'} value={'732'} />
        <KeyValuePair name={'SC Score'} value={'0'} />
        <KeyValuePair name={'FICO LiquidCredit'} />
        <KeyValuePair name={'Housing'} value={'Buying'} />
        <KeyValuePair name={'Mortgage/Rent'} value={'0$'} />
        <KeyValuePair name={'Time at Res'} value={'3y - 6m'} />
        <KeyValuePair name={'Time at Emp'} value={'4y - 0m'} />
        <KeyValuePair name={'Employer'} value={'Nayak Plastic Surgery'} />
        <KeyValuePair name={'Position'} value={'Med Tech'} />
        <KeyValuePair name={'SSN'} value={'xxx'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'Tot Income'} value={'$8,000.00'} />
        <KeyValuePair name={'Installment'} value={'$0.00'} />
        <KeyValuePair name={'Revolving'} value={'$1.69'} />
        <KeyValuePair name={'Mortgage'} value={'$28.48'} />
        <KeyValuePair name={'Remaining'} value={'$0.00'} />
        <KeyValuePair name={'Total Debt'} value={'$30.17'} />
        <KeyValuePair name={'Payoff'} value={'$0.00'} />
        <KeyValuePair name={'Current DTI'} value={'$37.712'} />
        <KeyValuePair name={'Post DTI'} value={'$49.364'} />
      </Grid>
    </SegmentContainer>
  </Flex>
);
