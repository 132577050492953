import React from 'react';
import type { FC } from 'react';
import { orderBy } from 'lodash-es';
import type { OpportunityClosingTask } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { ClosingTaskCard } from './ClosingTaskCard';

interface ClosingTasksProps {
  closingTasks: OpportunityClosingTask[];
}

export const ClosingTasks: FC<ClosingTasksProps> = ({ closingTasks }) => (
  <Flex flexDirection={'column'} gap={4}>
    {orderBy(closingTasks, ({ index }) => index).map((task) => (
      <ClosingTaskCard key={task.id} task={task} />
    ))}
  </Flex>
);
