import type { MRT_TableInstance } from 'material-react-table';
import type { DocumentLineItem } from '@lama/document-service-client';
import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { AddRowButton } from './AddRowButton';
import { AddColumnButton } from './AddColumnButton';
import type { Period } from './FinancialStatementsSpreadingContent';

interface ToolbarCustomActionsProps {
  defaultRow: DocumentLineItem;
  table: MRT_TableInstance<DocumentLineItem>;
  onAddColumn: (periods: Period) => void;
  creatingRow: boolean;
  toggleCreatingRow: () => void;
}

export const ToolbarCustomActions: FC<ToolbarCustomActionsProps> = ({ table, defaultRow, onAddColumn, creatingRow, toggleCreatingRow }) => (
  <Flex>
    <AddRowButton table={table} disabled={creatingRow} onClick={toggleCreatingRow} defaultRow={defaultRow} />
    <AddColumnButton table={table} onAddColumn={onAddColumn} />
  </Flex>
);
