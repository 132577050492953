import { compact } from 'lodash-es';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { ProductApiModel } from '@lama/contracts';
import { productServiceClient } from '../../../clients/productService';

export const useGetPartnersProductsQuery = (
  partnerIds: string[],
  options?: { includeDisabled?: boolean },
  queryOptions?: UseQueryOptions<ProductApiModel[] | null>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQueries({
    queries: partnerIds.map((partnerId) => ({
      queryKey: ['products', `includeDisabled=${!!options?.includeDisabled}`, partnerId],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        return productServiceClient.getProducts(partnerId, token, options);
      },
      queryOptions,
    })),
    combine: (results) => ({
      pending: results.some((result) => result.isPending),
      data: compact(results.flatMap((result) => result.data)),
    }),
  });
};
