import { useCallback, useContext } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { compact } from 'lodash-es';
import type { PlaceApiResult } from '../../Application/GooglePlacesServiceContext';
import { GooglePlacesServiceContext } from '../../Application/GooglePlacesServiceContext';

export const useGetBusinessImages = (placeIds: string[], options?: UseQueryOptions<{ placeId: string; imageUrl?: string }[]>) => {
  const { useGooglePlaces } = useFlags();

  const placesService = useContext(GooglePlacesServiceContext);

  const getBusinessImageQueryFunction = useCallback(async () => {
    if (!useGooglePlaces) {
      return [];
    }

    const uniquePlaceIds = [...new Set(placeIds)];

    const results = await Promise.all(
      uniquePlaceIds.map((placeId) => {
        if (placeId) {
          return new Promise<{ placeId: string; imageUrl?: string }>((resolve) => {
            const cachedPhoto = sessionStorage.getItem(`photosCache.${placeId}`);
            if (cachedPhoto) {
              resolve({ placeId, imageUrl: cachedPhoto });
            } else if (placesService) {
              placesService.getDetails({ placeId, fields: ['photos'] }, (placeDetails: PlaceApiResult) => {
                const photoUrlInner = placeDetails?.photos?.[0]?.getUrl({ maxWidth: 200 });

                if (photoUrlInner) {
                  sessionStorage.setItem(`photosCache.${placeId}`, photoUrlInner);
                }

                resolve({ placeId, imageUrl: photoUrlInner });
              });
            }
          });
        }

        return Promise.resolve(null);
      }),
    );

    return compact(results);
  }, [placeIds, placesService, useGooglePlaces]);

  const query = useQuery<{ placeId: string; imageUrl?: string }[]>({
    queryKey: ['businessImages', placeIds],
    queryFn: getBusinessImageQueryFunction,
    ...options,
  });

  return query;
};
