import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';

export const StyledDecisionCardContainer = styled(Flex)<{ disabled?: boolean }>`
  border: 1px solid ${greyPalette[300]};
  ${({ disabled }) => (disabled ? `background-color: ${greyPalette[100]};` : '')}
  gap: ${({ theme }) => theme.space[2]}px;
  padding: ${({ theme }) => theme.space[4]}px;
  flex-direction: column;
  border-radius: 4px;
`;
