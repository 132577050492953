import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { createApplicationServiceClient } from '@lama/clients';
import type { RelatedApplication } from '@lama/contracts';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useRelatedApplicationsQuery = (applicationId: string, options?: Partial<UseQueryOptions<RelatedApplication[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getRelatedApplicationsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return applicationServiceClient.getRelatedApplications(applicationId, token);
  }, [getAccessTokenSilently, applicationId]);

  const query = useQuery<RelatedApplication[]>({
    queryKey: ['relatedApplications', applicationId],
    queryFn: getRelatedApplicationsQuery,
    staleTime: Number.POSITIVE_INFINITY,
    ...options,
  });

  return query;
};
