import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { User } from '@lama/user-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { userServiceClient } from '../../../clients/userServiceClient';

export const useUserQuery = (userId: string | undefined, options?: Omit<UseQueryOptions<User | null>, 'queryFn' | 'queryKey'>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getUserQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!userId) {
      return null;
    }

    return userServiceClient.getUser({ userId }, token);
  }, [getAccessTokenSilently, userId]);

  const query = useQuery<User | null>({ queryKey: ['user', userId], queryFn: getUserQuery, ...options });

  return query;
};
