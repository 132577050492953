/* 
  This function takes an array and groups consecutive items by an iteratee.
  For example:
  consecutiveGroupBy([1, 2, 4, 3, 8, 6], (x) => x % 2 === 0) => [[1], [2, 4], [3], [8, 6]]
*/
export const consecutiveGroupBy = <T>(arr: T[], iteratee: (item: T) => any): T[][] =>
  arr.reduce<T[][]>((groups, value) => {
    const lastGroup = groups.at(-1);

    if (lastGroup?.length && iteratee(lastGroup[0]!) === iteratee(value)) {
      lastGroup.push(value);
    } else {
      groups.push([value]);
    }

    return groups;
  }, []);
