import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import type { FC } from 'react';
import type { FinancialData } from '@lama/contracts';
import { Text, redPalette, amberPalette, Input } from '@lama/design-system';
import { Stack, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LowPriorityIcon } from '../../../../../../shared/icons/LowPriorityIcon';
import { HelpIcon } from '../../../../../../shared/icons/HelpIcon';
import { LightBulbIcon } from '../../../../../../shared/icons/LightBulbIcon';

interface IconWithTextProps {
  Icon: React.FC<{ color?: string; width?: string; height?: string }>;
  text: string;
  color?: string;
}

const IconWithText: FC<IconWithTextProps> = ({ Icon, text, color }) => (
  <Stack display={'flex'} direction={'row'} gap={1} alignItems={'center'} p={0.5}>
    <Icon color={color} width={'14px'} height={'14px'} />
    <Text variant={'body3'} ellipsis>
      {text}
    </Text>
  </Stack>
);

interface TaxReturnsFormFieldProps {
  onFieldFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  subGroup: string;
  id: string;
  financialField?: FinancialData;
}

export const TaxReturnsFormField: FC<TaxReturnsFormFieldProps> = ({
  onFieldFocus,
  onFieldBlur,
  fullWidth,
  subGroup,
  id,
  financialField,
}) => {
  const [{ value, onChange }, { error, touched }] = useField(id);

  const theme = useTheme();
  const { spreadingBackOfficeMode } = useFlags();
  const helperText = useMemo(
    () =>
      spreadingBackOfficeMode ? (
        !financialField || value === '' || value === null ? (
          <IconWithText text={"Field wasn't extracted"} Icon={HelpIcon} color={amberPalette[700]} />
        ) : financialField.source.type === 'Document' && financialField.source.confidence && financialField.source.confidence < 70 ? (
          <IconWithText text={'Low confidence value'} Icon={LowPriorityIcon} color={redPalette[500]} />
        ) : financialField.source.type === 'Manual' ? (
          <IconWithText text={'Feedback has been received'} Icon={LightBulbIcon} color={theme.palette.primary.main} />
        ) : (
          ''
        )
      ) : (
        ''
      ),
    [financialField, spreadingBackOfficeMode, theme.palette.primary.main, value],
  );

  const innerOnChange = useCallback(
    (args: { floatValue: number | undefined }) => {
      onChange({ target: { name: id, value: args.floatValue ?? '' } });
    },
    [id, onChange],
  );

  return (
    <Input
      id={id}
      key={id}
      name={id}
      value={value}
      helper={helperText}
      label={subGroup}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      fullWidth={fullWidth}
      error={!!touched && Boolean(error)}
      type={'number'}
      thousandSeparator={','}
      prefix={'$'}
      valueIsNumericString
      onValueChange={innerOnChange}
    />
  );
};
