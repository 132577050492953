import React, { useContext } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { useGetDeal } from '../../../shared/hooks/react-query/deal/useGetDeal';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { BackLink } from '../../../shared/components/BackLink';
import { ProductSelectionCardList } from './ProductSelectionCardList';

export const ProductSelectionPage = () => {
  const theme = useTheme();
  const { partner } = useContext(UserDetailsContext);
  const { dealId } = useParams<{ dealId?: string }>();
  const { data: deal, isPending: loadingDeal } = useGetDeal(dealId, partner);

  if (loadingDeal) {
    return <LoadingPage />;
  }

  if (!dealId || !deal) {
    return (
      <Stack spacing={2}>
        <BackLink title={'Back to Deals'} to={'/deals'} />
        <span>{'No deal found'}</span>
      </Stack>
    );
  }

  return (
    <Stack height={'100%'} bgcolor={'#F6F6F6'} px={10} py={4} gap={4}>
      <BackLink title={'Back to Deal'} to={`/deals/${dealId}`} />
      <Stack gap={6}>
        <Stack gap={2}>
          <Typography variant={'h6'} color={theme.palette.text.secondary}>
            {'Select a product for this deal'}
          </Typography>
        </Stack>
        <ProductSelectionCardList deal={deal} />
      </Stack>
    </Stack>
  );
};
