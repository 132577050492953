import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { type DocusignTemplateDefinition } from '@lama/docusign-client';
import { useEffectOnce } from 'react-use';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps';
import { useOpportunityEnvelopesTrackingQuery } from './hooks/useOpportunityEnvelopesTrackingQuery';
import { useEnvelopeProcessedMutation } from './hooks/useEnvelopeProcessedMutation';

import { EnvelopeDocusignCard } from './EnvelopeDocusignCard';
import { matchEnvelopesAndRecipients } from './matchEnvelopeWithRecipient';

export const DocusignClosingTask: FC<ClosingTaskComponentProps> = ({ task }) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const envelopeId = searchParams.get('envelopeId');
  const { opportunity, application } = useContext(ApplicationContext);

  const { data: envelopesTrackings, isPending: loadingEnvelopesTracking } = useOpportunityEnvelopesTrackingQuery(opportunity.id);

  const onEnvelopeProcessed = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['opportunityEnvelopesTracking', opportunity.id] });
  }, [opportunity.id, queryClient]);

  const { mutateAsync: envelopeProcessed } = useEnvelopeProcessedMutation({ onSuccess: onEnvelopeProcessed });

  const templates: DocusignTemplateDefinition[] = useMemo(() => task.taskParams?.templates ?? [], [task.taskParams]);

  const envelopesData = useMemo(
    () => matchEnvelopesAndRecipients({ application, templates, envelopesTrackings }),
    [application, envelopesTrackings, templates],
  );

  useEffectOnce(() => {
    if (envelopeId) {
      void envelopeProcessed({ opportunityId: opportunity.id, envelopeId });
      searchParams.delete('envelopeId');
      searchParams.delete('event');

      setSearchParams(searchParams);
    }
  });

  if (loadingEnvelopesTracking) {
    return (
      <Flex flexDirection={'column'} gap={4} py={8} flex={1}>
        <LoadingPage />
      </Flex>
    );
  }

  return (
    <Flex flex={1} flexDirection={'row'} flexWrap={'wrap'} gap={4}>
      {envelopesData?.map(
        (e) =>
          e.templateId && (
            <EnvelopeDocusignCard
              key={e.id}
              people={e.people}
              templateId={e.templateId}
              envelopeTracking={e.envelopeTracking}
              business={e.business}
            />
          ),
      )}
    </Flex>
  );
};
