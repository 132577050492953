import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { Collateral } from '@lama/contracts';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { InfoLine } from '@lama/app-components';
import {
  getInfoLine,
  getRealEstateInfo,
  getVehicleInfo,
  getInsuranceInfo,
} from '../../../../Application/shared/Collateral/getCollateralDetails';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';

export const CollateralSegment: FC<{ collateral: Collateral }> = ({ collateral }) => {
  const { type, description, insuranceDetails } = collateral;
  const info: InfoLineProps[] = useMemo(() => getInfoLine(collateral), [collateral]);
  const realEstateInfo: InfoLineProps[] = useMemo(() => getRealEstateInfo(collateral), [collateral]);
  const vehicleInfo: InfoLineProps[] = useMemo(() => getVehicleInfo(collateral), [collateral]);
  const insuranceInfo: InfoLineProps[] = useMemo(() => getInsuranceInfo(collateral), [collateral]);

  return (
    <BorderedContainer>
      <Text variant={'body1'} color={greyPalette[500]}>
        {type}
      </Text>
      <Flex flexDirection={'column'} gap={4}>
        {info.map((line) => (
          <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />
        ))}
        {insuranceDetails
          ? insuranceInfo.map((line) => <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />)
          : null}
        {type === 'Commercial RE' || type === 'Personal Home'
          ? realEstateInfo.map((line) => <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />)
          : null}
        {type === 'Commercial Vehicle' || type === 'Personal Vehicle'
          ? vehicleInfo.map((line) => <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />)
          : null}
      </Flex>
      <Flex flexDirection={'column'} gap={2}>
        <Text variant={'body2'} color={greyPalette[500]}>
          {'Description'}
        </Text>
        <Text variant={'body2'} paragraph>
          {description}
        </Text>
      </Flex>
    </BorderedContainer>
  );
};
