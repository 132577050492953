import React, { useCallback, useMemo } from 'react';
import { Popover, Button, PopoverTrigger, PopoverContent, Flex, Text } from '@lama/design-system';
import { Tune, ArrowDropDown } from '@mui/icons-material';

import type { FC } from 'react';
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import type { Period } from '@lama/selectors';
import type { PeriodDisplayFormat, SpreadUnitType } from '../../types';
import { formatSpreadCellValue } from '../../utils/formatSpreadCellValue';
import { getPeriodDateWithMonths } from '../../utils/getSpreadColumnTitle';

export const SpreadUnitDescription: FC<{ title: string; unitType: SpreadUnitType }> = ({ title, unitType }) => (
  <Flex gap={1} flexDirection={'row'}>
    <Text variant={'body2'} noWrap>
      {title}
    </Text>
    <Text variant={'body3'}>{`(${formatSpreadCellValue(2110.63, 'currency', unitType)})`}</Text>
  </Flex>
);

interface SpreadSettingsButtonProps {
  allPeriods: Period[];
  currentPeriods: Period[];
  spreadUnitType: SpreadUnitType;
  setSpreadUnitType: (unitType: SpreadUnitType) => void;
  onSetSelectedPeriods: (periods: Period[]) => void;
  periodDisplayFormat: PeriodDisplayFormat;
}

interface PeriodOption {
  label: string;
  value: Period;
}

export const SpreadSettingsButton: FC<SpreadSettingsButtonProps> = ({
  allPeriods,
  currentPeriods,
  onSetSelectedPeriods,
  spreadUnitType,
  setSpreadUnitType,
  periodDisplayFormat,
}) => {
  const renderPeriodsInput = useCallback((params: any) => <TextField size={'small'} {...params} />, []);

  const periodOptions = useMemo<{ label: string; value: Period }[]>(
    () =>
      allPeriods.map((period) => ({
        label: getPeriodDateWithMonths(period, periodDisplayFormat),
        value: period,
      })),
    [allPeriods, periodDisplayFormat],
  );

  const onPeriodsChange = useCallback(
    (event: React.ChangeEvent<{}>, value: PeriodOption[]) => {
      onSetSelectedPeriods(value.map((option) => option.value));
    },
    [onSetSelectedPeriods],
  );

  const currentlySelectedPeriods = useMemo(
    () =>
      periodOptions.filter((option) =>
        currentPeriods.some((period) => period.startDate === option.value.startDate && period.endDate === option.value.endDate),
      ),
    [currentPeriods, periodOptions],
  );

  const onUnitTypeChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, pickedValue: string) => {
      setSpreadUnitType(pickedValue as SpreadUnitType);
    },
    [setSpreadUnitType],
  );

  return (
    <Popover placement={'bottom-end'}>
      <PopoverTrigger>
        <Button variant={'secondary'} color={'neutral'} size={'s'} startIcon={<Tune />} endIcon={<ArrowDropDown />}>
          {'View Settings'}
        </Button>
      </PopoverTrigger>
      <PopoverContent px={4} py={4} width={'535px'}>
        <Flex flexDirection={'column'} gap={4} flex={1}>
          <Flex flexDirection={'column'} gap={2}>
            <Text variant={'body2'} color={'secondary'}>
              {'Periods'}
            </Text>
            <Autocomplete
              id={'periods'}
              value={currentlySelectedPeriods}
              renderInput={renderPeriodsInput}
              options={periodOptions}
              onChange={onPeriodsChange}
              disableCloseOnSelect
              fullWidth
              multiple
            />
          </Flex>
          <Flex flexDirection={'column'} gap={2}>
            <Text variant={'body2'} color={'secondary'}>
              {'Value Format'}
            </Text>
            <Flex alignItems={'center'}>
              <RadioGroup value={spreadUnitType} onChange={onUnitTypeChange} row>
                <FormControlLabel
                  value={'decimal'}
                  control={<Radio />}
                  label={<SpreadUnitDescription title={'Decimal'} unitType={'decimal'} />}
                />
                <FormControlLabel
                  value={'nonDecimal'}
                  control={<Radio />}
                  label={<SpreadUnitDescription title={'Rounded'} unitType={'nonDecimal'} />}
                />
                <FormControlLabel
                  value={'short'}
                  control={<Radio />}
                  label={<SpreadUnitDescription title={'Thousands'} unitType={'short'} />}
                />
              </RadioGroup>
            </Flex>
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
