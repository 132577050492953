/* eslint-disable react/style-prop-object */
// @ts-ignore
import React from 'react';
import type { FC } from 'react';

export const Location: FC = () => (
  <svg width={'17'} height={'16'} viewBox={'0 0 17 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <mask id={'mask0_17081_181218'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'17'} height={'16'}>
      <rect x={'0.046875'} width={'16'} height={'16'} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask0_17081_181218)'}>
      <path
        d={
          'M8.04753 7.83333C8.36975 7.83333 8.64486 7.71955 8.87286 7.492C9.10041 7.264 9.21419 6.98889 9.21419 6.66667C9.21419 6.34444 9.10041 6.06933 8.87286 5.84133C8.64486 5.61378 8.36975 5.5 8.04753 5.5C7.7253 5.5 7.45019 5.61378 7.22219 5.84133C6.99464 6.06933 6.88086 6.34444 6.88086 6.66667C6.88086 6.98889 6.99464 7.264 7.22219 7.492C7.45019 7.71955 7.7253 7.83333 8.04753 7.83333ZM8.04753 13.2C9.4253 11.9778 10.4642 10.8082 11.1642 9.69133C11.8642 8.57489 12.2142 7.61111 12.2142 6.8C12.2142 5.53333 11.8115 4.5 11.0062 3.7C10.2004 2.9 9.21419 2.5 8.04753 2.5C6.88086 2.5 5.89464 2.9 5.08886 3.7C4.28353 4.5 3.88086 5.53333 3.88086 6.8C3.88086 7.61111 4.23086 8.57489 4.93086 9.69133C5.63086 10.8082 6.66975 11.9778 8.04753 13.2ZM8.04753 14.3C7.96975 14.3 7.89197 14.2889 7.81419 14.2667C7.73641 14.2444 7.66419 14.2056 7.59753 14.15C6.01975 12.7167 4.8393 11.3889 4.05619 10.1667C3.27264 8.94444 2.88086 7.82222 2.88086 6.8C2.88086 5.18889 3.40041 3.90267 4.43953 2.94133C5.47819 1.98044 6.68086 1.5 8.04753 1.5C9.41419 1.5 10.6171 1.98044 11.6562 2.94133C12.6949 3.90267 13.2142 5.18889 13.2142 6.8C13.2142 7.82222 12.8226 8.94444 12.0395 10.1667C11.256 11.3889 10.0753 12.7167 8.49753 14.15C8.43086 14.2056 8.35864 14.2444 8.28086 14.2667C8.20308 14.2889 8.1253 14.3 8.04753 14.3Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
    </g>
  </svg>
);
