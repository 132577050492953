import React, { useCallback } from 'react';
import type { FC } from 'react';
import type { MRT_Cell, MRT_Column, MRT_Row } from 'material-react-table';
import InputAdornment from '@mui/material/InputAdornment';
import { Input } from '@lama/design-system';
import type { DocumentLineItem } from '@lama/document-service-client';

interface NumericEditCellProps {
  cell: MRT_Cell<DocumentLineItem>;
  column: MRT_Column<DocumentLineItem>;
  row: MRT_Row<DocumentLineItem>;
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void;
  onCellBlur: (cell: MRT_Cell<DocumentLineItem>, value: string) => Promise<void>;
}

export const NumericEditCell: FC<NumericEditCellProps> = ({ cell, column, row, onCellBlur, onCellFocus }) => {
  const cellValue = cell.getValue<number>();

  const onBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      row._valuesCache[column.id] = event.target.value;
      await onCellBlur(cell, event.target.value);
    },
    [cell, column.id, onCellBlur, row._valuesCache],
  );

  const onFocus = useCallback(() => {
    onCellFocus(cell);
  }, [cell, onCellFocus]);

  return (
    <Input
      type={'number'}
      $size={'m'}
      startIcon={<InputAdornment position={'start'}>{'$'}</InputAdornment>}
      value={cellValue}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
