import React, { useContext, useMemo } from 'react';
import { Flex, Grid, Text, greyPalette } from '@lama/design-system';
import type { FC } from 'react';
import type { UseOfFundsBreakdown } from '@lama/contracts';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

const UseOfFundsBreakdownItem: FC<{
  breakdown: UseOfFundsBreakdown;
}> = ({ breakdown }) => {
  const { type, description, amount, id } = breakdown;

  const breakdownInfo: InfoLineProps[] = useMemo(
    () => [
      {
        label: 'Amount',
        values: amount ?? 0,
        type: 'currency' as const,
      },
    ],
    [amount],
  );

  return (
    <SegmentContainer title={type}>
      <Flex flexDirection={'column'} gap={4}>
        <Grid columns={3}>
          {breakdownInfo.map((line) => (
            <KeyValuePair key={`${id}_${line.label}`} name={line.label} value={line.values} type={line.type} />
          ))}
        </Grid>
        <KeyValuePair name={'Description'} value={description} />
      </Flex>
    </SegmentContainer>
  );
};

export const UseOfFundsBreakdownSegment: FC = () => {
  const {
    application: { useOfFundsBreakdown },
  } = useContext(ApplicationContext);

  if (!useOfFundsBreakdown?.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'h6'} color={greyPalette[500]}>
        {'Breakdown'}
      </Text>
      <Flex flexDirection={'column'} gap={12}>
        {useOfFundsBreakdown.map((breakdown) => (
          <UseOfFundsBreakdownItem key={breakdown.id} breakdown={breakdown} />
        ))}
      </Flex>
    </Flex>
  );
};
