import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import {
  applicationBorrowingBusinessSelector,
  legalOwners,
  personFullName,
  relatedBusinessesByRelationsSelector,
} from '@lama/data-formatters';
import type { CellContent } from '../../../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';

import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

const headers = [
  {
    text: 'Connected Relationship',
  },
  {
    text: 'Role',
  },
  {
    text: 'Ownership Percentage',
  },
];

interface OwnershipTableTableProps {
  title?: string;
}

export const GrasshopperLoanTermsOwnersTable: FC<OwnershipTableTableProps> = ({ title }) => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const business = applicationBorrowingBusinessSelector(application);
    if (!business) {
      return [];
    }

    const borrowers = relatedBusinessesByRelationsSelector(application, ['borrower']);
    const sellers = relatedBusinessesByRelationsSelector(application, ['seller']);
    const subsidieries = relatedBusinessesByRelationsSelector(application, ['subsidiary', 'sisterCompany']);
    const owners = legalOwners(application, business);
    const keyEmployees = business.people
      .filter((p) => !p.guarantor && !p.ownershipPercentage && !(p.position === 'None') && !(p.position === 'Unrelated'))
      .map((p) => ({
        name: personFullName(p),
        ownershipPercentage: p.ownershipPercentage ?? '',
        title: p.position ?? 'Key Employee',
      }));

    const borrowerRows = borrowers.map((b) => [{ value: b.business.legalName }, { value: 'Borrower' }, { value: '-' }]);
    const sellersRows = sellers.map((b) => [{ value: b.business.legalName }, { value: 'Operating Company' }, { value: '-' }]);
    const subsidieriesRows = subsidieries.map((b) => [{ value: b.business.legalName }, { value: 'Related' }, { value: '-' }]);
    const tablePeople = [...new Set([...owners, ...keyEmployees])];
    const peopleRows = tablePeople.map<CellContent[]>((p) => [
      {
        value: p.name,
      },
      {
        value: p.ownershipPercentage ? 'Principal' : 'Key Employee',
      },
      {
        value: p.ownershipPercentage ?? 0,
        type: 'percent',
      },
    ]);
    return [...borrowerRows, ...sellersRows, ...subsidieriesRows, ...peopleRows];
  }, [application]);

  return (
    <SegmentContainer title={title ?? 'Ownership and Management'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
