import type { FC } from 'react';
import React from 'react';

export const CheckCircleIcon: FC<{ color?: string; size?: string }> = ({ color = 'black', size = 24 }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <mask id={'mask0_47_1462'} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={size} height={size}>
      <rect width={size} height={size} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask0_47_1462)'}>
      <path
        d={
          'M10.575 14.15L8.225 11.8C8.09167 11.6667 7.921 11.6043 7.713 11.613C7.50433 11.621 7.33333 11.6917 7.2 11.825C7.05 11.975 6.975 12.15 6.975 12.35C6.975 12.55 7.05 12.725 7.2 12.875L9.95 15.625C10.1167 15.7917 10.325 15.875 10.575 15.875C10.825 15.875 11.0417 15.7917 11.225 15.625L16.8 10.025C16.9333 9.89167 16.996 9.72067 16.988 9.512C16.9793 9.304 16.9083 9.13333 16.775 9C16.625 8.85 16.45 8.775 16.25 8.775C16.05 8.775 15.875 8.85 15.725 9L10.575 14.15ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z'
        }
        fill={color}
      />
    </g>
  </svg>
);
