import React from 'react';
import type { FC } from 'react';
import type { ClosingTaskComponentProps } from './closingTaskComponentProps';
import { ExportClosingTask } from './ExportClosingTaskComponent';
import { LaserProExportClosingTask } from './LaserProExportTaskComponent';
import { DocusignClosingTask } from './DocusignClosingTask/DocusignClosingTaskComponent';
import { EtranPullTaskComponent } from './Etran/EtranPullTaskComponent';
import { NautilusExportTaskComponent } from './NautilusExport/NautilusExportTaskComponent';
import { LaserProPremiumExportClosingTask } from './LaserProPremiumExportTaskComponent';
import { MiddeskPullDocumentsTaskComponent } from './Middesk/MiddeskPullDocumentsTaskComponent';

export const closingTasksComponentsByType: Record<string, FC<ClosingTaskComponentProps>> = {
  general: () => null,
  export: (props) => <ExportClosingTask {...props} />,
  exportDocumentsToNautilus: (props) => <NautilusExportTaskComponent {...props} />,
  laserPro: (props) => <LaserProExportClosingTask {...props} />,
  laserProPremium: (props) => <LaserProPremiumExportClosingTask {...props} />,
  docusignV2: (props) => <DocusignClosingTask {...props} />,
  syncEtran: (props) => <EtranPullTaskComponent {...props} />,
  middesk: (props) => <MiddeskPullDocumentsTaskComponent {...props} />,
};
