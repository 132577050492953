/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useRef } from 'react';
import { Tooltip } from '@lama/app-components';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useToggle } from 'react-use';
import type { UnderwritingNote } from '@lama/contracts';
import { Flex, Spinner, Text } from '@lama/design-system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { toast } from 'react-toastify';
import { menuProps } from '../../../components/Application/ActionsMenu/ApplicationActionsMenu';
import { applicationServiceClient } from '../../clients/applicationServiceClient';

interface CommentActionsProps {
  note: UnderwritingNote;
  opportunityId: string;
  toggleEditMode: () => void;
  visible?: boolean;
  isCurrentUserSameAsCommentUser?: boolean;
}

export const addUrlParamWithBaseUrl = (url: string, paramName: string, value: string) => {
  const urlObj = new URL(url, window.location.origin);
  urlObj.searchParams.set(paramName, value);

  return urlObj.href;
};

export const CommentActions: FC<CommentActionsProps> = ({
  opportunityId,
  note,
  toggleEditMode,
  visible,
  isCurrentUserSameAsCommentUser,
}) => {
  const [open, toggleOpen] = useToggle(false);
  const menuRef = useRef(null);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { isPending: deletingNote, mutateAsync: deleteNote } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.deleteNote(opportunityId, note.id, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
  });

  const onClickCopyLink = useCallback(() => {
    const url = addUrlParamWithBaseUrl(window.location.href, 'commentId', note.id);
    void navigator.clipboard.writeText(url);
    toast.info('Link copied to clipboard');
    toggleOpen();
  }, [note.id, toggleOpen]);

  const onDeleteNote = useCallback(async () => {
    await deleteNote();
    toggleOpen();
  }, [deleteNote, toggleOpen]);

  const onEditNote = useCallback(() => {
    toggleEditMode();
    toggleOpen();
  }, [toggleEditMode, toggleOpen]);

  return (
    <>
      <Tooltip title={'Actions'}>
        <IconButton
          onClick={toggleOpen}
          sx={{
            borderRadius: '8px',
            '&:hover': { bgcolor: 'primary.light' },
            bgcolor: open ? 'primary.light' : 'transparent',
            visibility: `${visible || open ? 'visible' : 'hidden'}`,
          }}
          ref={menuRef}
          role={'button'}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        key={'comment-actions-menu'}
        anchorEl={menuRef.current}
        open={open}
        onClose={toggleOpen}
        {...menuProps}
        MenuListProps={{ sx: { py: 0.5, px: 0.5, minWidth: '150px' } }}
      >
        {isCurrentUserSameAsCommentUser
          ? [
              <MenuItem onClick={onEditNote} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }} key={`edit_${note.id}`}>
                <Text variant={'body1'}>{'Edit'}</Text>
              </MenuItem>,
              <MenuItem
                onClick={onDeleteNote}
                sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
                key={`delete_${note.id}`}
              >
                <Flex flexDirection={'row'} alignItems={'center'} gap={2} flex={1}>
                  {deletingNote ? <Spinner size={'s'} /> : null}
                  <Text variant={'body1'}>{'Delete'}</Text>
                </Flex>
              </MenuItem>,
            ]
          : null}
        <MenuItem onClick={onClickCopyLink} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
          <Text variant={'body1'}>{'Copy link'}</Text>
        </MenuItem>
      </Menu>
    </>
  );
};
