import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const IntegrationsIcon: FC<{ color: string }> = ({ color }) => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_13865_167934'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_13865_167934)'}>
        <path
          d={
            'M9.6 15.225L10.65 14.15L8.5 12L10.65 9.85L9.6 8.775L6.375 12L9.6 15.225ZM14.4 15.225L17.625 12L14.4 8.775L13.35 9.85L15.5 12L13.35 14.15L14.4 15.225ZM5.3 20.5C4.8 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.2 3.5 18.7V5.3C3.5 4.8 3.675 4.375 4.025 4.025C4.375 3.675 4.8 3.5 5.3 3.5H9.75C9.81667 2.95 10.0627 2.479 10.488 2.087C10.9127 1.69567 11.4167 1.5 12 1.5C12.6 1.5 13.1083 1.69567 13.525 2.087C13.9417 2.479 14.1833 2.95 14.25 3.5H18.7C19.2 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.8 20.5 5.3V18.7C20.5 19.2 20.325 19.625 19.975 19.975C19.625 20.325 19.2 20.5 18.7 20.5H5.3ZM5.3 19H18.7C18.7667 19 18.8333 18.9667 18.9 18.9C18.9667 18.8333 19 18.7667 19 18.7V5.3C19 5.23333 18.9667 5.16667 18.9 5.1C18.8333 5.03333 18.7667 5 18.7 5H5.3C5.23333 5 5.16667 5.03333 5.1 5.1C5.03333 5.16667 5 5.23333 5 5.3V18.7C5 18.7667 5.03333 18.8333 5.1 18.9C5.16667 18.9667 5.23333 19 5.3 19ZM12 4.35C12.2167 4.35 12.396 4.279 12.538 4.137C12.6793 3.99567 12.75 3.81667 12.75 3.6C12.75 3.38333 12.6793 3.204 12.538 3.062C12.396 2.92067 12.2167 2.85 12 2.85C11.7833 2.85 11.6043 2.92067 11.463 3.062C11.321 3.204 11.25 3.38333 11.25 3.6C11.25 3.81667 11.321 3.99567 11.463 4.137C11.6043 4.279 11.7833 4.35 12 4.35Z'
          }
          fill={color}
        />
      </g>
    </svg>
  </Box>
);
