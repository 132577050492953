import type { Collateral, CollateralType } from '@lama/contracts';
import { Typography } from '@mui/material';
import type { MRT_ColumnDef } from 'material-react-table';
import type { FC } from 'react';
import React from 'react';
import { formatValue } from '@lama/data-formatters';
import { SimpleTable } from '../../../../shared/components/SimpleTable';
import { CollateralTypeChip } from './CollateralTypeChip';

const columnsDefinition: MRT_ColumnDef<Collateral>[] = [
  {
    header: 'Description',
    accessorKey: 'description',
    Header: <Typography variant={'body1'}>{'Collateral'}</Typography>,
    Cell: ({ cell }) => (
      <Typography variant={'body2'} width={'260px'} maxWidth={'280px'}>
        {cell.getValue<string>()}
      </Typography>
    ),
  },
  {
    header: 'Type',
    accessorKey: 'type',
    Header: <Typography variant={'body1'}>{'Type'}</Typography>,
    Cell: ({ cell }) => <CollateralTypeChip type={cell.getValue<CollateralType>()} size={'small'} />,
  },
  {
    header: 'Total Value',
    accessorKey: 'totalValue',
    Header: <Typography variant={'body1'}>{'Total Value'}</Typography>,
    Cell: ({ cell }) => {
      const totalValue = cell.getValue<number | undefined>();
      return <Typography variant={'body2'}>{totalValue ? formatValue(totalValue, 'currency') : '-'}</Typography>;
    },
  },
  {
    header: 'Valuation Method',
    accessorKey: 'method',
    Header: <Typography variant={'body1'}>{'Valuation Method'}</Typography>,
    Cell: ({ cell }) => (
      <Typography variant={'body2'} color={'text.primary'}>
        {cell.getValue<string>()}
      </Typography>
    ),
  },
];

interface CollateralsTableProps {
  collateral: Collateral[];
}

export const CollateralsTable: FC<CollateralsTableProps> = ({ collateral }) => (
  <SimpleTable data={collateral} columns={columnsDefinition} />
);
