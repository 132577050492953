import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { documentServiceUrl } from '../../../../framework/environment';

interface DeleteFileParams {
  documentId: string;
}

interface DeleteDocumentQueryInvalidationParams {
  applicationId: string;
  opportunityId?: string;
}

export const useDeleteDocumentQuery = (
  params: DeleteDocumentQueryInvalidationParams,
  options?: UseMutationOptions<void, unknown, DeleteFileParams>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const deleteDocumentQuery = useCallback(
    async ({ documentId }: DeleteFileParams) => {
      const token = await getAccessTokenSilently();
      return createDocumentServiceClient(documentServiceUrl).deleteDocument(documentId, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: deleteDocumentQuery,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', params.applicationId] });
      await queryClient.invalidateQueries({ queryKey: ['spreadingDocuments', params.applicationId] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityRequirements', params.opportunityId].filter(Boolean) });
    },
    ...options,
  });
};
