import { useQueries } from '@tanstack/react-query';
import { createPartnerServiceClient } from '@lama/partner-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { compact } from 'lodash-es';
import axios from 'axios';
import { partnerServiceBaseUrl } from '../../../../framework/environment';

const partnerServiceClient = createPartnerServiceClient(partnerServiceBaseUrl);

export const useGetPartnersQuery = (partnerIds: string[]) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQueries({
    queries: partnerIds.map((partnerId) => ({
      queryKey: ['partner', partnerId],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        try {
          const partner = await partnerServiceClient.getPartner(partnerId, token);
          return partner;
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status === 403) {
            return null;
          }

          throw error;
        }
      },
    })),
    combine: (results) => ({
      pending: results.some((result) => result.isPending),
      data: compact(results.map((result) => result.data)),
    }),
  });
};
