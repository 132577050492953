import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Chip } from '@mui/material';
import type { ProductTemplateApiModel } from '@lama/contracts';
import { ProductCardBase } from '../ProductCardBase';

interface ProductCardProps {
  productTemplate: ProductTemplateApiModel;
  onClick: (templateId: string) => void;
  isLoading?: boolean;
}

const TemplateChip = <Chip variant={'outlined'} label={'Template'} sx={{ bgcolor: '#FFF8E1', color: '#FFA000', borderColor: '#FFA000' }} />;

export const ProductTemplateCard: FC<ProductCardProps> = ({ productTemplate, onClick, isLoading }) => {
  const onTemplateClick = useCallback(() => {
    onClick(productTemplate.id);
  }, [onClick, productTemplate.id]);

  return (
    <ProductCardBase cta={!isLoading ? 'Create' : 'Loading...'} product={productTemplate} chip={TemplateChip} onClick={onTemplateClick} />
  );
};
