import { useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { CreateBusinessModelApi, DataProviderCredentials } from '@lama/business-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { businessServiceClient } from '../../../shared/clients/businessServiceClient';

export const useCreateBusinessMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({
      business,
      dataProvidersCredentials,
      applicationId,
    }: {
      business: CreateBusinessModelApi;
      dataProvidersCredentials?: DataProviderCredentials[];
      applicationId: string;
    }) => {
      const token = await getAccessTokenSilently();
      await businessServiceClient.createBusiness({ business, applicationId, dataProvidersCredentials, token });
    },
    onError: () => {
      displayToast('Something went wrong while creating the business. Please contact support.', 'error');
    },
  });
};
