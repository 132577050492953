import type { ReactNode, FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import { MaterialRoundedIcon } from '@lama/app-components';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { ApplicationStatusChip } from '../../../shared/components/ApplicationStatusChip';
import { BusinessIcon } from '../../../shared/components/Icons/BusinessIcon';
import { ApplicationNumber } from '../../../shared/components/ApplicationNumber';
import type { SearchResult } from './types';

interface BusinessInfoProps {
  icon: ReactNode | string;
  text: string;
}
const BusinessInfo: FC<BusinessInfoProps> = ({ icon, text }) => {
  const theme = useTheme();

  return (
    <Flex alignItems={'center'}>
      {icon && typeof icon === 'string' ? <MaterialRoundedIcon name={icon} size={'16px'} color={theme.text.colors.secondary} /> : icon}
      <Text variant={'body4'} color={'secondary'} ellipsis>
        {text}
      </Text>
    </Flex>
  );
};

interface ApplicationSearchResultProps {
  searchResult: SearchResult;
  onItemClick: () => void;
}

export const ApplicationSearchResult: FC<ApplicationSearchResultProps> = ({ searchResult, onItemClick }) => {
  const { applicationNumber, primaryContactFullName, applicationStatus, loanDisplayName, opportunityId } = searchResult;

  return (
    <Link to={`/pipeline/${opportunityId}`} style={{ color: 'inherit', textDecoration: 'none', display: 'flex', flex: 1 }}>
      <Flex justifyContent={'space-between'} flex={1} onClick={onItemClick} alignItems={'center'} gap={2}>
        <Flex gap={2} alignItems={'center'} alignContent={'center'} maxWidth={'380px'}>
          <BusinessIcon />
          <Flex minWidth={0}>
            <Text variant={'body2'} ellipsis>
              {loanDisplayName ?? '-'}
            </Text>
          </Flex>
          {primaryContactFullName ? <BusinessInfo icon={'person'} text={primaryContactFullName} /> : null}
          {applicationNumber ? <ApplicationNumber numberLabel={applicationNumber} size={'small'} /> : null}
        </Flex>
        <ApplicationStatusChip status={applicationStatus} size={'small'} />
      </Flex>
    </Link>
  );
};
