import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const ExchangeIcon: FC<{ color: string }> = ({ color }) => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_13865_167931'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_13865_167931)'}>
        <path
          d={
            'M12.05 19.75C9.88334 19.75 8.04167 18.996 6.52501 17.488C5.00834 15.9793 4.25001 14.15 4.25001 12V11.225L2.40001 13.075L1.35001 12.025L5.00001 8.35L8.65001 12.025L7.60001 13.075L5.75001 11.225V12C5.75001 13.7333 6.36267 15.2083 7.58801 16.425C8.81267 17.6417 10.3 18.25 12.05 18.25C12.4667 18.25 12.8793 18.2043 13.288 18.113C13.696 18.021 14.1 17.8833 14.5 17.7L15.625 18.825C15.0583 19.125 14.475 19.354 13.875 19.512C13.275 19.6707 12.6667 19.75 12.05 19.75ZM19 15.65L15.35 11.975L16.4 10.925L18.25 12.775V12C18.25 10.2667 17.6377 8.79167 16.413 7.575C15.1877 6.35833 13.7 5.75 11.95 5.75C11.5333 5.75 11.121 5.79567 10.713 5.887C10.3043 5.979 9.90001 6.11667 9.50001 6.3L8.37501 5.175C8.94167 4.875 9.52501 4.64567 10.125 4.487C10.725 4.329 11.3333 4.25 11.95 4.25C14.1167 4.25 15.9583 5.004 17.475 6.512C18.9917 8.02067 19.75 9.85 19.75 12V12.775L21.6 10.925L22.65 11.975L19 15.65Z'
          }
          fill={color}
        />
      </g>
    </svg>
  </Box>
);
