import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { PropertiesRecord } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityPropertiesQuery = (opportunityId?: string, options?: UseQueryOptions<PropertiesRecord | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityPropertiesQuery = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const { properties } = await applicationServiceClient.getOpportunityProperties(opportunityId, token);

    return properties;
  }, [getAccessTokenSilently, opportunityId]);

  return useQuery<PropertiesRecord | null>({
    queryKey: ['opportunityProperties', opportunityId],
    queryFn: getOpportunityPropertiesQuery,
    ...options,
  });
};
