import type { FC } from 'react';
import React from 'react';
import { useTheme } from '@mui/material';
import { Flex } from '@lama/design-system';

interface BusinessDefaultImageProps {
  size: number;
  color?: string;
}

export const BusinessDefaultImage: FC<BusinessDefaultImageProps> = ({ size, color }) => {
  const theme = useTheme();

  return (
    <Flex width={`${size}px`} height={`${size}px`} minWidth={`${size}px`} minHeight={`${size}px`}>
      <svg width={size} height={size} viewBox={'0 0 64 64'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <rect x={'0.5'} y={'0.5'} width={'63'} height={'63'} rx={'9.5'} fill={'#FFF'} />
        <mask
          id={'mask0_9784_22238'}
          style={{ maskType: 'alpha' }}
          maskUnits={'userSpaceOnUse'}
          x={'20'}
          y={'20'}
          width={'24'}
          height={'24'}
        >
          <rect x={'20'} y={'20'} width={'24'} height={'24'} fill={'#D9D9D9'} />
        </mask>
        <g mask={'url(#mask0_9784_22238)'}>
          <path
            d={
              'M24.9502 25.75C24.7502 25.75 24.5752 25.679 24.4252 25.537C24.2752 25.3957 24.2002 25.2167 24.2002 25C24.2002 24.7833 24.2752 24.604 24.4252 24.462C24.5752 24.3207 24.7502 24.25 24.9502 24.25H39.0502C39.2502 24.25 39.4252 24.3207 39.5752 24.462C39.7252 24.604 39.8002 24.7833 39.8002 25C39.8002 25.2167 39.7252 25.3957 39.5752 25.537C39.4252 25.679 39.2502 25.75 39.0502 25.75H24.9502ZM25.1502 39.75C24.9002 39.75 24.6879 39.6627 24.5132 39.488C24.3379 39.3127 24.2502 39.1 24.2502 38.85V33.75H23.9502C23.6669 33.75 23.4295 33.6373 23.2382 33.412C23.0462 33.1873 22.9835 32.9333 23.0502 32.65L24.0502 27.975C24.1002 27.7583 24.2085 27.5833 24.3752 27.45C24.5419 27.3167 24.7335 27.25 24.9502 27.25H39.0502C39.2669 27.25 39.4585 27.3167 39.6252 27.45C39.7919 27.5833 39.9002 27.7583 39.9502 27.975L40.9502 32.65C41.0169 32.9333 40.9545 33.1873 40.7632 33.412C40.5712 33.6373 40.3335 33.75 40.0502 33.75H39.7502V39C39.7502 39.2167 39.6795 39.396 39.5382 39.538C39.3962 39.6793 39.2169 39.75 39.0002 39.75C38.7835 39.75 38.6042 39.6793 38.4622 39.538C38.3209 39.396 38.2502 39.2167 38.2502 39V33.75H33.7502V38.85C33.7502 39.1 33.6629 39.3127 33.4882 39.488C33.3129 39.6627 33.1002 39.75 32.8502 39.75H25.1502ZM25.7502 38.25H32.2502V33.75H25.7502V38.25ZM24.6752 32.25H39.3252L38.5752 28.75H25.4252L24.6752 32.25Z'
            }
            fill={color ?? theme.palette.primary.main}
          />
        </g>
        <rect x={'0.5'} y={'0.5'} width={'63'} height={'63'} rx={'9.5'} stroke={'#E0E0E0'} />
      </svg>
    </Flex>
  );
};
