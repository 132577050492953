import React from 'react';
import type { SpreadRow } from '@lama/spreads-generator-client';
import type { Period } from '@lama/selectors';
import { Text } from '@lama/design-system';
import { formatSpreadCellValue } from '../../utils/formatSpreadCellValue';

export const PrintSpreadRow = ({ spreadRow, displayedPeriods }: { spreadRow: SpreadRow; displayedPeriods: Period[] }) => {
  const indentLevel = spreadRow.attributeHierarchy.length > 1 ? spreadRow.attributeHierarchy.length - 1 : 0;
  const highlighted = spreadRow.propertyConfig.highlight;
  const rowKey = `${spreadRow.attributeDisplayName}-${spreadRow.entityId}`;

  const paddingLeft = `${8 + (indentLevel > 0 ? indentLevel * 16 : 0)}px`;

  return (
    <tr key={rowKey} className={`table-row ${highlighted ? 'highlight' : ''}`}>
      <td style={{ paddingLeft }}>{spreadRow.attributeDisplayName}</td>
      {displayedPeriods.map((period) => {
        const periodKey = `${period.startDate}-${period.endDate}`;
        const financialAttribute = spreadRow.valueByPeriod[periodKey]?.financialAttribute;

        return (
          <td key={periodKey}>
            <Text variant={'body3'}>
              {financialAttribute !== null ? formatSpreadCellValue(financialAttribute?.value, spreadRow.property.type) : '-'}
            </Text>
          </td>
        );
      })}
    </tr>
  );
};

export const PrintSpreadTableBody = ({ spreadRows, displayedPeriods }: { spreadRows: SpreadRow[]; displayedPeriods: Period[] }) => (
  <tbody>
    {spreadRows.map((spreadRow) => (
      <PrintSpreadRow
        key={`${spreadRow.attributeDisplayName}-${spreadRow.entityId}`}
        spreadRow={spreadRow}
        displayedPeriods={displayedPeriods}
      />
    ))}
  </tbody>
);
