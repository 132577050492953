import React, { useCallback, useContext } from 'react';
import { ArbitraryDocumentUploadButton } from '@lama/app-components';
import type { FC } from 'react';
import type { Entity } from '@lama/common-types';
import type { OpportunityRequirement } from '@lama/contracts';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { useUploadDocumentsMutation } from './hooks/useUploadDocumentsQuery';

interface UploadArbitraryDocumentButtonProps {
  applicationId: string;
  entityId: string;
  entityType: Entity;
  requirementId?: string;
  sharedRequirementId?: string;
  requirementKey?: string;
  exportConfiguration?: OpportunityRequirement['exportConfiguration'];
  extractable?: boolean;
  relatedItemId?: string;
  relatedItemType?: string;
}

export const UploadArbitraryDocumentButton: FC<UploadArbitraryDocumentButtonProps> = ({
  applicationId,
  entityId,
  entityType,
  requirementId,
  sharedRequirementId,
  requirementKey,
  exportConfiguration,
  extractable,
  relatedItemId,
  relatedItemType,
}) => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { mutate: uploadDocuments, isPending: isUploading } = useUploadDocumentsMutation({ applicationId, opportunityId });

  const uploadDocumentsCallback = useCallback(
    async (files: FileList) => {
      uploadDocuments(
        [...files].map((file) => ({
          applicationId,
          description: '',
          entityId,
          entityType,
          file,
          topic: null,
          requirementId,
          sharedRequirementId,
          requirementKey,
          exportConfiguration,
          extractable,
          relatedItemId,
          relatedItemType,
        })),
      );
    },
    [
      uploadDocuments,
      applicationId,
      entityId,
      entityType,
      requirementId,
      sharedRequirementId,
      requirementKey,
      exportConfiguration,
      extractable,
      relatedItemId,
      relatedItemType,
    ],
  );

  return <ArbitraryDocumentUploadButton onFilesSelected={uploadDocumentsCallback} uploading={isUploading} textColor={'text.secondary'} />;
};
