import { createDocumentServiceClient } from '@lama/document-service-client';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { documentServiceUrl } from '../../../../../framework/environment';
import { uploadToS3 } from '../../../../../shared/clients/s3';

const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

export const useUploadImage = () => {
  const { getAccessTokenSilently } = useAuth0();

  const uploadImageMutation = useCallback(
    async ({ opportunityId, filename, fileContents }: { opportunityId: string; filename: string; fileContents: File }) => {
      const token = await getAccessTokenSilently();

      const { signedUploadUrl, publicDownloadUrl } = await documentServiceClient.getSignedUploadUrlForInlineImage(
        opportunityId,
        filename,
        token,
      );
      await uploadToS3(signedUploadUrl, fileContents);
      return publicDownloadUrl;
    },
    [getAccessTokenSilently],
  );
  return useMutation({
    mutationFn: uploadImageMutation,
  });
};
