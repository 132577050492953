import { useAuth0 } from '@auth0/auth0-react';
import type { UpdateBusinessRequest } from '@lama/business-service-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { businessServiceClient } from '../../../clients/businessServiceClient';

export const useUpdateBusiness = (opportunityId?: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ businessId, updateBusinessPayload }: { businessId: string; updateBusinessPayload: UpdateBusinessRequest }) => {
      const token = await getAccessTokenSilently();

      await businessServiceClient.updateBusiness({ businessId, business: updateBusinessPayload, token });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityEvaluation', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityAlerts', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
