import { useCallback } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { DocusignTemplateDocument } from '@lama/docusign-client';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useTemplateDocusignDocumentsQuery = (
  opportunityId: string,
  templateId: string | undefined,
  options?: UseQueryOptions<DocusignTemplateDocument[] | null>,
): UseQueryResult<DocusignTemplateDocument[] | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getTemplateDocusignDocumentsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!templateId) {
      return null;
    }

    return docusignServiceClient.getTemplateDocuments({ opportunityId, templateId }, token);
  }, [getAccessTokenSilently, opportunityId, templateId]);

  const query = useQuery({
    queryKey: ['templateDocusignDocuments', opportunityId, templateId],
    queryFn: getTemplateDocusignDocumentsQuery,
    ...options,
  });

  return query;
};
