import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { audience, clientId, domain, tokenCacheLocation } from './framework/environment';

interface Props {
  children: React.ReactNode;
}

export const Auth0ProviderWithHistory: FC<Props> = ({ children }) => {
  const onRedirectCallback = useCallback((appState: AppState | undefined) => {
    window.location.href = appState?.returnTo ?? window.location.pathname;
  }, []);

  if (!(domain && clientId)) {
    console.error("missing auth0 domain and clientId, can't initialize auth0 provider");
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      audience={audience}
      clientId={clientId}
      redirectUri={window.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={tokenCacheLocation}
    >
      {children}
    </Auth0Provider>
  );
};
