import React, { useMemo, useCallback, useState, useEffect } from 'react';
import type { FC } from 'react';
import { Stack, Box } from '@mui/material';
import type { AccountData } from '@lama/contracts';
import { ChartPagination } from '../../../../../shared/components/ChartPagination';
import type { Granularity } from '../../types';
import { EmptyData } from './EmptyData';
import { getFirstAndLastDates, getFirstAndLastPaddedDates, getPageCountByGranularity } from './helpers';
import { BalanceChart } from './Balance/BalancesChart';
import { getGranulatedBalancesData } from './Balance/helpers';

export const Balances: FC<{ balances: AccountData['balances']; granularity: Granularity }> = ({ balances, granularity }) => {
  const [page, setPage] = useState(1);

  const { first: firstBalanceDate, last: lastBalanceDate } = useMemo(() => {
    const { first, last } = getFirstAndLastDates(balances);
    const { firstPadded, lastPadded } = getFirstAndLastPaddedDates(first, last, granularity);

    return { first: firstPadded, last: lastPadded };
  }, [granularity, balances]);

  const pageCount = useMemo(
    () => getPageCountByGranularity(granularity, firstBalanceDate, lastBalanceDate),
    [firstBalanceDate, granularity, lastBalanceDate],
  );

  const data = useMemo(() => {
    const datum = getGranulatedBalancesData(balances, lastBalanceDate, granularity, page);

    return datum.length ? [{ id: 'balance', data: datum }] : null;
  }, [balances, lastBalanceDate, granularity, page]);

  const granularityFormat = useMemo(
    () => (granularity === 'week' ? 'MM/dd/yy' : granularity === 'month' ? 'MMM yy' : 'yyyy'),
    [granularity],
  );

  const onPageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [setPage, granularity]);

  return (
    <Stack spacing={3} alignItems={'center'}>
      <Box height={'350px'} width={'90%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {data ? <BalanceChart data={data} granularityFormat={granularityFormat} /> : <EmptyData />}
      </Box>
      <ChartPagination pageCount={pageCount} page={page} onPageChange={onPageChange} />
    </Stack>
  );
};
