import { Grid } from '@lama/design-system';
import type { AutocompleteRenderInputParams } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

const renderAutocompleteInput = (label: string) => (params: AutocompleteRenderInputParams) =>
  <TextField {...params} label={label} variant={'outlined'} />;

export const FibtRiskLevel: FC = () => (
  <Grid columns={2}>
    <Autocomplete
      renderInput={renderAutocompleteInput('MSB/Consumer Debits/3rd Party Sender ')}
      options={['Yes', 'No']}
      defaultValue={'No'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('CCD Debit Window')}
      options={['5-Day Window / N/A', '4-Day Window', '3-Day Window']}
      defaultValue={'4-Day Window'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('Approved Batch Limits')}
      options={['$1 - $50,000', '$50,001 - $100,000', '$100,001 - $250,000', '$250,001 - $350,000', 'Over $350,001']}
      defaultValue={'$1 - $50,000'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('Consumer (PPD) Debits, WEB or TEL')}
      options={['No', 'Yes & 5-Day Window', 'Yes & 4-Day Window', 'Yes & 3-Day Window']}
      defaultValue={'Yes & 5-Day Window'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('Payroll Window Approved For')}
      options={['Wire, 5-Day or P4D', 'All Other Windows']}
      defaultValue={'All Other Windows'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('Payroll approved For')}
      options={['Clients Only / N/A', 'Clients & Own', 'Own Only']}
      defaultValue={'Clients Only / N/A'}
    />
    <Autocomplete
      renderInput={renderAutocompleteInput('History With Kotapay')}
      options={['Active 2+ Years', 'Active 1-2 Years', 'New Customer']}
      defaultValue={'New Customer'}
    />
  </Grid>
);
