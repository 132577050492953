import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { businessName, relatedBusinessesByRelationSelector } from '@lama/data-formatters';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel } from '@lama/clients';
import { FormikPicker } from '@lama/app-components';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { SaveFormButton } from '../../../shared/SaveFormButton';

export const HmdaPropertySelection: FC<{ application: ApplicationApiModel }> = ({ application }) => {
  const properties = useMemo(() => relatedBusinessesByRelationSelector(application, 'property'), [application]);
  const { mutate: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(
    application.id,
    application.leadingOpportunityId,
  );

  const propertiesOptions = useMemo(
    () => properties.map(({ business }, index) => ({ label: businessName(business) ?? `${index} (missing address)`, value: business.id })),
    [properties],
  );

  const validationSchema = yup.object().shape({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'hmda.reportedPropertyId': yup.string(),
  });

  const initialValues = {
    hmda: { reportedPropertyId: application.hmda?.reportedPropertyId ?? '' },
  };

  const onSubmit = useCallback(
    (newValues: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values: newValues });

      updateApplication({
        updateApplicationPayload: {
          hmda: {
            reportedPropertyId: newValues.hmda.reportedPropertyId,
          },
        },
      });
    },
    [updateApplication],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, dirty }) => (
        <Flex flexDirection={'column'} flex={1} height={'100%'} width={'100%'} alignItems={'center'} gap={4}>
          <FormikPicker name={'hmda.reportedPropertyId'} label={'Reported Property'} values={propertiesOptions} fullWidth />
          <SaveFormButton disabled={!dirty} loading={updatingApplication} submit={handleSubmit} />
        </Flex>
      )}
    </Formik>
  );
};
