import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { InlineNote } from '../../../../shared/InlineNote/InlineNote';

export const FirstBankDemoLoanSummary = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'Cost Center'} value={'xx'} />
        <KeyValuePair name={'Business Segment'} value={'Commercial Banking'} />
        <KeyValuePair name={'Relationship Manager'} value={'Brad Mauzy'} />
        <KeyValuePair name={'Sales Management Oppty ID'} />
        <KeyValuePair name={'Date'} value={'7/22/24'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer title={'Requested Summary'}>
      <Grid columns={3}>
        <KeyValuePair name={'Total Current Request'} value={'$5,500,000'} />
        <KeyValuePair name={'Total Aggregate Commercial Exposure'} value={'$5,500,000'} />
        <KeyValuePair name={'Existing Deposits'} value={'N/A'} />
        <KeyValuePair name={'Incremental Deposit Opportunity'} value={'Sweep'} />
        <KeyValuePair name={'Loan ROE'} value={'23.28%'} />
        <KeyValuePair name={'Relationship ROE'} value={'26.53%'} />
        <KeyValuePair name={'Preliminary Risk Rating'} value={'7'} />
        <KeyValuePair name={'Last Annual Review Date'} value={'n/a'} />
      </Grid>
    </SegmentContainer>
    <SegmentContainer title={'General Information About Borrower'}>
      <Grid columns={3}>
        <KeyValuePair name={'Borrower Name'} value={'Monarch Metals Inc.'} />
        <KeyValuePair name={'DBA Name'} value={'Monarch Metals'} />
        <KeyValuePair name={'Legal Entity Type'} value={'S Corp'} />
        <KeyValuePair name={'NAICS Code'} value={'423510'} />
        <KeyValuePair name={'In Business Since'} value={'1992'} />
        <KeyValuePair name={'Relationship Since'} value={'NA'} />
        <KeyValuePair name={'Method of Application'} />
        <KeyValuePair name={'If Broker Originated, identify Broker'} value={'NA'} />
      </Grid>
    </SegmentContainer>
    <InlineNote title={'Customer Background'} noteName={'Customer Background'} />
    <InlineNote
      title={'Transaction Rationale & Precision Lender Justification'}
      noteName={'Transaction Rationale & Precision Lender Justification'}
    />
  </Flex>
);
