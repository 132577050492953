import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { SizeStandardSegment } from '../../../shared/Eligibilities/SizeStandardSegment';
import { CreditElsewhereSegment } from '../../../shared/Eligibilities/CreditElsewhereSegment';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { SegmentContainer } from '../../../shared/SegmentContainer';

export const HanoverSbaEligibilitySection: FC = () => (
  <Flex flexDirection={'column'} gap={8}>
    <InlineNote title={'Eligibility'} noteName={'Eligibility'} />
    <SegmentContainer title={'Tax Verification'}>
      <CreditMemoTable
        headers={[{ text: 'Description' }, { text: '2019' }, { text: '2020' }, { text: '2021' }, { text: '2022' }]}
        rows={[]}
      />
    </SegmentContainer>
    <CreditMemoTable
      headers={[{ text: 'Guarantor Type' }, { text: 'Guarantor Name' }, { text: 'Citizen Status' }, { text: 'Details' }]}
      rows={[]}
    />
    <SegmentContainer title={'Case Law Search'}>
      <CreditMemoTable headers={[{ text: 'Business/Individual Name' }, { text: 'Results ' }]} rows={[]} />
    </SegmentContainer>
    <InlineNote title={'Case Law Findings Summary'} noteName={'Case Law Findings Summary'} />
    <InlineNote title={'Why was the debt(s) incurred?'} noteName={'Why was the debt(s) incurred?'} />
    <InlineNote title={'Is the existing debt(s) on unreasonable terms?'} noteName={'Is the existing debt(s) on unreasonable terms?'} />
    <InlineNote
      title={'How will the new loan improve the financial conditions of the Applicant?'}
      noteName={'How will the new loan improve the financial conditions of the Applicant?'}
    />
    <CreditElsewhereSegment />
    <SizeStandardSegment />
  </Flex>
);
