import React, { useCallback, type FC } from 'react';
import * as yup from 'yup';
import { Button, Flex, Modal, ModalContent } from '@lama/design-system';
import { Formik } from 'formik';
import { PropertyFormikInput } from '@lama/app-components';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';

const validationSchema = yup.object({
  reason: yup.string().trim().required('Please provide a reason'),
});

export const GrantCompleteApplicationExtensionModal: FC<{ opportunityId: string; onClose: () => void }> = ({ opportunityId, onClose }) => {
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const grantExtension = useCallback(
    (reason: string) => {
      updateOpportunity({
        completeApplicationExtension: { days: 30, grantedAt: new Date().toISOString(), reason },
      });
    },
    [updateOpportunity],
  );

  const onSubmit = useCallback(
    (formValues: { reason: string }) => {
      grantExtension(formValues.reason.trim());
      onClose();
    },
    [grantExtension, onClose],
  );

  return (
    <Modal open size={'m'} title={'Extend Time to Submission'} alignTitle={'center'} fullWidth>
      <ModalContent>
        <Formik onSubmit={onSubmit} initialValues={{ reason: '' }} validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
              <Flex flexDirection={'column'} gap={4}>
                <PropertyFormikInput name={'reason'} label={'Extension Reason'} fullWidth required />
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Button size={'l'} variant={'primary'} onClick={handleSubmit as any}>
                  {'Extend'}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
