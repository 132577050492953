import type { FC } from 'react';
import React, { useContext, useMemo, useCallback, useState, useRef } from 'react';
import { Command } from 'cmdk';
import { Flex, Input, Text } from '@lama/design-system';
import { compact, intersection, uniq } from 'lodash-es';
import { LoadingPage, EmptySearchState } from '@lama/app-components';
import { useDebouncedCallback } from 'use-debounce';
import { useGetPaginatedPipelineQuery } from '../../shared/hooks/react-query/pipeline/useGetPaginatedPipelineQuery';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { SearchIcon } from '../../shared/components/Icons';
import { ApplicationContext } from '../Application/ApplicationContext';
import { CommentSearchResultContainer } from './CommentSearchResult';
import { pipelineItemToSearchResult } from './searchResultTransformers';
import type { SearchResult } from './types';
import { useGetSimilarCommentsQuery } from './queries/useGetSimilarCommentsQuery';

import './commentsSearch.css';

export const CommentSearch: FC<{ commentType: string; onGenerate: (comment: string) => Promise<void> }> = ({ commentType, onGenerate }) => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { partner: userPartner, partnerPermissions } = useContext(UserDetailsContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const userPartners = useMemo(() => {
    const res = uniq([
      ...(userPartner ? [userPartner] : []),
      ...(partnerPermissions
        ? Object.entries(partnerPermissions)
            .filter(([, value]) => intersection(value, ['read', 'write']).length > 0)
            .map(([key]) => key)
        : []),
    ]);

    return res;
  }, [userPartner, partnerPermissions]);

  const { data: pipelineSearchResult, isPending: loadingPipelineItems } = useGetPaginatedPipelineQuery({
    partnerIds: userPartners,
    limit: 15,
    search: searchTerm,
  });

  const { data: similarComments, isPending: loadingSimilarComments } = useGetSimilarCommentsQuery({
    opportunityId,
    commentType,
  });

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setSearchTerm('');
    }, 400);
  }, []);

  const onItemSelect = useCallback(
    async (comment: string) => {
      // save value to note
      handleClose();
      await onGenerate(comment);
    },
    [handleClose, onGenerate],
  );

  const searchResults: SearchResult[] = useMemo(() => {
    if (!pipelineSearchResult || !similarComments) {
      return [];
    }

    const pipelineItems = pipelineSearchResult.pipeline.map((item) => pipelineItemToSearchResult(item));

    const results = pipelineItems.map((item) => {
      const similarComment = similarComments?.opportunitiesComments[item.opportunityId];

      if (similarComment) {
        return {
          ...item,
          comment: similarComment,
        };
      }
      return null;
    });

    return compact(results);
  }, [pipelineSearchResult, similarComments]);

  const { showSearchResult, showEmptyState } = useMemo(
    () => ({
      showSearchResult: !loadingPipelineItems && !loadingSimilarComments && searchTerm && searchResults.length,
      showRecentApplications: !loadingPipelineItems && !loadingSimilarComments && !searchTerm,
      showEmptyState: !loadingPipelineItems && !loadingSimilarComments && ((searchTerm && !searchResults.length) || !searchTerm),
    }),
    [loadingPipelineItems, loadingSimilarComments, searchTerm, searchResults.length],
  );

  const debouncedSearch = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, 350);

  return (
    <Flex className={'commentsSearch'} flex={1} flexDirection={'column'}>
      <Command shouldFilter={false}>
        <Flex mb={3}>
          <Input
            ref={inputRef}
            $size={'m'}
            startIcon={<SearchIcon />}
            placeholder={`Search Note (${commentType})`}
            onChange={debouncedSearch}
            autoFocus
            fullWidth
          />
        </Flex>
        <Command.List>
          {loadingPipelineItems || loadingSimilarComments ? (
            <Command.Loading>
              <LoadingPage spinnerSize={'l'} />
            </Command.Loading>
          ) : null}
          {showSearchResult ? (
            <Command.Group>
              {searchResults.map((item) => (
                <CommentSearchResultContainer
                  key={item.opportunityId}
                  searchResult={item}
                  onItemClick={onItemSelect}
                  commentType={commentType}
                />
              ))}
            </Command.Group>
          ) : null}
          {showEmptyState ? (
            <Command.Empty>
              <EmptySearchState searchTerm={searchTerm} initialText={'Start typing to find notes from applications'} />
            </Command.Empty>
          ) : null}
        </Command.List>
        {showSearchResult ? (
          <Flex px={2} pt={3}>
            <Text variant={'body3'} color={'secondary'}>
              {`Showing ${searchResults.length} matching Comment out of ${pipelineSearchResult?.total} Applications`}
            </Text>
          </Flex>
        ) : null}
      </Command>
    </Flex>
  );
};
