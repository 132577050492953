/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Typography } from '@mui/material';
import type { MRT_ColumnDef } from 'material-react-table';
import { formatValue } from '@lama/data-formatters';
import type { BusinessMetricsTableDatum } from './types';

export const columnsDefinition: MRT_ColumnDef<BusinessMetricsTableDatum>[] = [
  {
    id: 'id',
    header: '',
    accessorKey: 'id',
    size: 150,
    Header: () => (
      <Typography variant={'body1'} paddingLeft={3}>
        {'Business Metrics'}
      </Typography>
    ),
    Cell: ({ cell }) => (
      <Typography variant={'body2'} paddingLeft={3}>
        {cell.getValue<string>() ?? '-'}
      </Typography>
    ),
  },
  {
    id: 'twoYearsPriorFiscal',
    header: 'FY21',
    accessorKey: 'twoYearsPriorFiscal',
    size: 100,
    Header: () => <Typography variant={'body1'}>{'FY21'}</Typography>,
    Cell: ({ cell }) => <Typography variant={'body2'}>{formatValue(cell.getValue<number>(), 'currency')}</Typography>,
  },
  {
    id: 'priorFiscalYear',
    header: 'FY22',
    accessorKey: 'priorFiscalYear',
    size: 100,
    Header: () => <Typography variant={'body1'}>{'FY22'}</Typography>,
    Cell: ({ cell }) => <Typography variant={'body2'}>{formatValue(cell.getValue<number>(), 'currency')}</Typography>,
  },
  {
    id: 'ytd',
    header: 'YTD',
    accessorKey: 'ytd',
    size: 100,
    Header: () => <Typography variant={'body1'}>{'YTD'}</Typography>,
    Cell: ({ cell }) => <Typography variant={'body2'}>{formatValue(cell.getValue<number>(), 'currency')}</Typography>,
  },
];
