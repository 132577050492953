import React, { useCallback, useContext, type FC } from 'react';
import * as yup from 'yup';
import { Button, Flex, Modal, ModalContent } from '@lama/design-system';
import { Formik } from 'formik';
import { PropertyFormikInput } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import type { OpportunityApiModel } from '@lama/clients';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';

const validationSchema = yup.object({
  reason: yup.string().trim().required('Please provide a reason'),
});

interface ClearFraudulentActivityModalProps {
  opportunity: OpportunityApiModel;
  personId?: string;
  onClose: () => void;
}

export const ClearFraudulentActivityModal: FC<ClearFraudulentActivityModalProps> = ({ opportunity, onClose, personId }) => {
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { userId } = useContext(UserDetailsContext);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async ({ reason }: { reason: string }) => {
      if (!personId) {
        return;
      }

      await updateOpportunity({
        creditReportFraudulentActivityClearance: [
          ...(opportunity.creditReportFraudulentActivityClearance ?? []),
          {
            personId,
            clearedAt: new Date().toISOString(),
            clearedBy: userId!,
            reason,
          },
        ],
      });

      onClose();
    },
    [onClose, opportunity.creditReportFraudulentActivityClearance, personId, updateOpportunity, userId],
  );

  return (
    <Modal open size={'m'} title={t('clearCreditReportFraudulentActivity.title')} alignTitle={'center'} fullWidth>
      <ModalContent>
        <Formik onSubmit={onSubmit} initialValues={{ reason: '' }} validationSchema={validationSchema}>
          {({ handleSubmit }) => (
            <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
              <Flex flexDirection={'column'} gap={4}>
                <PropertyFormikInput name={'reason'} label={'Clearance Reason'} fullWidth required />
              </Flex>
              <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                <Button size={'l'} variant={'tertiary'} onClick={onClose as any}>
                  {t('clearCreditReportFraudulentActivity.cancel')}
                </Button>
                <Button size={'l'} variant={'primary'} onClick={handleSubmit}>
                  {t('clearCreditReportFraudulentActivity.cta')}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
