import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import { EmptySearchImage } from './EmptySearchImage';

interface EmptySearchStateProps {
  searchTerm: string;
  initialText?: string;
}

export const EmptySearchState: FC<EmptySearchStateProps> = ({ searchTerm, initialText }) => (
  <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} flex={1} gap={4}>
    <EmptySearchImage />
    <Text variant={'body3'} color={'secondary'}>
      {searchTerm ? `No results for "${searchTerm}"` : initialText}
    </Text>
  </Flex>
);
