import React from 'react';
import type { FC } from 'react';
import type { TextVariant } from '@lama/design-system';
import { Flex, Text } from '@lama/design-system';
import { useTheme } from 'styled-components';
import { NumberIcon } from '../icons/NumberIcon';

const fontSize: Record<string, TextVariant> = {
  small: 'body4',
  medium: 'body3',
};

const iconSize = {
  small: '16px' as const,
  medium: '18px' as const,
};

interface Props {
  numberLabel?: string | null;
  size?: 'medium' | 'small';
}

export const ApplicationNumber: FC<Props> = ({ numberLabel, size = 'medium' }) => {
  const theme = useTheme();

  return (
    <Flex alignItems={'center'} gap={1}>
      <NumberIcon size={iconSize[size]} color={theme.text.colors.secondary} />
      <Text variant={fontSize[size]} color={'secondary'}>
        {numberLabel ?? '----'}
      </Text>
    </Flex>
  );
};
