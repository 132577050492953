import { LoadingPage } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { ApplicationApiModel } from '@lama/clients';
import { useQueryClient } from '@tanstack/react-query';
import type { FinancialData } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getEndOfYear, getStartOfYear, getYear } from '@lama/data-formatters';
import type { BusinessApiModel, RelatedPersonApiModel } from '@lama/business-service-client';
import { useGetPartnersProductsQuery } from '../../shared/hooks/react-query/product/useGetPartnersProductsQuery';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useGetPartnerQuery } from '../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { UserPartnerSelection } from '../../shared/components/UserPartnerSelection';
import { SimulationForm, type SimulationValues } from './SimulatorForm';
import { ProductSimulationList } from './ProductSimulationList';

const reviewYear = getYear(new Date().toISOString());

export const SimulatorPage: FC = () => {
  const { enablePartnerSelection } = useFlags();
  const queryClient = useQueryClient();
  const { partner: userPartner, userId } = useContext(UserDetailsContext);
  const [partnerId, setPartnerId] = useState<string | null>(userPartner ?? null);

  const [currentApplication, setCurrentApplication] = useState<ApplicationApiModel | null>(null);

  const { data: partner, isPending: fetchingPartner } = useGetPartnerQuery(partnerId ?? '');
  const { data: products, pending: loadingProducts } = useGetPartnersProductsQuery(partner ? [partner.id] : []);

  const onSubmit = useCallback(
    async ({
      requestedAmount,
      requestedRateType,
      requestedRate,
      termInMonths,
      industry,
      businessAnnualCashAvailable,
      businessAnnualDebtService,
      businessStartDate,
      personalCreditScore,
      personalAnnualCashAvailable,
      personalAnnualDebtService,
    }: SimulationValues) => {
      if (!partner || !products.length) {
        return;
      }

      const applicationId = uuidv4();
      const businessId = uuidv4();
      const personId = uuidv4();

      const startDate = getStartOfYear(reviewYear);
      const endDate = getEndOfYear(reviewYear);

      const personalFinancialsToCreate: FinancialData[] = [
        {
          id: uuidv4(),
          entityId: personId,
          entityType: 'person',
          value: +personalAnnualCashAvailable,
          type: 'Personal Cash Available for Global',
          startDate,
          endDate,
          source: {
            user: {
              userId: userId ?? '',
              type: 'Lender',
            },
            type: 'Adjustment',
          },
        },
        {
          id: uuidv4(),
          entityId: personId,
          entityType: 'person',
          value: +personalAnnualDebtService,
          type: 'Personal Debt Service',
          startDate,
          endDate,
          source: {
            user: {
              userId: userId ?? '',
              type: 'Lender',
            },
            type: 'Adjustment',
          },
        },
      ];
      const businessFinancialsToCreate: FinancialData[] = [
        {
          id: uuidv4(),
          entityId: businessId,
          entityType: 'business',
          value: +businessAnnualCashAvailable,
          type: 'Business EBITDA Using Net Operating Income',
          startDate,
          endDate,
          source: {
            user: {
              userId: userId ?? '',
              type: 'Lender',
            },
            type: 'Adjustment',
          },
        },
        {
          id: uuidv4(),
          entityId: businessId,
          entityType: 'business',
          value: +businessAnnualCashAvailable,
          type: 'Business EBITDA Using NOI and Officer Compensation',
          startDate,
          endDate,
          source: {
            user: {
              userId: userId ?? '',
              type: 'Lender',
            },
            type: 'Adjustment',
          },
        },
        {
          id: uuidv4(),
          entityId: businessId,
          entityType: 'business',
          value: +businessAnnualDebtService,
          type: 'Business Debt Service with Adjustments and without Refinanced Debt',
          startDate,
          endDate,
          source: {
            user: {
              userId: userId ?? '',
              type: 'Lender',
            },
            type: 'Adjustment',
          },
        },
      ];

      const owner: RelatedPersonApiModel = {
        id: personId,
        firstName: 'John',
        lastName: 'Doe',
        applicationId,
        partnerId: partner.id,
        ownershipPercentage: 100,
        financials: personalFinancialsToCreate,
        ficoScore: +personalCreditScore,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      const business: BusinessApiModel = {
        id: businessId,
        applicationId,
        partnerId: partner.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        financials: businessFinancialsToCreate,
        people: [owner],
        sisterCompaniesIds: [],
        subsidiariesIds: [],
        industryTypes: industry,
        startDate: businessStartDate,
      };
      const application: ApplicationApiModel = {
        id: applicationId,
        status: 'Draft',
        originatingPartner: partner.id,
        originalProductId: '1',
        leadingProductId: '1',
        leadingOpportunityId: '1',
        leadingPartnerId: partner.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        lastStatusUpdatedAt: new Date().toISOString(),
        balanceReferenceDate: new Date().toISOString(),
        relatedPeople: [],
        relatedBusinesses: [{ business, relations: ['borrower'] }],
        requestedAmount: +requestedAmount,
        requestedTerm: +termInMonths,
        requestedRateType,
        requestedRate: +requestedRate,
        leadingReferenceYear: reviewYear,
      };

      setCurrentApplication(application);

      await queryClient.invalidateQueries({ queryKey: ['evaluateApplicationForProduct', applicationId] });
    },
    [partner, products, queryClient, userId],
  );

  const onClear = useCallback(() => {
    setCurrentApplication(null);
  }, []);

  const onPartnerChange = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      if (value?.value) {
        onClear();
        setPartnerId(value.value);
      }
    },
    [onClear],
  );

  if (loadingProducts || fetchingPartner) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} px={10} pt={10} pb={24} gap={10}>
      <Flex justifyContent={'space-between'}>
        <Text variant={'h4'}>{'Loan Simulator'}</Text>
        {enablePartnerSelection ? (
          <UserPartnerSelection
            partnerId={partnerId}
            onPartnerChange={onPartnerChange}
            autocompleteStyleProps={{ maxWidth: '300px', width: '100%' }}
            filterProductionPartners={false}
          />
        ) : null}
      </Flex>
      <Flex gap={5}>
        <div style={{ minWidth: '400px' }}>
          <SimulationForm onSubmit={onSubmit} onClear={onClear} />
        </div>
        <ProductSimulationList partnerId={partner?.id ?? ''} application={currentApplication} />
      </Flex>
    </Flex>
  );
};
