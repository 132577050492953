import type { FC } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { Condition } from '../../../../../../shared/types/conditionTypes';
import { ConditionsListSectionContent } from '../ConditionsListSectionContent';

const operationalEfficiencyDemoData: Condition = {
  id: uuidv4(),
  status: 'pending',
  name: 'Operational Efficiency',
  hasComments: true,
  hasInsight: true,
  category: 'General',
  chargeOffPrediction: 1.2,
  assignedId: '1',
  fields: [
    {
      name: 'No of employees',
      value: '7',
      dataSources: ['stateRegistry', 'tax'],
    },
    {
      name: 'Average employee annual wages',
      value: '$223,333',
      dataSources: ['payroll', 'tax', 'online'],
      flagReason: 'The average wages are high for this industry',
    },
    {
      name: 'Revenue per employee, Annual',
      value: '$223,333',
      dataSources: ['quickbooks', 'bank', 'payroll'],
    },
    {
      name: 'Inventory turnover',
      value: '130 days',
      dataSources: ['shopify', 'quickbooks'],
    },
    {
      name: 'AR Turnover',
      value: '5.7',
      dataSources: ['shopify', 'quickbooks'],
    },
    {
      name: 'Repeat customers rate',
      value: '36%',
      hasInsight: true,
      dataSources: ['shopify', 'stripe', 'quickbooks'],
    },
    {
      name: 'Sales by main line of business',
      value: '$2,250,000',
      dataSources: ['shopify', 'stripe', 'quickbooks'],
      values: [
        { date: '2019', current: 310_000, peerBenchmark: 210_000 },
        { date: '2020', current: 380_000, peerBenchmark: 220_000 },
        { date: '2021', current: 390_000, peerBenchmark: 240_000 },
        { date: '2022', current: 280_000, peerBenchmark: 250_000 },
      ],
      flagReason: 'Sales significantly deviate from the previous year',
    },
    {
      name: 'Main Line of business growth',
      value: '-6%',
      dataSources: ['stripe'],
    },
    {
      name: 'Sales by secondary lines of business',
      value: '$430,000',
      dataSources: ['stripe'],
    },
    {
      name: 'Secondary line of business growth',
      value: '12%',
      dataSources: ['stripe'],
    },
  ],
};

export const OperationalEfficiencySection: FC = () => <ConditionsListSectionContent {...operationalEfficiencyDemoData} />;
