/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react';
import { summarizedAccountTypes, type Tradeline, type TradelineAccountType } from '@lama/credit-report-retriever-client';
import { formatValue } from '@lama/data-formatters';
import { greyPalette, Text } from '@lama/design-system';
import type { FC } from 'react';
import styled from 'styled-components';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { getDangerousValueColor } from './getDangerousValueColor';
import { getTradelinesSummary } from './utils';

const StyledHeaderCell = styled('th')`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;
`;

const StyledCell = styled('td')`
  padding-top: 12px;
  padding-bottom: 12px;
`;

const StyledHeaderRow = styled('tr')`
  background-color: ${greyPalette[200]};
`;

const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  border-radius: 8px;
  border: 1px solid ${greyPalette[300]};
  overflow: hidden;

  th:last-child {
    padding-right: 16px;
  }

  thead > tr:not(:last-child) > th,
  thead > tr:not(:last-child) > td,
  tbody > tr:not(:last-child) > th,
  tbody > tr:not(:last-child) > td,
  tfoot > tr:not(:last-child) > th,
  tfoot > tr:not(:last-child) > td,
  tr:not(:last-child) > td,
  tr:not(:last-child) > th,
  thead:not(:last-child),
  tbody:not(:last-child),
  tfoot:not(:last-child) {
    border-bottom: 1px solid ${greyPalette[300]};
  }
`;

const StyledRow = styled('tr')`
  & > td:first-child {
    padding-left: 16px;
  }

  & > td:last-child {
    padding-right: 16px;
  }

  & td:not(:first-child) {
    text-align: right;
  }
`;

interface TradelineSummary {
  name: string;
  count: number;
  balance: number;
  payments: number;
  amountPastDue: number;
  highCredit: number;
}

const getAccountTypeSummary = (tradelines: Tradeline[], accountTypes: TradelineAccountType[]) => {
  const filteredTradelines = tradelines.filter((t) => t.accountType && accountTypes.includes(t.accountType));
  return getTradelinesSummary(filteredTradelines);
};

const TradelineSummaryRow: FC<TradelineSummary> = ({ name, count, balance, payments, amountPastDue, highCredit }) => (
  <StyledRow>
    <StyledCell>
      <Text variant={'body2'}>{name}</Text>
    </StyledCell>
    <StyledCell>
      <Text variant={'body2'}>{count}</Text>
    </StyledCell>
    <StyledCell>
      <Text variant={'body2'}>{formatValue(balance, 'currency')}</Text>
    </StyledCell>
    <StyledCell>
      <Text variant={'body2'}>{formatValue(highCredit, 'currency')}</Text>
    </StyledCell>
    <StyledCell>
      <Text variant={'body2'}>{formatValue(payments, 'currency')}</Text>
    </StyledCell>
    <StyledCell>
      <Text variant={'body2'} color={getDangerousValueColor(amountPastDue)}>
        {formatValue(amountPastDue, 'currency')}
      </Text>
    </StyledCell>
  </StyledRow>
);

interface TradeSummaryTableProps {
  guarantor: RelatedPersonApiModel;
}

export const TradelinesSummaryTable: FC<TradeSummaryTableProps> = ({ guarantor }) => {
  const relevantTradelines = useMemo(() => guarantor.tradelines?.filter((t) => !t.dismissed) ?? [], [guarantor.tradelines]);

  const nonRevolvingTradelines = useMemo(() => relevantTradelines.filter((t) => !t.revolving), [relevantTradelines]);

  const mortgageTradelinesSummary = useMemo(
    () => getAccountTypeSummary(nonRevolvingTradelines, summarizedAccountTypes.Mortgage),
    [nonRevolvingTradelines],
  );
  const autoTradelinesSummary = useMemo(
    () => getAccountTypeSummary(nonRevolvingTradelines, summarizedAccountTypes.Auto),
    [nonRevolvingTradelines],
  );
  const educationTradelinesSummary = useMemo(
    () => getAccountTypeSummary(nonRevolvingTradelines, summarizedAccountTypes.Education),
    [nonRevolvingTradelines],
  );
  const revolvingTradelinesSummary = useMemo(
    () =>
      getTradelinesSummary(
        relevantTradelines.filter((t) => t.revolving || (t.accountType && summarizedAccountTypes.Revolving.includes(t.accountType))),
      ),
    [relevantTradelines],
  );
  const otherInstallmentsTradelinesSummary = useMemo(
    () => getAccountTypeSummary(nonRevolvingTradelines, summarizedAccountTypes['Other Installments']),
    [nonRevolvingTradelines],
  );
  const otherTradelinesSummary = useMemo(
    () => getAccountTypeSummary(nonRevolvingTradelines, summarizedAccountTypes.Other),
    [nonRevolvingTradelines],
  );
  const totalSummary = useMemo(() => getTradelinesSummary(relevantTradelines), [relevantTradelines]);

  return (
    <StyledTable>
      <thead>
        <StyledHeaderRow>
          <StyledHeaderCell />
          <StyledHeaderCell>
            <Text variant={'body2'}>{'Count'}</Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text variant={'body2'}>{'Balance'}</Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text variant={'body2'}>{'High Credit'}</Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text variant={'body2'}>{'Payments'}</Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text variant={'body2'}>{'Past Due'}</Text>
          </StyledHeaderCell>
        </StyledHeaderRow>
      </thead>
      <tbody>
        <TradelineSummaryRow name={'Mortgage'} {...mortgageTradelinesSummary} />
        <TradelineSummaryRow name={'Auto'} {...autoTradelinesSummary} />
        <TradelineSummaryRow name={'Education'} {...educationTradelinesSummary} />
        <TradelineSummaryRow name={'Revolving'} {...revolvingTradelinesSummary} />
        <TradelineSummaryRow name={'Other Installments'} {...otherInstallmentsTradelinesSummary} />
        <TradelineSummaryRow name={'Other'} {...otherTradelinesSummary} />
        <TradelineSummaryRow name={'Total'} {...totalSummary} />
      </tbody>
    </StyledTable>
  );
};
