import { Flex } from '@lama/design-system';
import styled from 'styled-components';

export const SideNavSectionItemContainer = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  flex-direction: column;
  padding: 12px 32px;
  border-left: ${({ selected, theme }) => `3px solid ${selected ? theme.colors.primary.main : 'transparent'}`};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary.light : 'white')};
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.primary.light : '#f5f6f6')};
  }
`;
