import { businessName, personFullName } from '@lama/data-formatters';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import type { Entity } from '@lama/common-types';
import type { EntityGroup, Matcher } from '@lama/contracts';
import { getApplicationEntityByType } from '@lama/properties';
import { evaluateMatcher } from '@lama/conditions';
import { isNil } from 'lodash-es';

interface Section {
  id: string;
  prettyName: string;
  entityType?: Entity;
  entityGroups?: EntityGroup[];
  enabledMatcher?: Matcher;
}

export type MultipliedSection<T extends Section> = T & { entity?: Record<string, any> & { id: string } };

const entityTypeToNameFunction: Partial<Record<Entity, (entity: any) => string>> = {
  person: personFullName,
  business: (entity: any) => businessName(entity as BusinessApiModel) ?? '',
};

const multiplySection = <T extends Section>(section: T, application: ApplicationApiModel): MultipliedSection<T>[] => {
  const sectionEntityType = section.entityType;

  if (!sectionEntityType) {
    return [section];
  }

  return getApplicationEntityByType(application, sectionEntityType, section.entityGroups ?? []).map((e) => {
    const multipliedSection = {
      ...section,
      id: `${section.id}_${e.id}`,
      prettyName: `${section.prettyName} - ${entityTypeToNameFunction[sectionEntityType]?.(e) ?? 'Unknown'}`,
      entity: e,
    };
    return multipliedSection;
  });
};

const filterSectionsByEnabledMatcher = <T extends Section>(
  sections: MultipliedSection<T>[],
  opportunity: OpportunityApiModel,
): MultipliedSection<T>[] =>
  sections.filter(({ enabledMatcher, entity }) => {
    if (!enabledMatcher) {
      return true;
    }

    const evaluationResult = evaluateMatcher({
      matcher: enabledMatcher,
      application: opportunity.application,
      entityId: entity?.id,
      opportunity: { ...opportunity, applicationId: opportunity.application.id },
    });

    return evaluationResult.result && !isNil(evaluationResult.extractedValue);
  });

export const getRelevantSections = <T extends Section>(sections: T[], opportunity: OpportunityApiModel): MultipliedSection<T>[] =>
  sections.flatMap((s) => filterSectionsByEnabledMatcher(multiplySection(s, opportunity.application), opportunity));
