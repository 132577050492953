import { useAuth0 } from '@auth0/auth0-react';
import type { ApplicationCreateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applicationServiceClient } from '../../../shared/clients/applicationServiceClient';

export const useCreateApplicationMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ createApplicationPayload }: { createApplicationPayload: ApplicationCreateApiModel }) => {
      const token = await getAccessTokenSilently();
      return applicationServiceClient.createApplication({ application: createApplicationPayload, token });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
  });
};
