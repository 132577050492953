import type { AccountData } from '@lama/contracts';
import { format } from 'date-fns';
import { compact, groupBy, sum } from 'lodash-es';
import type { Granularity } from '../../../types';
import { getDataInRange } from '../helpers';

const getChartDataFromTransactions = (date: string, dateFormat: string, transactions: AccountData['transactions']) => {
  const transactionAmounts = compact(transactions.map((transaction) => transaction.amount));
  const profit = sum(transactionAmounts.filter((amount) => amount < 0)) * -1;
  const loss = sum(transactionAmounts.filter((amount) => amount > 0));

  return {
    date,
    format: dateFormat,
    profit,
    loss,
  };
};

export const getWeeklyTransactionsData = (transactionsInRange: AccountData['transactions']) => {
  const groupedTransactions = groupBy(transactionsInRange, (transaction) => {
    const date = new Date(transaction.date);

    return format(date, 'ww yyyy');
  });

  return compact(
    Object.values(groupedTransactions).map((group) => {
      const firstGroupDate = group[0]?.date;

      return firstGroupDate ? getChartDataFromTransactions(firstGroupDate, 'MMM dd yy', group) : null;
    }),
  );
};

export const getMonthlyTransactionsData = (transactionsInRange: AccountData['transactions']) => {
  const groupedTransactions = groupBy(transactionsInRange, (transaction) => {
    const date = new Date(transaction.date);

    return format(date, 'MMM yy');
  });

  return compact(
    Object.values(groupedTransactions).map((group) => {
      const firstGroupDate = group[0]?.date;

      return firstGroupDate ? getChartDataFromTransactions(firstGroupDate, 'MMM yy', group) : null;
    }),
  );
};

export const getYearlyTransactionsData = (transactionsInRange: AccountData['transactions']) => {
  const groupedTransactions = groupBy(transactionsInRange, (transaction) => {
    const date = new Date(transaction.date);

    return format(date, 'yyyy');
  });

  return compact(
    Object.values(groupedTransactions).map((group) => {
      const firstGroupDate = group[0]?.date;

      return firstGroupDate ? getChartDataFromTransactions(firstGroupDate, 'yy', group) : null;
    }),
  );
};

export const getGranulatedTransactionsData = (
  transactions: AccountData['transactions'],
  lastTransactionDate: Date,
  granularity: Granularity,
  page: number,
) => {
  const transactionsInRange = getDataInRange(transactions, lastTransactionDate, page, granularity);

  let dataForChart;

  switch (granularity) {
    case 'week': {
      dataForChart = getWeeklyTransactionsData(transactionsInRange);
      break;
    }
    case 'month': {
      dataForChart = getMonthlyTransactionsData(transactionsInRange);
      break;
    }
    case 'year': {
      dataForChart = getYearlyTransactionsData(transactionsInRange);
      break;
    }
  }

  return dataForChart;
};
