import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import { useAsync, useToggle } from 'react-use';
import { useCallback } from 'react';
import type { UserDetails } from '../context/UserDetailsContext';

export const useLaunchDarklyIdentify = (user: UserDetails) => {
  const ldClient = useLDClient();
  const [identified, toggleIdentified] = useToggle(false);

  const onDone = useCallback(() => {
    toggleIdentified(true);
  }, [toggleIdentified]);

  useAsync(async () => {
    if (!isEmpty(user)) {
      const { email, firstName, lastName, partner, permissions, segments } = user;

      const ldContext = navigator.userAgent.includes('DatadogSynthetics')
        ? ({
            kind: 'user',
            firstName: 'Synthetics',
            lastName: 'Test',
            email: 'synthetics@lama.ai',
            key: 'synthetics@lama.ai',
            custom: { partner, permissions, segments },
          } as LDSingleKindContext)
        : ({
            kind: 'user',
            firstName,
            lastName,
            email,
            key: email,
            custom: { partner, permissions, segments },
          } as LDSingleKindContext);

      await ldClient?.identify(ldContext, undefined, onDone);
    }
  }, [user, ldClient, onDone]);

  return { identified };
};
