import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { useGetGooglePlacePhotoUrl } from '../hooks/useGetGooglePlacePhotoUrl';
import { BusinessDefaultImage } from './BusinessDefaultImage';

interface BusinessImageProps {
  googlePlaceId?: string;
  size: number;
}

export const BusinessImage: FC<BusinessImageProps> = ({ googlePlaceId, size }) => {
  const photoUrl = useGetGooglePlacePhotoUrl(googlePlaceId);

  return photoUrl ? (
    <Box
      borderRadius={1}
      alt={'business_image'}
      component={'img'}
      sx={{ height: `${size}px`, width: `${size}px`, minWidth: `${size}px`, minHeight: `${size}px`, objectFit: 'cover' }}
      src={photoUrl}
    />
  ) : (
    <BusinessDefaultImage size={size} />
  );
};
