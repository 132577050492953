/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import type { FC } from 'react';
import { RichInput, type RichInputProps } from '@lama/design-system';
import { BorderedContainer } from '../BorderedContainer';

export const BorderedEditableText: FC<RichInputProps> = (props) => (
  <BorderedContainer>
    <RichInput {...props} />
  </BorderedContainer>
);
