import React, { useContext, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { LoadingPage, getRequirementsByCategory } from '@lama/app-components';
import { useSearchParams } from 'react-router-dom';
import { Flex, grayPalette } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../ApplicationContext';
import { useOpportunityRequirementsQuery } from '../../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { RequirementsSideNav } from './RequirementsSideNav';
import { RequirementContent } from './RequirementContent';

export type RequirementFilter = 'all' | 'pending' | 'review';

export const OpportunityRequirementsPage: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { id: opportunityId } = opportunity;
  const { defaultRequirementFilter } = useFlags();
  const [filter, setFilter] = useState<RequirementFilter>((defaultRequirementFilter as RequirementFilter) || 'all');

  const { data: requirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunityId);
  const [searchParams, setSearchParams] = useSearchParams();

  const currentRequirementId = searchParams.get('requirementId');

  const currentRequirement = useMemo(() => requirements?.find((r) => r.id === currentRequirementId), [requirements, currentRequirementId]);

  const requirementsByCategory = useMemo(
    () => (requirements?.length ? getRequirementsByCategory(requirements, application) : []),
    [application, requirements],
  );

  useEffect(() => {
    if (loadingRequirements) {
      return;
    }

    if (!currentRequirement) {
      const firstRequirementIdOfFirstFilteredCategory = requirementsByCategory
        .flatMap(({ requirements: categoryRequirements }) => categoryRequirements)
        .find(({ status }) => {
          if (filter === 'pending') {
            return status === 'PendingBorrower' || status === 'PendingReview';
          }

          if (filter === 'review') {
            return status === 'Complete';
          }

          return true;
        })?.id;

      if (firstRequirementIdOfFirstFilteredCategory) {
        setSearchParams({ requirementId: firstRequirementIdOfFirstFilteredCategory }, { replace: true });
      } else {
        setFilter('all');
      }
    }
  }, [currentRequirement, filter, loadingRequirements, requirementsByCategory, setSearchParams]);

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (!requirements) {
    return null;
  }

  return (
    <Flex flex={1} border={`1px solid ${grayPalette[300]}`} borderRadius={'4px'}>
      <RequirementsSideNav requirementsByCategory={requirementsByCategory} filter={filter} setFilter={setFilter} />
      {currentRequirement ? <RequirementContent requirement={currentRequirement} /> : null}
    </Flex>
  );
};
