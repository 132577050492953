import type { FC } from 'react';
import React, { useMemo, useCallback } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import type { MRT_Cell, MRT_TableInstance, MRT_Row, MRT_Column } from 'material-react-table';
import { FinancialSpreadingCellPicker } from './FinancialSpreadingCellPicker';

interface LineItemParentSelectProps {
  cell: MRT_Cell<DocumentLineItem>;
  table: MRT_TableInstance<DocumentLineItem>;
  row: MRT_Row<DocumentLineItem>;
  column: MRT_Column<DocumentLineItem>;
  lineItems: DocumentLineItem[];
  onChange: (
    row: MRT_Row<DocumentLineItem>,
    column: MRT_Column<DocumentLineItem>,
    cellLineItem: DocumentLineItem,
    value: string | null,
  ) => void;
}

export const LineItemParentSelect: FC<LineItemParentSelectProps> = ({ lineItems, cell, row, column, table, onChange }) => {
  const cellLineItem = cell.row.original;
  const parentLineItem = lineItems?.find((item) => item.id === cellLineItem.parentId);

  const onParentChange = useCallback(
    async (value: string | null) => {
      onChange(row, column, cellLineItem, value);
    },
    [row, column, cellLineItem, onChange],
  );

  const options = useMemo(
    () =>
      lineItems
        .filter(({ id, parentId }) => id !== cellLineItem.id && parentId !== cellLineItem.id)
        .map((lineItem) => ({
          label: lineItem.text,
          value: lineItem.id,
        })),
    [lineItems, cellLineItem],
  );

  const currentOption = useMemo(() => options.find((option) => option.value === parentLineItem?.id), [options, parentLineItem]);

  return (
    <FinancialSpreadingCellPicker options={options} value={currentOption} onValueChanged={onParentChange} label={'Parent'} table={table} />
  );
};
