/* eslint-disable react/style-prop-object */
// @ts-ignore
import React from 'react';
import type { FC } from 'react';

export const ReceivedFilesIcon: FC<{ color?: string; width?: string; height?: string }> = ({
  color = 'black',
  width = 22,
  height = 22,
}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M11.25 1.834C10.79 1.756 10.162 1.75 9.03 1.75C7.113 1.75 5.75 1.752 4.718 1.89C3.706 2.025 3.125 2.28 2.702 2.702C2.279 3.124 2.025 3.705 1.89 4.711C1.752 5.739 1.75 7.093 1.75 9.001V13.001C1.75 14.907 1.752 16.261 1.89 17.289C2.025 18.295 2.279 18.875 2.702 19.299C3.125 19.721 3.705 19.975 4.711 20.11C5.739 20.249 7.093 20.25 9 20.25H13C14.907 20.25 16.262 20.248 17.29 20.11C18.295 19.975 18.875 19.721 19.298 19.298C19.721 18.875 19.975 18.295 20.11 17.289C20.248 16.262 20.25 14.907 20.25 13V12.563C20.25 11.027 20.24 10.299 20.076 9.75H16.946C15.813 9.75 14.888 9.75 14.156 9.652C13.393 9.549 12.731 9.327 12.202 8.798C11.673 8.269 11.451 7.608 11.348 6.843C11.25 6.113 11.25 5.187 11.25 4.053V1.834ZM12.75 2.61V4C12.75 5.2 12.752 6.024 12.835 6.643C12.915 7.241 13.059 7.534 13.263 7.737C13.467 7.94 13.759 8.085 14.357 8.165C14.976 8.248 15.8 8.25 17 8.25H19.02C18.6362 7.88462 18.2462 7.5259 17.85 7.174L13.891 3.611C13.5176 3.26957 13.1371 2.93583 12.75 2.61ZM9.175 0.25C10.56 0.25 11.455 0.25 12.278 0.565C13.101 0.881 13.763 1.477 14.788 2.4L14.895 2.496L18.853 6.059L18.978 6.171C20.162 7.236 20.928 7.925 21.339 8.849C21.75 9.773 21.751 10.803 21.75 12.395V13.056C21.75 14.894 21.75 16.35 21.597 17.489C21.439 18.661 21.107 19.61 20.359 20.359C19.61 21.107 18.661 21.439 17.489 21.597C16.349 21.75 14.894 21.75 13.056 21.75H8.944C7.106 21.75 5.65 21.75 4.511 21.597C3.339 21.439 2.39 21.107 1.641 20.359C0.893 19.61 0.561 18.661 0.403 17.489C0.25 16.349 0.25 14.894 0.25 13.056V8.945C0.25 7.107 0.25 5.651 0.403 4.512C0.561 3.34 0.893 2.391 1.641 1.642C2.391 0.893 3.342 0.562 4.519 0.404C5.663 0.251 7.126 0.251 8.974 0.251H9.176'
      }
      fill={color}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M10.547 15.513C10.6773 15.374 10.7499 15.1906 10.7499 15C10.7499 14.8094 10.6773 14.626 10.547 14.487L8.672 12.487C8.60506 12.4135 8.52418 12.354 8.43405 12.312C8.34392 12.2701 8.24635 12.2464 8.14701 12.2425C8.04766 12.2386 7.94853 12.2545 7.85538 12.2892C7.76223 12.324 7.67691 12.3769 7.60439 12.4449C7.53187 12.5129 7.4736 12.5946 7.43296 12.6854C7.39232 12.7761 7.37013 12.874 7.36767 12.9734C7.36522 13.0728 7.38255 13.1717 7.41866 13.2643C7.45476 13.357 7.50893 13.4415 7.578 13.513L8.268 14.25H5C4.90151 14.25 4.80398 14.2694 4.71299 14.3071C4.62199 14.3448 4.53931 14.4 4.46967 14.4697C4.40003 14.5393 4.34478 14.622 4.30709 14.713C4.2694 14.804 4.25 14.9015 4.25 15C4.25 15.0985 4.2694 15.196 4.30709 15.287C4.34478 15.378 4.40003 15.4607 4.46967 15.5303C4.53931 15.6 4.62199 15.6552 4.71299 15.6929C4.80398 15.7306 4.90151 15.75 5 15.75H8.269L7.578 16.487C7.50893 16.5585 7.45476 16.643 7.41866 16.7357C7.38255 16.8283 7.36522 16.9272 7.36767 17.0266C7.37013 17.126 7.39232 17.2239 7.43296 17.3146C7.4736 17.4054 7.53187 17.4871 7.60439 17.5551C7.67691 17.6231 7.76223 17.676 7.85538 17.7108C7.94853 17.7455 8.04766 17.7614 8.14701 17.7575C8.24635 17.7536 8.34392 17.7299 8.43405 17.688C8.52418 17.646 8.60506 17.5865 8.672 17.513L10.547 15.513Z'
      }
      fill={color}
    />
  </svg>
);
