import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { Grid, Flex, Text } from '@lama/design-system';
import React, { type FC, type ReactNode } from 'react';

export const DetailsSegment: FC<{ title: string; items: InfoLineProps[]; borrowerIcon?: ReactNode; titleActionComponent?: ReactNode }> = ({
  title,
  items,
  borrowerIcon,
  titleActionComponent,
}) => (
  <Flex flexDirection={'column'} gap={4}>
    <Flex flexDirection={'column'} gap={1}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Text variant={'body1'}>{title}</Text>
        {titleActionComponent}
      </Flex>
      {borrowerIcon ? (
        <Flex gap={2} alignItems={'center'}>
          {borrowerIcon}
          <Text variant={'body3'} color={'secondary'}>
            {'Borrower'}
          </Text>
        </Flex>
      ) : null}
    </Flex>
    <Grid columns={3}>
      {items.map((item) => (
        <KeyValuePair key={item.label} name={item.label} value={item.values} type={item.type} />
      ))}
    </Grid>
  </Flex>
);
