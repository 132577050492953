import React, { useMemo } from 'react';
import type { Document } from '@lama/document-service-client';
import type { FC } from 'react';
import { AuditLog } from '../../../AuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';

export const CreateDocumentAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => {
  const documentsDescriptions = useMemo(
    () => audit.map((a) => (a.context.entity as Document).description || (a.context.entity as Document).filename).join(', '),
    [audit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Uploaded "${documentsDescriptions}"`}
      timestamp={groupInfo.timestamp}
    />
  );
};

export const DocumentUpdatedAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => {
  const [firstAudit] = audit;

  if (firstAudit?.action === 'Update' && firstAudit.changes?.extracted) {
    return (
      <AuditLog
        originType={'system'}
        text={`Automatically extracted financial data from "${firstAudit.entityName}"`}
        timestamp={groupInfo.timestamp}
      />
    );
  }

  return null;
};

export const DocumentDeletedAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => (
  <AuditLog
    {...(groupInfo.originType === 'user'
      ? { originType: groupInfo.originType, user: groupInfo.user }
      : { originType: groupInfo.originType })}
    text={`Deleted "${audit[0]?.entityName ?? 'Unknown document'}"`}
    timestamp={groupInfo.timestamp}
  />
);

export const DocumentAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'Create') {
    return <CreateDocumentAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Update') {
    return <DocumentUpdatedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Delete') {
    return <DocumentDeletedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  return null;
};
