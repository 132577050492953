import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AuditEntryApiModel } from '@lama/audit-service-client';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityAuditQueryV2 = (opportunityId: string, options?: Partial<UseQueryOptions<AuditEntryApiModel[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuditByEntityIdsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const { audit } = await applicationServiceClient.getOpportunityAuditV2(opportunityId, token);
    return audit;
  }, [opportunityId, getAccessTokenSilently]);

  const query = useQuery<AuditEntryApiModel[]>({ queryKey: ['audit', opportunityId], queryFn: getAuditByEntityIdsQuery, ...options });

  return query;
};
