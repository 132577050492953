import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { CreateEnvelopeResponse, CreateEnvelopeRequestBody } from '@lama/docusign-client';
import { displayToast } from '@lama/app-components';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useCreateEnvelopeMutation = (options?: UseMutationOptions<CreateEnvelopeResponse, unknown, CreateEnvelopeRequestBody>) => {
  const { getAccessTokenSilently } = useAuth0();

  const createEnvelope = useCallback(
    async (payload: CreateEnvelopeRequestBody) => {
      const token = await getAccessTokenSilently();

      return docusignServiceClient.createEnvelope(payload, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: createEnvelope,
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
