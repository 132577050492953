import React, { useCallback } from 'react';
import type { FC } from 'react';
import { capitalCase } from 'change-case-all';
import { Flex, Text } from '@lama/design-system';
import type { NoteFilterOptions } from './NotesPage';

export const FilterButton: FC<{
  filter: NoteFilterOptions;
  label?: string;
  active: boolean;
  count: number;
  setFilter: (filter: NoteFilterOptions) => void;
}> = ({ filter, label, count, active, setFilter }) => {
  const onClick = useCallback(() => {
    setFilter(filter);
  }, [filter, setFilter]);

  return (
    <Flex
      onClick={onClick}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      backgroundColor={active ? '#F5F5F5' : 'transparent'}
      borderRadius={3}
      px={2}
      py={1}
    >
      <Text variant={'body3'} color={'secondary'}>
        {`${label ?? capitalCase(filter)} (${count})`}
      </Text>
    </Flex>
  );
};
