import type { DocumentPage, SpreadingDocument } from '@lama/document-service-client';
import { useMemo } from 'react';

export const usePageRangeText = (document: SpreadingDocument, documentPage: DocumentPage) =>
  useMemo(() => {
    if (documentPage.formType === 'Attachments') {
      return null;
    }

    const documentPages = document.documentPages?.filter(({ formType: pageType }) => pageType === documentPage.formType);

    const minPage = Math.min(...(documentPages?.map(({ page }) => page) ?? []));
    const maxPage = Math.max(...(documentPages?.map(({ page }) => page) ?? []));

    return minPage === maxPage ? `(Page ${minPage})` : `(Pages ${minPage}-${maxPage})`;
  }, [document.documentPages, documentPage.formType]);
