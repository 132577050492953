import React, { useCallback, useMemo } from 'react';
import type { LabeledValue } from '@lama/contracts';
import { useField } from 'formik';
import type { FC } from 'react';
import { FormikPicker } from '@lama/app-components';
import { useUserPartner } from '../../../../shared/hooks/useCurrentPartner';
import { dismissApplicationStatuses } from './DismissApplicationDialog';

interface FormikDecisionPickerProps {
  label: string;
  statusFieldName: string;
  reasonsFieldName: string;
  reasonsOtherFieldName: string;
}

export const FormikDecisionPicker: FC<FormikDecisionPickerProps> = ({
  label,
  reasonsFieldName,
  statusFieldName,
  reasonsOtherFieldName,
}) => {
  const [, { value: statusValue }] = useField<string>(statusFieldName);
  const [, , { setValue: setReasonsValue }] = useField<string[]>(reasonsFieldName);
  const [, , { setValue: setReasonsOtherValue }] = useField<string>(reasonsOtherFieldName);
  const partner = useUserPartner();

  const statusOptions = useMemo<LabeledValue[]>(
    () =>
      dismissApplicationStatuses.map((status) => ({
        label: partner.applicationStatusDisplayNameMapping[status] ?? '',
        value: status,
      })),
    [partner.applicationStatusDisplayNameMapping],
  );

  const onChangeCallback = useCallback(
    async (newStatus: string) => {
      if (newStatus !== statusValue) {
        await setReasonsValue([]);
        await setReasonsOtherValue('');
      }
    },
    [statusValue, setReasonsValue, setReasonsOtherValue],
  );

  return (
    <FormikPicker name={statusFieldName} label={label} values={statusOptions} onChangeCallback={onChangeCallback} required fullWidth />
  );
};
