import type { FC } from 'react';
import React, { useContext } from 'react';
import { applicationNumberSelector, loanDisplayNameSelector } from '@lama/data-formatters';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

export const GrasshopperRelatedLoansTable: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const applicationNumber = applicationNumberSelector(opportunity);
  const loanAmount = application.requestedAmount;
  const loanDisplayName = loanDisplayNameSelector(application);

  return (
    <SegmentContainer title={'Related Loans'} bordered={false}>
      <CreditMemoTable
        headers={[
          {
            text: 'Loan',
          },
          {
            text: 'Loan Number',
          },
          {
            text: 'Loan Amount',
          },
          {
            text: 'Principal Balance',
          },
          {
            text: 'Availability From Core',
          },
          {
            text: 'Exposure Value',
          },
        ]}
        rows={[
          [
            {
              value: loanDisplayName,
            },

            {
              value: applicationNumber ?? '-',
            },
            {
              value: loanAmount ?? '-',
              type: 'currency',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: loanAmount ?? '-',
              type: 'currency',
            },
          ],
        ]}
      />
    </SegmentContainer>
  );
};
