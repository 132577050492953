import { Flex, greyPalette, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';

export const SideNavEmptyState: FC = () => (
  <Flex justifyContent={'center'} py={12}>
    <Text variant={'body2'} color={greyPalette[500]} px={2}>
      {'Nothing to show'}
    </Text>
  </Flex>
);
