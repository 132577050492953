import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { allApplicationPeopleSelector, personFullName } from '@lama/data-formatters';
import type { AuditEntryApiModel } from '@lama/audit-service-client';
import type { EmailTrackingInternalStatus } from '@lama/email-sender-client';
import type { AuditComponentProps, AuditGroupInfo } from '../types';
import { AuditLog } from '../../../AuditLog';
import { useUserQuery } from '../../../../../hooks/react-query/user/useUserQuery';
import { useOpportunityEmailTrackingsQuery } from '../../../../../hooks/react-query/emailTrackings/useOpportunityEmailTrackingsQuery';
import { ApplicationContext } from '../../../../../../components/Application/ApplicationContext';
import { emailTrackingStatusToDisplay, emailTypeToDisplay } from './EmailTrackingAuditLog';

interface EmailTrackingUpdatedAuditLogProps {
  audit: AuditEntryApiModel;
  groupInfo: AuditGroupInfo;
}

const EmailTrackingUpdatedAuditLog: FC<EmailTrackingUpdatedAuditLogProps> = ({ audit, groupInfo }) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { data: emailTrackings } = useOpportunityEmailTrackingsQuery(opportunity.id);

  const emailTracking = useMemo(() => emailTrackings?.find((et) => et.id === audit.entityId), [emailTrackings, audit.entityId]);
  const { data: recipientUser, isPending: loadingRecipient } = useUserQuery(emailTracking?.recipientId, {
    enabled: emailTracking?.recipientIdType !== 'person',
  });

  const text = useMemo(() => {
    if (!emailTracking) {
      return null;
    }

    const emailTypeDisplay = emailTypeToDisplay(emailTracking.emailType);
    const statusDisplay = emailTrackingStatusToDisplay(emailTracking.currentStatus as EmailTrackingInternalStatus);

    const recipientPersonOrUser =
      emailTracking.recipientIdType === 'person'
        ? allApplicationPeopleSelector(application).find((p) => p.id === emailTracking.recipientId)
        : recipientUser;

    if (!emailTypeDisplay || !recipientPersonOrUser || !statusDisplay || audit.context.changes?.statusUpdates?.[0]?.status === 'click') {
      return null;
    }

    return `${emailTypeDisplay} was ${statusDisplay} ${personFullName(recipientPersonOrUser)}`;
  }, [application, audit.context.changes?.statusUpdates, emailTracking, recipientUser]);

  if (loadingRecipient) {
    return null;
  }

  if (!emailTracking || !text) {
    return null;
  }

  return (
    <AuditLog
      text={text}
      timestamp={groupInfo.timestamp}
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
    />
  );
};

export const EmailTrackingUpdateAuditLogs: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const EmailTrackingUpdatedAuditLogs = useMemo(
    () => audit.map((a) => <EmailTrackingUpdatedAuditLog key={a.id} groupInfo={groupInfo} audit={a} />),
    [groupInfo, audit],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{EmailTrackingUpdatedAuditLogs}</>;
};
