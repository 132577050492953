import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { orderBy } from 'lodash-es';
import { ChatOutlined } from '@mui/icons-material';
import { useEffectOnce, useToggle } from 'react-use';
import { Button, Flex } from '@lama/design-system';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import type { UnderwritingNote } from '@lama/contracts';
import { ApplicationContext } from '../ApplicationContext';
import { CommentPlaceholder } from '../../../shared/components/Comments/CommentPlaceholder';
import { FilterButton } from './FilterButton';
import { EmptyNotesPage } from './EmptyNotesPage';
import { FlagsAndExceptionsList } from './FlagsAndExceptionsList';
import { CommentsList } from './CommentsList';

export type NoteFilterOptions = 'comments' | 'flags-and-exceptions';

export const CreateNoteButtonContainer = styled(Flex)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.1s;
`;

const listComponentByType: Record<NoteFilterOptions, FC<{ notes: UnderwritingNote[] }>> = {
  comments: CommentsList,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'flags-and-exceptions': FlagsAndExceptionsList,
};

const defaultFilter: NoteFilterOptions = 'comments';

export const NotesPage: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  const location = useLocation();

  const initialFilterSearchParam = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return (searchParams.get('filter') as NoteFilterOptions) ?? defaultFilter;
  }, [location.search]);

  const [filter, setFilter] = useState<NoteFilterOptions>(initialFilterSearchParam);
  const [submittingNewComment, toggleSubmittingNewComment] = useToggle(false);

  const navigate = useNavigate();

  useEffectOnce(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter') as NoteFilterOptions;

    if (!filterParam) {
      searchParams.set('filter', defaultFilter);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  });

  const onClickAddComment = useCallback(() => {
    toggleSubmittingNewComment();

    mixpanel.track('NotesPage - Add Comment Clicked', {
      page: 'notesPage',
      opportunityId: opportunity.id,
    });
  }, [opportunity.id, toggleSubmittingNewComment]);

  const opportunityExceptions = useMemo(
    () => opportunity.underwriting?.notes?.filter((note) => note.type === 'exception') ?? [],
    [opportunity.underwriting?.notes],
  );

  const opportunityClearedFlags = useMemo(
    () => opportunity.underwriting?.notes?.filter((note) => note.type === 'cleared') ?? [],
    [opportunity.underwriting?.notes],
  );

  const opportunityFlags = useMemo(() => opportunity.underwriting?.notes?.filter((note) => note.type === 'flag') ?? [], [opportunity]);

  const opportunityNotes = useMemo(() => opportunity.underwriting?.notes?.filter((note) => note.type === 'general') ?? [], [opportunity]);

  const filteredNotes = useMemo(() => {
    let notesToDisplay = [];

    switch (filter) {
      case 'comments': {
        notesToDisplay = opportunityNotes;
        break;
      }
      case 'flags-and-exceptions': {
        notesToDisplay = [...opportunityFlags, ...opportunityExceptions, ...opportunityClearedFlags];
        break;
      }
    }

    return orderBy(notesToDisplay, [(flag) => new Date(flag.createdAt)], ['desc']);
  }, [filter, opportunityClearedFlags, opportunityExceptions, opportunityFlags, opportunityNotes]);

  const flagAndExceptionCount = useMemo(
    () => opportunityFlags.length + opportunityExceptions.length + opportunityClearedFlags.length,
    [opportunityClearedFlags.length, opportunityExceptions.length, opportunityFlags.length],
  );

  const ListComponent = useMemo(() => listComponentByType[filter], [filter]);
  const onSetFilter = useCallback(
    (newFilter: NoteFilterOptions) => {
      setFilter(newFilter);

      mixpanel.track('NotesPage - Filter Changed', {
        page: 'notesPage',
        opportunityId: opportunity.id,
        filter: newFilter,
      });

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('filter', newFilter);

      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    },
    [location.pathname, location.search, navigate, opportunity.id],
  );

  return (
    <Flex flex={1} flexDirection={'column'} gap={8} px={20}>
      <Flex justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
        <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
          <FilterButton
            label={'Comments'}
            filter={'comments'}
            active={filter === 'comments'}
            count={opportunityNotes.length}
            setFilter={onSetFilter}
          />
          <FilterButton
            filter={'flags-and-exceptions'}
            label={'Flags & Exceptions'}
            active={filter === 'flags-and-exceptions'}
            count={flagAndExceptionCount}
            setFilter={onSetFilter}
          />
        </Flex>
        <CreateNoteButtonContainer visible={filter === 'comments'}>
          <Button
            variant={'secondary'}
            color={'neutral'}
            selected={submittingNewComment}
            startIcon={<ChatOutlined />}
            onClick={onClickAddComment}
          >
            {'Add Comment'}
          </Button>
        </CreateNoteButtonContainer>
      </Flex>
      {submittingNewComment && filter === 'comments' ? (
        <CommentPlaceholder
          relatedItemType={'requirement'}
          onSubmit={toggleSubmittingNewComment}
          onCancel={toggleSubmittingNewComment}
          initialShowNewCommentButton
          startInEditMode
        />
      ) : null}
      {filteredNotes.length === 0 && !submittingNewComment ? (
        <EmptyNotesPage filterType={filter} />
      ) : (
        <ListComponent notes={filteredNotes} />
      )}
    </Flex>
  );
};
