import type { OpportunityClosingTask } from '@lama/contracts';
import type { Document } from '@lama/document-service-client';
import type { Entity } from '@lama/common-types';
import { Flex, Text, grayPalette, Collapse } from '@lama/design-system';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { getOpportunityEntityByType } from '@lama/properties';
import { businessNameWithRelationsSelector, personNameWithRelationsSelector } from '@lama/data-formatters';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import { ApplicationContext } from '../../ApplicationContext';
import { useApplicationDocumentsQuery } from '../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { CommentsSegment } from '../../../../shared/components/Comments/CommentsSegment';
import { DocumentBox } from '../../../../shared/components/DocumentBox/DocumentBox';
import { useIndependentDocumentsMoveToTree } from '../../../DocumentHub/hooks/useIndependentDocumentsMoveToTree';
import { closingTasksComponentsByType } from './ClosingTasksTypes/closingTasksComponentsMap';
import { TaskCardHeader } from './TaskCardHeader';
import { TaskCardFooter } from './TaskCardFooter';
import { ArbitraryClosingTaskDocumentUpload } from './ArbitraryClosingTaskDocumentUpload';

interface ClosingTaskCardProps {
  task: OpportunityClosingTask;
  taskDocuments?: Document[];
}

const StyledCard = styled(Flex)<{ open: boolean }>(
  ({ open }) => `
    border-radius: 8px;
    border: 1px solid;
    background: white;
    flex-direction: column;
    border-color: ${grayPalette[300]};
    transition: background 0.1s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    ${open && 'box-shadow: 0px 8px 25px 0px rgba(56, 0, 100, 0.08);'}
`,
);

interface AdditionalDocumentsSectionProps {
  documents: Document[];
  taskDocumentRequirements: OpportunityClosingTask['requiredDocuments'];
  relatedEntityId?: string;
  relatedEntityType?: Entity;
  closingTask: OpportunityClosingTask;
}

export const AdditionalDocumentsSection = ({
  documents,
  taskDocumentRequirements,
  relatedEntityId,
  relatedEntityType,
  closingTask,
}: AdditionalDocumentsSectionProps) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const moveToTreeData = useIndependentDocumentsMoveToTree({
    applicationId: application.id,
    opportunityId: opportunity.id,
    grouping: 'topic',
  });
  const entityDocuments = useMemo(
    () => documents.filter((document) => (relatedEntityId ? document.relatedEntityId === relatedEntityId : true)),
    [documents, relatedEntityId],
  );

  const documentBoxes = useMemo(() => {
    const enabledSources = getEnabledFileUploadSources({ sources: taskDocumentRequirements ?? [], entityId: relatedEntityId, application });

    const predefinedDocumentsComponents = enabledSources.map(({ name: documentName, topic }) => (
      <DocumentBox
        key={documentName}
        description={documentName}
        topic={topic}
        document={entityDocuments.find((document) => document.topic === topic)}
        entityId={relatedEntityId}
        entityType={relatedEntityType}
        applicationId={application.id}
        closingTask={closingTask}
        moveToTreeData={moveToTreeData}
      />
    ));

    const topics = new Set(enabledSources.map((requirement) => requirement.topic));
    const arbitraryDocumentsComponents = documents
      .filter(
        (document) =>
          (!document.topic || !topics.has(document.topic)) && (!relatedEntityId || document.relatedEntityId === relatedEntityId),
      )
      .map((document) => (
        <DocumentBox
          key={document.id}
          description={document.filename}
          topic={document.topic}
          document={document}
          entityId={relatedEntityId}
          entityType={relatedEntityType}
          applicationId={application.id}
          closingTask={closingTask}
          moveToTreeData={moveToTreeData}
        />
      ));

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [application, closingTask, documents, entityDocuments, moveToTreeData, relatedEntityId, relatedEntityType, taskDocumentRequirements]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      {documentBoxes}
      <Flex p={1} borderRadius={'8px'}>
        <ArbitraryClosingTaskDocumentUpload
          opportunityId={opportunity.id}
          applicationId={application.id}
          taskId={closingTask.id}
          relatedEntityId={relatedEntityId}
          relatedEntityType={relatedEntityType}
        />
      </Flex>
    </Flex>
  );
};

const TaskDocumentsSegment: FC<{ task: OpportunityClosingTask }> = ({ task }) => {
  const { documentIds, requiredDocuments, entityType, entityGroups } = task;
  const { opportunity, application } = useContext(ApplicationContext);
  const { data: relatedDocuments } = useApplicationDocumentsQuery({ applicationId: application.id });
  const taskDocuments = useMemo(
    () => relatedDocuments?.filter((document) => documentIds?.includes(document.id)) ?? [],
    [relatedDocuments, documentIds],
  );

  const taskEntities = useMemo(
    () =>
      entityType && entityGroups
        ? getOpportunityEntityByType(opportunity, entityType, entityGroups).map((e) => {
            const entityName =
              entityType === 'application' || entityType === 'opportunity'
                ? null
                : entityType === 'person'
                ? personNameWithRelationsSelector(e.id, application)
                : businessNameWithRelationsSelector(e.id, application);
            return { entity: e, entityName };
          })
        : [],
    [application, entityGroups, entityType, opportunity],
  );

  return taskEntities.length ? (
    <Flex flexDirection={'column'} gap={4}>
      {taskEntities.map(({ entity, entityName }) => (
        <Flex key={entity.id} flexDirection={'column'} gap={4}>
          <Text variant={'body2'} color={'secondary'}>
            {entityName}
          </Text>
          <AdditionalDocumentsSection
            documents={taskDocuments}
            taskDocumentRequirements={requiredDocuments}
            relatedEntityId={entity.id}
            relatedEntityType={entityType}
            closingTask={task}
          />
        </Flex>
      ))}
    </Flex>
  ) : (
    <AdditionalDocumentsSection documents={taskDocuments} closingTask={task} taskDocumentRequirements={requiredDocuments} />
  );
};

export const ClosingTaskCard: FC<ClosingTaskCardProps> = ({ task }) => {
  const { hideAdditionalDocumentsSection, id: taskId, description } = task;

  const [searchParams, setSearchParams] = useSearchParams();
  const closingTaskId = searchParams.get('task');

  const [open, setOpen] = useState(closingTaskId === taskId);

  const TaskComponent = useMemo(() => closingTasksComponentsByType[task.type], [task]);

  const onClick = useCallback((_clickedTaskId: string) => {
    setOpen((prev) => !prev);
  }, []);

  const onTransitionStateChange = useCallback(
    (state: 'collapseEnd' | 'collapseStart' | 'collapsing' | 'expandEnd' | 'expanding' | 'expandStart') => {
      if (state === 'expandEnd') {
        setSearchParams({ task: taskId }, { replace: true });
      } else if (state === 'collapseEnd') {
        setSearchParams({}, { replace: true });
      }
    },
    [setSearchParams, taskId],
  );

  return (
    <StyledCard open={open}>
      <TaskCardHeader open={open} onClick={onClick} task={task} />
      <Collapse expanded={open} onTransitionStateChange={onTransitionStateChange}>
        <Flex flexDirection={'column'} gap={6} p={6}>
          {description ? (
            <Text variant={'body1'} color={'secondary'}>
              {description}
            </Text>
          ) : null}
          {TaskComponent ? <TaskComponent task={task} /> : null}
          {hideAdditionalDocumentsSection ? null : <TaskDocumentsSegment task={task} />}
          <TaskCardFooter task={task} />
          <CommentsSegment relatedItemId={taskId} relatedItemType={'closingTask'} showExportHelperText={false} />
        </Flex>
      </Collapse>
    </StyledCard>
  );
};
