import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { UseOfFundsSummaryTable } from '../../../Application/shared/UseOfFunds/UseOfFundsSummaryTable';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { UseOfFundsSegment } from './Segments/UseOfFundsSegment';

export const UseOfFundsView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  if (!application?.useOfFundsBreakdown?.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'No information found'}</Typography>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <>
        <Stack gap={2} sx={{ pageBreakInside: 'avoid' }}>
          <Typography variant={'h6'} color={grey[500]}>
            {'Summary'}
          </Typography>
          <UseOfFundsSummaryTable />
        </Stack>
        <Stack gap={2} sx={{ pageBreakInside: 'avoid' }}>
          <Typography variant={'h6'} color={grey[500]}>
            {'Breakdown'}
          </Typography>
          {application.useOfFundsBreakdown.map((breakdown) => (
            <UseOfFundsSegment key={breakdown.id} breakdown={breakdown} />
          ))}
        </Stack>
      </>
    </RequirementPrintContainer>
  );
};
