import React from 'react';
import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { EmptyDataImage } from './EmptyDataImage';

export const EmptyData: FC = () => (
  <Stack spacing={3}>
    <Typography variant={'body1'}>{'No data for the selected period'}</Typography>
    <EmptyDataImage />
  </Stack>
);
