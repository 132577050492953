import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import type { SourcedProperty } from '@lama/properties';
import { getSourcedProperty } from '@lama/properties';
import type { InformationTypes } from '@lama/app-components';
import { KeyValuePair, shouldShowConditionedProperty } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import type { PropertyKeyWithFormConfig } from '../../../../shared/utils/getFormProperties';
import { getFormProperties } from '../../../../shared/utils/getFormProperties';
import { SegmentContainer } from '../../shared/SegmentContainer';

export const GenericDataGridSegment: FC<{
  entity: Record<string, any> & { id: string };
  propertyDefinitions: PropertyKeyWithFormConfig[];
  columns?: number;
  title?: string;
}> = ({ entity, propertyDefinitions, columns = 3, title }) => {
  const { application, opportunity, properties } = useContext(ApplicationContext);

  const propertiesWithDecidedSource = useMemo<SourcedProperty[]>(() => {
    const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;

    return getFormProperties(propertyDefinitions, properties)
      .map((p) => getSourcedProperty(p, entity, application, yearsBack))
      .filter((p) => shouldShowConditionedProperty(p, entity, {}, application, opportunity));
  }, [propertyDefinitions, properties, opportunity, entity, application]);

  return (
    <SegmentContainer title={title}>
      <Grid columns={columns}>
        {propertiesWithDecidedSource.map(
          ({ propertyKey, displayName, displayNameOverride, selectedPropertyValue, type, displayType, restricted }) => (
            <KeyValuePair
              key={propertyKey}
              name={displayNameOverride ?? displayName}
              value={selectedPropertyValue?.value}
              type={(displayType as InformationTypes) ?? type}
              restricted={!!restricted}
            />
          ),
        )}
      </Grid>
    </SegmentContainer>
  );
};
