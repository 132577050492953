import { useMemo } from 'react';
import type { Plugin, PluginFunctions } from '@react-pdf-viewer/core';
import { createStore } from '@react-pdf-viewer/core';

interface StoreProps {
  jumpToPage?: (pageIndex: number) => void;
}

interface JumpToPagePlugin extends Plugin {
  jumpToPage: (pageIndex: number) => void;
}

const jumpToPagePlugin = (): JumpToPagePlugin => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useMemo(() => createStore<StoreProps>(), []);

  return {
    install: (pluginFunctions: PluginFunctions) => {
      store.update('jumpToPage', pluginFunctions.jumpToPage);
    },
    jumpToPage: (pageIndex: number) => {
      const fn = store.get('jumpToPage');
      if (fn) {
        fn(pageIndex);
      }
    },
  };
};

export default jumpToPagePlugin;
