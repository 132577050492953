import React from 'react';
import { FilterAltOutlined } from '@mui/icons-material';
import ReactDOM from 'react-dom/client';

export const FilterOnIcon = () => {
  const span = document.createElement('span');
  span.style.display = 'flex';
  span.style.alignItems = 'center';
  span.style.justifyContent = 'center';

  ReactDOM.createRoot(span).render(<FilterAltOutlined sx={{ color: 'gray', height: '16px', width: '16px' }} />);

  return span;
};
