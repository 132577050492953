import React from 'react';
import { Warning, Info } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { SecondaryActionButton } from '../../../../shared/components/SecondaryActionButton';

interface RequirementAlertProps {
  level: 'error' | 'info' | 'warning';
  message: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  onPrimaryActionClick?: () => void;
  onSecondaryActionClick?: () => void;
}

const levelToIcon = {
  info: <Info color={'info'} />,
  warning: <Warning color={'warning'} />,
  error: <Warning color={'error'} />,
};

export const RequirementAlert: React.FC<RequirementAlertProps> = ({
  message,
  level,
  onPrimaryActionClick = () => {},
  onSecondaryActionClick = () => {},
  primaryActionLabel,
  secondaryActionLabel,
}) => {
  const Icon = levelToIcon[level];

  return (
    <Stack direction={'column'} justifyContent={'center'} gap={2}>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {Icon}
        <Typography variant={'body1'}>{message}</Typography>
      </Stack>
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        {primaryActionLabel ? <SecondaryActionButton variant={'info'} label={primaryActionLabel} onClick={onPrimaryActionClick} /> : null}
        {secondaryActionLabel ? (
          <SecondaryActionButton variant={'info'} label={secondaryActionLabel} onClick={onSecondaryActionClick} />
        ) : null}
      </Stack>
    </Stack>
  );
};
