import React, { useMemo, useState } from 'react';
import type { ProductApiModel } from '@lama/contracts';
import { useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';
import { useAsyncFn, useMap } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { ConfirmLeave } from '@lama/app-components';

export const ProductSettingsContext = React.createContext<{
  product: ProductApiModel;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  saveFns: Record<string, () => Promise<void>>;
  registerSaveFn: (key: string, fn: () => any) => void;
  saveAll?: () => any;
  savingProduct: boolean;
  returnToAfterSave?: string;
}>({
  isDirty: false,
  product: {} as ProductApiModel,
  registerSaveFn: () => {},
  saveFns: {},
  saveAll: undefined,
  savingProduct: false,
  setIsDirty: () => {},
});

interface ProductProviderProps {
  product: ProductApiModel;
  returnToAfterSave?: string;
  children: React.ReactNode;
}

export const ProductSettingsProvider: FC<ProductProviderProps> = ({ product, returnToAfterSave, children }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [saveFns, { set }] = useMap<Record<string, () => Promise<void>>>({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [{ loading }, saveAll] = useAsyncFn(async () => {
    await Promise.all(Object.values(saveFns).map((fn) => fn()));
    await queryClient.invalidateQueries({ queryKey: ['product', product.id] });
    setIsDirty(false);

    if (returnToAfterSave) {
      navigate(returnToAfterSave);
    }
  }, [navigate, product.id, queryClient, returnToAfterSave, saveFns]);

  const value = useMemo(
    () => ({
      product,
      isDirty,
      setIsDirty,
      saveFns,
      registerSaveFn: set,
      saveAll,
      savingProduct: loading,
      returnToAfterSave,
    }),
    [isDirty, loading, product, returnToAfterSave, saveAll, saveFns, set],
  );

  return (
    <ConfirmLeave shouldBlock={isDirty}>
      <ProductSettingsContext.Provider value={value}>{children}</ProductSettingsContext.Provider>
    </ConfirmLeave>
  );
};
