import React, { useEffect } from 'react';
import type { UnderwritingGroup } from '@lama/contracts';
import { Collapse, Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import ExpandButton from '../../../../shared/components/ExpandButton';
import { UnderwritingSideNavItem } from './UnderwritingSideNavItem';

const CategoryFlex = styled(Flex)`
  cursor: pointer;
`;

interface UnderwritingCategoryListItemProps {
  group: UnderwritingGroup;
}

export const UnderwritingSideNavCategory: React.FC<UnderwritingCategoryListItemProps> = ({ group: { groupName, sections } }) => {
  const [categoryOpen, toggleCategoryOpen] = useToggle(false);
  const [searchParams] = useSearchParams();
  const currentRequirementId = searchParams.get('section');

  useEffect(() => {
    if (sections?.find((r) => r.id === currentRequirementId)) {
      toggleCategoryOpen(true);
    }
  }, [currentRequirementId, sections, toggleCategoryOpen]);

  return (
    <CategoryFlex onClick={toggleCategoryOpen} flexDirection={'column'}>
      <Flex px={6} py={2} justifyContent={'space-between'} alignItems={'center'}>
        <Text variant={'body1'} color={'text.secondary'} ellipsis data-sentry-unmask>
          {groupName}
        </Text>
        <ExpandButton expand={categoryOpen} />
      </Flex>
      <Flex flexDirection={'column'}>
        <Collapse expanded={categoryOpen}>
          {sections.map((s) => (
            <UnderwritingSideNavItem key={s.id} section={s} />
          ))}
        </Collapse>
      </Flex>
    </CategoryFlex>
  );
};
