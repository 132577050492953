import { useCallback } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { createDocumentServiceClient } from '@lama/document-service-client';
import type { Document } from '@lama/document-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { documentServiceUrl } from '../../../../../../../framework/environment';

interface UpdateDocumentMutationParams {
  opportunityId?: string;
  applicationId?: string;
}

export const useUpdateDocumentMutation = (invalidationParams: UpdateDocumentMutationParams = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const updateDocument = useCallback(
    async ({ documentId, payload }: { documentId: string; payload: Partial<Document> }) => {
      const token = await getAccessTokenSilently();
      await createDocumentServiceClient(documentServiceUrl).updateDocument({ documentId, payload }, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: updateDocument,
    onSuccess: async () => {
      if (invalidationParams?.applicationId) {
        await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', invalidationParams.applicationId] });
        await queryClient.invalidateQueries({ queryKey: ['spreadingDocuments', invalidationParams.applicationId] });
      }

      if (invalidationParams?.opportunityId) {
        await queryClient.invalidateQueries({ queryKey: ['opportunityRequirements', invalidationParams.opportunityId] });
      }
    },
  });
};
