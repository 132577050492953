import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import { capitalCase } from 'change-case-all';

export type Filter = 'all' | 'complete' | 'pending';

export const FilterButton: FC<{
  filter: Filter;
  active: boolean;
  count: number;
  setFilter: (filter: Filter) => void;
}> = ({ filter, count, active, setFilter }) => {
  const onClick = useCallback(() => {
    setFilter(filter);
  }, [filter, setFilter]);

  return (
    <Stack
      onClick={onClick}
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#F5F5F5' }, bgcolor: active ? '#F5F5F5' : 'transparent' }}
      px={1}
      py={0.5}
    >
      <Typography variant={'buttonSmall'} color={'text.secondary'}>
        {`${capitalCase(filter)} (${count})`}
      </Typography>
    </Stack>
  );
};
