import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { groupBy, keyBy } from 'lodash-es';
import { Flex, Text } from '@lama/design-system';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';

import type { SpreadingFormByDocumentTypeProps } from '../../../SpreadingDialog/Content/SpreadingFormByType';
import { sortFormAttributesByPage } from '../sortAttributes';
import { useGetAttributesDocumentFunction } from '../../../hooks/useGetAttributesDocumentFunction';
import { TaxReturnsSchedule } from './TaxReturnsSpreadingForm/TaxReturnsSchedule';

export const TaxReturnsSpreadingForm: FC<SpreadingFormByDocumentTypeProps> = ({
  formDocumentPages,
  formData: formAttributesWithFinancialData,
}) => {
  const { currentPage, setHighlightedArea, setJumpToPage, allFormTypes } = useContext(SpreadingDocumentContext);

  const formType = useMemo(() => formDocumentPages.at(0)?.formType, [formDocumentPages]);

  const currentFormTypeData = useMemo(() => allFormTypes?.find(({ id }) => id === formType), [allFormTypes, formType]);

  const attributesById = useMemo(
    () => keyBy(formAttributesWithFinancialData, (attribute) => attribute.id),
    [formAttributesWithFinancialData],
  );

  const getAttributeDocumentPage = useGetAttributesDocumentFunction();
  const onFieldFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { id } = event.target;
      const attribute = attributesById[id];

      if (attribute) {
        const pageToFocus = getAttributeDocumentPage(attribute);
        if (pageToFocus && currentPage !== pageToFocus - 1) {
          setJumpToPage(pageToFocus - 1);
        }

        const fieldSource = attribute.financialData?.source;
        if (fieldSource?.type === 'Document' && fieldSource?.boundingBox) {
          setHighlightedArea({ ...fieldSource.boundingBox, pageIndex: fieldSource.page });
        }
      }

      event.target.focus();
    },
    [attributesById, currentPage, getAttributeDocumentPage, setHighlightedArea, setJumpToPage],
  );

  const formAttributesBySchedule = useMemo(
    () =>
      Object.entries(groupBy(sortFormAttributesByPage(formAttributesWithFinancialData ?? []), ({ attribute }) => attribute.schedule ?? '')),
    [formAttributesWithFinancialData],
  );

  if (!formAttributesBySchedule.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} position={'relative'} overflow={'hidden'} flex={1}>
      <Flex flexDirection={'column'} p={4} gap={4}>
        <Flex flexDirection={'row'} alignItems={'flex-end'}>
          {currentFormTypeData ? <Text variant={'h6'}>{`${currentFormTypeData?.displayName ?? currentFormTypeData.id}`}</Text> : null}
        </Flex>
        {formAttributesBySchedule.map(([schedule, attributes]) => (
          <TaxReturnsSchedule key={schedule} financialAttributes={attributes} onFieldFocus={onFieldFocus} scheduleName={schedule} />
        ))}
      </Flex>
    </Flex>
  );
};
