import React from 'react';
import { FilterList } from '@mui/icons-material';
import ReactDOM from 'react-dom/client';

export const FilterMenuIcon = () => {
  const span = document.createElement('span');

  ReactDOM.createRoot(span).render(<FilterList sx={{ width: '18px', height: '18px' }} />);

  return span;
};
