import React from 'react';
import { Flex } from '@lama/design-system';
import { InlineNote } from '../../../../shared/InlineNote/InlineNote';

export const FirstBankDemoRiskRatingSection = () => (
  <Flex flexDirection={'column'} gap={8}>
    <InlineNote title={'Key Risks & Mitigating Factors'} noteName={'Key Risks & Mitigating Factors'} />
    <InlineNote title={'Preliminary Risk Rating Justification'} noteName={'Preliminary Risk Rating Justification'} />
    <InlineNote title={'Proposed Financial Covenants'} noteName={'Proposed Financial Covenants'} />
  </Flex>
);
