import React from 'react';

import type { TableCellProps } from '@mui/material';
import { Text } from '@lama/design-system';
import type { InformationTypes } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

const dataRows: {
  type: InformationTypes;
  values: (number | string | null)[];
  name: string;
  bold: boolean;
  bgColor?: string;
  colspan?: number;
}[] = [
  {
    name: 'Sales',
    values: [23_240_159, 22_764_360, 17_592_339, 9_183_604, 7_934_852, 16_343_586],
    type: 'currency',
    bold: true,
  },
  {
    name: ' % Growth',
    values: [58.7, -2, -22.7, -47.8, -13.6, -7.1],
    type: 'percent',
    bold: false,
  },
  {
    name: 'Gross Profit',
    values: [4_218_757, 3_505_839, 2_311_661, 1_631_029, 1_608_281, 2_288_913],
    type: 'currency',
    bold: false,
  },
  {
    name: ' % Gross Margin',
    values: [18.2, 15.4, 13.1, 17.8, 20.3, 14],
    type: 'percent',
    bold: false,
  },
  {
    name: 'Operating Income',
    values: [2_109_720, 897_521, -154_391, 205_726, 172_274, -187_843],
    type: 'currency',
    bold: false,
  },
  {
    name: ' % Operating Margin',
    values: [9.1, 3.9, -0.9, 2.2, 2.2, -1.1],
    type: 'percent',
    bold: false,
  },
  {
    name: 'Net Income',
    values: [2_108_789, 896_570, -155_075, 206_151, 172_419, -188_807],
    type: 'currency',
    bold: false,
  },
  {
    name: ' + Interest Expense',
    values: [137_010, 238_106, 467_448, 220_524, 207_275, 454_199],
    type: 'currency',
    bold: false,
  },
  {
    name: ' + Depreciation',
    values: [6616, 7022, 9213, 0, 0, 9213],
    type: 'currency',
    bold: false,
  },
  {
    name: ' + Amortization',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: ' + Tax Expense',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: ' - PPP Forgiveness',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: ' - ERC',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'EBITDA',
    values: [2_252_415, 1_141_698, 321_586, 426_675, 379_694, 274_605],
    type: 'currency',
    bold: true,
  },
  {
    name: '+/- EBITDA Adjustment',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Adjusted EBITDA',
    values: [2_252_415, 1_141_698, 321_586, 426_675, 379_694, 274_605],
    type: 'currency',
    bold: true,
  },
  {
    name: ' + Rent Expense (Historical)',
    values: [250_290, 394_370, 282_811, 165_412, 220_127, 337_527],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Adjusted EBITDAR',
    values: [2_502_705, 1_536_068, 604_397, 592_087, 599_821, 612_132],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Less: Unfinanced Capital Expenditures',
    values: [0, 3416, 17_445, 0, 0, 17_445],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Less: Cash Taxes Paid',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Less: Cash Distributions for Taxes',
    values: [572_000, 1_081_000, 132_245, 10_950, 20_000, 141_295],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Less: Distributions in Excess of Taxes',
    values: [0, 0, 0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Cash Flow Available for Debt Service',
    values: [1_930_705, 451_652, 454_707, 581_137, 579_821, 453_392],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Historical ADS & Rent',
    values: [],
    type: 'currency',
    bold: true,
    bgColor: '#EFEFEF',
    colspan: 1000,
  },
  {
    name: 'Interest Expense - Historical',
    values: [137_010, 238_106, 467_448, 220_524, 207_275, 454_199],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Principal Payments - Historical',
    values: [0, 137_370, 422_605, 0, 0, 422_605],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Rent Expense',
    values: [250_290, 394_370, 282_811, 165_412, 220_127, 337_527],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Cash Charges',
    values: [387_300, 769_846, 1_172_864, 385_936, 427_403, 1_214_331],
    type: 'currency',
    bold: true,
  },
  {
    name: 'ProForma ADS & Rent',
    values: [],
    type: 'currency',
    bold: true,
    bgColor: '#EFEFEF',
    colspan: 1000,
  },
  {
    name: 'Interest Expense - ProForma',
    values: [137_010, 238_106, 467_448, 220_524, 207_275, 454_199],
    type: 'currency',
    bold: false,
  },

  {
    name: 'Principal Payments - ProForma',
    values: [0, 137_370, 422_605, 0, 0, 422_605],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Rent Expense',
    values: [250_290, 394_370, 282_811, 165_412, 220_127, 337_527],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Net New Cash Charges',
    values: [387_300, 769_846, 1_172_864, 385_936, 427_403, 1_214_331],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Excess Cash Flow - Historical',
    values: [1_543_405, -318_194, -718_157, 195_201, 152_419, -760_939],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Excess Cash Flow - ProForma',
    values: [1_543_405, -318_194, -718_157, 195_201, 152_419, -760_939],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Cash Flow Coverage Ratios - Historical',
    values: [],
    type: 'currency',
    bold: true,
    bgColor: '#EFEFEF',
    colspan: 1000,
  },
  {
    name: 'Interest Coverage Ratio (1)',
    values: [16.39, 4.77, 0.67, 1.93, 1.83, 0.58],
    type: 'decimal',
    bold: false,
  },
  {
    name: 'Intermediate Debt Service Coverage Ratio (2)',
    values: [12.26, 0.16, 0.21, 1.89, 1.74, 0.15],
    type: 'decimal',
    bold: false,
  },
  {
    name: 'Fixed Charge Coverage Ratio (3)',
    values: [4.99, 0.59, 0.39, 1.51, 1.36, 0.37],
    type: 'decimal',
    bold: false,
  },
];

const headers = [
  { text: '', width: '30%' },
  { text: ['FYE', 'Reviewed', '12/31/2021'], width: '13%' },
  { text: ['FYE', 'Reviewed', '12/31/2022'], width: '13%' },
  { text: ['FYE', 'Reviewed', '12/31/2023'], width: '13%' },
  { text: ['YTD', 'Co Prep', '06/30/2023'], width: '13%' },
  { text: ['YTD', 'Co Prep', '06/30/2024'], width: '13%' },
  { text: ['TTM', '-', '06/30/2024'], width: '13%' },
];

export const FirstBankBusinessCashFlow = () => {
  const rows: CellContent[][] = dataRows.map((item) => [
    {
      value: item.name,
      bold: item.bold,
      bgColor: item.bgColor,
      colspan: item.colspan,
    },
    ...item.values.map((value) => ({
      value,
      type: item.type,
      align: 'right' as TableCellProps['align'],
      bold: item.bold,
      bgColor: item.bgColor,
      colspan: item.colspan,
    })),
  ]);

  return (
    <SegmentContainer bordered={false}>
      <CreditMemoTable headers={headers} rows={[...rows]} />
      <Text variant={'body3'} color={'secondary'}>
        {'(1) Interest Coverage Ratio defined as EBITA divided by LTM Interest Expense.'}
      </Text>
      <Text variant={'body3'} color={'secondary'}>
        {
          '(2)  The Intermediate Debt Service Coverage Ratio is calculated consistent with C&I Risk Rating Guidance:  (EBITDA - Cash Taxes Paid/Distributed) / (Prin Paid + Interest)'
        }
      </Text>
      <Text variant={'body3'} color={'secondary'}>
        {
          '(3)  The Fixed Charge Coverage Ratio is calculated consistent with C&I Risk Rating Guidance:  (EBITDAR - Unfinanced Capex - Cash Taxes - Distributions) / (Prin Paid + Interest + Rents)'
        }
      </Text>
    </SegmentContainer>
  );
};
