import React from 'react';
import type { FC } from 'react';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { CreatePersonAuditLog } from './CreatePersonAuditLog';

export const PersonAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'Update') {
    return <UpdateEntityAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Create') {
    return <CreatePersonAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  return null;
};
