import { getApprovalFlow } from '@lama/approval-flows';
import type { OpportunityApiModel } from '@lama/clients';
import type { ProductApiModel } from '@lama/contracts';
import type { User } from '@lama/user-service-client';

export const isUserDecisionMaker = ({
  userId,
  users,
  opportunity,
  partnerId,
  product,
}: {
  userId: string;
  users: User[];
  opportunity: OpportunityApiModel;
  partnerId: string;
  product?: ProductApiModel;
}) => {
  const currentUser = users?.find((user) => user.id === userId);
  const approvingRoles = getApprovalFlow(partnerId)?.({ opportunity, product }) ?? [];

  return approvingRoles.some((role) => currentUser?.roles.includes(role));
};
