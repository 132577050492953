import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { DealApiModel } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useGetDeal = (dealId: string | undefined, partner: string | undefined, options?: UseQueryOptions<DealApiModel | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getDealQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!partner || !dealId) {
      return null;
    }

    return applicationServiceClient.getDeal({ dealId, partnerId: partner, token });
  }, [dealId, getAccessTokenSilently, partner]);

  const query = useQuery<DealApiModel | null>({ queryKey: ['deal', dealId, partner], queryFn: getDealQueryFunction, ...options });

  return query;
};
