import React, { useContext, useMemo } from 'react';
import { sumBy } from 'lodash-es';
import type { CellContent } from '../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../ApplicationContext';
import { totalRow } from '../creditMemoTable/creditMemoTableRowCreator';

const headers = [
  { text: 'Use of Funds', width: 30 },
  { text: 'Loan', width: 14 },
  { text: 'Equity', width: 14 },
  { text: 'Down Payment or Money Invested into the business ', width: 60 },
  { text: 'Seller Financing', width: 14 },
  { text: 'Total', width: 14 },
];

export const HanoverUoFSummaryTable = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const uofRows = (application.useOfFundsBreakdown ?? []).map<CellContent[]>((item) => [
      { value: item.type },
      { value: item.amount, type: 'currency', align: 'right' },
      { value: 0, type: 'currency', align: 'right' },
      { value: 0, type: 'currency', align: 'right' },
      { value: 0, type: 'currency', align: 'right' },
      { value: item.amount, type: 'currency', align: 'right' },
    ]);

    const totalsRowCellValues: CellContent[] = [
      { value: sumBy(application.useOfFundsBreakdown ?? [], 'amount'), type: 'currency', align: 'right' },
      { value: 0, align: 'right', type: 'currency' },
      { value: 0, align: 'right', type: 'currency' },
      { value: 0, align: 'right', type: 'currency' },
      { value: sumBy(application.useOfFundsBreakdown ?? [], 'amount'), type: 'currency', align: 'right' },
    ];

    const totalRowDefinition = totalRow({ cells: totalsRowCellValues });

    return [...uofRows, totalRowDefinition];
  }, [application]);

  return <CreditMemoTable headers={headers} rows={rows} />;
};
