import type { ApplicationStatus, DecisionReason } from '@lama/contracts';

export const filterReasonsForStatus = (reasons: DecisionReason[], status: ApplicationStatus[]) =>
  reasons.filter((reason) => reason.relevantStatuses.some((relevantStatus) => status.includes(relevantStatus)));

export const defaultDecisionReasons: DecisionReason[] = [
  {
    id: 'GUID-1',
    code: '1',
    relevantStatuses: ['Rejected'],
    text: 'Application does not meet minimum financial analysis requirements',
    type: 'General',
  },
  {
    id: 'GUID-2',
    code: '2',
    relevantStatuses: ['Rejected'],
    text: 'Application not able to be processed due to indications of ineligibility',
    type: 'General',
  },
  {
    id: 'GUID-3',
    code: '3',
    relevantStatuses: ['Rejected'],
    text: 'At least one primary business owner does not meet SBA eligibility requirements',
    type: 'General',
  },
  {
    id: 'GUID-4',
    code: '4',
    relevantStatuses: ['Rejected'],
    text: "At least one primary business owner's credit is frozen so we were unable to assess their credit",
    type: 'Credit',
  },
  {
    id: 'GUID-5',
    code: '5',
    relevantStatuses: ['Rejected'],
    text: "At least one primary business owner's personal credit score doesn't meet the required minimum",
    type: 'Credit',
  },
  {
    id: 'GUID-6',
    code: '6',
    relevantStatuses: ['Rejected'],
    text: 'Multiple business owners personal credit scores do not meet the minimum average',
    type: 'Credit',
  },
  {
    id: 'GUID-7',
    code: '7',
    relevantStatuses: ['Rejected'],
    text: 'Business credit score does not meet the required minimum',
    type: 'Credit',
  },
  {
    id: 'GUID-8',
    code: '8',
    relevantStatuses: ['Rejected'],
    text: 'Business has not been established for the minimum number of years',
    type: 'General',
  },
  {
    id: 'GUID-9',
    code: '9',
    relevantStatuses: ['Rejected'],
    text: 'Business is in an ineligible industry',
    type: 'General',
  },
  {
    id: 'GUID-10',
    code: '10',
    relevantStatuses: ['Rejected'],
    text: 'Business is not in good standing with IRS',
    type: 'General',
  },
  {
    id: 'GUID-11',
    code: '11',
    relevantStatuses: ['Rejected'],
    text: "Business located outside of the Bank's lending area",
    type: 'General',
  },
  {
    id: 'GUID-12',
    code: '12',
    relevantStatuses: ['Rejected'],
    text: 'Delinquency on existing or prior government backed loans',
    type: 'General',
  },
  {
    id: 'GUID-13',
    code: '13',
    relevantStatuses: ['Rejected'],
    text: 'Does not pass SBA credit scoring system (ETRAN)',
    type: 'Credit',
  },
  {
    id: 'GUID-14',
    code: '14',
    relevantStatuses: ['Rejected'],
    text: 'Fraud Alert on Credit Report that is not cleared',
    type: 'Credit',
  },
  {
    id: 'GUID-15',
    code: '15',
    relevantStatuses: ['Rejected'],
    text: 'Guarantor unwilling to personally guarantee loan',
    type: 'General',
  },
  {
    id: 'GUID-16',
    code: '16',
    relevantStatuses: ['Rejected'],
    text: "Information on the tax documents is inaccurate and/or required fields are blank or don't reconcile between business/personal filing",
    type: 'General',
  },
  {
    id: 'GUID-17',
    code: '17',
    relevantStatuses: ['Rejected'],
    text: 'Unable to obtain personal credit report',
    type: 'Credit',
  },
  {
    id: 'GUID-18',
    code: '18',
    relevantStatuses: ['Rejected'],
    text: 'Insufficient business cash flow and ability to repay all business debts',
    type: 'General',
  },
  {
    id: 'GUID-19',
    code: '19',
    relevantStatuses: ['Rejected'],
    text: 'Insufficient cash flow and ability to repay all business and personal debts',
    type: 'General',
  },
  {
    id: 'GUID-20',
    code: '20',
    relevantStatuses: ['Rejected'],
    text: 'Tax returns not filed by deadline - Business',
    type: 'General',
  },
  {
    id: 'GUID-21',
    code: '21',
    relevantStatuses: ['Rejected'],
    text: 'Tax returns not filed by deadline - Guarantor',
    type: 'General',
  },
  {
    id: 'GUID-22',
    code: '22',
    relevantStatuses: ['Rejected'],
    text: 'Unable to get documentation',
    type: 'General',
  },
  {
    id: 'GUID-23',
    code: '23',
    relevantStatuses: ['Rejected'],
    text: 'Unable to get Tax Returns and/or Transcripts',
    type: 'General',
  },
  {
    id: 'GUID-24',
    code: '24',
    relevantStatuses: ['Rejected'],
    text: 'Unacceptable guarantor public record found',
    type: 'General',
  },
  {
    id: 'GUID-25',
    code: '25',
    relevantStatuses: ['Rejected'],
    text: 'Unresponsive applicant',
    type: 'General',
  },
  {
    id: 'GUID-26',
    code: '26',
    relevantStatuses: ['ExpresslyWithdrawn'],
    text: 'Duplicate Application',
    type: 'General',
  },
  {
    id: 'GUID-27',
    code: '27',
    relevantStatuses: ['ExpresslyWithdrawn'],
    text: 'Test Application',
    type: 'General',
  },
  {
    id: 'GUID-28',
    code: '28',
    relevantStatuses: ['ExpresslyWithdrawn'],
    text: 'Application started but not submitted',
    type: 'General',
  },
  {
    id: 'GUID-29',
    code: '29',
    relevantStatuses: ['ExpresslyWithdrawn'],
    text: 'Applicant requested to be withdrawn',
    type: 'General',
  },
  {
    id: 'GUID-30',
    code: '30',
    relevantStatuses: ['ExpresslyWithdrawn'],
    text: 'Applicant transferred to another department for processing',
    type: 'General',
  },
];
