/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import CommentsEmptyState from './assets/CommentsEmptyState.svg';
import ExceptionsEmptyState from './assets/ExceptionsEmptyState.svg';
import type { NoteFilterOptions } from './NotesPage';

interface EmptyNotesPageProps {
  filterType: NoteFilterOptions;
}

const filterTypeToEmptyText: Record<NoteFilterOptions, string> = {
  comments: 'Nobody commented on this application yet.',
  'flags-and-exceptions': 'No flags or exceptions were added to this application yet.',
};

const emptyStateImageByType: Record<NoteFilterOptions, React.FC> = {
  comments: CommentsEmptyState,
  'flags-and-exceptions': ExceptionsEmptyState,
};

export const EmptyNotesPage: FC<EmptyNotesPageProps> = ({ filterType }) => {
  const EmptyStateImage = emptyStateImageByType[filterType];
  return (
    <Flex flex={1} flexDirection={'column'} gap={4} justifyContent={'center'} alignItems={'center'}>
      <EmptyStateImage />
      <Text variant={'body2'} color={'secondary'}>
        {filterTypeToEmptyText[filterType]}
      </Text>
    </Flex>
  );
};
