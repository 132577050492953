/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { FormikAutoSave, FormikDatePicker, FormikMoneyInputField, FormikMultiPicker, PropertyFormikInput } from '@lama/app-components';
import { Button, Flex, greyPalette, Text } from '@lama/design-system';
import type { FC } from 'react';
import { Formik } from 'formik';
import { industryTypes } from '@lama/contracts';
import * as yup from 'yup';
import { Box } from '@mui/material';
import { SegmentContainer } from '../Application/shared/SegmentContainer';
import { FormikInputAndSlider } from './FormikInputAndSlider';

export interface SimulationValues {
  requestedAmount: string;
  termInMonths: string;
  industry: string[];
  businessAnnualCashAvailable: string;
  businessAnnualDebtService: string;
  businessStartDate: string;
  personalCreditScore: string;
  personalAnnualCashAvailable: string;
  personalAnnualDebtService: string;
  requestedRateType: string;
  requestedRate: string;
}

const initialValues: SimulationValues = {
  requestedAmount: '',
  termInMonths: '',
  industry: [],
  businessAnnualCashAvailable: '',
  businessAnnualDebtService: '',
  businessStartDate: '',
  personalCreditScore: '',
  personalAnnualCashAvailable: '',
  personalAnnualDebtService: '',
  requestedRateType: 'Fixed',
  requestedRate: '',
};

const validationSchema = yup.object({
  requestedAmount: yup.number().required('Required'),
  termInMonths: yup.number().required('Required'),
  industry: yup.array().required('Required'),
  businessAnnualCashAvailable: yup.number().required('Required'),
  businessAnnualDebtService: yup.number().required('Required'),
  personalCreditScore: yup.number().min(300).max(850).required('Required'),
  personalAnnualCashAvailable: yup.number().required('Required'),
  personalAnnualDebtService: yup.number().required('Required'),
});

export const SimulationForm: FC<{ onSubmit: (values: SimulationValues) => void; onClear: () => void }> = ({ onSubmit, onClear }) => {
  const onSubmitInternal = useCallback(
    async (values: SimulationValues) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const onClearInternal = useCallback(
    (resetForm: () => void) => {
      resetForm();
      onClear();
    },
    [onClear],
  );

  return (
    <Box bgcolor={greyPalette[50]}>
      <Formik initialValues={initialValues} onSubmit={onSubmitInternal} validationSchema={validationSchema}>
        {({ resetForm }) => (
          <>
            <SegmentContainer>
              <Flex gap={4} flexDirection={'column'} width={'100%'}>
                <Flex flexDirection={'column'} gap={4}>
                  <Flex flexDirection={'column'} gap={4}>
                    <Text variant={'body1'}>{'Requested Loan Details'}</Text>
                    <FormikInputAndSlider
                      minAmount={0}
                      maxAmount={35_000_000}
                      sliderStep={50_000}
                      type={'currency'}
                      name={'requestedAmount'}
                      label={'Requested Amount'}
                    />
                    <FormikInputAndSlider minAmount={0} maxAmount={150} type={'number'} name={'termInMonths'} label={'Term (months)'} />
                    <FormikInputAndSlider minAmount={0} maxAmount={20} type={'percentage'} name={'requestedRate'} label={'Rate'} />
                  </Flex>
                  <Flex flexDirection={'column'} gap={4}>
                    <Text variant={'body1'}>{'Busines Details'}</Text>
                    <FormikMultiPicker name={'industry'} label={'Industry'} options={industryTypes} />
                    <FormikMoneyInputField name={'businessAnnualCashAvailable'} label={'Annual Cash Available'} />
                    <FormikMoneyInputField name={'businessAnnualDebtService'} label={'Annual Debt Service'} />
                    <FormikDatePicker name={'businessStartDate'} label={'Business Start Date'} />
                  </Flex>
                  <Flex flexDirection={'column'} gap={4}>
                    <Text variant={'body1'}>{'Owner Details'}</Text>
                    <PropertyFormikInput name={'personalCreditScore'} label={'Credit Score'} type={'number'} />
                    <FormikMoneyInputField name={'personalAnnualCashAvailable'} label={'Annual Cash Available'} />
                    <FormikMoneyInputField name={'personalAnnualDebtService'} label={'Annual Debt Service'} />
                  </Flex>
                </Flex>
                <Flex justifyContent={'flex-end'} pt={8}>
                  <Button
                    variant={'secondary'}
                    onClick={() => {
                      onClearInternal(resetForm);
                    }}
                    color={'neutral'}
                  >
                    {'Clear'}
                  </Button>
                </Flex>
              </Flex>
            </SegmentContainer>
            <FormikAutoSave />
          </>
        )}
      </Formik>
    </Box>
  );
};
