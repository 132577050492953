import React from 'react';
import type { FC } from 'react';
import { useAsyncFn } from 'react-use';
import { Button, Flex, Modal, ModalContent, ModalFooter, Text } from '@lama/design-system';

interface SendEmailDialogProps {
  title: string;
  message?: string;
  open: boolean;
  onClose: () => void;
  sendEmail: ({ preview }: { preview: boolean }) => Promise<void>;
}

export const SendEmailDialog: FC<SendEmailDialogProps> = ({ title, message, sendEmail, open, onClose }) => {
  const [{ loading: sendingToApplicants }, onEmailApplicants] = useAsyncFn(async () => {
    await sendEmail({ preview: false });
    onClose();
  }, [onClose, sendEmail]);

  const [{ loading: sendingPreview }, onPreviewEmail] = useAsyncFn(async () => {
    await sendEmail({ preview: true });
    onClose();
  }, [onClose, sendEmail]);

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <ModalContent>
        <Text variant={'body1'}>{message ?? 'Are you sure you want to email the borrower? This action cannot be reversed.'}</Text>
      </ModalContent>
      <ModalFooter>
        <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button variant={'tertiary'} color={'neutral'} disabled={sendingPreview || sendingToApplicants} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Flex flexDirection={'row'} gap={2}>
            <Button variant={'tertiary'} color={'neutral'} onClick={onPreviewEmail} loading={sendingPreview} disabled={sendingToApplicants}>
              {'Email Me a Preview'}
            </Button>
            <Button variant={'primary'} onClick={onEmailApplicants} loading={sendingToApplicants} disabled={sendingPreview}>
              {'Email Applicants'}
            </Button>
          </Flex>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
