import React from 'react';
import type { FC } from 'react';
import { getAssetUrl } from '../../utils/getAssetUrl';

interface PartnerImageProps {
  partner: string;
  size?: string;
}

const partnerLogosBaseUrl = getAssetUrl('partner-logos');

export const PartnerImage: FC<PartnerImageProps> = ({ partner, size = '18px' }) => (
  <img style={{ maxHeight: size, maxWidth: size }} src={`${partnerLogosBaseUrl}/${partner.toLowerCase()}_small.png`} alt={''} />
);
