import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { DealApiModel } from '@lama/contracts';
import { Box, Stack, Typography } from '@mui/material';
import { sentenceCase } from 'change-case-all';
import { usdFormatCompact } from '@lama/data-formatters';
import type { DealInfoLineProps } from '../DealBoard/DealInfoLine';
import { DealInfoLine } from '../DealBoard/DealInfoLine';
import { MonetizationOn, Store, Location } from '../../../shared/components/Icons';
import { getDealName } from '../helpers';
import { InterestedMark } from '../InterestedMark';
import { getShortAddressInfoLine } from '../../../shared/utils/informationLineUtils';
import { DismissedMark } from './DismissedMark';
import { FullDealHeaderImage } from './FullDealHeader/FullDealHeaderImage';
import { HeaderButtons } from './FullDealHeader/HeaderButtons';
import { ContactUs } from './FullDealHeader/ContactUs';
import { SimilarDeals } from './FullDealHeader/SimilarDeals';

const infoLines = (deal: DealApiModel): DealInfoLineProps[] => [
  {
    icon: MonetizationOn,
    label: 'Requested amount',
    values: deal.requestedAmount ? usdFormatCompact.format(deal.requestedAmount) : [],
    type: 'item' as const,
  },
  {
    icon: Location,
    label: 'Location',
    values: getShortAddressInfoLine(deal.address),
    type: 'item' as const,
  },
  {
    icon: Store,
    label: 'Industry types',
    type: 'array' as const,
    values: deal.industryTypes?.map((value: string) => sentenceCase(value)) ?? ['-'],
  },
];

export const FullDealHeader: FC<{ deal: DealApiModel }> = ({ deal }) => {
  const interested = !!deal.liked;
  const dismissed = !!deal.dismissed;
  const name = useMemo(() => getDealName(deal), [deal]);

  return (
    <Stack spacing={2}>
      <Stack spacing={7} direction={'row'} mt={0}>
        <Stack flex={0.65} spacing={4}>
          <FullDealHeaderImage image={deal.image} />
          <Typography variant={'body2'}>{deal.businessDescription}</Typography>
        </Stack>
        <Stack flex={0.35} justifyContent={'center'}>
          <Stack direction={'row'} spacing={2}>
            <Typography variant={'h5'}>{name}</Typography>
            {interested ? <InterestedMark /> : dismissed ? <DismissedMark /> : null}
          </Stack>
          <Stack pt={3} pb={7} spacing={2}>
            {infoLines(deal).map((line) => (
              <DealInfoLine key={line.label} {...line} />
            ))}
          </Stack>
          <HeaderButtons interested={interested} dismissed={dismissed} deal={deal} />
        </Stack>
      </Stack>
      <Stack spacing={7} direction={'row'}>
        <Box flex={0.65}>
          <ContactUs name={name} />
        </Box>
        <Box flex={0.35}>
          <SimilarDeals interested={interested} dismissed={dismissed} />
        </Box>
      </Stack>
    </Stack>
  );
};
