import type { SearchStatusResult } from '@lama/contracts';
import { redPalette } from '@lama/design-system';
import { t } from 'i18next';

export const ofacStatusToLabel = (status?: SearchStatusResult): { text: string; color?: string } => {
  switch (status) {
    case 'Not Found': {
      return { text: t('ofacLookupResults.notFound') };
    }
    case 'Found': {
      return { text: t('ofacLookupResults.found'), color: redPalette[500] };
    }
    case 'Found and Cleared': {
      return { text: t('ofacLookupResults.foundAndCleared'), color: redPalette[500] };
    }
    default: {
      return { text: t('ofacLookupResults.unknown'), color: redPalette[500] };
    }
  }
};
