import React, { useContext, useCallback } from 'react';
import type { FC } from 'react';
import { Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Tooltip } from '@lama/app-components';
import { greyPalette } from '@lama/design-system';
import { ApplicationSearchContext } from './ApplicationSearchContext';

export const SearchButton: FC = () => {
  const { modalOpen, setModalOpen } = useContext(ApplicationSearchContext);

  const onSearchClick = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, [setModalOpen]);

  return (
    <Tooltip title={'Search'}>
      <IconButton
        onClick={onSearchClick}
        sx={{
          padding: '4px',
          borderRadius: '8px',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': { bgcolor: greyPalette[100] },
          bgcolor: modalOpen ? greyPalette[100] : undefined,
          color: '#1A1523',
        }}
        role={'button'}
        disableRipple
      >
        <Search height={'20px'} width={'20px'} />
      </IconButton>
    </Tooltip>
  );
};
