import React, { useMemo } from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import type { FC } from 'react';
import {
  combinedLatestTangibleNetWorthSelector,
  combinedTwoYearAverageNetIncome,
  yearsDiffFromReferenceYearSelector,
} from '@lama/selectors';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';

const headers = [
  { text: 'Borrower(s)', width: 40 },
  { text: 'Maximum', width: 30 },
  { text: 'Actual', width: 30 },
];

export const SizeStandardTable: FC<{ application: ApplicationApiModel; referenceYear: number }> = ({ application, referenceYear }) => {
  const yearsBack = useMemo(() => yearsDiffFromReferenceYearSelector(referenceYear), [referenceYear]);

  const twoYearsAverageNetIncome = useMemo(
    () => combinedTwoYearAverageNetIncome({ application, entity: application, entityType: 'application', yearsBack }),
    [application, yearsBack],
  );

  const tangibleNetWorth = useMemo(
    () => combinedLatestTangibleNetWorthSelector({ application, entity: application, entityType: 'application', yearsBack: 0 }),
    [application],
  );

  const rows = useMemo<CellContent[][]>(
    () => [
      [
        { value: '2-Year Average Net Income' },
        { value: 6_500_000, type: 'currency' },
        { value: twoYearsAverageNetIncome ?? '', type: 'currency' },
      ],
      [{ value: 'Tangible Net Worth' }, { value: 20_000_000, type: 'currency' }, { value: tangibleNetWorth ?? '', type: 'currency' }],
    ],
    [tangibleNetWorth, twoYearsAverageNetIncome],
  );
  return <CreditMemoTable headers={headers} rows={rows} />;
};
