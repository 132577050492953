import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Stack, Box } from '@mui/material';
import type { AccountData } from '@lama/contracts';
import { sortBy } from 'lodash-es';
import { add, format, isBefore } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { ChartPagination } from '../../../../../shared/components/ChartPagination';
import type { Granularity } from '../../types';
import { EmptyData } from './EmptyData';
import { TransactionsChart } from './Transactions/TransactionsChart';
import { getGranulatedTransactionsData } from './Transactions/helpers';
import { getFirstAndLastDates, getFirstAndLastPaddedDates, getPageCountByGranularity } from './helpers';

export const Transactions: FC<{ transactions: AccountData['transactions']; granularity: Granularity }> = ({
  transactions,
  granularity,
}) => {
  const [page, setPage] = useState(1);

  const { first: firstTransactionDate, last: lastTransactionDate } = useMemo(() => {
    const { first, last } = getFirstAndLastDates(transactions);
    const { firstPadded, lastPadded } = getFirstAndLastPaddedDates(first, last, granularity);

    return { first: firstPadded, last: lastPadded };
  }, [granularity, transactions]);

  const paddedTransactions = useMemo(() => {
    const accountId = transactions[0]?.accountId;

    if (!accountId) {
      return [];
    }

    let currentDate = new Date(firstTransactionDate);
    const padded: AccountData['transactions'] = [...transactions];

    while (isBefore(currentDate, lastTransactionDate)) {
      padded.push({
        accountId,
        transactionId: uuidv4(),
        pending: false,
        date: format(currentDate, 'MM/dd/yyyy'),
        amount: 0,
        isoCurrencyCode: null,
      });
      currentDate = add(currentDate, { days: 1 });
    }

    return padded;
  }, [firstTransactionDate, lastTransactionDate, transactions]);

  const pageCount = useMemo(
    () => getPageCountByGranularity(granularity, firstTransactionDate, lastTransactionDate),
    [lastTransactionDate, granularity, firstTransactionDate],
  );

  const data = useMemo(() => {
    const granulatedData = getGranulatedTransactionsData(paddedTransactions, lastTransactionDate, granularity, page);

    return sortBy(granulatedData, (datum) => new Date(datum.date));
  }, [granularity, paddedTransactions, lastTransactionDate, page]);

  const granularityFormat = useMemo(
    () => (granularity === 'week' ? 'MM/dd/yy' : granularity === 'month' ? 'MMM yy' : 'yyyy'),
    [granularity],
  );

  const onPageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [setPage],
  );

  useEffect(() => {
    setPage(1);
  }, [setPage, granularity]);

  return (
    <Stack spacing={3} alignItems={'center'}>
      <Box height={'350px'} width={'90%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {data.length ? <TransactionsChart data={data} granularityFormat={granularityFormat} /> : <EmptyData />}
      </Box>
      <ChartPagination pageCount={pageCount} page={page} onPageChange={onPageChange} />
    </Stack>
  );
};
