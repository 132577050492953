import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Flex, greyPalette } from '@lama/design-system';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { RequirementsByCategory } from '@lama/app-components';
import { SideNavContainer } from '../../../../shared/components/PageSideNav/SideNavContainer';
import { SideNavFilterButton } from '../../../../shared/components/PageSideNav/SideNavFilterButton';
import { SideNavEmptyState } from '../../../../shared/components/SideNav/SideNavEmptyState';
import { RequirementCategoryListItem } from './RequirementCategoryListItem';
import type { RequirementFilter } from './OpportunityRequirementsPage';

interface RequirementsSideNavProps {
  requirementsByCategory: RequirementsByCategory<EvaluatedOpportunityRequirement>[];
  filter: RequirementFilter;
  setFilter: (filter: RequirementFilter) => void;
}

export const RequirementsSideNav: FC<RequirementsSideNavProps> = ({ requirementsByCategory, filter, setFilter }) => {
  const requirements = useMemo(
    () => requirementsByCategory.flatMap(({ requirements: categoryRequirements }) => categoryRequirements),
    [requirementsByCategory],
  );

  const filteredRequirementsByCategory = useMemo(
    () =>
      requirementsByCategory
        .map(({ requirements: categoryRequirements, ...rest }) => ({
          ...rest,
          requirements: categoryRequirements.filter((r) => {
            if (filter === 'pending') {
              return r.status === 'PendingBorrower' || r.status === 'PendingReview';
            }

            if (filter === 'review') {
              return r.status === 'Complete';
            }

            return true;
          }),
        }))
        .filter(({ requirements: categoryRequirements }) => categoryRequirements.length),
    [filter, requirementsByCategory],
  );

  const reviewRequirements = useMemo(() => requirements.filter((r) => r.status === 'Complete'), [requirements]);

  const pendingRequirements = useMemo(
    () => requirements.filter((r) => r.status === 'PendingBorrower' || r.status === 'PendingReview'),
    [requirements],
  );

  const onFilterClick = useCallback(
    (filterValue: string) => {
      setFilter(filterValue as RequirementFilter);
    },
    [setFilter],
  );

  return (
    <SideNavContainer>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        gap={2}
        px={2}
        py={3}
        borderBottom={`1px solid ${greyPalette[200]}`}
      >
        <SideNavFilterButton
          text={'Pending'}
          count={pendingRequirements.length}
          isSelected={filter === 'pending'}
          filterValue={'pending'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'Review'}
          count={reviewRequirements.length}
          isSelected={filter === 'review'}
          filterValue={'review'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'All'}
          count={requirements.length}
          isSelected={filter === 'all'}
          filterValue={'all'}
          onClick={onFilterClick}
        />
      </Flex>
      {filteredRequirementsByCategory.length ? (
        filteredRequirementsByCategory.map((requirementCategoryItem) => (
          <RequirementCategoryListItem key={requirementCategoryItem.categoryId} requirementsByCategory={requirementCategoryItem} />
        ))
      ) : (
        <SideNavEmptyState />
      )}
    </SideNavContainer>
  );
};
