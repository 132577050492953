/* eslint-disable react/jsx-no-leaked-render */
import type { FC, ReactNode } from 'react';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { grey } from '@mui/material/colors';
import type { UnderwritingNote } from '@lama/contracts';
import type { InputValue } from '@lama/design-system';
import { Flex, RichInput, Avatar, greyPalette, Text, Collapse } from '@lama/design-system';
import { personFullName } from '@lama/data-formatters';
import { v4 as uuidv4 } from 'uuid';
import { useToggle } from 'react-use';
import { sortBy } from 'lodash-es';
import type { UnderwritingNoteCreateModel } from '@lama/clients';
import mixpanel from 'mixpanel-browser';

import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useCreateNoteMutation } from '../../hooks/react-query/opportunity/useCreateNoteMutation';
import type { UnderwritingNoteRelatedSectionType } from '../../../components/Application/shared/noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../../../components/Application/shared/noteUtils';
import ExpandButton from '../ExpandButton';
import { useGetPartnerQuery } from '../../hooks/react-query/partner/useGetPartnerQuery';
import { useUserSuggestions } from './hooks/useUserSuggestions';
import { Comment } from './Comment';

interface ThreadProps {
  threadNotes: UnderwritingNote[];
  threadId: string;
  relatedItemType?: UnderwritingNoteRelatedSectionType;
  relatedItemId?: string;
  threadTitleSuffixComponent?: ReactNode;
}

export const Thread: FC<ThreadProps> = ({ threadNotes = [], threadId, relatedItemId, relatedItemType, threadTitleSuffixComponent }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);
  const { userId, userData: commentUser } = useContext(UserDetailsContext);
  const userSuggestions = useUserSuggestions();
  const { mutateAsync: createNote } = useCreateNoteMutation(opportunity.id, userId);
  const [firstComment, ...restOfComments] = useMemo(() => sortBy(threadNotes, 'createdAt'), [threadNotes]);
  const [isReplyEditMode, toggleReplyEditMode] = useToggle(false);
  const { enableCommentMentions, enableReplies } = useMemo(() => partner?.featureConfigurations || {}, [partner]);
  const replyToThreadRef = useRef<HTMLDivElement>(null);

  const isUrlCommentInThisThread = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const urlCommentId = params.get('commentId');

    return threadNotes.some((comment) => comment.id === urlCommentId);
  }, [threadNotes]);

  const [showReplies, toggleShowReplies] = useToggle(isUrlCommentInThisThread || restOfComments.length < 2);

  const onToggleReplies = useCallback(() => {
    toggleShowReplies();
  }, [toggleShowReplies]);

  const onReply = useCallback(
    async (change: InputValue) => {
      if (!change.value || !userId) {
        return;
      }

      const fullPath = `${location.pathname}${location.search}`;

      const note: UnderwritingNoteCreateModel = {
        type: 'general',
        text: change.value,
        mentionedUserIds: change.mentionedIds,
        id: uuidv4(),
        url: fullPath,
        threadId,
        ...(relatedItemId && relatedItemType && { [getUnderwritingNoteRelatedItemProperty(relatedItemType)]: relatedItemId }),
      };

      await createNote(note);

      if (!showReplies) {
        onToggleReplies();
      }
      mixpanel.track('Reply to Thread - Create', {
        threadId: note.threadId,
        mentionedUserIds: change.mentionedIds,
        mentionedUserCount: change.mentionedIds?.length,
        commentId: note.id,
        creator: userId,
      });
    },
    [createNote, onToggleReplies, relatedItemId, relatedItemType, showReplies, threadId, userId],
  );

  return (
    <Flex flexDirection={'column'} borderRadius={'4px'} borderBottom={!enableReplies ? `1px solid ${greyPalette[300]}` : 'none'}>
      {firstComment && (
        <Comment opportunityId={opportunity.id} comment={firstComment} titleSuffix={threadTitleSuffixComponent} rootComment />
      )}
      {restOfComments.length > 0 && (
        <Flex
          px={4}
          onClick={onToggleReplies}
          flexDirection={'row'}
          gap={2}
          flex={1}
          alignItems={'center'}
          pb={showReplies ? 2 : 3}
          borderLeft={'1px solid #E0E0E0'}
          borderRight={'1px solid #E0E0E0'}
        >
          <ExpandButton size={20} expand={showReplies} />
          <Flex flex={1} gap={4} alignItems={'center'}>
            <Text variant={'body3'} color={'secondary'}>
              {restOfComments.length} {'Replies'}
            </Text>
            {showReplies && <Flex borderBottom={'1px solid #E0E0E0'} flex={1} />}
          </Flex>
        </Flex>
      )}
      <Collapse expanded={showReplies}>
        {restOfComments.map((note, _) => (
          <Comment opportunityId={opportunity.id} comment={note} key={note.id} />
        ))}
      </Collapse>
      {enableReplies && (
        <Flex
          flexDirection={'row'}
          gap={2}
          px={4}
          pt={3}
          pb={3}
          alignItems={isReplyEditMode ? 'flex-start' : 'center'}
          justifyContent={'center'}
          border={`1px solid ${grey[300]}`}
          borderBottomRightRadius={'8px'}
          borderBottomLeftRadius={'8px'}
          ref={replyToThreadRef}
        >
          <Flex alignItems={'center'}>{commentUser ? <Avatar size={'24px'} name={personFullName(commentUser)} /> : null}</Flex>
          <RichInput
            suggestions={userSuggestions}
            placeholder={'Reply'}
            toggleEditMode={toggleReplyEditMode}
            editMode={isReplyEditMode}
            onSubmit={onReply}
            currentUserId={userId}
            allowEditing
            enableMentions={enableCommentMentions === null ? undefined : enableCommentMentions}
          />
        </Flex>
      )}
    </Flex>
  );
};
