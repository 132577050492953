import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { productServiceClient } from '../../../../../shared/clients/productService';

interface DeleteConditionParams {
  conditionId: string;
}

export const useDeleteConditionMutation = (productId?: string, options?: UseMutationOptions<void, unknown, DeleteConditionParams>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ conditionId }: DeleteConditionParams) => {
      const token = await getAccessTokenSilently();

      if (!productId) {
        return;
      }

      return productServiceClient.deleteCondition(productId, conditionId, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['product', productId] });
    },
    onError: async () => {
      toast.error('An error has occurred. Please contact support.');
    },
    ...options,
  });
};
