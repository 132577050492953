import React from 'react';
import type { InfoLineProps } from '@lama/app-components';
import { ConditionResultIcon } from '../components/conditions/conditionIcons';

export const fibtDemoKeyIndicators: InfoLineProps[] = [
  {
    label: 'BBB Rating',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'A+',
  },
  {
    label: 'FTC',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'No Action',
  },
  {
    label: 'FinCEN',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'No Match',
  },
  {
    label: 'FBOP',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'No Match',
  },
  {
    label: 'Background Check',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'No Red Flags',
  },
  {
    label: 'Negative News',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'No Negative News',
  },
  {
    label: 'SOS Status',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'Good Standing',
  },
  {
    label: 'OFAC Search Business',
    icon: <ConditionResultIcon reason={'fail'} size={'16px'} />,
    values: 'Found',
  },
  {
    label: 'OFAC Search Person',
    icon: <ConditionResultIcon reason={'cleared'} size={'16px'} />,
    values: 'Not Found',
  },
];
