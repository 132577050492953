import type { BusinessMetricsTableDatum } from '../components/BusinessMetrics/types';

export const data: BusinessMetricsTableDatum[] = [
  {
    id: 'Total Assets',
    twoYearsPriorFiscal: 2_500_776,
    priorFiscalYear: 3_227_765,
    ytd: 3_546_108,
  },
  {
    id: 'Cash & Cash Equivalents',
    twoYearsPriorFiscal: 24_207,
    priorFiscalYear: 29_504,
    ytd: 14_954,
  },
  {
    id: 'Accounts Receivable',
    twoYearsPriorFiscal: 0,
    priorFiscalYear: 0,
    ytd: 0,
  },
  {
    id: 'Short Term Debt',
    twoYearsPriorFiscal: 429_504,
    priorFiscalYear: 317_052,
    ytd: 278_971,
  },
  {
    id: 'Long Term Debt',
    twoYearsPriorFiscal: 969_251,
    priorFiscalYear: 530_764,
    ytd: 320_272,
  },
  {
    id: 'Revenue',
    twoYearsPriorFiscal: 2_387_404,
    priorFiscalYear: 3_513_539,
    ytd: 3_278_739,
  },
  {
    id: 'Cost of Goods Sold',
    twoYearsPriorFiscal: 624_603,
    priorFiscalYear: 957_281,
    ytd: 849_911,
  },
  {
    id: 'Taxes',
    twoYearsPriorFiscal: 0,
    priorFiscalYear: 0,
    ytd: 0,
  },
  {
    id: 'Net Income',
    twoYearsPriorFiscal: 77_677,
    priorFiscalYear: 1_072_510,
    ytd: 738_299,
  },
];
