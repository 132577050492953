import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useToggle } from 'react-use';
import { productServiceClient } from '../../../../shared/clients/productService';
import { ProductSettingsContext } from './ProductSettingsContext';

export const ProductTitleEdit = ({ productName }: { productName: string }) => {
  const [editMode, toggleEditMode] = useToggle(false);
  const [productTitle, setProductTitle] = useState(productName);
  const { getAccessTokenSilently } = useAuth0();
  const { registerSaveFn, setIsDirty, product } = useContext(ProductSettingsContext);

  const { mutate } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      return productServiceClient.updateProduct(product.id, { prettyName: productTitle }, token);
    },
  });

  const onTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProductTitle(e.target.value);
      setIsDirty(true);
    },
    [setIsDirty],
  );

  const onTextFieldBlur = useCallback(() => {
    toggleEditMode();
  }, [toggleEditMode]);

  useEffect(() => {
    registerSaveFn('productName', mutate);
  }, [mutate, registerSaveFn]);

  return (
    <Box width={'50%'} component={'div'}>
      {editMode ? (
        <TextField
          variant={'standard'}
          onChange={onTitleChange}
          value={productTitle}
          defaultValue={productTitle}
          onBlur={onTextFieldBlur}
          fullWidth
          autoFocus
        />
      ) : (
        <Typography onClick={toggleEditMode} variant={'h5'} noWrap>{`${productTitle ?? ''}`}</Typography>
      )}
    </Box>
  );
};
