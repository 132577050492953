import React from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';

const depositHeaders = [
  { text: 'Customer', width: 14 },
  { text: 'Balance', width: 14 },
  { text: 'Type', width: 30 },
  { text: 'Balance', width: 14 },
  { text: 'Origination Date', width: 14 },
];

const collateralHeaders = [
  { text: 'Collateral Type', width: 14 },
  { text: 'Pledged By', width: 14 },
  { text: 'Loan Name', width: 30 },
  { text: 'Senior Liens', width: 14 },
  { text: 'Lien', width: 14 },
  { text: 'Reported Value', width: 14 },
];

export const HanoverLoanOverviewSection: FC = () => (
  <Flex flexDirection={'column'} gap={8}>
    <InlineNote title={'Summary'} noteName={'Summary'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote
      title={'Equity Injection'}
      noteName={'Equity Injection'}
      valueTemplate={undefined}
      placeholder={'for Business Acquisition Only – Describe the source of the equity injection'}
    />
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'h6'}>{'Deposit Relationship'}</Text>
      <CreditMemoTable headers={depositHeaders} rows={[]} />
    </Flex>
    <InlineNote
      title={'Deposit Relationship Comments'}
      noteName={'Deposit Relationship Comments'}
      valueTemplate={undefined}
      placeholder={undefined}
    />
    <CreditMemoTable headers={collateralHeaders} rows={[]} />
    <InlineNote title={'Weaknesses & Strengths'} noteName={'Weaknesses & Strengths'} valueTemplate={undefined} placeholder={undefined} />
  </Flex>
);
