import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { DismissedFlagsCard } from '../../CreditMemo/Sections/components/Notes/ExceptionCard';

export const ExceptionsSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  const opportunityExceptions = useMemo(
    () => opportunity.underwriting?.notes?.filter((note) => note.type === 'exception') ?? [],
    [opportunity.underwriting?.notes],
  );

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body1'} color={greyPalette[500]}>
        {`Exceptions (${opportunityExceptions?.length})`}
      </Text>
      {opportunityExceptions?.length ? null : (
        <Text variant={'body1'} color={'text.secondary'}>
          {'You have no exceptions on this application.'}
        </Text>
      )}
      {opportunityExceptions?.map((exception) => (
        <DismissedFlagsCard key={exception.id} note={exception} />
      ))}
    </Flex>
  );
};
