import type { FC } from 'react';
import React from 'react';
import { useTheme, Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { capitalCase } from 'change-case-all';
import type { DataSource } from '../../shared/types/dataSource';

export const dataSourceAvatarsImages: Record<DataSource, string> = {
  experian: '/companyAvatars/experian.png',
  freshbooks: '/companyAvatars/freshbooks.png',
  quickbooks: '/companyAvatars/quickbooks.png',
  shopify: '/companyAvatars/shopify.png',
  stripe: '/companyAvatars/stripe.png',
  bank: '/companyAvatars/bank.svg',
  accounting: '/companyAvatars/accounting.svg',
  tax: '/companyAvatars/tax.svg',
  commerce: '/companyAvatars/commerce.svg',
  payroll: '/companyAvatars/payroll.svg',
  bureaus: '/companyAvatars/credit-bureau.svg',
  stateRegistry: '/companyAvatars/state-registry.svg',
  online: '/companyAvatars/web.svg',
  socialMedia: '/companyAvatars/social-media.svg',
  police: '/companyAvatars/police.svg',
  lexisNexis: '/companyAvatars/lexis-nexis.svg',
};

interface DataSourceAvatarsProps {
  dataSources: DataSource[];
}

export const DataSourceAvatars: FC<DataSourceAvatarsProps> = ({ dataSources }) => {
  const theme = useTheme();
  return (
    <AvatarGroup max={5} sx={{ padding: 0.5, borderRadius: '30px', backgroundColor: theme.palette.primary.light }}>
      {dataSources.map((dataSource) => (
        <Tooltip key={dataSource} title={capitalCase(dataSource)}>
          <Avatar src={dataSourceAvatarsImages[dataSource]} sx={{ bgcolor: 'white', width: 24, height: 24 }} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};
