export interface EtranDialogProps<T = undefined> {
  open: boolean;
  onClose: () => void;
  onSuccess: (value?: T) => void;
}

export interface EtranCredentials {
  username: string;
  password: string;
}

export const errorIsEtranExportErrorsResponseData = (error: unknown): error is EtranExportErrorsResponseData =>
  (error as EtranExportErrorsResponseData).response?.status === 424;

export interface EtranExportErrorsResponseData {
  response: {
    data: {
      errors: string[];
    };
    status: 424;
  };
}

export type EtranDialogMode = 'auth' | 'code' | undefined;

export interface EtranPayloadErrorStatus {
  applicationHasErrors?: boolean;
  businessesHaveErrors?: boolean;
  peopleHaveErrors?: boolean;
}
