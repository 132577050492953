import type { FC } from 'react';
import React, { useMemo } from 'react';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { personFullName } from '@lama/data-formatters';
import { AuditLog } from '../../../../AuditLog';
import type { ApplicationStatusAuditProps } from './applicationStatusAuditComponentMap';

export const ApplicationRejectedAuditLog: FC<ApplicationStatusAuditProps> = ({ audit, groupInfo }) => {
  const rejectionText = useMemo(() => {
    if (audit.originType === 'system') {
      return 'Automatically rejected for not passing knockout criteria';
    }
    if (!audit.user?.firstName || !audit.user?.lastName) {
      return 'Application was Rejected';
    }

    return `Application was Rejected by ${personFullName(audit.user as { firstName: string; middleName?: string; lastName: string })}`;
  }, [audit]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={rejectionText}
      timestamp={audit.timestamp}
      key={audit.id}
      badge={<CancelIcon fontSize={'inherit'} color={'error'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />}
    />
  );
};
