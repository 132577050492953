import type { RangeMatcher } from '@lama/contracts';
import { Box, Slider, TextField, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import React, { useMemo, useState, useCallback } from 'react';
import { useDebounce } from 'react-use';
import type { RangeConditionConfiguration } from '@lama/conditions';
import { formatValue } from '@lama/data-formatters';
import { Flex } from '@lama/design-system';
import type { MatcherValue } from './Matchers/types';
import type { RangeMatcherConfiguration } from './Matchers/matcherConfigurations';

interface RangeConditionProps {
  condition?: RangeMatcher;
  configuration: RangeConditionConfiguration | RangeMatcherConfiguration;
  onChange: (matcher: MatcherValue) => void;
}

const RangeDivider = () => <Box sx={{ borderTop: ` 1px solid ${grey[300]};`, flex: 0.8, height: '0.5px' }} />;

export const RangeCondition: FC<RangeConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [range, setRange] = useState<{ min: number; max: number }>({
    min: condition?.min ?? configuration.default.min,
    max: condition?.max ?? configuration.default.max,
  });
  const { min, max } = range;

  const onChange = useCallback((event: Event, rangeUpdate: number[] | number) => {
    if (typeof rangeUpdate === 'number' || rangeUpdate.length !== 2) {
      return;
    }

    const [minUpdate, maxUpdate] = rangeUpdate;

    setRange({ min: minUpdate!, max: maxUpdate! });
  }, []);

  const conditionUpdated = useMemo(() => {
    if (condition) {
      return min !== condition?.min || max !== condition.max;
    }

    return min !== configuration.default.min || max !== configuration.default.max;
  }, [condition, configuration.default.max, configuration.default.min, max, min]);

  const updateCondition = useCallback(() => {
    if (!conditionUpdated) {
      return;
    }

    onConditionChange({
      min,
      max,
      type: configuration.type,
    });
  }, [conditionUpdated, configuration.type, max, min, onConditionChange]);

  useDebounce(updateCondition, 1000, [range]);

  const marks = [
    {
      value: configuration.min,
      label: formatValue(configuration.min, configuration.format),
    },
    {
      value: configuration.max,
      label: formatValue(configuration.max, configuration.format),
    },
  ];

  const setMaxValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRange({ min, max: Number(event.target.value) });
    },
    [min],
  );

  const onMaxBlur = useCallback(() => {
    if (max > configuration.max) {
      setRange({ min, max: configuration.max });
    }
  }, [configuration.max, max, min]);

  const setMinValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRange({ min: Number(event.target.value), max });
    },
    [max],
  );

  const onMinBlur = useCallback(() => {
    if (min < configuration.min) {
      setRange({ min: configuration.min, max });
    }
  }, [configuration.min, max, min]);

  const getAriaValueText = useCallback(
    (value: number) => (configuration?.format ? formatValue(value, configuration.format) : value.toString()),
    [configuration.format],
  );

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <TextField
          sx={{ width: '45%' }}
          id={`min-${configuration.displayName}}`}
          label={`Min ${configuration.displayName}`}
          type={'number'}
          onChange={setMinValue}
          onBlur={onMinBlur}
          value={min}
          InputProps={{
            startAdornment: configuration?.format === 'currency' ? <InputAdornment position={'end'}>{'$'}</InputAdornment> : undefined,
            inputProps: { min: configuration.min, max: configuration.max },
          }}
        />
        <RangeDivider />
        <TextField
          sx={{ width: '45%' }}
          id={`max-${configuration.displayName}}`}
          label={`Max ${configuration.displayName}`}
          type={'number'}
          onChange={setMaxValue}
          onBlur={onMaxBlur}
          value={max}
          InputProps={{
            startAdornment: configuration?.format === 'currency' ? <InputAdornment position={'end'}>{'$'}</InputAdornment> : undefined,
            inputProps: { min: configuration.min, max: configuration.max },
          }}
        />
      </Flex>
      <Box px={4}>
        <Slider
          marks={marks}
          value={[min, max]}
          step={configuration.step}
          min={configuration.min}
          max={configuration.max}
          onChange={onChange}
          valueLabelDisplay={'auto'}
          getAriaValueText={getAriaValueText}
        />
      </Box>
    </Flex>
  );
};
