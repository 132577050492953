import type { PeriodWithFinancialDataByEntityId } from '@lama/selectors';
import { getFinancialAttribute, getLongestPeriodByYearsBack, getPeriodsWithFinancials, type Period } from '@lama/selectors';
import type { SpreadRowConfiguration } from '@lama/partner-service-client';
import type { FinancialData, PropertiesRecord, Property } from '@lama/contracts';
import { compact, isNil } from 'lodash-es';
import { getYear } from '@lama/data-formatters';
import type { ApplicationApiModel, PersonApiModel, OpportunityApiModel } from '@lama/clients';
import { type Entity } from '@lama/common-types';
import { type BusinessApiModel } from '@lama/business-service-client';
import type { SpreadRow, SpreadRowPeriodValue } from '@lama/spreads-generator-client';
import { getEntitiesForRow } from './getEntitiesForRow.js';
import { getRowDisplayName } from './getRowDisplayName.js';
import { getAttributeCalculationExpression } from './getAttributeCalculationExpression.js';
import type { EntitiesByType } from './types.js';

const getFinancialValuesByPeriods = ({
  entity,
  entityType,
  spreadPeriods,
  property,
  allProperties,
  periodsWithFinancialDataByEntityId,
  application,
}: {
  entity: ApplicationApiModel | BusinessApiModel | OpportunityApiModel | PersonApiModel;
  entityType: Entity;
  spreadPeriods: Period[];
  property: Property;
  allProperties: PropertiesRecord;
  periodsWithFinancialDataByEntityId: PeriodWithFinancialDataByEntityId[];
  application: ApplicationApiModel;
}): Record<string, SpreadRowPeriodValue> =>
  Object.fromEntries(
    compact(
      spreadPeriods.map((period) => {
        const periodKey = `${period.startDate}-${period.endDate}`;

        const yearsBack = new Date().getUTCFullYear() - getYear(period.startDate);

        if (!property.financialAttribute) {
          return null;
        }

        const selectedPeriod = getLongestPeriodByYearsBack(periodsWithFinancialDataByEntityId, yearsBack);

        const selectorContext = {
          application,
          entity,
          entityType,
          yearsBack,
          periodWithFinancialDataByEntityId: selectedPeriod,
        };

        const financialAttribute = getFinancialAttribute({ selectorContext, financialType: property.financialAttribute });

        const periodValue: SpreadRowPeriodValue = { financialAttribute };

        if (financialAttribute?.selectedSource.financialDataSource.type === 'Calculation') {
          const expression = getAttributeCalculationExpression({
            property,
            allProperties,
            selectorContext,
            expressionTemplate: financialAttribute.selectedSource.calculationExpressionTemplate,
          });

          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          periodValue.calculationExpression = expression ?? '';

          // temp, remove this
          delete financialAttribute.selectedSource.calculationExpressionTemplate;

          // temp, remove this
          financialAttribute.prioritizedSources
            ?.filter((s) => !isNil(s.calculationExpressionTemplate))
            .forEach((s) => delete s.calculationExpressionTemplate);
        }

        return [periodKey, periodValue];
      }),
    ),
  );

export const propertyConfigToRows = ({
  propertyConfig,
  attributeHierarchy = [],
  displayedPeriods,
  entitiesByType,
  properties,
  showEntityNames,
  periodsWithFinancialDataByEntityId,
  application,
}: {
  propertyConfig: SpreadRowConfiguration;
  attributeHierarchy?: string[];
  displayedPeriods: Period[];
  entitiesByType: EntitiesByType;
  properties: PropertiesRecord;
  showEntityNames?: boolean;
  periodsWithFinancialDataByEntityId: PeriodWithFinancialDataByEntityId[];
  application: ApplicationApiModel;
}): SpreadRow[] => {
  const property = properties[propertyConfig.propertyKey];
  const entityType = property?.entityType;

  if (!property?.financialAttribute || !entityType || !entitiesByType[entityType]) {
    return [];
  }

  const entities = getEntitiesForRow({
    property,
    entitiesByType,
    entityGroups: propertyConfig.entityGroups ?? [],
  });

  const propertyRows: SpreadRow[] = entities.map<SpreadRow>((entity) => {
    const rowDisplayName = getRowDisplayName(property, !!showEntityNames, entity);
    const currentAttributeHierarchy = [...attributeHierarchy, rowDisplayName];

    const propertyRow: SpreadRow = {
      propertyConfig,
      attributeHierarchy: currentAttributeHierarchy,
      attributeDisplayName: rowDisplayName,
      property,
      entityId: entity.id,
      valueByPeriod: getFinancialValuesByPeriods({
        entity,
        entityType,
        spreadPeriods: displayedPeriods,
        property,
        allProperties: properties,
        periodsWithFinancialDataByEntityId,
        application,
      }),
    };

    return propertyRow;
  });

  return [
    ...propertyRows,
    ...(propertyConfig.children ?? []).flatMap((configChild) =>
      propertyConfigToRows({
        propertyConfig: configChild,
        displayedPeriods,
        attributeHierarchy: propertyRows.at(0)?.attributeHierarchy,
        entitiesByType,
        properties,
        showEntityNames,
        periodsWithFinancialDataByEntityId,
        application,
      }),
    ),
  ];
};

export const getSpreadRows = ({
  spreadConfigurations,
  displayedPeriods,
  entities,
  properties,
  showEntityNames = true,
  application,
}: {
  spreadConfigurations: SpreadRowConfiguration[];
  financials?: FinancialData[];
  displayedPeriods: Period[];
  entities: EntitiesByType;
  properties: PropertiesRecord;
  application: ApplicationApiModel;
  showEntityNames?: boolean;
}) => {
  const allFinancials = [
    ...entities.business.flatMap((a) => a.business.financials ?? []),
    ...entities.person.flatMap((a) => a.person.financials ?? []),
  ];

  const periodsWithFinancialDataByEntityId = getPeriodsWithFinancials(allFinancials);

  return spreadConfigurations.flatMap((propertyConfig) =>
    propertyConfigToRows({
      displayedPeriods,
      propertyConfig,
      entitiesByType: entities,
      properties,
      showEntityNames,
      periodsWithFinancialDataByEntityId,
      application,
    }),
  );
};
