import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { businessFullAddress, relatedBusinessesByRelationsSelector } from '@lama/data-formatters';
import { isNil, sumBy } from 'lodash-es';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { totalRow } from '../../creditMemoTable/creditMemoTableRowCreator';

const headers = [
  {
    text: 'Type',
    width: '15%',
  },
  {
    text: 'Address',
    width: '20%',
  },
  {
    text: 'Owner/Vesting',
    width: '15%',
  },
  {
    text: 'FMV',
    width: '10%',
  },
  {
    text: 'Mortgate Holder',
    width: '10%',
  },
  {
    text: 'Loan Balance',
    width: '10%',
  },
  {
    text: 'Monthly Payment',
    width: '10%',
  },
  {
    text: 'Remaining Equity',
    width: '10%',
  },
];

export const GrasshopperPropertySummaryTableSegment: FC = () => {
  const { application } = useContext(ApplicationContext);

  const properties = useMemo(() => relatedBusinessesByRelationsSelector(application, ['property']), [application]);

  const rows = useMemo(() => {
    const propertyRows = properties.map<CellContent[]>(({ business }) => [
      {
        value: business.hmda?.propertyType,
      },
      {
        value: businessFullAddress(business),
      },
      {
        value: business.hmda?.owner,
      },
      {
        value: business.hmda?.propertyValue,
        type: 'currency',
        align: 'right',
      },
      {
        value: business.hmda?.mortgageHolder,
      },
      {
        value: business.hmda?.loanBalance,
        type: 'currency',
        align: 'right',
      },
      {
        value: business.hmda?.monthlyPayments,
        type: 'currency',
        align: 'right',
      },
      {
        value: business.hmda?.remainingEquity,
        type: 'currency',
        align: 'right',
      },
    ]);

    const totalPropertyValue = properties.some(({ business }) => !isNil(business.hmda?.propertyValue))
      ? sumBy(properties, ({ business }) => business.hmda?.propertyValue ?? 0)
      : null;

    const totalLoanBalance = properties.some(({ business }) => !isNil(business.hmda?.loanBalance))
      ? sumBy(properties, ({ business }) => business.hmda?.loanBalance ?? 0)
      : null;

    const totalMonthlyPayments = properties.some(({ business }) => !isNil(business.hmda?.monthlyPayments))
      ? sumBy(properties, ({ business }) => business.hmda?.monthlyPayments ?? 0)
      : null;

    const totalRemainingEquity = properties.some(({ business }) => !isNil(business.hmda?.remainingEquity))
      ? sumBy(properties, ({ business }) => business.hmda?.remainingEquity ?? 0)
      : null;

    const totalsRowCellValues: CellContent[] = [
      { value: '' },
      { value: '' },
      { value: totalPropertyValue, type: 'currency', align: 'right' },
      { value: '' },
      { value: totalLoanBalance, type: 'currency', align: 'right' },
      { value: totalMonthlyPayments, type: 'currency', align: 'right' },
      { value: totalRemainingEquity, type: 'currency', align: 'right' },
    ];

    const totalRowDefinition = totalRow({ cells: totalsRowCellValues });

    return [...propertyRows, totalRowDefinition];
  }, [properties]);

  return (
    <SegmentContainer title={'Real Estate Schedule'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
