import type { ConditionConfigurationFormats } from '@lama/conditions';
import { useOfFunds, stateNames } from '@lama/contracts';
import type { Entity } from '@lama/common-types';
import type { Matcher, MatcherType } from '@lama/contracts';

interface BaseMatcherConfiguration {
  displayName: string;
  fieldName: string;
  entityType: Entity;
  type: MatcherType;
  format?: ConditionConfigurationFormats;
  default: any;
}

export interface RangeMatcherConfiguration extends BaseMatcherConfiguration {
  min: number;
  max: number;
  default: {
    min: number;
    max: number;
  };
  step: number;
  type: 'range';
}

export interface GreaterThanMatcherConfiguration extends BaseMatcherConfiguration {
  default: number;
  min: number;
  max: number;
  type: 'greaterThan';
  step?: number;
}

export interface LessThanMatcherConfiguration extends BaseMatcherConfiguration {
  default: number;
  min: number;
  max: number;
  type: 'lessThan';
  step?: number;
}

export interface IncludedMatcherConfiguration extends BaseMatcherConfiguration {
  default: string;
  options: string[] | readonly string[];
  type: 'included';
}

export interface NoneOfMatcherConfiguration extends BaseMatcherConfiguration {
  default: string[];
  options: string[] | readonly string[];
  type: 'noneOf';
}

export interface AnyOfMatcherConfiguration extends BaseMatcherConfiguration {
  default: string[];
  options: string[] | readonly string[];
  type: 'anyOf';
}

export interface NotIncludedMatcherConfiguration extends BaseMatcherConfiguration {
  default: string[];
  options: string[] | readonly string[];
  type: 'notIncluded';
}

export interface HasAllMatcherConfiguration extends BaseMatcherConfiguration {
  default: string[];
  options: string[] | readonly string[];
  type: 'hasAll';
}

export interface EqualsMatcherConfiguration extends BaseMatcherConfiguration {
  type: 'equals';
  default: any;
}

export type MatcherConfiguration =
  | AnyOfMatcherConfiguration
  | EqualsMatcherConfiguration
  | GreaterThanMatcherConfiguration
  | HasAllMatcherConfiguration
  | IncludedMatcherConfiguration
  | LessThanMatcherConfiguration
  | NoneOfMatcherConfiguration
  | NotIncludedMatcherConfiguration
  | RangeMatcherConfiguration;

export const matcherConfigurations: MatcherConfiguration[] = [
  {
    displayName: 'Min No. Of Guarantors',
    entityType: 'business',
    fieldName: 'guarantors',
    type: 'greaterThan',
    default: 1,
    min: 1,
    max: 10,
    step: 1,
  },
  {
    displayName: 'Max No. Of Guarantors',
    entityType: 'business',
    fieldName: 'guarantors',
    type: 'lessThan',
    default: 3,
    min: 1,
    max: 10,
    step: 1,
  },
  {
    displayName: 'Exact No. Of Guarantors',
    entityType: 'business',
    fieldName: 'guarantors',
    type: 'equals',
    default: 2,
  },
  {
    displayName: 'Use Of Funds Includes',
    entityType: 'application',
    fieldName: 'useOfFunds',
    type: 'anyOf',
    format: 'string',
    default: [],
    options: useOfFunds,
  },
  {
    displayName: 'Use Of Funds Excludes',
    entityType: 'application',
    fieldName: 'useOfFunds',
    type: 'noneOf',
    format: 'string',
    default: [],
    options: useOfFunds,
  },
  {
    displayName: 'Loan Amount is greater than',
    entityType: 'application',
    fieldName: 'requestedAmount',
    type: 'greaterThan',
    format: 'currencyCompact',
    default: 50_000,
    min: 10_000,
    max: 1_000_000,
    step: 10_000,
  },
  {
    displayName: 'Loan Amount is less than',
    entityType: 'application',
    fieldName: 'requestedAmount',
    type: 'lessThan',
    format: 'currencyCompact',
    default: 100_000,
    min: 10_000,
    max: 5_000_000,
    step: 10_000,
  },
  {
    displayName: 'Business Location',
    entityType: 'business',
    fieldName: 'usStates',
    type: 'anyOf',
    format: 'string',
    default: [],
    options: stateNames,
  },
  {
    displayName: 'Revolving Credit Limit is greater than',
    entityType: 'business',
    fieldName: 'totalCreditLinesLastYear',
    type: 'greaterThan',
    default: 10_000,
    min: 1000,
    max: 1_000_000,
    step: 1000,
  },
  {
    displayName: 'Revolving Credit Limit is less than',
    entityType: 'business',
    fieldName: 'totalCreditLinesLastYear',
    type: 'lessThan',
    default: 15_000,
    min: 1000,
    max: 1_000_000,
    step: 1000,
  },
  {
    entityType: 'person',
    fieldName: 'financialInformation.totalCreditLines',
    type: 'greaterThan',
    default: 15_000,
    displayName: 'Revolving Credit Limit is greater than',
    min: 1000,
    max: 1_000_000,
  },
  {
    entityType: 'person',
    fieldName: 'financialInformation.totalCreditLines',
    type: 'lessThan',
    default: 20_000,
    displayName: 'Revolving Credit Limit is less than',
    min: 1000,
    max: 1_000_000,
  },
  {
    entityType: 'business',
    fieldName: 'currentCustomer',
    type: 'equals',
    default: false,
    displayName: 'Is Current Customer',
  },
  {
    fieldName: 'guarantorsMaxMonthsBankedSelector',
    entityType: 'application',
    default: 12,
    type: 'greaterThan',
    min: 0,
    max: 60,
    displayName: 'Min Guarantors Months Banked',
  },
  {
    fieldName: 'useOfFundsWorkingCapitalPercentageSelector',
    entityType: 'application',
    default: 0.51,
    type: 'greaterThan',
    min: 0,
    max: 1,
    displayName: 'Min Working Capital Percentage',
    format: 'percent',
    step: 0.01,
  },
  {
    fieldName: 'guarantorsCount',
    entityType: 'business',
    default: 1,
    type: 'equals',
    displayName: 'Number of Guarantors',
  },
  {
    fieldName: 'guarantorsCount',
    entityType: 'business',
    type: 'greaterThan',
    default: 1,
    min: 1,
    max: 10,
    step: 1,
    displayName: 'Min Number of Guarantors',
  },
];

export const getMatcherConfig = (matcher: Matcher): MatcherConfiguration | null =>
  matcherConfigurations.find((m) => m.type === matcher.type && m.fieldName === matcher.fieldName && m.entityType === matcher.entityType) ??
  null;
