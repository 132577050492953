import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { personFullName } from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { useUsersByPartnerQuery } from '../../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsAssignersSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);
  const underwriterFullName = useMemo(() => {
    const underwriterUser = users?.find((u) => opportunity.assigneeIds?.includes(u.id) && u.roles.includes('underwriter'));
    return underwriterUser ? personFullName(underwriterUser) : '-';
  }, [users, opportunity.assigneeIds]);

  const relationshipManagerFullName = useMemo(() => {
    const relationshipManagerUser = users?.find((u) => opportunity.assigneeIds?.includes(u.id) && u.roles.includes('relationshipManager'));
    return relationshipManagerUser ? personFullName(relationshipManagerUser) : '-';
  }, [users, opportunity.assigneeIds]);

  return (
    <SegmentContainer title={'Assigners'}>
      <Grid columns={2}>
        <KeyValuePair name={'Underwriter'} value={underwriterFullName} />
        <KeyValuePair name={'Loan Officer'} value={relationshipManagerFullName} />
      </Grid>
    </SegmentContainer>
  );
};
