/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Tabs, Tab, useTheme, Skeleton } from '@mui/material';
import { capitalCase } from 'change-case-all';
import { sumBy } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import styled, { css } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { GetPipelineItemsQueryParams } from '@lama/pipeline-items-service-client';
import { useGetPipelineCategoriesQueries } from '../../shared/hooks/react-query/pipeline/useGetPipelineCategoriesQueries';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useGetPartnerQuery } from '../../shared/hooks/react-query/partner/useGetPartnerQuery';

const HidingContainer = styled(Flex)<{ hideCategories: boolean }>`
  width: 100%;
  transition: display 0.5s ease-out;
  ${({ hideCategories }) =>
    hideCategories &&
    css`
      display: none;
    `}
`;

type PipelineCategory = 'Closed' | 'Closing' | 'Leads' | 'Lost' | 'Pending Decision' | 'Qualified';

const noLeadsPipelineCategories: PipelineCategory[] = ['Qualified', 'Pending Decision', 'Closing', 'Closed', 'Lost'];
const workflowPipelineCategories: PipelineCategory[] = ['Leads', ...noLeadsPipelineCategories];
export const defaultPipelineCategory: PipelineCategory = 'Qualified';

interface PipelineCategoryTabsV2Props {
  selectedPipelineCategory: string | null;
  onChange: (_: React.SyntheticEvent, category: string) => void;
  queryFilters: Pick<GetPipelineItemsQueryParams, 'assignedTo' | 'partnerIds' | 'productIds' | 'referrerCodes' | 'search' | 'statuses'>;
  hideCategories: boolean;
}

const CategoryTabLabel = ({
  category,
  totalAmount,
  numberOfApplications,
  loading,
}: {
  category: string;
  totalAmount: number;
  numberOfApplications: number;
  loading: boolean;
}) => {
  const { fibtDemo } = useFlags();
  const theme = useTheme();
  return (
    <Flex flexDirection={'column'} alignItems={'baseline'} mr={'auto'}>
      <Text variant={'body1'}>{capitalCase(category)}</Text>
      <Flex gap={1}>
        {loading ? (
          <>
            <Skeleton>
              <Text variant={'h5'}>{'50M$'}</Text>
            </Skeleton>
            <Skeleton>
              <Text variant={'h5'}>{'(100)'}</Text>
            </Skeleton>
          </>
        ) : (
          <>
            {fibtDemo ? null : (
              <Text variant={'h5'} color={theme.palette.text.primary}>
                {formatValue(totalAmount, 'currencyCompact')}
              </Text>
            )}
            <Text variant={'h5'} color={theme.palette.text.disabled}>{`(${numberOfApplications})`}</Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const PipelineCategoryTabsV2: FC<PipelineCategoryTabsV2Props> = ({
  selectedPipelineCategory,
  queryFilters,
  onChange,
  hideCategories,
}) => {
  const { partner: userPartnerId } = useContext(UserDetailsContext);
  const { data: partner } = useGetPartnerQuery(userPartnerId);
  const hideLeadsTab = useMemo(() => partner?.featureConfigurations?.hideLeadsTab ?? false, [partner]);
  const relevantPipelineCategories: PipelineCategory[] = useMemo(
    () => (hideLeadsTab ? noLeadsPipelineCategories : workflowPipelineCategories),
    [hideLeadsTab],
  );

  const { data: pipelineCategoriesMetadata, pending: loadingCategoriesMetadata } = useGetPipelineCategoriesQueries(
    relevantPipelineCategories,
    queryFilters,
  );

  const all = useMemo(
    () => ({
      count: sumBy(pipelineCategoriesMetadata, (item) => item.count || 0),
      totalRequestedAmount: sumBy(pipelineCategoriesMetadata, (item) => item.totalRequestedAmount || 0),
    }),
    [pipelineCategoriesMetadata],
  );

  const finalCategoriesMetadata = useMemo(
    () =>
      loadingCategoriesMetadata
        ? relevantPipelineCategories.map((category) => ({
            category,
            count: 0,
            totalRequestedAmount: 0,
          }))
        : pipelineCategoriesMetadata,
    [loadingCategoriesMetadata, pipelineCategoriesMetadata, relevantPipelineCategories],
  );

  const selectedCategoryExists = useMemo(
    () =>
      selectedPipelineCategory === 'all' ||
      (loadingCategoriesMetadata
        ? relevantPipelineCategories.includes(selectedPipelineCategory as PipelineCategory)
        : pipelineCategoriesMetadata.some((categoryData) => categoryData.category === selectedPipelineCategory)),
    [loadingCategoriesMetadata, pipelineCategoriesMetadata, relevantPipelineCategories, selectedPipelineCategory],
  );

  return (
    <HidingContainer hideCategories={hideCategories}>
      <Tabs
        sx={{ textTransform: 'none', width: '100%' }}
        centered={false}
        value={selectedCategoryExists ? selectedPipelineCategory : false}
        variant={'fullWidth'}
        onChange={onChange}
      >
        {finalCategoriesMetadata.map(({ category, count, totalRequestedAmount }) =>
          category ? (
            <Tab
              key={category}
              sx={{ textTransform: 'none' }}
              label={
                <CategoryTabLabel
                  category={category}
                  totalAmount={totalRequestedAmount ?? 0}
                  numberOfApplications={count ?? 0}
                  loading={loadingCategoriesMetadata}
                />
              }
              value={category}
            />
          ) : null,
        )}
        {hideLeadsTab ? null : (
          <Tab
            key={'all'}
            sx={{ textTransform: 'none' }}
            label={
              <CategoryTabLabel
                category={'all'}
                totalAmount={all.totalRequestedAmount}
                numberOfApplications={all.count}
                loading={loadingCategoriesMetadata}
              />
            }
            value={'all'}
          />
        )}
      </Tabs>
    </HidingContainer>
  );
};
