import React from 'react';
import type { FC } from 'react';
import { sentenceCase } from 'change-case-all';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import type { CateogryDesign } from './types';
import { CategoryLogo } from './CategoryLogo';

export const TansactionsCategory: FC<{ category: CateogryDesign; profit: number; loss: number }> = ({ category, profit, loss }) => (
  <Flex border={'1px solid #E0E0E0'} borderRadius={'4px'}>
    <Flex flexDirection={'column'} p={4} gap={2}>
      <CategoryLogo category={category.name} />
      <Flex flexDirection={'column'} gap={1}>
        <Text variant={'body2'}>{sentenceCase(category.name)}</Text>
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          {profit ? <Text variant={'body2'} color={'green.500'}>{`+${formatValue(Math.abs(profit), 'currency')}`}</Text> : null}
          {loss ? <Text variant={'body2'} color={'red.500'}>{`-${formatValue(Math.abs(loss), 'currency')}`}</Text> : null}
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);
