import type { Rate } from '@lama/contracts';
import { FIXED_RATE_TYPE, PRIME_RATE_TYPE } from '@lama/selectors';

export const rateTypes = [FIXED_RATE_TYPE, PRIME_RATE_TYPE];

const backwardsCompatibleRateTypeMapping: Record<string, string> = {
  Prime: PRIME_RATE_TYPE,
  None: FIXED_RATE_TYPE,
};

export const getRateType = (rate?: Rate) => {
  if (!rate) {
    return;
  }

  const { isFixed, benchmarkRateType } = rate;

  if (isFixed) {
    return FIXED_RATE_TYPE;
  }

  if (!benchmarkRateType) {
    return;
  }

  if (benchmarkRateType in backwardsCompatibleRateTypeMapping) {
    return backwardsCompatibleRateTypeMapping[benchmarkRateType];
  }

  return benchmarkRateType;
};

export { FIXED_RATE_TYPE, PRIME_RATE_TYPE } from '@lama/selectors';
