/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { googleApiKey } from '../../framework/environment';

export interface PhotoResult {
  getUrl: (options: Record<string, unknown>) => string;
}

export interface ApiAddressPart {
  long_name?: string;
  short_name?: string;
  types: string[];
}

export interface PlaceApiResult {
  place_id: string;
  photos?: PhotoResult[];
  website?: string;
  address_components: ApiAddressPart[];
  name: string;
  formatted_phone_number?: string;
  types: string[];
}

type GooglePlacesService = {
  getDetails: (request: Record<string, any>, callback: (placeDetails: PlaceApiResult) => void) => void;
} | null;

export const GooglePlacesServiceContext = React.createContext<GooglePlacesService>(null);

interface GooglePlacesServiceProviderProps {
  children: React.ReactNode;
}

export const GooglePlacesServiceProvider: FC<GooglePlacesServiceProviderProps> = ({ children }) => {
  const [state, setState] = useState(false);

  // @ts-ignore
  window._googlePlacesScriptCallback = () => {
    setState(!state);
  };

  const { placesService } = usePlacesService({
    apiKey: `${googleApiKey}&callback=_googlePlacesScriptCallback`,
  });

  return <GooglePlacesServiceContext.Provider value={placesService}>{children}</GooglePlacesServiceContext.Provider>;
};
