import React, { useContext, type FC, useCallback, useRef, useState } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { useHmdaReportChartData } from '../Charts/hooks/useHmdaReportChartData';
import { ApplicationsWeeklyChart } from '../Charts/ApplicationsWeeklyChart';

export const HmdaReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { data: chartData, isPending: loadingChartData } = useHmdaReportChartData({ endDate, startDate, productId, partnerId });
  const { getPersistHMDAReport } = useFlags();
  const { mutateAsync: downloadHmdaReport, isPending: downloadingHmdaReport } = useDownloadReportMutation(
    getPersistHMDAReport ? 'PersistHMDA' : 'HMDA',
  );
  const { mutateAsync: downloadHmdaTxtReport, isPending: downloadingHmdaTxtReport } = useDownloadReportMutation(
    getPersistHMDAReport ? 'PersistHMDAPipeTxt' : 'HMDAPipeTxt',
  );

  const [showHmdaExportMenu, setShowHmdaExportMenu] = useState(false);
  const menuRef = useRef(null);

  const onClickDownloadButton = useCallback(() => {
    setShowHmdaExportMenu(true);
  }, []);
  const onCloseExportMenu = useCallback(() => {
    setShowHmdaExportMenu(false);
  }, []);

  const onClickDownloadHmdaReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download HMDA report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download HMDA report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download HMDA report without a partner id", 'info');
      return;
    }

    const filename = `HMDA Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.csv`;

    const data = await downloadHmdaReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'text/csv',
      },
      filename,
    });

    const blob = new Blob([data], {
      type: 'text/csv',
    });

    saveAs(blob, filename);
    setShowHmdaExportMenu(false);
  }, [downloadHmdaReport, endDate, partnerId, productId, startDate]);

  const onClickDownloadHmdaTxtReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download HMDA report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download HMDA report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download HMDA report without a partner id", 'info');
      return;
    }

    const filename = `HMDA Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.txt`;

    const data = await downloadHmdaTxtReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      filename,
    });

    const blob = new Blob([data]);

    saveAs(blob, filename);
    setShowHmdaExportMenu(false);
  }, [downloadHmdaTxtReport, endDate, partnerId, productId, startDate]);

  return (
    <>
      <ReportCard
        disableActions={downloadingHmdaReport || downloadingHmdaTxtReport}
        actionText={'Download Report'}
        onClickAction={onClickDownloadButton}
        actionLoading={downloadingHmdaReport || downloadingHmdaTxtReport}
        title={'HMDA Report'}
        loading={loadingChartData}
        actionMenuRef={menuRef}
      >
        <ApplicationsWeeklyChart chartData={chartData?.chartData} />
      </ReportCard>
      <Menu
        open={showHmdaExportMenu}
        anchorEl={menuRef.current}
        onClose={onCloseExportMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuList>
          <MenuItem onClick={onClickDownloadHmdaReport}>{'CSV Format'}</MenuItem>
          <MenuItem onClick={onClickDownloadHmdaTxtReport}>{'Pipe Separator Format'}</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
