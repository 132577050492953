import React, { useMemo } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import { personFullName } from '@lama/data-formatters';
import type { PersonApiModel } from '@lama/clients';
import { EntityPropertiesExport } from './EntityPropertiesExport';

export const RelatedPersonSegment = ({ properties, person }: { properties: RequirementProperty[]; person: PersonApiModel }) => {
  const personName = useMemo(() => personFullName(person), [person]);

  return <EntityPropertiesExport entity={person} entityName={personName} properties={properties} />;
};
