import React from 'react';
import type { FC } from 'react';
import type { ProductApiModel } from '@lama/contracts';
import { Outlet, useLocation } from 'react-router-dom';
import { ProductSettingsProvider } from './ProductSettingsContext';
import { ProductSettingsHeaderV2 } from './ProductSettingsHeaderV2';

export const ProductSettings: FC<{
  product: ProductApiModel;
}> = ({ product }) => {
  const location = useLocation();
  return (
    <ProductSettingsProvider product={product} returnToAfterSave={location.state?.returnTo}>
      <ProductSettingsHeaderV2 productPrettyName={product.prettyName} />
      <Outlet />
    </ProductSettingsProvider>
  );
};
