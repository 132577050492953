import type { DataNode } from '@lama/design-system';
import { Flex, Collapse, Text, Spinner, greyPalette } from '@lama/design-system';
import { groupBy, orderBy } from 'lodash-es';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useAsyncFn, useToggle } from 'react-use';
import { IconButton } from '@mui/material';
import { Tooltip, DownloadIcon, BusinessIcon, PersonIcon } from '@lama/app-components';
import type { ApplicationApiModel } from '@lama/clients';
import { personRelationsSelector, businessRelationsSelector } from '@lama/data-formatters';
import ExpandButton from '../../shared/components/ExpandButton';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentBox } from '../../shared/components/DocumentBox/DocumentBox';
import { DocumentListSubGroup } from './DocumentListSubGroup';
import { downloadDocumentGroup } from './hooks/useDownloadDocumentGroup';
import type { GroupedDocumentBoxDetails } from './types';

interface DocumentGroupProps {
  groupName: string;
  groupDocuments: GroupedDocumentBoxDetails[];
  moveToTreeData: DataNode[];
  flat: boolean;
  showSubtitle: boolean;
}

const getEntityRelation = (application: ApplicationApiModel, entityId: string, entityType: string) => {
  if (entityType === 'person') {
    return personRelationsSelector(entityId, application);
  }

  if (entityType === 'business') {
    return businessRelationsSelector(entityId, application);
  }

  return '';
};

const getCategoryIcon = (entityType?: string) => {
  if (entityType === 'business') {
    return <BusinessIcon />;
  }

  if (entityType === 'person') {
    return <PersonIcon />;
  }

  return null;
};

export const DocumentGroup: FC<DocumentGroupProps> = ({ groupName, groupDocuments, moveToTreeData, flat, showSubtitle }) => {
  const { application } = useContext(ApplicationContext);

  const [listExpanded, toggleListExpanded] = useToggle(true);

  const orderedSubGroups = useMemo(
    () => orderBy(Object.entries(groupBy(groupDocuments, (doc) => doc.subGroup)), ([subgroup]) => subgroup),
    [groupDocuments],
  );
  const isEmptyGroup = useMemo(() => !groupDocuments.some((d) => d.document), [groupDocuments]);

  const [{ loading: downloadingDocuments }, onClickDownloadAll] = useAsyncFn(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      await downloadDocumentGroup(groupName, groupDocuments);
    },
    [groupDocuments, groupName],
  );

  const icon = useMemo(() => getCategoryIcon(groupDocuments[0]?.entityType), [groupDocuments]);

  const subtitle = useMemo(
    () =>
      groupDocuments[0]?.entityId && groupDocuments[0]?.entityType
        ? getEntityRelation(application, groupDocuments[0]?.entityId, groupDocuments[0]?.entityType)
        : '',
    [application, groupDocuments],
  );

  if (!groupDocuments.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={4} data-testid={`group-${groupName}`}>
      <Flex gap={2} alignItems={'center'} width={'fit-content'}>
        <Flex gap={2} alignItems={'center'} onClick={toggleListExpanded}>
          <ExpandButton expand={listExpanded} sx={{ padding: 0 }} disableRipple />
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body1'}>{groupName}</Text>
            {showSubtitle && icon && subtitle ? (
              <Flex gap={2} alignItems={'center'}>
                {icon}
                <Text variant={'body3'} color={'secondary'} ellipsis data-sentry-unmask>
                  {subtitle}
                </Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Tooltip title={'Download all'} placement={'top'}>
          {!downloadingDocuments ? (
            // the span is needed in order to show a tooltip when the button is disabled
            <span>
              <IconButton
                sx={{ width: '30px' }}
                onClick={onClickDownloadAll}
                aria-label={'Download'}
                role={'button'}
                disabled={downloadingDocuments || isEmptyGroup}
              >
                <DownloadIcon size={'14px'} color={downloadingDocuments || isEmptyGroup ? greyPalette[300] : undefined} />
              </IconButton>
            </span>
          ) : (
            <Spinner size={'14px'} />
          )}
        </Tooltip>
      </Flex>
      <Collapse expanded={listExpanded}>
        <Flex flexDirection={'column'} gap={6}>
          {flat ? (
            <Flex flexDirection={'column'} gap={3}>
              {groupDocuments.map((documentBoxData) => (
                <DocumentBox
                  applicationId={application.id}
                  key={documentBoxData?.id || (documentBoxData?.document?.filename ?? `${documentBoxData?.document?.topic}` ?? '')}
                  document={documentBoxData.document}
                  description={documentBoxData?.name}
                  dismissed={documentBoxData?.dismissalData}
                  entityId={documentBoxData?.entityId}
                  entityType={documentBoxData?.entityType}
                  requirement={documentBoxData?.requirement}
                  topic={documentBoxData?.topic}
                  sharedRequirementId={documentBoxData?.requirement?.referenceRequirementId}
                  moveToTreeData={moveToTreeData}
                  closingTask={documentBoxData?.closingTask}
                />
              ))}
            </Flex>
          ) : (
            orderedSubGroups.map(([subgroup, documentBoxesData]) => (
              <DocumentListSubGroup
                key={subgroup}
                subgroupDocumentBoxes={documentBoxesData}
                subgroupName={subgroup}
                moveToTreeData={moveToTreeData}
              />
            ))
          )}
        </Flex>
      </Collapse>
    </Flex>
  );
};
