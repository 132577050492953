import type { EtranLoginDetails } from '@lama/clients';
import { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useExportToEtran } from '../../../../shared/hooks/react-query/etran/useExportToEtran';
import { useGetEtranAuthStatus } from '../../../../shared/hooks/react-query/etran/useGetEtranAuthStatus';
import { ApplicationContext } from '../../ApplicationContext';
import { useGetEtranPayload } from '../../../../shared/hooks/react-query/etran/useGetEtranPayload';
import type { EtranCredentials, EtranDialogMode, EtranPayloadErrorStatus } from './types';
import { errorIsEtranExportErrorsResponseData } from './types';

export const useExportToEtranFlow = () => {
  const [exportErrors, setExportErrors] = useState<string[]>([]);
  const { showEtranExportErrorsDialog } = useFlags();
  const { opportunity } = useContext(ApplicationContext);
  const { data: codeSaved, isPending: loadingAuthStatus } = useGetEtranAuthStatus();
  const { data: payloadPreview, isLoading: fetchingPreview } = useGetEtranPayload(opportunity.id);
  const exportToEtran = useExportToEtran(opportunity.id);
  const [exporting, setExporting] = useState(false);
  const [isAuthenticationDialogOpen, setIsAuthenticationDialogOpen] = useState(false);
  const [usernameAndPassword, setUsernameAndPassword] = useState<EtranLoginDetails>({ username: '', password: '' });
  const [isCodeDialogOpen, setIsCodeDialogOpen] = useState(false);

  const [taskState, setTaskState] = useState<'authenticated' | 'initial' | 'readyToExport'>('initial');
  const handleExportToEtran = useCallback(
    async (loginDetails: EtranLoginDetails) => {
      try {
        setExporting(true);
        await exportToEtran(loginDetails);
      } catch (error: unknown) {
        if (errorIsEtranExportErrorsResponseData(error) && showEtranExportErrorsDialog) {
          setExportErrors(error.response.data.errors);
        }
      } finally {
        setExporting(false);
      }
    },
    [exportToEtran, showEtranExportErrorsDialog],
  );
  useEffect(() => {
    if (loadingAuthStatus) {
      return;
    }
    if (codeSaved) {
      setTaskState('readyToExport');
    }
  }, [codeSaved, loadingAuthStatus]);
  const onNextStepClick = useCallback(async () => {
    switch (taskState) {
      case 'initial': {
        setIsAuthenticationDialogOpen(true);
        break;
      }
      case 'authenticated': {
        setIsCodeDialogOpen(true);
        break;
      }

      case 'readyToExport': {
        if (usernameAndPassword.username && usernameAndPassword.password) {
          try {
            await handleExportToEtran(usernameAndPassword);
          } catch (error: unknown) {
            if (errorIsEtranExportErrorsResponseData(error)) {
              setExportErrors(error.response.data.errors);
            }
          }
        } else {
          setIsAuthenticationDialogOpen(true);
        }
        break;
      }
    }
  }, [taskState, usernameAndPassword, handleExportToEtran]);
  const onClose = useCallback(() => {
    setIsAuthenticationDialogOpen(false);
    setIsCodeDialogOpen(false);
  }, []);

  const onAuthenticationSuccess = useCallback(
    async (loginDetails?: EtranCredentials) => {
      if (!loginDetails) {
        return;
      }
      setUsernameAndPassword(loginDetails);
      if (codeSaved) {
        setIsAuthenticationDialogOpen(false);
        await handleExportToEtran(loginDetails);
      } else {
        setTaskState('authenticated');
        setIsCodeDialogOpen(true);
      }
    },
    [codeSaved, handleExportToEtran],
  );
  const onCodeSubmit = useCallback(async () => {
    setTaskState('readyToExport');
    await handleExportToEtran(usernameAndPassword);
  }, [handleExportToEtran, usernameAndPassword]);
  const clearErrors = useCallback(() => {
    setExportErrors([]);
  }, []);

  const dialogMode: EtranDialogMode = useMemo(() => {
    if (usernameAndPassword.username && usernameAndPassword.password && !codeSaved) {
      return 'code';
    } else if (!usernameAndPassword.username && !usernameAndPassword.password) {
      return 'auth';
    }
  }, [usernameAndPassword, codeSaved]);

  const onDialogSuccess = useCallback(
    async (loginDetails?: EtranCredentials) => {
      await (!loginDetails ? onCodeSubmit() : onAuthenticationSuccess(loginDetails));
    },
    [onAuthenticationSuccess, onCodeSubmit],
  );
  const payloadErrorStatus: EtranPayloadErrorStatus = useMemo(
    () => ({
      applicationHasErrors: !!payloadPreview?.payload?.validationErrors?.application?.validationErrors?.length,
      businessesHaveErrors: payloadPreview?.payload?.validationErrors?.businesses?.some((b) => b.validationErrors?.length),
      peopleHaveErrors: payloadPreview?.payload?.validationErrors?.people?.some((p) => p.validationErrors?.length),
    }),
    [payloadPreview],
  );

  return {
    payloadErrorStatus,
    payload: payloadPreview?.payload,
    clearErrors,
    loading: loadingAuthStatus || fetchingPreview,
    exportErrors,
    isAuthenticationDialogOpen,
    isCodeDialogOpen,
    taskState,
    onNextStepClick,
    onClose,
    onAuthenticationSuccess,
    onCodeSubmit,
    hasCode: codeSaved,
    showDialog: isAuthenticationDialogOpen || isCodeDialogOpen,
    dialogMode,
    onDialogSuccess,
    exporting,
  };
};
