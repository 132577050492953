import type { FC, ReactNode } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { MRT_Column, MRT_TableInstance } from 'material-react-table';
import { ChevronRightOutlined } from '@mui/icons-material';
import { Button, Flex, Text } from '@lama/design-system';
import type { DocumentLineItem } from '@lama/document-service-client';
import { isSameDay } from 'date-fns';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { getUTCDateFromZonedTime, parseUTCDatetoString } from '@lama/data-formatters';
import { getUpdateLineItemDates } from '../utils/getUpdatedLineItemValues';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import { useUpdateDocumentLineItemsMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import 'react-datepicker/dist/react-datepicker.css';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import type { ColumnMeta } from '../utils/createTableColumns';
import { StyledDatePickerWrapper } from './StyledDatePicker';

export interface YearColumnDateRangeSelectProps {
  closeMenu: () => void;
  column: MRT_Column<DocumentLineItem>;
  internalColumnMenuItems: ReactNode[];
  table: MRT_TableInstance<DocumentLineItem>;
  numberOfPeriods: number;
  lineItems: DocumentLineItem[];
  startDate?: Date;
  endDate?: Date;
}

export const YearColumnDateRangeSelect: FC<YearColumnDateRangeSelectProps> = ({
  column,
  lineItems,
  startDate: initialStartDate,
  endDate: initialEndDate,
  closeMenu,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate ? getUTCDateFromZonedTime(initialStartDate) : null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ? getUTCDateFromZonedTime(initialEndDate) : null);

  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: updateDocumentLineItems, isPending: savingLineItemDates } = useUpdateDocumentLineItemsMutation();

  const displayStartDate = useMemo(() => (startDate ? parseUTCDatetoString(startDate) : ''), [startDate]);
  const displayEndDate = useMemo(() => (endDate ? parseUTCDatetoString(endDate) : ''), [endDate]);

  const startDateDirty = useMemo(
    () => startDate && initialStartDate && !isSameDay(startDate, initialStartDate),
    [startDate, initialStartDate],
  );

  const endDateDirty = useMemo(() => endDate && initialEndDate && !isSameDay(endDate, initialEndDate), [endDate, initialEndDate]);
  const updateLineItemDates = useCallback(
    async (startISODate: string, endISODate: string) => {
      const { startDate: columnStartDate, endDate: columnEndDate } = column.columnDef.meta as ColumnMeta;

      if (!startISODate || !endISODate || !userId || !columnStartDate || !columnEndDate) {
        return;
      }

      const lineItemsUpdates = lineItems.map((lineItem) => ({
        id: lineItem.id,
        values: getUpdateLineItemDates(
          lineItem,
          columnStartDate.toISOString(),
          columnEndDate.toISOString(),
          startISODate,
          endISODate,
          userId,
        ),
      }));

      await updateDocumentLineItems({
        documentId: currentDocument.id,
        lineItemUpdateBody: {
          lineItemUpdates: lineItemsUpdates,
        },
      });
    },
    [column.columnDef.meta, userId, lineItems, updateDocumentLineItems, currentDocument.id],
  );

  const onChange = useCallback(async (dates: [Date | null, Date | null]) => {
    const [timezoneStartDate, timezonedEndDate] = dates;

    if (!timezoneStartDate) {
      return;
    }

    const startDateChange = getUTCDateFromZonedTime(timezoneStartDate);
    const endDateChange = timezonedEndDate ? getUTCDateFromZonedTime(timezonedEndDate) : null;

    setStartDate(startDateChange);
    setEndDate(endDateChange);
  }, []);

  const saveLineItemDates = useCallback(async () => {
    if (startDate && endDate) {
      try {
        await updateLineItemDates(startDate.toISOString(), endDate.toISOString());
      } catch {
        toast.error('Failed to update. Please contact support.');
      } finally {
        closeMenu();
      }
    }
  }, [startDate, endDate, updateLineItemDates, closeMenu]);

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Flex flexDirection={'row'} gap={1} alignItems={'center'} p={1} justifyContent={'center'}>
        <Flex
          flexDirection={'column'}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          py={2}
          border={!startDateDirty && !endDateDirty ? '1px dashed #714EF5' : undefined}
          borderRadius={'4px'}
        >
          <Flex minWidth={'80px'} alignItems={'center'} justifyContent={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {startDate ? displayStartDate : 'N/A'}
            </Text>
          </Flex>
        </Flex>
        <ChevronRightOutlined color={'disabled'} />
        <Flex
          flexDirection={'column'}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          py={2}
          border={startDateDirty && !endDateDirty ? '1px dashed #714EF5' : undefined}
          borderRadius={'4px'}
        >
          <Flex minWidth={'80px'} alignItems={'center'} justifyContent={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {endDate ? displayEndDate : 'N/A'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <StyledDatePickerWrapper>
        <DatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          disabled={savingLineItemDates}
          onChange={onChange}
          dropdownMode={'select'}
          showMonthDropdown
          showYearDropdown
          selectsRange
          inline
        />
      </StyledDatePickerWrapper>
      <Button
        variant={'primary'}
        color={'primary'}
        onClick={saveLineItemDates}
        disabled={!startDate || !endDate}
        loading={savingLineItemDates}
        mx={2}
      >
        {'Save'}
      </Button>
    </Flex>
  );
};
