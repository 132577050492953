import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { OpportunityClosingTask } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityClosingTasksQuery = (opportunityId: string, options?: UseQueryOptions<OpportunityClosingTask[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityClosingTasksQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return applicationServiceClient.getOpportunityClosingTasks(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);

  const query = useQuery<OpportunityClosingTask[]>({
    queryKey: ['closingTasks', opportunityId],
    queryFn: getOpportunityClosingTasksQuery,
    ...options,
  });

  return query;
};
