import { useCallback } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { CreateIssueRequestBody } from '@lama/document-service-client';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { documentServiceUrl } from '../../../../../../../framework/environment';

const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

export const useCreateDocumentIssueMutation = (opportunityId?: string, applicationId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const createDocumentIssue = useCallback(
    async ({ documentId, issue }: { documentId: string; issue: CreateIssueRequestBody }) => {
      const token = await getAccessTokenSilently();
      await documentServiceClient.createDocumentIssue(documentId, issue, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: createDocumentIssue,
    onSuccess: async () => {
      if (applicationId) {
        await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', applicationId] });
        await queryClient.invalidateQueries({ queryKey: ['spreadingDocuments', applicationId] });
      }
      if (opportunityId) {
        await queryClient.invalidateQueries({ queryKey: ['opportunityRequirements', opportunityId] });
      }
    },
  });
};
