import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { Divider } from '@mui/material';
import type { FieldFilter } from './FieldFilterButton';
import { FieldFilterButton } from './FieldFilterButton';

interface FieldFiltersProps {
  setFieldFilter: (filter: FieldFilter) => void;
  filters: { filter: FieldFilter; text: string; count: number }[];
  currentFilter: FieldFilter;
}

export const FieldFilters: FC<FieldFiltersProps> = ({ setFieldFilter, currentFilter: fieldFilter, filters }) => (
  <Flex px={4} pt={3} pb={3} gap={4} flexDirection={'column'}>
    <Flex alignItems={'center'} justifyContent={'center'} gap={6}>
      {filters.map(({ filter, text, count }) => (
        <FieldFilterButton
          active={fieldFilter === filter}
          filter={filter}
          setFilter={setFieldFilter}
          text={text}
          count={count}
          key={filter}
        />
      ))}
    </Flex>
    <Divider />
  </Flex>
);
