import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { annualRateSelector, monthlyPaymentSelector } from '@lama/selectors';
import { proposedLoanAmount as proposedLoanAmountSelector } from '@lama/data-formatters';
import { isNil } from 'lodash-es';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getRateType } from '../../../../shared/LoanTerms/termsUtils';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsPaymentStream: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);

  const proposedLoanAmount = useMemo(() => proposedLoanAmountSelector(application), [application]);

  const proposedLoanMonthlyPayment = useMemo(() => {
    const annualRate = annualRateSelector({
      entity: application,
      entityType: 'application',
      application,
      yearsBack: 0,
    });

    if (!annualRate || !opportunity.terms?.termLength || isNil(proposedLoanAmount)) {
      return null;
    }

    return monthlyPaymentSelector(annualRate, opportunity.terms.termLength, proposedLoanAmount);
  }, [application, opportunity.terms?.termLength, proposedLoanAmount]);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const rateType = getRateType(opportunity.terms?.rate);

    return [
      {
        label: 'Length of Term',
        values: opportunity.terms?.termLength,
      },
      {
        label: 'Rate Type',
        values: rateType,
      },
      {
        label: 'Payment',
        values: proposedLoanMonthlyPayment,
        type: 'currency',
      },
      {
        label: 'Frequency',
        values: opportunity.terms?.paymentPeriod,
      },
      {
        label: 'Interest Payment Frequency',
        values: opportunity.terms?.paymentPeriod,
      },
      {
        label: 'Effective Date',
        values: opportunity.originationDate,
        type: 'date',
      },
    ];
  }, [
    opportunity.originationDate,
    opportunity.terms?.paymentPeriod,
    opportunity.terms?.rate,
    opportunity.terms?.termLength,
    proposedLoanMonthlyPayment,
  ]);

  return (
    <SegmentContainer title={'Payment Stream'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
