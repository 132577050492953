import axios from 'axios';
import saveAs from 'file-saver';
import type { GetAttributesResponse, FinancialData } from '@lama/contracts';
import type { DocumentLineItem, DocumentPage, SpreadingDocument } from '@lama/document-service-client';
import { financialsFormTypes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/utils/financialsFormTypes';
import { getFormAttributesWithFinancialData, isFinancialRelatedToFormPage } from '../../Forms/TaxReturns/formFinancialsUtils';

export interface ExpectedResult {
  formType: string;
  page: string;
  formPage: string;
  attributes?: any[];
  lineItems?: any[];
}

const parseTaxReturnForm = (
  formFinancials: FinancialData[],
  formAttributes: GetAttributesResponse['attributes'],
  formPages: DocumentPage[],
) => {
  const financialsWithAttributes = getFormAttributesWithFinancialData(formAttributes, formFinancials ?? [], formPages);
  return formPages.map(({ formType, formPage, page }) => ({
    formType,
    page: page.toString(),
    formPage: formPage.toString(),
    attributes: financialsWithAttributes
      .filter(({ attribute }) => Number(attribute.page) === formPage)
      .map(({ attribute, financialData }) => ({
        value: financialData?.value,
        attributeName: attribute.financialData?.type ?? '',
        startDate: attribute.financialData?.startDate ?? '',
        endDate: attribute.financialData?.endDate ?? '',
      })),
  }));
};

const parseFinancialStatementsForm = (lineItems: DocumentLineItem[], formPages: DocumentPage[]) =>
  formPages.flatMap(({ formType, formPage, page }) => {
    const relevantLineItems = lineItems.filter(({ page: lineItemPage }) => page === lineItemPage);
    const lineItemsIdToIndex = Object.fromEntries(relevantLineItems.map(({ id }, index) => [id, index]));

    return {
      formType,
      page: page.toString(),
      formPage: formPage.toString(),
      lineItems: lineItems
        .filter(({ page: lineItemPage }) => page === lineItemPage)
        .map(({ id, text, page: lineItemPage, attribute, parentId, values }, index) => ({
          id: lineItemsIdToIndex[id] ?? index,
          text,
          page: lineItemPage?.toString() ?? '',
          attribute: attribute ?? undefined,
          parentId: parentId ? lineItemsIdToIndex[parentId] : undefined,
          values,
        })),
    };
  });

export const exportSampleData = async (
  currentDocument: SpreadingDocument,
  documentPagesByFormType: DocumentPage[][],
  entityFinancials: FinancialData[],
  attributes: GetAttributesResponse['attributes'],
  lineItems: DocumentLineItem[],
) => {
  const sampleData = documentPagesByFormType.flatMap<ExpectedResult>((formPages) => {
    const { formType } = formPages.at(0)!;
    const formDocumentPageNumbers = formPages.map(({ page }) => page);
    if (financialsFormTypes.has(formType)) {
      return parseFinancialStatementsForm(lineItems, formPages);
    }
    const formAttributes = attributes.filter(
      ({ formId, entityType }) => formId === formType && entityType === currentDocument.relatedEntityType,
    );
    const formFinancials = entityFinancials.filter(({ source }) =>
      isFinancialRelatedToFormPage(source, currentDocument.id, formDocumentPageNumbers),
    );
    return parseTaxReturnForm(formFinancials, formAttributes, formPages);
  });

  const dataBlob = new Blob([JSON.stringify(sampleData)], {
    type: 'application/zip',
  });

  saveAs(dataBlob, `${currentDocument.id}_expectedResults.json`);

  if (currentDocument.previewUrl) {
    const pdfFile = await axios.get(currentDocument.previewUrl, { responseType: 'blob' });

    const pdfBlob = new Blob([pdfFile.data], {
      type: 'application/zip',
    });

    saveAs(pdfBlob, `${currentDocument.id}.pdf`);
  }
};
