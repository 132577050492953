import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { PartnerApiModel } from '@lama/partner-service-client';
import { createPartnerServiceClient } from '@lama/partner-service-client';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { partnerServiceBaseUrl } from '../../../../framework/environment';

const partnerServiceClient = createPartnerServiceClient(partnerServiceBaseUrl);

export const useGetPartnerQuery = (
  partnerId?: string,
  options?: Partial<UseQueryOptions<PartnerApiModel | null>>,
): UseQueryResult<PartnerApiModel | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getPartnerQueryFunction = useCallback(async () => {
    if (!partnerId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    return partnerServiceClient.getPartner(partnerId, token);
  }, [getAccessTokenSilently, partnerId]);

  const query = useQuery<PartnerApiModel | null>({ queryKey: ['partner', partnerId], queryFn: getPartnerQueryFunction, ...options });

  return query;
};
