import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import type { ConditionField, ConditionStatus } from '../../../../../shared/types/conditionTypes';
import { ConditionFieldCard } from './ConditionFieldCard';

interface ConditionListSectionProps {
  chargeOffPrediction?: number;
  fields: ConditionField[];
  status: ConditionStatus;
}

export const ConditionsListSectionContent: FC<ConditionListSectionProps> = ({ chargeOffPrediction, fields, status }) => (
  <Flex flexDirection={'column'} gap={8}>
    <Flex pb={2} alignItems={'center'} justifyContent={'space-between'}>
      {chargeOffPrediction ? (
        <Flex backgroundColor={'primary.light'} borderRadius={40}>
          <Text size={12} px={2} py={1}>
            {`Charge-off prediction ${chargeOffPrediction}%`}
          </Text>
        </Flex>
      ) : null}
    </Flex>
    <Flex flexDirection={'column'} gap={4} borderRadius={0}>
      {fields.map((field) => (
        <ConditionFieldCard key={field.name} conditionField={field} conditionStatus={status} />
      ))}
    </Flex>
  </Flex>
);
