import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../../shared/clients/applicationServiceClient';

interface RecrateOpportunityMutationVariables {
  opportunityId: string;
}

export const useRecreateOpportunityMustation = (
  options?: UseMutationOptions<RecrateOpportunityMutationVariables, unknown, RecrateOpportunityMutationVariables>,
) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ opportunityId }) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.recreateOpportunityRelatedEntities(opportunityId, token);

      return { opportunityId };
    },
    onSuccess: async ({ opportunityId }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem recreating the opportunity. Please try again.', 'error');
    },
    ...options,
  });
};
