import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createApplicationServiceClient } from '@lama/clients';
import { applicationServiceBaseUrl } from '../../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useCreateNewThreadForOpportunityMutation = (opportunityId: string, options: UseMutationOptions<void, unknown> = {}) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.createNewThreadForOpportunity(opportunityId, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
      ]);
    },
    ...options,
  });
};
