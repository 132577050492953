/* eslint-disable prefer-destructuring */
import type { PropertiesRecord, Property } from '@lama/contracts';
import { keyBy } from 'lodash-es';
import type { DeclartiveCalculationDefinition, FinancialSelectorContext } from '@lama/selectors';
import { declarativeCalculations, getFieldValuesForCalculation } from '@lama/selectors';
import { formatValue } from '@lama/data-formatters';
import type { ApplicationApiModel, OpportunityApiModel, PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';

const operatorToSymbol: Partial<Record<keyof DeclartiveCalculationDefinition, string>> = {
  add: '+',
  subtract: '-',
};

export const populateAttributeNames = (input: string, allProperties: PropertiesRecord) => {
  const regex = /%([^%]+)%/g;
  const matches = [...input.matchAll(regex)];
  const properties = keyBy(Object.values(allProperties), 'financialAttribute');

  matches.forEach((match) => {
    const attributeType = match[1];

    if (attributeType) {
      const property = properties[attributeType];

      input = input.replaceAll(`%${attributeType}%`, property?.displayName ?? attributeType);
    }
  });

  return input;
};

export const addBreakLineToCalculationExpression = (expression: string) => expression.replaceAll(/([*+÷-](?!\$))/g, '\n$&');

export const getAttributeCalculationExpression = ({
  property,
  allProperties,
  selectorContext,
  expressionTemplate,
}: {
  property: Property;
  allProperties: PropertiesRecord;
  selectorContext: FinancialSelectorContext<ApplicationApiModel | BusinessApiModel | OpportunityApiModel | PersonApiModel>;
  expressionTemplate?: string;
}): string | null => {
  if (!property.financialAttribute) {
    return null;
  }

  if (expressionTemplate) {
    const expressionWithAttributeNames = populateAttributeNames(expressionTemplate, allProperties);
    return addBreakLineToCalculationExpression(expressionWithAttributeNames);
  }

  const definition = declarativeCalculations[property.financialAttribute];

  if (!definition?.add?.length && !definition?.subtract?.length) {
    return null;
  }

  const fieldValues = getFieldValuesForCalculation(selectorContext, definition);

  const operatorDefinitions: { operator: keyof DeclartiveCalculationDefinition; value: string }[] = [
    ...(definition.add?.map((addValue) => ({ operator: 'add' as const, value: addValue })) ?? []),
    ...(definition.subtract?.map((subtractValue) => ({ operator: 'subtract' as const, value: subtractValue })) ?? []),
  ];

  return operatorDefinitions
    .map(({ operator, value }, idx) => {
      const financialValue = fieldValues[value]?.at(0)?.value;
      const formattedFinancialValue = formatValue(financialValue, 'currency') || null;

      return `${idx > 0 ? `${operatorToSymbol[operator]} ` : '   '}${value} (${formattedFinancialValue ?? '?'})`;
    })
    .join('\n');
};
