import type { FC } from 'react';
import React from 'react';
import type { ColDef } from '@ag-grid-community/core';

import { isAlertRelevantNow } from '../hooks/isAlertRelevantNow';
import type { CalculatedPipelineItem, CustomCellRendererParams } from '../types';
import { AlertCell } from './AlertCell';

const AlertCellRenderer: FC<CustomCellRendererParams> = ({ data }) => <AlertCell alerts={data?.alerts} />;

export const alertsColumnDefinition = ({ enableSorting = true }: { enableSorting: boolean }): ColDef<CalculatedPipelineItem> => ({
  headerName: 'Alerts',
  resizable: true,
  valueGetter: ({ data }) => {
    const alert = data?.alerts?.find(isAlertRelevantNow);
    return alert?.name;
  },
  filterValueGetter: ({ data }) => {
    const alert = data?.alerts?.find(isAlertRelevantNow);
    return alert?.name;
  },
  cellRenderer: AlertCellRenderer,
  cellStyle: { display: 'flex', alignItems: 'center', padding: '16px 16px' },
  minWidth: 250,
  sortable: enableSorting,
});
