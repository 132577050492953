import type { AuditEntryApiModel } from '@lama/audit-service-client';
import type { ExcelColumnSchema } from '@lama/reports-service-client';

export const auditExportExcelSchema: ExcelColumnSchema<AuditEntryApiModel>[] = [
  {
    column: 'Timestamp',
    type: Date,
    format: 'mm/dd/yyyy hh:mm:ss',
    value: (row) => new Date(row.timestamp),
    width: 15,
  },
  {
    column: 'Initiator',
    type: String,
    value: (row) => row.userName,
    width: 25,
  },
  {
    column: 'Entity',
    type: String,
    value: (row) => row.entityName,
    width: 25,
  },
  {
    column: 'Action',
    type: String,
    value: (row) => row.action,
    width: 15,
  },
  {
    column: 'Details',
    type: String,
    value: (row) => JSON.stringify(row.changes, null, 2),
    width: 18,
  },
];
