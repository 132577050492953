import type { FC } from 'react';
import React from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';

import { grey } from '@mui/material/colors';
import { Flex, Text } from '@lama/design-system';
import { funnelData } from './funnelData';

export const FlowAnalysisFunnel: FC = () => (
  <Flex flex={1} height={'400px'} width={'100%'} gap={4} flexDirection={'column'} p={2} border={`1px solid ${grey[300]}`}>
    <Text variant={'body1'}>{'Flow Analysis'}</Text>
    <ResponsiveFunnel
      data={funnelData}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      colors={{ scheme: 'green_blue' }}
      borderWidth={20}
      labelColor={{
        from: 'color',
        modifiers: [['darker', 3]],
      }}
      beforeSeparatorLength={100}
      beforeSeparatorOffset={20}
      afterSeparatorLength={100}
      afterSeparatorOffset={20}
      currentBorderWidth={40}
    />
  </Flex>
);
