import { useAuth0 } from '@auth0/auth0-react';
import type { PersonCreateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { peopleServiceClient } from '../../../clients/peopleServiceClient';

export const useCreatePerson = (opportunityId?: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ person }: { person: PersonCreateApiModel }) => {
      const token = await getAccessTokenSilently();

      return peopleServiceClient.createPerson(person, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityEvaluation', opportunityId],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while creating a person. Please contact support.', 'error');
    },
  });
};
