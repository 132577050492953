import React, { useContext, type FC, useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import { withdrawalReportExcelSchema } from '@lama/reports-service-client';
import writeXlsxFile from 'write-excel-file';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { useWithdrawalChartData } from '../Charts/hooks/useWithdrawalChartData';
import { ApplicationsWeeklyChart } from '../Charts/ApplicationsWeeklyChart';
import { applicationStatusToColors } from '../../../../shared/utils/applicationStatusToColors';

export const WithdrawlReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { mutateAsync: downloadWithdrawalReport, isPending: downloadingWithdrawalReport } = useDownloadReportMutation('Withdrawal');
  const { data: chartData, isPending: loadingChartData } = useWithdrawalChartData({ endDate, startDate, productId, partnerId });

  const onClickDownloadWithdrawalReport = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download Withdrawal report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download Withdrawal report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download Withdrawal report without a partner id", 'info');
      return;
    }

    const filename = `Withdrawal Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const data = await downloadWithdrawalReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await writeXlsxFile(data, {
      schema: withdrawalReportExcelSchema,
      fileName: filename,
    });
  }, [downloadWithdrawalReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || downloadingWithdrawalReport}
      actionText={'Download Report'}
      onClickAction={onClickDownloadWithdrawalReport}
      actionLoading={downloadingWithdrawalReport}
      loading={loadingChartData}
      title={'Withdrawal Report'}
    >
      <ApplicationsWeeklyChart chartData={chartData?.chartData} color={applicationStatusToColors.WithdrawalOfApprovedApplication.color} />
    </ReportCard>
  );
};
