import type { ReactElement } from 'react';
import React from 'react';

import { Flex, Text } from '@lama/design-system';
import type { InfoLineProps } from './InfoLine';
import { InfoLine } from './InfoLine';

export interface InformationStackProps {
  title: string;
  items: InfoLineProps[];
  subtitle?: ReactElement;
}

export const InformationStack: React.FC<InformationStackProps> = ({ title, items, subtitle }) => (
  <Flex minWidth={0} gap={subtitle ? 8 : 4} flexDirection={'column'}>
    <Flex flexDirection={'column'}>
      <Text variant={'body1'} color={'text.primary'}>
        {title}
      </Text>
      {subtitle ?? null}
    </Flex>
    <Flex gap={4} flexDirection={'column'}>
      {items.map((props) => (
        <InfoLine key={props.label} {...props} />
      ))}
    </Flex>
  </Flex>
);
