import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { User } from '@lama/user-service-client';
import { userServiceClient } from '../../../clients/userServiceClient';

export const useUsersByPartnerQuery = (partner?: string, options?: Partial<UseQueryOptions<User[]>>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const getUsersByPartner = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!partner) {
      return [];
    }

    const { users } = await userServiceClient.getUsersByPartner({ partner, type: 'lender' }, token);

    users.forEach((user) => {
      queryClient.setQueryData(['user', user.id], user);
    });

    return users;
  }, [getAccessTokenSilently, partner, queryClient]);

  const query = useQuery<User[]>({ queryKey: ['users', partner], queryFn: getUsersByPartner, ...options });

  return query;
};
