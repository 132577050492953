import React, { useMemo } from 'react';
import type { ApplicationStatus } from '@lama/contracts';
import type { AuditEntryApiModel } from '@lama/audit-service-client';
import type { FC } from 'react';
import type { AuditGroupInfo } from '../types';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import { applicationStatusAuditComponentMap } from './ApplicationStatusAuditTypes/applicationStatusAuditComponentMap';

interface ApplicationUpdateAuditLogProps {
  audit: AuditEntryApiModel[];
  groupInfo: AuditGroupInfo;
}

export const ApplicationStatusAuditLog: FC<ApplicationUpdateAuditLogProps> = ({ audit, groupInfo }) =>
  useMemo(() => {
    const [firstUpdate] = audit;
    if (!firstUpdate?.changes?.status) {
      return null;
    }

    const AuditComponent = applicationStatusAuditComponentMap[firstUpdate.changes.status as ApplicationStatus];

    if (!AuditComponent) {
      return <UpdateEntityAuditLog audit={[firstUpdate]} groupInfo={groupInfo} key={firstUpdate.id} />;
    }

    return <AuditComponent audit={firstUpdate} groupInfo={groupInfo} key={firstUpdate.id} />;
  }, [audit, groupInfo]);
