import React from 'react';
import type { FC } from 'react';
import { getAssetUrl } from '../../../shared/utils/getAssetUrl';

const defaultImage = getAssetUrl('deal-photos/dealCardExample.png');

interface DealCardImageProps {
  image?: string;
}

export const DealCardImage: FC<DealCardImageProps> = ({ image }) => (
  <img
    draggable={false}
    height={'140px'}
    style={{ objectFit: 'cover', borderRadius: '10px 10px 0 0', maxWidth: '320px' }}
    src={image || defaultImage}
    alt={'Deal Image'}
  />
);
