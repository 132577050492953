export const etranPropertiesToLabels: Record<string, string> = {
  ProcessingMethodCd: 'Processing Method',
  LenderCntctFirstName: 'Banker First Name',
  LenderCntctLastName: 'Banker Last Name',
  LenderCntctEmail: 'Email',
  LenderCntctPhnNmb: 'Phone',
  RequestedAmt: 'Requested Amount',
  LoanTermMnths: 'Loan Term in Months',
  SBAGntyPct: 'SBA Guarantee Percentage',
  InterestStructureCd: 'Interest Structure',
  FullAmortPymtInd: 'Full Amortization Payment',
  ProjectCityName: 'City',
  ProjectStCd: 'State',
  ProjectZipCd: 'Zip Code',
  ProjectStrtName1: 'Street Address',
  ProjectStrtName2: 'Street Address 2',
  BusinessPersonInd: 'Business or Person',
  LegalOrgnztnCd: 'Legal Organization',
  TaxId: 'Tax ID/SSN',
  BusinessName: 'Business Name',
  TradeName: 'Trade Name',
  PrimaryBusinessInd: 'Primary Business',
  ControlInterestInd: 'Control Interest',
  PhysCityName: 'City',
  PhysCountryCd: 'Country',
  PhysStCd: 'State',
  PhysStrtName1: 'Street Address',
  PhysStrtName2: 'Street Address 2',
  PhysZipCd: 'Zip Code',
  MailCityName: 'City (Mail address)',
  MailCountryCd: 'Country (Mail address)',
  MailStCd: 'State (Mail address)',
  MailStrtName1: 'Street Address (Mail address)',
  MailStrtName2: 'Street Address 2 (Mail address)',
  MailZipCd: 'Zip Code (Mail address)',
  FirstName: 'First Name',
  LastName: 'Last Name',
  MiddleInitial: 'Middle Initial',
  BirthDt: 'Date of Birth',
  BusinessTaxId: 'Business Tax ID',
  OwnrshpInBusinessPct: 'Ownership in Business (%)',
};

export const convertEtranPropertyToLabels = (property: string): string => etranPropertiesToLabels[property] ?? property;
