import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { documentDataExtractorServiceClient } from '../../../shared/clients/documentDataExtractorServiceClient';

export const useSyncSpreadingSample = (applicationId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { shouldSyncSpreadingSample } = useFlags();

  return useCallback(async () => {
    if (!shouldSyncSpreadingSample) {
      return;
    }
    const token = await getAccessTokenSilently();
    await documentDataExtractorServiceClient.syncSpreadingSample(applicationId, token);
  }, [applicationId, shouldSyncSpreadingSample, getAccessTokenSilently]);
};
