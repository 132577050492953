import React from 'react';
import type { FC } from 'react';
import { Button } from '@mui/material';
import { greyPalette, Text, Flex } from '@lama/design-system';

interface ExportLineProps {
  name: string;
  onExportClick: () => void;
  showButton?: boolean;
  buttonTitle?: string;
  exportDisabled?: boolean;
}

export const ExportLine: FC<ExportLineProps> = ({
  name,
  onExportClick,
  showButton = true,
  buttonTitle = 'Export',
  exportDisabled = false,
}) => (
  <Flex flexDirection={'row'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
    <Text variant={'body2'} color={'primary'}>
      {name}
    </Text>
    {showButton ? (
      <Button
        onClick={onExportClick}
        variant={'contained'}
        size={'small'}
        disabled={exportDisabled}
        sx={{
          borderRadius: '4px',
          color: 'text.secondary',
          backgroundColor: 'white',
          border: `1px solid ${greyPalette[300]}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            backgroundColor: greyPalette[50],
          },
        }}
      >
        {buttonTitle}
      </Button>
    ) : null}
  </Flex>
);
