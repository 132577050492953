import React from 'react';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';

import type { ConditionStatus } from '../../../../../shared/types/conditionTypes';
import { theme } from '../../../../../shared/styling/theme';
import { statusColorMapping } from './StatusFlag';

export const FlagReasonBanner: FC<{ status: ConditionStatus; flagReason: string }> = ({ status, flagReason }) => (
  <Box px={2} bgcolor={statusColorMapping[status].background} borderRadius={'4px'} height={'36px'} display={'flex'} alignItems={'center'}>
    <Typography variant={'body2'} color={theme.palette.text.secondary}>
      {flagReason}
    </Typography>
  </Box>
);
