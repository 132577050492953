/* eslint-disable @typescript-eslint/naming-convention */
import type { OpportunityApiModel } from '@lama/clients';
import { uniq } from 'lodash-es';

const descriptionTitleData = 'Description';

const payNotesPayableData = 'Pay Notes Payable';

const businessBackgroundData = 'Business Background';

const borrowerInformationData = 'Borrower Information';

const workingCapitalData = 'Working Capital';

const useOfProceedsData = 'Use of Proceeds';

const totalProjectCostData = 'Total Project Cost';

const guarantorsDetailsData = 'Guarantor Details';

const cbrCommentsData = 'CBR Comments';

const refinanceDetailsData = 'Refinance Details';

const payoffDateData = 'Payoff Date';

const payoffAmountData = 'Payoff Amount';

const reasonForRefinanceData = 'Reason for Refinance';

const assetPurchaseAgreementData = 'Asset Purchase Agreement';

const equityInjectionData = 'Equity Injection';

const businessValuationData = 'Business Valuation';

const inventoryData = 'Inventory';

const equipmentPurchaseData = 'Equipment Purchase';

const constructionProceedsData = 'Construction Proceeds';
const interestReserveData = 'Interest Reserve';

const realEstatePurchaseData = 'Real Estate Purchase';

const growthStartegyData = 'Growth Strategy';

const DEFAULT_TITLES = [descriptionTitleData, businessBackgroundData, borrowerInformationData];
export const uofToTitle: Record<string, string[]> = {
  'Working Capital': [workingCapitalData, useOfProceedsData, totalProjectCostData, guarantorsDetailsData, cbrCommentsData],
  'Debt Refinancing': [payNotesPayableData, refinanceDetailsData, payoffDateData, payoffAmountData, reasonForRefinanceData],
  'Business Acquisition': [
    assetPurchaseAgreementData,
    equityInjectionData,
    businessValuationData,
    growthStartegyData,
    useOfProceedsData,
    totalProjectCostData,
  ],
  Other: [],
  Inventory: [inventoryData, useOfProceedsData, totalProjectCostData],
  Startup: [equityInjectionData, businessValuationData, growthStartegyData, totalProjectCostData, useOfProceedsData],
  Equipment: [equipmentPurchaseData, useOfProceedsData, totalProjectCostData],
  Construction: [constructionProceedsData, interestReserveData],
  'Real Estate': [realEstatePurchaseData],
};

export const getCreditSummaryReportTitlesToGenerate = (opportunity: OpportunityApiModel) => {
  const useOfFunds = opportunity?.application?.useOfFunds;
  if (!useOfFunds?.length) {
    return;
  }
  const bestFittingTemplate = useOfFunds.flatMap((uof) => uofToTitle[uof] ?? []);
  return uniq([...DEFAULT_TITLES, ...bestFittingTemplate]);
};
