import React from 'react';
import type { FC } from 'react';

export const PersonIcon: FC = () => (
  <svg width={'17'} height={'16'} viewBox={'0 0 17 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <mask id={'mask0_16837_34749'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'17'} height={'16'}>
      <rect x={'0.263184'} width={'16'} height={'16'} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask0_16837_34749)'}>
      <path
        d={
          'M8.26318 7.79997C7.61874 7.79997 7.06874 7.5693 6.61318 7.10797C6.15763 6.64708 5.92985 6.09997 5.92985 5.46663C5.92985 4.82219 6.15763 4.27219 6.61318 3.81663C7.06874 3.36108 7.61874 3.1333 8.26318 3.1333C8.90763 3.1333 9.45763 3.36108 9.91318 3.81663C10.3687 4.27219 10.5965 4.82219 10.5965 5.46663C10.5965 6.09997 10.3687 6.64708 9.91318 7.10797C9.45763 7.5693 8.90763 7.79997 8.26318 7.79997ZM12.2465 12.8666H4.27985C3.99096 12.8666 3.74941 12.7695 3.55518 12.5753C3.36052 12.3806 3.26318 12.1444 3.26318 11.8666V11.3833C3.26318 11.0611 3.35207 10.7611 3.52985 10.4833C3.70763 10.2055 3.94652 9.98886 4.24652 9.8333C4.90207 9.51108 5.56607 9.2693 6.23852 9.10797C6.91052 8.94708 7.58541 8.86663 8.26318 8.86663C8.94096 8.86663 9.61607 8.94708 10.2885 9.10797C10.9605 9.2693 11.6243 9.51108 12.2799 9.8333C12.5799 9.98886 12.8187 10.2055 12.9965 10.4833C13.1743 10.7611 13.2632 11.0611 13.2632 11.3833V11.8666C13.2632 12.1444 13.1659 12.3806 12.9712 12.5753C12.777 12.7695 12.5354 12.8666 12.2465 12.8666ZM4.26318 11.8666H12.2632V11.3833C12.2632 11.25 12.2243 11.1277 12.1465 11.0166C12.0687 10.9055 11.9632 10.8111 11.8299 10.7333C11.2521 10.4555 10.6661 10.2415 10.0719 10.0913C9.47718 9.94152 8.87429 9.86663 8.26318 9.86663C7.65207 9.86663 7.04918 9.94152 6.45452 10.0913C5.86029 10.2415 5.27429 10.4555 4.69652 10.7333C4.56318 10.8111 4.45763 10.9055 4.37985 11.0166C4.30207 11.1277 4.26318 11.25 4.26318 11.3833V11.8666ZM8.26318 6.79997C8.62985 6.79997 8.94385 6.6693 9.20518 6.40797C9.46607 6.14708 9.59652 5.8333 9.59652 5.46663C9.59652 5.09997 9.46607 4.78597 9.20518 4.52463C8.94385 4.26375 8.62985 4.1333 8.26318 4.1333C7.89652 4.1333 7.58274 4.26375 7.32185 4.52463C7.06052 4.78597 6.92985 5.09997 6.92985 5.46663C6.92985 5.8333 7.06052 6.14708 7.32185 6.40797C7.58274 6.6693 7.89652 6.79997 8.26318 6.79997Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
    </g>
  </svg>
);
