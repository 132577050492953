/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Flex, Spinner, Text } from '@lama/design-system';
import { displayToast, useConfirmModal } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { referrerDisplayName } from '@lama/data-formatters';
import { ApplicationContext } from '../ApplicationContext';
import { usePartnerReferrersQuery } from '../../../shared/hooks/react-query/referrer/usePartnerReferrersQuery';
import { applicationServiceClient } from '../../../shared/clients/applicationServiceClient';
import { SubMenuHeader } from './SubMenuHeader';
import { ActionMenuSearchInput } from './ActionMenuSearchInput';

interface SetReferrerMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

export const SetReferrerMenu: FC<SetReferrerMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const {
    application: { id: applicationId, referredBy },
    opportunity: { partnerId: opportunityPartener, id: opportunityId },
  } = useContext(ApplicationContext);

  const [filterOptions, setFilterOptions] = useState('');
  const { t } = useTranslation();
  const { data: referrers } = usePartnerReferrersQuery(opportunityPartener);
  const { confirm } = useConfirmModal();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const { mutateAsync: setApplicationReferrer, isPending: mutating } = useMutation({
    mutationFn: async ({ referrerId }: { referrerId: string }) => {
      const token = await getAccessTokenSilently();
      await applicationServiceClient.setApplicationReferrer({ applicationId, referrerId }, token);
    },
    onSuccess: async () => {
      handleClose();
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
    onError: () => {
      displayToast('Something went wrong while setting the referrer. Please contact support.', 'error', { toastId: 'setReferrerError' });
    },
  });

  const onSetReferrer = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      const referrerId = event.currentTarget.getAttribute('value');

      if (!referrerId) {
        return;
      }

      const result = await confirm({
        title: t('setApplicationReferrerConfirmModal.title'),
        message: t('setApplicationReferrerConfirmModal.message'),
        cancelText: t('setApplicationReferrerConfirmModal.cancelText'),
        confirmText: t('setApplicationReferrerConfirmModal.confirmText'),
      });

      if (!result) {
        return;
      }

      await setApplicationReferrer({ referrerId });
    },
    [confirm, setApplicationReferrer, t],
  );

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const options = useMemo(() => {
    const referrerOptions = referrers
      ?.filter(({ id }) => id !== referredBy)
      .map((referrer) => ({ id: referrer.id, name: referrerDisplayName({ referrer }) }));

    return referrerOptions?.filter(({ name }) => `${name?.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase())) ?? [];
  }, [filterOptions, referredBy, referrers]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Flex flexDirection={'column'} gap={2} flex={1}>
        <SubMenuHeader onClick={onBack} text={'Referrers'} />
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Flex pt={2} px={2} maxHeight={'300px'} overflow={'auto'} flexDirection={'column'} flex={1}>
          {options.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={onSetReferrer}
              sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}
            >
              <Flex flexDirection={'row'} alignItems={'center'} flex={1} gap={6} pr={6}>
                <Text variant={'body1'} ellipsis>
                  {name}
                </Text>
                {mutating ? <Spinner size={'s'} /> : null}
              </Flex>
            </MenuItem>
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
