import React from 'react';
import type { FC } from 'react';
import type { DealApiModel } from '@lama/contracts';
import { differenceInMonths } from 'date-fns';
import { sentenceCase } from 'change-case-all';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';
import type { InfoLineProps } from '@lama/app-components';
import { InformationStack } from '@lama/app-components';
import { getShortAddressInfoLine } from '../../../shared/utils/informationLineUtils';

const InformationStacksFlexWrapper = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${greyPalette[300]};
  border-radius: 4px;
  padding: ${({ theme }) => theme.space[8]}px;
  flex: 0.5;
  gap: ${({ theme }) => theme.space[12]}px;
`;

const getBusinessInformationStackData = (
  { industryTypes, address, businessStartDate }: DealApiModel,
  useDemoBusinessDetails: boolean,
): InfoLineProps[] => [
  {
    label: 'Address',
    values: getShortAddressInfoLine(address),
  },
  {
    label: 'Business Type',
    values: industryTypes?.map((value: string) => sentenceCase(value)),
    type: 'array',
  },
  ...(useDemoBusinessDetails
    ? [
        {
          label: 'No. of Employees',
          values: '5',
        },
      ]
    : []),
  {
    label: 'Time in Business',
    values: businessStartDate ? `${Math.round((differenceInMonths(Date.now(), new Date(businessStartDate)) / 12) * 10) / 10} Years` : '',
  },
];

const getLoanInformationStackData = ({ requestedAmount, ficoScore, useOfFunds }: DealApiModel): InfoLineProps[] => [
  {
    label: 'Requested Amount',
    values: requestedAmount,
    type: 'currency',
  },
  {
    label: 'Owner FICO Score',
    values: ficoScore?.toString(),
  },
  {
    label: 'Use of Funds',
    values: useOfFunds,
    type: 'array',
  },
];

interface DealInformationProps {
  deal: DealApiModel;
}

export const DealInformation: FC<DealInformationProps> = ({ deal }) => {
  const { useDemoBusinessDetails } = useFlags<{ useDemoBusinessDetails: boolean }>();

  return (
    <Stack direction={'row'} spacing={3} width={'100%'}>
      <InformationStacksFlexWrapper>
        <InformationStack items={getBusinessInformationStackData(deal, useDemoBusinessDetails)} title={'Business Details'} />
      </InformationStacksFlexWrapper>
      <InformationStacksFlexWrapper>
        <InformationStack items={getLoanInformationStackData(deal)} title={'Loan Details'} />
      </InformationStacksFlexWrapper>
    </Stack>
  );
};
