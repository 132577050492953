import type { EtranPayloadResponse } from '@lama/clients';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { etranServiceClient } from '../../../clients/etranServiceClient';

export const useGetEtranPayload = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const getEtranPayload = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return etranServiceClient.getEtranPayload(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);
  const query = useQuery<{ payload: EtranPayloadResponse }>({
    queryKey: ['etran', opportunityId],
    queryFn: getEtranPayload,
  });
  return query;
};
