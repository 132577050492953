import React, { createContext, useContext } from 'react';
import type { FC } from 'react';
import type { PartnerApiModel } from '@lama/partner-service-client';

const PartnerContext = createContext<PartnerApiModel>(null!);

interface PartnerProviderProps {
  partner: PartnerApiModel;
  children: React.ReactNode;
}
export const PartnerProvider: FC<PartnerProviderProps> = ({ children, partner }) => {
  if (!partner) {
    throw new Error('PartnerProvider must be used within UserDetailsContext');
  }

  return <PartnerContext.Provider value={partner}>{children}</PartnerContext.Provider>;
};

export const useUserPartner = () => useContext(PartnerContext);
