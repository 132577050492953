/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { Tooltip } from '@lama/app-components';
import { CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useToggle } from 'react-use';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { Flex, Text } from '@lama/design-system';
import { isEmpty, omit } from 'lodash-es';
import { useUpdateRequirement } from '../../../../../shared/hooks/react-query/requirement/useUpdateRequirement';
import { menuProps } from '../../../ActionsMenu/ApplicationActionsMenu';
import { useUpdateRequirementV2 } from '../../../../../shared/hooks/react-query/requirement/useUpdateRequirementV2';
import { RequestAnotherDocumentModal } from './RequestAnotherDocumentModal';

interface RequirementHeaderActionsProps {
  requirement: EvaluatedOpportunityRequirement;
}

const MenuItemContent: FC<{ loading: boolean; text: string }> = ({ loading, text }) => (
  <Flex justifyContent={'space-between'} gap={4} width={'100%'}>
    <Text variant={'body1'}>{text}</Text>
    {loading ? <CircularProgress size={'20px'} /> : null}
  </Flex>
);

const useDismissOrMarkAsRequired = ({ id, opportunityId, status, entityId }: EvaluatedOpportunityRequirement) => {
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateRequirement(opportunityId, id);
  const { mutateAsync: updateRequirementV2, isPending: updatingRequirementV2 } = useUpdateRequirementV2(opportunityId, id);

  const dismissOrMarkAsRequired = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        statusOverrideByEntity: {
          [entityId]: status === 'DismissedByLender' ? null : 'DismissedByLender',
        },
      },
    });

    await updateRequirementV2({
      updateRequirementPayload: {
        dataByEntity: {
          [entityId]: {
            statusOverride: status === 'DismissedByLender' ? null : 'DismissedByLender',
          },
        },
      },
    });
  }, [entityId, status, updateRequirement, updateRequirementV2]);

  const loading = useMemo(() => updatingRequirement || updatingRequirementV2, [updatingRequirement, updatingRequirementV2]);

  return { dismissOrMarkAsRequired, loading };
};

const useMarkAsCompleteOrPending = ({ id, opportunityId, status, entityId }: EvaluatedOpportunityRequirement) => {
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateRequirement(opportunityId, id);
  const { mutateAsync: updateRequirementV2, isPending: updatingRequirementV2 } = useUpdateRequirementV2(opportunityId, id);

  const markAsCompleteOrPending = useCallback(async () => {
    await Promise.all([
      updateRequirement({
        updateRequirementPayload: {
          statusOverrideByEntity: {
            [entityId]: status === 'Complete' ? null : 'Complete',
          },
        },
      }),

      updateRequirementV2({
        updateRequirementPayload: {
          dataByEntity: {
            [entityId]: {
              statusOverride: status === 'Complete' ? null : 'Complete',
            },
          },
        },
      }),
    ]);
  }, [entityId, status, updateRequirement, updateRequirementV2]);

  const loading = useMemo(() => updatingRequirement || updatingRequirementV2, [updatingRequirement, updatingRequirementV2]);

  return { markAsCompleteOrPending, loading };
};

const useReopenRequirement = ({ id, opportunityId, submissions, entityId, entityType }: EvaluatedOpportunityRequirement) => {
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateRequirement(opportunityId, id);
  const { mutateAsync: updateRequirementV2, isPending: updatingRequirementV2 } = useUpdateRequirementV2(opportunityId, id);

  const reopenRequirement = useCallback(async () => {
    if (submissions) {
      await updateRequirement({
        updateRequirementPayload: {
          submissions: {},
        },
      });

      await updateRequirementV2({
        updateRequirementPayload: {
          dataByEntity: {
            [entityId]: { submission: null },
          },
        },
      });

      const isMultipliedEntityType = ['business', 'person'].includes(entityType);
      const submissionsExcludingCurrentEntity = omit(submissions, entityId);

      if (isMultipliedEntityType && !isEmpty(submissionsExcludingCurrentEntity)) {
        await Promise.all([
          updateRequirement({
            updateRequirementPayload: {
              submissions: submissionsExcludingCurrentEntity,
            },
          }),
        ]);
      }
    }
  }, [submissions, updateRequirement, updateRequirementV2, entityId, entityType]);

  const loading = useMemo(() => updatingRequirement || updatingRequirementV2, [updatingRequirement, updatingRequirementV2]);

  return { reopenRequirement, loading };
};

export const RequirementHeaderActions: FC<RequirementHeaderActionsProps> = ({ requirement }) => {
  const [open, toggleOpen] = useToggle(false);
  const [addDocumentModalOpen, toggleAddDocumentModal] = useToggle(false);
  const menuRef = useRef(null);

  const statusOverride = useMemo(
    () => requirement.statusOverride ?? requirement.statusOverrideByEntity?.[requirement.entityId],
    [requirement.entityId, requirement.statusOverride, requirement.statusOverrideByEntity],
  );

  const { dismissOrMarkAsRequired, loading: dismissingOrMarkingAsRequiredRequirement } = useDismissOrMarkAsRequired(requirement);
  const { markAsCompleteOrPending, loading: markingRequirementAsCompleteOrPending } = useMarkAsCompleteOrPending(requirement);
  const { reopenRequirement, loading: reopeningRequirement } = useReopenRequirement(requirement);

  const onDismissOrMarkAsRequiredClick = useCallback(async () => {
    await dismissOrMarkAsRequired();
    toggleOpen();
  }, [dismissOrMarkAsRequired, toggleOpen]);

  const isDocumentRequirementScreen = useMemo(() => requirement.screen === 'documentRequirement', [requirement.screen]);

  const onMarkCompletedOrPendingClick = useCallback(async () => {
    await markAsCompleteOrPending();
    toggleOpen();
  }, [markAsCompleteOrPending, toggleOpen]);

  const onReopenClick = useCallback(async () => {
    await reopenRequirement();
    toggleOpen();
  }, [reopenRequirement, toggleOpen]);

  const onOpenAddDocumentModal = useCallback(() => {
    toggleOpen();
    toggleAddDocumentModal();
  }, [toggleAddDocumentModal, toggleOpen]);

  return (
    <>
      <Tooltip title={'Actions'}>
        <IconButton
          onClick={toggleOpen}
          sx={{
            borderRadius: '8px',
            '&:hover': { bgcolor: 'primary.light' },
          }}
          ref={menuRef}
          role={'button'}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        key={'application-actions-menu'}
        anchorEl={menuRef.current}
        open={open}
        onClose={toggleOpen}
        {...menuProps}
        disableEnforceFocus
        MenuListProps={{ sx: { py: 0.5, px: 0.5 } }}
      >
        {statusOverride === 'Complete' ? (
          <MenuItem
            onClick={onMarkCompletedOrPendingClick}
            sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
            disabled={requirement.submitted || markingRequirementAsCompleteOrPending}
          >
            <MenuItemContent loading={markingRequirementAsCompleteOrPending} text={'Mark pending'} />
          </MenuItem>
        ) : !statusOverride && requirement.status !== 'Complete' ? (
          <MenuItem
            onClick={onMarkCompletedOrPendingClick}
            sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
            disabled={requirement.submitted || markingRequirementAsCompleteOrPending}
          >
            <MenuItemContent loading={markingRequirementAsCompleteOrPending} text={'Mark complete'} />
          </MenuItem>
        ) : null}
        {isDocumentRequirementScreen ? (
          <MenuItem
            onClick={onOpenAddDocumentModal}
            sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
            disabled={requirement.submitted}
          >
            <Text variant={'body1'}>{'Request additional document'}</Text>
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={onDismissOrMarkAsRequiredClick}
          sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
          disabled={requirement.submitted || dismissingOrMarkingAsRequiredRequirement}
        >
          <MenuItemContent
            loading={dismissingOrMarkingAsRequiredRequirement}
            text={requirement.status === 'DismissedByLender' ? 'Mark as required' : 'Dismiss requirement'}
          />
        </MenuItem>
        <MenuItem
          onClick={onReopenClick}
          sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
          disabled={!requirement.submitted || reopeningRequirement}
        >
          <MenuItemContent loading={reopeningRequirement} text={'Reopen'} />
        </MenuItem>
      </Menu>
      <RequestAnotherDocumentModal open={addDocumentModalOpen} onClose={toggleAddDocumentModal} requirement={requirement} />
    </>
  );
};
