import { useContext, useCallback } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import { useUpdateOpportunityClosingTaskMutation } from '../../useUpdateClosingTaskMutation';

export const useMarkTaskCompleted = (task: OpportunityClosingTask) => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutate: updateOpportunityClosingTask, isPending: updatingTaskStatus } = useUpdateOpportunityClosingTaskMutation(opportunity.id);
  const markAsComplete = useCallback(() => {
    const taskUpdate: Partial<OpportunityClosingTask> = { status: 'Complete', completedAt: new Date().toISOString() };
    if (!task.assigneeIds?.length && userId) {
      taskUpdate.assigneeIds = [userId];
    }
    updateOpportunityClosingTask({ taskId: task.id, taskUpdate });
  }, [task, updateOpportunityClosingTask, userId]);
  return { markAsComplete, markingTaskAsComplete: updatingTaskStatus };
};
