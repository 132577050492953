import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getRateType, FIXED_RATE_TYPE } from '../../../../shared/LoanTerms/termsUtils';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsPricingSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const rateType = getRateType(opportunity.terms?.rate);

    return [
      {
        label: rateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread',
        values: opportunity.terms?.rate?.spread,
      },
      {
        label: 'Rate Type',
        values: rateType,
      },
      {
        label: 'Pricing Frequency',
        values: opportunity.terms?.pricingFrequency,
      },
      {
        label: 'Index',
        values: opportunity.terms?.rate?.benchmarkRateType,
      },
      {
        label: 'All-In Rate',
        values: (opportunity.terms?.rate?.spread ?? 0) + (opportunity.terms?.rate?.benchmarkRate ?? 0),
      },
      {
        label: 'Term Length',
        values: opportunity.terms?.termLength,
      },
    ];
  }, [opportunity.terms?.pricingFrequency, opportunity.terms?.rate, opportunity.terms?.termLength]);

  return (
    <SegmentContainer title={'Pricing'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
