import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';

export const useLogin = () => {
  const { loginWithRedirect, isAuthenticated, user, isLoading, error } = useAuth0();

  useAsync(async () => {
    if (!isAuthenticated && !user && !isLoading && !error) {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [error, isAuthenticated, isLoading, loginWithRedirect, user]);

  return { isAuthenticated, user, error };
};
