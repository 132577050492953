import React, { useMemo } from 'react';

import type { FC } from 'react';

import { groupBy } from 'lodash-es';
import { Flex } from '@lama/design-system';
import type { AttributeWithFinancialData } from '../../../FinancialStatements/utils/financialAttribute';
import { sortScheduleAttributesByLine } from '../../sortAttributes';
import { TaxReturnsFormLine } from './TaxReturnsFormLine';

interface SpreadingFormFieldsProps {
  financialAttributes: AttributeWithFinancialData[];
  onFieldFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const TaxReturnFormFields: FC<SpreadingFormFieldsProps> = ({ financialAttributes: formAttributes, onFieldFocus, onFieldBlur }) => {
  const attributesByLineName = useMemo(() => {
    const attributesToDisplay = formAttributes.filter(({ attribute }) => attribute.displayData).sort(sortScheduleAttributesByLine);
    return groupBy(attributesToDisplay, ({ attribute }) => attribute.displayData!.displayLineName);
  }, [formAttributes]);

  const attributeLineNames = useMemo(() => Object.entries(attributesByLineName), [attributesByLineName]);

  return (
    <Flex flexDirection={'column'} gap={6}>
      {attributeLineNames.map(([lineName, financials]) => (
        <TaxReturnsFormLine
          key={lineName}
          lineName={lineName}
          financials={financials}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
        />
      ))}
    </Flex>
  );
};
