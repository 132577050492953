import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { displayToast } from '@lama/app-components';
import { etranServiceClient } from '../../../clients/etranServiceClient';

export const useSubmitCodeForEtran = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (etranCode: string) => {
      const token = await getAccessTokenSilently();
      return etranServiceClient.submitCode(opportunityId, etranCode, token);
    },
    onError: () => {
      displayToast('Something went wrong while authenticating with ETran. Verify the details you entered are correct.', 'error');
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['etranAuthStatus'],
      });
    },
  });
};
