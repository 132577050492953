import React, { lazy, useCallback } from 'react';
import type { FC } from 'react';
import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import { BalanceTooltip } from './BalanceTooltip';

const ResponsiveLine = lazy(() => import('@nivo/line').then((module) => ({ default: module.ResponsiveLine })));

export const BalanceChart: FC<{
  data: { id: string; data: { x: Date | number | string; y: Date | number | string }[] }[];
  granularityFormat: string;
}> = ({ data, granularityFormat }) => {
  const theme = useTheme();

  const xAxisFormat = useCallback((value: string) => format(new Date(value), granularityFormat), [granularityFormat]);

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 32, right: 48, bottom: 40, left: 48 }}
      xScale={{ type: 'time', min: 'auto', max: 'auto' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickValues: 4,
        tickPadding: 16,
        format: xAxisFormat,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 16,
        tickRotation: 0,
      }}
      curve={'monotoneX'}
      enableGridX={false}
      enableGridY={false}
      colors={[theme.palette.primary.main, '#D7CDFC']}
      pointSize={0}
      pointColor={'#D7CDFC'}
      pointBorderWidth={2}
      pointBorderColor={'#D7CDFC'}
      pointLabelYOffset={-12}
      enableArea
      areaOpacity={0.05}
      enableCrosshair={false}
      crosshairType={'top'}
      useMesh
      tooltip={BalanceTooltip}
      motionConfig={'gentle'}
    />
  );
};
