import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const SearchIcon: FC = () => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'25'} height={'24'} viewBox={'0 0 25 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_16528_178123'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'25'} height={'24'}>
        <rect x={'0.90918'} width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_16528_178123)'}>
        <path
          d={
            'M19.9346 20.05L14.1596 14.3C13.6596 14.7167 13.0846 15.0417 12.4346 15.275C11.7846 15.5083 11.1179 15.625 10.4346 15.625C8.7179 15.625 7.2679 15.0333 6.08457 13.85C4.90124 12.6667 4.30957 11.2167 4.30957 9.5C4.30957 7.8 4.90124 6.354 6.08457 5.162C7.2679 3.97067 8.7179 3.375 10.4346 3.375C12.1346 3.375 13.5762 3.96667 14.7596 5.15C15.9429 6.33333 16.5346 7.78333 16.5346 9.5C16.5346 10.2167 16.4179 10.9 16.1846 11.55C15.9512 12.2 15.6346 12.7667 15.2346 13.25L21.0096 19.025C21.1429 19.1583 21.2096 19.325 21.2096 19.525C21.2096 19.725 21.1346 19.9 20.9846 20.05C20.8346 20.2 20.6556 20.275 20.4476 20.275C20.2389 20.275 20.0679 20.2 19.9346 20.05ZM10.4346 14.125C11.7179 14.125 12.8056 13.675 13.6976 12.775C14.5889 11.875 15.0346 10.7833 15.0346 9.5C15.0346 8.21667 14.5889 7.125 13.6976 6.225C12.8056 5.325 11.7179 4.875 10.4346 4.875C9.13457 4.875 8.0389 5.325 7.14757 6.225C6.25557 7.125 5.80957 8.21667 5.80957 9.5C5.80957 10.7833 6.25557 11.875 7.14757 12.775C8.0389 13.675 9.13457 14.125 10.4346 14.125Z'
          }
          fill={'#714EF5'}
        />
      </g>
    </svg>
  </Box>
);
