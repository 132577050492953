import React from 'react';
import { Flex, Button, Text } from '@lama/design-system';
import type { FC, MutableRefObject, ReactNode } from 'react';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Skeleton, styled } from '@mui/material';

interface ReportCardProps {
  actionText?: string;
  onClickAction?: () => void;
  children: ReactNode;
  title?: string;
  actionLoading?: boolean;
  disableActions?: boolean;
  loading?: boolean;
  actionMenuRef?: MutableRefObject<null>;
}

// style the flex container with shadow on hover
const ReportCardContainer = styled(Flex)`
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 8px 25px 0px rgba(56, 0, 100, 0.08);
  }
`;

const LoadingSkeleton: FC = () => (
  <Flex height={'280px'} maxHeight={'280px'} width={'100%'} gap={8} alignItems={'flex-end'}>
    <Skeleton variant={'rectangular'} width={'100%'} height={'80%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'60%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'25%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'50%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'30%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'50%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'70%'} />
  </Flex>
);

export const ReportCard: FC<ReportCardProps> = ({
  children,
  actionText,
  disableActions,
  onClickAction,
  loading,
  actionLoading,
  title,
  actionMenuRef,
}) => (
  <ReportCardContainer
    flexDirection={'column'}
    border={'1px solid #E0E0E0'}
    borderRadius={4}
    p={6}
    pt={4}
    minHeight={'360px'}
    maxHeight={'450px'}
    backgroundColor={'white'}
    gap={2}
    justifyContent={'center'}
  >
    <Flex alignItems={'center'} justifyContent={'space-between'}>
      {title ? <Text variant={'body1'}>{title}</Text> : null}
      {actionText ? (
        <Button
          disabled={loading || disableActions}
          size={'s'}
          color={'neutral'}
          variant={'secondary'}
          onClick={onClickAction}
          loading={actionLoading}
          startIcon={<FileDownloadOutlined />}
          ref={actionMenuRef}
        >
          {actionText}
        </Button>
      ) : null}
    </Flex>
    {loading ? <LoadingSkeleton /> : children}
  </ReportCardContainer>
);
