import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { EtranLoginDetails } from '@lama/clients/dist/src/etranClient/types';
import { displayToast } from '@lama/app-components';
import { etranServiceClient } from '../../../clients/etranServiceClient';

export const useExportToEtran = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: async (loginDetails: EtranLoginDetails) => {
      const token = await getAccessTokenSilently();
      return etranServiceClient.exportToEtran(opportunityId, loginDetails, token);
    },
    onError: () => {
      displayToast('Something went wrong while authenticating with ETran. Verify the details you entered are correct.', 'error');
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
  });
  return mutateAsync;
};
