import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Popover, Select, Stack } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { LoadingButton } from '@mui/lab';

interface CreateDocumentErrorPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: string[];
  onSubmit: (text: string) => Promise<void>;
}

export const CreateDocumentErrorPopover: FC<CreateDocumentErrorPopoverProps> = ({ anchorEl, onSubmit, onClose, options }) => {
  const [reason, setReason] = React.useState<string>(options[0] ?? '');

  const updateText = React.useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      if (value) {
        setReason(value);
      }
    },
    [setReason],
  );

  const [{ loading: submitting }, onSubmitInternal] = useAsyncFn(async () => {
    await onSubmit(reason);
    onClose();
  }, [onSubmit, reason, onClose]);

  return (
    <Popover
      open={!!anchorEl}
      sx={{ mt: 1 }}
      PaperProps={{
        sx: {
          boxShadow: '0px 8px 32px 0px #3800641F',
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        },
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack gap={1} p={2} bgcolor={'white'} width={'500px'}>
        <Select value={reason} onChange={updateText}>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <LoadingButton loading={submitting} onClick={onSubmitInternal} variant={'contained'} color={'primary'} disabled={!reason}>
          {'Send Error'}
        </LoadingButton>
      </Stack>
    </Popover>
  );
};
