import type { ApplicationApiModel } from '@lama/clients';
import type { Collateral } from '@lama/contracts';
import { applicationCollateralSelector } from '@lama/selectors';

const calculatePersonalVehicleRate = (personalVehicleCollateral: Collateral, termInMonths: number) => {
  const year = personalVehicleCollateral?.vehicleDetails?.year ?? 0;
  if (year <= 2012) {
    return 7.84;
  }

  if (year < 2018) {
    if (termInMonths <= 36) {
      return 6.37;
    } else if (termInMonths <= 48) {
      return 6.5;
    } else if (termInMonths <= 60) {
      return 6.74;
    }
  }

  if (year < 2019) {
    return 7.06;
  }

  return 7.59;
};

const calculateCommercialVehicleRate = (personalVehicleCollateral: Collateral, termInMonths: number) => {
  const year = personalVehicleCollateral?.vehicleDetails?.year ?? 0;
  if (year <= 2010) {
    return 8.84;
  }

  if (year < 2017) {
    return 8.32;
  }

  if (year < 2018 && termInMonths <= 60) {
    return 7.74;
  }

  if (year < 2018 && termInMonths <= 72) {
    return 8.06;
  }

  return 8.59;
};

const calculateCommercialEquipmentRate = (requestedAmount: number, termInMonths: number) => {
  if (requestedAmount > 40_000 && termInMonths > 84) {
    return 8.5;
  }

  if (requestedAmount > 30_000 && termInMonths > 72) {
    return 8;
  }

  if (requestedAmount > 20_000 && termInMonths > 60) {
    return 7.5;
  }

  if (requestedAmount > 15_000 && termInMonths > 48) {
    return 7;
  }

  if (requestedAmount > 10_000 && termInMonths > 36) {
    return 6.5;
  }

  return 6.25;
};

export const getRecommendedRate = (application: ApplicationApiModel, termInMonths: number): number | undefined => {
  const collateral = applicationCollateralSelector({ application, entity: application, entityType: 'application', yearsBack: 0 });
  const personalVehicleCollateral = collateral?.find((c) => c.type === 'Personal Vehicle');

  if (personalVehicleCollateral) {
    return calculatePersonalVehicleRate(personalVehicleCollateral, termInMonths);
  }

  const commercialVehicleCollateral = collateral?.find((c) => c.type === 'Commercial Vehicle');

  if (commercialVehicleCollateral) {
    return calculateCommercialVehicleRate(commercialVehicleCollateral, termInMonths);
  }

  const equipmentCollateral = collateral?.find((c) => c.type === 'Equipment');

  if (equipmentCollateral && application.requestedAmount) {
    return calculateCommercialEquipmentRate(application.requestedAmount, termInMonths);
  }

  return undefined;
};
