import React from 'react';
import type { FC } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { customRequirementsViews } from '../CustomRequirementViews/customRequirementsViews';
import { RequirementPrintView } from './RequirementPrintView';

export const RenderRequirementView: FC<{ requirement: EvaluatedOpportunityRequirement }> = ({ requirement }) => {
  if (customRequirementsViews[requirement.screen]) {
    return customRequirementsViews[requirement.screen]?.({ requirement }) ?? null;
  }

  if (requirement.properties) {
    return <RequirementPrintView requirement={requirement} />;
  }

  return null;
};
