import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { businessDebtSelector } from '@lama/selectors';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { DebtSegment } from '../../../Application/shared/Debt/DebtSegment';

export const DebtView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses?.find((b) => b.business.id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  const businessDebt = useMemo(
    () => (business ? businessDebtSelector({ application, entity: business, entityType: 'business', yearsBack: 0 }) : undefined),
    [business, application],
  );

  if (!business) {
    return null;
  }

  if (!businessDebt) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'No information found'}</Typography>
      </RequirementPrintContainer>
    );
  }

  if (!businessDebt.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'Applicant marked no debt'}</Typography>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Stack gap={2}>
        {businessDebt.map((debt) => (
          <DebtSegment key={debt.id} debt={debt} />
        ))}
      </Stack>
    </RequirementPrintContainer>
  );
};
