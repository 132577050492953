import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { BarTooltipProps } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';

const BarToolTip: FC<BarTooltipProps<Record<string, number | string>>> = ({ data: { id, value } }) => (
  <Stack sx={{ backgroundColor: '#ffffff' }} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={0.5} px={1}>
    <Typography variant={'tooltip'}>{`${id} - ${value}`}</Typography>
  </Stack>
);

export const TreasuryOriginationChart: FC = () => (
  <Stack display={'flex'} flex={1} height={'400px'} width={'100%'} spacing={1} p={2} sx={{ border: `1px solid ${grey[300]}` }}>
    <Typography variant={'body1'}>{'Treasury Origination'}</Typography>
    <ResponsiveBar
      data={[
        { id: 'Opening', status: 'Opening', value: 3 },
        { status: 'Verifcation', value: 6, id: 'Verifcation' },
        { status: 'Review', value: 10, id: 'Review' },
        { status: 'Approved', value: 8, id: 'Approved' },
        { status: 'Rejected', value: 2, id: 'Rejected' },
      ]}
      margin={{ top: 50, right: 0, bottom: 50, left: 25 }}
      padding={0.2}
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      indexBy={'status'}
      colorBy={'indexValue'}
      colors={['#DCD4FD', '#FFDDA6', '#C1E2F4', '#BEEEDF', '#F9C3C1']}
      keys={['value']}
      borderRadius={4}
      tooltip={BarToolTip}
      legends={[
        {
          dataFrom: 'indexes',
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          symbolShape: 'circle',
          translateX: -20,
          translateY: -40,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  </Stack>
);
