/* eslint-disable react/jsx-no-bind */
import React from 'react';
import type { FC } from 'react';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { Autocomplete, TextField } from '@mui/material';
import { Flex, Text } from '@lama/design-system';
import DatePicker from 'react-datepicker';
import { StatusFlagOutlined } from '../StatusFlag';
import type { ConditionStatus } from '../../../../../../shared/types/conditionTypes';
import { StyledDatePickerWrapper } from '../../../../../Spreading/Forms/FinancialStatements/components/StyledDatePicker';

const riskOptions = ['Low Risk - 2 Years', 'Medium Risk - 1.5 Years', 'High Risk - 1 Year'];

interface ScoreCardData {
  label: string;
  value: string;
  status: ConditionStatus;
}

const scoreCardData: { label: string; value: string; status: ConditionStatus }[] = [
  {
    label: 'MSB/Consumer Debits/3rd Party Sender',
    value: 'No',
    status: 'approved',
  },
  {
    label: 'CCD Debit Window',
    value: '4',
    status: 'pending',
  },
  {
    label: 'Approved Batch Limits',
    value: '1',
    status: 'approved',
  },
  {
    label: 'Consumer (PPD) Debits, WEB or TEL',
    value: '5',
    status: 'rejected',
  },
  {
    label: 'Payroll Window Approved For',
    value: '5',
    status: 'rejected',
  },
  {
    label: 'Payroll Approved For',
    value: '1',
    status: 'approved',
  },
  {
    label: 'Transaction Category',
    value: '3',
    status: 'pending',
  },
  {
    label: 'Compliance / Nacha Return Rates',
    value: '1',
    status: 'approved',
  },
  {
    label: 'Proper use of SEC Codes',
    value: '3',
    status: 'approved',
  },
  {
    label: 'Years In Business',
    value: '3',
    status: 'approved',
  },
  {
    label: 'History With Kotapay',
    value: '5',
    status: 'rejected',
  },
  {
    label: 'NSF History',
    value: '1',
    status: 'approved',
  },
];

const columns: MRT_ColumnDef<ScoreCardData>[] = [
  {
    id: 'category',
    header: 'Category',
    accessorKey: 'label',
    Cell: ({ cell }) => <Text variant={'body2'}>{cell.getValue<string>()}</Text>,
  },
  {
    id: 'weightedScore',
    header: 'Weighted Score',
    accessorFn: (row) => row,
    size: 40,
    maxSize: 40,
    Cell: ({ cell }) => {
      const { value, status } = cell.getValue<ScoreCardData>();

      return (
        <Flex gap={4} alignItems={'center'}>
          <StatusFlagOutlined status={status} />
          <Text variant={'body2'}>{value}</Text>
        </Flex>
      );
    },
  },
];

export const FibtScoreCard: FC = () => {
  const [reviewDate, setReviewDate] = React.useState<Date | null>(new Date('2026-03-16'));

  return (
    <Flex flexDirection={'column'} gap={16}>
      <Flex flexDirection={'column'} gap={4}>
        <MaterialReactTable
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          enablePagination={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableSorting={false}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: '#F5F5F5',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              border: 'solid #0000001F',
              borderWidth: '1px 0px 0px 0px',
            },
          }}
          muiTableContainerProps={{
            sx: {
              border: 'solid #E0E0E0',
              borderWidth: '1px',
              borderRadius: '4px',
            },
          }}
          muiTablePaperProps={{
            sx: {
              boxShadow: 'none',
            },
          }}
          columns={columns}
          data={scoreCardData}
        />
        <Flex justifyContent={'flex-end'} alignItems={'center'} gap={4} pr={18}>
          <Text weight={700}>{'Risk Rating'}</Text>
          <StatusFlagOutlined status={'pending'} />
          <Text weight={700}>{'2.9'}</Text>
        </Flex>
      </Flex>
      <Flex width={'100%'} gap={4} justifyContent={'space-between'}>
        <Autocomplete
          options={riskOptions}
          renderInput={(params) => <TextField label={'Review Frequency'} {...params} />}
          sx={{ width: '300px' }}
          defaultValue={riskOptions[1]}
        />
        <StyledDatePickerWrapper>
          <DatePicker
            customInput={<TextField label={'Review Date'} variant={'outlined'} sx={{ width: '300px' }} />}
            selected={reviewDate}
            dropdownMode={'select'}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => {
              setReviewDate(date);
            }}
          />
        </StyledDatePickerWrapper>
      </Flex>
    </Flex>
  );
};
