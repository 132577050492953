import {
  add,
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  max,
  min,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import type { Granularity } from '../../types';

export const getPageCountByGranularity = (granularity: Granularity, firstDate: Date, lastDate: Date) => {
  const distance = differenceInCalendarDays(lastDate, firstDate);
  let count = 0;

  if (granularity === 'week') {
    count = distance / 7 / 6;
  } else if (granularity === 'month') {
    count = distance / 30.333 / 6;
  } else {
    count = distance / 365;
  }

  return Math.ceil(count);
};

export const getFirstAndLastDates = (dataWithDate: { date: string }[]) => {
  const transactionDates = dataWithDate.map(({ date }) => new Date(date));
  const last = max(transactionDates);
  const first = min(transactionDates);

  return { first, last };
};

export const getFirstAndLastPaddedDates = (firstDate: Date, lastDate: Date, granularity: Granularity) => {
  const firstPadded = granularity === 'week' ? startOfWeek(firstDate) : startOfMonth(firstDate);
  const lastPadded = granularity === 'week' ? endOfWeek(lastDate) : endOfMonth(lastDate);

  return { firstPadded, lastPadded };
};

export const getDataInRange = <T extends { date: string }>(data: T[], lastDate: Date, page: number, granularity: Granularity): T[] => {
  const afterDuration = granularity === 'week' ? { weeks: page * 6 } : granularity === 'month' ? { months: page * 6 } : { years: page * 6 };
  const beforeDuration =
    granularity === 'week' ? { weeks: (page - 1) * 6 } : granularity === 'month' ? { months: (page - 1) * 6 } : { years: (page - 1) * 6 };

  const afterValue = sub(lastDate, afterDuration);
  const beforeValue = sub(add(lastDate, { seconds: 1 }), beforeDuration);

  const filteredData = data.filter((datum) => {
    const date = new Date(datum.date);

    if (isAfter(date, afterValue) && isBefore(date, beforeValue)) {
      return true;
    }

    return false;
  });

  return filteredData;
};
