import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { peopleServiceClient } from '../../../../../../../shared/clients/peopleServiceClient';

export const useUpdateTradelineMutation = (opportunityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({
      personId,
      tradelineId,
      dismissed,
      dismissedReason,
    }: {
      personId: string;
      tradelineId: string;
      dismissed: boolean;
      dismissedReason?: string;
    }) => {
      const token = await getAccessTokenSilently();

      await peopleServiceClient.updateTradeline(personId, tradelineId, { dismissed, dismissedReason }, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['opportunity', opportunityId],
      });
    },
  });
};
