import React, { forwardRef } from 'react';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { Flex, Text } from '@lama/design-system';
import { personFullName } from '@lama/data-formatters';
import { CreditReport } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/creditReport/CreditReport';

interface CreditReportExportProps {
  person: RelatedPersonApiModel;
}

export const CreditReportExport = forwardRef<HTMLDivElement, CreditReportExportProps>(({ person }, ref) => (
  <div ref={ref} className={'hiddenContainer'}>
    <Flex gap={3} flexDirection={'column'}>
      <Text variant={'h4'}>{`${personFullName(person)}'s Credit Report`}</Text>
      <CreditReport person={person} tradelinesStartExpanded />
    </Flex>
  </div>
));
