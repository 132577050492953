import React, { Suspense, useCallback, useState } from 'react';
import type { FC } from 'react';
import type { AccountData } from '@lama/contracts';
import { LoadingPage } from '@lama/app-components';
import { ButtonGroup, Flex } from '@lama/design-system';
import type { ChartType, Granularity } from '../../types';
import { isGranularity, isChart } from '../../types';
import { Balances } from './Balances';
import { Transactions } from './Transactions';

export const Charts: FC<{ account: AccountData }> = ({ account }) => {
  const { balances, transactions } = account;
  const [chart, setChart] = useState<ChartType>('balance');
  const [granularity, setGranularity] = useState<Granularity>('month');

  const handleGranularityChange = useCallback(
    (value: string) => {
      if (value && isGranularity(value)) {
        setGranularity(value);
      }
    },
    [setGranularity],
  );

  const handleChartChange = useCallback(
    (value: string) => {
      if (value && isChart(value)) {
        setChart(value);
      }
    },
    [setChart],
  );

  return (
    <Suspense
      fallback={
        <Flex height={'470px'} width={'90%'} justifyContent={'center'} alignItems={'center'}>
          <LoadingPage />
        </Flex>
      }
    >
      <Flex flexDirection={'column'} gap={6}>
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <ButtonGroup
            value={chart}
            items={[
              { label: 'Balance', value: 'balance' },
              { label: 'P&L', value: 'profitAndLoss' },
            ]}
            onChange={handleChartChange}
          />
          {chart !== 'balance' && (
            <ButtonGroup
              value={granularity}
              items={[
                // 'week', 'month', 'year'
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
                { label: 'Year', value: 'year' },
              ]}
              onChange={handleGranularityChange}
            />
          )}
        </Flex>
        {chart === 'balance' ? (
          <Balances balances={balances} granularity={'month'} />
        ) : (
          <Transactions transactions={transactions} granularity={granularity} />
        )}
      </Flex>
    </Suspense>
  );
};
