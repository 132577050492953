import type { DealApiModel } from '@lama/contracts';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';

const getDealsByUseOfFunds = (deals: DealApiModel[]) =>
  deals.reduce<Record<string, DealApiModel[]>>((acc, deal) => {
    if (!deal.useOfFunds?.length) {
      acc.other = [...(acc.other || []), deal];
      return acc;
    }
    deal.useOfFunds.forEach((category) => {
      acc[category.toLowerCase()] = [...(acc[category.toLowerCase()] || []), deal];
    });

    return acc;
  }, {});

const getHighMatchDeals = (deals: DealApiModel[]) => ({
  highMatch: deals.filter((deal) => deal.matchingProducts?.length > 0),
});

export const useDealsByCategory = (deals: DealApiModel[]): Record<string, DealApiModel[]> => {
  const categories = useMemo(() => {
    const dealsByUseOfFunds = getDealsByUseOfFunds(deals);
    const highMatchingDeals = getHighMatchDeals(deals);

    const allCategories = { ...highMatchingDeals, ...dealsByUseOfFunds };

    const sortedCategories = Object.fromEntries(
      Object.entries(allCategories).map(([category, catDeals]) => [
        category,
        uniqBy(
          catDeals.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
          'id',
        ),
      ]),
    );

    return sortedCategories;
  }, [deals]);

  return categories;
};
