import React from 'react';
import type { FC } from 'react';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { UpdateFinancialsAuditLog } from './UpdateFinancialsAuditLog';
import { CreateFinancialsAuditLog } from './CreateFinancialsAuditLog';

export const FinancialsAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'Update') {
    return <UpdateFinancialsAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Create') {
    return <CreateFinancialsAuditLog audit={audit} groupInfo={groupInfo} />;
  }
  return null;
};
