/* eslint-disable react/no-array-index-key */
import type { FC, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { ComponentConfiguration, SegmentConfiguration } from '@lama/contracts';
import { Collapse, Flex, Text } from '@lama/design-system';
import { getOpportunityEntityByType } from '@lama/properties';
import { businessNameWithRelationsSelector, personNameWithRelationsSelector } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useToggle } from 'react-use';
import type { OpportunityApiModel } from '@lama/clients';
import { differenceBy, sortBy } from 'lodash-es';
import { entityRelationsPriority, getEntityPrimaryRelation } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { componentByType } from '../componentByType';
import ExpandButton from '../../../../shared/components/ExpandButton';

const getComponentByConfiguration = (componentConfiguration: ComponentConfiguration) =>
  componentByType[componentConfiguration.type === 'custom' ? componentConfiguration.customComponentName : componentConfiguration.type];

const getComponent = ({
  componentConfiguration,
  segmentIndex,
  componentIndex,
  entity,
  entityType,
  sectionId,
}: {
  componentConfiguration: ComponentConfiguration;
  segmentIndex: number;
  componentIndex: number;
  entity?: Record<string, any> & { id: string };
  entityType?: Entity;
  sectionId: string;
}): ReactNode => {
  const Component = getComponentByConfiguration(componentConfiguration);

  if (!Component) {
    return null;
  }

  return (
    <div key={`${sectionId}_${entity ? `${entity.id}_` : ''}${componentConfiguration.type}_${segmentIndex}_${componentIndex}`}>
      <Component componentConfiguration={componentConfiguration} entity={entity} entityType={entityType} />
    </div>
  );
};

const getSegmentEntities = (segment: SegmentConfiguration, opportunity: OpportunityApiModel) => {
  if (!segment.entityType) {
    return [];
  }

  const includedEntities = getOpportunityEntityByType(opportunity, segment.entityType, segment.entityGroups);
  const excludedEntities = segment.excludedEntityGroups
    ? getOpportunityEntityByType(opportunity, segment.entityType, segment.excludedEntityGroups)
    : [];

  const entities = differenceBy(includedEntities, excludedEntities, (e) => e.id);

  const sortedEntities = sortBy(entities, (entity) => {
    const primaryRelation = getEntityPrimaryRelation(entity.id, opportunity.application);

    if (!primaryRelation) {
      return 1;
    }

    return entityRelationsPriority.indexOf(primaryRelation);
  });

  return sortedEntities;
};

const getMultipliedEntitiesCount = (segments: SegmentConfiguration[], opportunity: OpportunityApiModel) =>
  segments.reduce((acc, segment) => {
    if (segment.entityType && segment.entityType !== 'application' && segment.entityType !== 'opportunity') {
      const entities = getSegmentEntities(segment, opportunity);
      return acc + entities.length;
    }
    return acc;
  }, 0);

const SegmentWrapper: FC<{ children: ReactNode; collapsable: boolean; entityType: Entity; entityName: string | null }> = ({
  children,
  collapsable,
  entityType,
  entityName,
}) => {
  const { enableCollapsableGenericSection } = useFlags();

  const collapsableEntity = useMemo(() => {
    if (entityType === 'application' || entityType === 'opportunity') {
      return false;
    }
    return true;
  }, [entityType]);

  const [expanded, toggleExpanded] = useToggle(!collapsableEntity || !collapsable);

  if (!enableCollapsableGenericSection || !collapsable || !collapsableEntity) {
    return (
      <Flex flexDirection={'column'} gap={8}>
        {entityName ? <Text variant={'h6'}>{entityName}</Text> : null}
        {children}
      </Flex>
    );
  }

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2}>
        {collapsable && collapsableEntity ? <ExpandButton onClick={toggleExpanded} expand={expanded} size={20} /> : null}
        {entityName ? <Text variant={'h6'}>{entityName}</Text> : null}
      </Flex>
      <Collapse expanded={expanded} defaultExpanded={expanded}>
        <Flex flexDirection={'column'} gap={8}>
          {children}
        </Flex>
      </Collapse>
    </Flex>
  );
};

interface GenericEntitySegmentProps {
  components: ComponentConfiguration[];
  sectionId: string;
  segmentIndex: number;
  entity: Record<string, any> & { id: string };
  entityType: Entity;
  collapsable: boolean;
}

const GenericEntitySegment: FC<GenericEntitySegmentProps> = ({ entity, entityType, components, sectionId, segmentIndex, collapsable }) => {
  const { fibtDemo } = useFlags();
  const { application } = useContext(ApplicationContext);

  const entityName = useMemo(() => {
    if (entityType === 'application' || entityType === 'opportunity') {
      return null;
    }
    const name =
      entityType === 'person'
        ? personNameWithRelationsSelector(entity.id, application)
        : businessNameWithRelationsSelector(entity.id, application);
    return fibtDemo ? name.replace('Borrower', 'Applicant') : name;
  }, [application, entity.id, entityType, fibtDemo]);

  const componentNodes = useMemo<ReactNode[]>(
    () =>
      components.map((componentConfiguration, i) =>
        getComponent({ componentConfiguration, segmentIndex, componentIndex: i, entity, entityType, sectionId }),
      ),
    [components, entity, entityType, sectionId, segmentIndex],
  );

  return (
    <SegmentWrapper collapsable={collapsable} entityType={entityType} entityName={entityName}>
      {componentNodes}
    </SegmentWrapper>
  );
};

export const GenericSection: FC<{
  segments: SegmentConfiguration[];
  sectionId: string;
  sectionEntity?: Record<string, any> & { id: string };
}> = ({ segments, sectionId, sectionEntity }) => {
  const { opportunity } = useContext(ApplicationContext);

  const collapsable = useMemo(() => {
    const entityCount = getMultipliedEntitiesCount(segments, opportunity);

    return entityCount > 1;
  }, [opportunity, segments]);

  const segmentComponents = useMemo<ReactNode[]>(
    () =>
      segments.flatMap((segment, segmentIdx) => {
        const segmentEntityType = segment.entityType;
        if (segmentEntityType) {
          if (sectionEntity) {
            console.error('Section configuration does not support both section entity and segment entity');
            return null;
          }

          return getSegmentEntities(segment, opportunity).map<ReactNode>((entity) => (
            <GenericEntitySegment
              entity={entity}
              entityType={segmentEntityType}
              components={segment.components}
              key={`${sectionId}_${entity.id}_${segmentIdx}`}
              sectionId={sectionId}
              segmentIndex={segmentIdx}
              collapsable={collapsable}
            />
          ));
        }

        return segment.components.map<ReactNode>((componentConfiguration, i) =>
          getComponent({
            componentConfiguration,
            segmentIndex: segmentIdx,
            componentIndex: i,
            sectionId,
            entity: sectionEntity,
          }),
        );
      }),
    [collapsable, opportunity, sectionEntity, sectionId, segments],
  );

  return (
    <Flex flexDirection={'column'} gap={12}>
      {segmentComponents}
    </Flex>
  );
};
