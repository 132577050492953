import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Referrer } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const usePartnerReferrersQuery = (partnerId?: string, options?: UseQueryOptions<Referrer[]>): UseQueryResult<Referrer[]> => {
  const { getAccessTokenSilently } = useAuth0();

  const getPartnerReferrersQuery = useCallback(async () => {
    if (!partnerId) {
      return [];
    }
    const token = await getAccessTokenSilently();

    return applicationServiceClient.getPartnerReferrers({ partnerId }, token);
  }, [getAccessTokenSilently, partnerId]);

  const query = useQuery({ queryKey: ['referrers', partnerId], queryFn: getPartnerReferrersQuery, ...options });

  return query;
};
