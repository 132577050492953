import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { ListSubheader, IconButton } from '@mui/material';
import type { FC } from 'react';
import { grey } from '@mui/material/colors';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';

interface ApplicationActionsMenuItemProps {
  text: string;
  onClick?: () => void;
}

const StyledFlex = styled(Flex)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const SubMenuHeader: FC<ApplicationActionsMenuItemProps> = ({ text, onClick }) => (
  <ListSubheader>
    <StyledFlex alignItems={'center'} justifyContent={'flex-start'} gap={1} onClick={onClick}>
      {onClick ? (
        <IconButton disableRipple size={'small'}>
          <ChevronLeft fontSize={'small'} sx={{ color: grey[500] }} />
        </IconButton>
      ) : null}
      <Text variant={'body3'} color={grey[500]}>
        {text}
      </Text>
    </StyledFlex>
  </ListSubheader>
);
