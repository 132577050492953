import type { FC } from 'react';
import React from 'react';

export const EmptyReportCardState: FC = () => (
  <svg width={'333'} height={'200'} viewBox={'0 0 333 200'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <circle cx={'166.428'} cy={'100'} r={'100'} fill={'url(#paint0_linear_29864_4266)'} />
    <path
      d={
        'M294.078 28.4105C294.292 25.8069 296.573 23.8673 299.178 24.0787C301.783 24.2902 303.722 26.5753 303.511 29.1799C303.298 31.7835 301.017 33.7232 298.412 33.5117C295.807 33.3003 293.868 31.0151 294.078 28.4105Z'
      }
      fill={'#EEEEEE'}
    />
    <path
      d={
        'M35.2625 39.0338C34.7474 38.8712 34.2507 38.6501 33.7737 38.3665C32.1663 37.4085 31.0288 35.8864 30.5713 34.0726C30.1126 32.2626 30.3853 30.3787 31.3433 28.7713C33.3172 25.4546 37.6217 24.3671 40.9384 26.341C42.5458 27.299 43.6833 28.8211 44.1408 30.635C44.5983 32.4488 44.3268 34.3287 43.3688 35.9361C42.4108 37.5435 40.8887 38.6811 39.0748 39.1386C37.7973 39.4606 36.488 39.4206 35.2625 39.0338ZM33.6668 30.1528C33.0807 31.1394 32.9089 32.2955 33.1934 33.4067C33.4778 34.5179 34.1736 35.4542 35.159 36.0442C36.1457 36.6303 37.3017 36.8021 38.4129 36.5177C39.5241 36.2332 40.4604 35.5374 41.0504 34.552C41.6365 33.5654 41.8044 32.4081 41.5238 31.2982C41.2394 30.187 40.5436 29.2506 39.5582 28.6606C38.5716 28.0746 37.4156 27.9027 36.3044 28.1871C35.1932 28.4716 34.2568 29.1674 33.6668 30.1528Z'
      }
      fill={'#EEEEEE'}
    />
    <path
      d={
        'M283.12 52.6052C282.605 52.4426 282.108 52.2215 281.631 51.9379C280.023 50.9799 278.886 49.4578 278.428 47.644C277.97 45.834 278.243 43.9502 279.201 42.3428C281.174 39.0261 285.479 37.9385 288.796 39.9124C290.403 40.8704 291.54 42.3925 291.998 44.2064C292.455 46.0203 292.184 47.9001 291.226 49.5075C290.268 51.1149 288.746 52.2525 286.932 52.71C285.654 53.032 284.345 52.992 283.12 52.6052ZM281.524 43.7242C280.938 44.7108 280.766 45.8669 281.051 46.9781C281.335 48.0893 282.031 49.0256 283.016 49.6156C284.003 50.2017 285.159 50.3735 286.27 50.0891C287.381 49.8046 288.318 49.1088 288.908 48.1234C289.494 47.1368 289.662 45.9795 289.381 44.8696C289.097 43.7584 288.401 42.8221 287.415 42.2321C286.429 41.646 285.273 41.4741 284.162 41.7585C283.05 42.043 282.114 42.7388 281.524 43.7242Z'
      }
      fill={'#EEEEEE'}
    />
    <g opacity={'0.8'}>
      <g filter={'url(#filter0_d_29864_4266)'}>
        <rect x={'25'} y={'53'} width={'234.286'} height={'45.7143'} rx={'4'} fill={'white'} />
        <rect x={'25.5'} y={'53.5'} width={'233.286'} height={'44.7143'} rx={'3.5'} stroke={'#F5F6F6'} />
      </g>
      <rect x={'36'} y={'64'} width={'22.8571'} height={'22.8571'} rx={'2'} fill={'#F3F0FF'} />
      <rect x={'70.7144'} y={'65'} width={'177.143'} height={'7.14286'} rx={'2'} fill={'#EFEFEF'} />
      <rect x={'70.7144'} y={'80.7143'} width={'107.143'} height={'7.14286'} rx={'2'} fill={'#EFEFEF'} />
    </g>
    <g filter={'url(#filter1_d_29864_4266)'}>
      <rect x={'73.5715'} y={'87.7778'} width={'234.286'} height={'45.7143'} rx={'4'} fill={'white'} />
      <rect x={'74.0715'} y={'88.2778'} width={'233.286'} height={'44.7143'} rx={'3.5'} stroke={'#F5F6F6'} />
    </g>
    <rect x={'85'} y={'99'} width={'22.8571'} height={'22.8571'} rx={'2'} fill={'#F3F0FF'} />
    <rect x={'119.286'} y={'99.2857'} width={'107.143'} height={'7.14286'} rx={'2'} fill={'#EFEFEF'} />
    <rect x={'119.286'} y={'115'} width={'170.714'} height={'7.14286'} rx={'2'} fill={'#EFEFEF'} />
    <defs>
      <filter
        id={'filter0_d_29864_4266'}
        x={'0'}
        y={'36'}
        width={'284.286'}
        height={'95.7143'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}
      >
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix in={'SourceAlpha'} type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'} result={'hardAlpha'} />
        <feOffset dy={'8'} />
        <feGaussianBlur stdDeviation={'12.5'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.219333 0 0 0 0 0 0 0 0 0 0.391667 0 0 0 0.08 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_29864_4266'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_29864_4266'} result={'shape'} />
      </filter>
      <filter
        id={'filter1_d_29864_4266'}
        x={'48.5715'}
        y={'70.7778'}
        width={'284.286'}
        height={'95.7143'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}
      >
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix in={'SourceAlpha'} type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'} result={'hardAlpha'} />
        <feOffset dy={'8'} />
        <feGaussianBlur stdDeviation={'12.5'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.219333 0 0 0 0 0 0 0 0 0 0.391667 0 0 0 0.08 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_29864_4266'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_29864_4266'} result={'shape'} />
      </filter>
      <linearGradient id={'paint0_linear_29864_4266'} x1={'166.428'} y1={'0'} x2={'166.428'} y2={'200'} gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#E8E8E8'} />
        <stop offset={'0.494792'} stopColor={'#ECECEC'} stopOpacity={'0.32'} />
        <stop offset={'1'} stopColor={'#EEEEEE'} stopOpacity={'0'} />
      </linearGradient>
    </defs>
  </svg>
);
