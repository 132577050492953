import type { RequirementProperty } from '@lama/contracts';

export interface OpportunityRequirementProperty {
  name: string;
  value: Object | string;
  type: RequirementProperty['type'];
}

export const granularities = ['week', 'month', 'year'] as const;
export type Granularity = (typeof granularities)[number];

export const isGranularity = (value: string): value is Granularity => granularities.includes(value as Granularity);

export const chartTypes = ['balance', 'profitAndLoss'] as const;
export type ChartType = (typeof chartTypes)[number];

export const isChart = (value: string): value is ChartType => chartTypes.includes(value as ChartType);
