import React, { useMemo, useState, useCallback } from 'react';
import type { GreaterThanMatcher, LessThanMatcher } from '@lama/contracts';
import { Slider, TextField } from '@mui/material';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import type { GreaterThanConditionConfiguration, LessThanConditionConfiguration } from '@lama/conditions';
import { formatValue } from '@lama/data-formatters';
import { Flex } from '@lama/design-system';
import type { MatcherValue } from './Matchers/types';
import type { GreaterThanMatcherConfiguration, LessThanMatcherConfiguration } from './Matchers/matcherConfigurations';

interface NumberConditionProps {
  condition?: GreaterThanMatcher | LessThanMatcher;
  configuration:
    | GreaterThanConditionConfiguration
    | GreaterThanMatcherConfiguration
    | LessThanConditionConfiguration
    | LessThanMatcherConfiguration;
  onChange: (matcher: MatcherValue) => void;
}

export const InequalityCondition: FC<NumberConditionProps> = ({ condition, configuration, onChange: onConditionChange }) => {
  const [value, setValue] = useState<number>(condition?.value ?? configuration.default);

  const conditionUpdated = useMemo(() => {
    if (condition) {
      return value !== condition.value;
    }

    return value !== configuration.default;
  }, [condition, configuration.default, value]);

  const upsertCondition = useCallback(() => {
    if (!conditionUpdated) {
      return;
    }

    onConditionChange({ value, type: configuration.type });
  }, [conditionUpdated, configuration.type, onConditionChange, value]);

  const marks = useMemo(
    () => [
      {
        value: configuration.min,
        label: formatValue(configuration.min, configuration.format),
      },
      {
        value: configuration.max,
        label: formatValue(configuration.max, configuration.format),
      },
    ],
    [configuration?.format, configuration.max, configuration.min],
  );

  useDebounce(upsertCondition, 1000, [value]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  }, []);

  const onSliderChange = useCallback((event: Event, changedValue: number[] | number) => {
    setValue(changedValue as number);
  }, []);

  return (
    <Flex flexDirection={'column'} gap={2}>
      <TextField
        id={`min-${configuration.displayName}}`}
        label={configuration.displayName}
        type={'number'}
        onChange={onChange}
        InputProps={{ inputProps: { min: configuration.min, max: configuration.max, step: configuration.step } }}
        value={value}
        fullWidth
      />
      <Flex px={8}>
        <Slider
          id={`slider-${configuration.displayName}}`}
          value={value}
          onChange={onSliderChange}
          marks={marks}
          min={configuration.min}
          max={configuration.max}
          step={configuration.step}
        />
      </Flex>
    </Flex>
  );
};
