import { useContext, useCallback } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateOpportunityClosingTaskMutation } from '../../useUpdateClosingTaskMutation';

export const useMarkTaskInapplicable = (task: OpportunityClosingTask) => {
  const { opportunity } = useContext(ApplicationContext);
  const { mutate: updateOpportunityClosingTask, isPending: updatingTaskStatus } = useUpdateOpportunityClosingTaskMutation(opportunity.id);
  const markAsInapplicable = useCallback(() => {
    updateOpportunityClosingTask({
      taskId: task.id,
      taskUpdate: { status: 'Dismissed', completedAt: null, assigneeIds: [] },
    });
  }, [task.id, updateOpportunityClosingTask]);
  return { markAsInapplicable, markingTaskAsInapplicable: updatingTaskStatus };
};
