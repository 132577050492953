import { endOfWeek, format, parseISO, startOfWeek } from 'date-fns';
import { groupBy, isNumber, orderBy } from 'lodash-es';

export const getFormattedWeek = (date: string) =>
  format(parseISO(date), "yyyy-'W'ww", { useAdditionalWeekYearTokens: true, useAdditionalDayOfYearTokens: true });

export const getChartDataByWeeks = (items: (Object & { itemTimestamp: string })[]) => {
  const itemsByWeek = groupBy(items, ({ itemTimestamp }) => getFormattedWeek(itemTimestamp));
  const sortedItemsByWeek = orderBy(itemsByWeek, (_, week) => new Date(week).getTime(), ['desc']);

  return Object.entries(sortedItemsByWeek).map(([week, weekItems]) => ({
    week,
    amount: weekItems.length,
    weekText: `${format(startOfWeek(new Date(weekItems[0]!.itemTimestamp)), 'MM/dd/yy')} - ${format(
      endOfWeek(new Date(weekItems[0]!.itemTimestamp)),
      'MM/dd/yy',
    )}`,
  }));
};

export const formatChartAxisValue = (value: any) => (isNumber(value) ? (Math.floor(value) === value ? value : '') : value);
