import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { BaseGenericList } from '@lama/app-components';
import type { RequirementScreenProps } from '../types';
import { useUpdateApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useUpdatePerson } from '../../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { ApplicationContext } from '../../../../ApplicationContext';
import { ItemDocuments } from './ItemDocuments';

export const GenericList: FC<RequirementScreenProps> = ({ requirement }) => {
  const { opportunity, application, product } = useContext(ApplicationContext);

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);

  const loading = useMemo(
    () => updatingApplication || updatingBusiness || updatingPerson,
    [updatingApplication, updatingBusiness, updatingPerson],
  );

  return (
    <BaseGenericList
      requirement={requirement}
      application={application}
      product={product}
      updatePerson={updatePerson}
      updateBusiness={updateBusiness}
      updateApplication={updateApplication}
      loading={loading}
      ItemDocumentsComponent={ItemDocuments}
    />
  );
};
