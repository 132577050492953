import React, { useMemo } from 'react';
import type { FC } from 'react';
import { conditionConfigurations, formatConditionDefinition } from '@lama/conditions';
import type { EvaluatedCondition } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';
import { ConditionResultIcon } from '../../../../shared/components/conditions/conditionIcons';
import { CreditMemoTable, type CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';

const getConditionConfiguration = (evaluatedCondition: EvaluatedCondition) =>
  conditionConfigurations.find(
    (config) =>
      config.type.toLocaleLowerCase() === evaluatedCondition.condition.conditionMatcher.type.toLocaleLowerCase() &&
      evaluatedCondition.condition.conditionMatcher.fieldName.toLocaleLowerCase() === config.selector.toLocaleLowerCase() &&
      evaluatedCondition.condition.conditionMatcher.entityType?.toLocaleLowerCase() === config.entityType.toLocaleLowerCase(),
  );

const headers = [
  { text: 'Requirement', width: '35%' },
  { text: 'Base Criteria', width: '35%' },
  { text: 'Actual', width: '20%' },
  { text: 'Status', width: '10%' },
];

export const ConditionsTableSegment: FC<{ conditions: EvaluatedCondition[] }> = ({ conditions }) => {
  const rows = useMemo(
    () =>
      conditions.map<CellContent[]>((condition) => {
        const config = getConditionConfiguration(condition);

        const format = typeof condition.extractedValue === 'boolean' ? 'passFail' : config?.format;

        return [
          {
            value: config?.displayName || condition.condition.name,
          },
          {
            value: formatConditionDefinition(condition.condition.conditionMatcher, format),
          },
          {
            value: formatValue(condition.extractedValue, format),
            type: config?.format,
          },
          {
            customComponent: <ConditionResultIcon reason={condition.reason} size={'24px'} />,
            align: 'center',
          },
        ];
      }),
    [conditions],
  );

  if (!rows?.length) {
    return null;
  }

  return <CreditMemoTable headers={headers} rows={rows} />;
};
