import type { PropertiesRecord } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { getSelectedPropertyValue } from '@lama/properties';
import { personFullName } from '@lama/data-formatters';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import type { InfoLineProps } from '@lama/app-components';
import { InfoLine } from '@lama/app-components';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';
import { ApplicationContext } from '../../../../Application/ApplicationContext';

const ficoScorePropertyKey = 'people_ficoScore';

export const PrincipalSegment: FC<{ principal: RelatedPersonApiModel; properties: PropertiesRecord }> = ({ principal, properties }) => {
  const ficoScoreProperty = useMemo(() => properties[ficoScorePropertyKey], [properties]);
  const { application } = useContext(ApplicationContext);

  const info: { title: string; lines: InfoLineProps[] }[] = useMemo(
    () => [
      {
        title: 'General Information',
        lines: [
          {
            label: 'Ownership',
            values: principal.ownershipPercentage,
            type: 'percent',
          },
          {
            label: 'Position',
            values: principal.position,
          },
          ...(ficoScoreProperty
            ? [
                {
                  label: 'FICO Score',
                  values: getSelectedPropertyValue({
                    fieldName: ficoScoreProperty.fieldName,
                    entity: principal,
                    entityType: 'person',
                    application,
                    sources: ficoScoreProperty.sources,
                    propertyType: ficoScoreProperty.type,
                  }).value,
                },
              ]
            : []),
          {
            label: 'SSN',
            values: principal.ssn,
          },
          {
            label: 'Phone Number',
            values: principal.phoneNumber ? [principal.phoneNumber, `tel:${principal.phoneNumber}`] : [],
            type: 'link',
          },
          {
            label: 'Email Address',
            values: principal.email ? [principal.email, `mailto:${principal.email}`] : [],
            type: 'link',
          },
          {
            label: 'Is Guarantor',
            values: principal.guarantor,
            type: 'boolean',
          },
        ],
      },
    ],
    [ficoScoreProperty, principal, application],
  );

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text variant={'h5'} color={greyPalette[500]}>
        {personFullName(principal)}
      </Text>
      <BorderedContainer>
        {info.map((i) => (
          <Flex flexDirection={'column'} key={i.title} gap={4}>
            <Text variant={'body1'} color={greyPalette[500]}>
              {i.title}
            </Text>
            <Flex flexDirection={'column'} gap={2}>
              {i.lines.map((line) => (
                <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />
              ))}
            </Flex>
          </Flex>
        ))}
      </BorderedContainer>
    </Flex>
  );
};
