import type { AccountData } from '@lama/contracts';
import { format } from 'date-fns';
import { compact, groupBy } from 'lodash-es';
import type { Granularity } from '../../../types';
import { getDataInRange } from '../helpers';

export const getChartDataFromBalances = (balances: AccountData['balances']) =>
  balances.map((balance) => ({ x: new Date(balance.date), y: balance.current }));

export const getWeeklyBalancesData = (balancesInRange: AccountData['balances']) => {
  const balancesByWeek = groupBy(balancesInRange, (balance) => format(new Date(balance.date), 'ww yyyy'));
  const lastBalanceByWeek = compact(Object.values(balancesByWeek).map((balances) => balances.at(-1)));

  return getChartDataFromBalances(lastBalanceByWeek);
};

export const getMonthlyBalancesData = (balancesInRange: AccountData['balances']) => {
  const balancesByMonth = groupBy(balancesInRange, (balance) => format(new Date(balance.date), 'yyyy-MM'));
  const lastBalanceByMonth = compact(Object.values(balancesByMonth).map((balances) => balances.at(-1)));

  return getChartDataFromBalances(lastBalanceByMonth);
};

export const getYearlyBalancesData = (balancesInRange: AccountData['balances']) => {
  const balancesByYear = groupBy(balancesInRange, (balance) => format(new Date(balance.date), 'yyyy'));
  const lastBalanceByYear = compact(Object.values(balancesByYear).map((balances) => balances.at(-1)));

  return getChartDataFromBalances(lastBalanceByYear);
};

export const getGranulatedBalancesData = (
  balances: AccountData['balances'],
  lastBalanceDate: Date,
  granularity: Granularity,
  page: number,
) => {
  const balancesInRange = getDataInRange(balances, lastBalanceDate, page, granularity);

  let dataForChart;

  switch (granularity) {
    case 'week': {
      dataForChart = getChartDataFromBalances(balancesInRange);
      break;
    }
    case 'month': {
      dataForChart = getChartDataFromBalances(balancesInRange);
      break;
    }
    case 'year': {
      dataForChart = getYearlyBalancesData(balancesInRange);
      break;
    }
  }

  return dataForChart;
};
