/* eslint-disable @typescript-eslint/naming-convention */
import type { ConditionEvaluationReason } from '@lama/contracts';

import { Stack, Typography } from '@mui/material';
import { amber, grey, red } from '@mui/material/colors';
import type { FC } from 'react';
import React from 'react';
import { ConditionResultIcon } from './conditionIcons';

interface ConditionAlertProps {
  alertType: ConditionEvaluationReason;
}

const alertStyles: Record<ConditionEvaluationReason, any> = {
  pass: {
    borderColor: '#44CFA6',
    backgroundColor: '#E2FBF3',
  },
  missing_data: {
    borderColor: grey[500],
    backgroundColor: grey[200],
  },
  fail: {
    borderColor: '#EF5350',
    backgroundColor: red[50],
  },
  cleared: {
    borderColor: grey[500],
    backgroundColor: grey[200],
  },
  exception: {
    borderColor: '#FFA000',
    backgroundColor: amber[50],
  },
  flag: {
    borderColor: '#EF5350',
    backgroundColor: red[50],
  },
};

const conditionSummaryText: Record<ConditionEvaluationReason, string> = {
  pass: 'All criteria for this product have been met',
  missing_data: 'This application is missing some information to assess the eligibility for this product',
  fail: 'This application does not meet some of the base criteria for this product',
  exception: 'Some conditions have been marked as exceptions',
  cleared: 'Some conditions have been cleared',
  flag: 'Some conditions have been flagged as issues',
};

export const ConditionAlert: FC<ConditionAlertProps> = ({ alertType }) => (
  <Stack
    flexDirection={'row'}
    p={2}
    gap={1}
    alignItems={'center'}
    sx={{
      borderRadius: 1,
      backgroundColor: alertStyles[alertType].backgroundColor,
      border: `1px solid ${alertStyles[alertType].borderColor}`,
    }}
  >
    <ConditionResultIcon size={'24px'} reason={alertType} />
    <Typography variant={'body2'}>{conditionSummaryText[alertType]}</Typography>
  </Stack>
);
