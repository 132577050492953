import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Collapse, Flex } from '@lama/design-system';
import { groupBy, isNil, orderBy } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Fuse from 'fuse.js';
import type { LabeledValue } from '@lama/contracts';
import { ApplicationContext } from '../Application/ApplicationContext';
import { useApplicationDocumentsQuery } from '../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useOpportunityRequirementsQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { DocumentGatheringCard } from '../Application/OpportunityRequirements/DocumentGathering';
import { useGetPartnerQuery } from '../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { useOpportunityClosingTasksQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { DocumentHubEmptyState } from './DocumentHubEmptyState';
import { useDocumentBoxesDetails } from './hooks/useDocumentBoxesDetails';
import { autoCompleteFilterOptions, DocumentHubFilters } from './DocumentHubFilters';
import type { GroupedDocumentBoxDetails, GroupingOptions } from './types';
import { DemoDocumentSummaryV2 } from './DocumentSummary/DemoDocumentSummaryV2';
import { DisabledWrapper, useDisableDocumentBoxesUpload } from './GrasshopperDisableDocumentHub';
import { useDocumentsMoveToTree } from './hooks/useDocumentsMoveToTree';
import { DocumentGroup } from './DocumentGroup';
import { getDocumentBoxesWithGroups } from './getDocumentBoxesWithGroups';

/* eslint-disable @typescript-eslint/naming-convention */
const groupNameToOrder: Record<string, number> = {
  'Deleted Documents': 3,
  'Miscellaneous Documents': 2,
  'Loan Request': 0,
};
/* eslint-enable @typescript-eslint/naming-convention */

export const DocumentHubPage: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);
  const { showDocumentGatheringCard, disableDocumentHubActions } = useFlags();
  const { showDocumentHubSpreadingSummary } = useMemo(() => partner?.featureConfigurations ?? {}, [partner]);
  const [searchParams, setSearchParams] = useSearchParams();

  const groupedBy = useMemo(() => {
    const groupParam = searchParams.get('groupedBy');
    return groupParam === 'entity' || groupParam === 'recent' || groupParam === 'topic' ? groupParam : 'entity';
  }, [searchParams]);

  const filterBy = useMemo(() => {
    const filterParam = searchParams.get('filter');
    return autoCompleteFilterOptions.find((opt) => opt.value === filterParam) ?? autoCompleteFilterOptions.at(0)!;
  }, [searchParams]);

  const searchQuery = searchParams.get('search') || '';

  const setSearchQuery = useCallback(
    (newSearch: string) => {
      searchParams.set('search', newSearch);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setFilterBy = useCallback(
    (newFilter: LabeledValue) => {
      searchParams.set('filter', newFilter.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const { data: closingTasks, isPending: loadingClosingTasks } = useOpportunityClosingTasksQuery(opportunity.id);
  const { data: allRequirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunity.id, {});
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: application.id,
    includeDeleted: true,
  });

  const documentBoxesDetails = useDocumentBoxesDetails({
    closingTasks: closingTasks ?? [],
    allDocuments: applicationDocuments ?? [],
    allRequirements: allRequirements ?? [],
    grouping: groupedBy,
  });

  const documentBoxesWithGroups = useMemo(
    () => getDocumentBoxesWithGroups(documentBoxesDetails, groupedBy),
    [documentBoxesDetails, groupedBy],
  );

  const moveToTreeData = useDocumentsMoveToTree(documentBoxesDetails, groupedBy);

  useDisableDocumentBoxesUpload(!!documentBoxesWithGroups.length, Boolean(disableDocumentHubActions));

  const filteredDocumentBoxes = useMemo((): GroupedDocumentBoxDetails[] => {
    let filteredDocs = documentBoxesWithGroups;

    if (searchQuery) {
      const fuse = new Fuse(documentBoxesWithGroups, {
        shouldSort: true,
        keys: ['name', 'topic', 'fileName', 'requirement.name'],
        threshold: 0.3,
      });

      filteredDocs = fuse.search(searchQuery).map((result) => result.item);
    }

    if (filterBy.value === 'missing') {
      filteredDocs = filteredDocs.filter((d) => isNil(d.document));
    } else if (filterBy.value === 'uploaded') {
      filteredDocs = filteredDocs.filter((d) => !isNil(d.document));
    }

    return filteredDocs;
  }, [filterBy.value, documentBoxesWithGroups, searchQuery]);

  const groups = useMemo(() => {
    const grouped = groupBy(filteredDocumentBoxes, (doc) => doc.group);
    return orderBy(Object.entries(grouped), [([groupName]) => groupNameToOrder[groupName] ?? 1, ([groupName]) => groupName]);
  }, [filteredDocumentBoxes]);

  const onGroupChange = useCallback(
    (value: GroupingOptions) => {
      searchParams.set('groupedBy', value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  if (loadingRequirements || loadingDocuments || loadingClosingTasks) {
    return <LoadingPage />;
  }

  if (!documentBoxesWithGroups.length) {
    return null;
  }

  return (
    <DisabledWrapper disabled={disableDocumentHubActions}>
      <Flex flexDirection={'column'} gap={10} px={20}>
        {showDocumentHubSpreadingSummary ? (
          <Collapse expanded={!!documentBoxesDetails.length}>
            <DemoDocumentSummaryV2 />
          </Collapse>
        ) : null}
        {showDocumentGatheringCard ? <DocumentGatheringCard /> : null}
        <DocumentHubFilters
          documents={documentBoxesDetails}
          groupedBy={groupedBy}
          onGroupChange={onGroupChange}
          filterBy={filterBy}
          onFilterChange={setFilterBy}
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
        />
        {documentBoxesDetails.length ? (
          <Flex flexDirection={'column'} gap={10}>
            {groups.map(([groupName, groupDocument]) => (
              <DocumentGroup
                key={groupName}
                groupName={groupName}
                groupDocuments={groupDocument}
                moveToTreeData={moveToTreeData}
                flat={groupedBy === 'recent'}
                showSubtitle={groupedBy === 'entity'}
              />
            ))}
          </Flex>
        ) : (
          <DocumentHubEmptyState />
        )}
      </Flex>
    </DisabledWrapper>
  );
};
