import type { SortModelItem } from '@ag-grid-community/core';

export const getSortParams = (sortModel?: SortModelItem[]) => {
  const sortedColumn = sortModel?.find((column) => column.sort);

  if (sortedColumn) {
    return { sortBy: sortedColumn.colId, order: sortedColumn.sort };
  }

  return { sortBy: undefined, order: undefined };
};
