import { Tabs } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LinkTab } from './LinkTab.js';
import type { LinkTabProps } from './linkTab.types.js';

interface LinkTabsProps {
  tabs: LinkTabProps[];
}

export const LinkTabs: FC<LinkTabsProps> = ({ tabs }) => {
  const { pathname } = useLocation();
  const selectedTab = useMemo(() => {
    const tabIndex = tabs.findIndex(({ href }) => href && pathname.includes(href));
    return tabIndex === -1 ? 0 : tabIndex;
  }, [pathname, tabs]);

  return (
    <Tabs value={selectedTab} sx={{ borderBottom: '1px solid #00000013' }} variant={'fullWidth'}>
      {tabs.map((tab) => (
        <LinkTab key={tab.label} tab={tab} />
      ))}
    </Tabs>
  );
};
