import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';
import decode from 'jwt-decode';
import { useState } from 'react';
import type { LamaJwtPayload } from '@lama/user-service-client';

export const usePartner = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [partner, setPartner] = useState<string | undefined>();

  useAsync(async () => {
    const token = await getAccessTokenSilently();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { 'lama.ai/partner': tokenPartner } = decode<LamaJwtPayload>(token);
    setPartner(tokenPartner);
  }, [getAccessTokenSilently]);

  return partner;
};
