/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { LoadingPage } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { DocumentHubEmptyState } from '../DocumentHub/DocumentHubEmptyState';
import { SpreadingDocumentProvider } from '../Spreading/SpreadingDocumentContext';
import { SpreadingDialogContent } from '../Spreading/SpreadingDialog/SpreadingContent';
import { SpreadingToolbar } from '../Spreading/SpreadingDialog/SpreadingToolbar/SpreadingToolbar';
import { useApplicationSpreadingDocumentsQuery } from '../Spreading/hooks/useApplicationSpreadingDocumentsQuery';

export const SpreadingManagementPage: React.FC = () => {
  const { spreadingBackOfficeMode } = useFlags();
  const { applicationId } = useParams();

  const { data: relatedDocuments, isPending: loadingDocuments } = useApplicationSpreadingDocumentsQuery(applicationId);

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  if (!spreadingBackOfficeMode || !applicationId || !relatedDocuments?.length) {
    return <DocumentHubEmptyState />;
  }

  return (
    <SpreadingDocumentProvider applicationId={applicationId} documents={relatedDocuments}>
      <SpreadingToolbar />
      <Divider />
      <SpreadingDialogContent />
    </SpreadingDocumentProvider>
  );
};
