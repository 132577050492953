import React from 'react';
import type { Period } from '@lama/selectors';
import type { SpreadRow } from '@lama/spreads-generator-client';
import type { PeriodDisplayFormat } from '../../types';
import { PeriodHeaderComponent } from './PeriodHeaderComponent';

export const TableHeader = ({
  periods,
  spreadRows,
  periodDisplayFormat,
}: {
  periods: Period[];
  spreadRows: SpreadRow[];
  periodDisplayFormat: PeriodDisplayFormat;
}) => (
  <thead>
    <tr>
      <th /> {/* Empty header for the attribute column */}
      {periods.map((period) => (
        <PeriodHeaderComponent
          key={`${period.startDate}-${period.endDate}`}
          period={period}
          spreadRows={spreadRows}
          periodDisplayFormat={periodDisplayFormat}
        />
      ))}
    </tr>
  </thead>
);
