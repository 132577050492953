import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { etranServiceClient } from '../../../clients/etranServiceClient';

export const useGetEtranAuthStatus = (options?: UseQueryOptions<boolean>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getEtranAuthStatus = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return etranServiceClient.getAuthStatus(token);
  }, [getAccessTokenSilently]);

  const query = useQuery<boolean>({ queryKey: ['etranAuthStatus'], queryFn: getEtranAuthStatus, ...options });

  return query;
};
