import React, { useContext, useMemo } from 'react';
import { Alert } from '@mui/material';
import type { FC } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { FlagCard } from '../../CreditMemo/Sections/components/Notes/FlagCard';

export const FlagsSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);
  const opportunityFlags = useMemo(() => opportunity.underwriting?.notes?.filter((note) => note.type === 'flag'), [opportunity]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body1'} color={greyPalette[500]}>
        {`Flags (${opportunityFlags?.length ?? 0})`}
      </Text>
      {opportunityFlags?.length ? (
        <Alert severity={'warning'}>
          {`There are ${opportunityFlags.length} flagged issues. They should be marked as exceptions before approving the loan.`}
        </Alert>
      ) : (
        <Text variant={'body1'} color={'text.secondary'}>
          {'You have no flagged issues on this application.'}
        </Text>
      )}
      {opportunityFlags?.map((flag) => (
        <FlagCard key={flag.id} flag={flag} />
      ))}
    </Flex>
  );
};
