import { Tab, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { LinkTabProps } from './linkTab.types';

export const LinkTab: FC<{ tab: LinkTabProps }> = ({ tab, ...props }) => {
  const navigate = useNavigate();

  const navigateToTab = useCallback(() => {
    navigate(`./${tab.href}`);
  }, [navigate, tab.href]);

  return (
    <Tab
      onClick={navigateToTab}
      sx={{ color: 'text.secondary', px: 0, py: 0, display: 'flex', flex: 1 }}
      disabled={tab.disabled}
      label={
        <Typography variant={'button'} textTransform={'none'}>
          {tab.label}
        </Typography>
      }
      icon={tab.icon}
      iconPosition={'end'}
      {...props}
    />
  );
};
