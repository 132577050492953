import React from 'react';
import type { FC } from 'react';
import { Avatar, Tooltip } from '@lama/app-components';
import { AvatarGroup } from '@mui/material';
import type { User } from '@lama/user-service-client';
import { personFullName } from '@lama/data-formatters';

interface AssigneeGroupProps {
  assignees: User[];
  max?: number;
}

export const AssigneeGroup: FC<AssigneeGroupProps> = ({ assignees, max = 4 }) =>
  assignees?.length > 0 ? (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <AvatarGroup max={max} total={assignees.length} sx={{ '& .MuiAvatarGroup-avatar': { height: '32px', width: '32px' } }}>
      {assignees.map((assignee) => (
        <Tooltip title={personFullName(assignee)} key={assignee.id}>
          <Avatar key={assignee.id} firstName={assignee.firstName} lastName={assignee.lastName} imgSize={'32px'} />
        </Tooltip>
      ))}
    </AvatarGroup>
  ) : null;
