import type { FinancialData } from '@lama/contracts';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash-es';
import { getYear } from '@lama/data-formatters';
import type { FinancialParamsForPayload, FinancialParamsForPayloadWithFinancials, FinancialPayload } from './types';

export const getFinancialFieldStartYear = (financialData: FinancialData) => getYear(financialData.startDate);

export const getFinancialFieldEndYear = (financialData: FinancialData) => getYear(financialData.endDate);

export const getFinancialFieldByYear = (
  financials: FinancialData[],
  year: number,
  fieldName: string,
  sourceType?: 'Document' | 'Manual',
) => {
  const fieldFinancials = financials.filter(
    (financial) =>
      getFinancialFieldStartYear(financial) === year && getFinancialFieldEndYear(financial) === year && financial.type === fieldName,
  );
  const manualField = fieldFinancials.find(({ source }) => source.type === 'Manual');
  const docField = fieldFinancials.find(({ source }) => source.type === 'Document');

  if (sourceType === 'Manual') {
    return manualField;
  }
  if (sourceType === 'Document') {
    return docField;
  }

  return manualField ?? docField;
};

export const getFinancialPayloadAction = ({
  fieldToUpdate,
  year,
  fieldName,
  value,
  userId,
  documentId,
  documentPageNumber,
  currentFormType,
  unverified,
  endDate,
  startDate,
  entityId,
}: FinancialParamsForPayload): FinancialPayload[] => {
  if (fieldToUpdate && fieldToUpdate?.source.type === 'Manual' && fieldToUpdate.value.toString() !== value?.toString()) {
    if (!isNil(value) && value !== '') {
      return [
        {
          action: 'update',
          id: fieldToUpdate.id,
          value: Number(value),
        },
      ];
    }
    return [
      {
        action: 'delete',
        id: fieldToUpdate.id,
      },
    ];
  }

  if (
    !isNil(value) &&
    value !== '' &&
    (!fieldToUpdate || (fieldToUpdate.source.type !== 'Manual' && fieldToUpdate.value.toString() !== value?.toString()))
  ) {
    return [
      {
        action: 'create',
        id: uuidv4(),
        entityId,
        source: {
          type: 'Manual' as const,
          user: { type: 'Lender' as const, userId },
          relatedDocumentId: documentId,
          relatedDocumentPage: documentPageNumber,
          extractedFinancialDataId: fieldToUpdate?.id,
          formType: currentFormType,
          unverified,
        },
        startDate: fieldToUpdate?.startDate ?? startDate ?? `${year}-01-01T00:00:00.000Z`,
        endDate: fieldToUpdate?.endDate ?? endDate ?? `${year}-12-31T00:00:00.000Z`,
        type: fieldName,
        value: Number(value),
      },
    ];
  }
  return [];
};

export const getFinancialPayload = ({
  entityId,
  financials,
  year,
  fieldName,
  value,
  userId,
  documentId,
  documentPageNumber,
  currentFormType,
  unverified,
}: FinancialParamsForPayloadWithFinancials): FinancialPayload[] => {
  const fieldToUpdate = getFinancialFieldByYear(financials, year, fieldName);

  return getFinancialPayloadAction({
    entityId,
    fieldToUpdate,
    year,
    fieldName,
    value,
    userId,
    documentId,
    documentPageNumber,
    currentFormType,
    unverified,
  });
};
