import type { FC } from 'react';
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tune } from '@mui/icons-material';
import { getAssetUrl } from '../../../shared/utils/getAssetUrl';

const highMatchImageUrl = getAssetUrl('highMatchCTABackground.png');

export const HighMatchCTA: FC = () => (
  <Stack gap={2} width={'100%'} pb={3}>
    <Typography variant={'h6'}>{'Deals for you'}</Typography>
    <Stack
      gap={2}
      sx={{
        p: 5,
        width: '100%',
        background: `url(${highMatchImageUrl}), linear-gradient(140.31deg, #714EF5 9.87%, #000000 165.38%)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        minHeight: '216px',
      }}
    >
      <Typography color={'white'} variant={'h5'}>
        {'Appetite Mapping'}
      </Typography>
      <Typography color={'white'} variant={'body1'}>
        {'Set your appetite to secure top-matching deals'}
      </Typography>
      <Link to={'/products'} style={{ textDecoration: 'none' }}>
        <Button color={'neutral'} variant={'contained'} startIcon={<Tune />}>
          {'Set Appetite'}
        </Button>
      </Link>
    </Stack>
  </Stack>
);
