import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import type { DocumentIssue } from '@lama/document-service-client';
import { useQueryClient } from '@tanstack/react-query';
import { Typography, Stack } from '@mui/material';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { PropertyFormikInput } from '@lama/app-components';
import { useUpdateExtractedDataQuery } from '../../shared/hooks/react-query/document/useUpdateExtractedDataQuery';
import { ApplicationContext } from '../Application/ApplicationContext';

interface DocumentIssuesSectionProps {
  documentId: string;
  issues: DocumentIssue[];
}

export const DocumentIssuesSection: FC<DocumentIssuesSectionProps> = ({ issues }) => {
  const queryClient = useQueryClient();
  const { application } = useContext(ApplicationContext);
  const { mutate: updateExtractedData, isPending } = useUpdateExtractedDataQuery({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', application.id] });
      await queryClient.invalidateQueries({ queryKey: ['spreadingDocuments', application.id] });
    },
  });

  const onSumbit = useCallback(
    (formValues: any) => {
      updateExtractedData({
        documentId: issues[0]!.documentId,
        extractedDataKey: issues[0]!.extractedDataKey!,
        correctedValue: Number(formValues.correctedValue),
        confidence: 100,
      });
    },
    [issues, updateExtractedData],
  );

  return (
    <Formik onSubmit={onSumbit} initialValues={{ correctedValue: '' }}>
      {({ handleSubmit }) => (
        <Stack spacing={1}>
          <Typography variant={'h5'}>{'Issues'}</Typography>
          {issues.map((issue) => (
            <Stack key={issue.id} spacing={1} maxWidth={'300px'}>
              <Typography variant={'body1'}>{issue.description}</Typography>
              <PropertyFormikInput name={'correctedValue'} label={'Corrected Value'} fullWidth />
              <LoadingButton
                size={'large'}
                variant={'contained'}
                color={'primary'}
                role={'button'}
                onClick={handleSubmit as any}
                loading={isPending}
              >
                {'Save'}
              </LoadingButton>
            </Stack>
          ))}
        </Stack>
      )}
    </Formik>
  );
};
