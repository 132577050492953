import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import type { User } from '@lama/user-service-client';
import { AssigneeGroup } from '../Application/ApplicationHeader/AssigneeGroup';

interface AssigneeCellProps {
  assignees: User[];
}

export const AssigneeCell: FC<AssigneeCellProps> = ({ assignees }) => (
  <Box display={'flex'} alignItems={'center'}>
    <AssigneeGroup assignees={assignees} max={3} />
  </Box>
);
