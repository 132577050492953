import React from 'react';
import type { FC } from 'react';
import { MaterialRoundedIcon, Tooltip } from '@lama/app-components';
import { Stack, Box, Typography, Chip } from '@mui/material';

const qualificationIcons = {
  approved: {
    name: 'check_circle',
    color: '#44CFA6',
  },
  processing: {
    name: 'help',
    color: '#FFA000',
  },
  rejected: {
    name: 'cancel',
    color: '#EF5350',
  },
};

const LineData: FC<{ values: string[] | string; type: 'array' | 'item' }> = ({ values, type }) => {
  if (!values?.length) {
    return (
      <Typography variant={'body2'} color={'text.primary'}>
        {'-'}
      </Typography>
    );
  }

  if (type === 'array') {
    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Box gap={1} display={'flex'} flexDirection={'row'} flex={0} flexWrap={'wrap'}>
          {(values as string[]).map((value, index) =>
            index < 1 ? <Chip key={value} label={value} variant={'outlined'} size={'small'} sx={{ color: 'text.secondary' }} /> : null,
          )}
        </Box>
        {values.length > 1 ? (
          <Tooltip title={(values as string[]).join(', ')} placement={'top-end'}>
            <Typography variant={'tooltip'} color={'text.disabled'} noWrap>{`+${values.length - 1} more`}</Typography>
          </Tooltip>
        ) : null}
      </Stack>
    );
  }

  if (type === 'item') {
    return (
      <Typography variant={'body2'} color={'text.secondary'} noWrap>
        {values}
      </Typography>
    );
  }
  return null;
};

export interface DealInfoLineProps {
  icon: FC;
  label: string;
  values: string[] | string;
  type: 'array' | 'item';
  check?: 'approved' | 'processing' | 'rejected';
}

export const DealInfoLine: React.FC<DealInfoLineProps> = ({ icon: Icon, label, values, type, check }) => (
  <Stack spacing={1} direction={'row'} width={'100%'}>
    <Tooltip title={label} placement={'top-start'}>
      <Box height={'16px'} width={'16px'}>
        <Icon />
      </Box>
    </Tooltip>
    <Stack flex={0.5} direction={'row'} spacing={0.5} alignItems={'center'} minWidth={0}>
      <LineData values={values} type={type} />
      {values.length && check ? (
        <MaterialRoundedIcon color={qualificationIcons[check].color} name={qualificationIcons[check].name} size={'16px'} />
      ) : null}
    </Stack>
  </Stack>
);
