import React, { forwardRef, useContext, useMemo } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { getRequirementsByCategory } from '@lama/app-components';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { ApplicationDisclaimer } from './ApplicationDisclaimer';
import { CategoryPrintView } from './PrintViews/CategoryPrintView';

interface ApplicationExportProps {
  requirements: EvaluatedOpportunityRequirement[];
}

export const ApplicationExport = forwardRef<HTMLDivElement, ApplicationExportProps>(({ requirements }, ref) => {
  const { application } = useContext(ApplicationContext);

  const requirementsByCategory = useMemo(
    () => getRequirementsByCategory(requirements, application).filter(({ categoryShortName }) => categoryShortName !== 'Submit'),
    [application, requirements],
  );

  return process.env.NODE_ENV !== 'test' && application ? (
    <div ref={ref} className={'hiddenContainer'}>
      {requirementsByCategory
        .filter(({ requirements: categoryRequirements }) => categoryRequirements.length)
        .map((category) => (
          <CategoryPrintView
            key={category.categoryId}
            requirements={category.requirements}
            categoryDisplayName={category.categoryLongName}
          />
        ))}
      <ApplicationDisclaimer />
    </div>
  ) : null;
});
