import type { FC } from 'react';
import React from 'react';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../SegmentContainer';

const termsData = [
  {
    label: 'Direct Deposit (PPD & CCD) For Clients',
    values: 'Premium 4-Day Window',
  },
  {
    label: 'Direct Deposit (PPD & CCD) For Own Company',
    values: 'Premium 4-Day Window',
  },
  {
    label: 'Batch Limit $ / Client Payroll Batch',
    values: "The Company's Entire Total For One Pay Period",
  },
  {
    label: 'Batch Limit $ / Own Payroll Batch',
    values: "The Company's Entire Total For One Pay Period",
  },
];

export const FibtLoanTermsTermsViewSegment: FC = () => (
  <SegmentContainer title={'Terms'}>
    <Grid columns={2}>
      {termsData.map((data) => (
        <KeyValuePair key={data.label} name={data.label} value={data.values} />
      ))}
    </Grid>
  </SegmentContainer>
);
