import { useAuth0 } from '@auth0/auth0-react';
import type { UnderwritingNoteCreateModel } from '@lama/clients';
import { useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { displayToast } from '@lama/app-components';
import type { UnderwritingNoteRelatedSectionType } from '../../noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../../noteUtils';
import { queryClient } from '../../../../../framework/queryClient';
import { applicationServiceClient } from '../../../../../shared/clients/applicationServiceClient';

export const useAddReviewedUnderwritingNoteMutation = (
  sectionId: string,
  sectionType: UnderwritingNoteRelatedSectionType,
  opportunityId: string,
  userId: string,
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
      const noteToAdd: UnderwritingNoteCreateModel = {
        [sectionIdProperty]: sectionId,
        id: uuidv4(),
        type: 'reviewed',
      };

      await applicationServiceClient.addNote(opportunityId, noteToAdd, token);

      return { noteToAdd };
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['opportunity', opportunityId] });

      const previousOpportunity = queryClient.getQueryData(['opportunity', opportunityId]);

      if (!previousOpportunity) {
        return;
      }

      queryClient.setQueryData(['opportunity', opportunityId], (old: any) => {
        const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
        return {
          ...old,
          underwriting: {
            ...old.underwriting,
            notes: [
              ...(old.underwriting?.notes || []),
              {
                [sectionIdProperty]: sectionId,
                id: uuidv4(),
                type: 'reviewed',
                createdBy: userId,
                updatedBy: userId,
              },
            ],
          },
        };
      });

      return { previousOpportunity };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
    onError: async (err, variables, context) => {
      if (!context?.previousOpportunity) {
        return;
      }

      queryClient.setQueryData(['opportunity', opportunityId], context.previousOpportunity);

      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
