import { compact } from 'lodash-es';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { Referrer } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useGetPartnersReferrersQuery = (partnerIds: string[], queryOptions?: UseQueryOptions<Referrer[] | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQueries({
    queries: partnerIds.map((partnerId) => ({
      queryKey: ['referrers', partnerId],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        return applicationServiceClient.getPartnerReferrers({ partnerId }, token);
      },
      queryOptions,
    })),
    combine: (results) => ({
      pending: results.some((result) => result.isPending),
      data: compact(results.flatMap((result) => result.data)),
    }),
  });
};
