import type { FC } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { compact, isNil } from 'lodash-es';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { useGetDocumentAttributes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetDocumentAttributes';

import { useGetAllDocumentAttributesWithFinancialData } from '../../hooks/useGetAllDocumentAttributesWithFinancialData';
import { useGetInitialValues } from '../../hooks/useGetInitialValues';
import { useSubmitDocumentFinancials } from '../../hooks/useSubmitDocumentFinancials';
import { useGroupedDocumentPagesByFormInstance } from '../../hooks/useGetGroupedDocumentPAgesByFormInstance';
import { useSyncSpreadingSample } from '../../hooks/useSyncSpreadingSample';
import { financialsFormTypes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/utils/financialsFormTypes';
import { SpreadingFormByType } from './SpreadingFormByType';
import type { FieldFilter } from './FieldFilterButton';
import { FieldFilters } from './FieldFilters';

export const isFinancialStatement = (formType: string) => financialsFormTypes.has(formType);

export const SpreadingForms: FC = () => {
  const { saving, loading: contextLoading, currentDocument } = useContext(SpreadingDocumentContext);
  const [fieldFilter, setFieldFilter] = useState<FieldFilter>('all');

  const { data: documentAttributes, isPending: documentAttributesPending } = useGetDocumentAttributes();
  const attributesForEntity = useMemo(
    () => documentAttributes?.filter((d) => d.entityType === currentDocument.relatedEntityType) ?? [],
    [currentDocument.relatedEntityType, documentAttributes],
  );
  const groupedDocumentPagesByFormInstance = useGroupedDocumentPagesByFormInstance();

  const { formAttributesWithFinancialData: formWithFinancialData } = useGetAllDocumentAttributesWithFinancialData(
    groupedDocumentPagesByFormInstance,
    attributesForEntity ?? [],
  );

  const totalFieldsCount = useMemo(() => formWithFinancialData.flatMap(({ formData }) => formData).length, [formWithFinancialData]);
  const missingFieldsCount = useMemo(
    () => formWithFinancialData.flatMap(({ formData }) => formData).filter(({ financialData }) => isNil(financialData?.value)).length,
    [formWithFinancialData],
  );

  const filters = useMemo(
    () => [
      { filter: 'all' as const, text: 'All Fields', count: totalFieldsCount },
      { filter: 'missing' as const, text: 'Missing Fields', count: missingFieldsCount },
    ],
    [totalFieldsCount, missingFieldsCount],
  );

  const filteredFormAttributesWithFinancialData = useMemo(
    () =>
      compact(
        formWithFinancialData.map((o) => {
          if (fieldFilter === 'all') {
            return o;
          }

          const formType = o.formDocumentPages.at(0)?.formType ?? '';
          if (isFinancialStatement(formType)) {
            return null;
          }

          return {
            ...o,
            formData: o.formData.filter(({ financialData }) => isNil(financialData?.value)),
          };
        }),
      ),
    [fieldFilter, formWithFinancialData],
  );

  const flatValues = useMemo(
    () => filteredFormAttributesWithFinancialData.flatMap(({ formData }) => formData),
    [filteredFormAttributesWithFinancialData],
  );

  const initalValues = useGetInitialValues(flatValues);
  const syncSpreadingSample = useSyncSpreadingSample(currentDocument.applicationId);

  const onSubmit = useSubmitDocumentFinancials(flatValues, fieldFilter, null, syncSpreadingSample);

  if (documentAttributesPending || contextLoading) {
    return <LoadingPage />;
  }

  return (
    <Formik initialValues={initalValues} onSubmit={onSubmit} enableReinitialize enableInitialize>
      {({ submitForm, dirty }) => (
        <>
          <Flex zIndex={1000} p={1} position={'fixed'} right={6}>
            <Button variant={'primary'} onClick={submitForm} loading={saving} disabled={!dirty}>
              {'Save'}
            </Button>
          </Flex>
          <FieldFilters currentFilter={fieldFilter} setFieldFilter={setFieldFilter} filters={filters} />
          {filteredFormAttributesWithFinancialData?.map(({ formData, formDocumentPages }) => (
            <SpreadingFormByType
              key={`${formDocumentPages.at(0)?.formType ?? ''}-${formDocumentPages.at(0)?.page ?? ''}`}
              formDocumentPages={formDocumentPages}
              formData={formData}
            />
          ))}
        </>
      )}
    </Formik>
  );
};
