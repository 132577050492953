/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { RelatedApplicationMatch } from '@lama/contracts';
import { Tooltip } from '@lama/app-components';
import { useTheme } from 'styled-components';
import { LinkIcon } from '../../../../shared/icons/LinkIcon';
import { selectMatch, selectedPrimaryMatchingKey } from './matchesUtils';

interface MatchDisplayProps {
  matches: RelatedApplicationMatch[];
}

export const MatchDisplay: FC<MatchDisplayProps> = ({ matches }) => {
  const theme = useTheme();
  const match = useMemo(() => selectMatch(matches), [matches]);
  const matchingKey = useMemo(() => {
    if (!match) {
      return;
    }

    const primaryMatchingKey = selectedPrimaryMatchingKey(match);

    const primaryMatchingKeyText = `${match?.displayName} has the same ${primaryMatchingKey}`;
    const totalMatchingKeys = match.matchingKeys.length ?? 0;
    const extraMatchingKeysText = totalMatchingKeys > 1 ? ` + ${totalMatchingKeys - 1} more` : '';
    const tooltip = `${primaryMatchingKeyText}${extraMatchingKeysText}`;

    return {
      display: primaryMatchingKey,
      tooltip,
    };
  }, [match]);

  if (!match || !matchingKey?.display) {
    return null;
  }

  return (
    <Flex gap={2} alignItems={'center'} height={'100%'}>
      <Text variant={'body2'} color={'secondary'} ellipsis>
        {match.displayName}
      </Text>
      <Text variant={'body2'} color={'grey.300'}>
        {' | '}
      </Text>
      <Tooltip title={matchingKey.tooltip} placement={'top'}>
        <Flex gap={1}>
          <LinkIcon color={theme.colors.primary.main} />
          <Text variant={'body2'} color={'secondary'} ellipsis>
            {matchingKey.display}
          </Text>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
