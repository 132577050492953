import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Entity } from '@lama/common-types';
import type { FinancialData } from '@lama/contracts';
import type { FinancialUpdateModel } from '@lama/clients';
import { financialsServiceClient } from '../../../../../../../shared/clients/financialsServiceClient';
import type { FinancialPayload } from '../types';

export const useSubmitFinancialsMutation = (entityId: string, entityType: Entity, opportunityId?: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (financialsPayload: FinancialPayload[]) => {
      const token = await getAccessTokenSilently();

      const createPayload = financialsPayload.filter(({ action }) => action === 'create').map(({ action, ...f }) => f as FinancialData);
      const deleteFinancialIds = financialsPayload.filter(({ action }) => action === 'delete').map(({ id }) => id);
      const updatePayload = financialsPayload
        .filter(({ action }) => action === 'update')
        .map(({ action, ...f }) => f as FinancialUpdateModel);

      await Promise.all([
        ...(updatePayload.length ? [financialsServiceClient.updateFinancials(entityType, entityId, updatePayload, token)] : []),
        ...(deleteFinancialIds.length
          ? [financialsServiceClient.deleteFinancials(entityType, entityId, { financialIds: deleteFinancialIds }, token)]
          : []),
        createPayload.length ? financialsServiceClient.createFinancials(entityType, entityId, createPayload, token) : {},
      ]);
    },
    onSuccess: async () => {
      await Promise.all([
        opportunityId
          ? [
              queryClient.invalidateQueries({
                queryKey: ['opportunity', opportunityId],
              }),
              queryClient.invalidateQueries({
                queryKey: ['opportunityRequirements', opportunityId],
              }),
              queryClient.invalidateQueries({
                queryKey: ['opportunityEvaluation', opportunityId],
              }),
            ]
          : [],
        queryClient.invalidateQueries({
          queryKey: ['financials', entityId],
        }),
      ]);
    },
  });
};
