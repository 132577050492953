import type { IconButtonProps } from '@mui/material';
import { IconButton, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

interface ExpandButtonProps extends Omit<IconButtonProps, 'size'> {
  expand: boolean;
  size?: number;
}

export const ExpandButton = styled((props: ExpandButtonProps) => {
  const { expand, size, ...other } = props;
  return (
    <IconButton sx={size ? { width: size * 1.2, height: size * 1.2 } : {}} {...other} aria-expanded={expand} aria-label={'show more'}>
      <ExpandMoreIcon sx={{ width: size, height: size }} />
    </IconButton>
  );
})(({ theme, expand }) => ({
  transform: `rotate(${expand ? 180 : 0}deg)`,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandButton;
