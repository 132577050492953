import React, { useMemo } from 'react';
import type { FC } from 'react';
import { isNil } from 'lodash-es';
import type { ConditionEvaluationReason, EvaluatedCondition } from '@lama/contracts';
import { ConditionAlert } from '../../../../shared/components/conditions/ConditionAlert';

export const ConditionsAlertsSegment: FC<{ conditions: EvaluatedCondition[] }> = ({ conditions }) => {
  const conditionAssessment = useMemo<ConditionEvaluationReason | null>(() => {
    const failed = conditions?.filter((condition) => !condition.result && !isNil(condition.extractedValue))?.length ?? 0;
    const missingData = conditions?.filter((condition) => isNil(condition.extractedValue))?.length ?? 0;
    const pass = conditions?.filter((condition) => condition.result && !isNil(condition.extractedValue))?.length ?? 0;

    if (failed > 0) {
      return 'fail';
    }

    if (missingData > 0) {
      return 'missing_data';
    }

    if (pass === conditions?.length) {
      return 'pass';
    }

    return null;
  }, [conditions]);

  if (!conditionAssessment) {
    return null;
  }

  return <ConditionAlert alertType={conditionAssessment} />;
};
