import React from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import {
  balanceSheetAttributes,
  incomeStatementAttributes,
  attachmentsAttributes,
  personalFinancialStatementAttributes,
} from '@lama/spreading';
import type { MRT_Cell, MRT_Column, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { Flex } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { isNil } from 'lodash-es';
import { LineItemParentSelect } from '../components/LineItemParentCellSelect';
import type { Period } from '../components/FinancialStatementsSpreadingContent';
import { FinancialAttributeCellSelect } from '../components/FinancialAttributeCellSelect';
import { YearSelectionMenuItem } from '../components/YearColumnActionsMenu';
import { NumericEditCell } from '../components/NumericEditCell';
import { LineItemPageCellSelect } from '../components/LineItemPageCellSelect';
import { LineItemIndexCellSelect } from '../components/LineItemIndexCellSelect';

interface EditCellProps {
  cell: MRT_Cell<DocumentLineItem>;
  column: MRT_Column<DocumentLineItem>;
  row: MRT_Row<DocumentLineItem>;
  table: MRT_TableInstance<DocumentLineItem>;
}

export interface ColumnMeta {
  startDate?: Date;
  endDate?: Date;
}

export const createAttributeColumns = (
  documentLineItems: DocumentLineItem[],
  formType: string,
  formDocumentPageNumbers: number[],
  isCreateMode: boolean,
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void,
  onCellBlur: (cell: MRT_Cell<DocumentLineItem>, value: string) => Promise<void>,
  onParentChange: (
    row: MRT_Row<DocumentLineItem>,
    column: MRT_Column<DocumentLineItem>,
    cellLineItem: DocumentLineItem,
    value: string | null,
  ) => Promise<void>,
): MRT_ColumnDef<DocumentLineItem>[] => {
  const attributeOptions =
    formType === 'Balance Sheet'
      ? balanceSheetAttributes
      : formType === 'Income Statement'
      ? incomeStatementAttributes
      : formType === 'Personal Financial Statement'
      ? personalFinancialStatementAttributes
      : formType === 'Attachments'
      ? attachmentsAttributes
      : [];

  const attributeColumns = [
    {
      id: 'page',
      accessorKey: 'page',
      header: 'Page',
      Edit: ({ cell, table, row, column }: EditCellProps) => (
        <LineItemPageCellSelect
          cell={cell}
          table={table}
          row={row}
          column={column}
          attributeOptions={formDocumentPageNumbers.map(String)}
        />
      ),
    },
    {
      id: 'index',
      accessorKey: 'lineIndex',
      header: 'Index',
      Edit: ({ cell, table, row, column }: EditCellProps) => {
        const pageLineItems = documentLineItems.filter((lineItem) => lineItem.page === cell.row.original.page);
        const allPossibleIndexes = Array.from({ length: pageLineItems.length })
          .map((_, i) => i)
          .map(String);

        return <LineItemIndexCellSelect cell={cell} table={table} row={row} column={column} attributeOptions={allPossibleIndexes} />;
      },
    },
    {
      id: 'text',
      accessorKey: 'text',
      header: 'Line Item',
      muiEditTextFieldProps: ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => ({
        type: 'text',
        onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
          void onCellBlur(cell, event.target.value);
        },
        onFocus: () => {
          onCellFocus(cell);
        },
      }),
    },
    {
      id: 'attribute',
      accessorKey: 'attribute',
      header: 'Attribute',
      Edit: ({ cell, table, row, column }: EditCellProps) => (
        <FinancialAttributeCellSelect cell={cell} table={table} row={row} column={column} attributeOptions={attributeOptions} />
      ),
    },
    {
      id: 'parentId',
      header: 'Parent',
      Edit: ({ cell, table, row, column }: EditCellProps) => (
        <LineItemParentSelect
          lineItems={documentLineItems ?? []}
          cell={cell}
          table={table}
          row={row}
          column={column}
          onChange={onParentChange}
        />
      ),

      accessorKey: 'parentId',

      accessorFn: (lineItem: DocumentLineItem) => documentLineItems?.find((item) => item.id === lineItem.parentId)?.text ?? '',
    },
  ];

  return attributeColumns.map<MRT_ColumnDef<DocumentLineItem>>((column) => ({
    ...column,
    muiEditTextFieldProps: ({ cell }) => ({
      type: 'text',
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
        void onCellBlur(cell, event.target.value);
      },
      onFocus: () => {
        onCellFocus(cell);
      },
    }),
  }));
};

export const createYearColumns = (
  lineItems: DocumentLineItem[],
  allPeriods: Period[],
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void,
  onCellBlur: (cell: MRT_Cell<DocumentLineItem>, value: string) => Promise<void>,
  enableEditingFinancialDatesInSpreading: boolean,
) =>
  [...allPeriods]
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    .map<MRT_ColumnDef<DocumentLineItem>>(({ startDate, endDate }) => ({
      meta: {
        startDate,
        endDate,
      },
      id: `${startDate}-${endDate}`,
      ...(enableEditingFinancialDatesInSpreading
        ? {
            renderColumnActionsMenuItems: (props) => [
              <YearSelectionMenuItem
                key={props.column.id}
                numberOfPeriods={allPeriods.length}
                lineItems={lineItems}
                startDate={startDate}
                endDate={endDate}
                {...props}
              />,
            ],
            muiColumnActionsButtonProps(props) {
              return {
                ...props,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'aria-label': `Open ${startDate.toISOString()} - ${endDate.toISOString()} column actions menu`,
              };
            },
          }
        : {}),
      header: endDate.getFullYear().toString(),
      Edit: ({ cell, row, column }: EditCellProps) => (
        <NumericEditCell cell={cell} row={row} column={column} onCellBlur={onCellBlur} onCellFocus={onCellFocus} />
      ),
      Cell: ({ cell }) => {
        const cellValue = cell.getValue<number>();
        if (isNil(cellValue) || Number.isNaN(cellValue)) {
          return <Flex />;
        }

        const formattedValue = formatValue(cell.getValue<number>(), 'currency');
        return <Flex>{formattedValue}</Flex>;
      },
      accessorFn: (lineItem) => {
        const value = lineItem.values?.find((lineValue) => lineValue.startDate.toString() === startDate.toISOString());
        return value?.value;
      },
    }));
