import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Button, greyPalette, ModalFooter, Modal, ModalContent } from '@lama/design-system';
import { Checkbox, FormControlLabel } from '@mui/material';
import type { PartnerRequirement } from '@lama/contracts';

interface AddRequirementDialogProps {
  open: boolean;
  handleClose: () => void;
  onConfirm: (requirement: PartnerRequirement) => void;
  requirement: PartnerRequirement | null;
}

export const AddRequirementDialog: FC<AddRequirementDialogProps> = ({ open, handleClose, onConfirm, requirement }) => {
  const [notifyBorrower, setNotifyBorrower] = useState(true);

  const onClickNotifyBorrower = useCallback(() => {
    setNotifyBorrower((prev) => !prev);
  }, []);

  const onClickConfirm = useCallback(() => {
    if (!requirement) {
      return;
    }
    onConfirm({ ...requirement, notifyBorrower });
  }, [onConfirm, requirement, notifyBorrower]);

  return (
    <Modal open={open} onClose={handleClose} title={`Add ${requirement?.name} Requirement`} alignTitle={'left'}>
      <ModalContent>
        <FormControlLabel
          sx={{ color: greyPalette[600] }}
          control={<Checkbox checked={notifyBorrower} onChange={onClickNotifyBorrower} />}
          label={'Notify the borrower about the new requirement'}
        />
      </ModalContent>
      <ModalFooter>
        <Button variant={'tertiary'} color={'neutral'} onClick={handleClose}>
          {'Cancel'}
        </Button>
        <Button variant={'primary'} color={'primary'} onClick={onClickConfirm}>
          {'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
