import React, { useContext, useCallback, useMemo } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import type { MRT_Cell, MRT_Row, MRT_TableInstance, MRT_Column } from 'material-react-table';
import type { FC } from 'react';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import { useUpdateDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemMutation';
import { FinancialSpreadingCellPicker } from './FinancialSpreadingCellPicker';

interface FinancialAttributeCellSelectProps {
  cell: MRT_Cell<DocumentLineItem>;
  row: MRT_Row<DocumentLineItem>;
  table: MRT_TableInstance<DocumentLineItem>;
  column: MRT_Column<DocumentLineItem>;
  attributeOptions: string[];
}

export const FinancialAttributeCellSelect: FC<FinancialAttributeCellSelectProps> = ({ cell, row, table, column, attributeOptions }) => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { mutateAsync: updateDocumentLine } = useUpdateDocumentLineItemMutation();

  const lineItem = cell.row.original;

  const onAttributeChange = useCallback(
    async (value: string | null) => {
      if (!value) {
        await updateDocumentLine({
          documentId: currentDocument.id,
          lineItemId: lineItem.id,
          lineItemUpdate: { attribute: null },
        });

        return;
      }

      row._valuesCache[column.id] = value;

      if (lineItem.id) {
        await updateDocumentLine({
          documentId: currentDocument.id,
          lineItemId: lineItem.id,
          lineItemUpdate: { attribute: value },
        });
      }
    },
    [column.id, currentDocument.id, lineItem, row, updateDocumentLine],
  );

  const options = useMemo(
    () =>
      attributeOptions.map((attribute) => ({
        label: attribute,
        value: attribute,
      })),
    [attributeOptions],
  );

  const currentOption = useMemo(() => options.find((option) => option.value === lineItem.attribute), [options, lineItem.attribute]);

  return (
    <FinancialSpreadingCellPicker
      onValueChanged={onAttributeChange}
      label={'Attribute'}
      options={options}
      table={table}
      value={currentOption}
    />
  );
};
