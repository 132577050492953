import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { GenericPropertiesProvider, LoadingPage } from '@lama/app-components';
import type { UnderwritingSection } from '@lama/contracts';
import { keyBy } from 'lodash-es';
import { CommentsSegment } from '../../../shared/components/Comments/CommentsSegment';
import { SectionDecisioning } from '../shared/SectionDecisioning';
import { SectionTitle } from '../shared/SectionTitle/SectionTitle';
import { customComponents } from '../OpportunityRequirements/OpportunityRequirements/RequirementScreens/customComponentsMap';
import { GenericSection } from '../shared/GenericSection/GenericSection';
import type { MultipliedSection } from '../shared/sectionUtils';
import { useGetPartnerSectionsQuery } from '../../../shared/hooks/react-query/partnerSections/useGetPartnerSectionsQuery';
import { ApplicationContext } from '../ApplicationContext';

export const UnderwritingSectionContent: FC<{ section?: MultipliedSection<UnderwritingSection> }> = ({ section }) => {
  const {
    opportunity: { partnerId },
  } = useContext(ApplicationContext);
  const { data: partnerSections, isPending: loadingPartnerSections } = useGetPartnerSectionsQuery(partnerId);

  const sectionConfigurationByKey = useMemo(() => keyBy(partnerSections, (c) => c.key), [partnerSections]);

  const sectionComponent = useMemo(() => {
    if (!section?.key) {
      return null;
    }

    const partnerSection = sectionConfigurationByKey[section.key];

    if (!partnerSection?.segments.length) {
      return null;
    }

    return <GenericSection segments={partnerSection.segments} sectionId={section.id} sectionEntity={section.entity} />;
  }, [section, sectionConfigurationByKey]);

  if (loadingPartnerSections) {
    return <LoadingPage />;
  }

  if (!section || !sectionComponent) {
    return null;
  }

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={{}}>
      <Flex flexDirection={'column'} px={14} py={10} flex={1} overflow={'auto'} alignItems={'center'}>
        <Flex flexDirection={'column'} width={'100%'} maxWidth={'960px'} gap={8}>
          <Flex flexDirection={'column'} gap={4}>
            <SectionTitle sectionId={section.id} sectionType={'underwriting'} title={section.prettyName} />
            {section.hideUnderwritingActions ? null : <SectionDecisioning sectionId={section.id} sectionType={'underwriting'} />}
          </Flex>
          {sectionComponent}
          <Flex flexDirection={'column'} pt={20}>
            <CommentsSegment relatedItemId={section.id} relatedItemType={'underwriting'} />
          </Flex>
        </Flex>
      </Flex>
    </GenericPropertiesProvider>
  );
};
