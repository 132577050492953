import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../../shared/clients/applicationServiceClient';

interface ChangeOpportunityProductMutationVariables {
  productId: string;
}

export const useChangeOpportunityProductMutation = (
  opportunityId: string,
  options?: UseMutationOptions<void, unknown, ChangeOpportunityProductMutationVariables>,
) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ productId }) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.changeOpportunityProductV2(opportunityId, productId, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
