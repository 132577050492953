import { bluePalette, Button, Flex, Text, yellowPalette } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import type { OpportunityApiModel } from '@lama/clients';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { useTranslation } from 'react-i18next';
import { LoadingPage, useConfirmModal } from '@lama/app-components';
import { personFullName } from '@lama/data-formatters';
import {
  getErrorPullingCreditReportMessage,
  getNoRecordFoundMessage,
  internalErrorCodesToMessage,
  getPersonCreditNotPulledYetMessage,
} from '@lama/credit-report-retriever-client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import type { CreditReportData } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash-es';
import { OfacReportIndividualSegment } from '../../../../shared/OfacReport/OfacReportIndividualSegment';
import { PersonFraudShieldAlertsSegment } from '../../../../shared/FraudShieldAlert/PersonFraudShieldAlertsSegment';
import { usePullCreditReport } from '../../../../../../shared/hooks/react-query/creditReport/usePullCreditReport';
import { ApplicationContext } from '../../../../ApplicationContext';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { useUsersByPartnerQuery } from '../../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useGetPartnerQuery } from '../../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { DerogatoryHistory } from './DerogatoryHistory';
import { InquiryDetails } from './InquiryDetails';
import { ScoreSummary } from './ScoreSummary';
import { TradeSummary } from './TradeSummary';
import { Tradelines } from './Tradelines';

interface CreditReportProps {
  person: RelatedPersonApiModel;
  tradelinesStartExpanded?: boolean;
}

const CreditReportAlerts: FC<{
  opportunity: OpportunityApiModel;
  creditReport?: CreditReportData;
  person: RelatedPersonApiModel;
}> = ({ opportunity, creditReport, person }) => {
  const { mutate: pullCredit, isPending: pullingCreditReport } = usePullCreditReport(opportunity.id);
  const personName = useMemo(() => personFullName(person), [person]);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();

  const { data: users, isLoading: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);

  const fetchStatus = useMemo(() => creditReport?.fetchStatus, [creditReport?.fetchStatus]);
  const errorCodes = creditReport?.fetchErrorCodes;

  const infoMessage = useMemo(() => {
    const clearance = opportunity.creditReportFraudulentActivityClearance?.find((c) => c.personId === person.id);

    if (!clearance) {
      return null;
    }

    const clearingUser = users?.find((u) => u.id === clearance.clearedBy);

    return `Fraudulent activity has been reported on ${personName}'s credit report. The alert was cleared by ${
      clearingUser ? personFullName(clearingUser) : 'UNKNOWN'
    }.`;
  }, [opportunity.creditReportFraudulentActivityClearance, person.id, personName, users]);

  const errorMessage = useMemo(() => {
    if (!fetchStatus) {
      return getPersonCreditNotPulledYetMessage(personName);
    }

    const filteredErrorCodes = errorCodes?.filter(
      (e) =>
        e !== 'STATEMENT_FRAUDULENT_ACTIVITY_REPORTED' ||
        !opportunity.creditReportFraudulentActivityClearance?.some((c) => c.personId === person.id),
    );

    if (filteredErrorCodes?.length) {
      return filteredErrorCodes.map((errorCode) => internalErrorCodesToMessage[errorCode](personName)).at(0);
    }

    if (fetchStatus === 'Error') {
      return getErrorPullingCreditReportMessage(personName);
    }

    if (fetchStatus === 'Not Found') {
      return getNoRecordFoundMessage(personName);
    }
  }, [errorCodes, fetchStatus, opportunity.creditReportFraudulentActivityClearance, person.id, personName]);

  const pullEnabled = useMemo(
    () => opportunity.application.submittedAt && (isNil(fetchStatus) || fetchStatus === 'Error' || fetchStatus === 'Not Found'),
    [fetchStatus, opportunity.application.submittedAt],
  );

  const onPullCreditClick = useCallback(async () => {
    const confirmed = await confirm({
      title: t('creditReport.dialog.title'),
      message: t('creditReport.dialog.message'),
      confirmText: t('creditReport.dialog.confirmText'),
    });
    if (!confirmed) {
      return;
    }

    pullCredit();
  }, [confirm, pullCredit, t]);

  if (loadingUsers) {
    return null;
  }

  if (!errorMessage && !infoMessage) {
    return null;
  }

  return (
    <>
      {errorMessage ? (
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={2}
          width={'100%'}
          p={4}
          borderRadius={'4px'}
          height={'64px'}
          backgroundColor={yellowPalette[50]}
        >
          <Flex gap={2} alignItems={'center'}>
            <ErrorOutlineRounded color={'warning'} />
            <Text variant={'body2'}>{errorMessage}</Text>
          </Flex>
          {pullEnabled ? (
            <Button variant={'secondary'} color={'neutral'} onClick={onPullCreditClick} loading={pullingCreditReport}>
              {t('creditReport.cta')}
            </Button>
          ) : null}
        </Flex>
      ) : null}
      {infoMessage ? (
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={2}
          width={'100%'}
          p={4}
          borderRadius={'4px'}
          height={'64px'}
          backgroundColor={bluePalette[50]}
        >
          <Flex gap={2} alignItems={'center'}>
            <ErrorOutlineRounded color={'info'} />
            <Text variant={'body2'}>{infoMessage}</Text>
          </Flex>
          {pullEnabled ? (
            <Button variant={'secondary'} color={'neutral'} onClick={onPullCreditClick} loading={pullingCreditReport}>
              {t('creditReport.cta')}
            </Button>
          ) : null}
        </Flex>
      ) : null}
    </>
  );
};

export const CreditReport: FC<CreditReportProps> = ({ person, tradelinesStartExpanded }) => {
  const { hideOldCreditReports, showEquifaxCreditProvider } = useFlags();

  const { opportunity } = useContext(ApplicationContext);

  const { data: partner, isLoading: loadingPartner } = useGetPartnerQuery(opportunity.partnerId);

  const fetchStatus = useMemo(() => person?.thirdPartyData?.creditReport?.fetchStatus, [person?.thirdPartyData]);

  // This is a patch for SouthState, so they don't see the old CRS credit reports.
  const fetchDate = person?.thirdPartyData?.creditReport?.ficoScoreDate;
  const shouldHideReport = useMemo(() => hideOldCreditReports && fetchDate && fetchDate < '2024-06-27', [fetchDate, hideOldCreditReports]);

  if (loadingPartner) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <CreditReportAlerts opportunity={opportunity} creditReport={person.thirdPartyData?.creditReport} person={person} />
      {shouldHideReport ? (
        <Text>{'You do not have permissions to view this report.'}</Text>
      ) : fetchStatus === 'Fetched' ? (
        <>
          <InquiryDetails guarantor={person} />
          <ScoreSummary person={person} creditProvider={showEquifaxCreditProvider ? 'Equifax' : partner?.creditProvider ?? 'Experian'} />
          <TradeSummary guarantor={person} />
          <DerogatoryHistory guarantor={person} />
          <Tradelines guarantor={person} tradelinesStartExpanded={tradelinesStartExpanded} />
          <SegmentContainer title={'OFAC Lookup'} bordered={false}>
            <OfacReportIndividualSegment individual={person} />
          </SegmentContainer>
          <SegmentContainer title={'Fraud Shield Alert'}>
            <PersonFraudShieldAlertsSegment person={person} />
          </SegmentContainer>
        </>
      ) : null}
    </Flex>
  );
};
