import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { DocumentPage } from '@lama/document-service-client';
import type { OpportunityApiModel } from '@lama/clients';
import { TaxReturnsSpreadingForm } from '../../Forms/TaxReturns/components/TaxReturnsForm';
import { FinancialStatementsSpreadingForm } from '../../Forms/FinancialStatements/components/FinancialStatementsSpreadingContent';
import type { FormAttributesWithFinanacialData } from '../../Forms/TaxReturns/formFinancialsUtils';
import { isFinancialStatement } from './SpreadingForms';

export interface SpreadingFormByDocumentTypeProps {
  opportunity?: OpportunityApiModel;
  formDocumentPages: DocumentPage[];
  formData?: FormAttributesWithFinanacialData[];
}

export const SpreadingFormByType: FC<SpreadingFormByDocumentTypeProps> = ({ formDocumentPages, formData }) => {
  const SpreadingContent = useMemo(
    () => (isFinancialStatement(formDocumentPages.at(0)?.formType ?? '') ? FinancialStatementsSpreadingForm : TaxReturnsSpreadingForm),
    [formDocumentPages],
  );

  return <SpreadingContent formData={formData} formDocumentPages={formDocumentPages} />;
};
