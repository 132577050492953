import { Flex, Button } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirmModal } from '@lama/app-components';
import { toast } from 'react-toastify';
import { useCreateDocumentIssueMutation } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useCreateDocumentIssueMutation';
import { useGetFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetFinancialsQuery';
import { useSubmitFinancialsMutation } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { useDocumentLineItemsQuery } from '../../../../shared/hooks/react-query/document/lineItems/useDocumentLineItemsQuery';
import { useUpdateDocumentLineItemsMutation } from '../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import type { FinancialUpdatePayload } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/types';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useGetDocumentAttributes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetDocumentAttributes';
import { useGroupedDocumentPagesByFormInstance } from '../../hooks/useGetGroupedDocumentPAgesByFormInstance';
import { useValidateDocumentMutation } from '../../hooks/useValidateDocumentMutation';
import { CreateDocumentErrorPopover } from './CreateDocumentErrorPopover';
import { exportSampleData } from './exportSampleData.js';
import { copytoSpreadingTestsPartner } from './copytoSpreadingTestsPartner.js';

const errorTypes = [
  'Wrong document',
  'Missing form',
  'Wrong year',
  'Bad scan quality',
  'Automatic spreading is not supported for password protected documents',
  'Duplicate document',
  'File format is not supported',
  'Tax return required, IRS transcript received',
];

export const SpreadingToolbarActions: FC = () => {
  const { currentDocument, opportunityId, applicationId } = useContext(SpreadingDocumentContext);

  const { spreadingBackOfficeMode } = useFlags();
  const { isPending: creatingIssue, mutateAsync: createDocumentIssue } = useCreateDocumentIssueMutation(opportunityId, applicationId);
  const { data: documentAttributes } = useGetDocumentAttributes();
  const { confirm } = useConfirmModal();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: entityFinancials, isPending: fetchingFinancials } = useGetFinancialsQuery(
    currentDocument.relatedEntityId,
    !!spreadingBackOfficeMode,
  );

  const { mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    currentDocument.relatedEntityId,
    currentDocument.relatedEntityType,
    opportunityId,
  );
  const { mutateAsync: validateDocument } = useValidateDocumentMutation({ opportunityId, applicationId });

  const { data: documentLineItems } = useDocumentLineItemsQuery(currentDocument.id, !!spreadingBackOfficeMode);
  const { mutateAsync: updateDocumentLineItems } = useUpdateDocumentLineItemsMutation();

  const { getAccessTokenSilently } = useAuth0();
  const user = useContext(UserDetailsContext);

  const onSendErrorClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const sendDocumentIssue = useCallback(
    async (reason: string) => {
      if (currentDocument?.id) {
        await createDocumentIssue(
          {
            documentId: currentDocument.id,
            issue: {
              id: uuidv4(),
              description: reason,
            },
          },
          {
            onSuccess: () => toast.success('Document issue sent successfully'),
            onError: () => toast.error('Failed to send document issue'),
          },
        );
      }
    },
    [createDocumentIssue, currentDocument],
  );

  const validateFinancials = useCallback(async () => {
    const financialsToVerify = entityFinancials
      ?.filter(
        (f) =>
          (f.source.type === 'Document' && f.source.documentId === currentDocument?.id && f.source.unverified) ||
          (f.source.type === 'Manual' && f.source.relatedDocumentId === currentDocument?.id && f.source.unverified),
      )
      .map<FinancialUpdatePayload>(({ id, source }) => ({
        action: 'update',
        id,
        source: {
          ...source,
          unverified: false,
        },
      }));

    if (!financialsToVerify?.length) {
      return;
    }

    await updateFinancialData(financialsToVerify);
  }, [currentDocument, entityFinancials, updateFinancialData]);

  const validateLineItems = useCallback(async () => {
    const lineItemsToUpdate = documentLineItems?.filter((lineItem) => lineItem.unverified).map(({ id }) => id);

    if (!lineItemsToUpdate) {
      return;
    }

    await updateDocumentLineItems({
      documentId: currentDocument.id,
      lineItemUpdateBody: { lineItemUpdates: lineItemsToUpdate.map((id) => ({ id, unverified: false })) },
    });
  }, [documentLineItems, currentDocument, updateDocumentLineItems]);

  const copyToTestPartner = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return copytoSpreadingTestsPartner(user, currentDocument, token);
  }, [currentDocument, getAccessTokenSilently, user]);

  const [{ loading: validatingData }, validateSpreadingData] = useAsyncFn(async () => {
    const confirmed = await confirm({
      title: 'Are you sure?',
      message: 'Are you sure you want to send all fields to the user?\nMake sure you have verified and filled all fields',
    });

    if (!confirmed) {
      return false;
    }

    try {
      await validateLineItems();
      await validateFinancials();
      await validateDocument(currentDocument.id);
    } catch (error) {
      console.error(error);
      toast.error('Failed to validate spreading data');
    }

    // Only copy to spreading tests partner if the document has not been validated before
    if (!currentDocument.extracted) {
      await copyToTestPartner();
    }
  }, [confirm, currentDocument.extracted, currentDocument.id, validateLineItems, validateFinancials, validateDocument, copyToTestPartner]);

  const groupedDocumentPagesByFormInstance = useGroupedDocumentPagesByFormInstance();

  const exportAsSample = useCallback(async () => {
    void exportSampleData(
      currentDocument,
      groupedDocumentPagesByFormInstance,
      entityFinancials ?? [],
      documentAttributes ?? [],
      documentLineItems ?? [],
    );
  }, [currentDocument, documentAttributes, documentLineItems, entityFinancials, groupedDocumentPagesByFormInstance]);

  return (
    <Flex gap={2} alignItems={'center'} justifyContent={'center'}>
      {spreadingBackOfficeMode ? (
        <>
          <Button onClick={validateSpreadingData} variant={'primary'} loading={validatingData || fetchingFinancials}>
            {'Validate'}
          </Button>
          <Button onClick={exportAsSample}>{'Download Sample'}</Button>
          <Button
            onClick={onSendErrorClick}
            disabled={!currentDocument || !!currentDocument.issues?.length}
            color={'danger'}
            loading={creatingIssue}
          >
            {'Send Document Error'}
          </Button>
          {anchorEl ? (
            <CreateDocumentErrorPopover
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              onSubmit={sendDocumentIssue}
              options={errorTypes}
            />
          ) : null}
        </>
      ) : null}
    </Flex>
  );
};
