import { Button, Input, Modal, ModalContent, ModalFooter } from '@lama/design-system';
import type { FC, ChangeEvent } from 'react';
import React, { useContext, useState, useCallback } from 'react';
import { useSubmitCodeForEtran } from '../../../../shared/hooks/react-query/etran/useSubmitCodeForEtran';
import { ApplicationContext } from '../../ApplicationContext';
import type { EtranDialogProps } from './types';

export const EtranEnterCodeDialog: FC<EtranDialogProps> = ({ open, onClose, onSuccess }) => {
  const { opportunity } = useContext(ApplicationContext);

  const { mutateAsync: submitCode, isPending: isSubmittingCode } = useSubmitCodeForEtran(opportunity.id);
  const [code, setCode] = useState('');
  const onCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  }, []);
  const onSubmitClick = useCallback(async () => {
    await submitCode(code);
    setCode('');
    onSuccess();
  }, [code, submitCode, onSuccess]);

  return (
    <Modal open={open} onClose={onClose} title={'Pull SBSS Score'} subtitle={'Enter the code you got from SBA'} size={'s'} fullWidth>
      <ModalContent>
        <Input label={'Code'} onChange={onCodeChange} />
      </ModalContent>
      <ModalFooter>
        <Button loading={isSubmittingCode} onClick={onSubmitClick}>
          {'Submit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
