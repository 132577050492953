import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { FinancialData } from '@lama/contracts';
import { useCallback } from 'react';
import { financialsServiceClient } from '../../../clients/financialsServiceClient';

export const useGetFinancialsQuery = (
  entityId: string,
  includeUnverifiedFinancials?: boolean,
  options?: UseQueryOptions<FinancialData[]>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getFinacials = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return financialsServiceClient.getFinancials({ entityId, includeUnverifiedFinancials }, token);
  }, [getAccessTokenSilently, entityId, includeUnverifiedFinancials]);

  const query = useQuery<FinancialData[]>({
    queryKey: ['financials', entityId, includeUnverifiedFinancials],
    queryFn: getFinacials,
    ...options,
  });

  return query;
};
