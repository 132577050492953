import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { applicationBorrowingRelatedBusinessesSelector, businessName, relatedCompaniesSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../../../ApplicationContext';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { RelatedCompaniesSummarySegment } from '../../../shared/RelatedCompany/RelatedCompaniesSummarySegment';
import { RelatedCompanySegment } from '../../../shared/RelatedCompany/RelatedCompanySegment';

export const RelatedCompaniesSection: FC = () => {
  const { application } = useContext(ApplicationContext);

  const borrowersAndRelatedCompanies = useMemo(
    () =>
      applicationBorrowingRelatedBusinessesSelector(application).map(({ business: borrower }) => ({
        borrower,
        relatedCompanies: relatedCompaniesSelector(application, borrower).map(({ business }) => business),
      })),
    [application],
  );

  if (borrowersAndRelatedCompanies.every(({ relatedCompanies }) => relatedCompanies.length === 0)) {
    return <Text variant={'body1'}>{'There are no affiliates associated with this application'}</Text>;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <RelatedCompaniesSummarySegment />
      {borrowersAndRelatedCompanies.map(({ borrower, relatedCompanies }) => (
        <Flex key={borrower.id} flexDirection={'column'} gap={2}>
          <Text variant={'h6'}>{`${businessName(borrower)} affiliates`}</Text>
          {relatedCompanies?.map((business) => (
            <RelatedCompanySegment key={business.id} relatedCompany={business} />
          ))}
        </Flex>
      ))}
      <InlineNote title={'Affiliate Details'} noteName={'Affiliates Overview'} />
    </Flex>
  );
};
