import type { FC } from 'react';
import React, { useMemo, useContext, useCallback, useState } from 'react';
import { BasePrincipalsScreen, SearchExistingCustomersProvider } from '@lama/app-components';
import type { RequirementScreenProps } from '../types';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useInviteToApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useInviteToApplicationMutation';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useUpdateApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useCreatePerson } from '../../../../../../shared/hooks/react-query/people/useCreatePerson';
import { useUpdatePerson } from '../../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { useSearchExistingCustomers } from '../../../../../../shared/hooks/react-query/searchExistingCustomers/useSearchExistingCustomers';
import { useConvertExistingCustomerToEntity } from '../../../../../../shared/hooks/react-query/searchExistingCustomers/useConvertExistingCustomerToEntity';
import { useCreateBusinessMutation } from '../../../../../Pipeline/hooks/useCreateBusinessMutation';
import { useGetPartnerQuery } from '../../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';

export const PrincipalsScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson(opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);
  const { mutateAsync: inviteToApplication, isPending: invitingPerson } = useInviteToApplicationMutation({
    applicationId: application.id,
    opportunityId: opportunity.id,
  });
  const { mutateAsync: convertExistingCustomerToEntity } = useConvertExistingCustomerToEntity(opportunity.partnerId);
  const [searchTerm, setSearchTerm] = useState('');
  const onSearchTermChange = useCallback((newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  }, []);
  const { data } = useSearchExistingCustomers(opportunity.partnerId, searchTerm, searchTerm?.length > 0);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);

  const minRequiredOwnershipPercentage = useMemo(
    () => (requirement.properties.some((p) => p.topic === 'fullPrincipals') ? 100 : 60),
    [requirement.properties],
  );

  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  const loading = useMemo(
    () => creatingBusiness || updatingBusiness || updatingApplication || creatingPerson || updatingPerson || invitingPerson,
    [creatingBusiness, updatingApplication, updatingBusiness, creatingPerson, updatingPerson, invitingPerson],
  );
  const onSelectedEntitySelectorChange = useCallback(
    async (newSelectedEntityIdentfier: string) => {
      const createdEntity = await convertExistingCustomerToEntity(newSelectedEntityIdentfier);
      if (!createdEntity) {
        return;
      }
      return createdEntity;
    },
    [convertExistingCustomerToEntity],
  );

  if (!business) {
    return null;
  }

  return (
    <SearchExistingCustomersProvider
      onSearchResultSelect={onSelectedEntitySelectorChange}
      onSearchTermChange={onSearchTermChange}
      searchResults={data}
      searchButtonVisible={
        partner?.featureConfigurations?.searchExistingCustomersButtonVisible === null
          ? undefined
          : partner?.featureConfigurations?.searchExistingCustomersButtonVisible
      }
    >
      <BasePrincipalsScreen
        business={business}
        application={application}
        requirementProperties={requirement.properties}
        createPerson={createPerson}
        updatePerson={updatePerson}
        createBusiness={createBusiness}
        updateBusiness={updateBusiness}
        updateApplication={updateApplication}
        inviteToApplication={inviteToApplication}
        loading={loading}
        partnerFeatureConfigurations={partner?.featureConfigurations ?? {}}
        minRequiredOwnershipPercentage={minRequiredOwnershipPercentage}
      />
    </SearchExistingCustomersProvider>
  );
};
