import React from 'react';
import { Typography, Stack, Switch, Box, Slider, Checkbox, FormControlLabel, Card, CardContent } from '@mui/material';
import { useToggle } from 'react-use';
import { Collapse, Text } from '@lama/design-system';
import ExpandButton from '../../../shared/components/ExpandButton';

const channelMarks = [
  {
    value: 0,
    label: 'Phone only',
  },
  {
    value: 50,
    label: 'Email only',
  },
  {
    value: 100,
    label: 'Both',
  },
];

const Channels: React.FC = () => (
  <Stack spacing={1}>
    <Typography variant={'h6'} color={'textSecondary'}>
      {'Channels'}
    </Typography>
    <Typography variant={'caption'} color={'textSecondary'}>
      {'Select the appropriate channels for customer communication'}
    </Typography>
    <Box pt={2} px={5}>
      <Slider step={null} defaultValue={0} marks={channelMarks} color={'primary'} />
    </Box>
  </Stack>
);

const urgencyMarks = [
  {
    value: 0,
    label: 'Low',
  },
  {
    value: 12.5,
  },
  {
    value: 25,
  },
  {
    value: 37.5,
  },
  {
    value: 50,
  },
  {
    value: 62.5,
  },
  {
    value: 75,
  },
  {
    value: 87.5,
  },
  {
    value: 100,
    label: 'High',
  },
];

const Urgency: React.FC = () => (
  <Stack spacing={1}>
    <Typography variant={'h6'} color={'textSecondary'}>
      {'Urgency'}
    </Typography>
    <Typography variant={'caption'} color={'textSecondary'}>
      {'This will determine frequency of communication with the customer'}
    </Typography>
    <Box pt={2} px={5}>
      <Slider step={12.5} defaultValue={25} marks={urgencyMarks} color={'secondary'} />
    </Box>
  </Stack>
);

const days = [
  { name: 'Monday', value: true },
  { name: 'Tuesday', value: true },
  { name: 'Wednesday', value: true },
  { name: 'Thursday', value: true },
  { name: 'Friday', value: false },
  { name: 'Saturday', value: false },
  { name: 'Sunday', value: false },
];

const CommunicationDays: React.FC = () => (
  <Stack spacing={1}>
    <Typography variant={'h6'} color={'textSecondary'}>
      {'Communication Availability'}
    </Typography>
    <Typography variant={'caption'} color={'textSecondary'}>
      {'Customer will be contacted on the selected days'}
    </Typography>
    <Stack direction={'row'} spacing={1} pt={2}>
      {days.map((day) => (
        <FormControlLabel key={day.name} label={day.name} control={<Checkbox defaultChecked={day.value} />} />
      ))}
    </Stack>
  </Stack>
);

export const DocumentGatheringCard: React.FC = () => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          p: 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Stack direction={'row'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
          <ExpandButton expand={expanded} onClick={toggleExpanded} />
          <Text variant={'body1'}>{'Document Gathering Automation'}</Text>
          <Stack direction={'row'} spacing={2}>
            <Switch color={'primary'} defaultChecked />
          </Stack>
        </Stack>
      </CardContent>
      <Collapse expanded={expanded}>
        <CardContent>
          <Stack spacing={4}>
            <Stack direction={'row'} spacing={10}>
              <Channels />
              <Urgency />
            </Stack>
            <CommunicationDays />
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
};
