import type { DataNode } from '@lama/design-system';
import { useMemo } from 'react';
import { groupBy, orderBy, uniqBy } from 'lodash-es';
import { v4 } from 'uuid';
import type { MoveToDataNode } from '../../../shared/components/DocumentBox/DocumentBox';
import { getDocumentBoxesWithGroups } from '../getDocumentBoxesWithGroups';
import type { DocumentBoxDetails, GroupedDocumentBoxDetails, GroupingOptions } from '../types';

export const useDocumentsMoveToTree = (documentBoxes: DocumentBoxDetails[], grouping: GroupingOptions): DataNode<MoveToDataNode>[] =>
  useMemo(() => {
    const documentDetailsWithGroups = getDocumentBoxesWithGroups(documentBoxes, grouping === 'recent' ? 'entity' : grouping);

    const additionalPlaceholders = uniqBy(
      documentDetailsWithGroups,
      ({ group, subGroup }) => `${group}_${subGroup}`,
    ).map<GroupedDocumentBoxDetails>((box) => ({
      id: v4(),
      group: box.group,
      subGroup: box.subGroup,
      fileName: '',
      name: 'Additional Documents',
      topic: '',
      viewGroup: box.viewGroup,
      requirement: box.requirement,
      closingTask: box.closingTask,
      entityName: box.entityName,
      topicName: box.topicName,
      entityId: box.entityId,
      entityType: box.entityType,
    }));

    const documentBoxesWithAdditionalPlaceholders = [...documentDetailsWithGroups, ...additionalPlaceholders];

    const relevantDocumentBoxes = documentBoxesWithAdditionalPlaceholders.filter(
      (doc) => doc.group && doc.group !== 'Deleted Documents' && !doc.document,
    );
    const grouped = groupBy(relevantDocumentBoxes, (doc) => doc.group);

    const treeNodes = Object.entries(grouped).flatMap(([group, emptyGroupDocumentBoxes]) => {
      const subGrouped = groupBy(emptyGroupDocumentBoxes, (doc) => doc.subGroup);

      const subgroupNodes = Object.entries(subGrouped).map(([subGroup, emptySubGroupDocumentBoxes]) => ({
        key: `${group}_${subGroup}`,
        title: subGroup,
        children: orderBy(
          emptySubGroupDocumentBoxes.map((box) => ({
            key: box.id,
            title: box.name,
            requirement: box.requirement,
            closingTask: box.closingTask,
            topic: box.topic,
            clickable: true,
            entityId: box.entityId,
            entityType: box.entityType,
          })),
          [(r) => (r.title === 'Additional Documents' ? 1 : 0), (r) => r.title],
        ) as DataNode<MoveToDataNode>[],
      }));

      const groupNode = {
        key: group,
        title: group,
        children: orderBy(subgroupNodes, (r) => r.title),
      };

      return groupNode;
    });

    return treeNodes;
  }, [documentBoxes, grouping]);
