import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SpreadingDocument } from '@lama/document-service-client';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { documentServiceUrl } from '../../../framework/environment';

const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

export const useApplicationSpreadingDocumentsQuery = (applicationId?: string, options?: UseQueryOptions<SpreadingDocument[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getApplicationDocumentsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!applicationId) {
      return [];
    }

    return documentServiceClient.getSpreadingDocuments(applicationId, token);
  }, [applicationId, getAccessTokenSilently]);

  const query = useQuery<SpreadingDocument[]>({
    queryKey: ['spreadingDocuments', applicationId],
    queryFn: getApplicationDocumentsQuery,
    staleTime: Number.POSITIVE_INFINITY,
    ...options,
  });

  return query;
};
