import React from 'react';
import { EtranEnterCodeDialog } from './EtranEnterCodeDialog';
import type { EtranUsernamePasswordProps } from './EtranUsernamePasswordDialog';
import { EtranUsernamePassword } from './EtranUsernamePasswordDialog';
import type { EtranDialogMode } from './types';

export interface EtranAuthenticationDialogProps extends EtranUsernamePasswordProps {
  mode: EtranDialogMode;
}

export const EtranAuthenticationDialog = ({ mode, ...props }: EtranAuthenticationDialogProps) => {
  if (mode === 'auth') {
    return <EtranUsernamePassword {...props} />;
  }
  if (mode === 'code') {
    return <EtranEnterCodeDialog {...props} />;
  }
  return null;
};
