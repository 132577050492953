import React, { useMemo } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { FilterButton, type Filter } from './FilterButton';

interface FiltersProps {
  filter: Filter;
  closingTasks: OpportunityClosingTask[];
  setFilter: (filter: Filter) => void;
}
export const ClosingFilters: React.FC<FiltersProps> = ({ filter, closingTasks, setFilter }) => {
  const counts = useMemo(
    () =>
      closingTasks.reduce(
        (acc, task) => {
          if (task.status === 'Pending') {
            acc.pending++;
          }

          if (task.status === 'Complete') {
            acc.complete++;
          }

          return acc;
        },
        { pending: 0, complete: 0 },
      ),
    [closingTasks],
  );

  return (
    <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
      <FilterButton filter={'all'} active={filter === 'all'} count={counts.pending + counts.complete} setFilter={setFilter} />
      <FilterButton filter={'pending'} active={filter === 'pending'} count={counts.pending} setFilter={setFilter} />
      <FilterButton filter={'complete'} active={filter === 'complete'} count={counts.complete} setFilter={setFilter} />
    </Flex>
  );
};
