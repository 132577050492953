import type { FC } from 'react';
import React from 'react';

const ExportSpreadIcon: FC<{ color?: string }> = ({ color }) => (
  <svg width={'16'} height={'16'} viewBox={'0 0 16 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M8.00001 11.4115C7.87949 11.4115 7.76731 11.3923 7.66348 11.3538C7.55963 11.3154 7.46091 11.2493 7.36733 11.1557L4.25773 8.04615C4.10901 7.89743 4.03562 7.7234 4.03756 7.52405C4.03947 7.3247 4.11286 7.14746 4.25773 6.99232C4.41286 6.83721 4.59106 6.75708 4.79233 6.75195C4.99361 6.74682 5.17182 6.82182 5.32696 6.97695L7.25003 8.90003V1.25C7.25003 1.03718 7.32182 0.858984 7.46541 0.715401C7.60899 0.571801 7.78719 0.5 8.00001 0.5C8.21282 0.5 8.39102 0.571801 8.53461 0.715401C8.67819 0.858984 8.74998 1.03718 8.74998 1.25V8.90003L10.6731 6.97695C10.8218 6.82823 10.9984 6.75483 11.2029 6.75675C11.4073 6.75868 11.5871 6.83721 11.7423 6.99232C11.8871 7.14746 11.9621 7.3231 11.9673 7.51925C11.9724 7.7154 11.8974 7.89103 11.7423 8.04615L8.63268 11.1557C8.5391 11.2493 8.44038 11.3154 8.33653 11.3538C8.2327 11.3923 8.12052 11.4115 8.00001 11.4115ZM2.30773 15.5C1.8026 15.5 1.37503 15.325 1.02503 14.975C0.675031 14.625 0.500031 14.1974 0.500031 13.6923V11.7307C0.500031 11.5179 0.57183 11.3397 0.71543 11.1961C0.859014 11.0525 1.03721 10.9808 1.25003 10.9808C1.46285 10.9808 1.64105 11.0525 1.78463 11.1961C1.92821 11.3397 2.00001 11.5179 2.00001 11.7307V13.6923C2.00001 13.7692 2.03206 13.8397 2.09616 13.9038C2.16027 13.9679 2.2308 14 2.30773 14H13.6923C13.7692 14 13.8397 13.9679 13.9039 13.9038C13.968 13.8397 14 13.7692 14 13.6923V11.7307C14 11.5179 14.0718 11.3397 14.2154 11.1961C14.359 11.0525 14.5372 10.9808 14.75 10.9808C14.9628 10.9808 15.141 11.0525 15.2846 11.1961C15.4282 11.3397 15.5 11.5179 15.5 11.7307V13.6923C15.5 14.1974 15.325 14.625 14.975 14.975C14.625 15.325 14.1974 15.5 13.6923 15.5H2.30773Z'
      }
      fill={color ?? '#1C1B1F'}
    />
  </svg>
);

export default ExportSpreadIcon;
