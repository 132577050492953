import React, { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { PDFLoadingState } from '@lama/app-components';
import { SwapHoriz as SwapHorizIcon } from '@mui/icons-material';
import { Flex, greyPalette } from '@lama/design-system';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import SpreadingPDFViewer from '../PDFViewer/SpreadingPDFViewer';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import { SpreadingForms } from './Content/SpreadingForms';

export const SpreadingDialogContent: FC = () => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const [currentDocumentId, setCurrentDocumentId] = useState<string | undefined>(currentDocument?.id);
  const [currentDocumentUrl, setCurrentDocumentUrl] = useState<string | undefined>(currentDocument?.previewUrl);

  useEffect(() => {
    setCurrentDocumentId(currentDocument?.id);
  }, [currentDocument]);

  useEffect(() => {
    if (currentDocumentId !== currentDocument?.id && currentDocument?.previewUrl !== currentDocumentUrl) {
      setCurrentDocumentUrl(currentDocument?.previewUrl);
    }
  }, [currentDocument, currentDocumentId, currentDocumentUrl]);

  return (
    <PanelGroup direction={'horizontal'} style={{ maxHeight: '95vh' }}>
      <Panel defaultSize={50} minSize={30} style={{ display: 'flex', height: '100%' }}>
        <Flex flexDirection={'column'} flex={1} height={'100%'} overflow={'auto'}>
          {currentDocumentUrl ? (
            <SpreadingPDFViewer fileUrl={currentDocumentUrl} renderLoader={PDFLoadingState} enableSmoothScroll />
          ) : null}
        </Flex>
      </Panel>
      <PanelResizeHandle
        style={{ display: 'flex', width: 18, alignItems: 'center', justifyContent: 'center', backgroundColor: greyPalette[200] }}
      >
        <SwapHorizIcon sx={{ fontSize: 18 }} />
      </PanelResizeHandle>
      <Panel defaultSize={40} minSize={30} style={{ overflow: 'auto', height: '100%' }}>
        <SpreadingForms />
      </Panel>
    </PanelGroup>
  );
};
