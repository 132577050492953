import React from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { useBankingDataQuery } from '../../../../../shared/hooks/react-query/bank/useBankingDataQuery';
import type { RequirementScreenProps } from '../../OpportunityRequirements/RequirementScreens/types';
import { DocumentRequirementScreen } from '../../OpportunityRequirements/RequirementScreens/DocumentRequirementScreen';
import { AccountCard } from './AccountCard';
import { TransactionsCategories } from './Transactions/TransactionsCategories';

export const BankRequirement: FC<RequirementScreenProps> = ({ requirement }) => {
  const { data: accounts, isPending: loadingAccounts } = useBankingDataQuery(requirement.entityId, requirement.entityType);

  if (loadingAccounts) {
    return <LoadingPage />;
  }

  if (!accounts?.length) {
    return <DocumentRequirementScreen requirement={requirement} />;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <Flex flexDirection={'column'} gap={4}>
        {accounts.map((account, index) => (
          <AccountCard key={account.accountId} account={account} institution={account.institutionDetails} expand={index === 0} />
        ))}
      </Flex>
      <TransactionsCategories transactions={accounts?.flatMap((account) => account.transactions) ?? []} />
    </Flex>
  );
};
