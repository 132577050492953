import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { useGetFinancialsQuery } from '../../../shared/hooks/react-query/financials/useGetFinancialsQuery';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

export const useGetAllFinancialsForCurrentDocument = () => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { spreadingBackOfficeMode } = useFlags();

  const { data: entityFinancials, isPending: fetchingData } = useGetFinancialsQuery(
    currentDocument.relatedEntityId,
    !!spreadingBackOfficeMode,
  );
  return { entityFinancials, fetchingData };
};
