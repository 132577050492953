import { BusinessIcon, KeyValuePair, LoadingPage, PersonIcon, Tooltip } from '@lama/app-components';
import type { ValidationError, EtranPayloadTypes, EtranPayloadResponse } from '@lama/clients';
import { Flex, Text, Grid, greyPalette, Collapse } from '@lama/design-system';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { set, capitalize } from 'lodash-es';

import React, { useCallback, useMemo, useState } from 'react';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Divider } from '@mui/material';
import { SegmentContainer } from '../SegmentContainer';
import ExpandButton from '../../../../shared/components/ExpandButton';
import { convertEtranPropertyToLabels } from '../../../../shared/utils/etranPropertiesToLabels';

const ApplicationIcon = () => <ArticleOutlinedIcon sx={{ color: '#616161', height: '16px', width: '16px' }} />;

interface EtranPayloadPreviewSegmentForPayloadProps {
  payload?: Partial<EtranPayloadTypes>;
  title: string;
  icon?: React.ReactNode;
  relation?: string;
}

const EtranPayloadPreviewSegmentForPayload = ({ payload, title, icon, relation }: EtranPayloadPreviewSegmentForPayloadProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback(() => {
    setIsOpen((prev) => !prev);
    return !isOpen;
  }, [isOpen]);
  const hasErrors = useMemo(() => {
    if (!payload) {
      return;
    }
    return Object.values(payload).some((value) => !value);
  }, [payload]);
  if (!payload) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} border={`1px solid ${greyPalette[300]}`} justifyContent={'center'} borderRadius={'8px'}>
      <Flex flexDirection={'column'}>
        <Flex onClick={onClick} gap={4} justifyContent={'space-between'} p={4}>
          <Flex flexDirection={'column'}>
            <Flex alignItems={'center'} gap={1}>
              <Text variant={'body1'}>{title}</Text>
              {hasErrors ? <ErrorOutlineRounded sx={{ height: '16px', width: '16px' }} color={'error'} /> : null}
            </Flex>
            {icon ? (
              <Flex gap={2} alignItems={'center'}>
                {icon}
                <Text variant={'body3'} color={'secondary'} ellipsis data-sentry-unmask>
                  {capitalize(relation)}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          <ExpandButton expand={isOpen} />
        </Flex>
        <Collapse expanded={isOpen}>
          <Divider />
          <Grid columns={3} p={4}>
            {Object.entries(payload).map(([label, value]) => (
              <Flex key={label} gap={1}>
                <KeyValuePair name={convertEtranPropertyToLabels(label)} value={value} />{' '}
                {!value ? (
                  <Tooltip title={'Invalid value for inquiry'}>
                    <ErrorOutlineRounded sx={{ height: '16px', width: '16px' }} color={'error'} />
                  </Tooltip>
                ) : null}
              </Flex>
            ))}
          </Grid>
        </Collapse>
      </Flex>
    </Flex>
  );
};

const mapErrorsToProperty = <T extends EtranPayloadTypes>(validationErrors: ValidationError[], payload?: Partial<T>) => {
  if (!validationErrors || !payload) {
    return payload;
  }
  const result = { ...payload };
  for (const error of validationErrors) {
    if (error.property) {
      set(result, error.property, error.currentValue);
    }
  }
  return result;
};

export const EtranPayloadPreviewSegment = ({ payload }: { payload?: EtranPayloadResponse }) => {
  const payloadRoot = useMemo(() => payload?.payload?.App?.at(0), [payload]);
  const applicationPayload = useMemo(
    () => mapErrorsToProperty(payload?.validationErrors?.application?.validationErrors ?? [], payloadRoot?.LoanApplication?.at(0)),
    [payloadRoot, payload],
  );
  const businessesPayloads = useMemo(
    () =>
      payload?.validationErrors?.businesses?.map((businessError) => {
        const businessPayload = businessError.payload;
        return { ...mapErrorsToProperty(businessError.validationErrors, businessPayload), relation: businessError.relation };
      }) ?? [],
    [payload?.validationErrors],
  );
  const peoplePayloads = useMemo(
    () =>
      payload?.validationErrors?.people.map((personError) => {
        const personPayload = personError.payload;
        return { ...mapErrorsToProperty(personError.validationErrors, personPayload), relation: personError.relation };
      }) ?? [],
    [payload?.validationErrors],
  );
  if (!payload) {
    return <LoadingPage />;
  }
  return (
    <SegmentContainer bordered={false}>
      <Flex flexDirection={'column'} gap={4}>
        <EtranPayloadPreviewSegmentForPayload
          payload={applicationPayload}
          title={'Application'}
          icon={<ApplicationIcon />}
          relation={'Application'}
        />
        {businessesPayloads?.map(({ relation, ...businessPayload }) => (
          <EtranPayloadPreviewSegmentForPayload
            key={businessPayload?.TaxId}
            payload={businessPayload}
            title={`${businessPayload?.BusinessName ?? 'Unknown'}`}
            icon={<BusinessIcon />}
            relation={relation}
          />
        ))}
        {peoplePayloads?.map(({ relation, ...people }) => (
          <EtranPayloadPreviewSegmentForPayload
            key={people.TaxId}
            payload={people}
            title={`${people.FirstName} ${people.LastName}`}
            icon={<PersonIcon />}
            relation={relation}
          />
        ))}
      </Flex>
    </SegmentContainer>
  );
};
