import type { DocumentBoxDetails, GroupedDocumentBoxDetails, GroupingOptions } from './types';

export const getDocumentBoxesWithGroups = (
  documentBoxesDetails: DocumentBoxDetails[],
  groupBy: GroupingOptions,
): GroupedDocumentBoxDetails[] =>
  documentBoxesDetails.map<GroupedDocumentBoxDetails>((box) => {
    if (box.viewGroup === 'deleted') {
      return { ...box, group: box.topicName, subGroup: '' };
    }

    if (groupBy === 'entity') {
      return { ...box, group: box.entityName, subGroup: box.topicName };
    }
    if (groupBy === 'topic') {
      return { ...box, group: box.topicName, subGroup: box.entityName };
    }

    return { ...box, group: 'All Documents', subGroup: '' };
  });
