import type { ProductTerms } from '@lama/contracts';
import { Box, Slider, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useDebounce } from 'react-use';

interface TermLengthSettingsProps {
  onChange: (terms: Partial<ProductTerms>) => void;
  term?: Exclude<ProductTerms['ranges'], undefined>['termLength'];
}

const marks = [
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 60,
    label: '60 months',
  },
];

export const TermLengthSettings: FC<TermLengthSettingsProps> = ({ onChange, term }) => {
  const [termRange, setTermRange] = React.useState<{ min: number; max: number }>({ min: term?.minMonth ?? 12, max: term?.maxMonth ?? 60 });
  const { min, max } = termRange;

  const onTermRangeChange = useCallback((event: Event, termRangeUpdate: number[] | number) => {
    if (typeof termRangeUpdate === 'number' || termRangeUpdate.length !== 2) {
      return;
    }

    const [minUpdate, maxUpdate] = termRangeUpdate;

    setTermRange({ min: minUpdate!, max: maxUpdate! });
  }, []);

  const updateProductTerms = useCallback(() => {
    onChange({ ranges: { termLength: { minMonth: min, maxMonth: max } } });
  }, [onChange, min, max]);

  const getAriaValueText = useCallback((value: number) => `${value} months`, []);

  useDebounce(updateProductTerms, 1000, [termRange]);

  return (
    <Stack p={5} gap={4} sx={{ backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
      <Typography variant={'h6'}>{'Term length'}</Typography>
      <Box px={2}>
        <Slider
          marks={marks}
          value={[min, max]}
          step={1}
          min={12}
          max={60}
          onChange={onTermRangeChange}
          valueLabelDisplay={'auto'}
          getAriaValueText={getAriaValueText}
        />
      </Box>
    </Stack>
  );
};
