import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import { yearsDiffFromReferenceYearSelector } from '@lama/selectors';
import { shouldShowConditionedProperty } from '@lama/app-components';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementProperties } from '../RequirementProperties';
import { formatPropertiesValues } from '../../../../shared/utils/formatPropertiesValues';
import { RequirementPrintContainer } from '../RequirementPrintContainer';

export const RequirementPrintView: FC<{ requirement: EvaluatedOpportunityRequirement }> = ({ requirement }) => {
  const { application, opportunity } = useContext(ApplicationContext);

  const requirementEntity = useMemo(() => {
    const entities = getApplicationEntityByType(application, requirement.entityType, requirement.entityGroups);
    const entity = entities.find(({ id }) => id === requirement.entityId);

    return entity;
  }, [application, requirement.entityGroups, requirement.entityId, requirement.entityType]);

  const currentRequirementProperties = useMemo(() => {
    const yearsBack = yearsDiffFromReferenceYearSelector(opportunity.referenceYear);
    const propertiesWithDecidedSource = requirement.properties
      .map((p) => getSourcedProperty(p, requirementEntity, application, yearsBack))
      .filter((p) => shouldShowConditionedProperty(p, requirementEntity ?? {}, {}, application, opportunity));
    return formatPropertiesValues(propertiesWithDecidedSource);
  }, [application, opportunity, requirement.properties, requirementEntity]);

  return (
    <RequirementPrintContainer title={requirement.name}>
      <RequirementProperties properties={currentRequirementProperties ?? []} />
    </RequirementPrintContainer>
  );
};
