import React, { useMemo, useCallback } from 'react';
import type { ApplicationStatus, DecisionReason, ProductApiModel } from '@lama/contracts';
import { TextField, MenuItem, Checkbox, Autocomplete } from '@mui/material';
import { useField } from 'formik';
import type { FC } from 'react';
import { Text } from '@lama/design-system';
import { defaultDecisionReasons, filterReasonsForStatus } from '../../../../shared/components/DecisionDialog/defaultDecisionReasons';

interface ReasonsAutoCompleteProps {
  product: ProductApiModel;
  reasonsFieldName: string;
  statusFieldName: string;
}

export const FormikDecisionReasonsMultiPicker: FC<ReasonsAutoCompleteProps> = ({ product, reasonsFieldName, statusFieldName }) => {
  const [, { error, value: reasonsValue, touched }, { setValue: setFieldValue }] = useField(reasonsFieldName);
  const [, { value: statusValue }] = useField<ApplicationStatus>(statusFieldName);

  const decisionReasons = useMemo(
    () => filterReasonsForStatus(product.decisionReasons ?? defaultDecisionReasons, [statusValue]),
    [statusValue, product.decisionReasons],
  );

  const renderReasonInput = useCallback(
    (props: any) => (
      <TextField
        id={reasonsFieldName}
        name={reasonsFieldName}
        label={'Reason(s)'}
        helperText={touched ? error : ''}
        error={!!touched && !!error}
        required
        {...props}
      />
    ),
    [error, reasonsFieldName, touched],
  );
  const renderReasonOption = useCallback(
    (props: any, reason: DecisionReason, { selected }: { selected: boolean }) => (
      <MenuItem name={reason.id} direction={'row'} gap={1} {...props}>
        <Checkbox checked={selected} />
        <Text variant={'body1'} ellipsis>
          {reason.text}
        </Text>
      </MenuItem>
    ),
    [],
  );

  const internalOnChange = useCallback(
    async (e: any, internalValue: any) => {
      await setFieldValue(internalValue);
    },
    [setFieldValue],
  );

  const getReasonOptionLabel = useCallback((option: DecisionReason) => option.text, []);

  const checkDisable = useCallback(() => (reasonsValue?.length ?? 0) >= 4, [reasonsValue]);

  if (!decisionReasons.length) {
    return null;
  }

  return (
    <Autocomplete
      value={reasonsValue}
      getOptionDisabled={checkDisable}
      onChange={internalOnChange}
      renderInput={renderReasonInput}
      getOptionLabel={getReasonOptionLabel}
      options={decisionReasons}
      renderOption={renderReasonOption}
      limitTags={1}
      disableCloseOnSelect
      multiple
    />
  );
};
