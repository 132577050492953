import type { DocumentPage } from '@lama/document-service-client';
import { sortBy } from 'lodash-es';
import { useContext } from 'react';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

export const useGroupedDocumentPagesByFormInstance = () => {
  const { currentDocument } = useContext(SpreadingDocumentContext);

  return sortBy(currentDocument.documentPages ?? [], ({ page }) => page).reduce<DocumentPage[][]>(
    (documentPagesByForm, currentDocumentPage) => {
      const lastFormTypeGroup = documentPagesByForm.findLast((group) => group[0]?.formType === currentDocumentPage.formType);

      const lastGroupContainsCurrectFormPage = lastFormTypeGroup?.map(({ formPage }) => formPage).includes(currentDocumentPage.formPage);

      if (lastFormTypeGroup && !lastGroupContainsCurrectFormPage) {
        lastFormTypeGroup.push(currentDocumentPage);
      } else {
        documentPagesByForm.push([currentDocumentPage]);
      }
      return documentPagesByForm;
    },
    [],
  );
};
