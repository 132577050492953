import React from 'react';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { DocumentBox } from '../DocumentBox/DocumentBox';

interface DeletedDocumentsListProps {
  deletedDocuments: DocumentWithIssues[];
}

export const DeletedDocumentsList: FC<DeletedDocumentsListProps> = ({ deletedDocuments }) => {
  if (!deletedDocuments.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text variant={'body1'}>{'Deleted Documents'}</Text>
      <Flex flexDirection={'column'} gap={4}>
        {deletedDocuments.map((document) => (
          <DocumentBox document={document} key={document.id} />
        ))}
      </Flex>
    </Flex>
  );
};
