import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { Alert } from '@lama/alerts-service-client';
import { alertsServiceClient } from '../../../clients/alertsServiceClient';

export const useGetOpportunityAlertsQuery = (opportunityId: string, options?: Partial<UseQueryOptions<Alert[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const { getAlertsFromAlertsService } = useFlags();

  const getOpportunityAlerts = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const alerts = await alertsServiceClient.getAlerts(opportunityId, token);

    return alerts ?? [];
  }, [getAccessTokenSilently, opportunityId]);

  const query = useQuery<Alert[]>({
    queryKey: ['opportunityAlerts', opportunityId, getAlertsFromAlertsService],
    queryFn: getOpportunityAlerts,
    ...options,
  });

  return query;
};
