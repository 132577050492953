import type { ProductTerms } from '@lama/contracts';
import { Box, Slider, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useDebounce } from 'react-use';

interface AprSettingsProps {
  onChange: (terms: Partial<ProductTerms>) => void;
  apr?: Exclude<ProductTerms['ranges'], undefined>['apr'];
}

const minApr = 12;
const maxApr = 25;

const marks = [
  {
    value: minApr,
    label: `${minApr}%`,
  },
  {
    value: maxApr,
    label: `${maxApr}%`,
  },
];

export const AprSettings: FC<AprSettingsProps> = ({ onChange, apr }) => {
  const [aprRange, setAprRange] = React.useState<{ min: number; max: number }>({ min: apr?.min ?? minApr, max: apr?.max ?? maxApr });
  const { min, max } = aprRange;

  const onTermRangeChange = useCallback((event: Event, termRangeUpdate: number[] | number) => {
    if (typeof termRangeUpdate === 'number' || termRangeUpdate.length !== 2) {
      return;
    }

    const [minUpdate, maxUpdate] = termRangeUpdate;

    setAprRange({ min: minUpdate!, max: maxUpdate! });
  }, []);

  const updateProductTerms = useCallback(() => {
    onChange({ ranges: { apr: { min, max } } });
  }, [onChange, min, max]);

  const getAriaValueText = useCallback((value: number) => `${value} months`, []);

  useDebounce(updateProductTerms, 1000, [aprRange]);

  return (
    <Stack p={5} gap={4} sx={{ backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
      <Typography variant={'h6'}>{'APR'}</Typography>
      <Box px={2}>
        <Slider
          marks={marks}
          value={[min, max]}
          step={1}
          min={minApr}
          max={maxApr}
          onChange={onTermRangeChange}
          valueLabelDisplay={'auto'}
          getAriaValueText={getAriaValueText}
        />
      </Box>
    </Stack>
  );
};
