export interface CateogryDesign {
  name: string;
  icon: string;
  iconColor: string;
  iconBackgroundColor: string;
}

export const categoriesDesign: CateogryDesign[] = [
  {
    name: 'Bank Fees',
    icon: 'account_balance',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFF8E1',
  },
  {
    name: 'Income',
    icon: 'payments',
    iconColor: '#00897B',
    iconBackgroundColor: '#E0F2F1',
  },
  {
    name: 'Rent & Utilities',
    icon: 'vpn_key',
    iconColor: '#8E24AA',
    iconBackgroundColor: '#EDE7F6',
  },
  {
    name: 'Food & Drink',
    icon: 'fastfood',
    iconColor: '#F5A623',
    iconBackgroundColor: '#FFF8E1',
  },
  {
    name: 'Transfer In',
    icon: 'vertical_align_bottom',
    iconColor: '#44CFA6',
    iconBackgroundColor: '#E2FBF3',
  },
  {
    name: 'Transfer Out',
    icon: 'vertical_align_top',
    iconColor: '#EF5350',
    iconBackgroundColor: '#FEEBEE',
  },
  {
    name: 'General',
    icon: 'widgets',
    iconColor: '#714EF5',
    iconBackgroundColor: '#F3F0FF',
  },
  {
    name: 'Medical',
    icon: 'prescriptions',
    iconColor: '#D81B60',
    iconBackgroundColor: '#FCE4EC',
  },
  {
    name: 'Transportation',
    icon: 'commute',
    iconColor: '#3949AB',
    iconBackgroundColor: '#E8EAF6',
  },
  {
    name: 'Services',
    icon: 'sms',
    iconColor: '#FDD835',
    iconBackgroundColor: '#FFFDE7',
  },
  {
    name: 'Travel',
    icon: 'flight_takeoff',
    iconColor: '#039BE5',
    iconBackgroundColor: '#E1F5FE',
  },
];
