/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useRef, useCallback, useContext, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { Flex, Text } from '@lama/design-system';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { personFullName, applicationGuarantorsSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../ApplicationContext';
import { CreditReportExport } from '../../ApplicationPrint/CreditReportExport/CreditReportExport';
import { SubMenuHeader } from './SubMenuHeader';

interface ExportMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

const CreditReportExportMenuItem: FC<{ person: RelatedPersonApiModel; onClose: () => void }> = ({ person, onClose }) => {
  const creditReportRef = useRef(null);

  const exportCreditReport = useReactToPrint({
    content: () => creditReportRef.current,
    onAfterPrint: onClose,
    documentTitle: `${personFullName(person)} Credit Report`,
  });

  const onClick = useCallback(
    (_: React.MouseEvent<HTMLElement>) => {
      exportCreditReport();
    },
    [exportCreditReport],
  );

  return (
    <>
      <MenuItem
        key={person.id}
        value={person.id}
        onClick={onClick}
        sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}
      >
        <Flex flex={1} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
          <Text variant={'body1'} ellipsis>
            {personFullName(person)}
          </Text>
        </Flex>
      </MenuItem>
      <CreditReportExport key={'creditMemoExport'} ref={creditReportRef} person={person} />
    </>
  );
};

export const ExportCreditReportMenu: FC<ExportMenuProps> = ({ menuProps, open, menuRef, onClose, onBack }) => {
  const { application } = useContext(ApplicationContext);

  const guarantors = useMemo(() => applicationGuarantorsSelector(application), [application]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={onClose}>
      <Flex flexDirection={'column'} gap={2}>
        <SubMenuHeader onClick={onBack} text={'Export Credit Report'} />
        <Flex flexDirection={'column'} px={2} maxHeight={'300px'} overflow={'auto'}>
          {guarantors?.map((g) => (
            <CreditReportExportMenuItem key={g.id} person={g} onClose={onClose} />
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
