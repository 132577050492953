import type { FC } from 'react';
import React from 'react';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { personFullName } from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

interface InquiryDetailsProps {
  guarantor: RelatedPersonApiModel;
}

export const InquiryDetails: FC<InquiryDetailsProps> = ({ guarantor }) => (
  <SegmentContainer title={'Inquiry Details'}>
    <Grid columns={3}>
      <KeyValuePair name={'Full Name'} value={personFullName(guarantor)} />
      <KeyValuePair name={'Address'} value={guarantor.address?.address1} />
      <KeyValuePair name={'State'} value={guarantor.address?.state} />
      <KeyValuePair name={'City'} value={guarantor.address?.city} />
      <KeyValuePair name={'Zip Code'} value={guarantor.address?.zip} />
      <KeyValuePair name={'Date of Birth'} value={guarantor.dateOfBirth} type={'date'} />
      <KeyValuePair name={'Report Date'} value={guarantor.thirdPartyData?.creditReport?.ficoScoreDate} type={'date'} />
    </Grid>
  </SegmentContainer>
);
