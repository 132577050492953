import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { CreateEnvelopeResponse, UpdateEnvelopeRequestBody } from '@lama/docusign-client';
import { displayToast } from '@lama/app-components';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useUpdateEnvelopeMutation = (options?: UseMutationOptions<CreateEnvelopeResponse, unknown, UpdateEnvelopeRequestBody>) => {
  const { getAccessTokenSilently } = useAuth0();

  const updateEnvelope = useCallback(
    async ({ envelopeId, returnUrl, opportunityId }: UpdateEnvelopeRequestBody) => {
      const token = await getAccessTokenSilently();

      return docusignServiceClient.updateEnvelope({ envelopeId, returnUrl, opportunityId }, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: updateEnvelope,
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
