import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { VoidEnvelopeRequestBody, VoidEnvelopeResponse } from '@lama/docusign-client';
import { displayToast } from '@lama/app-components';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useVoidEnvelopeMutation = (options?: UseMutationOptions<VoidEnvelopeResponse, unknown, VoidEnvelopeRequestBody>) => {
  const { getAccessTokenSilently } = useAuth0();

  const voidEnvelope = useCallback(
    async ({ envelopeId, voidReason, opportunityId }: VoidEnvelopeRequestBody) => {
      const token = await getAccessTokenSilently();

      return docusignServiceClient.voidEnvelope({ envelopeId, voidReason, opportunityId }, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: voidEnvelope,
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
