import styled from 'styled-components';

export const StyledDatePickerWrapper = styled.div`
  .react-datepicker {
    border: none;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: black;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: ${({ theme }) => theme.colors.primary.main};
    color: white !important;
  }
`;
