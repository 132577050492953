import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GenericPropertiesProvider, BaseRelationsList, SearchExistingCustomersProvider } from '@lama/app-components';
import type { Entity } from '@lama/common-types';
import type { Relation } from '@lama/contracts';
import type { PersonApiModel } from '@lama/clients';
import type { SelectedEntity } from '@lama/fiserv-service-client';
import type { BusinessApiModel } from '@lama/business-service-client';
import type { RequirementScreenProps } from '../types';
import { ApplicationContext } from '../../../../ApplicationContext';
import { customComponents } from '../customComponentsMap';
import { useUpdateBusiness } from '../../../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../../../../Pipeline/hooks/useCreateBusinessMutation';
import { useAddApplicationRelation } from '../../../../../../shared/hooks/react-query/application/useAddApplicationRelation';
import { useRemoveApplicationRelation } from '../../../../../../shared/hooks/react-query/application/useRemoveApplicationRelation';
import { useCreatePerson } from '../../../../../../shared/hooks/react-query/people/useCreatePerson';
import { useUpdatePerson } from '../../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { useInviteToApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useInviteToApplicationMutation';
import { useUpdateApplicationMutation } from '../../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useSearchExistingCustomers } from '../../../../../../shared/hooks/react-query/searchExistingCustomers/useSearchExistingCustomers';
import { useConvertExistingCustomerToEntity } from '../../../../../../shared/hooks/react-query/searchExistingCustomers/useConvertExistingCustomerToEntity';
import { useGetPartnerQuery } from '../../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';

interface RelationsListProps extends RequirementScreenProps {
  relation: Relation;
  allowedEntityTypes: Entity[];
  modifyPersonPayload?: (values: Partial<PersonApiModel>) => any;
  modifyBusinessPayload?: (values: Partial<BusinessApiModel>) => any;
  title?: string;
  showMarkNoEntitiesCheckbox?: boolean;
  searchExistingCustomersAllowed?: boolean;
}

export const RelationsList: FC<RelationsListProps> = ({
  requirement: { properties, name },
  relation,
  showMarkNoEntitiesCheckbox,
  allowedEntityTypes,
  modifyBusinessPayload,
  modifyPersonPayload,
  searchExistingCustomersAllowed,
}) => {
  const { application, product, opportunity } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);
  const { searchExistingCustomersButtonVisible } = useMemo(() => partner?.featureConfigurations ?? {}, [partner]);
  const [searchTerm, setSearchTerm] = useState('');
  const onSearchTermChange = useCallback((newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  }, []);
  const { mutateAsync: convertExisintCustomerToEntity } = useConvertExistingCustomerToEntity(opportunity.partnerId);

  const { data } = useSearchExistingCustomers(opportunity.partnerId, searchTerm, searchTerm?.length > 0);
  const { mutateAsync: addApplicationRelation, isPending: addingApplicationRelation } = useAddApplicationRelation(
    application.id,
    opportunity.id,
  );
  const { mutateAsync: removeApplicationRelatedEntity, isPending: removingApplicationRelatedEntity } = useRemoveApplicationRelation(
    application.id,
    opportunity.id,
  );
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson(opportunity.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(opportunity.id);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(application.id, opportunity.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(opportunity.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: inviteToApplication } = useInviteToApplicationMutation({
    applicationId: application.id,
    opportunityId: opportunity.id,
  });

  const onSelectedEntitySelectorChange = useCallback(
    async (newSelectedEntityIdentfier: string): Promise<SelectedEntity | undefined> => {
      const createdEntity = await convertExisintCustomerToEntity(newSelectedEntityIdentfier);
      if (!createdEntity) {
        return;
      }
      if (createdEntity.business) {
        const businessToCreate = {
          ...createdEntity.business,
          people: [],
          applicationId: application.id,
          partnerId: application.originatingPartner,
        };
        await createBusiness({ business: businessToCreate, applicationId: application.id });
        await addApplicationRelation({
          relation,
          entityId: businessToCreate.id,
          entityType: 'business',
          applicationId: application.id,
        });
      }
      if (createdEntity.person) {
        const personToCreate = {
          ...createdEntity.person,
          businesses: [],
          applicationId: application.id,
          partnerId: application.originatingPartner,
        };
        await createPerson({ person: personToCreate });
        await addApplicationRelation({
          relation,
          entityId: personToCreate.id,
          entityType: 'person',
          applicationId: application.id,
        });
      }
    },
    [
      addApplicationRelation,
      application.id,
      application.originatingPartner,
      convertExisintCustomerToEntity,
      createBusiness,
      createPerson,
      relation,
    ],
  );
  const loading = useMemo(
    () =>
      creatingBusiness ||
      updatingBusiness ||
      addingApplicationRelation ||
      removingApplicationRelatedEntity ||
      creatingPerson ||
      updatingPerson ||
      updatingApplication,
    [
      creatingBusiness,
      updatingBusiness,
      addingApplicationRelation,
      removingApplicationRelatedEntity,
      creatingPerson,
      updatingPerson,
      updatingApplication,
    ],
  );

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <SearchExistingCustomersProvider
        onSearchResultSelect={onSelectedEntitySelectorChange}
        onSearchTermChange={onSearchTermChange}
        searchResults={data}
        searchButtonVisible={!!searchExistingCustomersAllowed && !!searchExistingCustomersButtonVisible}
      >
        <BaseRelationsList
          relation={relation}
          requirementName={name}
          allowedEntityTypes={allowedEntityTypes}
          createPerson={createPerson}
          updatePerson={updatePerson}
          createBusiness={createBusiness}
          updateBusiness={updateBusiness}
          inviteToApplication={inviteToApplication}
          addApplicationRelation={addApplicationRelation}
          removeApplicationRelatedEntity={removeApplicationRelatedEntity}
          updateApplication={updateApplication}
          application={application}
          requirementProperties={properties}
          loading={loading}
          modifyBusinessPayload={modifyBusinessPayload}
          modifyPersonPayload={modifyPersonPayload}
          showMarkNoEntitiesCheckbox={showMarkNoEntitiesCheckbox}
          partnerFeatureConfigurations={partner?.featureConfigurations ?? {}}
        />
      </SearchExistingCustomersProvider>
    </GenericPropertiesProvider>
  );
};
