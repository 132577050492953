import type { DealApiModel } from '@lama/contracts';
import type { FC } from 'react';
import React from 'react';
import { DealCategoryCarousel } from './DealCategoryCarousel';
import { HighMatchCTA } from './HighMatchCTA';

interface HighMatchCategoryProps {
  highMatchDeals: DealApiModel[];
}

export const HighMatchCategory: FC<HighMatchCategoryProps> = ({ highMatchDeals }) => {
  if (highMatchDeals.length === 0) {
    return <HighMatchCTA />;
  }

  return <DealCategoryCarousel key={'highMatch'} category={'highMatch'} categoryTitle={'Deals for you'} deals={highMatchDeals} />;
};
