import React from 'react';
import type { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Lock from '../../../shared/icons/lock.svg';

export const LockedInsights: FC = () => (
  <Stack direction={'row'} border={'1px solid #E0E0E0'} p={3} justifyContent={'space-between'} alignItems={'center'}>
    <Stack direction={'row'} spacing={1}>
      <Lock />
      <Typography variant={'body1'} color={'text.disabled'}>
        {'Insights'}
      </Typography>
    </Stack>
    <Button variant={'outlined'} href={'mailto:contact@lama.ai?subject=Request to unlock business insights'}>
      {'Unlock insights'}
    </Button>
  </Stack>
);
