import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Inbox, type Notification } from '@novu/react';
import { type Theme } from '@lama/design-system';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { novuApplicationIdentifier } from '../../../framework/environment';
import { decodeFromBase64 } from '../../../shared/utils/base64Utils';
import { localization } from './notificationLocalization';

interface NotificationCenterProps {
  prop?: any;
}

const getNovuAppearance = (theme: Theme) => ({
  colorPrimary: theme.colors.primary.main,
});

export const NotificationCenter: FC<NotificationCenterProps> = () => {
  const theme = useTheme();
  const { userId } = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const appearance = useMemo(
    () => ({
      variables: getNovuAppearance(theme),
    }),
    [theme],
  );

  const onClickNotification = useCallback(
    async (notification: Notification) => {
      if (!notification.data) {
        return;
      }

      if (notification.data.opportunityId) {
        await queryClient.invalidateQueries({ queryKey: ['opportunity', notification.data.opportunityId] });
      }

      const url = decodeFromBase64((notification.data.url as string) ?? '');

      if (url) {
        navigate(url.startsWith('/') ? url : `/${url}`);
      }
    },
    [navigate, queryClient],
  );

  if (!userId) {
    return null;
  }

  return (
    <Inbox
      applicationIdentifier={novuApplicationIdentifier}
      subscriberId={userId}
      appearance={appearance}
      onNotificationClick={onClickNotification}
      localization={localization}
      preferencesFilter={{ tags: ['lama'] }}
    />
  );
};
