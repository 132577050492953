/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useMemo, useCallback } from 'react';
import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import { Text, Flex, Button } from '@lama/design-system';
import { SearchIcon } from '../../shared/components/Icons/SearchIcon';
import { CloseIcon } from '../../shared/components/Icons/CloseIcon';

interface PipelineFilterProps {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onClearFilters: () => void;
  clearGlobalFilter: () => void;
  agFilterCount: number;
}

export const PipelineFilters: FC<PipelineFilterProps> = ({
  globalFilter,
  setGlobalFilter,
  onClearFilters,
  clearGlobalFilter,
  agFilterCount,
}) => {
  const totalFilterCount = useMemo(() => agFilterCount + (globalFilter ? 1 : 0), [agFilterCount, globalFilter]);

  const onSearchTermChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setGlobalFilter(e.target.value);
    },
    [setGlobalFilter],
  );

  const onClickSearchClear = useCallback(() => {
    clearGlobalFilter();
  }, [clearGlobalFilter]);

  const onClearFiltersInner = useCallback(() => {
    clearGlobalFilter();
    onClearFilters();
  }, [clearGlobalFilter, onClearFilters]);

  return (
    <Flex gap={4} flexGrow={0.4}>
      <TextField
        variant={'outlined'}
        label={'Search'}
        size={'small'}
        sx={{ borderRadius: '4px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: globalFilter && (
            <InputAdornment position={'end'}>
              <IconButton size={'small'} onClick={onClickSearchClear}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={globalFilter}
        onChange={onSearchTermChange}
        fullWidth
      />
      <Flex pl={2} gap={2}>
        <Divider orientation={'vertical'} flexItem />
        <Button variant={'tertiary'} color={'primary'} size={'s'} onClick={onClearFiltersInner} disabled={!totalFilterCount}>
          <Text variant={'body1'} weight={500} px={3}>
            {`Clear Filters (${totalFilterCount})`}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};
