import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';

interface ChartPaginationProps {
  pageCount: number;
  page: number;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const ChartPagination: FC<ChartPaginationProps> = ({ pageCount, onPageChange, page }) => {
  const onLeftClicked = useCallback(
    (e: React.ChangeEvent<unknown>) => {
      onPageChange(e, page + 1);
    },
    [onPageChange, page],
  );
  const onRightClicked = useCallback(
    (e: React.ChangeEvent<unknown>) => {
      onPageChange(e, page - 1);
    },
    [onPageChange, page],
  );

  return (
    <Stack direction={'row'} spacing={4}>
      <IconButton onClick={onLeftClicked} disabled={page === pageCount}>
        <ChevronLeftRounded />
      </IconButton>
      <IconButton onClick={onRightClicked} disabled={page === 1}>
        <ChevronRightRounded />
      </IconButton>
    </Stack>
  );
};
