import type { FC } from 'react';
import React from 'react';
import ReactSwitch from 'react-switch';
import type { ReactSwitchProps } from 'react-switch';
import { useTheme } from 'styled-components';
import { SwitchLabel } from './SwitchLabel.js';
import type { SwitchSize } from './Switch.styles.js';
import { switchHeight, switchWidth, switchHandleDiameter, switchTextSize } from './Switch.styles.js';

export interface SwitchProps extends ReactSwitchProps {
  size?: SwitchSize;
  uncheckedText?: string;
  checkedText?: string;
  onChange: (active: boolean) => void;
  checked: boolean;
}

export const Switch: FC<SwitchProps> = ({
  size = 'm',
  uncheckedText,
  checkedText,
  uncheckedIcon = false,
  checkedIcon = false,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ReactSwitch
      {...props}
      role={'switch'}
      uncheckedIcon={uncheckedIcon}
      checkedIcon={checkedIcon}
      height={switchHeight[size]}
      width={switchWidth[size]}
      handleDiameter={switchHandleDiameter[size]}
      onColor={theme.colors.primary.main}
      {...(uncheckedText ? { uncheckedIcon: <SwitchLabel text={uncheckedText} size={switchTextSize[size]} paddingRight={0.5} /> } : false)}
      {...(checkedText ? { checkedIcon: <SwitchLabel text={checkedText} size={switchTextSize[size]} paddingLeft={0.5} /> } : false)}
    />
  );
};
