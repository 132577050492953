import { useAuth0 } from '@auth0/auth0-react';
import type { UpdateCondition } from '@lama/clients';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { productServiceClient } from '../../../../../shared/clients/productService';

export const useUpdateConditionMutation = (productId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ conditionId, conditionPayload }: { conditionId: string; conditionPayload: UpdateCondition }) => {
      const token = await getAccessTokenSilently();

      if (!productId) {
        return;
      }

      return productServiceClient.updateCondition(productId, conditionId, conditionPayload, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['product', productId] });
    },
    onError: async () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
