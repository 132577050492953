import React, { useContext, type FC, useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import writeXlsxFile from 'write-excel-file';
import type { RegBExportRow } from '@lama/reports-service-client';
import { regBReportExcelSchema } from '@lama/reports-service-client';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { ApplicationsByStatusChart } from '../Charts/ApplicationsByStatusBarChart';
import { useApplicationsByStatusChartData } from '../Charts/hooks/useApplicationsByStatusChartData';

const REPORT_NAME = 'RegB';

export const RegBReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { mutateAsync: downloadReport, isPending: downloadingReport } = useDownloadReportMutation('RegBReport');
  const { data: chartData, isPending: loadingChartData } = useApplicationsByStatusChartData({ endDate, startDate, productId, partnerId });

  const onClickDownload = useCallback(async () => {
    if (!startDate || !endDate || !partnerId || !productId) {
      displayToast(`${REPORT_NAME} report unavailable, please contact support`, 'info');

      console.error('some params are missing for downloading', REPORT_NAME, { partnerId, productId, startDate, endDate });

      return;
    }

    const filename = `${REPORT_NAME} Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.xlsx`;

    const data: RegBExportRow[] = await downloadReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    await writeXlsxFile(data, {
      schema: regBReportExcelSchema,
      fileName: filename,
    });
  }, [downloadReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || downloadingReport}
      actionText={'Download Report'}
      onClickAction={onClickDownload}
      actionLoading={downloadingReport}
      loading={loadingChartData}
      title={`${REPORT_NAME} Report`}
    >
      <ApplicationsByStatusChart chartData={chartData} />
    </ReportCard>
  );
};
