import type { DocumentLineItem } from '@lama/document-service-client';
import { groupBy } from 'lodash-es';

const sortDocumentLineItemsByPosition = (a: DocumentLineItem, b: DocumentLineItem): number => {
  if (a.page !== b.page) {
    return (a.page ?? 0) - (b.page ?? 0);
  }

  if (a.lineIndex && b.lineIndex) {
    return a.lineIndex - b.lineIndex;
  }

  const aMinY = a.textBoundingBox?.top ?? Math.min(...a.values.map((value) => value.boundingBox?.top ?? 0));
  const bMinY = b.textBoundingBox?.top ?? Math.min(...b.values.map((value) => value.boundingBox?.top ?? 0));

  return aMinY - bMinY;
};

const groupByParentAndSort = (items: DocumentLineItem[]): Record<string, DocumentLineItem[]> => {
  const ids = new Set(items.map((i) => i.id));
  const groupedByParent = groupBy(items, (i) => (i.parentId && ids.has(i.parentId) ? i.parentId : 'ROOT'));

  return Object.fromEntries(
    Object.entries(groupedByParent).map(([parent, children]) => [parent, children.sort(sortDocumentLineItemsByPosition)]),
  );
};

const flattenGroups = (groupedByParent: Record<string, DocumentLineItem[]>, parentId?: string): DocumentLineItem[] => {
  const result: DocumentLineItem[] = [];

  groupedByParent[parentId ?? 'ROOT']?.forEach((item) => {
    result.push(item, ...flattenGroups(groupedByParent, item.id));
  });

  return result;
};

export const sortDocumentLineItems = (documentLineItems: DocumentLineItem[]): DocumentLineItem[] => {
  const sortedItems = documentLineItems.sort(sortDocumentLineItemsByPosition);

  const grouped = groupByParentAndSort(sortedItems);
  return flattenGroups(grouped);
};
