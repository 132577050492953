import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import type { GenerateCreditMemoCommentBody } from '@lama/clients';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

interface GenerateNoteParams {
  noteType: string;
  body: GenerateCreditMemoCommentBody;
}

export const useGenerateNoteMutation = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ noteType, body }: GenerateNoteParams) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.generateCreditMemoComment({ opportunityId, commentType: noteType }, body, token);
    },
    onError: () => {
      displayToast('There was a problem generating the comment. Please try again.', 'error');
    },
  });
};
