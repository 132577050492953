import type { EvaluatedOpportunityRequirement, OpportunityClosingTask, ProductApiModel, UnderwritingNote } from '@lama/contracts';
import type { UnderwritingNoteRelatedSectionType } from '@lama/clients';
import type { Entity } from '@lama/common-types';

export interface NoteRelation {
  sectionName: string;
  groupName?: string;
  tabName?: string;
  relatedItemId?: string;
  relatedItemType?: UnderwritingNoteRelatedSectionType;
  relatedEntityType?: Entity;
}

export const getNoteRelatedItems = (
  note: UnderwritingNote,
  opportunityRequirements: EvaluatedOpportunityRequirement[],
  opportunityClosingTasks: OpportunityClosingTask[],
  product: ProductApiModel,
): NoteRelation => {
  if (note.title) {
    return { sectionName: note.title };
  }

  const creditMemoSection = product.creditMemoConfiguration?.sections.find((section) => section.id === note.relatedSection);
  const creditMemoTitle = creditMemoSection?.prettyName;
  if (creditMemoTitle) {
    return {
      sectionName: creditMemoTitle,
      tabName: 'Credit Memo',
      relatedItemId: note.relatedSection,
      relatedItemType: 'creditMemoSection',
      relatedEntityType: creditMemoSection?.entityType,
    };
  }

  const underwritingSection = product.underwritingConfiguration
    ?.flatMap(({ sections, groupName }) => sections.map((section) => ({ ...section, groupName })))
    .find((section) => section.id === note.relatedUnderwritingSectionId);
  const underwritingTitle = underwritingSection?.prettyName;

  if (underwritingTitle) {
    return {
      sectionName: underwritingTitle,
      groupName: underwritingSection.groupName,
      tabName: 'Underwriting',
      relatedItemId: note.relatedUnderwritingSectionId,
      relatedItemType: 'underwriting',
      relatedEntityType: underwritingSection.entityType,
    };
  }

  const opportunityRequirement = opportunityRequirements?.find((requirement) => requirement.id === note.relatedRequirementId);
  const opportunityRequirementsTitle = opportunityRequirement?.name;

  if (opportunityRequirementsTitle) {
    return {
      sectionName: opportunityRequirementsTitle,
      tabName: 'Application',
      relatedItemId: note.relatedRequirementId,
      relatedItemType: 'requirement',
      relatedEntityType: opportunityRequirement.entityType,
    };
  }

  const closingTask = opportunityClosingTasks?.find((task) => task.id === note.relatedTaskId);
  const closingTaskTitle = closingTask?.name;

  if (closingTaskTitle) {
    return {
      sectionName: closingTaskTitle,
      tabName: 'Closing',
      relatedItemId: note.relatedTaskId,
      relatedItemType: 'closingTask',
      relatedEntityType: closingTask.entityType,
    };
  }

  return { sectionName: '' };
};

export const noteRelationToContext = (noteRelation: NoteRelation, entitiesNamesById: Record<string, string | null>) => {
  const { sectionName, groupName, tabName, relatedItemId } = noteRelation;

  if (!tabName) {
    return sectionName || 'General Comment';
  }

  const entityId = relatedItemId && relatedItemId.includes('_') ? relatedItemId.split('_')[1] : null;

  const entityName = entityId ? entitiesNamesById[entityId] : '';

  return `${tabName ? `${tabName} > ` : ''}${groupName ? `${groupName} > ` : ''}${entityName ? `${entityName} > ` : ''}${sectionName}`;
};
