import React from 'react';
import type { FC } from 'react';
import { Stack } from '@mui/material';
import type { DealApiModel } from '@lama/contracts';
import { BackLink } from '../../../shared/components/BackLink';
import { FullDealHeader } from './FullDealHeader';

export const DealHeaderWrapper: FC<{ deal: DealApiModel; category?: string }> = ({ deal, category }) => (
  <Stack px={10} py={4} spacing={4} bgcolor={'#FAFAFA'} borderRadius={0}>
    <BackLink title={'Back to Deals'} to={`/deals${category ? `?category=${category}` : ''}`} />
    <FullDealHeader deal={deal} />
  </Stack>
);
