import React, { useMemo } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { SpreadRow } from '@lama/spreads-generator-client';
import EditedIcon from '../../assets/edited.svg';

export const SpreadValueCell: FC<ICellRendererParams<SpreadRow>> = ({ value, data, column, formatValue }) => {
  const currentValueObject = useMemo(() => data?.valueByPeriod[column?.getId() ?? ''], [data, column]);
  return (
    <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
      {currentValueObject?.financialAttribute?.selectedSource?.financialDataSource?.type === 'Adjustment' && <EditedIcon />}
      {formatValue?.(value)}
    </Flex>
  );
};
