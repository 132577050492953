import React from 'react';

export const LowPriorityIcon: React.FC<{ width?: string; height?: string; color?: string }> = ({
  height = '20px',
  width = '20px',
  color,
}) => (
  <svg width={width} height={height} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M17.9863 1C17.9863 0.447715 18.434 0 18.9863 0H23.0005C23.5528 0 24.0005 0.447715 24.0005 1V23C24.0005 23.5522 23.5528 24 23.0005 24H18.9863C18.434 24 17.9863 23.5522 17.9863 23V1Z'
      }
      fill={color ?? '#EEEEEE'}
      opacity={color ? '0.2' : '1'}
    />
    <path
      d={
        'M8.99023 7C8.99023 6.44772 9.43795 6 9.99023 6H14.0044C14.5567 6 15.0044 6.44772 15.0044 7V23C15.0044 23.5523 14.5567 24 14.0044 24H9.99023C9.43795 24 8.99023 23.5523 8.99023 23V7Z'
      }
      opacity={color ? '0.2' : '1'}
      fill={color ?? '#EEEEEE'}
    />
    <path
      d={
        'M0 13C0 12.4477 0.447715 12 1 12H5.01419C5.56648 12 6.01419 12.4477 6.01419 13V23C6.01419 23.5523 5.56648 24 5.01419 24H1C0.447716 24 0 23.5523 0 23V13Z'
      }
      fill={color ?? '#714EF5'}
    />
  </svg>
);
