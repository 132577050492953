import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { OpportunityUpdateApiModel } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useUpdateOpportunityMutation = (
  opportunityId?: string,
  options: UseMutationOptions<void, unknown, OpportunityUpdateApiModel> = {},
) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (updateOpportunityPayload: OpportunityUpdateApiModel) => {
      const token = await getAccessTokenSilently();

      if (!opportunityId) {
        return;
      }

      await applicationServiceClient.updateOpportunity(opportunityId, updateOpportunityPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityAlerts', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['pipeline'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['etran', opportunityId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
      ]);
    },
    ...options,
  });
};
