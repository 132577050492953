export const domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;
export const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
export const applicationServiceBaseUrl = import.meta.env.VITE_APPLICATION_SERVICE_URL;
export const pipelineItemsServiceBaseUrl = import.meta.env.VITE_PIPELINE_ITEMS_SERVICE_URL;
export const userServiceBaseUrl = import.meta.env.VITE_USER_SERVICE_URL;
export const documentServiceUrl = import.meta.env.VITE_DOCUMENT_SERVICE_URL;
export const documentDataExtractorServiceUrl = import.meta.env.VITE_DOCUMENT_DATA_EXTRACTOR_SERVICE_URL;
export const productServiceBaseUrl = import.meta.env.VITE_PRODUCT_SERVICE_URL;
export const lamaAssetsUrl = import.meta.env.VITE_LAMA_ASSETS_URL;
export const peopleServiceBaseUrl = import.meta.env.VITE_PEOPLE_SERVICE_URL;
export const financialsServiceBaseUrl = import.meta.env.VITE_FINANCIALS_SERVICE_URL;
export const partnerServiceBaseUrl = import.meta.env.VITE_PARTNER_SERVICE_URL;
export const bankServiceBaseUrl = import.meta.env.VITE_BANK_SERVICE_URL;
export const verifyBusinessServiceBaseUrl = import.meta.env.VITE_VERIFY_BUSINESS_SERVICE_URL;
export const accountingsServiceBaseUrl = import.meta.env.VITE_ACCOUNTING_SERVICE_URL;
export const businessServiceBaseUrl = import.meta.env.VITE_BUSINESS_SERVICE_URL;
export const auditServiceBaseUrl = import.meta.env.VITE_AUDIT_SERVICE_URL;
export const docusignServiceBaseUrl = import.meta.env.VITE_DOCUSIGN_SERVICE_URL;
export const handleApplicationRejectionBaseUrl = import.meta.env.VITE_HANDLE_APPLICATION_REJECTION_URL;
export const emailSenderServiceUrl = import.meta.env.VITE_EMAIL_SENDER_SERVICE_URL;
export const googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
export const tokenCacheLocation = import.meta.env.VITE_TOKEN_CACHE_LOCATION;
export const ldClientSideId = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID;
export const agGridLicenceKey = import.meta.env.VITE_AG_GRID_LICENCE_KEY;
export const creditReportRetrieverBaseUrl = import.meta.env.VITE_CREDIT_REPORT_RETRIEVER_URL;
export const alertsServiceBaseUrl = import.meta.env.VITE_ALERTS_SERVICE_URL;
export const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
export const reportsServiceBaseUrl = import.meta.env.VITE_REPORTS_SERVICE_URL;
export const etranServiceBaseUrl = import.meta.env.VITE_ETRAN_SERVICE_URL;
export const nautilusIntegrationServiceBaseUrl = import.meta.env.VITE_NAUTILUS_INTEGRATION_SERVICE_URL;
export const searchExistingCustomersServiceUrl = import.meta.env.VITE_SEARCH_EXISTING_CUSTOMERS_SERVICE_URL;
export const spreadsGeneratorUrl = import.meta.env.VITE_SPREADS_GENERATOR_URL;
export const openAiKey = import.meta.env.VITE_OPEN_AI_KEY;
export const novuApplicationIdentifier = import.meta.env.VITE_NOVU_APPLICATION_IDENTIFIER;
