/* eslint-disable react/style-prop-object */
// @ts-ignore
import React from 'react';
import type { FC } from 'react';

export const ApprovalDelegation: FC = () => (
  <svg width={'14'} height={'13'} viewBox={'0 0 14 13'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M7.6166 5.36667L5.0166 2.76667L5.73327 2.06667L7.6166 3.95L11.3999 0.183334L12.0999 0.883334L7.6166 5.36667ZM8.2166 12.5333L3.34994 11.1333V12.05H0.0166016V5.66667H4.78327L8.8666 7.16667C9.1666 7.28889 9.4166 7.49156 9.6166 7.77467C9.8166 8.05822 9.9166 8.38889 9.9166 8.76667H11.5833C12.061 8.76667 12.4528 8.93044 12.7586 9.258C13.0639 9.586 13.2166 10.0111 13.2166 10.5333V10.9667L8.2166 12.5333ZM1.0166 11.05H2.34994V6.66667H1.0166V11.05ZM8.18327 11.4667L12.1666 10.2333C12.1444 10.0889 12.0806 9.97489 11.9753 9.89133C11.8695 9.80822 11.7388 9.76667 11.5833 9.76667H8.43327C8.11105 9.76667 7.80549 9.74711 7.5166 9.708C7.22771 9.66933 6.94438 9.6 6.6666 9.5L5.43327 9.08333L5.74994 8.11667L7.04994 8.55C7.26105 8.62778 7.50282 8.68333 7.77527 8.71667C8.04727 8.75 8.42771 8.76667 8.9166 8.76667C8.9166 8.6 8.8806 8.45822 8.8086 8.34133C8.73616 8.22489 8.63882 8.14444 8.5166 8.1L4.6166 6.66667H3.34994V10.1L8.18327 11.4667Z'
      }
      fill={'black'}
      fillOpacity={'0.6'}
    />
  </svg>
);
