import type { FC, SVGAttributes } from 'react';
import React, { useCallback } from 'react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { grey, red, amber } from '@mui/material/colors';

export const secondaryActionButtonVariants = {
  danger: {
    backgroundColor: 'white',
    borderColor: grey[300],
    iconColor: red[400],
    hover: {
      backgroundColor: '#F5F6F6',
    },
    selected: {
      backgroundColor: red[50],
      borderColor: red[400],
      iconColor: red[400],
    },
  },
  info: {
    backgroundColor: 'white',
    borderColor: grey[300],
    iconColor: grey[600],
    hover: {
      backgroundColor: '#F5F6F6',
    },
    selected: {
      backgroundColor: grey[200],
      borderColor: grey[500],
      iconColor: grey[700],
    },
  },
  warning: {
    backgroundColor: 'white',
    borderColor: grey[300],
    iconColor: amber[700],
    hover: {
      backgroundColor: '#F5F6F6',
    },
    selected: { backgroundColor: amber[50], borderColor: amber[700], iconColor: amber[700] },
  },
  success: {
    backgroundColor: 'white',
    borderColor: grey[300],
    iconColor: '#89BBC2',
    hover: {
      backgroundColor: '#F5F6F6',
    },
    selected: { backgroundColor: '#E5F8F8', borderColor: '#89BBC2', iconColor: '#89BBC2' },
  },
};
interface SecondaryActionButtonProps {
  variant: keyof typeof secondaryActionButtonVariants;
  label: string;
  icon?: FC<{ sx?: SxProps; height?: string; width?: string }> | FC<SVGAttributes<SVGElement>>;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  disabled?: boolean;
}

export const SecondaryActionButton: FC<SecondaryActionButtonProps> = ({ label, variant, icon: Icon, onClick, selected, disabled }) => {
  const buttonTheme = secondaryActionButtonVariants[variant];

  const onClickInner = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        onClick(event);
      }
    },
    [disabled, onClick],
  );

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      component={'button'}
      gap={1}
      p={1}
      onClick={onClickInner}
      sx={{
        backgroundColor: buttonTheme.backgroundColor,
        border: `1px solid ${buttonTheme.borderColor}`,
        borderRadius: '4px',
        whiteSpace: 'nowrap',

        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          background: selected ? buttonTheme.selected.backgroundColor : buttonTheme.hover.backgroundColor,
          border: `1px solid ${selected ? buttonTheme.selected.borderColor : buttonTheme.borderColor}`,
          cursor: 'pointer',
        },
        ...((selected || disabled) && {
          backgroundColor: buttonTheme.selected.backgroundColor,
          border: `1px solid ${buttonTheme.selected.borderColor}`,
        }),
        ...(disabled && {
          color: grey[500],
          borderColor: grey[300],
          cursor: 'default',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': {
            color: grey[500],
            borderColor: grey[300],
            cursor: 'default',
          },
        }),
      }}
    >
      {Icon ? (
        <Icon
          height={'18px'}
          width={'18px'}
          sx={{
            color: disabled ? grey[500] : selected ? buttonTheme.selected.iconColor : buttonTheme.iconColor,
            height: '18px',
            width: '18px',
          }}
        />
      ) : null}
      <Typography variant={'body2'}>{label}</Typography>
    </Box>
  );
};
