import React from 'react';
import type { FC } from 'react';
import { TextareaAutosize, useTheme } from '@mui/material';
import { Flex, Text } from '@lama/design-system';

interface LoanDescriptionProps {
  description: string;
}

export const LoanDescription: FC<LoanDescriptionProps> = ({ description }) => {
  const theme = useTheme();

  return (
    <Flex flexDirection={'column'} gap={8} p={8} backgroundColor={'#FAFAFA'} borderRadius={'4px'}>
      <Text variant={'body1'}>{'Loan description'}</Text>
      <TextareaAutosize
        value={description}
        maxRows={10}
        readOnly
        style={{
          resize: 'none',
          border: 'none',
          fontVariant: 'body2',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          fontFamily: "'Poppins', sans-serif",
          color: theme.palette.text.secondary,
          background: 'transparent',
        }}
      />
    </Flex>
  );
};
