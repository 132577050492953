import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { getApprovalFlow } from '@lama/approval-flows';
import { Flex, Text } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { DecisionCard } from './DecisionCard';
import { DecisionMakingCard } from './DecisionMakingCard';
import { RequiredRoleDecisionCard } from './RequiredRoleDecisionCard';

export const DecisionSegment: FC = () => {
  const { opportunity, product } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);

  const approvingRoles = useMemo(() => {
    const getApprovingRoles = getApprovalFlow(opportunity.partnerId);
    return getApprovingRoles?.({ opportunity, product }) ?? [];
  }, [opportunity, product]);

  const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);

  const currentUser = useMemo(() => users?.find((user) => user.id === userId), [userId, users]);
  const userHasRole = useCallback((role: string) => currentUser?.roles.includes(role), [currentUser]);

  const canCurrentUserApprove = useMemo(() => approvingRoles.some(userHasRole), [approvingRoles, userHasRole]);

  const currentUserDecisionMakingCard = useMemo(() => {
    const currentUserDecided = opportunity.underwriting?.decisions?.some((decision) => decision.userId === userId);

    return currentUserDecided ? null : (
      <DecisionMakingCard role={currentUser?.roles.at(-1) ?? ''} canCurrentUserApprove={canCurrentUserApprove} />
    );
  }, [canCurrentUserApprove, currentUser, opportunity.underwriting?.decisions, userId]);

  const decisionHintCard = useMemo(
    () => (canCurrentUserApprove && approvingRoles.length ? null : <RequiredRoleDecisionCard requiredRole={approvingRoles.at(0) ?? ''} />),
    [approvingRoles, canCurrentUserApprove],
  );

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'h6'} color={'text.secondary'}>
        {'Decision'}
      </Text>
      {opportunity.underwriting?.decisions?.map((decision) => (
        <DecisionCard key={decision.id} decision={decision} canCurrentUserApprove={canCurrentUserApprove} />
      ))}
      {currentUserDecisionMakingCard}
      {decisionHintCard}
    </Flex>
  );
};
