import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { displayToast } from '@lama/app-components';
import { nautilusIntegrationServiceClient } from '../../../clients/nautilusServiceClient';

export const useUploadDocumentsToNautilus = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (dnaLoanNumber: string) => {
      const token = await getAccessTokenSilently();
      return nautilusIntegrationServiceClient.exportToNautilus(opportunityId, dnaLoanNumber, token);
    },
    onError: () => {
      displayToast('Failed to upload documents to Nautilus.', 'error');
    },
    onSuccess: () => {
      displayToast('Documents uploaded to Nautilus successfully.', 'success');
    },
  });
};
