/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { BusinessIcon } from '@lama/app-components';
import { Command } from 'cmdk';
import parse from 'html-react-parser';
import { ApplicationNumber } from '../../shared/components/ApplicationNumber';
import { useGetOpportunityQuery } from '../../shared/hooks/react-query/opportunity/useGetOpportunityQuery';
import type { SearchResult } from './types';

interface CommentSearchResultProps {
  searchResult: SearchResult;
  onItemClick: (comment: string) => Promise<void>;
  comment: string;
}

export const CommentSearchResult: FC<CommentSearchResultProps> = ({ searchResult, onItemClick, comment }) => {
  const { applicationNumber, loanDisplayName } = searchResult;

  const onItemClickInner = useCallback(async () => {
    await onItemClick(comment);
  }, [comment, onItemClick]);

  const parsedCommentHtml = useMemo(() => parse(comment), [comment]);

  return (
    <Flex flexDirection={'column'} alignContent={'flex-start'} onClick={onItemClickInner} alignItems={'center'} gap={2}>
      <Flex flex={1} gap={2} alignItems={'center'} alignSelf={'flex-start'} maxWidth={'380px'}>
        <BusinessIcon />
        <Flex minWidth={0}>
          <Text variant={'body2'} ellipsis>
            {loanDisplayName ?? '-'}
          </Text>
        </Flex>
        {applicationNumber ? <ApplicationNumber numberLabel={applicationNumber} size={'small'} /> : null}
      </Flex>
      <Flex flexDirection={'column'} height={'180px'} maxHeight={'180px'}>
        {parsedCommentHtml}
      </Flex>
    </Flex>
  );
};

interface CommentSearchResultContainerProps {
  searchResult: SearchResult;
  onItemClick: (comment: string) => Promise<void>;
  commentType: string;
}

export const CommentSearchResultContainer: FC<CommentSearchResultContainerProps> = ({ searchResult, onItemClick, commentType }) => {
  const { opportunityId } = searchResult;
  const { data: opportunity } = useGetOpportunityQuery(opportunityId);

  const comment = useMemo(
    () => opportunity?.underwriting?.notes?.find((note) => note.title === commentType)?.text,
    [commentType, opportunity?.underwriting?.notes],
  );

  if (!comment) {
    return null;
  }

  return (
    <Command.Item value={opportunityId}>
      <CommentSearchResult searchResult={searchResult} onItemClick={onItemClick} comment={comment} />
    </Command.Item>
  );
};
