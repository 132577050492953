import React, { useCallback } from 'react';
import type { FC } from 'react';
import { MenuItem } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '@lama/app-components';
import { Text } from '@lama/design-system';
import type { ConditionConfiguration } from '@lama/conditions';
import type { CreateCondition } from '@lama/clients';
import { rangeMatcherType } from '@lama/contracts';
import type { MatcherValue } from './Matchers/types';

interface AddConditionMenuItemProps {
  conditionConfiguration: ConditionConfiguration;
  addCondition: (condition: CreateCondition) => void;
  closeMenu: () => void;
}

export const AddConditionMenuItem: FC<AddConditionMenuItemProps> = ({ conditionConfiguration, addCondition, closeMenu }) => {
  const onClick = useCallback(() => {
    const typeSpecificValues: MatcherValue =
      conditionConfiguration.type === rangeMatcherType
        ? { min: conditionConfiguration.min, max: conditionConfiguration.max, type: conditionConfiguration.type }
        : {
            value: conditionConfiguration.default,
            type: conditionConfiguration.type,
          };

    const createCondition: CreateCondition = {
      active: false,
      conditionMatcher: {
        entityType: conditionConfiguration.entityType,
        fieldName: conditionConfiguration.selector,
        ...typeSpecificValues,
      },
      id: uuidv4(),
      name: conditionConfiguration.displayName,
      propertyId: conditionConfiguration.propertyId,
    };

    addCondition(createCondition);

    closeMenu();
  }, [addCondition, closeMenu, conditionConfiguration]);
  return (
    <Tooltip title={conditionConfiguration.displayName}>
      <MenuItem
        onClick={onClick}
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&:hover': { bgcolor: 'primary.light' },
          borderRadius: '4px',
          px: 2,
        }}
      >
        <Text ellipsis>{conditionConfiguration.displayName}</Text>
      </MenuItem>
    </Tooltip>
  );
};
