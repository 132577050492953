import type { FC } from 'react';
import React from 'react';
import { Flex, Text, DotLoader, greyPalette } from '@lama/design-system';

interface LoadingDataBoxProps {
  loadingText: string;
}
export const LoadingDataBox: FC<LoadingDataBoxProps> = ({ loadingText }) => (
  <Flex backgroundColor={greyPalette[50]} py={6} justifyContent={'center'} width={'100%'} borderRadius={2} gap={4} alignItems={'center'}>
    <DotLoader />
    <Text variant={'body2'}>{loadingText}</Text>
  </Flex>
);
