import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Period } from '@lama/selectors';
import { useCallback } from 'react';
import { type EntityIdsByType, type GenerateSpreadResponse } from '@lama/spreads-generator-client';
import { spreadsGeneratorClient } from '../../../../../shared/clients/spreadsGeneratorClient';

interface GenerateSpreadParams {
  periods: Period[];
  showEntityNames: boolean;
  entityIdsByType: EntityIdsByType;
  opportunityId: string;
  spreadId: string;
}

export const useGenerateSpreadQuery = (
  { opportunityId, spreadId, showEntityNames, periods, entityIdsByType }: GenerateSpreadParams,
  options?: Partial<UseQueryOptions<GenerateSpreadResponse | null>>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getSpreadRowsQueryFunction = useCallback(async (): Promise<GenerateSpreadResponse | null> => {
    const token = await getAccessTokenSilently();

    const spread = await spreadsGeneratorClient.generateOpportunitySpread(
      { spreadId, periods, showEntityNames, entityIdsByType, opportunityId },
      token,
    );

    return spread;
  }, [entityIdsByType, getAccessTokenSilently, opportunityId, periods, showEntityNames, spreadId]);

  const query = useQuery<GenerateSpreadResponse | null>({
    queryKey: [
      'opportunity',
      opportunityId,
      'spread',
      spreadId,
      Object.values(entityIdsByType).flat().join('_'),
      ...periods.map((p) => `${p.startDate}_${p.endDate}`),
    ],
    queryFn: getSpreadRowsQueryFunction,
    ...options,
  });

  return query;
};
