import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { add } from 'date-fns';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';
import { FIXED_RATE_TYPE, getRateType } from './termsUtils';

export const LoanTermsTermsViewSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const rateType = getRateType(opportunity.terms?.rate);

    return [
      {
        label: 'Loan Type',
        values: opportunity.loanType,
      },
      {
        label: 'Origination Date',
        values: opportunity.originationDate,
        type: 'date',
      },
      {
        label: 'Maturity Date',
        values: opportunity.originationDate
          ? add(new Date(opportunity.originationDate), { months: opportunity.terms?.termLength ?? 0 }).toISOString()
          : null,
        type: 'date',
      },
      {
        label: 'Review Year',
        values: opportunity.referenceYear,
        type: 'string',
      },
      {
        label: 'SBA Guaranty Percentage',
        values: opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
        type: 'percent',
      },
      {
        label: 'Loan Sold on Secondary Market',
        values: opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
        type: 'boolean',
      },
      {
        label: 'Rate Type',
        values: rateType,
      },
      {
        label: rateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread',
        values: opportunity.terms?.rate?.spread,
      },
      {
        label: 'Term (Months)',
        values: opportunity.terms?.termLength,
      },
      {
        label: 'Policy Exception',
        values: opportunity.underwriting?.isPolicyException,
      },
      {
        label: 'Annual Servicing Period',
        values: opportunity.annualServicingPeriod,
      },
    ];
  }, [opportunity]);

  return (
    <SegmentContainer title={'Terms'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
