import React, { useContext, useState } from 'react';
import type { FC } from 'react';
import { Button, Text, Flex } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from '@mui/material';
import { getAssetUrl } from '../../../../shared/utils/getAssetUrl';
import { OptionButton } from '../../../../shared/components/OptionLink';

import { ProductSettingsContext } from './ProductSettingsContext';
import { ProductTitleEdit } from './ProductTitleSetting';

interface AppetiteHeaderCardProps {
  productPrettyName: string;
}

export const ProductSettingsHeaderV2: FC<AppetiteHeaderCardProps> = ({ productPrettyName: productName }) => {
  const { enableEngagement, showAutoApprovalSettings } = useFlags();
  const { saveAll, returnToAfterSave } = useContext(ProductSettingsContext);

  const [selectedOption, setSelectedOption] = useState(window.location.pathname.split('/').pop() ?? 'appetite');

  return (
    <Flex flexDirection={'column'} gap={8}>
      <Flex flexDirection={'row'} gap={6}>
        <Box
          width={'80px'}
          height={'80px'}
          border={'1px solid #E0E0E0'}
          borderRadius={'8px'}
          sx={{
            backgroundImage: `url(${getAssetUrl('appetiteHeaderBgV2.png')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
          }}
        />
        <Flex flexDirection={'column'} gap={2} flex={1}>
          <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <ProductTitleEdit productName={productName} />
            {selectedOption === 'appetite' || selectedOption === 'engagement' || selectedOption === 'autoApproval' ? null : (
              <Button variant={'primary'} onClick={saveAll}>
                {!returnToAfterSave ? 'Save All Changes' : 'Save & Continue'}
              </Button>
            )}
          </Flex>
          <Text variant={'body2'} color={'secondary'}>
            {'Modify your appetite to secure top-matching leads'}
          </Text>
        </Flex>
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <OptionButton active={selectedOption === 'appetite'} option={'appetite'} setOption={setSelectedOption} label={'Appetite'} />
        <OptionButton
          active={selectedOption === 'autoApproval'}
          option={'autoApproval'}
          setOption={setSelectedOption}
          label={'Auto-Approval'}
          disabled={!showAutoApprovalSettings}
        />
        <OptionButton
          active={selectedOption === 'requirements'}
          option={'requirements'}
          setOption={setSelectedOption}
          label={'Requirements'}
        />
        <OptionButton active={selectedOption === 'terms'} option={'terms'} setOption={setSelectedOption} label={'Terms'} />
        {enableEngagement ? (
          <OptionButton active={selectedOption === 'engagement'} option={'engagement'} setOption={setSelectedOption} label={'Engagement'} />
        ) : null}
      </Flex>
    </Flex>
  );
};
