import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Stack } from '@mui/material';
import type { DealApiModel } from '@lama/contracts';
import { useSearchParams } from 'react-router-dom';
import { DealCategoryCarousel } from './DealCategoryCarousel';
import { useDealsByCategory } from './hooks/useDealsByCategory';
import { HighMatchCategory } from './HighMatchCategory';
import { DealCategoryShowAll } from './DealCategoryShowAll';

interface DealBoardProps {
  deals: DealApiModel[];
}

export const DealsBoard: FC<DealBoardProps> = ({ deals }: DealBoardProps) => {
  const dealsByCategory = useDealsByCategory(deals);
  const [search] = useSearchParams();
  const category = search.get('category');

  const { highMatch, ...otherCategories } = dealsByCategory;

  const otherCategoriesSortedByDealCount = useMemo(
    () => Object.entries(otherCategories).sort(([, categoryDeals1], [, categoryDeals2]) => categoryDeals2.length - categoryDeals1.length),
    [otherCategories],
  );

  if (category && dealsByCategory[category]?.length) {
    return <DealCategoryShowAll category={category} deals={dealsByCategory[category]!} />;
  }

  return (
    <Stack alignItems={'center'} py={4} px={10} gap={2} bgcolor={'#F7F7F7'}>
      {highMatch?.length ? <HighMatchCategory highMatchDeals={highMatch} /> : null}
      {otherCategoriesSortedByDealCount.map(([dealCategory, categoryDeals]) => (
        <DealCategoryCarousel key={dealCategory} category={dealCategory} deals={categoryDeals} />
      ))}
    </Stack>
  );
};
