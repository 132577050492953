import React from 'react';
import type { FC } from 'react';
import type { AccountData } from '@lama/contracts';
import { Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { formatValue } from '@lama/data-formatters';
import { CategoryLogo } from '../CategoryLogo';

const TransactionListItem = styled(Stack)(({ theme }) => ({
  borderRadius: '0px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(1.5),
    borderBottom: '1px solid #0000001F',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const TransactionItem: FC<{ transaction: AccountData['transactions'][0] }> = ({ transaction }) => (
  <TransactionListItem direction={'row'} width={'100%'}>
    <Stack direction={'row'} alignItems={'center'} spacing={2} flex={0.8}>
      <CategoryLogo category={transaction.category?.[0] ?? '-'} />
      <Stack justifyContent={'left'}>
        <Typography variant={'button'}>{transaction.name ?? '-'}</Typography>
        <Typography variant={'tooltip'} color={'text.secondary'}>
          {transaction.category?.length ? transaction.category.join(', ') : '-'}
        </Typography>
      </Stack>
    </Stack>
    <Stack alignItems={'flex-end'} flex={0.2}>
      <Typography variant={'button'} color={transaction.amount < 0 ? 'success.main' : 'error.main'}>
        {`${transaction.amount < 0 ? '+' : '-'}${formatValue(Math.abs(transaction.amount), 'currency')}`}
      </Typography>
      <Typography variant={'tooltip'} color={'text.secondary'}>
        {format(new Date(transaction.date), 'h:mm aa, d MMM yyyy')}
      </Typography>
    </Stack>
  </TransactionListItem>
);
