import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import {
  monthlyProposedDebtServiceSelector,
  monthlyBusinessDebtServiceSelector,
  monthlyBusinessRefinancedDebtServiceSelector,
} from '@lama/selectors';
import { Grid } from '@lama/design-system';
import { isNil } from 'lodash-es';
import { KeyValuePair, KeyValuePairSkeleton } from '@lama/app-components';
import { SegmentContainer } from '../../shared/SegmentContainer';

export const LiquiditySegment: FC<{ averageBalance: number | null; business?: BusinessApiModel; application: ApplicationApiModel }> = ({
  averageBalance,
  business,
  application,
}) => {
  const monthlyDebtService = useMemo(() => {
    if (!business) {
      return null;
    }

    const businessMonthlyDebtService = monthlyBusinessDebtServiceSelector({
      application,
      entity: business,
      entityType: 'business',
      yearsBack: 0,
    });

    const monthlyProposedDebt = monthlyProposedDebtServiceSelector({
      application,
      entity: application,
      entityType: 'application',
      yearsBack: 0,
    });
    const monthlyRefinancedDebt =
      monthlyBusinessRefinancedDebtServiceSelector({ application, entity: business, entityType: 'business', yearsBack: 0 }) ?? 0;

    if (isNil(businessMonthlyDebtService) || isNil(monthlyProposedDebt)) {
      return null;
    }

    return businessMonthlyDebtService + monthlyProposedDebt - monthlyRefinancedDebt;
  }, [business, application]);

  const liquidityRatio = useMemo(() => {
    if (!monthlyDebtService || !averageBalance) {
      return null;
    }

    return averageBalance / monthlyDebtService;
  }, [monthlyDebtService, averageBalance]);

  if (!business) {
    return (
      <SegmentContainer title={'Details'}>
        <Grid columns={3}>
          <KeyValuePairSkeleton name={'Monthly Debt Service'} />
          <KeyValuePairSkeleton name={'Average Balance'} />
          <KeyValuePairSkeleton name={'Liquidity Ratio'} />
        </Grid>
      </SegmentContainer>
    );
  }

  return (
    <SegmentContainer title={'Details'}>
      <Grid columns={3}>
        <KeyValuePair name={'Monthly Debt Service'} value={monthlyDebtService} type={'currency'} />
        <KeyValuePair name={'Average Balance'} value={averageBalance} type={'currency'} />
        <KeyValuePair name={'Liquidity Ratio'} value={liquidityRatio} type={'number'} />
      </Grid>
    </SegmentContainer>
  );
};
