import React, { useEffect } from 'react';
import type { RequirementsByCategory } from '@lama/app-components';
import { Tooltip } from '@lama/app-components';
import { useToggle } from 'react-use';
import { useSearchParams } from 'react-router-dom';
import { Collapse, Flex, Text, greyPalette } from '@lama/design-system';
import styled from 'styled-components';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ExpandButton from '../../../../shared/components/ExpandButton';
import { RequirementListItem } from './RequirementListItem';

interface RequirementCategoryListItemProps {
  requirementsByCategory: RequirementsByCategory<EvaluatedOpportunityRequirement>;
}

const CategoryContainer = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  border-bottom: 1px solid ${greyPalette[300]};
`;

export const RequirementCategoryListItem: React.FC<RequirementCategoryListItemProps> = ({
  requirementsByCategory: { categoryShortName, categoryLongName, entityRelation, categoryIcon, requirements },
}) => {
  const { fibtDemo } = useFlags();
  const [categoryOpen, toggleCategoryOpen] = useToggle(true);
  const [searchParams] = useSearchParams();
  const currentRequirementId = searchParams.get('requirementId');

  useEffect(() => {
    if (requirements?.find((r) => r.id === currentRequirementId)) {
      toggleCategoryOpen(true);
    }
  }, [currentRequirementId, requirements, toggleCategoryOpen]);

  return (
    <CategoryContainer onClick={toggleCategoryOpen}>
      <Flex px={6} py={2} justifyContent={'space-between'} alignItems={'center'}>
        <Flex flexDirection={'column'} gap={0.5} minWidth={0}>
          <Tooltip title={categoryLongName} placement={'top'}>
            <Text variant={'body2'} color={'secondary'} ellipsis data-sentry-unmask>
              {categoryShortName}
            </Text>
          </Tooltip>
          {entityRelation && !fibtDemo ? (
            <Flex gap={2} alignItems={'center'}>
              {categoryIcon}
              <Text variant={'body3'} color={'secondary'} ellipsis data-sentry-unmask>
                {entityRelation}
              </Text>
            </Flex>
          ) : null}
        </Flex>
        <ExpandButton expand={categoryOpen} />
      </Flex>
      <Flex flexDirection={'column'} backgroundColor={categoryOpen ? 'white' : greyPalette[50]}>
        <Collapse expanded={categoryOpen}>
          {requirements.map((r) => (
            <RequirementListItem key={r.id} requirement={r} />
          ))}
        </Collapse>
      </Flex>
    </CategoryContainer>
  );
};
