import type { FC } from 'react';
import React from 'react';
import type { PaddingProps } from 'styled-system';
import { Flex } from '../Flex/Flex.component.js';
import type { TextProps } from '../Text/index.js';
import { Text } from '../Text/index.js';

interface SwitchLabelProps extends PaddingProps {
  text: string;
  size?: TextProps['size'];
  paddingLeft?: number;
  paddingRight?: number;
}

export const SwitchLabel: FC<SwitchLabelProps> = ({ text, size = 12, ...props }) => (
  <Flex flexDirection={'row'} height={'100%'} alignItems={'center'} justifyContent={'center'} flex={1}>
    <Text size={size} color={'white'} textAlign={'center'} {...props}>
      {text}
    </Text>
  </Flex>
);
