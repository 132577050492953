import type { FC } from 'react';
import React from 'react';
import type { ConditionEvaluationReason } from '@lama/contracts';
import ConditionPass from './assets/ConditionPass.svg';
import ConditionMissingData from './assets/ConditionMissingData.svg';
import ConditionException from './assets/ConditionException.svg';
import ConditionFail from './assets/ConditionFail.svg';

interface ConditionResultIconProps {
  reason: ConditionEvaluationReason;
  size: string;
}

export const ConditionResultIcon: FC<ConditionResultIconProps> = ({ reason, size }) => {
  switch (reason) {
    case 'pass':
    case 'cleared': {
      return <ConditionPass width={size} height={size} />;
    }
    case 'missing_data': {
      return <ConditionMissingData width={size} height={size} />;
    }

    case 'exception': {
      return <ConditionException width={size} height={size} />;
    }
    case 'fail':
    case 'flag': {
      return <ConditionFail width={size} height={size} />;
    }
    default: {
      return null;
    }
  }
};
