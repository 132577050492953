import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import './creditMemoCover.css';
import type { PartnerApiModel } from '@lama/partner-service-client';
import { Flex, Text } from '@lama/design-system';
import { BusinessDefaultImage } from '../../../shared/components/BusinessDefaultImage';
import { BusinessQuickParams } from './BusinessQuickParams';

interface CreditMemoCoverPageProps {
  businessName: string;
  businessImage: string | null;
  loanAmount: number;
  partner: PartnerApiModel;
}

const businessImageSize = 180;

export const CreditMemoCoverPage: FC<CreditMemoCoverPageProps> = ({ businessImage, businessName, loanAmount, partner }) => {
  const date = useMemo(() => new Date().toLocaleDateString(), []);

  const primaryTextColor = useMemo(() => partner.branding.theme.primary.main ?? '#002D72', [partner]);

  return (
    <Flex flexDirection={'column'} py={4} height={'100%'} justifyContent={'space-between'} className={'creditMemoCoverPage'}>
      <Flex
        flexDirection={'column'}
        className={'creditMemoCoverContainer'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={6}
        pt={16}
        width={'100%'}
      >
        <Box
          sx={{
            borderRadius: '6px',
            filter: 'drop-shadow(0px 0px 40px rgba(102, 158, 245, 0.80)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.12))',
          }}
        >
          {businessImage ? (
            <Box
              alt={'business_image'}
              component={'img'}
              sx={{
                height: `${businessImageSize}px`,
                width: `${businessImageSize}px`,
                minWidth: `${businessImageSize}px`,
                minHeight: `${businessImageSize}px`,
                objectFit: 'cover',
              }}
              src={businessImage}
            />
          ) : (
            <BusinessDefaultImage color={partner.branding.theme.primary.main} size={businessImageSize} />
          )}
        </Box>
        <Flex flexDirection={'column'} gap={4} alignItems={'center'}>
          <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Text weight={500} size={32} color={primaryTextColor}>
              {businessName}
            </Text>
            <Text weight={300} size={16} color={primaryTextColor}>
              {'Credit Memo'}
            </Text>
          </Flex>
          <BusinessQuickParams date={date} partnerName={partner.displayName} loanAmount={loanAmount} />
        </Flex>
      </Flex>
    </Flex>
  );
};
