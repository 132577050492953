/* eslint-disable react/no-array-index-key */
import React, { useCallback, useContext, useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { Flex, greenPalette, greyPalette, redPalette, Text } from '@lama/design-system';
import type { OpportunityInsights, OpportunityInsightSentiment } from '@lama/clients';
import { formatValue } from '@lama/data-formatters';
import type { BarTooltipProps } from '@nivo/bar';
import { ResponsiveBar } from '@nivo/bar';
import { Tooltip, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { InfoOutlined } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../ApplicationContext';
import { InsightsPageSkeleton } from './InsightsPageSkeleton';
import { useOpportunityInsightsQuery } from './hooks/useOpportunityInsightsQuery';

const BorderedContainer = styled(Flex)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 1px 1px 0px #e0e0e0b8;
`;

const sentimentToIcon: Record<OpportunityInsightSentiment, ReactElement | null> = {
  positive: <CheckCircleOutlineOutlinedIcon fontSize={'small'} style={{ color: greenPalette[300] }} />,
  negative: <WarningAmberOutlinedIcon fontSize={'small'} style={{ color: redPalette[300] }} />,
  neutral: null,
};

const Metric: FC<{ metric: OpportunityInsights['metrics'][number] }> = ({ metric }) => {
  const tooltip = useMemo(() => {
    if (!metric.calculation && !metric.source) {
      return null;
    }

    return (
      <Flex flexDirection={'column'} gap={2}>
        {metric.calculation ? (
          <Flex flexDirection={'column'}>
            <Text variant={'body3'}>{'Calculation'}</Text>
            <Text variant={'body2'}>{metric.calculation}</Text>
          </Flex>
        ) : null}
        {metric.source ? (
          <Flex flexDirection={'column'}>
            <Text variant={'body3'}>{'Source'}</Text>
            <Text variant={'body2'}>{metric.source}</Text>
          </Flex>
        ) : null}
      </Flex>
    );
  }, [metric.calculation, metric.source]);

  return (
    <BorderedContainer gap={4} width={'300px'} height={'120px'}>
      <Flex flexDirection={'column'} gap={2} justifyContent={'space-between'} width={'100%'}>
        <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Text variant={'body2'} color={greyPalette[700]}>
            {metric.title}
          </Text>
          <Tooltip title={tooltip} placement={'top'}>
            <InfoOutlined sx={{ color: greyPalette[500], height: '18px', width: '18px' }} />
          </Tooltip>
        </Flex>
        <Flex gap={2} alignItems={'center'}>
          {metric.sentiment ? sentimentToIcon[metric.sentiment] : null}
          <Text variant={'body1'}>{formatValue(metric.value, metric.format)}</Text>
        </Flex>
        <Text variant={'body3'} color={greyPalette[500]}>{`${metric.startDate} - ${metric.endDate}`}</Text>
      </Flex>
    </BorderedContainer>
  );
};

const Metrics: FC<{ metrics: OpportunityInsights['metrics'] }> = ({ metrics }) => (
  <Flex gap={4} flexDirection={'column'}>
    <Text variant={'h6'}>{'Metrics'}</Text>
    <Flex gap={4} flexWrap={'wrap'}>
      {metrics.map((metric) => (
        <Metric key={`${metric.title}-${metric.startDate}-${metric.endDate}`} metric={metric} />
      ))}
    </Flex>
  </Flex>
);

const BarToolTip: FC<BarTooltipProps<{ value: number }>> = ({ data: { value } }) => (
  <Flex bg={'#ffffff'} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={1} px={2}>
    <Text variant={'body3'}>{formatValue(value, 'currency')}</Text>
  </Flex>
);

const tableMargins = {
  top: 20,
  right: 0,
  bottom: 90,
  left: 100,
};

const Trend: FC<{ trend: OpportunityInsights['trends'][number] }> = ({ trend }) => {
  const theme = useTheme();
  const barData = useMemo(() => trend.values.map((value) => ({ date: value.date, value: value.value })), [trend]);

  return (
    <BorderedContainer gap={4} flexDirection={'column'} height={'250px'} width={'100%'}>
      <Text variant={'body1'} color={greyPalette[700]}>
        {trend.title}
      </Text>
      <ResponsiveBar
        data={barData}
        indexBy={'date'}
        colors={[`${theme.palette.primary.main}77`]}
        margin={tableMargins}
        enableGridX={false}
        enableGridY={false}
        enableLabel={false}
        axisBottom={{
          tickSize: 0,
          tickRotation: 45,
        }}
        axisLeft={{
          format: (value) => formatValue(value, 'currency'),
          tickValues: 3,
        }}
        tooltip={BarToolTip}
      />
    </BorderedContainer>
  );
};

const Trends: FC<{ trends: OpportunityInsights['trends'] }> = ({ trends }) => (
  <Flex flex={0.5} gap={4} flexDirection={'column'} width={'100%'}>
    <Text variant={'h6'}>{'Trends'}</Text>
    <Flex flexDirection={'column'} gap={8}>
      {trends.map((trend) => (
        <Trend key={trend.title} trend={trend} />
      ))}
    </Flex>
  </Flex>
);

const Insights: FC<{ insights: OpportunityInsights['insights'] }> = ({ insights }) => (
  <Flex flex={0.5} flexDirection={'column'} gap={4}>
    <Text variant={'h6'}>{'Insights'}</Text>
    <Flex flexDirection={'column'} gap={8}>
      {insights.map((insight) => (
        <BorderedContainer key={insight.title} gap={4}>
          <Flex flexDirection={'column'} gap={4}>
            <Flex gap={2} alignItems={'center'}>
              {insight.sentiment ? sentimentToIcon[insight.sentiment] : null}
              <Text variant={'body1'} color={greyPalette[700]}>
                {insight.title}
              </Text>
            </Flex>
            <Text variant={'body2'}>{insight.description}</Text>
          </Flex>
        </BorderedContainer>
      ))}
    </Flex>
  </Flex>
);

const Summary: FC<{ conclusion: OpportunityInsights['conclusion'] }> = ({ conclusion }) => (
  <Flex gap={4} flexDirection={'column'}>
    <Text variant={'h6'}>{'Conclusion'}</Text>
    <BorderedContainer gap={4} backgroundColor={greyPalette[50]}>
      <Text variant={'body2'}>{conclusion}</Text>
    </BorderedContainer>
  </Flex>
);

export const InsightsPage: FC = () => {
  const { refreshInsightsButtonEnabled } = useFlags();
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { data: opportunityInsights, isLoading: loadingInsights, refetch } = useOpportunityInsightsQuery(opportunityId);

  const onClickFetchInsights = useCallback(() => {
    void refetch();
  }, [refetch]);

  return (
    <Flex flexDirection={'column'} gap={20}>
      {loadingInsights ? (
        <InsightsPageSkeleton />
      ) : opportunityInsights ? (
        <Flex flexDirection={'column'} gap={12}>
          <Summary conclusion={opportunityInsights.conclusion} />
          <Metrics metrics={opportunityInsights.metrics} />
          <Flex gap={8}>
            <Insights insights={opportunityInsights.insights} />
            <Trends trends={opportunityInsights.trends} />
          </Flex>
        </Flex>
      ) : null}
      {refreshInsightsButtonEnabled ? (
        <Flex justifyContent={'flex-end'} pb={10}>
          <LoadingButton variant={'outlined'} onClick={onClickFetchInsights} loading={loadingInsights}>
            {'Refresh Insights'}
          </LoadingButton>
        </Flex>
      ) : null}
    </Flex>
  );
};
