import { useAuth0 } from '@auth0/auth0-react';
import type { UnderwritingNoteCreateModel } from '@lama/clients';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useCreateNoteMutation = (opportunityId: string, userId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notePayload: Omit<UnderwritingNoteCreateModel, 'createdBy' | 'updatedBy'>) => {
      const token = await getAccessTokenSilently();

      if (!userId) {
        return;
      }

      return applicationServiceClient.addNote(opportunityId, notePayload, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
  });
};
