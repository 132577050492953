import React from 'react';

import type { TableCellProps } from '@mui/material';
import { Text } from '@lama/design-system';
import type { InformationTypes } from '@lama/app-components';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';

const dataRows: {
  type: InformationTypes;
  values: (number | null)[];
  name: string;
  bold: boolean;
  bgColor?: string;
  colspan?: number;
}[] = [
  {
    name: 'Cash',
    values: [521, 322, 56_450, 1_173_633],
    type: 'currency',
    bold: false,
  },
  {
    name: 'A/R',
    values: [2_114_233, 2_642_729, 2_513_239, 2_383_379],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Inventory',
    values: [5_782_534, 10_111_811, 7_851_414, 6_233_952],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Other Current Assets',
    values: [16_649, 8898, 34_348, 16_368],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Current Assets',
    values: [7_913_937, 12_763_760, 10_455_451, 9_807_332],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Net Fixed Assets',
    values: [16_091, 12_485, 20_717, 20_716],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Intangible Assets',
    values: [5343, 0, 9804, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Operating Right of Use Assets',
    values: [0, 288_155, 153_199, 163_003],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Deposits and other Assets',
    values: [432_302, 34_914, 18_683, 42_977],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Assets',
    values: [8_367_673, 13_099_314, 10_657_854, 10_034_028],
    type: 'currency',
    bold: true,
  },
  {
    name: 'CPLTD',
    values: [0, 241_350, 68_358, 5_291_098],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Revolver Outstandings',
    values: [3_853_106, 5_346_804, 5_291_098, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Accounts Payable',
    values: [712_763, 3_377_147, 1_453_509, 1_117_200],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Accrued Expenses',
    values: [157_589, 166_151, 95_087, 85_152],
    type: 'currency',
    bold: false,
  },
  {
    name: 'CM  of Operating Lease Obligations',
    values: [0, 185_629, 204_385, 212_743],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Current Liabilities',
    values: [4_723_458, 9_317_081, 7_112_437, 6_706_193],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Long Term Sr Secured Debt',
    values: [0, 121_280, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Unsecured / Subordinated Debt',
    values: [0, 0, 370_000, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Long Term Debt',
    values: [0, 121_280, 370_000, 0],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Other Long Term Liabilities',
    values: [0, 0, 1877, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'PPP Loan',
    values: [0, 0, 0, 0],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Operating Right of Use Liabilities',
    values: [0, 201_168, 1075, 2952],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Total Liabilities',
    values: [4_723_458, 9_639_529, 7_485_389, 6_709_145],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Total Net Worth',
    values: [8_367_673, 3_459_785, 3_172_456, 3_324_884],
    type: 'currency',
    bold: true,
  },
  {
    name: 'Leverage Statistics',
    bold: true,
    type: 'currency',
    values: [],
    bgColor: '#EFEFEF',
    colspan: 1000,
  },
  {
    name: 'Tangible Net Worth',
    values: [3_638_872, 3_372_798, 3_010_537, 3_164_833],
    type: 'currency',
    bold: false,
  },
  {
    name: 'Debt-to-Tangible Net Worth (2)',
    values: [1.3, 2.8, 2.49, 2.12],
    type: 'number',
    bold: false,
  },
  {
    name: 'Senior Debt to EBITDA',
    values: [1.71, 5, 16.67, 13.94],
    type: 'number',
    bold: false,
  },
  {
    name: 'Total Debt to EBITDA',
    values: [1.71, 5, 17.82, 13.94],
    type: 'number',
    bold: false,
  },
  {
    name: 'Lease Adjusted Debt to EBITDAR (3)',
    values: [2.14, 5.26, 12.29, 11.02],
    type: 'number',
    bold: false,
  },
  {
    name: 'Liquidity & Working Capital Statistics',
    bold: true,
    type: 'currency',
    values: [],
    bgColor: '#EFEFEF',
    colspan: 1000,
  },
  {
    name: 'Current Ratio (1)',
    values: [1.68, 1.37, 1.47, 1.46],
    type: 'number',
    bold: false,
  },
  {
    name: 'Quick Ratio',
    values: [0.45, 0.28, 0.36, 0.53],
    type: 'number',
    bold: false,
  },
  {
    name: 'Days Inventory',
    values: [121.4, 150.6, 214.5, 293.1],
    type: 'number',
    bold: false,
  },
  {
    name: 'Days Sales Outstanding',
    values: [31.8, 38.1, 53.5, 85.2],
    type: 'number',
    bold: false,
  },
  {
    name: 'Days Payable Outstanding',
    values: [30.4, 38.8, 57.7, 53.2],
    type: 'number',
    bold: false,
  },
  {
    name: 'Cash Conversion Cycle',
    values: [122.8, 150, 210.3, 325.1],
    type: 'number',
    bold: false,
  },
  {
    name: 'Asset Turnover',
    values: [2.78, 1.78, 1.67, 0.8],
    type: 'number',
    bold: false,
  },
];

const headers = [
  { text: '', width: '30%' },
  { text: ['FYE', 'Reviewed', '12/31/2021'], width: '15%' },
  { text: ['FYE', 'Reviewed', '12/31/2022'], width: '15%' },
  { text: ['FYE', 'Reviewed', '12/31/2023'], width: '15%' },
  { text: ['YTD', 'Co Prep', '06/30/2024'], width: '15%' },
];

export const FirstBankBalanceSheet = () => {
  const rows: CellContent[][] = dataRows.map((item) => [
    { value: item.name, bold: item.bold, bgColor: item.bgColor, colspan: item.colspan },
    ...item.values.map((value) => ({
      value,
      type: item.type,
      align: 'right' as TableCellProps['align'],
      bold: item.bold,
      bgColor: item.bgColor,
      colspan: item.colspan,
    })),
  ]);

  return (
    <SegmentContainer bordered={false}>
      <CreditMemoTable headers={headers} rows={[...rows]} />
      <Text variant={'body3'} color={'secondary'}>
        {'(1)  Current Ratio calculated consistent with C&I Risk Rating guidance of Current Assets / Current Liabilities'}
      </Text>
      <Text variant={'body3'} color={'secondary'}>
        {'(2)  Debt-to-Tangible Net Worth calculated consistent with C&I Risk Rating guidance of (Total Liabilities / Tangible Net Worth)'}
      </Text>
      <Text variant={'body3'} color={'secondary'}>
        {'(3)  Lease Adjusted Debt to EBITDAR assumes a 6x capitalization factor for present year lease expense.'}
      </Text>
    </SegmentContainer>
  );
};
