import React, { lazy, useCallback } from 'react';
import type { FC } from 'react';
import { format } from 'date-fns';
import { formatValue } from '@lama/data-formatters';
import { TransactionTooltip } from './TransactionTooltip';

const ResponsiveBar = lazy(() => import('@nivo/bar').then((module) => ({ default: module.ResponsiveBar })));

export const TransactionsChart: FC<{ data: Record<string, number | string>[]; granularityFormat: string }> = ({
  data,
  granularityFormat,
}) => {
  const xAxisFormat = useCallback((value: string) => format(new Date(value), granularityFormat), [granularityFormat]);
  const yAxisFormat = useCallback((value: number) => formatValue(value, 'currencyCompact'), []);

  return (
    <ResponsiveBar
      data={data}
      margin={{ top: 16, bottom: 32, left: 48 }}
      padding={0.2}
      indexBy={'date'}
      groupMode={'grouped'}
      colors={['#FEAC8A', '#83F0D0']}
      colorBy={'id'}
      keys={['loss', 'profit']}
      borderRadius={4}
      tooltip={TransactionTooltip}
      axisBottom={{
        tickSize: 0,
        tickPadding: 16,
        format: xAxisFormat,
      }}
      axisLeft={{
        tickSize: 0,
        format: yAxisFormat,
      }}
      enableLabel={false}
      animate
      ariaLabel={'Transactions Bar Chart'}
    />
  );
};
