import { formatValue } from '@lama/data-formatters';
import { isNil, isNumber, isString } from 'lodash-es';
import type { SpreadUnitType } from '../types';

export const formatSpreadCellValue = (value?: any, type?: string, unitType: SpreadUnitType = 'decimal') => {
  if (isString(value) && !isNil(value)) {
    return value;
  }

  if (!isNumber(value)) {
    return '-';
  }

  if (type === 'currency') {
    switch (unitType) {
      case 'decimal': {
        return formatValue(value, 'spreadCurrency');
      }
      case 'short': {
        return `${formatValue(Math.round(value / 1000), 'noDecimalCurrency')}K`;
      }
      case 'nonDecimal': {
        return formatValue(value, 'noDecimalCurrency');
      }
    }
  }

  return formatValue(value, type);
};

export const formatCellValueWithHierarchyIndentation = (value: any, level: number) => {
  const indentation = '\t'.repeat(level);
  return `${indentation}${value}`;
};
