import { greenPalette, amberPalette, Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import type { UnderwritingNoteRelatedSectionType } from '../../../components/Application/shared/noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../../../components/Application/shared/noteUtils';

const StyledStatusBadge = styled.div<{ isReviewed: boolean }>`
  height: 8px;
  width: 8px;
  background-color: ${({ isReviewed }) => (isReviewed ? greenPalette[500] : amberPalette[700])};
  border-radius: 50%;
  display: inline-block;
`;

export const SideNavSectionItemReviewedStatus: FC<{ sectionId: string; relatedItemType: UnderwritingNoteRelatedSectionType }> = ({
  sectionId,
  relatedItemType,
}) => {
  const { opportunity } = useContext(ApplicationContext);
  const isSectionReviewed = useMemo(
    () =>
      !!opportunity.underwriting?.notes?.some(
        (note) => note[getUnderwritingNoteRelatedItemProperty(relatedItemType)] === sectionId && note.type === 'reviewed',
      ),
    [opportunity.underwriting?.notes, relatedItemType, sectionId],
  );

  return (
    <Flex gap={2} alignItems={'center'}>
      <StyledStatusBadge isReviewed={isSectionReviewed} />
      <Text variant={'body3'} color={'secondary'}>
        {isSectionReviewed ? 'Reviewed' : 'Pending Review'}
      </Text>
    </Flex>
  );
};
