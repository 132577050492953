/* eslint-disable react/style-prop-object */
// @ts-ignore
import React from 'react';
import type { FC } from 'react';

export const Store: FC = () => (
  <svg width={'12'} height={'12'} viewBox={'0 0 12 12'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M1.29996 1.83333C1.16663 1.83333 1.04996 1.786 0.949962 1.69133C0.849962 1.59711 0.799962 1.47777 0.799962 1.33333C0.799962 1.18888 0.849962 1.06933 0.949962 0.974661C1.04996 0.880439 1.16663 0.833328 1.29996 0.833328H10.7C10.8333 0.833328 10.95 0.880439 11.05 0.974661C11.15 1.06933 11.2 1.18888 11.2 1.33333C11.2 1.47777 11.15 1.59711 11.05 1.69133C10.95 1.786 10.8333 1.83333 10.7 1.83333H1.29996ZM1.4333 11.1667C1.26663 11.1667 1.12507 11.1084 1.00863 10.992C0.89174 10.8751 0.833295 10.7333 0.833295 10.5667V7.16666H0.633296C0.444407 7.16666 0.286184 7.09155 0.158629 6.94133C0.0306287 6.79155 -0.011149 6.62222 0.0332954 6.43333L0.699962 3.31666C0.733296 3.17222 0.805518 3.05555 0.916629 2.96666C1.02774 2.87777 1.15552 2.83333 1.29996 2.83333H10.7C10.8444 2.83333 10.9722 2.87777 11.0833 2.96666C11.1944 3.05555 11.2666 3.17222 11.3 3.31666L11.9666 6.43333C12.0111 6.62222 11.9695 6.79155 11.842 6.94133C11.714 7.09155 11.5555 7.16666 11.3666 7.16666H11.1666V10.6667C11.1666 10.8111 11.1195 10.9307 11.0253 11.0253C10.9306 11.1195 10.8111 11.1667 10.6666 11.1667C10.5222 11.1667 10.4026 11.1195 10.308 11.0253C10.2137 10.9307 10.1666 10.8111 10.1666 10.6667V7.16666H7.16663V10.5667C7.16663 10.7333 7.10841 10.8751 6.99196 10.992C6.87507 11.1084 6.7333 11.1667 6.56663 11.1667H1.4333ZM1.8333 10.1667H6.16663V7.16666H1.8333V10.1667ZM1.11663 6.16666H10.8833L10.3833 3.83333H1.61663L1.11663 6.16666Z'
      }
      fill={'black'}
      fillOpacity={'0.6'}
    />
  </svg>
);
