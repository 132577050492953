import { getEndOfYear, getStartOfYear, getYear, parseDateWithoutTime } from '@lama/data-formatters';
import type { Period } from '@lama/selectors';
import { differenceInDays } from 'date-fns';
import { maxBy } from 'lodash-es';

export const getDefaultSpreadPeriods = (periods: Period[], configuredYears: number[]): Period[] =>
  configuredYears.map((year) => {
    const currentYearsPeriods = periods.filter(({ endDate }) => getYear(endDate) === year);
    const longestPeriodInCurrentYear = maxBy(currentYearsPeriods, (period) =>
      differenceInDays(parseDateWithoutTime(period.endDate), parseDateWithoutTime(period.startDate)),
    );

    if (longestPeriodInCurrentYear) {
      return longestPeriodInCurrentYear;
    }

    return { startDate: getStartOfYear(year), endDate: getEndOfYear(year) };
  });
