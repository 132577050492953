import { useAuth0 } from '@auth0/auth0-react';
import type { UpdateDealRequestBody } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';
import { UserDetailsContext } from '../../../context/UserDetailsContext';

export const useUpdateDeal = (dealId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { partner } = useContext(UserDetailsContext);

  const { mutateAsync } = useMutation({
    mutationFn: async (dealUpdate: UpdateDealRequestBody) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.updateDeal({
        dealId,
        dealUpdate,
        partnerId: partner,
        token,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['deals', partner],
        }),
        queryClient.invalidateQueries({
          queryKey: ['deal', dealId],
        }),
      ]);
    },
  });

  return mutateAsync;
};
