import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { ProductApiModel, PropertiesRecord } from '@lama/contracts';
import type { FC } from 'react';
import React, { useMemo } from 'react';

export const ApplicationContext = React.createContext<{
  application: ApplicationApiModel;
  product: ProductApiModel;
  opportunity: OpportunityApiModel;
  properties: PropertiesRecord;
}>(null!);

interface ApplicationProviderProps {
  product: ProductApiModel;
  opportunity: OpportunityApiModel;
  properties: PropertiesRecord;
  children: React.ReactNode;
}

export const ApplicationProvider: FC<ApplicationProviderProps> = ({ product, opportunity, properties, children }) => {
  const value = useMemo(
    () => ({
      application: opportunity.application,
      product,
      opportunity,
      properties,
    }),
    [opportunity, product, properties],
  );

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
