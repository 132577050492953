import { useAuth0 } from '@auth0/auth0-react';
import type { UnderwritingDecisionUpdateModel } from '@lama/clients';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useUpdateDecisionMutation = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ decisionId, decisionPayload }: { decisionId: string; decisionPayload: UnderwritingDecisionUpdateModel }) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.updateDecision(opportunityId, decisionId, decisionPayload, token);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
    onError: async () => {
      displayToast('An error has occurred. Please contact support.', 'error');
    },
  });
};
