import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createProductServiceClient } from '@lama/clients';
import type { SectionConfiguration } from '@lama/contracts';
import { productServiceBaseUrl } from '../../../../framework/environment';

const productServiceClient = createProductServiceClient(productServiceBaseUrl);

export const useGetPartnerSectionsQuery = (
  partnerId: string,
  options?: Partial<UseQueryOptions<SectionConfiguration[] | null>>,
): UseQueryResult<SectionConfiguration[] | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getPartnerSectionsQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return productServiceClient.getPartnerSections(partnerId, token);
  }, [getAccessTokenSilently, partnerId]);

  const query = useQuery<SectionConfiguration[] | null>({
    queryKey: ['partnerSections', partnerId],
    queryFn: getPartnerSectionsQueryFunction,
    ...options,
  });

  return query;
};
