import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import type { GetAttributesResponse } from '@lama/contracts';
import { documentDataExtractorServiceClient } from '../../../../../../../shared/clients/documentDataExtractorServiceClient';

export const useGetDocumentAttributes = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getDocumentAttributes = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return documentDataExtractorServiceClient.getAttributes(token);
  }, [getAccessTokenSilently]);

  return useQuery<GetAttributesResponse['attributes']>({ queryKey: ['documentAttributes'], queryFn: getDocumentAttributes });
};
