import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Divider } from '@mui/material';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { format, subMonths, isSameMonth, startOfMonth, isWithinInterval, endOfMonth } from 'date-fns';
import { Flex, Grid, Text } from '@lama/design-system';
import {
  FormikAutoSave,
  FormikDatePicker,
  FormikMoneyInputField,
  FormikPicker,
  LoadingWrapper,
  displayToast,
  ConfirmLeave,
} from '@lama/app-components';
import { getSourcedProperty } from '@lama/properties';
import { taxEntityTypes } from '@lama/contracts';
import { compact, sumBy } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAsyncFn } from 'react-use';
import type { BusinessApiModel } from '@lama/business-service-client';
import { getUTCDateFromZonedTime, parseDateWithoutTime } from '@lama/data-formatters';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useUpdateBusiness } from '../../../../shared/hooks/react-query/business/useUpdateBusiness';
import { useSubmitFinancialsMutation } from '../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import { getFinancialPayloadAction } from '../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { SaveFormButton } from '../../shared/SaveFormButton';
import type { FinancialPayload } from '../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/types';
import { LiquiditySegment } from './LiquiditySegment';

const taxEntityTypePropertyKey = 'business_taxEntityType';
const balancePropertyKey = 'business_monthEndDepositBalance';

export const AdditionalFinancialInfoSegment: FC<{ business: BusinessApiModel }> = ({ business }) => {
  const {
    opportunity: { id: opportunityId, application },
    properties,
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { autoSaveEnabled } = useFlags();

  const taxEntityTypeProperty = useMemo(() => properties[taxEntityTypePropertyKey], [properties]);
  const balanceProperty = useMemo(() => properties[balancePropertyKey], [properties]);
  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(application.id, opportunityId);

  const latestBalanceDate = useMemo(() => endOfMonth(new Date(application.balanceReferenceDate ?? application.createdAt)), [application]);

  const balanceFinancials = useMemo(
    () =>
      business.financials.filter(
        ({ type, endDate }) =>
          balanceProperty?.financialAttribute === type &&
          isWithinInterval(parseDateWithoutTime(endDate), { start: startOfMonth(subMonths(latestBalanceDate, 5)), end: latestBalanceDate }),
      ) ?? [],
    [balanceProperty?.financialAttribute, business.financials, latestBalanceDate],
  );

  const latestBalanceFinancial = useMemo(
    () => balanceFinancials.find((balanceFinancial) => isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), latestBalanceDate)),
    [balanceFinancials, latestBalanceDate],
  );

  const fifthBalanceFinancial = useMemo(
    () =>
      balanceFinancials.find((balanceFinancial) =>
        isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), subMonths(latestBalanceDate, 1)),
      ),
    [balanceFinancials, latestBalanceDate],
  );

  const fourthBalanceFinancial = useMemo(
    () =>
      balanceFinancials.find((balanceFinancial) =>
        isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), subMonths(latestBalanceDate, 2)),
      ),
    [balanceFinancials, latestBalanceDate],
  );

  const thirdBalanceFinancial = useMemo(
    () =>
      balanceFinancials.find((balanceFinancial) =>
        isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), subMonths(latestBalanceDate, 3)),
      ),
    [balanceFinancials, latestBalanceDate],
  );

  const secondBalanceFinancial = useMemo(
    () =>
      balanceFinancials.find((balanceFinancial) =>
        isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), subMonths(latestBalanceDate, 4)),
      ),
    [balanceFinancials, latestBalanceDate],
  );

  const firstBalanceFinancial = useMemo(
    () =>
      balanceFinancials.find((balanceFinancial) =>
        isSameMonth(parseDateWithoutTime(balanceFinancial.endDate), subMonths(latestBalanceDate, 5)),
      ),
    [balanceFinancials, latestBalanceDate],
  );

  const averageBalance = useMemo(() => {
    const sixMonthsBalanceFinancials = compact([
      latestBalanceFinancial,
      fifthBalanceFinancial,
      fourthBalanceFinancial,
      thirdBalanceFinancial,
      secondBalanceFinancial,
      firstBalanceFinancial,
    ]);
    if (sixMonthsBalanceFinancials.length < 6) {
      return null;
    }

    return sumBy(sixMonthsBalanceFinancials, (fin) => fin.value) / sixMonthsBalanceFinancials.length;
  }, [
    fifthBalanceFinancial,
    firstBalanceFinancial,
    fourthBalanceFinancial,
    latestBalanceFinancial,
    secondBalanceFinancial,
    thirdBalanceFinancial,
  ]);

  const { mutateAsync: updateBusiness } = useUpdateBusiness(opportunityId);
  const { mutateAsync: submitFinancialChanges } = useSubmitFinancialsMutation(business.id ?? '', 'business', opportunityId);

  const sourcedTaxEntityType = useMemo(
    () => (taxEntityTypeProperty ? getSourcedProperty(taxEntityTypeProperty, business, application, 0) : undefined),
    [taxEntityTypeProperty, application, business],
  );

  const initialValuesForEntityType = useMemo(
    () => ({
      taxEntityType: (sourcedTaxEntityType?.selectedPropertyValue?.value as string | undefined) ?? '',
    }),
    [sourcedTaxEntityType?.selectedPropertyValue?.value],
  );

  const initialValuesForReferenceDate = useMemo(
    () => ({
      balanceReferenceDate: latestBalanceDate.toISOString(),
    }),
    [latestBalanceDate],
  );

  const initialValuesForBalances = useMemo(
    () => ({
      latestBalance: latestBalanceFinancial?.value ?? '',
      fifthBalance: fifthBalanceFinancial?.value ?? '',
      fourthBalance: fourthBalanceFinancial?.value ?? '',
      thirdBalance: thirdBalanceFinancial?.value ?? '',
      secondBalance: secondBalanceFinancial?.value ?? '',
      firstBalance: firstBalanceFinancial?.value ?? '',
    }),
    [
      fifthBalanceFinancial?.value,
      firstBalanceFinancial?.value,
      fourthBalanceFinancial?.value,
      latestBalanceFinancial?.value,
      secondBalanceFinancial?.value,
      thirdBalanceFinancial?.value,
    ],
  );

  const onSubmitTaxEntityType = useCallback(
    async (values: typeof initialValuesForEntityType, { resetForm }: FormikHelpers<any>) => {
      const { taxEntityType } = values;

      resetForm({ values });

      if (taxEntityType !== sourcedTaxEntityType?.selectedPropertyValue?.value) {
        await updateBusiness({
          businessId: business.id,
          updateBusinessPayload: {
            taxEntityType,
          },
        });
      }
    },
    [business, sourcedTaxEntityType?.selectedPropertyValue?.value, updateBusiness],
  );

  const [{ loading: savingReferenceDate }, onSubmitReferenceDate] = useAsyncFn(
    async (values: typeof initialValuesForReferenceDate, { resetForm }: FormikHelpers<any>) => {
      const { balanceReferenceDate } = values;

      resetForm({ values });

      const adjustedApplicationReferenceDate = endOfMonth(
        parseDateWithoutTime(application.balanceReferenceDate ?? application.createdAt),
      ).toISOString();

      const balanceReferenceDataISO = balanceReferenceDate;

      if (balanceReferenceDataISO !== adjustedApplicationReferenceDate) {
        await updateApplication({
          updateApplicationPayload: {
            balanceReferenceDate: balanceReferenceDataISO,
          },
        });
      }
    },
    [application.balanceReferenceDate, application.createdAt, updateApplication],
  );

  const [{ loading: savingForm }, onSubmit] = useAsyncFn(
    async (values: typeof initialValuesForBalances, { resetForm }: FormikHelpers<any>) => {
      const { latestBalance, fifthBalance, fourthBalance, thirdBalance, secondBalance, firstBalance } = values;

      if (!userId) {
        displayToast('Submitting user not found, please contact support', 'error');
        return;
      }

      if (!balanceProperty) {
        displayToast('Configuration error, please contact support', 'error');
        return;
      }

      resetForm({ values });

      const balanceReferenceDataISO = getUTCDateFromZonedTime(latestBalanceDate).toISOString();

      const financialPayload: FinancialPayload[] = [
        ...getFinancialPayloadAction({
          entityId: business.id,
          fieldToUpdate: latestBalanceFinancial,
          year: latestBalanceDate.getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: latestBalance,
          userId,
          startDate: balanceReferenceDataISO,
          endDate: balanceReferenceDataISO,
        }),
        ...getFinancialPayloadAction({
          entityId: business.id,
          fieldToUpdate: fifthBalanceFinancial,
          year: subMonths(latestBalanceDate, 1).getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: fifthBalance,
          userId,
          startDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 1)).toISOString(),
          endDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 1)).toISOString(),
        }),
        ...getFinancialPayloadAction({
          entityId: business.id,

          fieldToUpdate: fourthBalanceFinancial,
          year: subMonths(latestBalanceDate, 2).getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: fourthBalance,
          userId,
          startDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 2)).toISOString(),
          endDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 2)).toISOString(),
        }),
        ...getFinancialPayloadAction({
          entityId: business.id,

          fieldToUpdate: thirdBalanceFinancial,
          year: subMonths(latestBalanceDate, 3).getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: thirdBalance,
          userId,
          startDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 3)).toISOString(),
          endDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 3)).toISOString(),
        }),
        ...getFinancialPayloadAction({
          entityId: business.id,

          fieldToUpdate: secondBalanceFinancial,
          year: subMonths(latestBalanceDate, 4).getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: secondBalance,
          userId,
          startDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 4)).toISOString(),
          endDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 4)).toISOString(),
        }),
        ...getFinancialPayloadAction({
          entityId: business.id,

          fieldToUpdate: firstBalanceFinancial,
          year: subMonths(latestBalanceDate, 5).getFullYear(),
          fieldName: balanceProperty.financialAttribute ?? balanceProperty.fieldName,
          value: firstBalance,
          userId,
          startDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 5)).toISOString(),
          endDate: getUTCDateFromZonedTime(subMonths(latestBalanceDate, 5)).toISOString(),
        }),
      ];
      await submitFinancialChanges(financialPayload);
    },
    [
      userId,
      balanceProperty,
      latestBalanceDate,
      business.id,
      latestBalanceFinancial,
      fifthBalanceFinancial,
      fourthBalanceFinancial,
      thirdBalanceFinancial,
      secondBalanceFinancial,
      firstBalanceFinancial,
      submitFinancialChanges,
    ],
  );

  return (
    <Flex flexDirection={'column'} gap={8}>
      <Flex flexDirection={'column'} gap={4}>
        <Formik initialValues={initialValuesForEntityType} onSubmit={onSubmitTaxEntityType} enableReinitialize>
          {() => (
            <>
              <FormikAutoSave debounceMs={100} />
              <FormikPicker
                name={'taxEntityType'}
                label={taxEntityTypeProperty?.displayName ?? 'Tax Entity Type'}
                values={taxEntityTypes}
              />
            </>
          )}
        </Formik>
        <Divider orientation={'horizontal'} />
        <Text variant={'body1'} color={'grey.500'}>
          {'Current Liquidity Ratio'}
        </Text>
        <Formik initialValues={initialValuesForReferenceDate} onSubmit={onSubmitReferenceDate} enableReinitialize>
          {({ values: { balanceReferenceDate } }) => (
            <>
              <FormikAutoSave debounceMs={100} />
              <FormikDatePicker
                name={'balanceReferenceDate'}
                views={['year', 'month']}
                label={'Evaluation Period End Month'}
                value={balanceReferenceDate}
                helperText={`Monthly balance will be evaluated from ${format(
                  subMonths(new Date(balanceReferenceDate), 5),
                  'LLL yyyy',
                )} to ${format(new Date(balanceReferenceDate), 'LLL yyyy')}`}
              />
            </>
          )}
        </Formik>
        <Formik initialValues={initialValuesForBalances} onSubmit={onSubmit} enableReinitialize>
          {({ dirty, isValid, values: { firstBalance, secondBalance, thirdBalance, fourthBalance, fifthBalance }, handleSubmit }) => (
            <ConfirmLeave shouldBlock={dirty}>
              <Flex flexDirection={'column'} gap={4}>
                <LoadingWrapper loading={savingReferenceDate} disabled={savingReferenceDate}>
                  <Grid columns={2}>
                    <FormikMoneyInputField name={'latestBalance'} label={`${format(latestBalanceDate, 'LLL yyyy')} Balance`} fullWidth />
                    <FormikMoneyInputField
                      name={'fifthBalance'}
                      value={fifthBalance}
                      label={`${format(subMonths(latestBalanceDate, 1), 'LLL yyyy')} Balance`}
                      fullWidth
                    />
                    <FormikMoneyInputField
                      name={'fourthBalance'}
                      value={fourthBalance}
                      label={`${format(subMonths(latestBalanceDate, 2), 'LLL yyyy')} Balance`}
                      fullWidth
                    />
                    <FormikMoneyInputField
                      name={'thirdBalance'}
                      value={thirdBalance}
                      label={`${format(subMonths(latestBalanceDate, 3), 'LLL yyyy')} Balance`}
                      fullWidth
                    />
                    <FormikMoneyInputField
                      name={'secondBalance'}
                      value={secondBalance}
                      label={`${format(subMonths(latestBalanceDate, 4), 'LLL yyyy')} Balance`}
                      fullWidth
                    />
                    <FormikMoneyInputField
                      name={'firstBalance'}
                      value={firstBalance}
                      label={`${format(subMonths(latestBalanceDate, 5), 'LLL yyyy')} Balance`}
                      fullWidth
                    />
                  </Grid>
                </LoadingWrapper>
                {autoSaveEnabled ? (
                  <FormikAutoSave />
                ) : (
                  <SaveFormButton loading={savingForm} submit={handleSubmit} disabled={!dirty || !isValid} />
                )}
              </Flex>
            </ConfirmLeave>
          )}
        </Formik>
      </Flex>
      <LiquiditySegment averageBalance={averageBalance} business={business} application={application} />
    </Flex>
  );
};
