import type { FC } from 'react';
import React from 'react';
import type { ChipProps } from '@mui/material';
import { Chip, Skeleton } from '@mui/material';
import { colors, Flex, greenPalette, greyPalette, Grid, redPalette, Text } from '@lama/design-system';
import { capitalCase } from 'change-case-all';
import type { EvaluatedCondition, ProductApiModel } from '@lama/contracts';
import styled from 'styled-components';
import { ConditionResultIcon } from '../../shared/components/conditions/conditionIcons';
import type { SimulationResult } from './ProductSimulationList';

const CardWrapper = styled(Flex)`
  &:hover {
    box-shadow: 0px 8px 32px 0px #3800641f;
  }
  border: 1px solid ${greyPalette[300]};
  border-radius: 10px;
  min-height: 200px;
  width: 400px;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

const simulationResultToChipStyle: Record<SimulationResult, ChipProps['sx']> = {
  pass: {
    bgcolor: greenPalette[50],
    color: colors.success.light,
    borderColor: colors.success.light,
    width: '50px',
  },
  fail: {
    bgcolor: redPalette[50],
    color: redPalette[400],
    borderColor: redPalette[400],
    width: '50px',
  },
  pending: {
    bgcolor: greyPalette[50],
    color: greyPalette[400],
    borderColor: greyPalette[400],
    width: '50px',
  },
};

const ProductSimulationCardSkeleton: FC = () => (
  <CardWrapper>
    <Skeleton variant={'text'} width={150} height={50} />
    <Grid columns={2}>
      <Flex gap={2} alignItems={'center'}>
        <Skeleton variant={'rectangular'} width={16} height={16} />
        <Skeleton variant={'text'} width={100} />
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <Skeleton variant={'rectangular'} width={16} height={16} />
        <Skeleton variant={'text'} width={100} />
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <Skeleton variant={'rectangular'} width={16} height={16} />
        <Skeleton variant={'text'} width={100} />
      </Flex>
    </Grid>
  </CardWrapper>
);

export const ProductSimulationCard: FC<{
  product: ProductApiModel;
  evaluatedConditions: EvaluatedCondition[];
  simulationResult: SimulationResult;
  loading: boolean;
}> = ({ product, evaluatedConditions, simulationResult, loading }) =>
  loading ? (
    <ProductSimulationCardSkeleton />
  ) : (
    <CardWrapper>
      <Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
        <Text variant={'h6'} color={'text.primary'}>
          {product?.prettyName}
        </Text>
        <Chip
          label={capitalCase(simulationResult)}
          size={'small'}
          variant={'outlined'}
          sx={{ minWidth: '80px', ...simulationResultToChipStyle[simulationResult] }}
        />
      </Flex>
      {evaluatedConditions.length ? (
        <Grid columns={2}>
          {evaluatedConditions.map(({ condition, reason }) => (
            <Flex gap={2} alignItems={'center'} key={condition.id}>
              <Flex width={'16px'} height={'16px'}>
                <ConditionResultIcon reason={reason} size={'16px'} />
              </Flex>
              <Text>{condition.name}</Text>
            </Flex>
          ))}
        </Grid>
      ) : (
        <Text variant={'body2'} color={greyPalette[500]}>
          {'No conditions are configured for this product.'}
        </Text>
      )}
    </CardWrapper>
  );
