import type { PixelSuffix } from '@lama/design-system';
import React from 'react';
import type { FC } from 'react';

export const NumberIcon: FC<{ color?: string; size?: PixelSuffix }> = ({ color = '#616161', size }) => (
  <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M11.4414 7.02903C11.8033 7.13759 12.0086 7.51898 11.9001 7.88086L11.3433 9.7366H14.4756L15.1502 7.48771C15.2589 7.12582 15.6402 6.92047 16.0021 7.02903C16.364 7.13759 16.5693 7.51898 16.4608 7.88086L15.904 9.7366H17.6298C18.0076 9.7366 18.3139 10.0429 18.3139 10.4207C18.3139 10.7985 18.0076 11.1048 17.6298 11.1048H15.4936L14.8095 13.3852H16.7177C17.0955 13.3852 17.4018 13.6915 17.4018 14.0693C17.4018 14.4471 17.0955 14.7534 16.7177 14.7534H14.399L13.7244 17.0023C13.6157 17.3642 13.2344 17.5695 12.8725 17.461C12.5106 17.3523 12.3053 16.971 12.4138 16.6091L12.9706 14.7534H9.83831L9.16364 17.0023C9.05508 17.3642 8.67369 17.5695 8.31181 17.461C7.94992 17.3523 7.74457 16.971 7.85313 16.6091L8.40986 14.7534H6.68411C6.30629 14.7534 6 14.4471 6 14.0693C6 13.6915 6.30629 13.3852 6.68411 13.3852H8.82032L9.50443 11.1048H7.59625C7.21843 11.1048 6.91214 10.7985 6.91214 10.4207C6.91214 10.0429 7.21843 9.7366 7.59625 9.7366H9.91489L10.5895 7.48771C10.6982 7.12582 11.0795 6.92047 11.4414 7.02903ZM10.9329 11.1048H14.0652L13.381 13.3852H10.2488L10.9329 11.1048Z'
      }
      fill={color}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M11.9519 3C10.0194 2.99999 8.50481 2.99998 7.32311 3.15886C6.11356 3.32148 5.15912 3.66083 4.40998 4.40998C3.66083 5.15912 3.32148 6.11356 3.15886 7.32311C2.99998 8.50481 2.99999 10.0194 3 11.9519V12.0481C2.99999 13.9807 2.99998 15.4952 3.15886 16.6769C3.32148 17.8864 3.66083 18.8409 4.40998 19.5901C5.15912 20.3392 6.11356 20.6785 7.32311 20.8412C8.50481 21 10.0194 21 11.9519 21H12.0481C13.9807 21 15.4952 21 16.6769 20.8412C17.8864 20.6785 18.8409 20.3392 19.5901 19.5901C20.3392 18.8409 20.6785 17.8864 20.8412 16.6769C21 15.4952 21 13.9807 21 12.0481V11.9519C21 10.0194 21 8.50481 20.8412 7.32311C20.6785 6.11356 20.3392 5.15912 19.5901 4.40998C18.8409 3.66083 17.8864 3.32148 16.6769 3.15886C15.4952 2.99998 13.9807 2.99999 12.0481 3H11.9519ZM5.29797 5.29797C5.77489 4.82105 6.42014 4.54737 7.49044 4.40347C8.57879 4.25715 10.0089 4.25581 12 4.25581C13.9911 4.25581 15.4212 4.25715 16.5095 4.40347C17.5798 4.54737 18.2251 4.82105 18.702 5.29797C19.179 5.77489 19.4527 6.42014 19.5965 7.49044C19.7428 8.57879 19.7442 10.0089 19.7442 12C19.7442 13.9911 19.7428 15.4212 19.5965 16.5095C19.4527 17.5798 19.179 18.2251 18.702 18.702C18.2251 19.179 17.5798 19.4527 16.5095 19.5965C15.4212 19.7428 13.9911 19.7442 12 19.7442C10.0089 19.7442 8.57879 19.7428 7.49044 19.5965C6.42014 19.4527 5.77489 19.179 5.29797 18.702C4.82105 18.2251 4.54737 17.5798 4.40347 16.5095C4.25715 15.4212 4.25581 13.9911 4.25581 12C4.25581 10.0089 4.25715 8.57879 4.40347 7.49044C4.54737 6.42014 4.82105 5.77489 5.29797 5.29797Z'
      }
      fill={color}
    />
  </svg>
);
