import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { applicationBorrowingRelatedBusinessesSelector } from '@lama/data-formatters';
import { add } from 'date-fns';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getRateType, FIXED_RATE_TYPE } from '../../../../shared/LoanTerms/termsUtils';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsAdditionalDetailsSegment: FC = () => {
  const { opportunity, product, application } = useContext(ApplicationContext);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const borrowersNaics = applicationBorrowingRelatedBusinessesSelector(application)
      .map((b) => b.business.naicsCodes?.join(', '))
      .join(', ');
    const rateType = getRateType(opportunity.terms?.rate);

    return [
      {
        label: 'Loan Type',
        values: opportunity.loanType,
      },
      {
        label: 'RegO',
        values: application.regO,
      },
      {
        label: 'Employee Loan',
        values: application.employeeLoan,
      },
      {
        label: 'Borrowers NAICS Code',
        values: borrowersNaics,
      },
      {
        label: 'Origination Date',
        values: opportunity.originationDate,
        type: 'date',
      },
      {
        label: 'Maturity Date',
        values: opportunity.originationDate
          ? add(new Date(opportunity.originationDate), { months: opportunity.terms?.termLength ?? 0 }).toISOString()
          : null,
        type: 'date',
      },
      {
        label: 'Review Year',
        values: opportunity.referenceYear,
        type: 'string',
      },
      {
        label: 'SBA Guaranty Percentage',
        values: opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
        type: 'percent',
      },
      {
        label: 'Loan Sold on Secondary Market',
        values: opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
        type: 'boolean',
      },
      {
        label: rateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread',
        values: opportunity.terms?.rate?.spread,
      },
      {
        label: 'Policy Exception',
        values: opportunity.underwriting?.isPolicyException,
      },
      {
        label: 'Annual Servicing Period',
        values: opportunity.annualServicingPeriod,
      },
      {
        label: 'Product',
        values: product.prettyName,
      },
    ];
  }, [
    application,
    opportunity.terms?.rate,
    opportunity.terms?.termLength,
    opportunity.loanType,
    opportunity.originationDate,
    opportunity.referenceYear,
    opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
    opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
    opportunity.underwriting?.isPolicyException,
    opportunity.annualServicingPeriod,
    product.prettyName,
  ]);

  return (
    <SegmentContainer title={'Additional Details'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
