import React, { useCallback } from 'react';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';

interface SimpleTableProps<T extends Record<string, any>> {
  columns: MRT_ColumnDef<T>[];
  data: T[];
}

export const SimpleTable = <T extends Record<string, any>>({ columns, data }: SimpleTableProps<T>) => {
  const bodyCellProps = useCallback(
    () => ({
      sx: {
        padding: 2,
        border: 'none',
      },
      align: 'left' as const,
    }),
    [],
  );

  return (
    <MaterialReactTable
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableSorting={false}
      muiTableHeadRowProps={{
        sx: {
          backgroundColor: '#F5F5F5',
          boxShadow: 'none',
        },
      }}
      muiTableHeadCellProps={{
        align: 'left',
        sx: {
          padding: 2,
        },
      }}
      muiTableBodyCellProps={bodyCellProps}
      muiTableContainerProps={{
        sx: {
          boxShadow: 'none',
          height: '100%',
          border: 'solid #0000001F',
          borderWidth: '1px 1px 1px 1px',
          borderRadius: '4px',
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          border: 'none',
          height: '100%',
        },
      }}
      columns={columns}
      data={data}
    />
  );
};
