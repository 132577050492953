import type { FC } from 'react';
import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

export const BackLink: FC<{ to: string; title: string; state?: Record<string, any> }> = ({ to, title, state }) => {
  const theme = useTheme();
  return (
    <Box>
      <Link
        to={to}
        style={{ color: theme.palette.text.secondary, textDecoration: 'inherit', display: 'inline-block', verticalAlign: 'top' }}
        state={state || {}}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <ChevronLeft sx={{ color: theme.palette.text.secondary }} />
          <Typography variant={'body2'}>{title}</Typography>
        </Stack>
      </Link>
    </Box>
  );
};
