import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { relatedBusinessesByRelationsSelector } from '@lama/data-formatters';
import type { ClosingTaskComponentProps } from '../closingTaskComponentProps.js';
import { ApplicationContext } from '../../../../ApplicationContext.js';
import { useApplicationDocumentsQuery } from '../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery.js';
import { MiddeskBusinessTaskComponent } from './MiddeskBusinessTaskComponent.js';

export const MiddeskPullDocumentsTaskComponent: FC<ClosingTaskComponentProps> = (props) => {
  const { task } = props;
  const { application } = useContext(ApplicationContext);
  const { data: relatedDocuments } = useApplicationDocumentsQuery({ applicationId: application.id });

  const taskDocuments = useMemo(
    () => relatedDocuments?.filter((document) => task.documentIds?.includes(document.id)) ?? [],
    [relatedDocuments, task.documentIds],
  );

  const relevantBusinesses = useMemo(() => relatedBusinessesByRelationsSelector(application, ['borrower', 'guarantor']), [application]);

  return (
    <Flex flexDirection={'column'} gap={6}>
      {relevantBusinesses.map(({ business }) => (
        <MiddeskBusinessTaskComponent key={business.id} business={business} taskDocuments={taskDocuments} {...props} />
      ))}
    </Flex>
  );
};
