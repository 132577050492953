import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { useAuth0 } from '@auth0/auth0-react';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

interface InviteToApplicationMutationParams {
  opportunityId?: string;
  applicationId: string;
}

interface InviteToApplicationParams {
  personId: string;
  skipInviteEmail?: boolean;
}

export const useInviteToApplicationMutation = (
  { applicationId, opportunityId }: InviteToApplicationMutationParams,
  options?: UseMutationOptions<{ skipInviteEmail?: boolean }, unknown, InviteToApplicationParams>,
) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ personId, skipInviteEmail }: InviteToApplicationParams) => {
      const token = await getAccessTokenSilently();

      await applicationServiceClient.invitePersonToApplication({ applicationId, personId, skipInviteEmail }, token);

      return { skipInviteEmail };
    },
    onSuccess: async ({ skipInviteEmail }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunity', opportunityId],
        }),
      ]);

      if (!skipInviteEmail) {
        displayToast('Invitation sent', 'success', {
          toastId: 'inviteToApplication',
        });
      }
    },
    onError: () => {
      displayToast('Something went wrong while inviting to the application. Please contact support.', 'error', {
        toastId: 'inviteToApplication',
      });
    },
    ...options,
  });
};
