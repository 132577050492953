import type { FunctionComponent } from 'react';
import React from 'react';
import type { UseCollapseInput } from 'react-collapsed';
import { useCollapse } from 'react-collapsed';

import { Flex } from '../Flex';

export interface CollapseProps extends UseCollapseInput {
  children: React.ReactNode;
  /**
   * If true, the collapsible element is expanded.
   */
  expanded?: boolean;
  /**
   * If true, the collapsible element is expanded when it initially mounts.
   * @default false
   */
  defaultExpanded?: boolean;
  /**
   * Sets the height (Number) to which the elements collapses.
   * @default 0
   */
  collapsedHeight?: number;
  /**
   * Sets the transition-timing-function of the animation.
   * @default 'cubic-bezier(0.4, 0, 0.2, 1)'
   */
  easing?: string;
  /**
   * Sets the duration of the animation. If undefined, a 'natural' duration is
   * calculated based on the distance of the animation.
   */
  duration?: number;
  /**
   * If true, the animation is disabled. Overrides the hooks own logic for
   * disabling the animation according to reduced motion preferences.
   */
  hasDisabledAnimation?: boolean;
  /**
   * Handler called at each stage of the animation.
   */
  onTransitionStateChange?: (state: 'collapseEnd' | 'collapseStart' | 'collapsing' | 'expandEnd' | 'expanding' | 'expandStart') => void;
  /**
   * Unique identifier used to for associating elements appropriately for accessibility.
   */
  id?: number | string;
}

export const Collapse: FunctionComponent<CollapseProps> = ({ children, expanded, ...props }) => {
  const { getCollapseProps } = useCollapse({ ...props, isExpanded: expanded });

  return (
    <Flex flexDirection={'column'} {...getCollapseProps()}>
      {children}
    </Flex>
  );
};

Collapse.displayName = 'Collapse';
