import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { MiddeskReportTracking, RequestDocumentsBody } from '@lama/verify-business-service-client';
import { useAuth0 } from '@auth0/auth0-react';
import { verifyBusinessServiceClient } from '../../../../../../../shared/clients/verifyBusinessServiceClient';

export const useRequestMiddeskDocumentsMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestDocumentsBody: RequestDocumentsBody) => {
      const token = await getAccessTokenSilently();

      await verifyBusinessServiceClient.requestDocuments(requestDocumentsBody, token);
      return requestDocumentsBody;
    },
    onMutate: async (requestDocumentsBody: RequestDocumentsBody) => {
      const { businessId, relatedClosingTaskId, documentType } = requestDocumentsBody;

      const previousBusinessMiddeskTracking: MiddeskReportTracking | undefined = queryClient.getQueryData([
        'middeskReportTracking',
        businessId,
      ]);

      const otherOrders =
        previousBusinessMiddeskTracking?.ordersTracking?.filter((order: any) => order.relatedClosingTaskId !== relatedClosingTaskId) ?? [];

      const updatedBusinessMiddeskTracking = {
        ...previousBusinessMiddeskTracking,
        ordersTracking: [
          ...otherOrders,
          {
            orderType: documentType === 'liens' ? 'liens' : 'documents',
            relatedClosingTaskId,
            searchOnly: false,
            status: 'pending',
          },
        ],
      };

      queryClient.setQueryData(['middeskReportTracking', businessId], () => updatedBusinessMiddeskTracking);
    },
    onError: () => {
      displayToast('Something went wrong. Please contact support.', 'error');
    },
  });
};
