import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const ProductsIcon: FC<{ color: string }> = ({ color }) => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_13865_167928'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_13865_167928)'}>
        <path
          d={
            'M3.3 20.375C2.8 20.375 2.375 20.2 2.025 19.85C1.675 19.5 1.5 19.075 1.5 18.575V7.8C1.5 7.58333 1.57067 7.404 1.712 7.262C1.854 7.12067 2.03333 7.05 2.25 7.05C2.46667 7.05 2.646 7.12067 2.788 7.262C2.92933 7.404 3 7.58333 3 7.8V18.575C3 18.6583 3.02933 18.7293 3.088 18.788C3.146 18.846 3.21667 18.875 3.3 18.875H18.775C18.9917 18.875 19.1707 18.95 19.312 19.1C19.454 19.25 19.525 19.425 19.525 19.625C19.525 19.8417 19.454 20.021 19.312 20.163C19.1707 20.3043 18.9917 20.375 18.775 20.375H3.3ZM6.8 16.875C6.3 16.875 5.875 16.7 5.525 16.35C5.175 16 5 15.575 5 15.075V4.3C5 3.8 5.175 3.375 5.525 3.025C5.875 2.675 6.3 2.5 6.8 2.5H11.25C11.5 2.5 11.7333 2.54567 11.95 2.637C12.1667 2.729 12.3583 2.85833 12.525 3.025L13.625 4.125H20.7C21.2 4.125 21.625 4.3 21.975 4.65C22.325 5 22.5 5.425 22.5 5.925V15.075C22.5 15.575 22.325 16 21.975 16.35C21.625 16.7 21.2 16.875 20.7 16.875H6.8ZM6.8 15.375H20.7C20.7833 15.375 20.8543 15.3457 20.913 15.287C20.971 15.229 21 15.1583 21 15.075V5.925C21 5.84167 20.971 5.771 20.913 5.713C20.8543 5.65433 20.7833 5.625 20.7 5.625H13L11.475 4.075C11.4417 4.05833 11.4043 4.04167 11.363 4.025C11.321 4.00833 11.2833 4 11.25 4H6.8C6.71667 4 6.646 4.029 6.588 4.087C6.52933 4.14567 6.5 4.21667 6.5 4.3V15.075C6.5 15.1583 6.52933 15.229 6.588 15.287C6.646 15.3457 6.71667 15.375 6.8 15.375Z'
          }
          fill={color}
        />
      </g>
    </svg>
  </Box>
);
