/* eslint-disable react/jsx-no-bind */
import { LoadingPage, PropertyFormikInput, Tooltip } from '@lama/app-components';
import type { ApplicationStatus, DecisionReason } from '@lama/contracts';
import { Button, Flex, Modal, ModalContent, Text } from '@lama/design-system';
import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import * as yup from 'yup';
import { orderBy, uniq } from 'lodash-es';
import { personFullName } from '@lama/data-formatters';
import { inactiveApplicationStatuses } from '@lama/clients';
import { EmailOutlined } from '@mui/icons-material';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateDecisionMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateDecisionMutation';
import { useUserQuery } from '../../../../shared/hooks/react-query/user/useUserQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { defaultDecisionReasons } from '../../../../shared/components/DecisionDialog/defaultDecisionReasons';
import { useCreateDecisionRecommendationMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateDecisionRecommendationMutation';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useSendRejectionEmailMutation } from '../../../../shared/hooks/react-query/useSendRejectionEmailMutation';
import { useGetPartnerQuery } from '../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { useUserPartner } from '../../../../shared/hooks/useCurrentPartner';
import { FormikDecisionReasonsMultiPicker } from './FormikDecisionReasonsMultiPicker';
import { FormikDecisionPicker } from './FormikDecisionPicker';
import DeclineSvg from './assets/DeclineSvg.svg';

interface DecisionFormValues {
  decision: ApplicationStatus;
  reasons: DecisionReason[];
  otherReason: string;
  comments: string;
}

export const dismissApplicationStatuses: ApplicationStatus[] = inactiveApplicationStatuses.filter((status) => status !== 'Closed');

const DismissApplicationForm: FC<{ onDone: (status: ApplicationStatus) => void; mode: 'decision' | 'recommendation' }> = ({
  onDone,
  mode,
}) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId, underwriting },
    product,
  } = useContext(ApplicationContext);
  const { userId, userData: user } = useContext(UserDetailsContext);
  const { mutateAsync: createDecisionRecommendation, isPending: creatingDecisionRecommendation } =
    useCreateDecisionRecommendationMutation(opportunityId);
  const { mutateAsync: createDecision, isPending: creatingDecision } = useCreateDecisionMutation(opportunityId);
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(applicationId, opportunityId);

  const dismissRecommendation = useMemo(
    () =>
      mode === 'decision'
        ? orderBy(underwriting?.decisionRecommendations ?? [], ({ createdAt }) => createdAt, ['desc']).find(({ recommendation }) =>
            dismissApplicationStatuses.includes(recommendation),
          )
        : null,
    [mode, underwriting?.decisionRecommendations],
  );

  const { data: recommendationUser, isPending: fetchingRecommendingUser } = useUserQuery(dismissRecommendation?.userId);

  const recommendationIndication = useMemo(() => {
    if (!dismissRecommendation) {
      return null;
    }

    const recommendationUserName =
      recommendationUser?.firstName && recommendationUser?.lastName
        ? personFullName({ firstName: recommendationUser.firstName, lastName: recommendationUser.lastName })
        : '';

    return `This application was requested to be dismissed${recommendationUserName ? ` by ${recommendationUserName}` : ''}.`;
  }, [dismissRecommendation, recommendationUser]);

  const initialValues = useMemo<DecisionFormValues>(
    () => ({
      decision: dismissRecommendation?.recommendation ?? ('' as ApplicationStatus),
      reasons: dismissRecommendation?.reasons ?? [],
      otherReason: dismissRecommendation?.reasons?.find((reason) => reason.id === 'other')?.text ?? '',
      comments: dismissRecommendation?.comment ?? '',
    }),
    [dismissRecommendation?.comment, dismissRecommendation?.reasons, dismissRecommendation?.recommendation],
  );

  const actionText = useMemo(() => (mode === 'decision' ? 'Make Decision' : 'Make Request'), [mode]);

  const statusesWithReasons = useMemo(() => {
    const decisionReasons = product.decisionReasons ?? defaultDecisionReasons;
    return uniq(decisionReasons.flatMap((reason) => reason.relevantStatuses));
  }, [product.decisionReasons]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string(),
        decision: yup.string().required('Decision is required'),
        reasons: yup
          .array()
          .when('decision', ([decision]: ApplicationStatus[], schema: any) =>
            decision && statusesWithReasons.includes(decision) ? schema.min(1, 'At least one reason is required').required() : schema,
          ),
        otherReason: yup
          .string()
          .when('reasons', ([reasons]: DecisionReason[][], schema: any) =>
            reasons?.some((reason) => reason.id === 'other') ? schema.required('Other reason is required') : schema,
          ),
      }),
    [statusesWithReasons],
  );

  const onSubmit = useCallback(
    async (values: DecisionFormValues) => {
      if (!user) {
        return;
      }

      if (mode === 'decision') {
        await createDecision({
          decision: values.decision,
          text: values.comments,
          reasons: values.reasons.map((reason) => ({
            ...reason,
            text: reason.id === 'other' ? `Other - ${values.otherReason}` : reason.text,
          })),
          roles: user?.roles ?? [],
          userId: userId ?? '',
          id: uuid4(),
        });
      } else {
        await createDecisionRecommendation({
          recommendation: values.decision,
          comment: values.comments,
          reasons: values.reasons.map((reason) => ({
            ...reason,
            text: reason.id === 'other' ? `Other - ${values.otherReason}` : reason.text,
          })),
          userRoles: user?.roles ?? [],
          userId: userId ?? '',
          id: uuid4(),
        });
        await updateApplication({
          updateApplicationPayload: {
            status: 'PendingLost',
          },
        });
      }

      onDone(values.decision);
    },
    [createDecision, createDecisionRecommendation, mode, onDone, updateApplication, user, userId],
  );

  const isLoading = useMemo(() => fetchingRecommendingUser, [fetchingRecommendingUser]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} validateOnChange enableReinitialize>
      {({ values, handleSubmit }) => (
        <Flex flexDirection={'column'} gap={8} width={'100%'} justifyContent={'center'}>
          {recommendationIndication ? <Text variant={'body2'}>{recommendationIndication}</Text> : null}
          <FormikDecisionPicker
            label={mode === 'decision' ? 'Decision' : 'Request'}
            statusFieldName={'decision'}
            reasonsFieldName={'reasons'}
            reasonsOtherFieldName={'otherReason'}
          />
          <FormikDecisionReasonsMultiPicker product={product} reasonsFieldName={'reasons'} statusFieldName={'decision'} />
          {values.reasons.some((reason) => reason.id === 'other') ? (
            <PropertyFormikInput
              name={'otherReason'}
              label={'Explain the "Other" reason'}
              variant={'outlined'}
              helperText={'Note: This will be shared with the applicant'}
              required
              multiline
              rows={4}
            />
          ) : (
            <PropertyFormikInput name={'comments'} label={'Comments'} variant={'outlined'} multiline rows={4} />
          )}
          <Flex justifyContent={'flex-end'} alignItems={'center'}>
            <Button
              size={'l'}
              variant={'primary'}
              onClick={() => {
                handleSubmit();
              }}
              loading={creatingDecision || creatingDecisionRecommendation || updatingApplication}
            >
              {actionText}
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

const DismissSummary: FC<{ onClose: () => void; status: ApplicationStatus }> = ({ status }) => {
  const partner = useUserPartner();
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const [emailSent, setEmailSent] = useState(false);

  const { mutateAsync: sendRejectionEmail, isPending: loading } = useSendRejectionEmailMutation(application.id, opportunityId);

  const sendMessageToApplicant = useCallback(async () => {
    await sendRejectionEmail({});
    setEmailSent(true);
  }, [sendRejectionEmail, setEmailSent]);

  return (
    <Flex gap={8} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
      <Flex flexDirection={'column'} gap={4} alignItems={'center'} width={'100%'}>
        <DeclineSvg />
        <Text variant={'body1'} textAlign={'center'}>
          {`You have marked this application as ${partner.applicationStatusDisplayNameMapping[status]}.`}
        </Text>
      </Flex>
      <Flex justifyContent={'center'} alignItems={'center'}>
        <Tooltip title={'Send an email message with a default adverse selection template'} placement={'top-start'}>
          <Button color={'primary'} startIcon={<EmailOutlined />} onClick={sendMessageToApplicant} loading={loading} disabled={emailSent}>
            {'Message Applicant'}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export const DismissApplicationDialog: FC<{ open: boolean; onClose: () => void; mode?: 'decision' | 'recommendation' | null }> = ({
  open,
  onClose,
  mode,
}) => {
  const {
    opportunity: { partnerId },
  } = useContext(ApplicationContext);
  const [activeStep, setActiveStep] = useState<'form' | 'summary'>('form');
  const [dismissStatus, setDismissStatus] = useState<ApplicationStatus | null>(null);
  const { data: partner, isPending: loadingPartner } = useGetPartnerQuery(partnerId);

  const onDismissDone = useCallback(
    (status: ApplicationStatus) => {
      if (mode === 'decision' && partner?.dismissSummaryRelevantStatuses?.includes(status)) {
        setDismissStatus(status);
        setActiveStep('summary');
        return;
      }

      onClose();
    },
    [mode, onClose, partner?.dismissSummaryRelevantStatuses],
  );

  const currentStep = useMemo(() => {
    if (!mode) {
      return null;
    }

    if (loadingPartner) {
      return <LoadingPage />;
    }

    switch (activeStep) {
      case 'form': {
        return <DismissApplicationForm onDone={onDismissDone} mode={mode} />;
      }
      case 'summary': {
        if (dismissStatus) {
          return <DismissSummary onClose={onClose} status={dismissStatus} />;
        }
      }
      default: {
        return null;
      }
    }
  }, [activeStep, dismissStatus, loadingPartner, mode, onClose, onDismissDone]);

  const title = useMemo(() => (mode === 'decision' ? 'Dismiss Application' : 'Request to Dismiss'), [mode]);

  return (
    <Modal open={open} onClose={onClose} title={title} data-testid={'dismiss-application-dialog'} alignTitle={'center'} fullWidth>
      <ModalContent>{currentStep}</ModalContent>
    </Modal>
  );
};
