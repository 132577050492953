import type { DealApiModel } from '@lama/contracts';

const getDealNumber = (id: string) => {
  const number = Number.parseInt(id.replaceAll('-', ''), 16) % 1000;
  return number === 0 ? 1 : number;
};

export const getDealName = ({ name, id }: DealApiModel) => {
  if (name) {
    return name;
  }

  const dealNumber = getDealNumber(id);
  return `Deal No.${dealNumber}`;
};
