import React, { useContext, useMemo } from 'react';
import { Text } from '@lama/design-system';
import type { FC } from 'react';
import { applicationBorrowingRelatedBusinessesSelector, applicationGuarantorsSelector, personFullName } from '@lama/data-formatters';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

const headers = [
  { text: 'Relationship', width: '20%' },
  { text: 'Covenant Type', width: '14%' },
  { text: 'Frequency', width: '14%' },
  { text: 'Doc Date', width: '14%' },
  { text: 'Due Date', width: '14%' },
  { text: 'Status', width: '14%' },
];

export const GrasshopperReportingCovenantsTable: FC = () => {
  const { application } = useContext(ApplicationContext);

  const covenantsRows = useMemo<CellContent[][]>(() => {
    const borrowingBusinesses = applicationBorrowingRelatedBusinessesSelector(application);

    const businessTaxReturnRows = borrowingBusinesses.map((business) => [
      { value: business.business.legalName },
      { value: 'Business Tax Return' },
      { value: 'Annually' },
      { value: '12/31/2024' },
      { value: '5/15/2025' },
      { value: '-' },
    ]);

    const personGuarantors = applicationGuarantorsSelector(application);
    const personalTaxReturnRows = personGuarantors.map((person) => [
      { value: personFullName(person) },
      { value: 'Personal Tax Return' },
      { value: 'Annually' },
      { value: '12/31/2024' },
      { value: '5/15/2025' },
      { value: '-' },
    ]);

    const personFinancialStatementRows = personGuarantors.map((person) => [
      { value: personFullName(person) },
      { value: 'Personal Financial Statement' },
      { value: 'Annually' },
      { value: '8/24/2024' },
      { value: '8/24/2025' },
      { value: '-' },
    ]);

    return [...businessTaxReturnRows, ...personalTaxReturnRows, ...personFinancialStatementRows];
  }, [application]);
  return (
    <SegmentContainer title={'Reporting Covenants'} bordered={false}>
      <CreditMemoTable headers={headers} rows={covenantsRows} />
      <Text variant={'body3'} color={'secondary'}>
        {'Description: Verify annually that RE insurance has been paid on 5420 Interstate Drive, Shreveport, Louisiana 71109.'}
      </Text>
    </SegmentContainer>
  );
};
