import { Switch } from '@lama/design-system';
import type { PartnerRequirement } from '@lama/contracts';
import { Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import React, { useCallback } from 'react';

interface PartnerRequirementSettingsCardProps {
  requirement: PartnerRequirement;
  requirementActive: boolean;
  onCheck: (requirement: PartnerRequirement) => void;
}

export const RequirementSettingsCard: FC<PartnerRequirementSettingsCardProps> = ({ requirement, requirementActive, onCheck }) => {
  const theme = useTheme();

  const handleCheck = useCallback(() => {
    onCheck(requirement);
  }, [onCheck, requirement]);

  // we handle the click event on the stack instead of the switch, so the switch should not do anything
  const onSwitchChange = useCallback(() => {}, []);

  if (!requirement) {
    return null;
  }

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={4}
      px={4}
      py={2}
      onClick={handleCheck}
      sx={{ backgroundColor: requirementActive ? 'white' : grey[50], border: '1px solid #E0E0E0', borderRadius: '8px', cursor: 'pointer' }}
    >
      <Stack gap={1} flex={3}>
        <Typography variant={'h6'} color={requirementActive ? 'black' : 'text.disabled'}>
          {requirement.name}
        </Typography>
        {requirement?.description ? (
          <Typography variant={'body2'} color={requirementActive ? 'text.secondary' : 'text.disabled'}>
            {requirement.description}
          </Typography>
        ) : null}
      </Stack>
      <Switch
        onChange={onSwitchChange}
        checked={requirementActive}
        onColor={theme.palette.success.light}
        offColor={grey[400]}
        width={36}
        height={18}
        handleDiameter={12}
      />
    </Stack>
  );
};
