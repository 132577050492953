import type { Debt } from '@lama/contracts';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { compact } from 'lodash-es';
import type { CellContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { RefinanceIcon } from './RefinanceIcon';

const headers = [
  { text: 'Creditor Name/Address' },
  { text: 'Original Date', width: 120 },
  { text: 'Original Amount' },
  { text: 'Present Balance' },
  { text: 'Interest Rate' },
  { text: 'Monthly Payment' },
  { text: 'Maturity Date', width: 120 },
  { text: 'Collateral Security' },
  { text: 'Refinance Debt' },
];

export const DebtScheduleTableSegment: FC<{ debt: Debt[] }> = ({ debt }) => {
  const rows = useMemo(
    () =>
      debt.map<CellContent[]>((item) =>
        compact([
          { value: `${item.creditorName ? `${item.creditorName},` : ''} ${item.creditorAddress ?? ''}` },
          { value: item.originalDate, type: 'date' },
          { value: item.originalAmount, type: 'currency' },
          { value: item.amount, type: 'currency' },
          { value: item.interestRate, type: 'percent' },
          { value: item.monthlyPayment, type: 'currency' },
          { value: item.maturityDate, type: 'date', align: 'center' },
          { value: item.securedBy, align: 'center' },
          item.willBeRefinanced ? { align: 'center', customComponent: <RefinanceIcon size={'24px'} /> } : { value: null, align: 'center' },
        ]),
      ),
    [debt],
  );

  return <CreditMemoTable headers={headers} rows={rows} />;
};
