import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import { NoConditionsImage } from './assets/NoConditionsImage';

export const AppetiteEmptyState: FC = () => (
  <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
    <NoConditionsImage />
    <Text variant={'body1'} color={'secondary'}>
      {'You have no rules on the product'}
    </Text>
  </Flex>
);
