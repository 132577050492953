import React, { useCallback, useMemo, useContext, useState } from 'react';
import type { FC } from 'react';
import type { MRT_Cell, MRT_Column, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { useAsyncFn, useToggle } from 'react-use';
import { min, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { LoadingPage, useConfirmModal } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { DocumentLineItem } from '@lama/document-service-client';
import { parseDateWithoutTime } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentLineItemsQuery } from '../../../../../shared/hooks/react-query/document/lineItems/useDocumentLineItemsQuery';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import { useUpdateDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemMutation';
import { useCreateDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useCreateDocumentLineItemMutation';
import { useDeleteDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useDeleteDocumentLineItemMutation';
import type { ColumnMeta } from '../utils/createTableColumns';
import { createAttributeColumns, createYearColumns } from '../utils/createTableColumns';
import { createNewEmptyRow, createNewLineItemFromRow } from '../utils/createNewRow';
import { getUpdatedLineItemValues } from '../utils/getUpdatedLineItemValues';
import { sortDocumentLineItems } from '../utils/sortDocumentLineItems';
import type { SpreadingFormByDocumentTypeProps } from '../../../SpreadingDialog/Content/SpreadingFormByType';
import { usePageRangeText } from '../../../hooks/useFormTypePageRangeText';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { ToolbarCustomActions } from './ToolbarCustomActions';

export interface Period {
  startDate: Date;
  endDate: Date;
}

export const FinancialStatementsSpreadingForm: FC<SpreadingFormByDocumentTypeProps> = ({ formDocumentPages }) => {
  const { currentDocument, setHighlightedArea, currentPage, setSaving } = useContext(SpreadingDocumentContext);
  const { userId } = useContext(UserDetailsContext);
  const { spreadingBackOfficeMode, enableEditingFinancialDatesInSpreading, enableAddingDatesColumnInSpreading } = useFlags<{
    spreadingBackOfficeMode: boolean;
    enableEditingFinancialDatesInSpreading: boolean;
    enableAddingDatesColumnInSpreading: boolean;
  }>();
  const { data: documentLineItems, isPending: loadingDocumentLineItems } = useDocumentLineItemsQuery(
    currentDocument.id,
    spreadingBackOfficeMode,
  );
  const { mutateAsync: updateDocumentLineItem } = useUpdateDocumentLineItemMutation();
  const { mutateAsync: createDocumentLineItem } = useCreateDocumentLineItemMutation();
  const { mutateAsync: deleteDocumentLineItem } = useDeleteDocumentLineItemMutation();
  const [creatingRow, toggleCreatingRow] = useToggle(false);
  const [manuallyAddedPeriods, setManuallyAddedPeriods] = useState<Period[]>([]);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();

  const formDocumentPageNumbers = useMemo(() => formDocumentPages.map(({ page }) => page), [formDocumentPages]);

  const formType = useMemo(() => formDocumentPages.at(0)?.formType ?? '', [formDocumentPages]);

  const currentFormLineItems = useMemo(
    () => documentLineItems?.filter((lineItem) => lineItem.page && formDocumentPageNumbers.includes(lineItem.page)),
    [documentLineItems, formDocumentPageNumbers],
  );
  const tableLineItems = useMemo(() => sortDocumentLineItems(currentFormLineItems ?? []), [currentFormLineItems]);

  const onCellFocus = useCallback(
    (cell: MRT_Cell<DocumentLineItem>) => {
      const lineItem = cell.row.original;

      if (!lineItem.id) {
        return;
      }

      if (cell.column.id === 'text') {
        setHighlightedArea({ ...lineItem.textBoundingBox, pageIndex: lineItem.page });
        return;
      }

      const focusedItem = lineItem.values.find(({ value }) => value === cell.getValue());

      setHighlightedArea({ ...focusedItem?.boundingBox, pageIndex: lineItem.page });
    },
    [setHighlightedArea],
  );

  const allPeriods = useMemo(
    () =>
      uniqBy(
        [
          ...(documentLineItems?.flatMap((lineItem) =>
            lineItem.values.map((value) => ({
              startDate: parseDateWithoutTime(value.startDate),
              endDate: parseDateWithoutTime(value.endDate),
            })),
          ) ?? []),
          ...manuallyAddedPeriods,
        ],
        ({ startDate, endDate }) => `${startDate}-${endDate}`,
      ),
    [documentLineItems, manuallyAddedPeriods],
  );

  const onEditLineItemValue = useCallback(
    async (cell: MRT_Cell<DocumentLineItem>, value: string) => {
      const lineItem = tableLineItems[cell.row.index];
      if (!lineItem) {
        return;
      }

      setSaving(true);

      const newValue = value ? Number.parseFloat(value.replaceAll(',', '')) : undefined;

      const { startDate: cellStartDate, endDate: cellEndDate } = cell.column.columnDef.meta as ColumnMeta;
      cellStartDate?.setUTCHours(0, 0, 0, 0);
      cellEndDate?.setUTCHours(0, 0, 0, 0);

      const updatedLineItemValues = getUpdatedLineItemValues(
        lineItem,
        cellStartDate?.toISOString(),
        cellEndDate?.toISOString(),
        userId,
        newValue,
      );

      if (!creatingRow && lineItem.id && updatedLineItemValues) {
        await updateDocumentLineItem({
          documentId: currentDocument.id,
          lineItemId: lineItem.id,
          lineItemUpdate: { values: updatedLineItemValues },
        });
      }

      setSaving(false);
      setHighlightedArea(null);
    },
    [creatingRow, currentDocument.id, setHighlightedArea, setSaving, tableLineItems, updateDocumentLineItem, userId],
  );

  const [, onEditLineItemAttribute] = useAsyncFn(
    async (cell: MRT_Cell<DocumentLineItem>, value: string) => {
      if (!value) {
        return;
      }

      setSaving(true);

      const lineItem = tableLineItems?.[cell.row.index];

      if (lineItem?.page) {
        const lineItemUpdate = {
          ...lineItem,
          [cell.column.id]: value,
        };

        if (!creatingRow && lineItem.id) {
          await updateDocumentLineItem({ documentId: currentDocument.id, lineItemId: lineItem.id, lineItemUpdate });
        }
      }
      setSaving(false);
    },
    [creatingRow, currentDocument.id, setSaving, tableLineItems, updateDocumentLineItem],
  );

  const onParentChange = useCallback(
    async (row: MRT_Row<DocumentLineItem>, column: MRT_Column<DocumentLineItem>, cellLineItem: DocumentLineItem, value: string | null) => {
      if (!column.id) {
        return;
      }

      setSaving(true);
      if (!value) {
        await updateDocumentLineItem({
          documentId: currentDocument.id,
          lineItemId: cellLineItem.id,
          lineItemUpdate: { parentId: null },
        });

        setSaving(false);
        return;
      }

      row._valuesCache[column.id] = value;

      if (cellLineItem.id) {
        await updateDocumentLineItem({
          documentId: currentDocument.id,
          lineItemId: cellLineItem.id,
          lineItemUpdate: { parentId: value },
        });
      }
      setSaving(false);
    },
    [setSaving, updateDocumentLineItem, currentDocument.id],
  );

  const lineItemAttributeColumns = useMemo<MRT_ColumnDef<DocumentLineItem>[]>(
    () =>
      createAttributeColumns(
        documentLineItems ?? [],
        formType,
        formDocumentPageNumbers,
        creatingRow,
        onCellFocus,
        onEditLineItemAttribute,
        onParentChange,
      ),
    [creatingRow, documentLineItems, formDocumentPageNumbers, formType, onCellFocus, onEditLineItemAttribute, onParentChange],
  );

  const yearColumns = useMemo<MRT_ColumnDef<DocumentLineItem>[]>(
    () => createYearColumns(documentLineItems ?? [], allPeriods, onCellFocus, onEditLineItemValue, enableEditingFinancialDatesInSpreading),
    [allPeriods, documentLineItems, onCellFocus, onEditLineItemValue, enableEditingFinancialDatesInSpreading],
  );

  const columns = useMemo<MRT_ColumnDef<DocumentLineItem>[]>(() => {
    if (lineItemAttributeColumns.length === 0 || yearColumns.length === 0) {
      return [];
    }
    return [...lineItemAttributeColumns, ...yearColumns];
  }, [lineItemAttributeColumns, yearColumns]);

  const tableTextFieldProps = useCallback(
    ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => ({
      placeholder: cell.getValue(),
    }),
    [],
  );

  const onCreateLineItem = useCallback(
    async ({
      row,
      values,
      table,
    }: {
      table: MRT_TableInstance<DocumentLineItem>;
      row: MRT_Row<DocumentLineItem>;
      values: Partial<DocumentLineItem>;
    }) => {
      setSaving(true);

      const lineItemPage = formDocumentPageNumbers.includes(currentPage) ? currentPage : min(formDocumentPageNumbers);

      if (!lineItemPage) {
        return;
      }

      const newLineItem = createNewLineItemFromRow(row, values, currentDocument, lineItemPage, formType, allPeriods);

      await createDocumentLineItem({ documentId: currentDocument.id, lineItem: newLineItem });

      table.setCreatingRow(null);
      toggleCreatingRow();
      setSaving(false);
    },
    [allPeriods, createDocumentLineItem, currentDocument, currentPage, formDocumentPageNumbers, formType, setSaving, toggleCreatingRow],
  );

  const addColumn = useCallback(
    (period: Period) => {
      setManuallyAddedPeriods([...manuallyAddedPeriods, period]);
    },
    [manuallyAddedPeriods],
  );

  const renderToolbarCustomActions = useCallback(
    ({ table }: { table: MRT_TableInstance<DocumentLineItem> }) => {
      const newEmptyRow = createNewEmptyRow(currentDocument, allPeriods);
      return (
        enableAddingDatesColumnInSpreading && (
          <ToolbarCustomActions
            table={table}
            onAddColumn={addColumn}
            defaultRow={newEmptyRow}
            creatingRow={creatingRow}
            toggleCreatingRow={toggleCreatingRow}
          />
        )
      );
    },
    [currentDocument, allPeriods, enableAddingDatesColumnInSpreading, addColumn, creatingRow, toggleCreatingRow],
  );

  const onDeleteLineItem = useCallback(
    async ({ row }: { row: MRT_Row<DocumentLineItem> }) => {
      const childrenOfThisLineItem = tableLineItems?.filter((lineItem) => lineItem.parentId === row.original?.id);

      const result = await confirm({
        title: t('deleteDocumentLineItemConfirmModal.title'),
        message: `This line item has ${childrenOfThisLineItem?.length} children. Are you sure you want to delete it?`,
        cancelText: t('deleteDocumentLineItemConfirmModal.cancelText'),
        confirmText: t('deleteDocumentLineItemConfirmModal.confirmText'),
      });

      if (!result) {
        return;
      }

      setSaving(true);
      await deleteDocumentLineItem({ documentId: currentDocument.id, lineItemId: row.original?.id });
      setSaving(false);
    },
    [confirm, currentDocument.id, deleteDocumentLineItem, setSaving, t, tableLineItems],
  );

  const renderRowActionMenuItems = useCallback(
    ({ row }: { row: MRT_Row<DocumentLineItem> }) => [
      // eslint-disable-next-line react/jsx-no-bind
      <MenuItem key={'delete'} onClick={() => onDeleteLineItem({ row })}>
        {'Delete'}
      </MenuItem>,
    ],
    [onDeleteLineItem],
  );

  const pageRangeText = usePageRangeText(currentDocument, formDocumentPages.at(0)!);
  if (loadingDocumentLineItems) {
    return <LoadingPage />;
  }
  return (
    // This flex is needed to make the table scrollable and limit its height
    <Flex flexDirection={'column'} flex={1} gap={4}>
      <Flex px={4}>
        <Text variant={'h6'}>{`${formType} ${pageRangeText ?? ''}`}</Text>
      </Flex>
      <MaterialReactTable
        localization={{ actions: '' }}
        renderRowActionMenuItems={renderRowActionMenuItems}
        positionActionsColumn={'last'}
        enableRowActions
        onCreatingRowSave={onCreateLineItem}
        onCreatingRowCancel={toggleCreatingRow}
        columns={columns}
        data={tableLineItems ?? []}
        createDisplayMode={'row'}
        editDisplayMode={'cell'}
        // @ts-ignore
        muiEditTextFieldProps={tableTextFieldProps}
        muiTableContainerProps={{
          sx: { overflow: 'auto', maxHeight: '100%' },
        }}
        enableTableFooter={false}
        enableStickyFooter={false}
        enablePagination={false}
        renderTopToolbarCustomActions={renderToolbarCustomActions}
        enableEditing
        enableStickyHeader
      />
    </Flex>
  );
};
