/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Menu } from '@mui/material';
import { Flex, greyPalette } from '@lama/design-system';
import type { Matcher } from '@lama/contracts';
import { ActionMenuSearchInput } from '../../../../../../ActionsMenu/ActionMenuSearchInput';
import { AddMatcherMenuItem } from './AddMatcherMenuItem';
import { matcherConfigurations } from './matcherConfigurations';

interface AddMatcherMenuProps {
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
  addMatcher: (matcher: Matcher) => void;
}

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '268px',
      maxWidth: '100%',
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

export const AddMatcherMenu: FC<AddMatcherMenuProps> = ({ open, menuRef, onClose, addMatcher }) => {
  const [filterOptions, setFilterOptions] = useState('');

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const matcherOptions = useMemo(
    () => matcherConfigurations.filter((m) => m.displayName.toLowerCase().includes(filterOptions.toLowerCase())) ?? [],
    [filterOptions],
  );

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={onClose}>
      <Flex flexDirection={'column'} gap={2}>
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Flex flexDirection={'column'} pt={2} maxHeight={'300px'} overflow={'auto'} px={2}>
          {matcherOptions.map((matcherConfiguration) => (
            <AddMatcherMenuItem
              key={matcherConfiguration.displayName}
              matcherConfiguration={matcherConfiguration}
              closeMenu={onClose}
              addMatcher={addMatcher}
            />
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
