import type { FC } from 'react';
import React from 'react';
import { Text, Button, Modal, ModalContent, ModalFooter, Flex } from '@lama/design-system';

interface OrderConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  confirm: () => void;
  title: string;
  message: string;
  note?: string;
  orderButtonLabel?: string;
}

export const OrderConfirmDialog: FC<OrderConfirmDialogProps> = ({ title, message, note, orderButtonLabel, open, handleClose, confirm }) => (
  <Modal title={title} open={open} onClose={handleClose}>
    <ModalContent>
      <Flex flexDirection={'column'} gap={2}>
        <Text variant={'body1'}>{message}</Text>
        <Text variant={'body2'} color={'secondary'}>
          {note}
        </Text>
      </Flex>
    </ModalContent>
    <ModalFooter>
      <Button variant={'tertiary'} color={'neutral'} onClick={handleClose}>
        {'Cancel'}
      </Button>
      <Button variant={'primary'} color={'primary'} onClick={confirm}>
        {orderButtonLabel ?? title}
      </Button>
    </ModalFooter>
  </Modal>
);
