import { CalendarTodayOutlined, PersonOutline, PaidOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { greyPalette as grey, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { formatValue } from '@lama/data-formatters';

interface BusinessQuickParamsProps {
  date: string;
  partnerName: string;
  loanAmount: number;
}

export const BusinessQuickParams: FC<BusinessQuickParamsProps> = ({ date, partnerName, loanAmount }) => (
  <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'} flexShrink={1}>
    <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'center'} flexShrink={1}>
      <CalendarTodayOutlined sx={{ color: grey[500], width: '12px', height: '12px' }} />
      <Text weight={400} size={12} color={grey[500]}>
        {date}
      </Text>
    </Stack>
    <Text weight={400} size={12} color={grey[500]}>
      {' • '}
    </Text>
    <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'center'} flexShrink={1}>
      <PersonOutline sx={{ color: grey[500], width: '12px', height: '12px' }} />
      <Text weight={400} size={12} color={grey[500]}>
        {partnerName}
      </Text>
    </Stack>
    <Text weight={400} size={12} color={grey[500]}>
      {' • '}
    </Text>
    <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyContent={'center'} flexShrink={1}>
      <PaidOutlined sx={{ color: grey[500], width: '12px', height: '12px' }} />
      <Text weight={400} size={12} color={grey[500]}>
        {formatValue(loanAmount, 'currency')}
      </Text>
    </Stack>
  </Stack>
);
