import { useEffect } from 'react';
import styled, { css } from 'styled-components';

export const DisabledWrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      [data-testid='document-actions'] button,
      [data-testid='document-actions'] input {
        pointer-events: none;

        ::before {
          cursor: not-allowed;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.5);

          z-index: 10000;
        }
      }
    `}
`;
export const useDisableDocumentBoxesUpload = (documentsLoaded: boolean, disable: boolean) => {
  useEffect(() => {
    if (!documentsLoaded || !disable) {
      return;
    }

    const documentBoxes = document.querySelectorAll('[data-testid="document-box"]');

    documentBoxes.forEach((box) => {
      if (box) {
        const preventDragEvents = (event: any) => {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
        };

        const dragEvents = ['dragenter', 'dragover', 'dragleave', 'drop'];

        dragEvents.forEach((eventName) => {
          box.addEventListener(eventName, preventDragEvents, true);
        });

        return () => {
          dragEvents.forEach((eventName) => {
            box.removeEventListener(eventName, preventDragEvents, true);
          });
        };
      }
    });
  }, [disable, documentsLoaded]);
};
