import React, { useCallback } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import { Button } from '@mui/material';
import type { MRT_TableInstance } from 'material-react-table';
import type { FC } from 'react';
import { Add } from '@mui/icons-material';

export const AddRowButton: FC<{
  table: MRT_TableInstance<DocumentLineItem>;
  onClick: () => void;
  defaultRow: DocumentLineItem;
  disabled?: boolean;
}> = ({ table, onClick, disabled = false }) => {
  const onAddRow = useCallback(() => {
    table.setCreatingRow(true);
    onClick();
  }, [onClick, table]);

  return (
    <Button onClick={onAddRow} startIcon={<Add />} disabled={disabled}>
      {'Add Row'}
    </Button>
  );
};
