import { Flex, Text } from '@lama/design-system';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledFlex = styled(Flex)<{ active?: boolean; disabled?: boolean }>(
  ({ active, disabled }) => `
${
  !disabled
    ? `;&:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }`
    : ''
}
   ${active ? 'background-color: #f5f5f5' : 'background-color: transparent'}
   `,
);

export const OptionButton: FC<{
  option: string;
  label: string;
  active: boolean;
  count?: number;
  disabled?: boolean;
  setOption: (option: string) => void;
}> = ({ option, active, setOption, count, label, disabled }) => {
  const navigate = useNavigate();
  const navigateToLink = useCallback(() => {
    navigate(`./${option}`);
  }, [option, navigate]);

  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }
    setOption(option);
    navigateToLink();
  }, [disabled, navigateToLink, option, setOption]);

  return (
    <StyledFlex active={active} disabled={disabled} onClick={onClick} px={2} py={1}>
      <Text variant={'body3'} color={disabled ? 'disabled' : 'secondary'}>
        {`${label}${count ? ` (${count})` : ''}`}
      </Text>
    </StyledFlex>
  );
};
