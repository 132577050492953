import React, { useCallback } from 'react';
import type { FC } from 'react';
import { ArbitraryDocumentUploadButton } from '@lama/app-components';
import { v4 as uuidv4 } from 'uuid';
import type { Entity } from '@lama/common-types';
import { useUploadDocumentsMutation } from '../../../../shared/components/DocumentBox/hooks/useUploadDocumentsQuery';
import { useAddDocumentsToClosingTaskMutation } from './useAddDocumentsToClosingTaskMutation';

interface ArbitraryClosingTaskDocumentProps {
  opportunityId: string;
  applicationId: string;
  taskId: string;
  relatedEntityId?: string;
  relatedEntityType?: Entity;
}

export const ArbitraryClosingTaskDocumentUpload: FC<ArbitraryClosingTaskDocumentProps> = ({
  opportunityId,
  applicationId,
  taskId,
  relatedEntityId,
  relatedEntityType,
}) => {
  const { mutateAsync: uploadDocuments, isPending: isUploading } = useUploadDocumentsMutation({ applicationId, opportunityId });
  const { mutateAsync: addOpportunityClosingTaskDocuments } = useAddDocumentsToClosingTaskMutation(opportunityId);

  const uploadDocumentsCallback = useCallback(
    async (files: FileList) => {
      const filesWithIds = [...files].map((file) => ({
        applicationId,
        description: '',
        entityId: relatedEntityId ?? applicationId,
        entityType: (relatedEntityType ?? 'application') as Entity,
        file,
        topic: null,
        documentId: uuidv4(),
      }));
      await uploadDocuments(filesWithIds);

      await addOpportunityClosingTaskDocuments({
        taskId,
        documentIds: filesWithIds.map((file) => file.documentId),
      });
    },
    [addOpportunityClosingTaskDocuments, applicationId, relatedEntityId, relatedEntityType, taskId, uploadDocuments],
  );

  return <ArbitraryDocumentUploadButton onFilesSelected={uploadDocumentsCallback} uploading={isUploading} textColor={'text.secondary'} />;
};
