import { Flex, Switch, greyPalette, Text } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { Condition } from '@lama/contracts';
import { Tooltip } from '@lama/app-components';
import { IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import type { ConditionConfiguration } from '@lama/conditions';
import { formatMatcher } from '@lama/conditions';
import type { FailActionType } from './FailActionChip';
import { FailActionChip } from './FailActionChip';
import { getMatcherConfig } from './Matchers/matcherConfigurations';

interface ConditionCardProps {
  condition: Condition;
  config: ConditionConfiguration;
  onChange: (condition: Condition) => void;
}

export const ConditionCardV2: FC<ConditionCardProps> = ({ condition, onChange, config }) => {
  const navigate = useNavigate();

  const internalOnChange = useCallback(() => {
    onChange({ ...condition, active: !condition.active });
  }, [condition, onChange]);

  const onEdit = useCallback(() => {
    navigate(`./${condition.id}`);
  }, [condition.id, navigate]);

  const conditionDefinition = useMemo(() => formatMatcher(condition.conditionMatcher, config?.format), [condition, config]);

  const matcherConfig = useMemo(() => condition.enabledMatcher && getMatcherConfig(condition.enabledMatcher), [condition.enabledMatcher]);

  const matcherDefinition = useMemo(
    () => condition.enabledMatcher && formatMatcher(condition.enabledMatcher, matcherConfig?.format),
    [condition, matcherConfig?.format],
  );

  return (
    <Flex
      flexDirection={'row'}
      py={4}
      px={6}
      backgroundColor={'white'}
      border={`1px solid ${greyPalette[300]}`}
      borderRadius={'8px'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Flex flexDirection={'column'} gap={2}>
        {condition.enabledMatcher ? (
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {condition.enabledMatcher.displayName ?? matcherConfig?.displayName ?? 'Missing matcher name'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>
              {matcherDefinition}
            </Text>
          </Flex>
        ) : null}
        <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
          <Flex gap={2} alignItems={'center'}>
            <Text variant={'body2'}>{condition.name}</Text>
            <Text variant={'body2'} color={'grey.300'}>
              {'•'}
            </Text>
            <Text variant={'body2'} color={'secondary'}>
              {conditionDefinition}
            </Text>
          </Flex>
          {condition.failAction ? <FailActionChip status={condition.failAction as FailActionType} size={'medium'} /> : null}
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2} pr={4}>
        <Switch checked={condition.active} onChange={internalOnChange} size={'m'} />
        <Tooltip title={'Edit'}>
          <IconButton onClick={onEdit} sx={{ color: '#607D8B' }}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
