import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { LoanTermsBorrowerDetailsSegment } from '../../../shared/LoanTerms/LoanTermsBorrowerDetailsSegment';
import { SBSSAndCreditScoreTable } from '../../../shared/LoanSummary/SBSSAndCreditScoreTable';
import { OwnershipTable } from '../../../shared/LoanSummary/OwnershipTable';
import { HanoverLoanTermsTermsViewSegment } from './HanoverLoanTermsTermsViewSegment';
import { HanoverUoFSummaryTable } from './HanoverUoFSummaryTable';

export const HanoverLoanDetailsSection: FC = () => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses.find(({ relations }) => relations.includes('borrower'))?.business,
    [application.relatedBusinesses],
  );

  if (!business) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <HanoverLoanTermsTermsViewSegment />
      <LoanTermsBorrowerDetailsSegment />
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'}>{'Use of Funds Summary'}</Text>
        <HanoverUoFSummaryTable />
      </Flex>
      <SBSSAndCreditScoreTable title={'SBSS and Credit Scores'} />
      <OwnershipTable business={business} />
    </Flex>
  );
};
