import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';

export const OverviewSection = styled(Flex)`
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  border: 1px solid ${greyPalette[300]};
  border-radius: 4px;
  padding: ${({ theme }) => theme.space[8]}px;
`;
