import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { AttributeWithFinancialData } from '../../../FinancialStatements/utils/financialAttribute';
import { TaxReturnsFormField } from './TaxReturnsFormField';

interface TaxReturnsFormLineProps {
  lineName: string;
  financials: AttributeWithFinancialData[];
  onFieldFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const TaxReturnsFormLine: FC<TaxReturnsFormLineProps> = ({ lineName, financials, onFieldFocus, onFieldBlur }) => {
  const singleFinancialInLine = useMemo(() => financials.length === 1, [financials]);
  const singleFinancialColumnName = useMemo(() => financials[0]?.attribute.displayData?.columnName, [financials]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      {!singleFinancialInLine || (singleFinancialInLine && singleFinancialColumnName) ? <Text variant={'body2'}>{lineName}</Text> : null}
      <Flex flexDirection={'row'} justifyContent={'space-between'} gap={4}>
        {financials.map(({ id, financialData, attribute }) => (
          <TaxReturnsFormField
            key={id}
            id={id}
            subGroup={singleFinancialInLine && !attribute.displayData?.columnName ? lineName : attribute.displayData?.columnName ?? ''}
            onFieldFocus={onFieldFocus}
            onFieldBlur={onFieldBlur}
            financialField={financialData}
            fullWidth
          />
        ))}
      </Flex>
    </Flex>
  );
};
