import type { DocumentLineItem, DocumentLineItemValue } from '@lama/document-service-client';
import { isNil } from 'lodash-es';

const getUpdatedLineItemValue = (
  columnStartDate: string,
  columnEndDate: string,
  user: string,
  newValue?: number,
  existingLineItemValue?: DocumentLineItemValue,
): DocumentLineItemValue | null => {
  if (isNil(newValue) || Number.isNaN(newValue)) {
    return null;
  }

  if (!existingLineItemValue) {
    return {
      startDate: columnStartDate,
      endDate: columnEndDate,
      value: newValue,
      source: { type: 'Manual', user },
    };
  }

  const updatedValue = { ...existingLineItemValue };
  updatedValue.value = newValue;
  updatedValue.source = { type: 'Manual', user };
  return updatedValue;
};

export const getUpdatedLineItemValues = (
  lineItem: DocumentLineItem,
  cellStartDate?: string,
  cellEndDate?: string,
  userId?: string,
  newValue?: number,
): DocumentLineItemValue[] | null => {
  const currentValueForPeriod = lineItem?.values.find((item) => item.startDate === cellStartDate && item.endDate === cellEndDate);

  if (!lineItem?.page || !cellStartDate || !cellEndDate || !userId || currentValueForPeriod?.value === newValue) {
    return null;
  }

  const otherValues = lineItem?.values.filter((item) => item.startDate !== cellStartDate || item.endDate !== cellEndDate);

  const updatedValuedForPeriod = getUpdatedLineItemValue(cellStartDate, cellEndDate, userId, newValue, currentValueForPeriod);

  return updatedValuedForPeriod ? [...otherValues, updatedValuedForPeriod] : otherValues;
};

export const removeFinancialsFromLineItemInDateRange = (
  lineItem: DocumentLineItem,
  cellStartDate: string,
  cellEndDate: string,
): DocumentLineItemValue[] => lineItem.values.filter((value) => value.startDate !== cellStartDate || value.endDate !== cellEndDate);

export const getUpdateLineItemDates = (
  lineItem: DocumentLineItem,
  columnStartDate: string,
  columnEndDate: string,
  newStartDate: string,
  newEndDate: string,
  user: string,
): DocumentLineItemValue[] =>
  lineItem.values.map((value) =>
    columnStartDate === value.startDate && columnEndDate === value.endDate
      ? { ...value, startDate: newStartDate, endDate: newEndDate, source: { type: 'Manual', user } }
      : value,
  );
