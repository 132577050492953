import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';

export const CloseIcon: FC = () => (
  <Box sx={{ height: '20px', width: '20px' }}>
    <svg width={'20'} height={'20'} viewBox={'0 0 28 28'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M19.8332 9.34167L18.6582 8.16667L13.9998 12.825L9.3415 8.16667L8.1665 9.34167L12.8248 14L8.1665 18.6583L9.3415 19.8333L13.9998 15.175L18.6582 19.8333L19.8332 18.6583L15.1748 14L19.8332 9.34167Z'
        }
        fill={'black'}
        fillOpacity={'0.54'}
      />
    </svg>
  </Box>
);
