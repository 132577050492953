import React, { useMemo, useState } from 'react';
import type { Placement, UseFloatingReturn } from '@floating-ui/react';
import { useFloating, autoUpdate, offset, flip, shift, useClick, useDismiss, useRole, useInteractions } from '@floating-ui/react';
import { isNil } from 'lodash-es';
import { PopoverContext } from './PopoverContext';

export interface PopoverOptions {
  initialOpen?: boolean;
  placement?: Placement;
  modal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export type UsePopoverReturn = PopoverOptions &
  ReturnType<typeof useInteractions> &
  UseFloatingReturn & {
    labelId?: string;
    descriptionId?: string;
    setLabelId: (id: string) => void;
    setDescriptionId: (id: string) => void;
  };

export const usePopover = ({
  initialOpen = false,
  placement = 'bottom',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: PopoverOptions = {}): UsePopoverReturn => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: isNil(controlledOpen),
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId],
  );
};

export const Popover = ({
  children,
  modal = false,
  ...restOptions
}: PopoverOptions & {
  children: React.ReactNode;
}) => {
  const popover = usePopover({ modal, ...restOptions });

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};
