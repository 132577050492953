import React from 'react';
import { Flex, Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../../../shared/SegmentContainer';

export const FirstBankCollateral = () => (
  <Flex flexDirection={'column'} gap={8}>
    <SegmentContainer>
      <Grid columns={3}>
        <KeyValuePair name={'Collatoral'} value={'stock'} />
        <KeyValuePair name={'Value'} value={'$282,813.56'} />
        <KeyValuePair name={'Appraised Value'} />
        <KeyValuePair name={'Accessories Total'} />
        <KeyValuePair name={'Prior Liens'} />
        <KeyValuePair name={'LTV'} value={'35.359%'} />
        <KeyValuePair name={'Primary Lien'} value={'X'} />
      </Grid>
    </SegmentContainer>
  </Flex>
);
