import type { SvgIconProps } from '@mui/material';
import { Avatar, Badge } from '@mui/material';
import { orange, amber, red } from '@mui/material/colors';
import type { FC } from 'react';
import React from 'react';
import FlagsIcon from '@mui/icons-material/Flag';
import FlagsOutLinedIcon from '@mui/icons-material/FlagOutlined';
import type { ConditionStatus } from '../../../../../shared/types/conditionTypes';

export const statusColorMapping: Record<ConditionStatus, { flag: string; background: string }> = {
  pending: {
    flag: orange[500],
    background: amber[50],
  },
  rejected: {
    flag: red[400],
    background: red[50],
  },
  approved: {
    flag: '#44CFA6',
    background: '#E2FBF3',
  },
};

interface StatusFlagProps {
  status: ConditionStatus;
  showBadge?: boolean;
}

export const StatusFlag: FC<StatusFlagProps> = ({ status, showBadge = false }) => (
  <Badge
    overlap={'circular'}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={showBadge ? <img src={'/images/AIBadge.png'} width={'16x'} height={'16px'} alt={''} /> : null}
  >
    <Avatar variant={'square'} sx={{ bgcolor: statusColorMapping[status].background, borderRadius: '4px' }}>
      <FlagsIcon sx={{ color: statusColorMapping[status].flag }} />
    </Avatar>
  </Badge>
);

export const StatusFlagOutlined: FC<StatusFlagProps & SvgIconProps> = ({ status, sx, ...props }) => (
  <FlagsOutLinedIcon {...props} sx={{ ...sx, color: statusColorMapping[status].flag }} />
);
