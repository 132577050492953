// useSaveAdjustment.ts
import type { Property, FinancialData, UnderwritingNote } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react';
import { isNil } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';
import type { SpreadRowPeriodValue } from '@lama/spreads-generator-client';
import { useCreateNoteMutation } from '../../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { useCreateFinancialsMutation } from './useCreateFinancialsMutation';
import { useUpdateFinancialsMutation } from './useUpdateFinancialsMutation';

export const useAdjustFinancialValue = ({
  opportunityId,
  entityId,
  value,
  currentValue,
  property,
  existingAdjustment,
  currentAdjustmentNote,
  onSuccess,
}: {
  opportunityId: string;
  entityId: string;
  value: number | undefined;
  currentValue: SpreadRowPeriodValue | null;
  property: Property;
  existingAdjustment?: FinancialData;
  currentAdjustmentNote: UnderwritingNote | undefined;
  onSuccess: () => void;
}) => {
  const user = useContext(UserDetailsContext);
  const queryClient = useQueryClient();
  const { mutateAsync: createFinancials } = useCreateFinancialsMutation(opportunityId, entityId);
  const { mutateAsync: updateFinancials } = useUpdateFinancialsMutation(opportunityId, entityId);
  const { mutateAsync: createNote } = useCreateNoteMutation(opportunityId, entityId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunityId, entityId);

  return useAsyncFn(
    async ({ noteText, startDate, endDate }: { noteText: string; startDate: string; endDate: string }) => {
      if (
        isNil(value) ||
        !user?.userId ||
        (value === currentValue?.financialAttribute?.value && noteText === currentAdjustmentNote?.text) ||
        !property.entityType ||
        !property.financialAttribute ||
        !noteText.length
      ) {
        return;
      }

      if (existingAdjustment?.entityId && existingAdjustment.entityType && currentAdjustmentNote?.id) {
        await Promise.all([
          updateFinancials({
            financialsToUpdate: [
              {
                id: existingAdjustment.id,
                value,
              },
            ],
            entityId: existingAdjustment.entityId,
            entityType: existingAdjustment.entityType,
          }),
          updateNote({
            noteId: currentAdjustmentNote.id,
            notePayload: {
              text: noteText,
            },
          }),
        ]);
        await queryClient.invalidateQueries({ queryKey: ['financials', entityId, false] });

        onSuccess();
        return;
      }

      const createFinancialId = uuidv4();
      await Promise.all([
        createFinancials({
          financialsToCreate: [
            {
              id: createFinancialId,
              entityId,
              entityType: property.entityType,
              value,
              type: property.financialAttribute,
              endDate,
              startDate,
              source: {
                user: {
                  userId: user?.userId,
                  type: 'Lender',
                },
                type: 'Adjustment',
              },
            },
          ],
        }),
        createNote({
          id: uuidv4(),
          type: 'adjustment',
          text: noteText,
          relatedFinancialAdjustmentId: createFinancialId,
        }),
      ]);

      await queryClient.invalidateQueries({ queryKey: ['financials', entityId, false] });

      onSuccess();
    },
    [
      value,
      user?.userId,
      currentValue?.financialAttribute?.value,
      currentAdjustmentNote?.text,
      currentAdjustmentNote?.id,
      property.entityType,
      property.financialAttribute,
      existingAdjustment?.entityId,
      existingAdjustment?.entityType,
      existingAdjustment?.id,
      createFinancials,
      entityId,
      createNote,
      onSuccess,
      updateFinancials,
      updateNote,
      queryClient,
    ],
  );
};
