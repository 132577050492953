import type { FC } from 'react';
import React from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';

interface NoDataFoundBoxProps {
  message: string;
  Icon?: FC<{ color?: string }>;
}

export const NoDataFoundBox: FC<NoDataFoundBoxProps> = ({ message, Icon }) => (
  <Flex
    py={4}
    border={`1px solid ${greyPalette[200]}`}
    justifyContent={'center'}
    width={'100%'}
    borderRadius={4}
    gap={4}
    alignItems={'center'}
  >
    {Icon ? <Icon color={greyPalette[500]} /> : null}
    <Text variant={'body2'} color={greyPalette[500]}>
      {message}
    </Text>
  </Flex>
);
