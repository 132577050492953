import React from 'react';
import type { FC } from 'react';
import { SegmentContainer } from '../SegmentContainer';
import { UseOfFundsSummaryTable } from './UseOfFundsSummaryTable';

export const UseOfFundsSummarySegment: FC = () => (
  <SegmentContainer title={'Use of Funds Summary'} bordered={false}>
    <UseOfFundsSummaryTable />
  </SegmentContainer>
);
