import type { FC } from 'react';
import React from 'react';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { Skeleton } from '@mui/material';

export const KeyValuePairSkeleton: FC<{ name?: string }> = ({ name }) => (
  <Flex flexDirection={'column'} gap={2} minWidth={0}>
    {name ? (
      <Text variant={'body2'} color={greyPalette[500]}>
        {name}
      </Text>
    ) : (
      <Skeleton width={'100px'} />
    )}
    <Skeleton width={'80px'} />
  </Flex>
);
