import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import { Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { keyBy } from 'lodash-es';
import type { EvaluatedCondition, ProductApiModel } from '@lama/contracts';
import { useEvaluateApplicationForProductsQuery } from '../../shared/hooks/react-query/application/useEvaluateApplicationForProductsQuery';
import { useGetPartnersProductsQuery } from '../../shared/hooks/react-query/product/useGetPartnersProductsQuery';
import type { FilterOptions } from './ProductSimulatorFilter';
import { ProductSimulatorFilter } from './ProductSimulatorFilter';
import { ProductSimulationCard } from './ProductSimulationCard';
import NoProducts from './assets/NoProducts.svg';

export type SimulationResult = 'fail' | 'pass' | 'pending';

const getSimulationConditions = ({
  product,
  application,
  evaluatedConditions,
}: {
  product: ProductApiModel;
  application?: ApplicationApiModel;
  evaluatedConditions: EvaluatedCondition[];
  filter: FilterOptions;
}) => {
  const conditions = application
    ? evaluatedConditions
    : product?.conditions?.map<EvaluatedCondition>((condition) => ({
        id: condition.id,
        condition,
        reason: 'missing_data' as const,
        result: true,
        extractedValue: undefined,
        referenceConditionId: condition.id,
      })) ?? [];

  return conditions.filter(({ condition: { relevantForSimulation } }) => relevantForSimulation);
};

const getSimulationResult = (application: ApplicationApiModel | null, simulationConditions: EvaluatedCondition[]): SimulationResult => {
  if (!application) {
    return 'pending';
  }

  if (simulationConditions?.some(({ result }) => !result)) {
    return 'fail';
  }

  if (simulationConditions?.some(({ reason }) => reason === 'missing_data')) {
    return 'pending';
  }

  return 'pass';
};

export const ProductSimulationList: FC<{ application: ApplicationApiModel | null; partnerId: string }> = ({ application, partnerId }) => {
  const [filter, setFilter] = useState<FilterOptions>('all');
  const { data: products, pending: loadingProducts } = useGetPartnersProductsQuery([partnerId]);

  const relevantProducts = useMemo(() => products?.filter((product) => product.relevantForSimulation), [products]);

  const { data: evaluatedConditionsPerProduct, pending: loadingEvaluatedConditionsPerProducts } = useEvaluateApplicationForProductsQuery(
    relevantProducts.map(({ id }) => id),
    application ?? undefined,
  );

  const evaluationResultPerProduct = useMemo(() => {
    const evaluationResults = evaluatedConditionsPerProduct?.map((evalRes) => {
      const simulationConditions = getSimulationConditions({
        product: relevantProducts.find(({ id }) => id === evalRes.productId)!,
        application: application ?? undefined,
        evaluatedConditions: evalRes.evaluatedConditions,
        filter,
      });
      const simulationResult = getSimulationResult(application, simulationConditions);

      return {
        productId: evalRes.productId,
        simulationResult,
        simulationConditions,
      };
    });

    const filteredResults = evaluationResults?.filter((res) => filter === 'all' || res.simulationResult === filter);

    const res = keyBy(filteredResults, ({ productId }) => productId);

    return res;
  }, [application, evaluatedConditionsPerProduct, filter, relevantProducts]);

  const showEmptyState = useMemo(
    () =>
      !relevantProducts.length ||
      (!loadingEvaluatedConditionsPerProducts && !Object.values(evaluationResultPerProduct).length && filter !== 'all'),
    [evaluationResultPerProduct, filter, loadingEvaluatedConditionsPerProducts, relevantProducts],
  );

  if (loadingProducts) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} minWidth={'300px'} width={'100%'}>
      <ProductSimulatorFilter filter={filter} onFilterChange={setFilter} />
      {showEmptyState ? (
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} pt={10}>
          <NoProducts />
        </Flex>
      ) : (
        <Flex paddingTop={8} gap={10} flexWrap={'wrap'}>
          {relevantProducts.map((product) => (
            <ProductSimulationCard
              key={product.id}
              product={product}
              evaluatedConditions={evaluationResultPerProduct[product.id]?.simulationConditions ?? []}
              simulationResult={evaluationResultPerProduct[product.id]?.simulationResult ?? 'pending'}
              loading={loadingEvaluatedConditionsPerProducts}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
