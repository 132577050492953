import type { UnderwritingNoteType } from '@lama/contracts';
import { Button, Modal, ModalContent, ModalFooter } from '@lama/design-system';
import { TextField } from '@mui/material';
import { isNil } from 'lodash-es';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useToggle } from 'react-use';
import { useUpdateNoteMutation } from '../../../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';

interface ChangeNoteTypeButtonProps {
  changeNoteTypeCTA: string;
  noteTypeTarget: UnderwritingNoteType;
  loading?: boolean;
  confirmMessage?: string;
  opportunityId: string;
  noteId: string;
}
export const ChangeNoteTypeButton: FC<ChangeNoteTypeButtonProps> = ({
  changeNoteTypeCTA,
  noteTypeTarget,
  confirmMessage,
  opportunityId,
  noteId,
}) => {
  const [modalOpen, toggleModal] = useToggle(false);
  const [confirmComment, setConfirmComment] = useState<string>('');
  const currentUser = useContext(UserDetailsContext);
  const { mutateAsync: updateNote, isPending: updatingNote } = useUpdateNoteMutation(opportunityId, currentUser?.userId);

  const submitNoteTypeChange = useCallback(
    async ({ type, clearReason }: { type: UnderwritingNoteType; text?: string; clearReason?: string }) => {
      // eslint-disable-next-line no-undef
      if (isNil(type)) {
        return;
      }

      await updateNote({
        noteId,
        notePayload: {
          ...(type ? { type } : {}),
          ...(clearReason ? { clearReason } : {}),
        },
      });
    },
    [updateNote, noteId],
  );

  const onClick = useCallback(async () => {
    if (confirmMessage) {
      toggleModal();
      return;
    }
    await submitNoteTypeChange({ type: noteTypeTarget, clearReason: confirmComment });
  }, [confirmMessage, submitNoteTypeChange, noteTypeTarget, confirmComment, toggleModal]);

  const onConfirm = useCallback(async () => {
    await submitNoteTypeChange({ type: noteTypeTarget, clearReason: confirmComment });
    toggleModal();
  }, [noteTypeTarget, toggleModal, confirmComment, submitNoteTypeChange]);

  const onCommentChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmComment(e.target.value);
  }, []);

  return (
    <>
      <Modal
        open={modalOpen}
        title={confirmMessage}
        onClose={toggleModal}
        aria-labelledby={'alert-dialog-title'}
        aria-describedby={'alert-dialog-description'}
        size={'m'}
        fullWidth
      >
        <ModalContent>
          <TextField label={'Comments'} value={confirmComment} onChange={onCommentChange} autoFocus />
        </ModalContent>
        <ModalFooter>
          <Button variant={'tertiary'} color={'neutral'} onClick={toggleModal}>
            {'Cancel'}
          </Button>
          <Button variant={'tertiary'} onClick={onConfirm} autoFocus disabled={!confirmComment?.length}>
            {changeNoteTypeCTA}
          </Button>
        </ModalFooter>
      </Modal>
      <Button key={noteTypeTarget} variant={'secondary'} color={'neutral'} onClick={onClick} loading={updatingNote}>
        {changeNoteTypeCTA}
      </Button>
    </>
  );
};
