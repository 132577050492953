/* eslint-disable react/iframe-missing-sandbox */
import React from 'react';

export default () => (
  <iframe
    src={'https://demo.lama.ai'}
    frameBorder={'0'}
    title={'integrations'}
    width={'100%'}
    height={'99%'}
    sandbox={'allow-same-origin allow-forms allow-scripts'}
  />
);
