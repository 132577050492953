import React, { type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { Condition } from '../../../../../../shared/types/conditionTypes';
import { ConditionsListSectionContent } from '../ConditionsListSectionContent';

const localDemoData: Condition = {
  id: uuidv4(),
  status: 'approved',
  name: 'Local Activity',
  hasComments: false,
  hasInsight: false,
  category: 'Activity',
  chargeOffPrediction: 0.5,
  assignedId: '',

  fields: [
    { name: 'Average visitors per week (foot traffic)', value: '14,260', dataSources: ['online'] },
    { name: 'Monthly visitors trends (foot traffic)', value: '-19.48', dataSources: ['online'] },
    {
      name: 'Competitors density in area',
      value: 'Low',
      dataSources: ['online', 'socialMedia'],
      hasInsight: true,
    },
    {
      name: 'Annual population growth in area',
      value: '5%',
      dataSources: ['online', 'socialMedia', 'stateRegistry'],
    },
    {
      name: 'Crime rate',
      value: 'Below average',
      hasInsight: true,
      dataSources: ['police', 'stateRegistry'],
    },
    {
      name: 'Natural disasters susceptibility',
      value: '2% every year (flood threat)',
      dataSources: ['online', 'stateRegistry'],
      hasInsight: true,
    },
  ],
};

export const LocalActivitySection: FC = () => <ConditionsListSectionContent {...localDemoData} />;
