import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { EnvelopeProcessedRequestBody, EnvelopeTracking } from '@lama/docusign-client';
import { displayToast } from '@lama/app-components';
import { docusignServiceClient } from '../../../../../../../shared/clients/docusignServiceClient';

export const useEnvelopeProcessedMutation = (options?: UseMutationOptions<EnvelopeTracking, unknown, EnvelopeProcessedRequestBody>) => {
  const { getAccessTokenSilently } = useAuth0();

  const envelopeProcessed = useCallback(
    async ({ opportunityId, envelopeId }: EnvelopeProcessedRequestBody) => {
      const token = await getAccessTokenSilently();

      return docusignServiceClient.envelopeProcessed({ opportunityId, envelopeId }, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: envelopeProcessed,
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
