import type { FC } from 'react';
import React, { useMemo, useContext, useCallback } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { ApplicationContext } from '../../ApplicationContext';
import type { UnderwritingNoteRelatedSectionType } from '../noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../noteUtils';
import Check from './assets/check.svg';
import Uncheck from './assets/uncheck.svg';
import { useAddReviewedUnderwritingNoteMutation } from './hooks/useAddReviewedUnderwritingNoteMutation';
import { useDeleteUnderwritingNoteMutation } from './hooks/useDeleteUnderwritingNoteMutation';

interface SectionTitleProps {
  sectionId: string;
  title: string;
  sectionType: UnderwritingNoteRelatedSectionType;
}

export const SectionTitle: FC<SectionTitleProps> = ({ sectionId, title, sectionType }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutate: addReviewedNote } = useAddReviewedUnderwritingNoteMutation(sectionId, sectionType, opportunity.id, userId!);
  const { mutate: deleteNote } = useDeleteUnderwritingNoteMutation(opportunity.id);

  const sectionReviewedNote = useMemo(() => {
    const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
    return opportunity.underwriting?.notes?.find(
      (note) => note[sectionIdProperty] === sectionId && note.type === 'reviewed' && note.createdBy === userId,
    );
  }, [opportunity.underwriting?.notes, sectionType, sectionId, userId]);

  const onReadClick = useCallback(() => {
    if (!userId) {
      return;
    }

    if (sectionReviewedNote) {
      deleteNote(sectionReviewedNote.id);
      return;
    }

    addReviewedNote();
  }, [userId, sectionReviewedNote, addReviewedNote, deleteNote]);

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} gap={1}>
      <Text variant={'h5'}>{title}</Text>
      <Button
        variant={'secondary'}
        color={'neutral'}
        size={'m'}
        selected={!!sectionReviewedNote}
        startIcon={sectionReviewedNote ? Check : Uncheck}
        onClick={onReadClick}
      >
        {'Reviewed'}
      </Button>
    </Flex>
  );
};
