import React, { useCallback, useMemo } from 'react';
import { LoadingPage } from '@lama/app-components';
import { useToggle } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Flex } from '@lama/design-system';
import { useSearchParams } from 'react-router-dom';
import { useApplicationDocumentsQuery } from '../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { RequirementDocuments } from '../../../shared/RequirementDocuments';
import { UploadArbitraryDocumentButton } from '../../../../../shared/components/DocumentBox/UploadArbitraryDocumentButton';
import { SpreadingDialog } from '../../../../Spreading/SpreadingDialog/SpreadingDialog';
import { isDocumentRelatedToRequirement } from '../../../../../shared/utils/isDocumentRelatedToRequirement';
import { DeletedDocumentsList } from '../../../../../shared/components/DeletedDocumentsList/DeletedDocumentsList';
import { useApplicationSpreadingDocumentsQuery } from '../../../../Spreading/hooks/useApplicationSpreadingDocumentsQuery';
import SpreadingIcon from './assets/icons/spreadingIcon.svg';
import type { RequirementScreenProps } from './types';

export const DocumentRequirementScreen: React.FC<RequirementScreenProps> = ({ requirement }) => {
  const { id: requirementId, applicationId, entityType, entityId, referenceRequirementId, key, sources, exportConfiguration } = requirement;
  const [searchParams, setSearchParams] = useSearchParams();

  const dialogOpen = searchParams.get('dialogOpen');

  const [open, toggleDialog] = useToggle(dialogOpen === 'true');

  const toggleFiancialDialog = useCallback(() => {
    toggleDialog();
    setSearchParams((currentParams) => {
      currentParams.set('dialogOpen', (!open).toString());
      if (open) {
        currentParams.delete('documentId');
      }

      return currentParams;
    });
  }, [open, setSearchParams, toggleDialog]);

  const { spreadingBackOfficeMode } = useFlags();

  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({ applicationId, includeDeleted: true });

  const { data: spreadingDocuments, isPending: loadingSpreadingDocuments } = useApplicationSpreadingDocumentsQuery(applicationId);

  const requirementDocuments = useMemo(
    () => applicationDocuments?.filter((doc) => isDocumentRelatedToRequirement(doc, requirement) && doc.status !== 'Deleted') ?? [],
    [applicationDocuments, requirement],
  );

  const deletedDocuments = useMemo(
    () => applicationDocuments?.filter((doc) => isDocumentRelatedToRequirement(doc, requirement) && doc.status === 'Deleted') ?? [],
    [applicationDocuments, requirement],
  );

  const relevantSpreadingDocuments = useMemo(
    () =>
      spreadingDocuments?.filter(
        (doc) =>
          isDocumentRelatedToRequirement(doc, requirement) &&
          (spreadingBackOfficeMode || doc.status === 'Processed' || doc.status === 'Reviewed'),
      ) ?? [],
    [requirement, spreadingBackOfficeMode, spreadingDocuments],
  );

  const shouldDisplaySpreadingDialog = useMemo(
    () => spreadingBackOfficeMode && sources.uploadFilesSource?.some(({ extractable }) => extractable) && relevantSpreadingDocuments.length,
    [spreadingBackOfficeMode, sources.uploadFilesSource, relevantSpreadingDocuments.length],
  );

  const requirementHasExtractableSources = useMemo(
    () => sources.uploadFilesSource?.some(({ extractable }) => extractable),
    [sources.uploadFilesSource],
  );

  if (loadingDocuments || loadingSpreadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={6} justifyContent={'center'}>
      {shouldDisplaySpreadingDialog ? (
        <Flex flexDirection={'row'} alignItems={'flex-start'}>
          <Button variant={'secondary'} color={'neutral'} startIcon={SpreadingIcon} onClick={toggleFiancialDialog} size={'s'}>
            {'Edit Financial Data'}
          </Button>
        </Flex>
      ) : null}
      <RequirementDocuments requirement={requirement} requirementDocuments={requirementDocuments} />
      <UploadArbitraryDocumentButton
        applicationId={applicationId}
        entityId={entityId}
        entityType={entityType}
        requirementId={requirementId}
        sharedRequirementId={referenceRequirementId}
        requirementKey={key}
        exportConfiguration={exportConfiguration}
        extractable={requirementHasExtractableSources}
      />
      {deletedDocuments.length ? <DeletedDocumentsList deletedDocuments={deletedDocuments} /> : null}
      <SpreadingDialog open={open} toggleDialog={toggleFiancialDialog} documents={relevantSpreadingDocuments} />
    </Flex>
  );
};
