import type { PixelSuffix } from '@lama/design-system';
import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';

export const BorderedContainer = styled(Flex)<{ padding?: PixelSuffix }>`
  flex-direction: column;
  border: 1px solid ${greyPalette[300]};
  border-radius: 8px;
  padding: ${({ padding, theme }) => padding ?? theme.space[6]}px;
`;
