import React, { useMemo, useCallback } from 'react';
import type { FC } from 'react';
import { Button, Flex } from '@lama/design-system';
import { useToggle } from 'react-use';
import type { FinancialData, Property } from '@lama/contracts';
import type { ColDef, ColGroupDef } from '@ag-grid-community/core';
import type { SpreadRowPeriodValue, SpreadRow } from '@lama/spreads-generator-client';
import type { SpreadTooltipProps } from '../SpreadTooltip';
import { useDeleteFinancialsMutation } from '../../../hooks/useDeleteFinancialsMutation';
import { AdjustFinancialValueDialog } from './AdjustFinancialValueDialog';

interface SpreadTooltipFooterProps extends SpreadTooltipProps {
  existingAdjustment?: FinancialData;
  property: Property;
  applicationId: string;
  entityId: string;
  currentValue: SpreadRowPeriodValue | null;
  colDef?: ColDef<SpreadRow> | ColGroupDef<SpreadRow> | null;
  allowRowAdjustments?: boolean;
  opportunityId: string;
}

export const SpreadTooltipFooter: FC<SpreadTooltipFooterProps> = ({
  existingAdjustment,
  property,
  colDef,
  applicationId,
  endDate,
  startDate,
  entityId,
  currentValue,
  hideTooltipCallback,
  allowRowAdjustments,
  opportunityId,
}) => {
  const [adjustModalOpen, toggleAdjustModalOpen] = useToggle(false);

  const disableAdjustments = useMemo(
    () => property.entityType === 'application' || !allowRowAdjustments,
    [property.entityType, allowRowAdjustments],
  );

  const { mutateAsync: deleteFinancials, isPending } = useDeleteFinancialsMutation();

  const onClickDeleteAdjustment = useCallback(async () => {
    if (!existingAdjustment?.id || !property.entityType) {
      return;
    }

    await deleteFinancials(
      {
        financialIds: [existingAdjustment?.id],
        entityId,
        entityType: property.entityType,
        opportunityId,
      },
      {
        onSettled: () => {
          hideTooltipCallback?.();
        },
      },
    );
  }, [deleteFinancials, entityId, existingAdjustment?.id, hideTooltipCallback, property.entityType, opportunityId]);

  if (!colDef?.headerName || !property || disableAdjustments) {
    return null;
  }

  return (
    <>
      <Flex gap={2} flex={1} justifyContent={'flex-end'}>
        {existingAdjustment?.id ? (
          <Button variant={'tertiary'} size={'m'} onClick={onClickDeleteAdjustment} loading={isPending}>
            {'Reset'}
          </Button>
        ) : null}
        <Button variant={'tertiary'} size={'m'} onClick={toggleAdjustModalOpen}>
          {'Edit'}
        </Button>
      </Flex>
      <AdjustFinancialValueDialog
        key={existingAdjustment?.id}
        columnDate={colDef.headerName}
        property={property}
        existingAdjustment={existingAdjustment}
        open={adjustModalOpen}
        onClose={toggleAdjustModalOpen}
        applicationId={applicationId}
        entityId={entityId}
        endDate={endDate}
        startDate={startDate}
        currentValue={currentValue}
      />
    </>
  );
};
