import React, { useContext, useMemo } from 'react';
import { Text } from '@lama/design-system';
import type { FC } from 'react';
import { applicationBorrowingRelatedBusinessesSelector } from '@lama/data-formatters';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

const headers = [
  { text: 'Relationship', width: '25%' },
  { text: 'Covenant Type', width: '25%' },
  { text: 'Frequency', width: '14%' },
  { text: 'Required', width: '14%' },
  { text: 'Actual', width: '5%' },
  { text: 'Next Evaluation Date', width: '14%' },
  { text: 'Status', width: '5%' },
];

export const GrasshopperFinancialCovenantsTable: FC = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const borrowingBusinesses = applicationBorrowingRelatedBusinessesSelector(application);
    return borrowingBusinesses.map((business) => [
      { value: business.business.legalName },
      { value: 'Debt Service Coverage Ratio - Post Distribution' },
      { value: 'Annually' },
      { value: '≥ 1.25' },
      { value: ' ' },
      { value: '5/15/2025' },
      { value: ' ' },
    ]);
  }, [application]);
  return (
    <SegmentContainer title={'Financial Covenants'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
      <Text variant={'body3'} color={'secondary'}>
        {
          "Definition: Borrower's DSCR (Net Profit After Tax + Depriciation Expense + Amortization Expense + Interest Expense - Distributions DIVIDED BY Senior Debt) shall be no less than 1.25x, based on year-end tax returns."
        }
      </Text>
    </SegmentContainer>
  );
};
