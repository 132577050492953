import type { FC } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Tooltip } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import { PartnerImage } from '../../shared/components/Partner/PartnerImage';

const PartnerLogo: FC<{ partnerId: string }> = ({ partnerId }) => (
  <Box
    sx={{
      minWidth: '32px',
      minHeight: '32px',
      borderRadius: '50%',
      bgcolor: grey[200],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <PartnerImage partner={partnerId} size={'18px'} />
  </Box>
);

export const PartnerCell: FC<{ partnerId: string; partnerDisplayName: string }> = ({ partnerId, partnerDisplayName }) => (
  <Flex gap={2} alignItems={'center'} width={'100%'}>
    <PartnerLogo partnerId={partnerId} />
    <Tooltip title={partnerDisplayName}>
      <Text variant={'body2'} textAlign={'left'} ellipsis>
        {partnerDisplayName}
      </Text>
    </Tooltip>
  </Flex>
);
