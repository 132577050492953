import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { LenderProfile } from '@lama/user-service-client';
import { userServiceClient } from '../../../clients/userServiceClient';

export const useLenderProfilesByPartnerQuery = (partner?: string, options?: UseQueryOptions<LenderProfile[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getLenderProfilesByPartner = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!partner) {
      return [];
    }

    const { lenderProfiles } = await userServiceClient.getLenderProfilesByPartner({ partner }, token);
    return lenderProfiles;
  }, [getAccessTokenSilently, partner]);

  const query = useQuery<LenderProfile[]>({ queryKey: ['lenderProfiles', partner], queryFn: getLenderProfilesByPartner, ...options });

  return query;
};
