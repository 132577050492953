import React from 'react';
import type { FC } from 'react';
import { ConnectionEstablishedAuditLog } from '../System/ConnectionEstablishedAuditLog';
import { DataProcessedAuditLog } from '../System/DataProcessedAuditLog';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import { CreateEntityAuditLog } from '../CreateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';

export const BusinessAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'Update') {
    return <UpdateEntityAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Create') {
    return <CreateEntityAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'Connection') {
    return <ConnectionEstablishedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'DataProcessed') {
    return <DataProcessedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  return null;
};
