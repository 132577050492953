import { Card, Input } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useAsyncFn } from 'react-use';
import { LoadingButton } from '@mui/lab';
import { Flex } from '@lama/design-system';

interface AddNoteInputProps {
  placeholder: string;
  initialText?: string;
  onSubmit: (text: string) => Promise<void>;
}

export const AddNoteInput: FC<AddNoteInputProps> = ({ onSubmit, placeholder, initialText }) => {
  const [text, setText] = React.useState<string>(initialText ?? '');

  const [{ loading: submitting }, onSubmitInternal] = useAsyncFn(async () => {
    await onSubmit(text);
  }, [text, onSubmit]);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
    },
    [setText],
  );

  return (
    <Card>
      <Flex flexDirection={'column'} flex={1} alignItems={'baseline'} gap={2} p={4} backgroundColor={'white'}>
        <Input
          size={'small'}
          placeholder={placeholder}
          onChange={onChangeText}
          value={text}
          disableUnderline
          multiline
          autoFocus
          fullWidth
        />
        <Flex justifyContent={'flex-end'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
          <LoadingButton loading={submitting} variant={'contained'} onClick={onSubmitInternal}>
            {'Save'}
          </LoadingButton>
        </Flex>
      </Flex>
    </Card>
  );
};
