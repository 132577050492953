import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Text } from '@lama/design-system';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import type { LabeledValue } from '@lama/contracts';
import { failActionOptions } from './FailActionChip';

export interface FailActionAutocompleteProps {
  failAction: LabeledValue | null;
  setFailAction: (failAction: LabeledValue | null) => void;
}

export const FailActionAutocomplete: FC<FailActionAutocompleteProps> = ({ failAction, setFailAction }) => {
  const onFailActionChange = useCallback(
    (event: React.ChangeEvent<{}>, value: LabeledValue | null) => {
      setFailAction(value ?? null);
    },
    [setFailAction],
  );

  const renderActionsInput = useCallback((params: any) => <TextField size={'small'} label={'If condition fails'} {...params} />, []);

  const renderActionOption = useCallback(
    (props: any, actionOption: LabeledValue) => (
      <MenuItem value={actionOption.value} {...props}>
        <Text variant={'body1'} ellipsis>
          {actionOption.label}
        </Text>
      </MenuItem>
    ),
    [],
  );

  const getActionOptionLabel = useCallback((option: LabeledValue) => option.label, []);

  return (
    <Autocomplete
      id={'failAction'}
      value={failAction}
      renderInput={renderActionsInput}
      options={failActionOptions}
      onChange={onFailActionChange}
      renderOption={renderActionOption}
      getOptionLabel={getActionOptionLabel}
      fullWidth
    />
  );
};
