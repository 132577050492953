import type { DataNode } from '@lama/design-system';
import type { MoveToDataNode } from '../../../shared/components/DocumentBox/DocumentBox';
import type { GroupingOptions } from '../types';
import { useApplicationDocumentsQuery } from '../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { useDocumentBoxesDetails } from './useDocumentBoxesDetails';
import { useDocumentsMoveToTree } from './useDocumentsMoveToTree';

export const useIndependentDocumentsMoveToTree = ({
  applicationId,
  opportunityId,
  grouping,
}: {
  opportunityId: string;
  applicationId: string;
  grouping: GroupingOptions;
}): DataNode<MoveToDataNode>[] => {
  const { data: applicationDocuments, isLoading: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId,
    includeDeleted: false,
    includeIssues: false,
  });
  const { data: allRequirements, isLoading: loadingRequirements } = useOpportunityRequirementsQuery(opportunityId);
  const { data: closingTasks, isLoading: loadingClosingTasks } = useOpportunityClosingTasksQuery(opportunityId);

  const documentBoxesDetails = useDocumentBoxesDetails({
    closingTasks: closingTasks ?? [],
    allDocuments: applicationDocuments ?? [],
    allRequirements: allRequirements ?? [],
    grouping,
  });

  const moveToTreeData = useDocumentsMoveToTree(documentBoxesDetails, grouping);

  if (loadingDocuments || loadingRequirements || loadingClosingTasks) {
    return [];
  }

  return moveToTreeData;
};
