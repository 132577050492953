import React, { useCallback } from 'react';
import { Flex, ButtonGroup } from '@lama/design-system';

export type FilterOptions = 'all' | 'fail' | 'pass';

export const ProductSimulatorFilter: React.FC<{ filter: FilterOptions; onFilterChange: (filter: FilterOptions) => void }> = ({
  filter,
  onFilterChange,
}) => {
  const handleGroupChange = useCallback(
    (value: string) => {
      onFilterChange(value as FilterOptions);
    },
    [onFilterChange],
  );

  return (
    <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <ButtonGroup
        items={[
          { value: 'all', label: 'All' },
          { value: 'pass', label: 'Pass' },
          { value: 'fail', label: 'Fail' },
        ]}
        value={filter}
        onChange={handleGroupChange}
      />
    </Flex>
  );
};
