import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { groupBy, mapValues, sortBy } from 'lodash-es';
import type { UnderwritingNote } from '@lama/contracts';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { personFullName, businessName } from '@lama/data-formatters';
import mixpanel from 'mixpanel-browser';
import { Thread } from '../../../shared/components/Comments/Thread';
import type { NoteRelation } from '../../../shared/utils/getNoteRelatedItems';
import { getNoteRelatedItems } from '../../../shared/utils/getNoteRelatedItems';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { ApplicationContext } from '../ApplicationContext';

interface ThreadTitleSuffixComponentProps {
  noteRelation: NoteRelation;
  entitiesNamesById: Record<string, string | null>;
}

const ThreadTitleSuffixComponent: FC<ThreadTitleSuffixComponentProps> = ({
  noteRelation: { tabName, sectionName, groupName, relatedItemId },
  entitiesNamesById,
}) => {
  const titleSuffix = useMemo(() => {
    const entityId = relatedItemId && relatedItemId.includes('_') ? relatedItemId.split('_')?.at(1) : null;

    const entityName = entityId ? entitiesNamesById[entityId] : '';

    return `${tabName ? `${tabName} > ` : ''}${groupName ? `${groupName} > ` : ''}${entityName ? `${entityName} > ` : ''}${sectionName}`;
  }, [tabName, sectionName, groupName, relatedItemId, entitiesNamesById]);

  if (!tabName) {
    return null;
  }

  return (
    <Flex gap={2} alignItems={'center'}>
      <Text variant={'body2'} color={greyPalette[500]}>
        {titleSuffix}
      </Text>
    </Flex>
  );
};

export const CommentsList: FC<{ notes: UnderwritingNote[] }> = ({ notes }) => {
  const { product, opportunity, application } = useContext(ApplicationContext);
  const { data: opportunityRequirements, isFetching: fetchingOpportunityRequirements } = useOpportunityRequirementsQuery(opportunity.id);
  const { data: opportunityClosingTasks, isFetching: fetchingClosingTasks } = useOpportunityClosingTasksQuery(opportunity.id);

  const entitiesNamesByIds = useMemo(() => {
    const x = application.relatedPeople.reduce<Record<string, string | null>>((acc, { person }) => {
      acc[person.id] = personFullName(person);
      return acc;
    }, {});

    application.relatedBusinesses.forEach(({ business }) => {
      x[business.id] = businessName(business);
    });

    return x;
  }, [application]);

  const sortedThreads = useMemo(() => {
    const groupedByThread = groupBy(notes, 'threadId');

    const sortedNotesByThread = mapValues(groupedByThread, (threadNotes) => sortBy(threadNotes, 'createdAt'));

    return sortedNotesByThread;
  }, [notes]);

  mixpanel.track_pageview({
    applicationId: opportunity.application.id,
    opportunityId: opportunity.id,
    productId: opportunity.productId,
    page: 'comments',
  });

  if (fetchingOpportunityRequirements || fetchingClosingTasks) {
    <LoadingPage />;
  }

  if (!notes.length) {
    return null;
  }

  return (
    <Flex gap={4} flexDirection={'column'}>
      {Object.entries(sortedThreads).map(([threadId, threadNotes]) => {
        const noteRelation = getNoteRelatedItems(threadNotes[0]!, opportunityRequirements ?? [], opportunityClosingTasks ?? [], product);

        return (
          <Thread
            key={threadId}
            threadNotes={threadNotes}
            threadId={threadId}
            relatedItemId={noteRelation.relatedItemId}
            relatedItemType={noteRelation.relatedItemType}
            threadTitleSuffixComponent={<ThreadTitleSuffixComponent noteRelation={noteRelation} entitiesNamesById={entitiesNamesByIds} />}
          />
        );
      })}
    </Flex>
  );
};
