import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import DocumentHubEmptyStateSvg from './assets/DocumentHubEmptyState.svg';

export const DocumentHubEmptyState: FC = () => (
  <Stack flex={1} alignItems={'center'} justifyContent={'center'} pt={10} gap={4}>
    <DocumentHubEmptyStateSvg />
    <Typography variant={'body1'} color={'textSecondary'}>
      {'No documents uploaded or requested for this application'}
    </Typography>
  </Stack>
);
