import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import type { DealApiModel } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useGetDeals = (partnerId: string, options?: UseQueryOptions<DealApiModel[]>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const getDealsQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const deals = await applicationServiceClient.getDeals({ partnerId, token });
    deals.forEach((deal) => {
      queryClient.setQueryData(['deal', deal.id, partnerId], deal);
    });

    return deals;
  }, [getAccessTokenSilently, partnerId, queryClient]);

  const query = useQuery<DealApiModel[]>({
    queryKey: ['deals', partnerId],
    queryFn: getDealsQueryFunction,
    ...options,
  });

  return query;
};
