import type { FinancialData } from '@lama/contracts';
import type { FC } from 'react';
import { DocumentSourceDescription } from './DocumentSourceDescription';
import { ManualSourceDescription } from './ManualSourceDescription';
import { CalculationSourceDescription } from './CalculationSourceDescription';
import { AdjustmentSourceDescription } from './AdjustmentSourceDescription';
import type { FieldDescriptionProps } from './types';
import { NoSourceDescription } from './NoSourceDescription';

type ValueSourceTypes = FinancialData['source']['type'] | 'NoSource';

export const valueDescriptionBySource: Record<ValueSourceTypes, FC<FieldDescriptionProps>> = {
  Document: DocumentSourceDescription,
  Adjustment: AdjustmentSourceDescription,
  Manual: ManualSourceDescription,
  Calculation: CalculationSourceDescription,
  NoSource: NoSourceDescription,
};
