import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext } from 'react';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { SegmentContainer } from '../../../Application/shared/SegmentContainer';
import { gc14FamilyLoanDetailsApplicationProperties } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/GatecityLoanDetailsRequirementScreen';
import { GenericDataGridSegment } from '../../../Application/shared/GenericDataGridSegment/GenericDataGridSegment';

export const GC14LoanDetailsView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        <SegmentContainer>
          <GenericDataGridSegment entity={application} propertyDefinitions={gc14FamilyLoanDetailsApplicationProperties} />
        </SegmentContainer>
      </Flex>
    </RequirementPrintContainer>
  );
};
