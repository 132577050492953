import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { createApplicationServiceClient } from '@lama/clients';
import type { OpportunityApiModel } from '@lama/clients';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useGetOpportunityQuery = (opportunityId: string | undefined, options?: UseQueryOptions<OpportunityApiModel | null>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityQuery = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    return applicationServiceClient.getOpportunity(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);

  const query = useQuery<OpportunityApiModel | null>({
    queryKey: ['opportunity', opportunityId],
    queryFn: getOpportunityQuery,
    ...options,
  });

  return query;
};
