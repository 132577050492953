import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../../ApplicationContext';
import { SegmentContainer } from '../../../shared/SegmentContainer';

export const HanoverLoanTermsTermsViewSegment: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);

  const termsData = useMemo<InfoLineProps[]>(
    () => [
      {
        label: 'Loan Type',
        values: opportunity.loanType,
      },
      {
        label: 'Product Code',
        values: '03 - SBA 7(a) Small',
      },
      {
        label: 'Loan Amount',
        values: application.requestedAmount,
        type: 'currency',
      },
      {
        label: 'Total Equity Injection',
        values: null,
      },

      {
        label: 'Total Project/Funds',
        values: null,
      },
      {
        label: 'Loan-To-Cost',
        values: null,
      },
      {
        label: 'Loan-To-Value',
        values: null,
      },
      {
        label: 'Loan Term',
        values: opportunity.terms?.termLength,
      },
      {
        label: 'Amortization',
        values: null,
      },
      {
        label: 'DSCR',
        values: null,
      },
      {
        label: 'Global DSCR',
        values: null,
      },
      {
        label: 'Prepayment Penalty',
        values: null,
      },
      {
        label: 'Risk Rating',
        values: null,
      },
      {
        label: 'Exception to Policy',
        values: opportunity.underwriting?.isPolicyException,
      },
      {
        label: 'SOP Approved Under',
        values: '50.10.7.1 ',
      },
      {
        label: 'Interest Rate',
        values: opportunity.terms?.rate?.benchmarkRate,
      },
      {
        label: 'Variable',
        values: opportunity.terms?.rate?.spread,
      },
      {
        label: 'Committee Date',
        values: null,
        tyoe: 'date',
      },
      {
        label: 'Loan Officer',
        values: null,
      },
      {
        label: 'Payment',
        values: null,
      },
      {
        label: 'Site Visit',
        values: null,
      },
      {
        label: 'Broker',
        values: null,
      },
      {
        label: 'Broker Fee',
        values: null,
      },
      {
        label: 'Revenue Code',
        values: null,
      },
      {
        label: 'Internal Referral Source',
        values: null,
      },
      {
        label: 'Packaging Fee',
        values: null,
      },
      {
        label: 'SBA Guarantee (%)',
        values: opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
        type: 'percent',
      },
      {
        label: 'Processing Method',
        values: null,
      },
      {
        label: 'HMDA Reportable',
        values: null,
      },
    ],
    [opportunity, application],
  );

  return (
    <SegmentContainer title={'Details'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
