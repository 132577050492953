import React, { useMemo, type FC } from 'react';
import { AuditLog } from '../../../AuditLog';
import type { AuditComponentProps } from '../types';

export const OpportunityCompleteApplicationExtensionAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const reason = audit[0]?.changes?.completeApplicationExtension?.reason;
  const text = useMemo(() => `Extended the time for submission by 30 days. Extension reason: "${reason}"`, [reason]);

  if (!reason) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={text}
      timestamp={groupInfo.timestamp}
    />
  );
};
