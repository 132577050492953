/* eslint-disable react/no-array-index-key */
import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { Skeleton } from '@mui/material';

const MetricsSkeleton: FC = () => (
  <Flex flexDirection={'column'} gap={4}>
    <Skeleton variant={'text'} width={150} height={50} />
    <Flex gap={4} flexWrap={'wrap'}>
      {Array.from({ length: 12 }).map((_, index) => (
        <Skeleton key={`metrics_skeleton_${index}`} variant={'rectangular'} width={300} height={120} />
      ))}
    </Flex>
  </Flex>
);

const InsightsSkeleton: FC = () => (
  <Flex flex={0.5} flexDirection={'column'} gap={4}>
    <Skeleton variant={'text'} width={150} height={50} />
    <Flex gap={4} flexDirection={'column'}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={`insight_skeleton_${index}`} variant={'rectangular'} height={100} />
      ))}
    </Flex>
  </Flex>
);

const BarChartSkeleton: FC = () => (
  <Flex height={'280px'} gap={8} alignItems={'flex-end'}>
    <Skeleton variant={'rectangular'} width={'100%'} height={'80%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'60%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'25%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'50%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'30%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'50%'} />
    <Skeleton variant={'rectangular'} width={'100%'} height={'70%'} />
  </Flex>
);

const TrendsSkeleton: FC = () => (
  <Flex flex={0.5} flexDirection={'column'} gap={4} width={'100%'}>
    <Skeleton variant={'text'} width={150} height={50} />
    <Flex gap={8} flexDirection={'column'}>
      {Array.from({ length: 2 }).map((_, index) => (
        <Flex key={`trend_skeleton_${index}`} flexDirection={'column'} gap={4}>
          <Skeleton variant={'text'} width={100} height={40} />
          <BarChartSkeleton />
        </Flex>
      ))}
    </Flex>
  </Flex>
);

const SummarySkeleton: FC = () => (
  <Flex gap={4} flexDirection={'column'}>
    <Skeleton variant={'text'} width={150} height={50} />
    <Skeleton variant={'rectangular'} width={'100%'} height={100} />
  </Flex>
);

export const InsightsPageSkeleton: FC = () => (
  <Flex flexDirection={'column'} gap={16}>
    <SummarySkeleton />
    <MetricsSkeleton />
    <Flex gap={8} width={'100%'}>
      <InsightsSkeleton />
      <TrendsSkeleton />
    </Flex>
  </Flex>
);
