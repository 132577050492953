import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { CellContent } from '../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { SegmentContainer } from '../../../shared/SegmentContainer';

const headers = [
  { text: 'Insurance Type', width: 30 },
  { text: 'Required', width: 14 },
  { text: 'Comments if necessary', width: 14 },
];

const titles = [
  'Life',
  'Disability',
  'Liability',
  'Dram Shop/Liquor Liability',
  'Malpractice',
  'Workers Compensation',
  'Other (state what it is)',
];

const rows = titles.map<CellContent[]>((item) => [{ value: item }, { value: null, align: 'right' }, { value: null, align: 'right' }]);

export const HanoverClosingConditionsSection: FC = () => (
  <Flex flexDirection={'column'} gap={8}>
    <InlineNote title={'Pre-Closing Conditions'} noteName={'Pre-Closing Conditions'} valueTemplate={undefined} placeholder={undefined} />
    <InlineNote title={'Post-Closing Conditions'} noteName={'Post-Closing Conditions'} valueTemplate={undefined} placeholder={undefined} />
    <CreditMemoTable headers={headers} rows={rows} />
    <SegmentContainer title={'Landlord'}>
      <Flex flexDirection={'column'} gap={8}>
        <InlineNote
          title={'Is the property leased?'}
          noteName={'Is the property leased'}
          valueTemplate={undefined}
          placeholder={'If no, skip this section.  If yes, enter information below'}
        />
        <InlineNote
          title={'Is a lease required for the term of the loan?'}
          noteName={'Is a lease required for the term of the loan?'}
          valueTemplate={undefined}
          placeholder={'If Not, please explain why.'}
        />
        <InlineNote
          title={'Is a landlord waiver required?'}
          noteName={'Is a landlord waiver required?'}
          valueTemplate={undefined}
          placeholder={'If No, explain why'}
        />
      </Flex>
    </SegmentContainer>
  </Flex>
);
