export const funnelData = [
  {
    id: 'prequal',
    value: 52,
    label: 'Prequal',
  },
  {
    id: 'application',
    value: 30,
    label: 'Application',
  },
  {
    id: 'verification',
    value: 26,
    label: 'Verification',
  },
  {
    id: 'processing',
    value: 20,
    label: 'Processing',
  },
  {
    id: 'opened',
    value: 12,
    label: 'Opened',
  },
];
