import { Button, Flex } from '@lama/design-system';
import React from 'react';

export const SaveFormButton = ({ disabled, loading, submit }: { disabled: boolean; loading: boolean; submit: () => void }) => (
  <Flex justifyContent={'flex-end'} width={'100%'}>
    <Button
      type={'submit'}
      disabled={disabled}
      color={'primary'}
      variant={'primary'}
      loading={loading}
      width={'130px'}
      height={'40px'}
      onClick={submit}
    >
      {'Save'}
    </Button>
  </Flex>
);
