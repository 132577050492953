import type { ApplicationApiModel } from '@lama/clients';
import type { UserDetails } from '../context/UserDetailsContext';

export const isAllowedToEditApplication = (
  { originatingPartner }: ApplicationApiModel,
  { permissions, partner, segments, partnerPermissions }: UserDetails,
) =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  permissions?.includes('manage:applications') ||
  segments?.includes('demo') ||
  partner === originatingPartner ||
  Object.keys(partnerPermissions ?? {}).includes(originatingPartner);
