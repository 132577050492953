import type { AgGridReact } from '@ag-grid-community/react';
import { useCallback, useImperativeHandle, useRef } from 'react';
import type { ForwardedRef } from 'react';
import type { SpreadTableRef } from './types';

export const useExportAGGridToExcel = (ref: ForwardedRef<SpreadTableRef>, fileName: string, sheetName: string) => {
  const gridRef = useRef<AgGridReact>(null);
  const getSpread = useCallback(
    (sheetNameOverride?: string) => {
      if (!gridRef.current) {
        return;
      }

      return gridRef.current.api.getSheetDataForExcel({
        fileName,
        sheetName: sheetNameOverride || sheetName,
        processRowGroupCallback: (params) => {
          if (params.node.group) {
            return params.node.key;
          }
          return params.node.data.attributeHierarchy;
        },
      });
    },
    [fileName, sheetName, gridRef],
  );

  const exportSpread = useCallback(async () => {
    gridRef.current?.api.exportDataAsExcel({
      fileName,
      sheetName,
      processRowGroupCallback: (params) => {
        if (params.node.group) {
          return params.node.key;
        }
        return params.node.data.attributeHierarchy;
      },
    });
  }, [fileName, sheetName]);

  useImperativeHandle(
    ref,
    () => ({
      getSpread,
      exportSpread,
    }),
    [getSpread, exportSpread],
  );
  return {
    exportSpread,
    getSpread,
    gridRef,
  };
};
