import { LoadingPage } from '@lama/app-components';
import { Stack } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { useProductsByPartnerQuery } from '../../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { usePartner } from '../../../shared/hooks/usePartner';
import { ProductCard } from './ProductCard';
import { CreateProductFromTemplateCard } from './ProductTemplates/CreateProductFromTemplateCard';

export const ProductCardList: FC = () => {
  const partner = usePartner();
  const { data: products, isPending: loadingProducts } = useProductsByPartnerQuery(partner);

  const orderedProducts = useMemo(() => orderBy(products, ['name'], ['asc']), [products]);

  if (loadingProducts) {
    return <LoadingPage />;
  }

  return (
    <Stack width={'100%'} gap={4} direction={'row'} flexWrap={'wrap'}>
      {orderedProducts.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
      <CreateProductFromTemplateCard />
    </Stack>
  );
};
