import { useAuth0 } from '@auth0/auth0-react';
import { displayToast } from '@lama/app-components';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../../../framework/queryClient';
import { applicationServiceClient } from '../../../../../shared/clients/applicationServiceClient';

export const useDeleteUnderwritingNoteMutation = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (noteId: string) => {
      const token = await getAccessTokenSilently();

      return applicationServiceClient.deleteNote(opportunityId, noteId, token);
    },
    onMutate: async (noteId: string) => {
      await queryClient.cancelQueries({ queryKey: ['opportunity', opportunityId] });

      const previousOpportunity = queryClient.getQueryData(['opportunity', opportunityId]);

      if (!previousOpportunity) {
        return;
      }

      queryClient.setQueryData(['opportunity', opportunityId], (old: any) => ({
        ...old,
        underwriting: {
          ...old.underwriting,
          notes: old.underwriting?.notes?.filter((note: any) => note.id !== noteId),
        },
      }));

      return { previousOpportunity };
    },
    onError: async (err, variables, context) => {
      if (!context?.previousOpportunity) {
        return;
      }

      queryClient.setQueryData(['opportunity', opportunityId], context.previousOpportunity);

      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['opportunity', opportunityId] });
    },
  });
};
