import type { FC } from 'react';
import React from 'react';

export const FileXIcon: FC<{ color?: string; size?: string }> = ({ color = 'black', size = 24 }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z'
      }
      stroke={color}
      strokeWidth={'1.4'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20M14.5 12.5L9.5 17.5M9.5 12.5L14.5 17.5'}
      stroke={color}
      strokeWidth={'1.4'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
