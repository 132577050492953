import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';
import type { CreateCondition } from '@lama/clients';
import type { Condition } from '@lama/contracts';
import { Flex, Button, Text, greyPalette } from '@lama/design-system';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { ConditionConfiguration } from '@lama/conditions';
import { getConditionConfig } from '@lama/conditions';
import { AutoApprovalEmptyState } from './AutoApprovalEmptyState';
import { AddConditionMenu } from './ConditionComponents/AddConditionMenu';
import { ConditionCardV2 } from './ConditionComponents/ConditionCardV2';
import { AutoApprovalContext } from './context/AutoApprovalContext';

export const AutoApprovalSettingsPage = () => {
  const { conditionId } = useParams<{ productId?: string; conditionId?: string }>();
  const { conditions, addCondition, updateCondition } = useContext(AutoApprovalContext);
  const navigate = useNavigate();

  const onAddCondition = useCallback(
    async (condition: CreateCondition) => {
      addCondition(condition);
      navigate(`./${condition.id}`);
    },
    [navigate, addCondition],
  );

  const menuRef = useRef(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);

  const conditionsRecord = useMemo(
    () =>
      Object.fromEntries(
        conditions?.map((condition) => {
          const config = getConditionConfig(condition);

          return [condition.id, { condition, config }];
        }) ?? [],
      ) as Record<string, { condition: Condition; config: ConditionConfiguration }>,
    [conditions],
  );

  if (conditionId) {
    return <Outlet />;
  }

  return (
    <>
      <Flex flexDirection={'column'} gap={8}>
        <Flex flex={1} border={`0.5px solid ${greyPalette[300]}`} height={'1px'} borderRadius={4} />
        <Flex alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
          <Text variant={'body2'} color={greyPalette[700]}>
            {'Applications meeting '}
            <span style={{ fontWeight: 700 }}>{'all'}</span>
            {' these rules will be automatically approved.'}
          </Text>
          <Button ref={menuRef} variant={'secondary'} color={'primary'} size={'l'} onClick={toggleMenuOpen}>
            {'Add Rule'}
          </Button>
        </Flex>
        {Object.keys(conditionsRecord)?.length ? (
          Object.values(conditionsRecord).map(({ condition, config }) => (
            <ConditionCardV2 key={condition.id} condition={condition} config={config} onChange={updateCondition} />
          ))
        ) : (
          <AutoApprovalEmptyState />
        )}
      </Flex>
      <AddConditionMenu menuRef={menuRef} open={menuOpen} onClose={toggleMenuOpen} addCondition={onAddCondition} />
    </>
  );
};
