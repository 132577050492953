import type { PipelineItem } from '@lama/pipeline-items-service-client';
import type { SearchResult } from './types';

export const pipelineItemToSearchResult = (pipelineItem: PipelineItem): SearchResult => ({
  opportunityId: pipelineItem.opportunityId,
  applicationNumber: pipelineItem.applicationNumber,
  applicationStatus: pipelineItem.applicationStatus,
  loanDisplayName: pipelineItem.loanDisplayName,
  primaryContactFullName: pipelineItem.primaryContactFullName,
  comment: '',
});
