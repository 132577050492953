import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { EmailTracking } from '@lama/email-sender-client';
import { emailSenderServiceClient } from '../../../clients/emailSenderServiceClient';

export const useOpportunityEmailTrackingsQuery = (opportunityId: string, options?: Partial<UseQueryOptions<EmailTracking[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getEmailTrackingsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const emailTrackings = await emailSenderServiceClient.getEmailsTracking({ opportunityId }, token);
    return emailTrackings ?? [];
  }, [opportunityId, getAccessTokenSilently]);

  const query = useQuery<EmailTracking[]>({ queryKey: ['emailTrackings', opportunityId], queryFn: getEmailTrackingsQuery, ...options });

  return query;
};
