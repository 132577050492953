import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ProductApiModel } from '@lama/contracts';
import { useAuth0 } from '@auth0/auth0-react';
import { productServiceClient } from '../../../clients/productService';

export const useProductsByPartnerQuery = (
  partner: string | undefined,
  options?: { includeDisabled?: boolean },
  queryOptions?: UseQueryOptions<ProductApiModel[] | null>,
): UseQueryResult<ProductApiModel[] | null> => {
  const { getAccessTokenSilently } = useAuth0();

  const getProductQuery = async () => {
    if (!partner) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const products = await productServiceClient.getProducts(partner, token, options);

    return products;
  };

  return useQuery({
    queryKey: ['products', `includeDisabled=${!!options?.includeDisabled}`, partner],
    queryFn: getProductQuery,
    ...queryOptions,
  });
};
