import React from 'react';
import { Card } from '@mui/material';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import Add from '../assets/add.svg';
import { getAssetUrl } from '../../../../shared/utils/getAssetUrl';

export const CreateProductFromTemplateCard: FC = () => (
  <Link to={'templates'} style={{ textDecoration: 'none' }}>
    <Card
      elevation={0}
      sx={{
        padding: '36px 32px',
        minWidth: '310px',
        aspectRatio: '2/1',
        backgroundImage: `url(${getAssetUrl('templateCardBg.png')})`,
        backgroundSize: 'contain',
        border: '2px dashed #E0E0E0',
        borderRadius: '10px',
        cursor: 'pointer',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: '0px 8px 33px 0px #3800641F',
        },
      }}
    >
      <Flex flexDirection={'column'} gap={4} justifyContent={'center'} alignItems={'center'}>
        <Add />
        <Text variant={'body1'} color={'text.secondary'}>
          {'Create From Template'}
        </Text>
      </Flex>
    </Card>
  </Link>
);
