import type { FC } from 'react';
import React from 'react';

export const PrintContext = React.createContext<
  | {
      printMode: boolean;
    }
  | undefined
>(undefined);

interface ExportProviderProps {
  children: React.ReactNode;
}

const printModeContextValue = {
  printMode: true,
};

export const PrintContextProvider: FC<ExportProviderProps> = ({ children }) => (
  <PrintContext.Provider value={printModeContextValue}>{children}</PrintContext.Provider>
);
