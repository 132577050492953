import type { UseOfFundsBreakdown } from '@lama/contracts';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import type { InfoLineProps } from '@lama/app-components';
import { InfoLine } from '@lama/app-components';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';

export const UseOfFundsSegment: FC<{
  breakdown: UseOfFundsBreakdown;
}> = ({ breakdown }) => {
  const { type, description, amount } = breakdown;
  const info: InfoLineProps[] = [
    {
      label: 'Amount',
      values: amount ?? 0,
      type: 'currency' as const,
    },
  ];

  return (
    <BorderedContainer>
      <Text variant={'body1'} color={greyPalette[500]} pb={2}>
        {type}
      </Text>
      <Flex flexDirection={'column'} gap={4}>
        {info.map((line) => (
          <InfoLine key={line.label} label={line.label} values={line.values} type={line.type} />
        ))}
      </Flex>
      <Flex flexDirection={'column'} gap={2}>
        <Text variant={'body2'} color={greyPalette[500]}>
          {'Description'}
        </Text>
        <Text variant={'body2'} paragraph>
          {description}
        </Text>
      </Flex>
    </BorderedContainer>
  );
};
