import { useQuery } from '@tanstack/react-query';
import type { OpportunityInsights } from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useOpportunityInsightsQuery = (opportunityId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunityInsights = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return applicationServiceClient.getOpportunityInsights(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);

  const query = useQuery<OpportunityInsights | null>({
    queryKey: ['opportunity', opportunityId, 'insights'],
    queryFn: getOpportunityInsights,
  });

  return query;
};
