import React from 'react';
import type { FC } from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import type { CellContent } from '../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../creditMemoTable/CreditMemoTable';
import { InlineNote } from '../../../shared/InlineNote/InlineNote';
import { SegmentContainer } from '../../../shared/SegmentContainer';

const guarantorsHeaders = [
  { text: 'Guarantor Type', width: 30 },
  { text: 'Guarantor Name', width: 14 },
  { text: 'Address', width: 14 },
  { text: 'SSN', width: 14 },
];

const creditBureausHeaders = [
  { text: 'Credit Bureau', width: 30 },
  { text: 'Score', width: 14 },
  { text: 'Date', width: 14 },
];

const debtScheduleHeaders = [
  { text: 'Type', width: 30 },
  { text: 'Description', width: 14 },
  { text: 'Monthly Payment', width: 14 },
];

const creditBureaus = ['Experian', 'Equifax', 'TransUnion'];

const creditBureausRows = creditBureaus.map<CellContent[]>((item) => [
  { value: item },
  { value: null, align: 'right' },
  { value: null, align: 'right' },
]);

const termsData: InfoLineProps[] = [
  {
    label: '30-Day Delinquencies',
    values: null,
  },
  {
    label: '90-Day Delinquencies',
    values: null,
  },
  {
    label: 'Bankruptcies',
    values: null,
  },
  {
    label: '60-Day Delinquencies',
    values: null,
  },
  {
    label: 'Other Delinquencies',
    values: null,
  },
  {
    label: 'Total Payments Past Due',
    values: null,
  },
  {
    label: 'Fraud Shield Cleared',
    values: null,
  },
];

export const HanoverGuarantorAnalysisSection: FC = () => (
  <Flex flexDirection={'column'} gap={8}>
    <CreditMemoTable headers={guarantorsHeaders} rows={[]} />
    <InlineNote title={'Biographical Summary'} noteName={'Biographical Summary'} valueTemplate={undefined} placeholder={undefined} />
    <SegmentContainer title={'Credit Report'}>
      <Flex flexDirection={'column'} gap={4}>
        <CreditMemoTable headers={creditBureausHeaders} rows={creditBureausRows} />
        <Grid columns={3}>
          {termsData.map((data) => (
            <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
          ))}
        </Grid>
        <InlineNote
          title={'Fraud Shield Not Cleared Explanation'}
          noteName={'Fraud Shield Not Cleared Explanation'}
          valueTemplate={undefined}
          placeholder={undefined}
        />
      </Flex>
    </SegmentContainer>
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'h6'}>{'Debt Schedule as of '}</Text>
      <CreditMemoTable headers={debtScheduleHeaders} rows={[]} />
    </Flex>
    <InlineNote title={'Credit Report Comments'} noteName={'Credit Report Comments'} valueTemplate={undefined} placeholder={undefined} />
  </Flex>
);
