import React from 'react';

export const LightBulbIcon: React.FC<{ width?: string; height?: string; color?: string }> = ({
  height = '20px',
  width = '20px',
  color = '#714EF5',
}) => (
  <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 10 14'} fill={'none'}>
    <path
      d={
        'M5.00002 13.3846C4.66328 13.3846 4.3729 13.2679 4.12887 13.0346C3.88486 12.8013 3.75217 12.5162 3.73079 12.1795H6.26926C6.24788 12.5162 6.11518 12.8013 5.87117 13.0346C5.62715 13.2679 5.33677 13.3846 5.00002 13.3846ZM3.00002 11.2564C2.85815 11.2564 2.73935 11.2085 2.64362 11.1128C2.5479 11.0171 2.50004 10.8983 2.50004 10.7564C2.50004 10.6145 2.5479 10.4957 2.64362 10.4C2.73935 10.3043 2.85815 10.2564 3.00002 10.2564H7.00002C7.1419 10.2564 7.2607 10.3043 7.35642 10.4C7.45215 10.4957 7.50001 10.6145 7.50001 10.7564C7.50001 10.8983 7.45215 11.0171 7.35642 11.1128C7.2607 11.2085 7.1419 11.2564 7.00002 11.2564H3.00002ZM2.60259 9.33332C1.90431 8.89913 1.35154 8.33183 0.944274 7.6314C0.537007 6.93098 0.333374 6.16496 0.333374 5.33334C0.333374 4.0342 0.786363 2.93164 1.69234 2.02565C2.59833 1.11968 3.70089 0.666687 5.00002 0.666687C6.29916 0.666687 7.40172 1.11968 8.30771 2.02565C9.21369 2.93164 9.66667 4.0342 9.66667 5.33334C9.66667 6.16496 9.46304 6.93098 9.05577 7.6314C8.64851 8.33183 8.09574 8.89913 7.39746 9.33332H2.60259ZM2.90002 8.33334H7.10002C7.60002 7.97778 7.98613 7.53889 8.25836 7.01667C8.53058 6.49445 8.66669 5.93334 8.66669 5.33334C8.66669 4.31112 8.31113 3.44445 7.60002 2.73334C6.88891 2.02223 6.02225 1.66667 5.00002 1.66667C3.9778 1.66667 3.11114 2.02223 2.40002 2.73334C1.68891 3.44445 1.33336 4.31112 1.33336 5.33334C1.33336 5.93334 1.46947 6.49445 1.74169 7.01667C2.01391 7.53889 2.40002 7.97778 2.90002 8.33334Z'
      }
      fill={color}
    />
  </svg>
);
