import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Flex, Text, Button } from '@lama/design-system';
import type { Matcher } from '@lama/contracts';
import { formatMatcher } from '@lama/conditions';
import { conditionV2ByTypeComponent } from '../conditionComponentByType';
import { getMatcherConfig } from './matcherConfigurations';
import type { MatcherValue } from './types';

export interface MatcherCardProps {
  matcher: Matcher;
  setMatcher: (matcher: Matcher) => void;
  onRemove: () => void;
}

export const MatcherCard: FC<MatcherCardProps> = ({ matcher, setMatcher, onRemove }) => {
  const config = useMemo(() => getMatcherConfig(matcher), [matcher]);

  const setMatcherValue = useCallback(
    (value: MatcherValue) => {
      if (!config) {
        return;
      }

      setMatcher({ ...matcher, ...value, displayName: matcher.displayName ?? config.displayName });
    },
    [config, matcher, setMatcher],
  );

  const MatcherComponent = useMemo(() => {
    if (!matcher || !config) {
      return null;
    }

    return conditionV2ByTypeComponent(config, setMatcherValue, matcher);
  }, [config, matcher, setMatcherValue]);

  if (!config) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} border={'1px solid #E0E0E0'} borderRadius={'8px'}>
      <Flex
        flexDirection={'row'}
        px={6}
        py={5}
        justifyContent={'space-between'}
        backgroundColor={'grey.50'}
        borderRadius={'8px 8px 0px 0px'}
      >
        <Flex gap={2} alignItems={'center'}>
          <Text variant={'body2'}>{config.displayName}</Text>
          <Text variant={'body2'} color={'secondary'}>
            {formatMatcher(matcher)}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} p={6} gap={6}>
        {MatcherComponent ?? null}
        <Flex flexDirection={'row'} justifyContent={'flex-start'}>
          <Button variant={'tertiary'} color={'danger'} size={'l'} onClick={onRemove} type={'button'}>
            {'Delete Condition'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
