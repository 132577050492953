import { LoadingPage } from '@lama/app-components';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useGetDeals } from '../../shared/hooks/react-query/deal/useGetDeals';
import { DealsBoard } from './DealBoard/DealBoard';

const DealsPage: FC = () => {
  const { partner } = useContext(UserDetailsContext);
  const { data: deals, isPending: loadingDeals } = useGetDeals(partner ?? '');

  if (loadingDeals) {
    return <LoadingPage />;
  }

  if (!deals?.length) {
    return <span>{' No deals found '}</span>;
  }

  return <DealsBoard deals={deals} />;
};

export default DealsPage;
