/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import {
  applicationPrimaryBorrowerIdSelector,
  relatedBusinessesByRelationsSelector,
  relatedPeopleByRelationsSelector,
  businessFullAddress,
  personFullAddress,
  applicationGuarantorsSelector,
} from '@lama/data-formatters';
import { SegmentContainer } from '../../../../shared/SegmentContainer';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

const headers = [
  {
    text: 'Name',
  },
  {
    text: 'Borrower Type',
  },
  {
    text: 'Guarantee Type',
  },
  {
    text: 'Contingent Percentage',
  },
  {
    text: 'Contingent Amount',
  },
  {
    text: 'Address',
  },
  {
    text: 'Phone Number',
  },
  {
    text: 'Tax ID',
  },
];

interface RelavantEntitiesRows {
  businesses: {
    borrowers: CellContent[][];
    guarantors: CellContent[][];
  };
  people: {
    borrowers: CellContent[][];
    guarantors: CellContent[][];
  };
}

export const GrasshopperBorrowersTable: FC = () => {
  const { application } = useContext(ApplicationContext);
  const primaryBorrowerId = applicationPrimaryBorrowerIdSelector(application);

  const relevantEntitiesRows: RelavantEntitiesRows = useMemo(
    () => ({
      businesses: {
        borrowers: relatedBusinessesByRelationsSelector(application, ['borrower'])
          .map(({ business }) => business)
          .map((borrowerBusiness) => [
            {
              value: borrowerBusiness.legalName ?? '-',
            },
            {
              value: borrowerBusiness.id === primaryBorrowerId ? 'Borrower' : 'Co-Borrower',
            },
            {
              value: 'Joint & several',
            },
            {
              value: borrowerBusiness.contingentPercentage ?? '-',
              type: 'percent',
            },
            {
              value: borrowerBusiness.contingentAmount ?? '-',
              type: 'currency',
            },
            {
              value: businessFullAddress(borrowerBusiness),
            },
            {
              value: borrowerBusiness.phoneNumber ?? '-',
            },
            {
              value: borrowerBusiness.tin ?? '-',
            },
          ]),
        guarantors: relatedBusinessesByRelationsSelector(application, ['guarantor'])
          .map(({ business }) => business)
          .map((guarantorBusiness) => [
            {
              value: guarantorBusiness.legalName ?? '-',
            },
            {
              value: 'Guarantor',
            },
            {
              value: 'Joint & several',
            },
            {
              value: guarantorBusiness.contingentPercentage ?? '-',
              type: 'percent',
            },
            {
              value: guarantorBusiness.contingentAmount ?? '-' ?? '-',
              type: 'currency',
            },
            {
              value: businessFullAddress(guarantorBusiness),
            },
            {
              value: guarantorBusiness.phoneNumber ?? '-',
            },
            {
              value: guarantorBusiness.tin ?? '-',
            },
          ]),
      },
      people: {
        borrowers: relatedPeopleByRelationsSelector(application, ['borrower'])
          .map(({ person }) => person)
          .map((borrowerPerson) => [
            {
              value: `${borrowerPerson.firstName} ${borrowerPerson.lastName}`,
            },
            {
              value: borrowerPerson.id === primaryBorrowerId ? 'Borrower' : 'Co-Borrower',
            },
            {
              value: 'Joint & several',
            },
            {
              value: borrowerPerson.contingentPercentage ?? '-',
              type: 'percent',
            },
            {
              value: borrowerPerson.contingentAmount ?? '-',
              type: 'currency',
            },
            {
              value: personFullAddress(borrowerPerson),
            },
            {
              value: borrowerPerson.phoneNumber ?? '-',
            },
            {
              value: '-',
            },
          ]),
        guarantors: applicationGuarantorsSelector(application).map((guarantorPerson) => [
          {
            value: `${guarantorPerson.firstName} ${guarantorPerson.lastName}`,
          },
          {
            value: 'Guarantor',
          },
          {
            value: 'Joint & several',
          },
          {
            value: guarantorPerson.contingentPercentage ?? '-',
            type: 'percent',
          },
          {
            value: guarantorPerson.contingentAmount ?? '-',
            type: 'currency',
          },
          {
            value: personFullAddress(guarantorPerson),
          },
          {
            value: guarantorPerson.phoneNumber ?? '-',
          },
          {
            value: '-',
          },
        ]),
      },
    }),
    [application, primaryBorrowerId],
  );

  const rows: CellContent[][] = useMemo(
    () => [
      ...relevantEntitiesRows.businesses.borrowers,
      ...relevantEntitiesRows.businesses.guarantors,
      ...relevantEntitiesRows.people.borrowers,
      ...relevantEntitiesRows.people.guarantors,
    ],
    [relevantEntitiesRows],
  );

  return (
    <SegmentContainer title={'Borrowers'} bordered={false}>
      <CreditMemoTable headers={headers} rows={rows} />
    </SegmentContainer>
  );
};
