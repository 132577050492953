import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { PointTooltip } from '@nivo/line';
import { ResponsiveLine } from '@nivo/line';
import { lineData } from './lineData';

export const LineToolTip: PointTooltip = ({
  point: {
    data: { x, y },
  },
}) => (
  <Stack sx={{ backgroundColor: '#ffffff' }} borderRadius={'4px'} alignItems={'center'} justifyContent={'center'} py={0.5} px={1}>
    <Typography variant={'tooltip'}>{`${x} - ${y}`}</Typography>
  </Stack>
);
export const AccountOpeningChart: FC = () => (
  <Stack display={'flex'} flex={1} height={'400px'} width={'100%'} spacing={1} p={2} sx={{ border: `1px solid ${grey[300]}` }}>
    <Typography variant={'body1'}>{'Account Opening'}</Typography>
    <ResponsiveLine
      data={lineData}
      enableGridY={false}
      margin={{ top: 50, right: 10, bottom: 50, left: 30 }}
      xScale={{ type: 'point' }}
      colors={['#DCD4FD', '#FFDDA6', '#C1E2F4', '#BEEEDF', '#F9C3C1']}
      curve={'basis'}
      axisTop={null}
      axisRight={null}
      enablePoints={false}
      useMesh
      enableArea
      tooltip={LineToolTip}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          symbolShape: 'circle',
          translateX: -20,
          translateY: -40,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  </Stack>
);
