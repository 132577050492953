import type { FC } from 'react';
import React from 'react';
import type { BusinessApiModel } from '@lama/business-service-client';
import type { RequirementScreenProps } from '../types';
import { RelationsList } from './RelationsList';

const modifyBusinessPayload = (values: Partial<BusinessApiModel>) => {
  if (values.addresses?.[0]) {
    return { ...values, isProperty: true };
  }

  return values;
};

export const PropertiesList: FC<RequirementScreenProps> = (props) => (
  <RelationsList
    {...props}
    relation={'property'}
    allowedEntityTypes={['business']}
    title={'Properties'}
    searchExistingCustomersAllowed={false}
    modifyBusinessPayload={modifyBusinessPayload}
  />
);
