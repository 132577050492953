import React from 'react';
import { Flex, Text } from '@lama/design-system';

export const InfoType: React.FC<{ label: string }> = ({ label }) => (
  <Flex gap={2} alignItems={'center'} flex={0.45}>
    <Text variant={'body2'} color={'text.secondary'}>
      {label}
    </Text>
  </Flex>
);
