/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Chip } from '@mui/material';
import type { CollateralType } from '@lama/contracts';
import {
  HomeWorkOutlined,
  ReceiptLongRounded,
  CottageOutlined,
  DirectionsCarOutlined,
  Inventory2Outlined,
  ConstructionRounded,
  DeckRounded,
} from '@mui/icons-material';

const chipStyles = {
  PersonalResidence: { bgcolor: '#E1F5FE', color: '#0288D1' },
  Inventory: { bgcolor: '#E0F2F1', color: '#00796B' },
  Equipment: { bgcolor: '#FBE9E7', color: '#E64A19' },
  Invoices: { bgcolor: '#FFF8E1', color: '#FFA000' },
  CommercialRe: { bgcolor: '#FCE4EC', color: '#C2185B' },
  PersonalVehicle: { bgcolor: '#E0F7FA', color: '#0097A7' },
  Other: { bgcolor: '#F3F0FF', color: '#714EF5' },
};

const typeToChipStyles: Record<CollateralType, { style: {}; Icon?: React.FC }> = {
  'Commercial RE': { style: chipStyles.CommercialRe, Icon: () => <HomeWorkOutlined fontSize={'small'} /> },
  'Invoices / AR': { style: chipStyles.Invoices, Icon: () => <ReceiptLongRounded fontSize={'small'} /> },
  'Personal Home': { style: chipStyles.PersonalResidence, Icon: () => <CottageOutlined fontSize={'small'} /> },
  'Personal Vehicle': { style: chipStyles.PersonalVehicle, Icon: () => <DirectionsCarOutlined fontSize={'small'} /> },
  'Commercial Vehicle': { style: chipStyles.PersonalVehicle, Icon: () => <DirectionsCarOutlined fontSize={'small'} /> },
  Inventory: { style: chipStyles.Inventory, Icon: () => <Inventory2Outlined fontSize={'small'} /> },
  Equipment: { style: chipStyles.Equipment, Icon: () => <ConstructionRounded fontSize={'small'} /> },
  'Furniture and Fixtures (FF&E)': { style: chipStyles.Equipment, Icon: () => <DeckRounded fontSize={'small'} /> },
  Other: { style: chipStyles.Other },
};

export const CollateralTypeChip = ({ type, size }: { type: CollateralType; size?: 'medium' | 'small' }) => {
  const { style, Icon } = typeToChipStyles[type];
  return (
    <Chip
      label={type}
      size={size}
      variant={'outlined'}
      sx={{ ...style, border: 0, pl: Icon ? 0.5 : 0 }}
      {...(Icon ? { icon: <Icon /> } : null)}
    />
  );
};
