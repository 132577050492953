export type UnderwritingNoteRelatedSectionType = 'closingTask' | 'creditMemoSection' | 'requirement' | 'underwriting';

export const getUnderwritingNoteRelatedItemProperty = (relatedItemType: UnderwritingNoteRelatedSectionType) => {
  switch (relatedItemType) {
    case 'closingTask': {
      return 'relatedTaskId';
    }
    case 'creditMemoSection': {
      return 'relatedSection';
    }
    case 'requirement': {
      return 'relatedRequirementId';
    }
    case 'underwriting': {
      return 'relatedUnderwritingSectionId';
    }
  }
};
