import type { Address } from '@lama/contracts';
import { stateNameToAbbreviation } from '@lama/contracts';

export const getShortAddressInfoLine = (address?: Address) => {
  if (!address) {
    return '';
  }

  const { city, state } = address;

  return [city, stateNameToAbbreviation(state ?? '')].filter(Boolean).join(', ');
};

export const getFullAddressInfoLine = (address?: Address) => {
  if (!address) {
    return '';
  }

  const { address1, address2, city, state, zip } = address;

  return [address1, address2, city, state, zip].filter(Boolean).join(', ');
};
