import { useContext, useCallback } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { ApplicationContext } from '../../../../ApplicationContext';
import { useUpdateOpportunityClosingTaskMutation } from '../../useUpdateClosingTaskMutation';

export const useMarkTaskPending = (task: OpportunityClosingTask) => {
  const { opportunity } = useContext(ApplicationContext);
  const { mutate: updateOpportunityClosingTask, isPending: updatingTaskStatus } = useUpdateOpportunityClosingTaskMutation(opportunity.id);
  const markAsPending = useCallback(() => {
    updateOpportunityClosingTask({
      taskId: task.id,
      taskUpdate: { status: 'Pending', completedAt: null, assigneeIds: [] },
    });
  }, [task.id, updateOpportunityClosingTask]);
  return { markAsPending, markingTaskAsPending: updatingTaskStatus };
};
