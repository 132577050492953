import React, { useMemo } from 'react';
import { formatAlertMessage, type Alert } from '@lama/alerts-service-client';
import { Flex, Text } from '@lama/design-system';
import { ErrorOutline } from '@mui/icons-material';
import type { FC } from 'react';
import { sortBy } from 'lodash-es';
import styled from 'styled-components';
import { Tooltip } from '@lama/app-components';
import { isAlertRelevantNow } from '../hooks/isAlertRelevantNow';

interface AlertCellProps {
  alerts: Alert[] | undefined;
}

const WrappedText = styled(Text)`
  text-wrap: wrap;
`;

export const AlertCell: FC<AlertCellProps> = ({ alerts }) => {
  const relevantAlert = useMemo(() => {
    if (!alerts) {
      return null;
    }

    const alert = sortBy(alerts, (a) => a.sticky).find(isAlertRelevantNow);

    return formatAlertMessage(alert);
  }, [alerts]);

  if (!relevantAlert) {
    return null;
  }

  return (
    <Flex alignItems={'center'} gap={1} minWidth={0}>
      <ErrorOutline fontSize={'small'} color={'warning'} />
      <Tooltip title={relevantAlert}>
        <WrappedText variant={'body3'} maxLines={2}>
          {relevantAlert}
        </WrappedText>
      </Tooltip>
    </Flex>
  );
};
