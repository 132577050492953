import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { OpportunitySpreadConfiguration } from '@lama/partner-service-client';

import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createApplicationServiceClient } from '@lama/clients';
import { applicationServiceBaseUrl } from '../../../../framework/environment';

const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl);

export const useGetOpportunitySpreadsConfigurationQuery = (
  opportunityId?: string,
  options?: Partial<UseQueryOptions<OpportunitySpreadConfiguration | null>>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const getOpportunitySpreadsConfigurationFunction = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    return applicationServiceClient.getOpportunitySpreadConfiguration(opportunityId, token);
  }, [getAccessTokenSilently, opportunityId]);

  return useQuery<OpportunitySpreadConfiguration | null>({
    queryKey: ['opportunitySpreadsConfiguration', opportunityId],
    queryFn: getOpportunitySpreadsConfigurationFunction,
    ...options,
  });
};
