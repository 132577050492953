import React, { type FC } from 'react';
import { Flex } from '@lama/design-system';

export const BusinessIcon: FC = () => (
  <Flex height={'20px'} width={'20px'}>
    <svg width={'20'} height={'20'} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M2.81041 11.1H3.15541V16.192C3.15541 16.4464 3.24229 16.6643 3.4167 16.8387C3.59111 17.0131 3.80899 17.1 4.06341 17.1H10.4474C10.7018 17.1 10.9197 17.0131 11.0941 16.8387C11.2685 16.6643 11.3554 16.4464 11.3554 16.192V11.1H15.6934V16.769C15.6934 16.9372 15.7508 17.0825 15.8672 17.1972C15.9828 17.3112 16.1269 17.3682 16.2929 17.369C16.4597 17.3698 16.6044 17.3124 16.7201 17.1967C16.8357 17.0811 16.8934 16.9366 16.8934 16.77V11.1H17.2384L17.2387 11.1C17.5218 11.0993 17.7594 10.9832 17.9454 10.7588C18.1335 10.5319 18.1918 10.2713 18.1175 9.98717L17.118 5.60527C17.118 5.60517 17.118 5.60508 17.118 5.60499C17.0721 5.39917 16.963 5.22896 16.7939 5.09714L16.7939 5.0971C16.6255 4.96601 16.4325 4.9 16.2184 4.9H3.83041C3.61622 4.9 3.42322 4.96639 3.25494 5.09812C3.08584 5.2293 2.97674 5.39921 2.93086 5.605C2.93084 5.60509 2.93082 5.60518 2.9308 5.60527L1.93128 9.98716C1.85697 10.2707 1.91539 10.5312 2.10332 10.7587C2.2893 10.9838 2.52702 11.1 2.81041 11.1ZM10.1554 11.1V15.9H4.35541V11.1H10.1554ZM3.2507 2.92671C3.36639 3.0424 3.51124 3.1 3.67841 3.1H16.3704C16.5363 3.1 16.6805 3.04272 16.7966 2.9282C16.9134 2.81301 16.9712 2.66807 16.9704 2.50053C16.9696 2.33374 16.9122 2.18915 16.7974 2.07354C16.6822 1.95761 16.5376 1.9 16.3704 1.9H3.67841C3.51041 1.9 3.36524 1.95694 3.25045 2.07254C3.13616 2.18763 3.07919 2.33195 3.07841 2.49853C3.07762 2.66585 3.13488 2.8109 3.2507 2.92671ZM16.8886 9.9H3.16123L4.04188 6.1H16.0079L16.8886 9.9Z'
        }
        fill={'#714EF5'}
        stroke={'#714EF5'}
        strokeWidth={'0.2'}
      />
    </svg>
  </Flex>
);
