import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { EtranLoginDetails } from '@lama/clients/dist/src/etranClient/types';
import { displayToast } from '@lama/app-components';
import { etranServiceClient } from '../../../clients/etranServiceClient';

export const useAuthenticateWithEtran = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (loginDetails: EtranLoginDetails) => {
      const token = await getAccessTokenSilently();
      return etranServiceClient.authenticate(loginDetails, token);
    },
    onError: () => {
      displayToast('Something went wrong while authenticating with ETran. Verify the details you entered are correct.', 'error');
    },
  });
};
