import React from 'react';
import type { FC } from 'react';
import type { BarTooltipProps } from '@nivo/bar';
import { format } from 'date-fns';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';

export const TransactionTooltip: FC<BarTooltipProps<Record<string, number | string>>> = ({
  id,
  value,
  data: { date, format: dateFormat },
}) => (
  <Flex
    flexDirection={'column'}
    backgroundColor={'#616161E5'}
    borderRadius={'4px'}
    alignItems={'center'}
    justifyContent={'center'}
    py={1}
    px={2}
  >
    <Text variant={'body2'} color={id === 'profit' ? '#31A985' : 'red.400'}>
      {id === 'profit' ? '+' : '-'}
      {formatValue(value, 'currency')}
    </Text>
    <Text variant={'body4'} color={'white'}>
      {date ? format(new Date(date), dateFormat as string) : '-'}
    </Text>
  </Flex>
);
