import type { TextProps } from '../Text';

export type SwitchSize = 'l' | 'm' | 's';

export const switchHeight: Record<SwitchSize, number> = {
  l: 24,
  m: 20,
  s: 16,
};

export const switchWidth: Record<SwitchSize, number> = {
  l: 48,
  m: 40,
  s: 32,
};

export const switchHandleDiameter: Record<SwitchSize, number> = {
  l: 20,
  m: 16,
  s: 12,
};

export const switchTextSize: Record<SwitchSize, TextProps['size']> = {
  l: 16,
  m: 12,
  s: 10,
};
